import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentationInputRMA, setDocumentationErrorMsg, setDocumentationError, setDocumentationResults } from '../core/redux/actions';
import { selectDocumentationInputRMA, selectDocumentationErrorMsg, selectDocumentationError, selectDocumentationResults } from '../core/redux/selectors';
import { Input, Link } from '../components/controls';
import { useTranslate } from '../core/contexts';
import { getValidAvailableDCRMA, getRMADeliveryChallan, getRMA } from '../services';
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { POWRButton, POWRIcon } from '../components/styledComponents';
import { useAlert, useRMAValidation, useGlobalMessage, useConfigParams } from "../core/hooks";
import { BASE_URL_API, URL_INDIA_DOC } from '../core/constants/URLS';
import { REGEXP, COLOR, DOCUMENTATION } from '../core/constants';

export default props => {
    const dispatch = useDispatch();
    const { isValidRMADashboard } = useRMAValidation();
    const { showAlert } = useAlert();
    const { translate } = useTranslate();
    const { getFaqLink, getContactUsLink } = useConfigParams();
    const { showPageMessage } = useGlobalMessage(); // Global Messages

    const [searched, setSearched] = useState('');

    const inputRMA = useSelector(selectDocumentationInputRMA);
    const errorMsg = useSelector(selectDocumentationErrorMsg);
    const error = useSelector(selectDocumentationError);
    const results = useSelector(selectDocumentationResults);

    const setInputRMA = value => dispatch(setDocumentationInputRMA(value));
    const setErrorMsg = value => dispatch(setDocumentationErrorMsg(value));
    const setError = value => dispatch(setDocumentationError(value));
    const setResults = value => dispatch(setDocumentationResults(value));

    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();

    useEffect(() => {
        setInputRMA("");
        setErrorMsg("");
        setError(false);
        setResults(false);
        setSearched('');
    }, []);

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.search) {
            setInputRMA(props.location.state.search);
        }
        if (props.location && props.location.state && props.location.state.rma) {
            setInputRMA(props.location.state.rma);
            handleSearch(props.location.state.rma);
        }
    }, [props.location]);

    useEffect(() => {
        let params = {
            module: 'GCT_Documentation_Access',
            country: '',
            rmaCategory: ''
        }
        showPageMessage(params, DOCUMENTATION);
    }, []);

    const handleChange = (e) => {
        if (REGEXP.RMAS.test(e.target.value)) {
            setInputRMA(e.target.value);
        }
    }

    // validation to know if an Indian RMA has a Delivery Challan available
    const serviceValidation = (input) => {
        const rmas = input ? (typeof (input) === 'string' ? input.replace(' ', ',').split(',') : input) : inputRMA.split(',');
        let message;
        const parameters = {
            param_rma_or_tradein: rmas.join(','),
            param_fa_case_num: "",
            isSimpleSearch: true,
            isReturnFa: false
        };

        return getRMA(parameters).then(response => {
            let rmaValidList = [];

            if (response.res.success) {
                if (response.res.dtos.length) {
                    response.res.dtos.map((rma, i) => {
                        if (isValidRMADashboard({ res: { dtos: [response.res.dtos[i]] } }, false, true).isValid) rmaValidList.push(rma.rma_num[0])
                    });
                    if (rmaValidList.length) {
                        const data = {
                            rmas: rmaValidList.length > 1 ? rmaValidList : rmaValidList[0],
                        };
                        getValidAvailableDCRMA(data).then(response => {
                            let data_response = response.res.dtos;

                            if (data_response.length > 0) {
                                rmaValidList.forEach((rma) => {
                                    if ((response.res.dtos.filter(x => x.rma_num === rma)).length === 0) {
                                        showAlert({
                                            id: "DCNotAvailable",
                                            message: `${translate('error.DCNotAvailable1') + rma + translate('error.DCNotAvailable2')}`,
                                            page: DOCUMENTATION,
                                            color: 'red'
                                        });
                                    }
                                })
                                const value = array_diff(data_response, rmaValidList);

                                if (value[0].length > 0) {
                                    setResults(true);
                                    setError(false);
                                    //     message = <Fragment>{translate('error.DCNotAvailableForRMA1') + value[0].join(', ') + translate('error.DCNotAvailableForRMA2')}</Fragment>;
                                    //     setAlert(message, '*');
                                    setInputRMA(value[0].join(', '))
                                } else {
                                    setError(true);
                                    setResults(false);
                                    message = <Fragment>{translate('error.DCNotAvailableForRMA1') + value[0].join(', ') + translate('error.DCNotAvailableForRMA2')}</Fragment>;
                                    // setInputRMA(value[1].join(', '))
                                }
                            } else {
                                setError(true);
                                setResults(false);
                                setErrorMsg(translate(translate('error.DCNotAvailableForRMA1') + rmas[0].join(', ') + translate('error.DCNotAvailableForRMA2')));
                            }
                        }).catch(() => {
                            setError(true);
                            setResults(false);
                            setErrorMsg(translate('error.rmaNotValidDC1'));
                        });
                    } else {
                        //  setError(translate('MMA ' + rmas + translate('error.rmaNotValidDC1')));
                    }
                } else {
                    setError(true);
                    setResults(false);
                    setError('RMA ' + rmas + translate('message.wasNotFound'));
                    return null;
                }
            }
        }).catch((e) => {
        });
    }

    // This function returns two lists, one with the valid rms[1] and the other with the invalid rms[0] for dc
    function array_diff(data_response, rmas) {
        let arrayDiff = [];
        let arrayEquals = [];
        let flag = false;

        for (var i = 0; i < rmas.length; i++) {
            for (var j = 0; j < data_response.length; j++) {
                if (rmas[i].includes(data_response[j]['rma_num'])) {
                    arrayEquals.push(data_response[j]['rma_num'])
                    flag = true;
                    //arrayDiff.splice(i, data_response[j]['rma_num']);
                }

            }
            if (flag === false) {
                arrayDiff.push(rmas[i]);
                flag = true;
            }
        }
        return [arrayEquals, arrayDiff];
    }

    const downloadFile = (option) => {
        const data = {
            param_rma: inputRMA,
            param_option: option
        };
        getRMADeliveryChallan(data).then(response => {
            let data_response = response.res.dtos;
            if (data_response.length > 0) {
                window.open(`${BASE_URL_API}/${URL_INDIA_DOC}` + data.param_rma + "&option=" + data.param_option, 'Download DC', "status=1,menubar=1,resizable=1,scrollbars=1,width=600,height=500");
            } else {
                setError(true);
                setErrorMsg(translate('error.DCNotAvailable1') + inputRMA + translate('error.DCNotAvailable2'));
            }
        }).catch(() => {
        });
    }

    // This function accepts a search for a String type or a search with an array of String. So I use a typeOf.
    const handleSearch = (input) => {
        let rma = input ? input : inputRMA;
        setSearched(rma);
        if (typeof rma === 'string') {
            if (rma.length >= 3) {
                setError(false);
                setErrorMsg('');
                if (input) serviceValidation(rma);
                else serviceValidation();
            } else {
                setError(true);
                setResults(false);
                setErrorMsg(translate('error.min'));
            }
        } else {
            setError(false);
            setErrorMsg('');
            if (input) serviceValidation(rma);
            else serviceValidation();
        }
    }

    return (
        <FullPage>
            <div className="main-documentation">
                <h1>{translate('title.documentationAccess')} <small className="ml-2">{translate('subtitle.indiaOnly')}</small></h1>
                <p className="subhead-documentation">{translate('message.searchAndDownload')}</p>
                <div className="body-documentation">
                    <div className="search-documentation">
                        <Input
                            label={translate('title.rma')}
                            name="inputRMA"
                            placeholder={translate('title.rma')}
                            value={inputRMA}
                            onChange={handleChange}
                            onEnter={() => handleSearch()}
                            icon={{ content: "search", width: "23px", height: "16px", color: COLOR.DARK_GREY_1 }}
                            error={error}
                            errorMessage={errorMsg ? errorMsg : translate('error.rmaError')}
                        />
                        <POWRButton className="ml-3" onClick={() => handleSearch()} color="secondary">
                            {translate('button.search')}
                        </POWRButton>
                    </div>
                    <div key={`documentation-result-${results}`}>
                        {
                            results ?
                                <div className="download-documentation">
                                    <div className="download-card">
                                        <POWRIcon
                                            className="fal fa-file-alt icon-card"
                                            size="70px"
                                            color={COLOR.GREY}
                                        />
                                        <p>{translate('aux.deliveryChallan')}</p>
                                        <Link onClick={() => downloadFile(1)}>
                                            <POWRIcon
                                                className="fal fa-download mr-2"
                                                size="14px"
                                                color={COLOR.CISCO_BLUE}
                                            />
                                            {translate('button.download')}
                                        </Link>
                                    </div>
                                </div>
                                :
                                (
                                    searched ?
                                        <div className="no-results">
                                            <POWRIcon
                                                className="fal fa-search icon-card"
                                                size="70px"
                                                color={COLOR.GREY}
                                            />
                                            <p className="text-center">{translate('message.rmawasNotFound').replace('{0}', searched)} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></p>
                                        </div>
                                        :
                                        <div className="no-results">
                                            <POWRIcon
                                                className="fal fa-search icon-card"
                                                size="70px"
                                                color={COLOR.GREY}
                                            />
                                            <p className="text-center">{translate('message.noRMAdetected')} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></p>
                                        </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </FullPage>
    )
}