import {
    SHIPMENT_STATUS_NUM,
    RMA_SHIPEVENTTYPE_TYPES,
    LOGINTYPE_CISCO_IRETURNS
} from '../constants';

const loginTypeGeneral = 2;

export const renderCancel = (ship_event, status) => {
    let parsedShipEvent = parseInt(ship_event,10);
    let loginType = (loginTypeGeneral !== LOGINTYPE_CISCO_IRETURNS);
    let visible = (
        loginType &&
        (status !== SHIPMENT_STATUS_NUM.STATUS_DELETED) &&
        (status !== SHIPMENT_STATUS_NUM.STATUS_DRAFT) &&
        (
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_CREATED_BY_USER ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_CONFIRMED ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_DENIED ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_RESCHEDULED_BY_USER ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_RESCHEDULED_BY_CARRIER ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_UNKNOWN ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_LABEL_CREATED
        )
    );

    return visible;
}