export const LOGINTYPE_UNKNOWN = 0;
export const LOGINTYPE_RMA_EMAIL = 1;
export const LOGINTYPE_CISCO = 2;
export const LOGINTYPE_CISCO_MOBILE = 3;
export const LOGINTYPE_CISCO_IRETURNS = 4;

export const LOGIN_TYPES = [
    { id: 0, type: 'UNKNOWN' },
    { id: 1, type: 'RMA_EMAIL' },
    { id: 2, type: 'CISCO' },
    { id: 3, type: 'CISCO_MOBILE' },
    { id: 4, type: 'CISCO_IRETURNS' },
]