import React, { useState, useEffect } from 'react';
import { Link } from './controls';
import { useSelector, useDispatch } from 'react-redux';
import { InfoSection, POWRIcon } from './styledComponents';
import { useTranslate } from '../core/contexts';
import {
    selectSelectionsInfo, selectBaseRMAInfo, selectSchedulePickup, selectLabelOnly,
    selectReturnFlowAttachFiles, selectSpecialInstrucLimit, selectZoneInstructions
} from '../core/redux/selectors';
import { ContactCard, ViewPackagesTable } from './index';
import { capitalize } from '../core/helpers';
import { setReturnFlowProp } from '../core/redux/actions';
import { ScheduleInfoCard } from '../components';
import moment from 'moment';
import { COLOR } from '../core/constants/Colors';
import { COUNTRY } from '../core/constants';

export const ReturnSummary = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const dateFormat = sessionStorage.getItem('dateFormat');
    const [pickLocation, setPickLocation] = useState(null);
    const [requestor, setRequestor] = useState(null);
    // const [returnAddress, setReturnAddress] = useState(null);
    const [contacts, setContacts] = useState([]);

    const selectionsInfo = useSelector(selectSelectionsInfo);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const savedSchedule = useSelector(selectSchedulePickup);
    const labelOnly = useSelector(selectLabelOnly);
    const attachedFiles = useSelector(selectReturnFlowAttachFiles);
    const instructionLimit = useSelector(selectSpecialInstrucLimit);
    const zoneInstructions = useSelector(selectZoneInstructions);

    const scheduleInfo = {
        title: "scheduleInfo",
        headers: [savedSchedule && savedSchedule.carrier && {
            title: translate("formLabel.shipCarrier"),
            value: `${savedSchedule.carrier.descr} (${savedSchedule.carrier.carrier_code} - ${savedSchedule.carrier.service_code})`
        }],
        content: [
            {
                title: translate("title.date"),
                value: savedSchedule && savedSchedule.pickup && moment(savedSchedule.pickup.schedule_date).format(dateFormat)
            },
            {
                title: translate("title.time"),
                value: savedSchedule.pickup.schedule_time_type === 0 ? translate("scheduleTimeType.any") :
                    savedSchedule.pickup.schedule_time_type === 1 ? translate("scheduleTimeType.am") :
                        savedSchedule.pickup.schedule_time_type === 2 ? translate("scheduleTimeType.pm") : ""
            }
        ],
        message: (savedSchedule && savedSchedule.instruct) && savedSchedule.paperless && translate("message.carrierPaperless")
    }

    const equimpentInfo = {
        title: "equimpentInfo",
        headers: [
            savedSchedule && savedSchedule.instruct && savedSchedule.instruct.equip_pallet_jack && {
                title: "",
                value: translate('message.requirePalletJack')
            },
            savedSchedule && savedSchedule.instruct && savedSchedule.instruct.equip_lift_gate && {
                title: "",
                value: translate('message.requireLiftTruck')
            }
        ],
        content: [
            savedSchedule && savedSchedule.pickup && savedSchedule.pickup.oversized_items && {
                title: translate("formLabel.pallets"),
                value: savedSchedule.pickup.oversized_items
            }

        ]
    };


    const hasCommercialInvoice = savedSchedule && savedSchedule.instruct &&
        (savedSchedule.instruct.generate_invoice === 'on' || savedSchedule.instruct.generate_sli || savedSchedule.instruct.generate_packing_lst);

    const commercialInvoice = [
        {
            title: "",
            value: savedSchedule && savedSchedule.instruct && savedSchedule.instruct.generate_invoice === 'on' && translate('title.commercialInvoice')
        },
        {
            title: "",
            value: savedSchedule && savedSchedule.instruct && savedSchedule.instruct.generate_sli && translate('aux.sli')
        },
        {
            title: "",
            value: savedSchedule && savedSchedule.instruct && savedSchedule.instruct.generate_packing_lst && translate('title.packageList')
        }

    ];
    const infoCardValid = (title) => {
        switch (title) {
            case "equimpentInfo":
                return (savedSchedule && savedSchedule.pickup) && savedSchedule.pickup.oversized_items > 0
                    || savedSchedule.instruct.equip_lift_gate || savedSchedule.instruct.equip_pallet_jack;
            case "scheduleInfo":
                return (savedSchedule && savedSchedule.carrier) || savedSchedule && savedSchedule.pickup
                    || (savedSchedule && savedSchedule.instruct) && savedSchedule.paperless;
            case "commercialInvoice":
                return (savedSchedule && savedSchedule.instruct) && savedSchedule.instruct.generate_invoice === 'on'
                    || savedSchedule.instruct.generate_sli || savedSchedule.instruct.generate_packing_lst
            default: break;
        }
    }

    useEffect(() => {
        if (selectionsInfo.additional.length > instructionLimit) {
            let newAdditional = selectionsInfo.additional.substr(0, instructionLimit)
            handleAdditionalInfo(newAdditional);
        }
    }, [instructionLimit, selectionsInfo])

    useEffect(() => {
        let cts = [];
        if (selectionsInfo.contact) cts.push(selectionsInfo.contact)
        if (selectionsInfo.alternateContact) cts.push(selectionsInfo.alternateContact);
        cts = cts.map((item) => {
            const fullName = `${capitalize(item.fname)} ${capitalize(item.lname)}`;
            let contactContent = {
                title: item.save_as || fullName || '',
                info: [...(item.save_as ? [fullName] : []), item.email, item.phone],
                errors: [],
                idd: item.idd
            };

            return contactContent;
        });
        setContacts(cts);
    }, [selectionsInfo.contact, selectionsInfo.alternateContact]);

    useEffect(() => {
        const pu = selectionsInfo.pickup;
        if (pu) {
            let info = [];
            const labelText = pu.country === COUNTRY.MEXICO ? translate('RFC') : translate('formLabel.gstin');
            for (let i = 0; i <= 4; i++) {
                if (pu[`addr${i + 1}`]) info.push(pu[`addr${i + 1}`]);
            }
            const gstin = pu.gstin || rmaInfo.gstin;
            setPickLocation({
                title: pu.save_as || pu.name,
                info: [
                    ...(pu.save_as ? [pu.name] : []),
                    ...info,
                    `${pu.city ? pu.city + ', ' : ''}${pu.province ? pu.province + ', ' : ''}${pu.postal ? (pu.postal + ' ') : ''}${pu.country || ''}`,
                    ...(gstin ? [`${labelText}: ${gstin}`] : [])
                ],
                additional: selectionsInfo && selectionsInfo.additional
            });
        }
        else setPickLocation(null);

        const rq = selectionsInfo.requestor;
        if (rq) {
            const fullName = `${capitalize(rq.fname)} ${capitalize(rq.lname)}`;
            setRequestor({
                title: rq.save_as || fullName,
                info: [...(rq.save_as ? [fullName] : []), rq.email, rq.phone],
                idd: rq.idd
            });
        }
        else setRequestor(null);

        // if (rmaInfo && rmaInfo.returnAddress) {
        //     const ad = rmaInfo.returnAddress;
        //     let info = [];
        //     for (let i = 0; i <= 4; i++) {
        //         if (ad[`addr${i + 1}`]) info.push(ad[`addr${i + 1}`]);
        //     }
        //     setReturnAddress({
        //         title: translate('title.returnAddress'),
        //         info: [
        //             (ad ? ad.name : ''),
        //             ...info,
        //             (ad ? `${ad.city ? ad.city + ', ' : ''}${ad.province ? ad.province + ', ' : ''}${ad.postal + ' ' || ''}${ad.country || ''}` : '')
        //         ]
        //     });
        // }
    }, [rmaInfo, selectionsInfo, translate]);

    const handleEditSetPickup = function () {
        dispatch(setReturnFlowProp('currentStep', 0));
    };

    const handleEditSchedule = function () {
        dispatch(setReturnFlowProp('scheduleStep', 0))
        dispatch(setReturnFlowProp('currentStep', 3));
    };

    const handleAdditionalInfo = (v) => {
        let newAddress = {
            ...pickLocation,
            additional: v
        }
        setPickLocation(newAddress);
        dispatch(setReturnFlowProp('additional', v));
    };

    return (
        <div className="container-fluid p-0">
            <section className="row">
                <div className="col justify-content-between">
                    <h2 className="mb-16">{translate("title.pickupInformation")}</h2>
                </div>
                <div className="col-auto text-right">
                    <Link onClick={() => handleEditSetPickup()} className="mr-3">
                        <span className="pr-1"><POWRIcon className="fas fa-edit select" color={COLOR.CISCO_BLUE} /></span>
                        {translate('button.edit')}
                    </Link>
                </div>
            </section>
            <section className="row">
                <div className="col-12 col-lg-6 pr-lg-8 pb-2 pb-lg-3">
                    <InfoSection
                        type={'cards'}
                        className="full-height"
                        title={translate('subtitle.pickupLocation')} >
                        <ContactCard
                            fullHeight
                            icon="map-marker-alt"
                            content={pickLocation}
                            hideButtons={true}
                            editAdditional
                            additionalLength={instructionLimit}
                            setAdditional={(v) => handleAdditionalInfo(v)}
                        />
                    </InfoSection>
                </div>
                <div className="col-12 col-lg-6 pr-lg-8 pb-2 pb-lg-3">
                    <InfoSection
                        type={'cards'}
                        className="full-height"
                        title={translate('subtitle.requestorContactInfo')} >
                        <ContactCard
                            fullHeight
                            icon="user-circle"
                            content={requestor}
                            hideButtons={true}
                        />
                    </InfoSection>
                </div>
                <div className="col-12 pr-lg-8 pb-3 pb-lg-4">
                    <InfoSection
                        type={'cards'}
                        title={translate('subtitle.pickupContact')} >
                        <div className="row">
                            {
                                contacts.map((contact, i) => (
                                    <div className={i + 1 < contacts.length ? "col-md-6 mb-3 mb-md-0" : "col-md-6"} key={`contact-${i}`}>
                                        <ContactCard
                                            fullHeight
                                            icon="portrait"
                                            content={contact}
                                            hideButtons={true}
                                        />
                                    </div>)
                                )
                            }
                        </div>
                    </InfoSection>
                </div>
            </section>
            <section className="row">
                <div className="col">
                    <h2 className="mb-16">{translate("title.package")}</h2>
                </div>
                <div className="col-auto text-right">
                    <Link onClick={() => handleEditSchedule()} className="mr-3">
                        <span className="pr-1"><POWRIcon className="fas fa-edit select" color={COLOR.CISCO_BLUE} /></span>
                        {translate('button.edit')}
                    </Link>
                </div>
            </section>
            <section className="row pb-3 pb-lg-4">
                <div className="col-12">
                    <InfoSection
                        type='table'
                        hideBorders={true}
                        buttonSymbol={<POWRIcon className="fas fa-edit select" color={COLOR.CISCO_BLUE} />}
                        onButtonClick={() => handleEditSchedule()} >
                        <ViewPackagesTable hideTN={true} />
                    </InfoSection>
                </div>
            </section>
            <section className="row">
                <div className="col">
                    <h2 className="mb-16">{translate(`title.${labelOnly ? 'selectCarrier' : 'schedulePickup'}`)}</h2>
                </div>
                <div className="col-auto text-right">
                    <Link onClick={() => handleEditSchedule()} className="mr-3">
                        <span className="pr-1"><POWRIcon className="fas fa-edit select" color={COLOR.CISCO_BLUE} /></span>
                        {translate('button.edit')}
                    </Link>
                </div>
            </section>
            <section className="row">
                {infoCardValid("scheduleInfo") ?
                    <div className="col-12 col-lg-6 pr-lg-8 pb-2 pb-lg-3" key="scheduleInfo">
                        <InfoSection
                            type={'cards'}
                            className="full-height"
                            title={labelOnly ? translate('title.generateLabel') : translate('formLabel.shipCarrier')} >
                            <ScheduleInfoCard
                                content={scheduleInfo.content}
                                headers={scheduleInfo.headers}
                                message={scheduleInfo.message}
                                labelOnly={labelOnly}
                            />
                        </InfoSection>
                    </div>
                    : <div key="scheduleInfo" />
                }
                {infoCardValid("equimpentInfo") ?
                    <div className="col-12 col-lg-6 pr-lg-8 pb-2 pb-lg-3" key="equimpentInfo">
                        <InfoSection
                            type={'cards'}
                            className="full-height"
                            title={translate('title.specialEquipment')}
                            isEmpty={true}>
                            {
                                <ScheduleInfoCard
                                    content={equimpentInfo.content}
                                    headers={equimpentInfo.headers}
                                    message={equimpentInfo.message}
                                />
                            }
                        </InfoSection>
                    </div>
                    : <div key="equimpentInfo" />
                }
                {infoCardValid("commercialInvoice") ?
                    <div className="col-12 col-lg-6 pr-lg-8 pb-2 pb-lg-3" key="commercialInvoice">
                        <InfoSection
                            type={'cards'}
                            className="full-height"
                            title={translate('title.commercialInvoice')}
                            isEmpty={true}>
                            {hasCommercialInvoice &&
                                <ScheduleInfoCard headers={commercialInvoice} />
                            }
                        </InfoSection>
                    </div>
                    : <div key="commercialInvoice" />
                }
                {attachedFiles && attachedFiles.length > 0 &&
                    <div className="col-12 col-lg-6 pr-lg-8 pb-2 pb-lg-3" key="attachedFiles">
                        <InfoSection
                            type={'cards'}
                            isEmpty={true}
                            className="full-height"
                            title={translate('title.documentationUpload')} >
                            {
                                attachedFiles.map((file, i) => (
                                    <div key={`fileContainer-${i}`} className="fileContainer mb-2">
                                        <POWRIcon className="fal fa-file-alt mr-3" color={COLOR.GREY} size="30px" />
                                        <div className="fileData">
                                            <div className="fileNameAExt">
                                                <h4 className="fileName">{file.name}</h4>
                                            </div>
                                            <label>{file.size}</label>
                                        </div>
                                    </div>
                                ))
                            }
                        </InfoSection>
                    </div>
                }
            </section>
            {zoneInstructions && zoneInstructions.trim() !== '' && <>
                <section className="row mb-3">
                    <div className="col"></div>
                    <div className="col-auto text-right">
                        <Link onClick={() => handleEditSetPickup()} className="mr-3">
                            <span className="pr-1"><POWRIcon className="fas fa-edit select" color={COLOR.CISCO_BLUE} /></span>
                            {translate('button.edit')}
                        </Link>
                    </div>
                </section>
                <section className="row">
                    <div className="col-12 pr-lg-8 pb-2 pb-lg-3" key="zoneInstrc">
                        <InfoSection
                            type={'cards'}
                            isEmpty={true}
                            className="full-height"
                            title={translate('subtitle.complianceInformation')}
                        >
                            <p className="m-0">{translate('message.bondedOrFreeTrade')}</p>
                            {zoneInstructions !== 'N/A' ? <p className="m-0">{zoneInstructions}</p> : null}
                        </InfoSection>
                    </div>
                </section>
            </>}
        </div>
    )
}

export default ReturnSummary;