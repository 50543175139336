import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDown, Input, Link, Button, Select } from '../components/controls';
import { POWRModal, POWRIcon } from '../components/styledComponents';
import { useTranslate } from '../core/contexts';
import { loadPackages, loadSubLines, setReturnFlowProp, updatePackage } from '../core/redux/actions';
import { RESET_PACKAGES } from '../core/redux/actionTypes';
import {
    selectRMAsPackages, selectReturnFlowScheduleStep, selectArePackagesMofied,
    selectReturnFlowSavedInfo, selectShipmentId, selectRMAsNum, selectSchedulePickup
} from '../core/redux/selectors';
import { getRMASubLines, saveRMASubLines } from '../services/rmaService';
import { getShipmentPackages } from '../services/shipmentService';
import { WEIGHT_UNITS, LENGTH_UNITS } from '../core/constants';
import { changeWeight, changeLength } from '../core/helpers';
import { VirtualScrollChild } from './shared';
import { PackageWeightDimensionsForm } from './forms/packageWeightDimensionsForm';
import { ViewPackageModal } from './modals';
import { COLOR } from '../core/constants/Colors';
import { RETURN } from '../core/constants/ROUTES';
import { useAlert } from '../core/hooks';
import { isValidPackageProp } from '../core/helpers/formatUnits';
import { gtagService } from '../services';

const SchedulePickup = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const [sortProp, setSortProp] = useState({ name: 'idd', dir: false });
    const [sortPackage, setSortPackage] = useState(false);
    const [sortedList, setSortedList] = useState([]);
    const [allCollapsed, setAllCollapsed] = useState(false);
    const [itemValues, setItemValues] = useState([]);
    // MANAGE UNITS
    const [showUnits, setShowUnits] = useState(false);
    const [weightUOM, setWeightUOM] = useState(0);
    const [lengthUOM, setLengthUOM] = useState(0);
    const [weightUOMText, setWeightUOMText] = useState('');
    const [lengthUOMText, setLengthUOMText] = useState('');
    const [showFillPackages, setShowFillPackages] = useState(false);
    const [messageShown, setMessageShown] = useState(false);
    const weightUnits = useMemo(() => WEIGHT_UNITS.filter(x => x.code !== 0), []);
    const lengthUnits = useMemo(() => LENGTH_UNITS.filter(x => x.code !== 0), []);

    const shipmentId = useSelector(selectShipmentId);
    const rmaNums = useSelector(selectRMAsNum);
    const rmasPackages = useSelector(selectRMAsPackages);
    const scheduleStep = useSelector(selectReturnFlowScheduleStep);
    const returnFlow = useSelector(selectReturnFlowSavedInfo);
    const showReset = useSelector(selectArePackagesMofied);
    const savedSchedule = useSelector(selectSchedulePickup);

    useEffect(() => {
        if (rmaNums.length && shipmentId && !rmasPackages.length) {
            generateShipPackages().then(() => {
                dispatch(loadPackages(shipmentId, true)).then(() => {
                    dispatch(loadSubLines(shipmentId, rmaNums, true));
                });
            });
        }
    }, [rmaNums, shipmentId, rmasPackages, dispatch]);

    const sortRMAsPackages = useCallback(() => {
        let temp = rmasPackages;
        if (!rmasPackages) return [];
        temp = sortPackage ? temp.sort((a, b) => b.rma - a.rma) : temp.sort((a, b) => a.rma - b.rma);
        return temp.map((rps) => ({
            ...rps,
            packages: sortProp.dir ? rps.packages.sort((a, b) => b[sortProp.name] > a[sortProp.name]) :
                rps.packages.sort((a, b) => a[sortProp.name] > b[sortProp.name]),
            collapsed: sortedList.length ? sortedList.find(x => x.rma === rps.rma).collapsed : false
        }));
    }, [sortPackage, sortProp, rmasPackages]);

    useEffect(() => {
        setSortedList(sortRMAsPackages());
    }, [sortRMAsPackages]);

    useEffect(() => {
        let uoms = [];
        var listPackages = sortedList.length > 0 ? sortedList : rmasPackages;
        listPackages.forEach(rmaPackages => {
            rmaPackages.packages.forEach(item => {
                item["weight"] = parseFloat(item["weight"]).toFixed(2);
                item["depth"] = parseFloat(item["depth"]).toFixed(2);
                item["height"] = parseFloat(item["height"]).toFixed(2);
                item["width"] = parseFloat(item["width"]).toFixed(2);
                uoms.push(item);
            });
        });
        setItemValues(uoms);
    }, [rmasPackages, sortedList]);

    useEffect(() => {
        setAllCollapsed(!sortedList.find(x => x.collapsed === true));
        if (sortedList[0] && sortedList[0].packages[0] && !weightUOM && !lengthUOM) {
            let weightCode = sortedList[0].packages[0].weight_uom;
            let weightUnit = WEIGHT_UNITS.filter(x => x.code === weightCode)[0] || WEIGHT_UNITS[0];
            setWeightUOM(weightUnit.code);
            setWeightUOMText(weightUnit.text);
            let lengthCode = sortedList[0].packages[0].dim_uom;
            let lengthUnit = LENGTH_UNITS.filter(x => x.code === lengthCode)[0] || LENGTH_UNITS[0];
            setLengthUOM(lengthUnit.code);
            setLengthUOMText(lengthUnit.text);
        }
        if (sortedList.length > 0 && sortedList[0].packages.length === 1 && scheduleStep === 0 && !messageShown && !savedSchedule) {
            showAlert({
                id: 'defaultDimensions',
                message: rmasPackages[0].packages[0].weight !== '0.00' &&
                    rmasPackages[0].packages[0].length !== '0.00' &&
                    rmasPackages[0].packages[0].width !== '0.00' &&
                    rmasPackages[0].packages[0].height !== '0.00' ? translate("message.defaultDimensions") : translate("message.enterDimensions"),
                page: RETURN
            });
            setMessageShown(true);
        }
    }, [sortedList]);

    useEffect(() => {
        if (!scheduleStep) setSortProp({ name: 'idd', dir: false });
    }, [scheduleStep])

    useEffect(() => {
        let weightUnit = WEIGHT_UNITS.filter(x => x.code === weightUOM)[0];
        if (weightUnit) setWeightUOMText(weightUnit.text);
    }, [weightUOM]);

    useEffect(() => {
        let lengthUnit = LENGTH_UNITS.filter(x => x.code === lengthUOM)[0];
        if (lengthUnit) setLengthUOMText(lengthUnit.text);
    }, [lengthUOM]);

    const handleSetUnits = () => {
        sortedList.forEach(el => {
            el.packages.forEach(item => {
                let prevWeightUnit = WEIGHT_UNITS.filter(x => x.code === item.weight_uom)[0].code;
                let prevLengthUnit = LENGTH_UNITS.filter(x => x.code === item.dim_uom)[0].code;
                if (prevWeightUnit !== weightUOM) {
                    updatePackageIntProp(item.idd, 'weight_uom', weightUOM);
                    updatePackageProp(item.idd, 'weight', changeWeight(weightUOM, item.weight), true);
                }
                if (prevLengthUnit !== lengthUOM) {
                    updatePackageIntProp(item.idd, 'dim_uom', lengthUOM);
                    updatePackageProp(item.idd, 'depth', changeLength(prevLengthUnit, lengthUOM, item.depth), true);
                    updatePackageProp(item.idd, 'height', changeLength(prevLengthUnit, lengthUOM, item.height), true);
                    updatePackageProp(item.idd, 'width', changeLength(prevLengthUnit, lengthUOM, item.width), true);
                }
            })
        })
        setShowUnits(false);
    };

    const generateShipPackages = () => new Promise((resolve) => {
        getShipmentPackages({ ship_id: shipmentId }).then(({ res }) => {
            if (res.success && res.dtos.length >= rmaNums.length) {
                const packages = res.dtos;
                const sublinesWithPackages = [];
                Promise.all(rmaNums.map((rma) => {
                    const packageToAssign = packages.find(x => x.rma_num === rma);
                    return getRMASubLines({
                        sort: 'sub_line', dir: 'ASC', ship_id: shipmentId, rma: rma
                    }).then((response) => {
                        if (response.res.success && response.res.dtos.length) {
                            const subline = response.res.dtos[0];
                            sublinesWithPackages.push({
                                idd: subline.idd,
                                return_qty: 1,
                                serial: subline.serial,
                                package_code: packageToAssign.pack_code,
                                package_id: packageToAssign.idd,
                                type: 'ShipSubLine',
                                seq: 1,
                                pkg_modified: false
                            });
                        }
                    });
                })).then(() => {
                    saveRMASubLines({
                        ship_id: shipmentId,
                        json: JSON.stringify({ dtos: sublinesWithPackages }),
                        weight_uom: returnFlow.weight_uom,
                        length_uom: returnFlow.length_uom
                    }).then((result) => {
                        if (result.res.success) resolve(null);
                    })
                });
            }
        });
    });

    const handleCollapse = (listItem) => setSortedList(
        sortedList.map((x) => x.rma === listItem.rma ? { ...x, collapsed: !listItem.collapsed } : x)
    );

    const collapseAll = (val) => setSortedList(sortedList.map((x) => ({ ...x, collapsed: val })));

    const changeSort = (name) => sortProp.name === name ?
        setSortProp({ name, dir: !sortProp.dir }) : setSortProp({ name, dir: false });

    const fillPackagesByCopy = useCallback((values) => {
        let newPackages = [];
        if (!values.shouldOverride)
            newPackages = itemValues.map(pk => {
                return {
                    ...pk,
                    weight: parseFloat(pk.weight) ? pk.weight : (values.weight || '0.00'),
                    height: parseFloat(pk.height) ? pk.height : (values.height || '0.00'),
                    width: parseFloat(pk.width) ? pk.width : (values.width || '0.00'),
                    depth: parseFloat(pk.depth) ? pk.depth : (values.depth || '0.00')
                };
            });
        else
            newPackages = itemValues.map(pk => {
                return {
                    ...pk,
                    weight: values.weight || '0.00',
                    height: values.height || '0.00',
                    width: values.width || '0.00',
                    depth: values.depth || '0.00'
                };
            });
        setItemValues(newPackages);
        dispatch(setReturnFlowProp('packages', newPackages));
        setShowFillPackages(false);
    }, [itemValues, dispatch]);

    const updatePackageProp = (id, property, value, isBlur, idx) => {
        if (scheduleStep === 1) return;
        let v = String(value).replace(/[^0-9\.]/g, "");
        let item = itemValues.find(a => a.idd === id);

        const newPropertyValue = !isBlur ? v : parseFloat(v).toFixed(2);

        if (!isValidPackageProp(newPropertyValue, property)) return;

        if (!isBlur)
            setItemValues(itemValues.map(x => x.idd === id ? { ...x, [property]: newPropertyValue } : x));
        else {
            if (value === '' || isNaN(value))
                dispatch(updatePackage({ id, property, value: '0.00' }));
            else {
                dispatch(updatePackage({ id: item.idd, property, value: newPropertyValue }));
            }
        }
    };

    const updatePackageIntProp = (id, property, value) => {
        dispatch(updatePackage({ id, property, value }));
    };

    const handleShowAutoFillPackages = () => {
        setShowFillPackages(true);
        gtagService.event('NewFeatures', 'AutoFillWeight&Dimensions');
    };

    return (
        <div className="schedulePickupContainer">
            <p>
                {`${translate('message.assignWeightSize')} `}
                <Link onClick={() => setShowUnits(true)}>
                    {translate('button.changeUnits')}
                </Link>
                {` ${translate('aux.uom')}`}
            </p>
            {!scheduleStep && <h4>{translate('tip.schedule')}</h4>}
            <div className="schedulePickupWrapper">
                <div className="actions mt-3 justify-content-between justify-content-md-end">
                    {!scheduleStep ?
                        <div className="col-auto pl-3">
                            <Link onClick={() => handleShowAutoFillPackages()}>
                                <span className="pr-1" key={'copy-icon-2'}>
                                    <POWRIcon className="fas fa-copy" color={COLOR.CISCO_BLUE} />
                                </span>
                                <span>{translate('button.fillPackages')}</span>
                            </Link>
                        </div> : null
                    }
                    {!scheduleStep ?
                        (showReset ?
                            <div className="col-auto pr-3">
                                <Link onClick={() => dispatch({ type: RESET_PACKAGES })}>
                                    <span className="pr-1" key={'undo-icon-2'}>
                                        <POWRIcon className="fas fa-undo" color={COLOR.CISCO_BLUE} />
                                    </span>
                                    <span>{translate('button.resetAll')}</span>
                                </Link>
                            </div>
                            : null
                        )
                        :
                        <div className="col-auto pr-3">
                            <Link onClick={() => dispatch(setReturnFlowProp('scheduleStep', 0))}>
                                <span className="pr-1" key={'pen-icon-3'}>
                                    <POWRIcon className="fas fa-pen" color={COLOR.CISCO_BLUE} />
                                </span>
                                <span>{translate('button.edit')}</span>
                            </Link>
                        </div>
                    }
                </div>
                <table className="d-none d-xl-table">
                    <thead>
                        <tr className="pickUpsTitles">
                            <th className="iconWidth"><div
                                className="th"
                                onClick={() => allCollapsed ? collapseAll(true) : collapseAll(false)}
                                key={`allCollapsedSquare-${allCollapsed}`} >
                                <POWRIcon className={`${allCollapsed ? "fas fa-minus-square" : "fas fa-plus-square"}`} size="1rem" color={COLOR.CISCO_BLUE} />
                            </div></th>
                            <th><div
                                className="th"
                                // onClick={() => setSortPackage(!sortPackage)}
                                onClick={() => changeSort('pack_num')}
                                key={`sortPackageArrow-${sortProp.name === 'pack_num' && sortProp.dir}`} >
                                {translate('title.package')}
                                <POWRIcon className={`${(sortProp.name === 'pack_num' && sortProp.dir) ? "fas fa-arrow-down" : "fas fa-arrow-up"} ml-2`} size="12px" color={COLOR.CISCO_BLUE} />
                            </div></th>
                            <th><div
                                className="th mr-5"
                                onClick={scheduleStep ? () => changeSort('weight') : () => { }}
                                key={`sortPackageArrow-weight-${sortProp.name === 'weight' && sortProp.dir}`} >
                                {translate('aux.weight')}
                                {!!scheduleStep &&
                                    <span>
                                        <POWRIcon className={`${sortProp.name === 'weight' && sortProp.dir ? "fas fa-arrow-down" : "fas fa-arrow-up"} ml-2`} size="12px" color={COLOR.CISCO_BLUE} />
                                    </span>
                                }
                            </div></th>
                            <th><div
                                className="th"
                                onClick={scheduleStep ? () => changeSort('depth') : () => { }}
                                key={`sortPackageArrow-dim_uom-${sortProp.name === 'depth' && sortProp.dir}`} >
                                {translate('gridColumns.dimensions')}
                                {!!scheduleStep &&
                                    <span>
                                        <POWRIcon className={`${sortProp.name === 'depth' && sortProp.dir ? "fas fa-arrow-down" : "fas fa-arrow-up"} ml-2`} size="12px" color={COLOR.CISCO_BLUE} />
                                    </span>
                                }
                            </div></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedList.map((rmaPackages, i) => (
                                rmaPackages.packages.length ?
                                    <Fragment key={'rmaPackages' + i}>
                                        <VirtualScrollChild height='60' type='table' className="packageSelect" onClick={() => handleCollapse(rmaPackages)} >
                                            <td className="iconWidth"><div
                                                className="showLines"
                                                key={`showLines-${!rmaPackages.collapsed}`} >
                                                <POWRIcon className={`${!rmaPackages.collapsed ? "fas fa-caret-down" : "fas fa-caret-right"}`} size="16px" color={COLOR.CISCO_BLUE} />
                                            </div></td>
                                            <td><div className="headPackage">
                                                <h4>{translate('title.rma')}: {rmaPackages.rma || ''}</h4>
                                            </div></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </VirtualScrollChild>
                                        {
                                            !rmaPackages.collapsed &&
                                            rmaPackages.packages.map((item, i) => {
                                                let weight_uom = WEIGHT_UNITS.filter(x => x.code === item.weight_uom);
                                                let dim_uom = LENGTH_UNITS.filter(x => x.code === item.dim_uom);
                                                let itemValue = itemValues.length > 0 && itemValues.find(a => a.idd === item.idd);
                                                
                                                return (
                                                    <VirtualScrollChild height='60' type='table' className="pickUpsRow" key={'package' + i}>
                                                        <td className="iconWidth"></td>
                                                        <td><div className="td">
                                                            <POWRIcon
                                                                className="fas fa-box ml-4 mr-2"
                                                                size="18px"
                                                                color={COLOR.DARK_GREY_1} />
                                                            <p>{item.pack_num}</p>
                                                            <div className="d-inline-block d-md-none float-right">
                                                                <DropDown options={[{
                                                                    icon: "",
                                                                    text: "Reset",
                                                                    handler: () => dispatch({ type: RESET_PACKAGES })
                                                                }]} />
                                                            </div>
                                                        </div></td>
                                                        <td><div className="td">
                                                            <Input
                                                                readOnly={!!scheduleStep}
                                                                // style={{ width: `calc(${itemValue && String(itemValue.weight).length + 2}ch + 1rem)` }}
                                                                className="sizeInput sizeInputWeight m-0"
                                                                placeholder=""
                                                                name="weight"
                                                                value={itemValue ? (itemValue.weight || "") : ""}
                                                                onChange={(e) => updatePackageProp(item.idd, 'weight', e.target.value, false, i)}
                                                                onBlur={(e) => updatePackageProp(item.idd, 'weight', e.target.value, true, i)}
                                                                error={item.weight === '.' || !Number(item.weight)} />
                                                            <p className="lbs">{(weight_uom[0] && weight_uom[0].text) || 'NA'}</p>
                                                        </div></td>
                                                        <td><div className="td">
                                                            <Input
                                                                readOnly={!!scheduleStep}
                                                                // style={{ width: `calc(${itemValue && String(itemValue.depth).length + 2}ch + 1rem)` }}
                                                                className="sizeInput"
                                                                placeholder="L"
                                                                name="size"
                                                                value={itemValue ? (itemValue.depth || "") : ""}
                                                                onChange={(e) => updatePackageProp(item.idd, 'depth', e.target.value, false, i)}
                                                                onBlur={(e) => updatePackageProp(item.idd, 'depth', e.target.value, true, i)}
                                                                error={item.depth === '.' || !Number(item.depth)} />
                                                            <label>X</label>
                                                            <Input
                                                                readOnly={!!scheduleStep}
                                                                // style={{ width: `calc(${itemValue && String(itemValue.width).length + 2}ch + 1rem)` }}
                                                                className="sizeInput"
                                                                placeholder="H"
                                                                name="size"
                                                                value={itemValue ? (itemValue.width || "") : ""}
                                                                onChange={(e) => updatePackageProp(item.idd, 'width', e.target.value, false, i)}
                                                                onBlur={(e) => updatePackageProp(item.idd, 'width', e.target.value, true, i)}
                                                                error={item.width === '.' || !Number(item.width)} />
                                                            <label>X</label>
                                                            <Input
                                                                readOnly={!!scheduleStep}
                                                                // style={{ width: `calc(${itemValue && String(itemValue.height).length + 2}ch + 1rem)` }}
                                                                className="sizeInput"
                                                                placeholder="W"
                                                                name="size"
                                                                value={itemValue ? (itemValue.height || "") : ""}
                                                                onChange={(e) => updatePackageProp(item.idd, 'height', e.target.value, false, i)}
                                                                onBlur={(e) => updatePackageProp(item.idd, 'height', e.target.value, true, i)}
                                                                error={item.height === '.' || !Number(item.height)} />


                                                            <p>{(dim_uom[0] && dim_uom[0].text) || 'NA'}</p>
                                                        </div></td>
                                                        <td><div className="td justify-content-end text-right pr-2">
                                                            <ViewPackageModal
                                                                idd={item.idd}
                                                                shipId={shipmentId}
                                                                isSerialEditable
                                                                weightUnit={(weight_uom[0] && weight_uom[0].text) || 'NA'}
                                                                dimensionUnit={(dim_uom[0] && dim_uom[0].text) || 'NA'} />
                                                        </div></td>
                                                    </VirtualScrollChild>
                                                )
                                            })
                                        }
                                    </Fragment>
                                    : null
                            ))
                        }
                    </tbody>
                </table>
                <div className="d-block d-xl-none py-16 px-16">
                    {sortedList.map((rmaPackages, i) => (
                        rmaPackages.packages.length ?
                            <Fragment key={'rmaPackages' + i}>
                                <VirtualScrollChild height="60" className="border-bottom-gray pb-3 mb-3">
                                    <div className="row border-bottom-gray py-2" key={`showLines-${!rmaPackages.collapsed}`} onClick={() => handleCollapse(rmaPackages)}>
                                        <div className="col d-flex">
                                            <POWRIcon
                                                className={`fas fa-${!rmaPackages.collapsed ? "caret-down" : "caret-right"} mr-2`}
                                                size="16px"
                                                color={COLOR.CISCO_BLUE} />
                                            <h4 className="m-0">{translate('title.rma')}: {rmaPackages.rma || ''}</h4>
                                        </div>
                                    </div>

                                    {!rmaPackages.collapsed && rmaPackages.packages.map((item, i) => {
                                        let weight_uom = WEIGHT_UNITS.filter(x => x.code === item.weight_uom);
                                        let dim_uom = LENGTH_UNITS.filter(x => x.code === item.dim_uom);
                                        let itemValue = itemValues.length > 0 && itemValues.find(a => a.idd === item.idd);

                                        return (
                                            <VirtualScrollChild height="60" key={'package' + i} className="border-bottom-gray py-2">
                                                <div className="row py-2">
                                                    <div className="col d-flex">
                                                        <POWRIcon
                                                            className="fas fa-box mr-2"
                                                            size="14px"
                                                            color={COLOR.DARK_GREY_1} />
                                                        <p>{item.pack_num}</p>
                                                    </div>
                                                    <div className="col-auto">
                                                        <ViewPackageModal
                                                            idd={item.idd}
                                                            shipId={shipmentId}
                                                            isSerialEditable
                                                            weightUnit={(weight_uom[0] && weight_uom[0].text) || 'NA'}
                                                            dimensionUnit={(dim_uom[0] && dim_uom[0].text) || 'NA'} />
                                                    </div>
                                                </div>
                                                <div className="row py-2">
                                                    <div className="col-12 col-md d-flex justify-content-between">
                                                        <div className="d-flex d-md-none">
                                                            <label>{translate('aux.weight')}</label>
                                                        </div>
                                                        <Input
                                                            style={{ height: 'auto' }}
                                                            bStyle={{ padding: 0 }}
                                                            readOnly={!!scheduleStep}
                                                            className="sizeInput m-0"
                                                            placeholder=""
                                                            name="weight"
                                                            value={itemValue ? (itemValue.weight || "") : ""}
                                                            onChange={(e) => updatePackageProp(item.idd, 'weight', e.target.value, false, i)}
                                                            onBlur={(e) => updatePackageProp(item.idd, 'weight', e.target.value, true, i)}
                                                            error={item.weight === '.' || !Number(item.weight)} />
                                                        <p className="lbs">{(weight_uom[0] && weight_uom[0].text) || 'NA'}</p>
                                                    </div>
                                                    <div className="col-12 col-md d-flex justify-content-between">
                                                        <div className="d-flex d-md-none">
                                                            <label>{translate('aux.length')}</label>
                                                        </div>
                                                        <Input
                                                            style={{ height: 'auto' }}
                                                            bStyle={{ padding: 0 }}
                                                            readOnly={!!scheduleStep}
                                                            className="sizeInput"
                                                            placeholder="L"
                                                            name="size"
                                                            value={itemValue ? (itemValue.depth || "") : ""}
                                                            onChange={(e) => updatePackageProp(item.idd, 'depth', e.target.value, false, i)}
                                                            onBlur={(e) => updatePackageProp(item.idd, 'depth', e.target.value, true, i)}
                                                            error={item.depth === '.' || !Number(item.depth)} />
                                                        <label className="d-none d-md-flex">X</label>
                                                        <p className="d-flex d-md-none">{(dim_uom[0] && dim_uom[0].text) || 'NA'}</p>
                                                    </div>
                                                    <div className="col-12 col-md d-flex justify-content-between">
                                                        <div className="d-flex d-md-none">
                                                            <label>{translate('aux.width')}</label>
                                                        </div>
                                                        <Input
                                                            style={{ height: 'auto' }}
                                                            bStyle={{ padding: 0 }}
                                                            readOnly={!!scheduleStep}
                                                            className="sizeInput"
                                                            placeholder="H"
                                                            name="size"
                                                            value={itemValue ? (itemValue.width || "") : ""}
                                                            onChange={(e) => updatePackageProp(item.idd, 'width', e.target.value, false, i)}
                                                            onBlur={(e) => updatePackageProp(item.idd, 'width', e.target.value, true, i)}
                                                            error={item.width === '.' || !Number(item.width)} />
                                                        <label className="d-none d-md-flex">X</label>
                                                        <p className="d-flex d-md-none">{(dim_uom[0] && dim_uom[0].text) || 'NA'}</p>
                                                    </div>
                                                    <div className="col-12 col-md d-flex justify-content-between">
                                                        <div className="d-flex d-md-none">
                                                            <label>{translate('aux.height')}</label>
                                                        </div>
                                                        <Input
                                                            style={{ height: 'auto' }}
                                                            bStyle={{ padding: 0 }}
                                                            readOnly={!!scheduleStep}
                                                            className="sizeInput"
                                                            placeholder="W"
                                                            name="size"
                                                            value={itemValue ? (itemValue.height || "") : ""}
                                                            onChange={(e) => updatePackageProp(item.idd, 'height', e.target.value, false, i)}
                                                            onBlur={(e) => updatePackageProp(item.idd, 'height', e.target.value, true, i)}
                                                            error={item.height === '.' || !Number(item.height)} />
                                                        <p>{(dim_uom[0] && dim_uom[0].text) || 'NA'}</p>
                                                    </div>
                                                </div>
                                            </VirtualScrollChild>
                                        )
                                    })}
                                </VirtualScrollChild>
                            </Fragment>
                            : null
                    ))}
                </div>
            </div>
            <POWRModal
                size="small"
                show={showUnits}
                title={translate('subtitle.unitOfMeasurement')}
                onClose={setShowUnits.bind(this, false)}
                noBodyOverflow
            >
                <div className="unitOfMeasurement">
                    <Select
                        value={weightUOM}
                        text={weightUOMText}
                        onChange={(e) => setWeightUOM(e)}
                        options={weightUnits}
                        className="selectUnit"
                        label={translate('formLabel.weightUOM')}
                        singleSelection={true}
                        hideClear={true} />
                    <Select
                        value={lengthUOM}
                        text={lengthUOMText}
                        onChange={(e) => setLengthUOM(e)}
                        options={lengthUnits}
                        className="selectUnit"
                        label={translate('formLabel.lengthUOM')}
                        singleSelection={true}
                        hideClear={true} />
                </div>
                <div className="toolbar">
                    <Button
                        onClick={setShowUnits.bind(this, false)}
                        type="2" >
                        {translate('button.cancel')}
                    </Button>
                    <Button onClick={handleSetUnits.bind(this)} >
                        {translate('button.apply')}
                    </Button>
                </div>
            </POWRModal>
            <POWRModal
                show={showFillPackages}
                size="wide"
                title={translate('subtitle.packageWeightDimensions')}
                onClose={() => setShowFillPackages(false)}>
                <PackageWeightDimensionsForm
                    onSave={(values) => fillPackagesByCopy(values)}
                    onCancel={() => setShowFillPackages(false)}
                    weightUnit={WEIGHT_UNITS.find(x => x.code === weightUOM).text}
                    dimUnit={LENGTH_UNITS.find(x => x.code === lengthUOM).text} />
            </POWRModal>
        </div>
    )
};

export default SchedulePickup;