import { SET_LOADER, ADD_ALERT, REMOVE_ALERT, REMOVE_ALERTS, REMOVE_ALL_ALERTS, CLICK_OUTSIDE_RETURN, SET_TYPE_ALERT, REMOVE_ALL_GLOBAL_MESSAGES, OPEN_ASIDE_FILTERS, SET_DOWNLOAD_PARAMS, SET_SHIPMENT_EDIT, SET_RMAS_URL_PARAM } from "../actionTypes";

const initialState = {
    alerts: [],
    loader: false,
    clickOutsideReturn: '',
    clickCancelReturn: false,
    isModal: false,
    asideFilters: false,
    downloadParams: null,
    RMAsURLParam:null
};


export const general = (state = initialState, { payload, type }) => {
    switch (type) {
        case ADD_ALERT: return {
            ...state,
            alerts: [...state.alerts, payload]
        };
        case REMOVE_ALL_ALERTS:
            {
                return {
                    ...state,
                    alerts: [],
                    loader: false

                };
            }
        case REMOVE_ALERT: return {
            ...state,
            alerts: state.alerts.filter(x => x.id !== payload)
        };
        case REMOVE_ALERTS: return {
            ...state,
            alerts: state.alerts.filter(x => !payload.find(id => id === x.id))
        };
        case REMOVE_ALL_GLOBAL_MESSAGES: return {
            ...state,
            alerts: state.alerts.filter(x => !x.isGlobalMessage)
        }
        case SET_TYPE_ALERT: return {
            ...state,
            isModal: payload
        }
        case SET_LOADER:
            return {
                ...state,
                loader: payload
            };
        case CLICK_OUTSIDE_RETURN:
            return { ...state, clickOutsideReturn: payload };
        case OPEN_ASIDE_FILTERS:
            return { ...state, asideFilters: payload };
        case SET_DOWNLOAD_PARAMS:
            return { ...state, downloadParams: payload };
        case SET_SHIPMENT_EDIT:
            return {...state, isShipmentEdit: payload}
        case SET_RMAS_URL_PARAM:
            return { ...state, RMAsURLParam: payload }
        default: return state;
    }
};