import {
    SHIPMENT_STATUS_NUM,
    RMA_SHIPEVENTTYPE_TYPES
} from '../constants';

export const renderReEnable = (ship_event, status) => {
    let cancel_visible1 = (status !== SHIPMENT_STATUS_NUM.STATUS_DELETED && status !== SHIPMENT_STATUS_NUM.STATUS_DRAFT);
    let parsedShipEvent = parseInt(ship_event,10);
    let statusCheck = parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_DENIED;

    return cancel_visible1 && statusCheck;
}