import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslate } from '../../core/contexts';
import { convertDateFormat } from "../../core/helpers";
import { POWRModal } from "../styledComponents";
import { Button, Select, InputCalendar, CheckRadio, Spinner } from "../controls";
import { useAlert, usePickupDate } from '../../core/hooks';
import { getCarrierServices, getRescheduleData, postReschedule, getZones, getShipmentPackages, getShipmentDetails, gtagService } from '../../services';
import { getShipCarriers } from '../../services/carrierListService';

export default props => {
    const { translate } = useTranslate();
    const { showAlert, removeAlerts } = useAlert();
    const { getPickupDates } = usePickupDate();
    const { show, onClose } = props;

    const [timezoneData, setTimezoneData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCarriers, setIsLoadingCarriers] = useState(false);
    const [carriers, setCarriers] = useState([]);
    const [packages, setPackages] = useState(null);
    const [numPallets, setNumPallets] = useState(null);
    const [_inputddlCarrier, setInputddlCarrier] = useState('');
    const [_selectedCarrierCode, setSelectedCarrierCode] = useState(null);
    const [firstAvailableDay, setFirstAvailableDay] = useState(null);
    const [_serviceDisabledDays, setServiceDisabledDays] = useState([]);
    const [_disabledDates, setDisabledDates] = useState([]);
    const [_availableDates, setAvailableDates] = useState([]);
    const [_avalaibleAM, setAvalaibleAM] = useState(false);
    const [_avalaiblePM, setAvalaiblePM] = useState(false);
    const [_avalaibleANY, setAvalaibleANY] = useState(false);
    const [_selectedTime, setSelectedTime] = useState(null);
    const [_selectedDate, setSelectedDate] = useState(null);
    const [_selectedCarrier, setSelectedCarrier] = useState(null);
    const [rescheduleCancelCarrierCode, setRescheduleCancelCarrierCode] = useState('');

    const dateFormat = sessionStorage.getItem('dateFormat');

    useEffect(() => {
        setIsLoading(true);
        searchRescheduleData();
    }, [props.data, props.carrierProfile]);

    useEffect(() => {
        if (!props.noChangeCarrier) {
            setIsLoadingCarriers(true);
            if (!props.packages && !packages) searchShipmentPackages();
            else setPackages(props.packages);
            if (!props.num_pallets && !numPallets) searchNumPallets(props.id);
            else setNumPallets(props.num_pallets);
        }
    }, [props.noChangeCarrier]);

    useEffect(() => {
        if (packages !== null && numPallets !== null) loadSelectsOptions();
    }, [packages && numPallets]);

    useEffect(() => {
        if (carriers && carriers.length && _selectedCarrierCode) {
            let selectedCarrier = carriers.filter(x => x.carrier_code === _selectedCarrierCode)[0];
            setSelectedCarrier(selectedCarrier);
            setAvalaibleAM(selectedCarrier.am_pickup_available);
            setAvalaiblePM(selectedCarrier.pm_pickup_available);
            setAvalaibleANY(selectedCarrier.any_pickup_available);
        }
    }, [carriers, _selectedCarrierCode, _selectedDate]);

    useEffect(() => {
        let time = timezoneData[0] ? timezoneData[0].location_date : null;
        if (time && _selectedCarrier) {
            let pickupDates = getPickupDates(_selectedCarrier, time);
            if (pickupDates.firstDay) setFirstAvailableDay(moment(pickupDates.firstDay).toDate());
            if (pickupDates.noDates && pickupDates.noDates < 1) setServiceDisabledDays(_selectedCarrier.service_disabled_days);
            setAvailableDates(pickupDates.dates);
            setDisabledDates(pickupDates.noDates);
        }
    }, [_selectedCarrier, timezoneData]);

    const searchRescheduleData = () => {
        const params = { ship_id: props.idd, pickup_id: props.data.pickup_id };

        getRescheduleData(params).then(response => {
            if (response.status === 200) {
                setSelectedCarrier(props.carrierProfile);
                setSelectedDate(response.res.dtos[0].schedule_date);
                setSelectedTime(response.res.dtos[0].schedule_time_type);
                setAvalaibleAM(props.carrierProfile.am_pickup_available);
                setAvalaiblePM(props.carrierProfile.pm_pickup_available);
                setAvalaibleANY(props.carrierProfile.any_pickup_available);

                getTimeZoneData(response.res.dtos);
            }
        }).catch(error => {
            showAlert({
                id: 'getRescheduleData',
                message: translate('message.unexpectedSituation'),
                page: '*',
                color: "red"
            });
            console.log('Error', error);
            setIsLoading(false);
        });
    }

    const getTimeZoneData = (rData) => {
        const params = {
            pickup_city: rData[0].pickup_city,
            pickup_province: rData[0].pickup_province,
            pickup_country: rData[0].pickup_country
        }

        getZones(params).then(response => {
            if (response.status === 200) {
                setTimezoneData(response.res.dtos);
                searchCarrierServices();
            }
        }).catch(error => {
            showAlert({
                id: 'timezoneError',
                message: 'There was an unexpected situation loading information, try again later',
                page: '*',
                color: "red"
            });

            console.log('Error', error);
            setIsLoading(false);
        })
    }

    const searchCarrierServices = () => {
        const params = { ship_id: props.idd, reschedule: true };
        getCarrierServices(params).then(response => {
            if (response.status === 200) {
                if (!carriers.length) {
                    let formattedCarriers = response.res.dtos.map((carrier) => ({
                        ...carrier,
                        text: carrier.descr,
                        code: carrier.carrier_code,
                    }));
                    setCarriers(formattedCarriers);
                }
                setSelectedCarrierCode(response.res.dtos[0].carrier_code);
                setInputddlCarrier(response.res.dtos[0].descr);
                setIsLoading(false);
            }
        }).catch(error => {
            showAlert({
                id: 'searchCarrier',
                message: 'There was an unexpected situation loading information, try again later',
                page: '*',
                color: "red"
            });
            console.log('Error', error);
            setIsLoading(false);
        })
    }

    const searchShipmentPackages = async () => {
        await getShipmentPackages({ ship_id: props.id, used_only: true }).then((res) => {
            if (res.res.success) {
                let datos = res.res.dtos;
                setPackages(datos);
            } else {
                setIsLoadingCarriers(true);
            }
        });
    }
    const searchNumPallets = async (id) => {
        await getShipmentDetails({ id }).then(res => {
            let datos = res.res.dtos;
            setNumPallets(datos[0] ? datos[0].equip_pallet_jack : 0);
        }).catch(err => {
            setIsLoadingCarriers(true);
            console.log(err);
        })
    }

    const loadSelectsOptions = () => {
        return getShipCarriers({
            ship_id: props.idd,
            json: JSON.stringify({ packages: packages }),
            num_pallets: numPallets,
            is_fact: false
        }).then(response => {
            if (response && response.res.success) {
                let formattedCarriers = response.res.dtos.map((carrier) => ({
                    ...carrier,
                    text: carrier.descr,
                    code: carrier.carrier_code,
                }));
                setCarriers(formattedCarriers);
                setIsLoadingCarriers(false);
            } else {
                setIsLoadingCarriers(false);
            }
        }).catch(console.log);
    };

    const handleSelectCarrier = (e) => {
        let select = carriers.filter(x => x.code === e);
        let text = select[0] && select[0].text ? select[0].text : '';
        setSelectedCarrierCode(e);
        setInputddlCarrier(text);
    }

    const handleChangeDateBegin = (date, mobile = false) => {
        let newDate = moment(date, mobile ? 'YYYY-MM-DD' : dateFormat).format('YYYYMMDD');
        if (newDate !== "Invalid date" && moment(newDate, 'YYYYMMDD').isSameOrAfter(moment())) {
            if (!_availableDates.length)
                setSelectedDate(newDate);
            else if (moment(date, mobile ? 'YYYY-MM-DD' : dateFormat).isSameOrBefore(moment(_availableDates[_availableDates.length - 1]))) {
                if (!_disabledDates.length)
                    setSelectedDate(newDate);
                else {
                    if (!_disabledDates.some(disabledDate => newDate === moment(disabledDate).format('YYYYMMDD')))
                        setSelectedDate(newDate);
                }
            }
        }
    }

    const saveShipment = () => {
        removeAlerts()
        setIsLoading(true);

        var dates = convertDateFormat(_selectedDate.toString());
        const params = {
            ship_id: props.idd,
            pickup_id: props.data.pickup_id,
            schedule_date: dates,
            carrier_type: 1,
            schedule_time_type: _selectedTime
        }

        postReschedule(params).then(response => {
            if (response.status === 200) {
                if (!response.res.success) {
                    showAlert({
                        id: "postRescheduleSuccess",
                        message: response.res.errors[0].msg,
                        page: '*',
                        color: "red"
                    });
                } else {
                    if (props.onReload) props.onReload({ date: dates, time: _selectedTime });
                    if (_selectedCarrierCode === "DHL-APAC-EXPRESS") {
                        showAlert({
                            id: 'postReschedule',
                            message: (response.res.dtos && response.res.dtos[0].message)? response.res.dtos[0].message: translate('message.rescheduledMsg'),
                            page: '*',
                            color: 'orange'
                        });
                    } else {
                        showAlert({
                            id: 'postReschedule',
                            message: translate('message.rescheduledMsg'),
                            page: '*',
                            color: 'green'
                        });
                    }

                    gtagService.event('Shipments', 'RescheduleShipment');
                }
                setIsLoading(false);
                onClose();
            }
        }).catch(error => {
            showAlert({
                id: 'postReschedule',
                message: error.message,
                page: '*',
                color: 'red'
            });

            setIsLoading(false);
            onClose()
            console.log('Error', error);
        });
    }

    return (
        <POWRModal
            show={show}
            size="small"
            title={translate('button.reschedulePickup')}
            onClose={() => onClose()}
            noContentOverflow
            noBodyOverflow
        >
            <div className="content">
                {!isLoading && !isLoadingCarriers ?
                    <div className="reschedule">
                        <Select
                            value={_selectedCarrierCode}
                            text={_inputddlCarrier}
                            onChange={handleSelectCarrier}
                            options={carriers}
                            label={translate('gridColumns.carrier')}
                            placeholder={translate('formLabel.sCarrier')}
                            singleSelection={true}
                            disabled={props.noChangeCarrier}
                        />
                        <InputCalendar
                            text={_selectedDate !== "" ? moment(_selectedDate).format(dateFormat) : ""}
                            value={_selectedDate !== "" ? moment(_selectedDate).format("DD/MM/YY") : ""}
                            minDate={firstAvailableDay ? firstAvailableDay : _availableDates[0]}
                            maxDate={_availableDates && _availableDates[_availableDates.length - 1]}
                            onBlur={(v, m) => handleChangeDateBegin(v, m)}
                            label={translate('formLabel.dueDate')}
                            placeholder={dateFormat}
                            disabledDays={_serviceDisabledDays.length > 0}
                            disabledDates={_serviceDisabledDays.length ? _serviceDisabledDays : _disabledDates}
                            noPostion
                        />
                        <div>
                            <h3>{translate('title.time')}</h3>
                            <div className="d-flex flex-column">
                                {_avalaibleANY &&
                                    <CheckRadio
                                        id={'time1'}
                                        name="time"
                                        text={translate("scheduleTimeType.any")}
                                        checked={_selectedTime === 0}
                                        onChange={() => setSelectedTime(0)} />
                                }
                                {_avalaibleAM &&
                                    <CheckRadio
                                        id={'time2'}
                                        name="time"
                                        text={translate("scheduleTimeType.am")}
                                        checked={_selectedTime === 1}
                                        onChange={() => setSelectedTime(1)} />
                                }
                                {_avalaiblePM &&
                                    <CheckRadio
                                        id={'time3'}
                                        name="time"
                                        text={translate("scheduleTimeType.pm")}
                                        checked={_selectedTime === 2}
                                        onChange={() => setSelectedTime(2)} />
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Spinner
                        className="ml-40"
                        size="70px"
                        color={'#099AD6'}
                    />
                }
            </div>
            <div className="toolbar" >
                <Button onClick={() => onClose()} type="2" >
                    {translate('button.cancel')}
                </Button>
                <Button
                    disabled={_selectedTime === null || !_selectedDate || isLoading || isLoadingCarriers}
                    onClick={() => saveShipment()}
                >
                    {translate('button.reschedule')}
                </Button>
            </div>
        </POWRModal>
    );
}