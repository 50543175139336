// TODO
export const ADD_TODO = 'ADD_TODO';
export const REMOVE_TODO = 'REMOVE_TODO';

// RMA
export const SET_RMA_INFO = 'SET_RMA_INFO';
export const RESET_RMA_INFO = 'RESET_RMA_INFO';
export const RESET_LABEL_ONLY = 'RESET_LABEL_ONLY';
export const SET_RMA_SEARCH_PARAMETERS = 'SET_RMA_SEARCH_PARAMETERS';
export const SET_RMA_SEARCH_RESULTS = 'SET_RMA_SEARCH_RESULTS';
export const SET_RMA_SEARCHING = 'SET_RMA_SEARCHING';
export const SET_RETURN_ADDRESS = 'SET_RETURN_ADDRESS';
export const SET_GSTIN = 'SET_GSTIN';
export const SET_IS_FA = 'SET_IS_FA';
export const SET_RMAS = 'SET_RMAS';

// RETURN_FLOW
export const SET_RETURN_FLOW_PROP = 'SET_RETURN_FLOW_PROP';
export const SET_DEFAULT_OPTIONS = 'SET_DEFAULT_OPTIONS';
export const SET_SELECTION_ENTITY = 'SET_SELECTION_ENTITY';
export const SWITCH_CONTACTS = 'SWITCH_CONTACTS';
export const COPY_REQUESTOR = 'COPY_REQUESTOR';
export const ADD_ADDRESS_BOOK_ENTITY = 'ADD_ADDRESS_BOOK_ENTITY';
export const UPDATE_ADDRESS_BOOK_ENTITY = 'UPDATE_ADDRESS_BOOK_ENTITY';
export const REMOVE_SELECTION_ENTITY = 'REMOVE_SELECTION_ENTITY';
export const SAVE_SHIPMENT_DATA = 'SAVE_SHIPMENT_DATA';
export const RESET_RETURN_FLOW = 'RESET_RETURN_FLOW';
export const RESET_PARTS = 'RESET_PARTS';
export const SET_PACKAGES = 'SET_PACKAGES';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const SET_SUB_LINES = 'SET_SUB_LINES';
export const SET_SAVED_SUB_LINES = 'SET_SAVED_SUB_LINES';
export const RESET_SUB_LINES_PACKAGES = 'RESET_SUB_LINES_PACKAGES';
export const UPDATE_SUB_LINE = 'UPDATE_SUB_LINE';
export const SET_SCHEDULE_PICKUP = 'SET_SCHEDULE_PICKUP';
export const RESET_PACKAGES = 'RESET_PACKAGES';
export const SET_CREATE_PACKAGES_STEP = 'SET_CREATE_PACKAGES_STEP';
export const COPY_PICKUP_CONTACT = 'COPY_PICKUP_CONTACT';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const SET_LINES_ERROR = 'SET_LINES_ERROR';

// CONTACTS
export const SET_CONTACTS = 'SET_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';

// ADDRESSES
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';

// EVENTS
export const SET_EVENTS = 'SET_EVENTS';
export const ADD_EVENT = 'ADD_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const REMOVE_EVENT = 'REMOVE_EVENT';

// CISCO ENTITIES
export const SET_CISCO_ENTITY = 'SET_CISCO_ENTITY';

// GENERAL
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALERTS = 'REMOVE_ALERTS';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS';
export const SET_LOADER = 'SET_LOADER';
export const CLICK_OUTSIDE_RETURN = 'CLICK_OUTSIDE_RETURN';
export const SET_TYPE_ALERT = 'SET_TYPE_ALERT';
export const REMOVE_ALL_GLOBAL_MESSAGES = 'REMOVE_ALL_GLOBAL_MESSAGES';
export const OPEN_ASIDE_FILTERS = 'OPEN_ASIDE_FILTERS';
export const SET_DOWNLOAD_PARAMS = 'SET_DOWNLOAD_PARAMS';
export const SET_SHIPMENT_EDIT = 'SET_SHIPMENT_EDIT';
export const SET_RMAS_URL_PARAM = 'SET_RMAS_URL_PARAM';


// SHIPMENTS
export const SET_SHIPMENTS = 'SET_SHIPMENTS';
export const SET_SHIPMENTS_PROP = 'SET_SHIPMENTS_PROP';
export const SET_SHIPMENTS_SEARCH_PARAMETERS = 'SET_SHIPMENTS_SEARCH_PARAMETERS';
export const SET_SHIPMENTS_SEARCH_RESULTS = 'SET_SHIPMENTS_SEARCH_RESULTS';
export const SET_SHIPMENTS_SEARCHING = 'SET_SHIPMENTS_SEARCHING';
export const SET_SHIPMENTS_RELOAD = 'SET_SHIPMENTS_RELOAD';
export const SET_SHIPMENTS_SELECTED = 'SET_SHIPMENTS_SELECTED';
export const SET_SHIPMENTS_DETAIL = 'SET_SHIPMENTS_DETAIL';
export const DELETE_SHIPMENT_SEARCH_RESULTS = 'DELETE_SHIPMENT_SEARCH_RESULTS';

//WAYBILL
export const SET_WAYBILL = 'SET_WAYBILL';
export const SET_WAYBILL_SEARCH_PARAMETERS = 'SET_WAYBILL_SEARCH_PARAMETERS';
export const SET_WAYBILL_SEARCH_RESULTS = 'SET_WAYBILL_SEARCH_RESULTS';
export const SET_WAYBILL_SEARCHING = 'SET_WAYBILL_SEARCHING';

// USER SETTINGS
export const SET_USER_SETTINGS_PROP = 'SET_USER_SETTINGS_PROP';
export const RESET_USER_SETTINGS = 'RESET_USER_SETTINGS';
//DOCUMENTATION
export const SET_DOCUMENTATION_INPUT_RMA = 'SET_DOCUMENTATION_INPUT_RMA';
export const SET_DOCUMENTATION_ERROR_MSG = 'SET_DOCUMENTATION_ERROR_MSG';
export const SET_DOCUMENTATION_ERROR = 'SET_DOCUMENTATION_ERROR';
export const SET_DOCUMENTATION_RESULTS = 'SET_DOCUMENTATION_RESULTS';

//RETURN REQUEST FLOW
export const SET_RTF_FLOW_PROP = 'SET_RTF_FLOW_PROP';
export const SET_RTF_DEFAULT_OPTIONS = 'SET_RTF_DEFAULT_OPTIONS';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCTS_QTY = 'UPDATE_PRODUCTS_QTY';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';
export const REMOVE_UNIT = 'REMOVE_UNIT';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const SET_RETURN_REQUEST_DATA = 'SET_RETURN_REQUEST_DATA';
export const SET_RETURN_REQUEST_DATA_COMPLETE = 'SET_RETURN_REQUEST_DATA_COMPLETE';
export const SAVE_RTF_DATA = 'SAVE_RTF_DATA';
export const ADD_MULTIPLE_PRODUCTS = 'ADD_MULTIPLE_PRODUCTS';
export const REMOVE_MULTIPLE_PRODUCTS = 'REMOVE_MULTIPLE_PRODUCTS';
export const UPDATE_EOR = 'UPDATE_EOR';
export const UPDATE_IOR = 'UPDATE_IOR';
