import React from "react";
import styled from "styled-components";
import { CiscoLogo } from "../../shared/CiscoLogo/CiscoLogo.react";
import { Navigation } from "./Navigation.react";
import { useTranslate } from "../../../core/contexts/i18n";

const FooterCopyright = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: CiscoSansTT-Light;
  font-size: 12px;
  line-height: 16px;
  font-style: oblique;
  padding-left: 24px;

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    font-size: 10px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.gray};
    padding-top: 16px;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    padding-left: 0;
  }
`;

const FooterCisco = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    flex-direction: column;
  }
`;

const FooterCiscoLogo = styled(CiscoLogo)`
  height: 24px;
  width: 42px;
  fill: ${({ theme: { colors } }) => colors.white};
`;

export const FooterNavigation = () => {
  const { translate } = useTranslate();

  return (
    <>
      <FooterCisco>
        <FooterCiscoLogo />
        {/* @TODO Current translate implementation do not support Interpolation */}
        <FooterCopyright>
          {translate("footer.copyright", { year: 2020 })} {translate("footer.copyright2")}
        </FooterCopyright>
      </FooterCisco>
      <Navigation />
    </>
  );
};
