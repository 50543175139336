import { ADD_ADDRESS, REMOVE_ADDRESS, SET_ADDRESSES, UPDATE_ADDRESS } from "../actionTypes";

const initialState = {
    addresses: [],
    selectedAddress: null
};

export const addresses = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_ADDRESSES: return { ...state, addresses: payload };
        case ADD_ADDRESS: return { ...state, addresses: [payload, ...state.addresses] };
        case UPDATE_ADDRESS: return { ...state, addresses: state.addresses.map((x) => x.idd === payload.idd ? payload : x) };
        case REMOVE_ADDRESS: return { ...state, addresses: state.addresses.filter(x => x.idd !== payload) };
        default:
            return state;
    }
};
