import React, { useState, useRef } from 'react';
import { useLostFocus } from '../../core/hooks';
import { POWRIcon } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import { PickupOptionsButtons } from '..';
import { setShipmentsReload, setShipmentsSearching } from '../../core/redux/actions';
import { useDispatch } from 'react-redux';

export default ({ item }) => {
    const dispatch = useDispatch();

    const [showOptions, setShowOptions] = useState(false);
    const ref = useRef();
    useLostFocus([ref], () => setShowOptions(false));
    
    return (
        <div className={"dropDown"}>
            <div className="dropDownBtn" onClick={() => setShowOptions(!showOptions)}>
                <POWRIcon className="far fa-ellipsis-h" size="16px" color={COLOR.GREY} />
            </div>
            {showOptions && <div className={showOptions ? "dropDownContent" : "dropDownContentHide"} ref={ref} >
                <PickupOptionsButtons
                    dropdown
                    id={item.id}
                    shipment={item}
                    onReload={(type) => {
                        dispatch(setShipmentsReload({type, item}))
                        dispatch(setShipmentsSearching(true))
                    }}
                />
            </div>}
        </div>
    );
}