import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FooterNavigation } from "../layout/FooterNavigation/FooterNavigation.react";
import { HeaderNavigation } from "../layout/HeaderNavigation/HeaderNavigation.react";
import { SideNavigation } from "../layout/SideNavigation/SideNavigation.react";
import { useSelector } from "react-redux";
import { selectLoader, selectUserInfo } from "../../core/redux/selectors";
import { useConfigParams } from "../../core/hooks";
import { Spinner } from "../controls";
import {
  PageWrapper,
  ContentWrapper,
  MainWrapper,
  FooterWrapper,
  HeaderWrapper,
  SidePanelWrapper,
  WizardPanelWrapper
} from "./partials";
import {
  ReturnTaskBar,
  DownloadSearchBar,
  DocumentationBar,
  ShipmentDetailBar,
  RTFSideBar
} from '../';
import {
  RETURN,
  SEARCH_SHIPMENTS,
  DOCUMENTATION,
  DOWNLOAD,
  SHIPMENT_DETAIL,
  RTFCOMMERCIALINVOICE
} from "../../core/constants/ROUTES";

export const AppWrapperContext = React.createContext({
  isSidePanelOpen: false,
  toggleSidePanel: () => { },
});

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.background};
  width: 100%;
  overflow-x: hidden;

  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7,
  .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2,
  .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto,
  .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4,
  .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1,
  .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
  .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11,
  .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8,
  .col-xl-9, .col-xl-auto {
    padding-right: 8px;
    padding-left: 8px;
  }

  .row {
    margin-right: -8px;
    margin-left: -8px;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    overflow: auto;
  }
`;

export const AppShell = ({ children }) => {
  const history = useHistory();
  const { rtfMenuAvailable } = useConfigParams();
  const [isSidePanelOpen, toggleSidePanel] = useState(false);
  const [pathname, setPathname] = useState('');;
  const [rmaLogin, setRMALogin] = useState(false);
  const [wizardPanel, setWizardPanel] = useState(false);
  const showLoader = useSelector(selectLoader);
  const userInfo = useSelector(selectUserInfo);
  const isRTFMenuAvailable = rtfMenuAvailable();

  useEffect(() => {
    setRMALogin(userInfo ? userInfo.rmaLogin: false);
  }, [userInfo]);

  useEffect(() => {
    setPathname(history.location.pathname)
    return history.listen((location) => {
      setPathname(location.pathname);
    })
  }, [history]);

  useEffect(() => {
    let rutes = [RETURN, SHIPMENT_DETAIL, DOWNLOAD];
    setWizardPanel(
      (pathname === DOCUMENTATION && ((userInfo && userInfo.documentAccessAllowed) || rmaLogin)) ||
      (pathname === RTFCOMMERCIALINVOICE && isRTFMenuAvailable) ||
      (pathname !== SEARCH_SHIPMENTS && rmaLogin) ||
      rutes.includes(pathname)
    );
  }, [pathname, rmaLogin, isRTFMenuAvailable]);

  return (
    <AppWrapperContext.Provider value={{ isSidePanelOpen, toggleSidePanel }}>
      <AppWrapper>

        <HeaderWrapper>
          <HeaderNavigation />
        </HeaderWrapper>

        <PageWrapper wizard={wizardPanel}>
          <ContentWrapper wizard={wizardPanel}>
            <MainWrapper>
              {children}
            </MainWrapper>

          </ContentWrapper>

          {wizardPanel &&
            <WizardPanelWrapper>
              {
                (pathname === DOCUMENTATION && ((userInfo && userInfo.documentAccessAllowed) || rmaLogin)) ? <DocumentationBar /> :
                  pathname === SHIPMENT_DETAIL ? <ShipmentDetailBar /> :
                    (pathname === RETURN || rmaLogin) ? <ReturnTaskBar /> :
                      pathname === DOWNLOAD ? <DownloadSearchBar /> :
                        ((pathname === RTFCOMMERCIALINVOICE) && isRTFMenuAvailable)  ? <RTFSideBar /> : <div />
              }
            </WizardPanelWrapper>
          }

        </PageWrapper>
        <FooterWrapper wizard={wizardPanel}>
          <FooterNavigation />
        </FooterWrapper>

        <SidePanelWrapper>
          <SideNavigation />
        </SidePanelWrapper>
        {showLoader &&
          <div className="overlay-spinner">
            <Spinner
              className="spinner"
              size="100px"
              color={'#099AD6'} />
          </div>
        }
      </AppWrapper>
    </AppWrapperContext.Provider>
  );
};
