import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setShipmentsSearching, setShipmentSeleted, setShipmentsSearchResults, setShipmentsReload,clickOutsideReturn, setShipmentEdit } from '../core/redux/actions';
import { selectShipmentsSearchParameters, selectShipmentsSearching, selectShipmentSeleted, selectShipmentsSearchResults, selectShipmentsReload, selectUserInfo,selectClickOutsideReturn, selectIsShipmentEdit } from '../core/redux/selectors';
import { getShipments } from '../services';
import { useTranslate } from '../core/contexts';
import { RLSPSearchBar, RLSPInfoBar } from '../components';
import { RLSPList } from '../components/shared';
import { Link } from '../components/controls';
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { useGlobalMessage, useShipmentSearch } from '../core/hooks';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showPageMessage } = useGlobalMessage(); // Global Messages
    const { searchRLSP, handleExport } = useShipmentSearch();
    const history = useHistory();
    const searching = useSelector(selectShipmentsSearching);
    const reload = useSelector(selectShipmentsReload);
    const shipment = useSelector(selectShipmentSeleted);
    const data = useSelector(selectShipmentsSearchResults);
    const userInfo = useSelector(selectUserInfo);
    const parameters = useSelector(selectShipmentsSearchParameters);
    const clickOutsideRLSP = useSelector(selectClickOutsideReturn);
    const isShipmentEdit = useSelector(selectIsShipmentEdit);
    const [canceled, setCanceled] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const setSearching = (v) => {
        dispatch(setShipmentsSearching(v))
    }

    //handle change page with unsaved info 
    useEffect(() => {
        if (clickOutsideRLSP) {
            if (!isShipmentEdit) {
                history.push(clickOutsideRLSP)
                dispatch(clickOutsideReturn(''))
            }
        }
    }, [clickOutsideRLSP])

    useEffect(() => {

        dispatch(clickOutsideReturn(''))
        dispatch(setShipmentSeleted({}));
        dispatch(setShipmentsReload(null));
        setSearching(true);
    }, []);

    useEffect(() => {
        if (reload) {
            let remove = reload.type === 'cancel';
            let dat = data;
            var index = dat.indexOf(reload.shipment);
            if (index !== -1) {
                if (remove) {
                    dat = dat.filter(x => x !== reload.shipment);
                    dispatch(setShipmentSeleted({}));
                    setCanceled(true);
                } else {
                    const params = {
                        param_ship_code: reload.shipment.ship_code, param_max_records: 1, param_exclude_saved: 'on'
                    }; 
                    getShipments(params).then(response => {
                        let ship = response.res.dtos[0];
                        dispatch(setShipmentSeleted(ship));   
                        let update = reload.type === 'update';
                        if(update){
                            dat[index].carrier_msg = ship.carrier_msg;
                            dat[index].tns = ship.tns ? ship.tns : [];
                            dat[index].ship_event = ship.ship_event;
                            dat[index].ship_event_time = ship.ship_event_time;
                            dat[index].carrier_msg = ship.carrier_msg;
                            dat[index].agreed_pickup_dt = ship.agreed_pickup_dt;
                            
                    }
                    }).catch(err => {
                        console.log(err);
                    });
                    let newStatus = reload.type === 'reenable';
                    let pickup = reload.type.date ? moment(reload.type.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
                    let time = reload.type.time ? reload.type.time === 1 ? "AM" : "PM" : "ANY";
                    if (newStatus) {
                        dat[index].status = 3;
                        dispatch(setShipmentSeleted(dat[index]));
                    };
                    if (pickup) {
                        dat[index].ship_event = 4;
                        dat[index].date_pickup = pickup;
                        dat[index].pickup_schedule_time = time;
                    }
                }
                dispatch(setShipmentsSearchResults(dat));
            }
            setSearching(false);
            dispatch(setShipmentsReload(null));
        }
    }, [reload]);

    useEffect(() => {
        let params = {
            module:'GCT_SEARCH_SHIPMENTS',
            country:'',
            rmaCategory:''
        }
        showPageMessage(params, '/shipments');
    }, []);

    useEffect(() => {
        if (parameters && searching) {
            setCanceled(false); 
            searchRLSP(parameters, firstLoad);
            setFirstLoad(false)
        }
    }, [parameters, searching]);

    const handleItem = (id, mobile) => {
        let newItems = data.filter(d => d.idd === id);
        dispatch(setShipmentSeleted(newItems.length ? newItems[0] : {}));
        setMobile(mobile)
    }
    
      const getDownloadTempalte = () =>{
        return  '/RLSP_Bulk_Upload_Template.xlsx';
    } 

    const getRLSPQsGuide = () =>{
        return  '/RLSP_POWR3.0_QuickStart_Guide.pdf';
    } 

    return (
        <FullPage>
            <div className="d-flex d-xl-none" >
                <div className="col-12 pb-16 mt-3 mt-sm-0">
                    <h1 className="m-0">{translate('title.rlspEditor')}</h1>
                </div>
            </div>
            {!userInfo.rmaLogin && 
                <div className={shipment.idd && !mobile ? 'd-none' : 'col-12 col-xl-3 p-xl-0'}>
                    <RLSPSearchBar location={props.location} canceled={canceled} rlsp />
                </div>
            }
            <div className={userInfo.rmaLogin && !shipment.idd ? "col-12" : "col-12 col-xl-9 p-xl-0"}>
                <div className="main-shipments">
                    <div className="d-none d-xl-flex head-lg justify-content-between align-items-center">
                        <h1>{translate('title.rlspEditor')}</h1>
                        <div>
                            {userInfo &&
                                <Link to={getRLSPQsGuide} target="_blank" >
                                    {translate('footer.rlspqsguide')}&nbsp;
                                </Link>
                            }
                            {userInfo && userInfo.allow_upload_file_rlsp &&
                                <Link to={getDownloadTempalte} target="_blank" >
                                    |&nbsp;{translate('title.downloadTemplate')}
                                </Link>
                            }
                        </div>
                    </div>
                    {/* <label className="pb-2">{translate('subtitle.carrierToBringLabel')}</label> */}
                    {searching ?
                        <p>{translate('aux.loading')}</p>
                        :
                        <RLSPList
                            full={true}
                            tableId="shipmentsFullTable"
                            items={data}
                            selectedItem={shipment.idd}
                            onClick={handleItem.bind(this)}
                        />
                    }
                </div>
            </div>
            {shipment.idd && <div className="d-none d-sm-flex col-12 col-xl-3 p-xl-0 pt-2 pt-xl-0" >
                <RLSPInfoBar />
            </div>}
        </FullPage>
    );
}
