import React, { Fragment, useState } from "react";
import { Card } from "../../components";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../core/contexts";
import { getValidAvailableDCRMA, getRMA, gtagService } from "../../services";
import { useAlert, useRMAValidation } from "../../core/hooks";
import { DOCUMENTATION } from "../../core/constants/ROUTES";

const Documentation = (props) => {
    const { clearStorage } = props;
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const { isValidRMADashboard } = useRMAValidation();

    const history = useHistory();
    const [error, setError] = useState("");
    const [rma, setRMA] = useState("");

    const handleDocumentation = async () => {
        if (rma.length > 2) {
            setError("");
            gtagService.event('DashboardWidgets', 'DocumentAccess')
            await serviceValidation();
        } else {
            clearStorage();
            setError(translate('error.pleaseMin3'))
        }
    }

    const serviceValidation = async () => {
        const parameters = {
            param_rma_or_tradein: rma,
            param_fa_case_num: "",
            isSimpleSearch: true,
            isReturnFa: false
        };
        return getRMA(parameters).then(response => {
            let rmaValidList = [];

            if (response.res.success) {
                if (response.res.dtos.length) {
                    response.res.dtos.map((rma, i) => {
                        if (isValidRMADashboard({ res: { dtos: [response.res.dtos[i]] } }, false, true).isValid) rmaValidList.push(rma.rma_num[0])
                    });

                    if (rmaValidList.length) {
                        const data = {
                            rmas: rmaValidList.length > 1 ? rmaValidList : rmaValidList[0],
                        };

                        getValidAvailableDCRMA(data).then(response => {

                            let dtos = response.res.dtos;
                            if (dtos.length > 0) {
                                rmaValidList.map((rma) => {
                                    if ((response.res.dtos.filter(x => x.rma_num === rma)).length === 0) {
                                        showAlert({
                                            id: "DCNotAvailable1",
                                            message: `${translate('error.DCNotAvailable1') + rma + translate('error.DCNotAvailable2')}`,
                                            page: "/home"
                                        });
                                    }
                                })
                                history.push({
                                    pathname: DOCUMENTATION,
                                    state: { rma: rmaValidList }
                                })
                            } else {
                                setError(translate('error.DCNotAvailable1') + rmaValidList + translate('error.DCNotAvailable2'));
                            }
                        }).catch(() => {
                            setError(translate('error.rmaNotValidDC1'));
                        });
                    }
                } else {
                    setError('RMA ' + rma + translate('message.wasNotFoundError'));
                    return null;
                }
            }
        }).catch((e) => {
        });
    }

    const documentationAccessTitle =
        <Fragment>{translate('title.documentationAccess')} <small>{translate('subtitle.indiaOnly')}</small></Fragment>;

    return (
        <Fragment>
            <Card
                icon="fal fa-folders"
                title={documentationAccessTitle}
                value={rma}
                setValue={setRMA}
                onClick={handleDocumentation}
                button={translate("button.search")}
                validateRMA={true}
                error={error}
                type="documentation"
            >
                {translate("subtitle.documentationInfo")}
            </Card>
        </Fragment>
    );
};

export default Documentation;