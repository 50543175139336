import { createSelector } from "reselect";
import { EVENT_FREQUENCY_TYPES, EVENT_RESTRICTION_TYPES, RMA_SHIP_EVENT_TYPES } from "../../constants";

const selectEvents = (state) => state.events;
const selectCE = (state) => state.ciscoEntities;

export const selectUserEvents = createSelector(
    [selectEvents, selectCE],
    (eventsState, ce) => eventsState.events.map((ev) => {
        const country = ce.countries.find(x => x.code === ev.country_code);
        const rmaType = ce.rmaTypes.find(x => x.code === ev.rma_type_code);
        const carrier  = ce.eventCarriers.find(x => x.code === ev.carrier_code);

        // WITH TRANSLATIONS
        const eventTypeDesc = RMA_SHIP_EVENT_TYPES.find(x => x.type === Number(ev.event_type));
        const eventFrequencyDesc = EVENT_FREQUENCY_TYPES.find(x => x.type === Number(ev.event_frequency));
        const restrictionTypeDesc = EVENT_RESTRICTION_TYPES.find(x => x.type === Number(ev.restriction_type));

        return {
            ...ev,
            event_type: String(ev.event_type),
            eventTypeDesc: `eventType.${eventTypeDesc ? eventTypeDesc.key : 'unknown'}`,

            event_frequency: String(ev.event_frequency),
            eventFrequencyDesc: `frequencyType.${eventFrequencyDesc ? eventFrequencyDesc.key : 'unknown'}`,

            restriction_type: String(ev.restriction_type),
            restrictionTypeDesc: `restrictionType.${restrictionTypeDesc ? restrictionTypeDesc.key : 'unknown'}`,

            carrier_descr: carrier ? (carrier.text || 'ALL') : 'ALL',
            country_descr: country ? country.descr_code : 'ALL',
            rma_type_descr: rmaType ? (rmaType.descr || 'ALL') : 'ALL'
        };
    })
);

export const selectSelectedEvent = createSelector(
    selectEvents,
    (eventsState) => eventsState.events.find(ev => ev.idd === eventsState.selectedEvent)
);