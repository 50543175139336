import { useCallback } from "react";
import { COUNTRY, REGEXP } from "../constants";
import { useTranslate } from "../contexts";

export const useContactValidation = () => {
    const { translate } = useTranslate();

    const getContactErrors = useCallback((contact, country) => {
        const errors = [];

        let usca = country === COUNTRY.US ||country === COUNTRY.CANADA;
        let rPhone = usca ? REGEXP.PHONE_USCA : REGEXP.PHONE
        if (!contact.fname) errors.push(translate('error.fnameMissing'));
        if (!contact.lname) errors.push(translate('error.lnameMissing'));
        if (!contact.phone || !rPhone.test(contact.phone)) errors.push(translate('error.phoneMissing'));
        if (!contact.email || !REGEXP.EMAIL.test(contact.email.toLowerCase())) errors.push(translate('error.emailMissing'));

        return errors;
    }, [translate]);

    return {
        getContactErrors
    };
};