
export const CONTACT_TYPE = {
    UNKNOWN: 0,
    PROFILE: 1,
    REQUESTOR: 2,
    ONSITE: 3,
    LOCAL: 4
};

export const CONTACT_TYPES = [
    {
        type: 0,
        key: 'unknown'
    },
    {
        type: 1,
        key: 'profile'
    },
    {
        type: 2,
        key: 'requestor'
    },
    {
        type: 3,
        key: 'pickup'
    },
    {
        type: 4,
        key: 'alternate'
    }
];
