export const FORM_TYPE = {
    COMMERCIAL_INVOICE: 'commercialInvoice',
    PACKING_LIST: 'packingList',
    SLI: 'sli',
    FAULT_DETECTION: 'fdf',
    ACT_OF_EXCHANGE: 'PocUkrainian',
    VIEW_RECEIPT: 'shipmentReceipt',
    ZIP: 'zip', 
    RTF: 'rtf',
    PACKING_LIST_FR: 'packingListFR',
    COMMERCIAL_INVOICE_CH: 'commercialInvoiceCH',
}