import React, { Fragment } from 'react';
import { useTranslate } from '../../core/contexts';
import { RMAPaymentType, RMACategoryType } from '../../core/constants/RMAStatus';
import { useAlert } from './useAlert';
import { useSelector } from 'react-redux';
import { Link } from '../../components/controls';
import { selectUserInfo } from '../redux/selectors';
import { ROLES } from '../constants';
import { useConfigParams } from './useConfigParams';

//This hook validate all the possibles stopers to start the return flow with the RMA entered.
export const useRMAValidation = () => {
    const showPaidByUserAler = false;
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const { getFaqLink, getContactUsLink } = useConfigParams();

    const userInfo = useSelector(selectUserInfo);

    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();
    //Return the alert and if the rma´s are valid or not
    const sendAlert = (id, message, isValid, color, rmas, page = '*') => {
        showAlert({
            id: id + Date.now,
            message,
            page: page,
            color: color,
        });
        return { isValid: isValid, rmas: rmas && rmas.length > 0 ? rmas : [] }
    }


    //valid rmas from dashboard page, documentation and searchCard
    const isValidRMADashboard = (res, isFA, isDA) => {
        let rmaInfo = res.res.dtos;
        let warn = res.res.warn;

        //Validate different payment type in rmas when the search return more than one result
        if (res.res.dtos.length > 1) {
            if (res.res.dtos.filter(a => a.payment_type[0] !== res.res.dtos[0].payment_type[0]).length > 0)
                return sendAlert(
                    "paymentCustomer",
                    translate('message.nonMatchingRmaPayTypeMsgDashboard'),
                    false,
                    "yellow");

        }
        //validate addresses for FACT return
        if (res.res.rmaFaAddressNotAllowed && isFA)
            return sendAlert(
                "countryNotAllowed",
                <Fragment>
                    {translate('message.rmaFaAddressNotAllowed')}
                </Fragment>,
                false);
        //Validate country for FACT return
        if (res.res.countryNotAllowed && isFA)
            return sendAlert(
                "countryNotAllowed",
                <Fragment>
                    {translate('message.countryNotAllowed')}
                </Fragment>,
                false);


        //rma Country not enabled
        if (warn)
            return sendAlert("notEnabledCountry",
                <Fragment>
                    {
                        translate('message.countryRMAError1')}
                    <a href={contactUsLink} className="link" target="_blank"
                        rel="noopener noreferrer">
                        {translate('message.assetRecoveryAgent')}</a>
                    {translate('message.countryRMAError2')} ({warn})
                     </Fragment>,
                false,
                "yellow");

        //rma not found: validate RMA does not exist, this validation is also in UI
        if (rmaInfo.length <= 0)
            return sendAlert("rmaNotExist", <Fragment>{'RMA ' + translate('message.wasNotFound')} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></Fragment>, false);

        //Category not exist: find if the RMAs has different category code than S or P
        if (rmaInfo.filter(a => a.rma_category_code[0] !== "S" && a.rma_category_code[0] !== "P").length)
            return sendAlert("catNotExist", <Fragment>category not exist</Fragment>, false);

         //Validate RMA entityTypes

       if(hasDifferentRMACategoryCode(rmaInfo)) {
            return false;
        }

        //category empty: validate if the category field is empty for some of the rmas
        if (rmaInfo.filter(a => a.rma_category_code === "").length)
            return sendAlert("emptyCat", <Fragment>category empty</Fragment>, false);

        if (rmaInfo.length === 1)
            return validSingleRMA(rmaInfo[0], isDA );

        else {
            return validMultipleRMA(res, isDA);            
        }
    }

    //valid rmas from dashboard page, documentation and searchCard
    const isValidRMADashboard2 = (res, isFA, isrmalogin,isDA) => {
        let rmaInfo = res.res.dtos;
        let warn = res.res.warn;

        //Validate different payment type in rmas when the search return more than one result
        if (res.res.dtos.length > 1) {
            if (res.res.dtos.filter(a => a.payment_type[0] !== res.res.dtos[0].payment_type[0]).length > 0)
                return sendAlert(
                    "paymentCustomer",
                    translate('message.nonMatchingRmaPayTypeMsgDashboard'),
                    false,
                    "yellow");

        }
        //validate addresses for FACT return
        if (res.res.rmaFaAddressNotAllowed && isFA)
            return sendAlert(
                "countryNotAllowed",
                <Fragment>
                    {translate('message.rmaFaAddressNotAllowed')}
                </Fragment>,
                false);
        //Validate country for FACT return
        if (res.res.countryNotAllowed && isFA)
            return sendAlert(
                "countryNotAllowed",
                <Fragment>
                    {translate('message.countryNotAllowed')}
                </Fragment>,
                false);


        //rma Country not enabled
        if (warn)
            return sendAlert("notEnabledCountry",
                <Fragment>
                    {
                        translate('message.countryRMAError1')}
                    <a href={contactUsLink} className="link" target="_blank"
                        rel="noopener noreferrer">
                        {translate('message.assetRecoveryAgent')}</a>
                    {translate('message.countryRMAError2')} ({warn})
                         </Fragment>,
                false,
                "yellow");

        //rma not found: validate RMA does not exist, this validation is also in UI
        if (rmaInfo.length <= 0)
            return sendAlert("rmaNotExist", <Fragment>{'RMA ' + translate('message.wasNotFound')} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></Fragment>, false);

        //Category not exist: find if the RMAs has different category code than S or P
        if (rmaInfo.filter(a => a.rma_category_code[0] !== "S" && a.rma_category_code[0] !== "P").length)
            return sendAlert("catNotExist", <Fragment>category not exist</Fragment>, false);

        //category empty: validate if the category field is empty for some of the rmas
        if (rmaInfo.filter(a => a.rma_category_code === "").length)
            return sendAlert("emptyCat", <Fragment>category empty</Fragment>, false);

        if (!isFA && isrmalogin) {
            if (rmaInfo[0].payment_type[0] === RMAPaymentType[0].id)
                return sendAlert(
                    "customerCarrierAccount1",
                    <Fragment>
                        {translate('message.customerCarrierAccount1')}
                        <a href={contactUsLink} className="link" target="_blank"
                            rel="noopener noreferrer">{translate('title.contact')}</a>
                        {translate('message.customerCarrierAccount2')}
                    </Fragment>,
                    true,
                    "yellow");
        }

        if (rmaInfo.length === 1)
            return validSingleRMA(rmaInfo[0], isDA);

        else {
            return validMultipleRMA(res, isDA);
        }
    }

    //Valid a single rma dashboard and list page
    const validSingleRMA = (rma, isDA) => {
        //Validate if the rma has no open lines or closed
        if (!(isDA && (userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT)) && String(rma.return_state[0]) === '2')
            return sendAlert(
                "noOpenLines",
                <Fragment>{translate('message.rmaMsg')} {rma.rma_num[0]} {translate('message.noLines')} {translate('message.faqOrAssetRecovery1')} <Link target="_blank" href={faqLink} >{translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} >
                    {translate("message.assetRecoveryAgent")}</Link>
                </Fragment>,
                false, userInfo.rmaLogin ? 'red' : '');

        //Warnings: soft stop, just send a alert but allow to continue with the return flow
        //reminder if the customer has to pay for this shipment
        // if (rma.payment_type == RMAPaymentType[0].id)
        //     return sendAlert(
        //         "paymentCustomer",
        //         <Fragment>{translate('message.customerCarrierAccount1')}
        //             <a href={contactUsLink} className="link" target="_blank">{translate('title.contact')}</a>
        //             {translate('message.customerCarrierAccount2')
        //             }</Fragment>,
        //         true,
        //         "yellow");

        //remind if shipment has more than 1000 products to send
        // if (rma.total_ordered_qty > TOTAL_QTY_ORDERED)
        //     return sendAlert(<Fragment>{`${translate('title.rma')} ${rma.rma_num[0]} ${translate('message.has')} ${rma.total_ordered_qty} ${translate('message.parts')}. ${translate('message.bigShipmentValidation')}`} </Fragment>, true, "yellow");

        return { isValid: true, rmas: [] };
    }

    //valid multiple RMA for dashboard page
    const validMultipleRMA = (res, isDA) => {
        //Multiple rmas: start validations for multiple RMAs.
        if (res.res.rmaTypesCombinedNotAllowed)
            return sendAlert(
                "combinedTypes",
                <Fragment>
                    {translate('message.rmaTypesCombinedNotAllowed') + res.res.rmaTypesCombinedNotAllowed}
                </Fragment>,
                false);

        let noOpenLines = res.res.dtos.filter(a => String(a.return_state[0]) === '2');
        if (!(isDA && (userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT)) && noOpenLines.length)
            return sendAlert(
                "noOpenLinesMultiple",
                <Fragment>
                    {translate('message.rmaMsg')}
                    {noOpenLines[0].rma_num[0]} {translate('message.noLines')}
                    {translate('message.faqOrAssetRecovery1')} <Link target="_blank" href={faqLink} >{translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} >
                    {translate("message.assetRecoveryAgent")}</Link>
                </Fragment>,
                false, userInfo.rmaLogin ? 'red' : '');

                

        return { isValid: true, rmas: [] };
    }

    const isValidRMAList = (res, currentRMA, isSingleCheck, isFA) => {
        let rmas = res.res.dtos;
        let warn = res.res.warn;
        //dispatch(removeAllAlerts())

        // let noOpenLines = res.res.dtos.filter(a => a.return_state[0] == 2);
        // if (noOpenLines.filter(a => a.return_state[0] == 2).length)
        //     return sendAlert(<Fragment>{translate('message.rmaMsg')} {noOpenLines[0].rma_num[0]} {translate('message.noLines')} {translate('message.faqOrAssetRecovery1')} <a href={faqLink} className="link" target="_blank">{translate('message.faq')}</a> {translate('message.faqOrAssetRecovery2')} </Fragment>, false);


        let selectRMAsCategory = [];
        let selectRMAsPayment = [];

        let current = rmas.find(a => a.rma_num.join(',').includes(currentRMA));

        selectRMAsCategory.push(current.rma_num[0]);
        selectRMAsPayment.push(current.rma_num[0]);

        for (let index = 0; index < rmas.length; index++) {
            const item = rmas[index];

            if (item.rma_category_code[0] === current.rma_category_code[0] && item.idd !== current.idd) {
                selectRMAsCategory.push(item.rma_num[0])
                if (item.payment_type[0] === current.payment_type[0])
                    selectRMAsPayment.push(item.rma_num[0])

            }
        }

        let last = isSingleCheck ? current : rmas.find(a => a.idd !== current.idd);
        let currentMsg = isSingleCheck ? rmas.find(a => a.idd !== current.idd) : current;
        if (selectRMAsCategory.length !== rmas.length)
            return sendAlert(
                "nonMatchingRMACatMsg",
                <Fragment>{`${translate('message.nonMatchingRMACatMsg')} ${translate('message.shipmentCategory')} 
                ${translate(RMACategoryType.find(a => a.id === currentMsg.rma_category_code[0]).text)} 
                ${translate('message.nonMatchingRMACatMsg2')} 
                ${translate(RMACategoryType.find(a => a.id === last.rma_category_code[0]).text)}`
                }</Fragment>,
                false,
                null,
                selectRMAsPayment);

        if (selectRMAsPayment.length !== rmas.length)
            return sendAlert(
                "nonMatchingRmaPayTypeMsg",
                <Fragment>
                    {`${translate('message.nonMatchingRmaPayTypeMsg')} 
                    ${translate('message.shipmentType')} 
                    ${translate(RMAPaymentType[currentMsg.payment_type[0] - 1].text)} 
                    ${translate('message.nonMatchingRmaPayTypeMsg2')} 
                    ${translate(RMAPaymentType[last.payment_type[0] - 1].text)}`
                    }</Fragment>,
                false,
                null,
                selectRMAsPayment);

        //Warnings: soft stop, just send a alert but allow to continue with the return flow
       
        if(!isFA){
            if (current.payment_type == RMAPaymentType[0].id)
            return sendAlert(
                "customerCarrierAccount1",
                <Fragment>
                    {translate('message.customerCarrierAccount1')}
                    <a href={contactUsLink} className="link" target="_blank" rel="noopener noreferrer">{translate('title.contact')}</a>
                    {translate('message.customerCarrierAccount2')}
                </Fragment>,
                true,
                "yellow");
        }

        if(isFA && showPaidByUserAler){
            if (current.payment_type == RMAPaymentType[0].id)
            return sendAlert(
                "customerCarrierAccount1",
                <Fragment>
                    {translate('message.customerCarrierAccount1')}
                    <a href={contactUsLink} className="link" target="_blank" rel="noopener noreferrer">{translate('title.contact')}</a>
                    {translate('message.customerCarrierAccount2')}
                </Fragment>,
                true,
                "yellow");
        }
       

        //remind if shipment has more than 1000 products to send
        let total_ordered = 0;
        let rmasNumber = "";
        res.res.dtos.forEach(item => {
            total_ordered += item.total_ordered_qty;
            rmas += item.rma_num[0] + ","
        });
        if (total_ordered > 500)
            return sendAlert(
                "bigOrder",
                <Fragment>{
                    `${translate('title.rma')} 
                    ${rmasNumber.substring(0, rmasNumber.length - 1)} 
                    ${translate('message.has')} ${total_ordered} 
                    ${translate('message.parts')}. 
                    ${translate('message.bigShipmentValidation')}`
                } </Fragment>,
                true,
                "yellow");

        //validate addresses for FACT return
        if (res.res.rmaTypesCombinedNotAllowed)
            return sendAlert(
                "simpleTypeNotAllowed",
                <Fragment>
                    {translate('message.rmaTypesCombinedNotAllowed') + res.res.rmaTypesCombinedNotAllowed}
                </Fragment>,
                false);

        //validate country for FACT return
        if (res.res.rmaFaAddressNotAllowed && isFA)
            return sendAlert(
                "countryNotAllowed",
                <Fragment>
                    {translate('message.rmaFaAddressNotAllowed')}
                </Fragment>,
                false);

        if (res.res.countryNotAllowed && isFA)
            return sendAlert(
                "countryNotAllowed",
                <Fragment>
                    {translate('message.countryNotAllowed')}
                </Fragment>,
                false);




        return { isValid: true, rmas: [] };
    }

    function hasDifferentRMACategoryCode(rmas) {
        if (rmas.length === 0) return false;
    
        const firstCategoryCode = rmas[0].rma_category_code ? rmas[0].rma_category_code[0] : null;
    
        for (let index = 1; index < rmas.length; index++) {
            const currentCategoryCode = rmas[index].rma_category_code ? rmas[index].rma_category_code[0] : null;
            if (currentCategoryCode !== firstCategoryCode) {
                sendAlert(
                    "nonMatchingRMACatMsg",
                    <Fragment>{`${translate('message.nonMatchingRMACatMsg')} ${translate('message.shipmentCategory')} 
                    ${translate(RMACategoryType.find(a => a.id === currentCategoryCode).text)}`
                    }</Fragment>,
                    false,
                    null,
                    null);
                    return true;
            }
        }
        return false;
    }

    return {
        isValidRMADashboard,
        isValidRMADashboard2,
        isValidRMAList
    };
};


