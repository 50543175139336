
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ContactCard from "../components/contactCard";
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { useTranslate } from '../core/contexts';
import { POWRButton, POWRIcon, POWRModal, InfoSection } from '../components/styledComponents';
import { PickupLocationForm } from "../components/forms";
import { COLOR, REGEXP } from '../core/constants';
import { Input, Link, TableRTF } from '../components/controls';
import { selectRTFFlowProducts, selectRTFFlowEOR, selectRTFFlowIOR } from '../core/redux/selectors';
import {
    removeRequestReturnProduct, removeRTFUnit, setRequestReturnFlowProp, updateRTFProduct,
    updateRTFUnit, updateRTFProductsQty, setRTFFlowProducts, loadCountries, updateEOR, updateIOR,
    setRTFFlowDefaultOptions
} from '../core/redux/actions';
import { useAlert, useRMAValidation, useGlobalMessage, useConfigParams } from "../core/hooks";
import { getExportOfRecordsRTF, getRMA, getRTFLines, isRTF } from '../services';
import {RTFTEST} from '../core/constants/RTFTEST';
import { useDispatch, useSelector } from 'react-redux';
import { RTFCOMMERCIALINVOICE } from '../core/constants/ROUTES';
import { BASE_URL } from '../core/constants/URLS';

export default props => {

    const dispatch = useDispatch();
    const { showAlert, removeAlerts, removeAlert } = useAlert();
    const { translate } = useTranslate();
    const { getFaqLink, getContactUsLink } = useConfigParams();
    const [inputRMA, setInputRMA] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [searched, setSearched] = useState('');
    const [results, setResults] = useState(false);
    const [exportRecords, setExportRecords] = useState(null);
    const [importRecords, setImportRecords] = useState(null);
    const [showPickupAddressModal, setShowPickupAddressModal] = useState(false);
    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();
    const products = useSelector(selectRTFFlowProducts);
    const eor = useSelector(selectRTFFlowEOR);
    const ior = useSelector(selectRTFFlowIOR);

    useEffect(() => {
        if (products && products.length === 0) {
            setResults(false);
            setSearched('')
        }
    }, [products, dispatch]) 

    useEffect(() => {
        if (ior && Object.keys(ior).length > 0) {
            const ad = ior;
            let info = [];
            for (let i = 0; i <= 4; i++) if (ad[`addr${i + 1}`]) info.push(ad[`addr${i + 1}`]);
            
            setImportRecords({
                title: (ad ? (ad.atag || ad.name) : ''),
                info: [
                    (ad ? (ad.atag || ad.name) : ''),
                    ...info,
                    (ad ? `${ad.city ? ad.city + ', ' : ''}${ad.province ? ad.province + ', ' : ''}${ad.postal ? (ad.postal + ' ') : ''}${ad.country || ''}` : '')
                ]
            });
        }
        else setImportRecords(null);

    }, [ior]);

    useEffect(() => {
        if (eor && Object.keys(eor).length > 0) {
            const ad = eor;
            let info = [];
            for (let i = 0; i <= 4; i++) if (ad[`addr${i + 1}`]) info.push(ad[`addr${i + 1}`]);
            
            setExportRecords({
                title: (ad ? (ad.atag || ad.name) : ''),
                info: [
                    (ad ? (ad.atag || ad.name) : ''),
                    ...info,
                    (ad ? `${ad.city ? ad.city + ', ' : ''}${ad.province ? ad.province + ', ' : ''}${ad.postal ? (ad.postal + ' ') : ''}${ad.country || ''}` : '')
                ]
            });
        }
        else setExportRecords(null);

    }, [eor]);

    const handleChange = (e) => {
        if (REGEXP.RMAS.test(e.target.value)) {
            setInputRMA(e.target.value);
        }
    }

    const saveEOR = (data) => {
        dispatch(updateEOR({ ...data, atag: data.name }));
    }
    
    const handleSearch = (input) => {
        let rma = input ? input : inputRMA;
        setSearched(rma);
        if (typeof rma === 'string') {
            if (rma.length >= 3) {
                setError(false);
                setErrorMsg('');
                serviceValidation(rma);
            } else {
                setError(true);
                setResults(false);
                setErrorMsg(translate('error.min'));
            }
        } else {
            setError(false);
            setErrorMsg('');
            if (input) serviceValidation(rma);
            else serviceValidation();
        }
    }
    const serviceValidation = (input) => {
        // RTC VALIDATION
        isRTF(input).then(async (response) => {
            if (response.res.dtos.length === 0) {
                // showAlert({
                //     id: 'rtfMsg',
                //     page: RTFCOMMERCIALINVOICE,
                //     message: `${translate(`RMA ${input} is not a valid RTF`)}`,
                //     color: "yellow"
                // });
                setError(true);
                setResults(false);
                setErrorMsg(translate('error.rmaError'));
                return;
            }
            else {
                setResults(false);
                dispatch(setRTFFlowDefaultOptions());
                removeAlerts();
                
              
                
                // save reference number
                let date = moment.utc().valueOf().toString().slice(-8);
                let refNumber = (response.res.dtos[0].country ? response.res.dtos[0].country[0] : '') + date;
                dispatch(setRequestReturnFlowProp('rma', input));
                dispatch(setRequestReturnFlowProp('refNumber', refNumber));

                //IMPORT RECORDS
                const ior = response.res.dtos[0]?.return_address || {};
                dispatch(updateIOR(ior))

                // getting EOR
                await getExportOfRecordsRTF(input).then((response) => {
                    if (response.res.dtos.length >= 0) {
                        const eor = response.res.dtos[0];

                        dispatch(updateEOR({
                            acode: eor.cust_acode || '',
                            atag: eor.cust_atag || eor.cust_name,
                            save_as: eor.cust_name,
                            name: eor.cust_name,
                            addr1: eor.cust_addr1,
                            addr2: eor.cust_addr2,
                            addr3: eor.cust_addr3,
                            addr4: eor.cust_addr4,
                            attention: eor.cust_attention,
                            city: eor.cust_city,
                            province: eor.cust_province,
                            postal: eor.cust_postal,
                            country: eor.cust_country,
                        }))

               
                    }
                }).catch((e) => {
                });


                // getting RTF LINES
                getRTFLines(input).then((response) => {
                    if (!response.res.success || response.res.dtos.length <= 0) {
                        showAlert({
                            id: 'rtfMsg',
                            page: RTFCOMMERCIALINVOICE,
                            message: `${translate('message.mandatoryFieldsPrincipal')}`,
                            color: "yellow"
                        });
                        return;
                    }
                    else {
                        const sublines = response.res.dtos;
                        sublines.forEach(element => {
                            setData(element)
                        });
                        setResults(true);
                        return;
                    }

                })
            }

        }).catch((e) => {
        });



    }

    const setData = (data) => { 
        let arrayItems = []
        for (let i = 0; i < data.orig_ordered_qty; i++) arrayItems.push({
            id: i + 1,
            // crpRequestHeaderId: i + 1,
            idProd: data.idd,
            name: `Unit ${i + 1}`,
            partNum: i + 1,
            sn: data.cust_sn ? data.cust_sn.split(',')[i] || '' : '',
            serialNum: data.cust_sn,
            type: 'unit',
            partDescription: data.part_descr,
            partNumRef: data.part_num,
            partWeight: data.part_weight,
            partWeightUom: data.part_weight_uom,
            ciscoLineId: data.cisco_line_id,
            reqDate: data.request_date,
            returnQty: data.ordered_qty,
        })
        const product = {
            id: data.idd,
            idd: data.idd,
            name: data.part_num,
            qty: data.orig_ordered_qty,
            units: arrayItems,
            //weight: props.weight || '',
            partWeightUom: data.part_weight_uom,
            sn: data.cust_sn,
            serialNum: data.cust_sn, 
            //urlLarge: props.rich_media_urls?.large_image_url || '',
            //urlSmall: props.rich_media_urls?.small_image_url || '',
            // image: props.rich_media_urls?.small_image_url ? props.rich_media_urls?.small_image_url :
            //     props.rich_media_urls?.large_image_url ? props.rich_media_urls?.large_image_url :
            //         CiscoLogo
        }
        
        dispatch(setRTFFlowProducts(product))
    }

    const removeUnit = (id, idUnit) => {
        const product = products.find(x => x.id === id)
        if (product.units.length === 1) {
            dispatch(removeRequestReturnProduct({ id }))
        } else
            dispatch(removeRTFUnit({ id, idUnit }))
    }

    const removeItem = (id) => {
        dispatch(removeRequestReturnProduct({ id }))
    }

    const onChangeSN = (id, value, idUnit,) => {
       
        
        if (value || value >= 0) {
            if (!idUnit) {
                dispatch(updateRTFProduct({ id, property: 'serialNum', value }));
                dispatch(updateRTFProduct({ id, property: 'sn', value }));
                if (value) {
                    updateItem(id, 1, value)
                }
            }
            else {
                const prod = products.find(x => x.id === idUnit)
                if (prod?.units.length === 1 && !!value) {
                    dispatch(updateRTFProduct({ id: idUnit, property: 'serialNum', value }));
                    dispatch(updateRTFProduct({ id: idUnit, property: 'sn', value }));
                    //dispatch(updateRTFUnit({ id, idUnit, property: 'serialNum', value }));
                    dispatch(updateRTFUnit({ id, idUnit, property: 'sn', value }));
                }
                else if (prod?.units.length === 1 && !!!value) {
                    //NO UPDATE
                }
                else {
                    dispatch(updateRTFProduct({ id: idUnit, property: 'sn', value:"" }));
                    //dispatch(updateRTFProduct({ id: idUnit, property: 'sn', value }));
                    dispatch(updateRTFUnit({ id, idUnit, property: 'sn', value }));
                }
            }

        }
    }

    const updateItem = (id, value, sn = '', units = []) => {
        let arrayItems = []
        for (let i = 0; i < Number(value); i++) arrayItems.push({
            id: i + 1,
            crpRequestHeaderId: i + 1,
            idProd: id,
            name: `Unit ${i + 1}`,
            partNum: i + 1,
            sn: sn || (units[i] && units[i].sn) || '',
            serialNum: sn || (units[i] && units[i].sn) || '',
            type: 'unit',
        })
        dispatch(updateRTFProductsQty({ id, property: 'qty', value, units: arrayItems }));
    }

    const events = [
        {
            key: 'name',
            key2: 'pid',
            tag: 'products',
            template: 'boldImage',
            headerClasses: 'justify-content-center',
            hidden: true
        },
        {
            key: 'qty',
            tag: 'qty',
            template: 'bold',
            hidden: true,
            editable: true
        },
        {
            key: 'sn',
            tag: 'serialNumber',
            hasValue: true,
            placeholder: translate('gridColumns.serialNumber'),
            template: 'selectSN', // withTranslation
            hidden: true,
            onChange: onChangeSN,
            disabled:false 
        }
    ]

    return (
        <FullPage>
            <div className='main-documentation'>
                <div className='d-md-flex justify-content-between'>
                    <div>
                        <h1>{translate("title.RTFCI")}</h1>
                        <p className="subhead-documentation">{translate("message.rtfDesc")}</p>
                    </div>
                    <Link className={"download-link"} href={`${BASE_URL}/RTF-guide.pdf`} rel="noopener noreferrer" target="_blank">
                        {translate('title.qsRTF')}
                    </Link> 
                </div>
            <div className='body-documentation'>
                    <div className="search-documentation">
                        <Input
                            label={translate('title.rma')}
                            name="inputRMA"
                            placeholder={translate('title.rma')}
                            value={inputRMA}
                            onChange={handleChange}
                            onEnter={() => handleSearch()}
                            icon={{ content: "search", width: "23px", height: "16px", color: COLOR.DARK_GREY_1 }}
                            error={error}
                            errorMessage={errorMsg ? errorMsg : translate('error.rmaError')}
                        />
                        <POWRButton className="ml-3" onClick={() => handleSearch()} color="secondary">
                            {translate('button.search')}
                        </POWRButton>

                    </div>
                    {results &&
                        <div className='container-fluid p-0'>
                            <div className="row mb-4">
                                <div className="col-12 col-lg-6 pr-lg-8">
                                    <InfoSection
                                        className="full-height"
                                        title={translate('title.exportRecords')}
                                        type={'cards'}
                                        isPickUpCard
                                        buttonText={translate('button.editInfo')}
                                        onButtonClick={() => setShowPickupAddressModal(true)}
                                    >
                                        <ContactCard
                                            fullHeight
                                            icon="map-marker-alt"
                                            content={exportRecords}
                                            hideButtons
                                            isPickupCard
                                        />
                                    </InfoSection>
                                </div>

                                <div className="col-12 col-lg-6 pr-lg-8">
                                    <InfoSection
                                        className="full-height"
                                        title={translate('title.importRecords')}
                                        type={'cards'}
                                        isPickUpCard
                                    >
                                        <ContactCard
                                            fullHeight
                                            icon="map-marker-alt"
                                            content={importRecords}
                                            isPickupCard
                                            hideButtons
                                        />
                                    </InfoSection>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='table-rtf' key={`documentation-result-${results}`}>
                    {
                        results ?
                                <div className="download-documentation-rtf">
                                    <TableRTF type='collapseCRP' columns={events} dataSource={products.length >= 0 ? products : RTFTEST} itemsPerPage={50} actions={{
                                        removeUnit,
                                        removeItem,
                                        updateItem,
                                        onChangeSN
                                    }}
                                    currentStep={2} />
                            </div>
                            :
                            (
                                searched ?
                                    <div className="no-results">
                                        <POWRIcon
                                            className="fal fa-search icon-card"
                                            size="70px"
                                            color={COLOR.GREY}
                                        />
                                        <p className="text-center">{translate('message.rmawasNotFound').replace('{0}', searched)} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></p>
                                    </div>
                                    :
                                    <div className="no-results">
                                        <POWRIcon
                                            className="fal fa-search icon-card"
                                            size="70px"
                                            color={COLOR.GREY}
                                        />
                                        <p className="text-center">{translate('message.noRMAdetected')} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></p>
                                    </div>
                            )
                    }
                </div>
                </div>
                <POWRModal
                    show={showPickupAddressModal}
                    title={translate(`title.exportRecords`)}
                    onClose={() => { setShowPickupAddressModal(false); removeAlerts() }}>
                    <PickupLocationForm
                        data={eor}
                        onClose={() => { setShowPickupAddressModal(false); removeAlerts() }}
                        saveData={saveEOR}
                        isRTF
                    />


                </POWRModal>
            </div>
        </FullPage>
    );
}
