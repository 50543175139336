import React from "react";
import styled from "styled-components";
import CiscoLogo from '../../../static/icons/cisco-blue.svg';
import CiscoLogoWhite from '../../../static/icons/cisco-white.svg';
import POWRLogo from '../../../static/icons/POWR_logo.svg';
import POWRLogoWhite from '../../../static/icons/POWR_logo_white.svg';

// @TODO Inject Theme variables and calculations for easier changes
const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderLeft = styled(HeaderBlock)`
  align-items: center;
  height: 64px;
  gap: ${({ theme: { gap } }) => gap[1]};
  padding: 0 0 0 32px;

  .ciscoLogo {
    max-width: 52px;
  }

  .line {
    box-sizing: border-box;
    height: 34px;
    width: 1px;
    border-right: 1px solid #099AD6;
    opacity: 0.25;
    margin: 0 1rem
  }

  .powrLogo {
    max-width: 82px;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    justify-content: center;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    gap: 0;
    padding: 0;
    width: 100%;
  }
`;

const HeaderRight = styled(HeaderBlock)`
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderPanel = styled.header`
  display: flex;
  position: fixed;
  justify-content: space-between;
  height: 64px;
  max-height: 64px;
  width: calc(100% - 80px);
  margin-left: 80px;
  z-index: 2;

  svg {
    transition: fill ${({ theme: { time } }) => time[0]};
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    svg {
      fill: ${({ theme: { colors } }) => colors.white};
    }
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    position: fixed;
    width: calc(100vw - 80px);
  }

  box-shadow: 0 0 4px 0 #00000010;

  // @TODO Implement Theme provider
  transition: background-color ${({ theme: { time } }) => time[0]};
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px ${({ theme: { colors } }) => colors.lightGray} solid;

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    background-color: ${({ theme: { colors } }) => colors.ciscoBlue};
    border-bottom: unset;
  }
`;

export const HeaderWrapper = ({ children }) => {
  return (
    <HeaderPanel>
      <HeaderLeft>
        <img className="ciscoLogo d-none d-lg-flex" src={CiscoLogo} />
        <img className="ciscoLogo d-flex d-lg-none" src={CiscoLogoWhite} />
        <div className="line" />
        <img className="powrLogo d-none d-lg-flex" src={POWRLogo} />
        <img className="powrLogo d-flex d-lg-none" src={POWRLogoWhite} />
      </HeaderLeft>
      <HeaderRight>{children}</HeaderRight>
    </HeaderPanel>
  );
};
