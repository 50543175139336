import React, { useEffect, useState, useRef, Fragment } from 'react';
import { ContactCard } from './index';
import { InfoSection, POWRButton, POWRIcon, POWRModal } from './styledComponents';
import { useTranslate } from '../core/contexts';
import { capitalize } from '../core/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectionsInfo, selectUserInfo, selectSaveRMASForms, selectBaseRMAInfo } from '../core/redux/selectors';
import {
    deleteAlternateContact, deletePickupAddress, deletePickupContact, deleteRequestor, setReturnFlowProp,
    setPickupContactAsAlternateContact, setRequestorAsPickupContact, saveContact, savePickupAddress,
    updateRequestor, updatePickupContact, updateAlternateContact, updatePickupAddress,
    addPickupAddress, addRequestor, addPickupContact, addAlternateContact
} from '../core/redux/actions';
import { PickupLocationForm, PickupLocationRMAForm, PickupRequestorForm, PickupRequestorRMAForm } from './forms';
import { useContactValidation, usePickupLocationValidation, useAlert, useCountry } from '../core/hooks';
import { COLOR } from '../core/constants/Colors';
import { CONTACT_TYPES, COUNTRY } from '../core/constants';
import { SET_GSTIN } from '../core/redux/actionTypes';
import { Input } from './controls';

const PickUpLocation = () => {
    const pickupRef = useRef();
    const requestorRef = useRef();
    const contactRef = useRef();
    const alternateRef = useRef();

    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { removeAlert } = useAlert();
    const { getContactErrors } = useContactValidation();
    const { getPickupErrors, handleMaxLengthPostalCode } = usePickupLocationValidation();
    const { getCountry } = useCountry();
    // STATE
    const [pickLocation, setPickLocation] = useState(null);
    const [requestor, setRequestor] = useState(null);
    const [contact, setContact] = useState(null);
    const [alternate, setAlternate] = useState(null);
    const [modalTemplate, setModalTemplate] = useState(null);
    const [RMALogin, setRMALogin] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState('');
    const [coping, setCoping] = useState(false);
    const [copy, setCopy] = useState(null);
    const [copingContact, setCopingContact] = useState(false);
    const [copyC, setCopyC] = useState(null);
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [saving, setSaving] = useState({});
    const [saveName, setSaveName] = useState('');
    // SELECTORS
    const selectionsInfo = useSelector(selectSelectionsInfo);
    const userInfo = useSelector(selectUserInfo);
    const saveRMASForms = useSelector(selectSaveRMASForms);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    
    const country = getCountry;

    useEffect(() => {
        if (userInfo.rmaLogin) {
            setRMALogin(true);
        }
    }, [userInfo]);

    useEffect(() => {
        if (modalTemplate === null) removeAlert("mandatoryFields")
    }, [modalTemplate]);

    useEffect(() => {
        const pu = selectionsInfo.pickup;
        
        if (pu) {
            const labelText = pu.country === COUNTRY.MEXICO ? translate('RFC') : translate('formLabel.gstin');
            let info = [];
            for (let i = 0; i <= 3; i++) {
                if (pu[`addr${i + 1}`]) info.push(pu[`addr${i + 1}`]);
            }
            let pickupContent = {
                title: pu.save_as || pu.name || '',
                info: [
                    ...(pu.save_as ? [pu.name] : []),
                    ...info,
                    `${pu.city ? pu.city + ', ' : ''}${pu.province ? pu.province + ', ' : ''}${pu.postal ? (pu.postal + ' ') : ''}${pu.country || ''}`,
                    ...(pu.gstin ? [`${labelText}: ${pu.gstin}`] : rmaInfo && rmaInfo.gstin ? [`${labelText}: ${rmaInfo.gstin}`] : [])
                ],
                errors: []
            };
            pickupContent.errors = getPickupErrors(pu);

            setPickLocation(pickupContent);

            if (defaultCountry === '') setDefaultCountry(pu.country);
        }
        else setPickLocation(null);
    }, [selectionsInfo.pickup, getPickupErrors, dispatch]);

    useEffect(() => {
        const rq = selectionsInfo.requestor;
        if (rq) {
            const fullName = `${capitalize(rq.fname)} ${capitalize(rq.lname)}`;
            let contactContent = {
                title: rq.save_as || fullName,
                info: [
                    ...(rq.save_as ? [fullName] : []),
                    rq.email,
                    rq.phone
                ],
                errors: [],
                idd: rq.idd
            }
            contactContent.errors = getContactErrors(rq, country);
            setRequestor(contactContent);
        }
        else setRequestor(null);
    }, [selectionsInfo.pickup, selectionsInfo.requestor, getContactErrors]);

    useEffect(() => {
        const cts = selectionsInfo.contact;
        if (cts) {
            const fullName = `${capitalize(cts.fname)} ${capitalize(cts.lname)}`;
            let contactContent = {
                title: cts.save_as || fullName,
                info: [
                    ...(cts.save_as ? [fullName] : []),
                    cts.email,
                    cts.phone
                ],
                errors: [],
                idd: cts.idd
            };
            contactContent.errors = getContactErrors(cts, country);
            setContact(contactContent);
        } else setContact(null);
    }, [selectionsInfo.pickup, selectionsInfo.contact, getContactErrors]);

    useEffect(() => {
        const cts = selectionsInfo.alternateContact;
        if (cts) {
            const fullName = `${capitalize(cts.fname)} ${capitalize(cts.lname)}`;
            let contactContent = {
                title: cts.save_as || fullName,
                info: [
                    ...(cts.save_as ? [fullName] : []),
                    cts.email,
                    cts.phone
                ],
                errors: [],
                idd: cts.idd
            };
            contactContent.errors = getContactErrors(cts, country);
            setAlternate(contactContent);
        } else setAlternate(null);
    }, [selectionsInfo.pickup, selectionsInfo.alternateContact, getContactErrors]);

    useEffect(() => {
        dispatch(setReturnFlowProp('currentStep', 0));
    }, []);

    useEffect(() => {
        if (saveRMASForms) handleSaveRMAForms();
    }, [saveRMASForms]);

    const handleSaveRMAForms = () => {
        pickupRef.current.saveAll();
        requestorRef.current.saveAll();
        contactRef.current.saveAll();
        alternateRef.current.saveAll();
    }

    const handleDelete = () => {
        switch (modalTemplate.type) {
            case 'PickupLocation':
                dispatch(deletePickupAddress(pickLocation.idd)); break;
            case 'PickupRequestor':
                dispatch(deleteRequestor(requestor.idd)); break;
            case 'PickupContact':
                dispatch(deletePickupContact(contact.idd)); break;
            default:
                dispatch(deleteAlternateContact(alternate.idd)); break;
        }
        setModalTemplate(null);
    };

    const handleCopyRequestor = () => {
        if (requestor) dispatch(setRequestorAsPickupContact());
    };

    const handleCopyPickupContact = () => {
        if (contact) dispatch(setPickupContactAsAlternateContact());
    };

    const copyRequestor = () => {
        requestorRef.current.copyInfo();
    }

    const copyContact = () => {
        contactRef.current.copyInfo();
    }

    const changeRequestor = (v) => {
        const { idd, ...values } = v;
        setCoping(true);
        setCopy(values);
    }

    const changeContact = (v) => {
        const { idd, ...values } = v;
        setCopingContact(true);
        setCopyC(values);
    }

    useEffect(() => {
        if (coping) setTimeout(() => setCoping(false), 300)
    }, [coping]);

    useEffect(() => {
        if (copingContact) setTimeout(() => setCopingContact(false), 300)
    }, [copingContact]);

    const funcs = {
        PickupRequestorUdt: updateRequestor,
        PickupContactUdt: updatePickupContact,
        AlternateContactUdt: updateAlternateContact,
        PickupRequestor: addRequestor,
        PickupContact: addPickupContact,
        AlternateContact: addAlternateContact
    };

    const handleSaveAs = (info, typeName, template) => {
        setShowSaveAs(true);
        let type = typeName === 'location' ? 'location'
            : CONTACT_TYPES.filter(x => x.key === typeName)[0].type;
        setSaving({ ...info, oldType: info.type, type, template });
        setSaveName(info.save_as);
    };

    const handleSave = () => {
        let add = saving.type === 'location' ? saveName !== saving.save_as
            : saveName !== saving.save_as && saving.type !== saving.oldType;
        if (saving.type === 'location') handleSaveLocation(add);
        else handleSaveContact(add);
    };

    const handleSaveLocation = (add) => {
        let oldID = saving.idd;
        let newAddress = { ...saving, ...(add ? { idd: 0 } : {}) };

        newAddress = {
            idd: newAddress.idd,
            name: newAddress.name,
            addr1: newAddress.addr1,
            addr2: newAddress.addr2,
            addr3: newAddress.addr3,
            addr4: newAddress.addr4,
            country: newAddress.country,
            province: newAddress.province,
            city: newAddress.city,
            postal: handleMaxLengthPostalCode(newAddress.postal),
            gstin: newAddress.gstin,
            atag: saveName,
            save_as: saveName
        };

        if (add) dispatch(addPickupAddress(newAddress));
        else dispatch(updatePickupAddress({ ...newAddress, idd: oldID }));
        if (add) delete newAddress['save_as'];
        dispatch(savePickupAddress({ json: { dtos: [newAddress] } }, add));
        dispatch({ type: SET_GSTIN, payload: newAddress.gstin });
        setShowSaveAs(false);
    }

    const handleSaveContact = (add) => {
        let template = saving.template;
        let oldID = saving.idd;
        let newContact = { ...saving, ...(add ? { idd: 0 } : {}) };

        newContact = {
            idd: newContact.idd,
            fname: newContact.fname,
            lname: newContact.lname,
            email: newContact.email,
            phone: newContact.phone,
            type: newContact.type,
            ctag: saveName,
            save_as: saveName
        };

        if (add) dispatch(funcs[`${template}`](newContact));
        else dispatch(funcs[`${template}Udt`]({ ...newContact, idd: oldID }));
        if (add) delete newContact['save_as'];
        dispatch(saveContact({ json: { dtos: [newContact] } }, add));
        setShowSaveAs(false);
    }

    return (
        <div className="container-fluid p-0">
            {!RMALogin ?
                <div className="row">
                    <div className="col-12 col-lg-6 pr-lg-8">
                        <InfoSection
                            className="full-height"
                            title={translate('subtitle.pickupLocation')}
                            isRequired type={'cards'}
                            isPickUpCard={true}
                            buttonText={translate('button.editPickupLocation')}
                            onButtonClick={() => setModalTemplate({ action: 'edit', type: 'PickupLocation' })}
                        >
                            
                            <ContactCard
                                fullHeight
                                icon="map-marker-alt"
                                content={pickLocation}
                                isPickupCard={true}
                                onEdit={() => setModalTemplate({ action: 'add', type: 'PickupLocation' })}
                                onSave={() => handleSaveAs(selectionsInfo.pickup, 'location', 'PickupLocation')}
                                onDelete={() => setModalTemplate({ action: 'delete', type: 'PickupLocation' })} />
                        </InfoSection>
                    </div>
                    <div className="col-12 col-lg-6 pl-lg-8 pt-2 pt-lg-0">
                        <InfoSection
                            className="full-height"
                            title={translate('subtitle.requestorContactInfo')}
                            isRequired type={'cards'}
                            isPickUpCard={true}
                            buttonText={translate('button.changeInformation')}
                            onButtonClick={() => setModalTemplate({ action: 'add', type: 'PickupRequestor' })}>
                            <ContactCard
                                fullHeight
                                icon="user-circle"
                                content={requestor}
                                onEdit={() => setModalTemplate({ action: 'edit', type: 'PickupRequestor' })}
                                onSave={() => handleSaveAs(selectionsInfo.requestor, 'requestor', 'PickupRequestor')}
                                onDelete={() => setModalTemplate({ action: 'delete', type: 'PickupRequestor' })} />
                        </InfoSection>
                    </div>
                    <div className="col-12 pt-2">
                        <InfoSection
                            title={translate('subtitle.pickupContact')}
                            isRequired type={'cards'}
                            buttonText={translate(`button.add${!contact ? '' : 'Alternate'}Contact`)}
                            onButtonClick={() => setModalTemplate({ action: 'add', type: !contact ? 'PickupContact' : 'AlternateContact' })}>
                            <div className="row">
                                {contact ?
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <ContactCard
                                            icon="portrait"
                                            content={contact} fullHeight
                                            onEdit={() => setModalTemplate({ action: 'edit', type: 'PickupContact' })}
                                            onSave={() => handleSaveAs(selectionsInfo.contact, 'pickup', 'PickupContact')}
                                            onDelete={() => setModalTemplate({ action: 'delete', type: 'PickupContact' })} />
                                    </div>
                                    :
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <ContactCard
                                            enableCopy fullHeight
                                            onCopyRequest={() => handleCopyRequestor()}
                                            content={null} />
                                    </div>
                                }
                                {alternate ?
                                    <div className="col-md-6">
                                        <ContactCard
                                            icon="portrait"
                                            content={alternate} fullHeight
                                            onEdit={() => setModalTemplate({ action: 'edit', type: 'AlternateContact' })}
                                            onSave={() => handleSaveAs(selectionsInfo.alternateContact, 'alternate', 'AlternateContact')}
                                            onDelete={() => setModalTemplate({ action: 'delete', type: 'AlternateContact' })} />
                                    </div>
                                    : contact ?
                                        <div className="col-md-6">
                                            <ContactCard
                                                enableCopy
                                                isCopyPickup fullHeight
                                                onCopyRequest={() => handleCopyPickupContact()}
                                                content={null} />
                                        </div>
                                        : <div />
                                }
                            </div>
                        </InfoSection>
                    </div>
                    <POWRModal
                        show={showSaveAs} size="wide"
                        title={translate('button.saveAs')}
                        onClose={() => setShowSaveAs(false)} >
                        <div className="body">
                            <Input label={translate('formLabel.addressBookName')}
                                onChange={e => setSaveName(e.target.value)} value={saveName} />
                        </div>
                        <div className="custom-toolbar">
                            <label>
                                <span className="mr-1">
                                    <POWRIcon className={'fas fa-info-circle'} size="1rem" color={COLOR.CISCO_BLUE} />
                                </span>
                                {translate('message.saveAsModal')}
                            </label>
                            <div className="toolbar-content pt-2">
                                <POWRButton type="button" color='secondary' onClick={() => setShowSaveAs(false)}>{translate('button.cancel')}</POWRButton>
                                <POWRButton type="button" onClick={() => handleSave()}>{translate('button.save')}</POWRButton>
                            </div>
                        </div>
                    </POWRModal>
                </div>
                :
                <div className="row mb-4">
                    <InfoSection
                        titleIcon={<POWRIcon className="fas fa-map-marker-alt mr-2" color={COLOR.CISCO_BLUE} size="16px" />}
                        title={translate('subtitle.pickupLocation')}
                        type={'cards'}
                        isPickUpCard={true}
                        className="mb-3">
                        <PickupLocationRMAForm
                            data={selectionsInfo && selectionsInfo.pickup ? selectionsInfo.additional ?
                                { ...selectionsInfo.pickup, site_directions: selectionsInfo.additional }
                                : selectionsInfo.pickup : null}
                            ref={pickupRef} />
                    </InfoSection>
                    <InfoSection
                        titleIcon={<POWRIcon className="fas fa-user-circle mr-2" color={COLOR.CISCO_BLUE} size="16px" />}
                        title={translate('subtitle.requestorContactInfo')}
                        type={'cards'}
                        isPickUpCard={true}
                        className="mb-3">
                        <PickupRequestorRMAForm
                            data={selectionsInfo && selectionsInfo.requestor ? selectionsInfo.requestor : null}
                            ref={requestorRef} 
                            country={country}
                            user={userInfo}
                            onCopy={(v) => changeRequestor(v)}
                            template="PickupRequestor" />
                    </InfoSection>
                    <InfoSection
                        titleIcon={<POWRIcon className="fas fa-portrait mr-2" color={COLOR.CISCO_BLUE} size="16px" />}
                        title={translate('subtitle.pickupContact')}
                        type={'cards'}
                        isPickUpCard={true}
                        buttonText={translate('button.copyContactInfo')}
                        className="mb-3"
                        onButtonClick={() => copyRequestor()}>
                        <PickupRequestorRMAForm
                            data={copy ? copy : selectionsInfo && selectionsInfo.contact ? selectionsInfo.contact : null}
                            isDataCopy={coping}
                            country={country}
                            ref={contactRef}
                            user={userInfo}
                            onCopy={(v) => changeContact(v)}
                            template="PickupContact" />
                    </InfoSection>
                    <InfoSection
                        titleIcon={<POWRIcon className="fas fa-portrait mr-2" color={COLOR.CISCO_BLUE} size="16px" />}
                        title={translate('aux.alternateContactInfo')}
                        type={'cards'}
                        isPickUpCard={true}
                        buttonText={translate('formLabel.copyPickupContact')}
                        onButtonClick={() => copyContact()}>
                        <PickupRequestorRMAForm
                            data={copyC ? copyC : selectionsInfo && selectionsInfo.alternateContact ? selectionsInfo.alternateContact : null}
                            isDataCopy={copingContact}
                            country={country}
                            ref={alternateRef}
                            user={userInfo}
                            template="AlternateContact" />
                    </InfoSection>
                </div>
            }
            {
                (modalTemplate != null && modalTemplate.action !== 'delete') &&
                <POWRModal
                    show={true}
                    title={translate(`subtitle.${modalTemplate.action}${modalTemplate.type}`)}
                    onClose={() => setModalTemplate(null)}>
                    <Fragment>
                        {(
                            modalTemplate.type === 'PickupLocation' ?
                                <PickupLocationForm
                                    isAdd={modalTemplate.action === 'add'}
                                    data={modalTemplate.action !== 'add' ? selectionsInfo.pickup : null}
                                    onClose={() => setModalTemplate(null)} />
                                :
                                <PickupRequestorForm
                                    isAdd={modalTemplate.action === 'add'}
                                    country={country}
                                    template={modalTemplate.type}
                                    data={modalTemplate.action !== 'add' ? (modalTemplate.type === 'PickupRequestor' ?
                                        selectionsInfo.requestor : (modalTemplate.type === 'PickupContact' ?
                                            selectionsInfo.contact : selectionsInfo.alternateContact)) : null}
                                    onClose={() => setModalTemplate(null)} />
                        )}
                    </Fragment>
                </POWRModal>
            }
            {
                (modalTemplate != null && modalTemplate.action === 'delete') &&
                <POWRModal
                    show={true}
                    title={translate('message.title.confirmDelete')}
                    size={'small'}
                    onClose={() => setModalTemplate(null)}>
                    <div className="content">
                        <div className="x-centered mt-3 mb-4">
                            <POWRIcon
                                className="fas fa-trash-alt"
                                size="3.5em"
                                color={COLOR.CISCO_BLUE} />
                        </div>
                        <span className="d-block my-2 text-center">
                            {translate(`message.confirmDelete${modalTemplate.type === 'PickupLocation' ? 'Address' : 'Contact'}`)}
                        </span>
                        <span className="d-block my-2 text-center">{translate('message.sure')}</span>
                    </div>
                    <div className="toolbar">
                        <POWRButton color={'secondary'} onClick={() => setModalTemplate(null)}>{translate('button.cancel')}</POWRButton>
                        <POWRButton onClick={handleDelete}>{translate('button.ok')}</POWRButton>
                    </div>
                </POWRModal>
            }
        </div>
    )
};

export default PickUpLocation;