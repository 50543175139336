import { SET_CISCO_ENTITY } from "../actionTypes";

const initialState = {
    eventCarriers: [],
    rmaTypes: [],
    countries: []
};

export const ciscoEntities = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_CISCO_ENTITY: return { ...state, [payload.entityName]: payload.data };
        default:
            return state;
    }
};
