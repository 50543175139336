import {
    SET_SHIPMENTS, 
    SET_SHIPMENTS_SEARCH_PARAMETERS,
    SET_SHIPMENTS_SEARCH_RESULTS,
    SET_SHIPMENTS_SEARCHING,
    SET_SHIPMENTS_RELOAD,
    SET_SHIPMENTS_SELECTED,
    SET_SHIPMENTS_DETAIL,
    DELETE_SHIPMENT_SEARCH_RESULTS,
    SET_SHIPMENTS_PROP
} from '../actionTypes';
import { getShipments } from '../../../services';

export const loadShipments = (rma, shipCode, trackingNumber) => (dispatch) => {
    return getShipments({
        param_ship_code: shipCode || '',
        param_rma_or_tradein: rma || '',
        param_tn: trackingNumber || '',
        param_ship_status: '',
        param_carrier: '',
        param_exclude_saved: 'on'
    }).then((response) => {
        let shipments = [];
        if (response.res.success)
            shipments = response.res.dtos.filter(x => !(x.status === 0 || x.status === 3));
        dispatch({ type: SET_SHIPMENTS, payload: shipments });
        return shipments;
    });
};

export const setShipmentsProp = (propName, value) => ({ type: SET_SHIPMENTS_PROP, payload: { propName, value } });

export const setShipmentsSearchParameters = (data) => ({
    type: SET_SHIPMENTS_SEARCH_PARAMETERS,
    payload: data
});

export const setShipments = (data) => ({
    type: SET_SHIPMENTS,
    payload: data
});

export const setShipmentsSearchResults = (data) => ({
    type: SET_SHIPMENTS_SEARCH_RESULTS,
    payload: data
});

export const setShipmentsSearching = (data) => ({
    type: SET_SHIPMENTS_SEARCHING,
    payload: data
});

export const setShipmentsReload = (data) => ({
    type: SET_SHIPMENTS_RELOAD,
    payload: data
});

export const setShipmentSeleted = (data) => ({
    type: SET_SHIPMENTS_SELECTED,
    payload: data
});

export const setShipmentDetail = (data) => ({
    type: SET_SHIPMENTS_DETAIL,
    payload: data
});

export const deleteShipmentSearchResults = (idd) => ({
    type: DELETE_SHIPMENT_SEARCH_RESULTS,
    payload: idd
});