import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { POWRIcon } from './icon';
import { HeadAlert } from "../index";

const DivStructure = ({ title, className, children, show, onClose, size, customToolbar, hideAlerts, hideClose }) => (
    <Modal  className={className} show={show} onHide={!hideClose ? onClose : undefined} animation={false}>
      {!hideAlerts && <HeadAlert /> }
        {size !== 'tiny' && <div className="header">
            <div className="header-content">
                <span className="title">{title}</span>
                {!hideClose &&
                    <span className="close" onClick={onClose}>
                        <POWRIcon className="fal fa-times" size="1.2rem" color="#099ad6" selectable />
                    </span>
                }
            </div>
        </div>}
        <div className="body">
            {children}
        </div>
    </Modal>
);

export const POWRModal = styled(DivStructure)`
    .modal-dialog {
        max-width: none;
        width: min-content;
        position: absolute;
        top: 48%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%) !important;
        .modal-content {
            width: ${props => props.size === 'small' ? '50vw' : (props.size === 'medium' ? '32vw' : (props.size === 'long' ? '90vw' : (props.size === 'tiny' ? '320px' : (props.size === 'wide' ? '80vh' : '50vw'))))};
            height: ${props => props.size === 'small' ? 'auto' : (props.size === 'medium' ? 'auto' : (props.size === 'long' ? '64vh' : (props.size === 'tiny' ? 'auto' : (props.size === 'wide' ? '40%' : '80vh'))))};
            min-height: 100% !important;
            max-height: 80vh;
            background-color: white;
            position: static !important;
            ${props => props.size === 'tiny' ? 'border-radius: 5px;' : 'border-radius: 3px;'}
            .header {
                display: flex;
                width: 100%;
                padding: 24px;
                padding-bottom: 0px;
                background-color: white;
                .header-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #DFDFDF;
                    .title {
                        font-family: CiscoSansTT-Bold;
                        color: #686767;
                    }
                }
            }
            .body {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                max-height: 100%;
                padding: ${props => props.size === 'tiny' ? '16px;' : '24px'};
                ${props => !props.noBodyOverflow ? 'overflow: hidden;' : ''}
                background-color: white;
                .content {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    background-color: white;
                    ${props => !props.noContentOverflow ? `
                    overflow-y: auto;
                    overflow-x: hidden;` : ''}
                    .reschedule {
                        max-width: 58.333333%;
                    }
                }
                .modalBody {
                    overflow-y: auto;
                    overflow-x: hidden;
                    background-color: white;
                }
                .modal-tooltip {
                    width: 300px;
                }
                .toolbar {
                    display: flex;
                    width: 100%;
                    justify-content: ${props => props.alignActions === 'center' ? 'center' : 'flex-end'};
                    ${props => props.size !== 'tiny' ? `
                        height: auto;
                        margin-top: 16px;
                        padding-top: 16px;
                        border-top: 1px solid #DFDFDF;
                        ` : ''}
                    background-color: white;
                    min-height: min-content;
                    align-items: center;
                    button {
                        width: ${props => props.largeActions ? '100%' : 'auto'};
                    }
                    * {
                        margin: 0px 8px;
                    }
                    button:nth-child(1) {
                        margin-left: 0
                    }
                    button:nth-child(2) {
                        margin-right: 0
                    }
                }
                .custom-toolbar {
                    display: block;
                    width: 100%;
                    height: auto; 
                    margin-top: 16px;
                    padding-top: 16px;
                    border-top: 1px solid #DFDFDF;
                    background-color: white;
                    .toolbar-content {
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        align-items: center;
                        button {
                            width: ${props => props.largeActions ? '100%' : 'auto'};
                        }
                        * {
                            margin: 0px 8px;
                        }
                        button:nth-child(1) {
                            margin-left: 0
                        }
                        button:nth-child(2) {
                            margin-right: 0
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .modal-dialog {
            .modal-content {   
                .header {
                    padding: 16px 16px 0 16px;
                }
                .body {
                    padding: 16px;
                }
                width: 90vw;
                height: ${props => (props.size === 'small' || props.size === 'medium') ? 'auto' : '90vh'};
                margin-top: 5vh;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .modal-dialog {
            .modal-content { 
                .body {
                    .toolbar {
                        height: auto;
                        button {
                            font-size: 0.89rem;
                            padding: 0 5px;
                            min-width: 115px;
                        }
                    }
                    .custom-toolbar {
                        .toolbar-content {
                            flex-direction:column; 
                            
                            button   {
                                margin: 0em;
                                margin-top: 0.5em;
                                display: block;
                                width: 100%; 
                            }
                        } 
                    }
                }
            }
        }
    }
`;