import React from 'react';
import styled from 'styled-components';

const DivStructure = ({ className, options, selectedTab, onSelectTab }) => (
    <div className={className}>
        <div className="tablines">
            {
                options.map((option, i) => (
                    <div 
                        key={`tab-${i}`}
                        onClick={() => onSelectTab(option.value)}
                        className={`col-${12 / options.length} tab ${option.value === selectedTab ? 'active' : ''}`}>
                        <h3>{option.name}</h3>
                    </div>
                ))
            }
        </div>
    </div>
);

export const Tabs = styled(DivStructure)`
    width: 100%;
    height: 38px;
    .tablines{
        display: flex;
        height: 100%;
        .tab{
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: #DFDFDF;
            cursor: pointer;
            h3 {
                color: #099AD6;
                margin-bottom: 0;
            }
            &.active {
                border-bottom-color: #099AD6;
                h3 {
                    color: #58585B;
                }
            }
        }
    }
`;
