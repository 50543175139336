import React from 'react';
import { COLOR } from '../../core/constants/Colors';
import { POWRIcon } from '../styledComponents';

export default props => {
    return (
        <div className={`InfoTooltipContainer ${props.className || ''} ${props.middle ? 'middle-tooltip' : ''}`}>
            <POWRIcon
                className="fal fa-info-circle"
                color={props.color ? props.color : COLOR.GREY}
                size="16px"
            />
            <span className={`InfoTooltipText ${props.spanClass || ''}`}>{props.children}</span>
        </div>
    )
}