import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle, useMemo } from 'react';
import { Formik } from 'formik';
import { InfoTooltip, TextInput } from '../controls';
import { POWRButton } from '../styledComponents';
import { ContactSchema, ContactSchemaUSCA } from '../../core/schemas';
import { useDispatch } from 'react-redux';
import { addAlternateContact, addPickupContact, addRequestor, setReturnFlowProp, updateAlternateContact, updatePickupContact, updateRequestor } from '../../core/redux/actions';
import { useTranslate } from '../../core/contexts';
import { CONTACT_TYPES, COUNTRY } from '../../core/constants';
import { useAlert } from '../../core/hooks';

const funcs = {
    PickupRequestor: addRequestor,
    PickupRequestorUpd: updateRequestor,
    PickupContact: addPickupContact,
    PickupContactUpd: updatePickupContact,
    AlternateContact: addAlternateContact,
    AlternateContactUpd: updateAlternateContact
};

export const PickupRequestorRMAForm = forwardRef(({ onCopy, template, data, user, isDataCopy, country }, ref) => {
    const dispatch = useDispatch();
    const refSubmit = useRef();
    const refForm = useRef();
    const { translate } = useTranslate();
    const { showAlert } = useAlert();

    const [initialValues, setInitialValues] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        type: '',
        save_as: ''
    });
    const [localData, setLocalData] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [coping, setCoping] = useState(false);
    const [schema, setSchema] = useState(ContactSchema);
    
    const isUSorCA = useMemo(() => (country === COUNTRY.US ||country === COUNTRY.CANADA), [country]);

    useEffect(() => {
        if (!typeOptions.length) setTypeOptions(CONTACT_TYPES.filter(x => x.type > 1).map(x => ({
            text: translate(`contactType.${x.key}`), code: String(x.type)
        })));
    }, [translate, typeOptions]);

    useEffect(() => {
        setLocalData(data);
    }, [data]);

    useEffect(() => { setSchema( isUSorCA ? ContactSchemaUSCA : ContactSchema ); }, [country]);

    useEffect(() => {
        if (typeOptions.length > 0) {
            const defaultType = { type: template === 'PickupRequestor' ? '2' : '3' };
            let newValues = { ...initialValues, ...(localData ? localData : {}), ...defaultType };
            if (isDataCopy) refForm.current.click();
            setInitialValues(newValues);
            if (localData) checkInitialErrors(newValues);
        }
    }, [localData, template, typeOptions]);

    const checkInitialErrors = async (values) => {
        let isValid = await ContactSchema.isValid(values)
        if (!isValid) refSubmit.current.click();
    }

    const save = (values, { setSubmitting }) => {
        let newContact = {
            ...(localData ? localData : {}),
            ...values,
            ...{ ctag: values.fname + ' ' + values.lname }
        };
        dispatch(funcs[`${template}${localData && localData.idd ? 'Upd' : ''}`](newContact));
        let tp = template === "PickupRequestor" ? 'RMASForm2Valid' : template === "PickupContact" ? 'RMASForm3Valid' : 'RMASForm4Valid';
        dispatch(setReturnFlowProp(tp, true));
        setSubmitting(false);
    };

    useImperativeHandle(ref, () => ({
        saveAll() {
            refSubmit.current.click();
        },
        copyInfo() {
            setCoping(true);
        }
    }));

    useEffect(() => {
        if (coping) refSubmit.current.click();
    }, [coping])

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        e.preventDefault();
        if (coping) {
            onCopy(values);
            setCoping(false);
        } else {
            let result = await validateForm(values);
            if (!Object.keys(result).length) handleSubmit();
            else {
                let tp = template === "PickupRequestor" ? 'RMASForm2Valid' : template === "PickupContact" ? 'RMASForm3Valid' : 'RMASForm4Valid';
                dispatch(setReturnFlowProp(tp, false));
                dispatch(setReturnFlowProp('saveRMASForms', false));
                setTouched(result);
                setErrors(result);
                showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow" })
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={save}
            key={`form-${country}`} >
            {
                ({ handleSubmit, handleReset, isSubmitting, getFieldMeta, setFieldValue, values, validateForm, setTouched, setErrors }) => (
                    <form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} data-testid="ctForm" className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'fname'} label={'formLabel.firstName'}
                                        placeholder={translate('formLabel.firstName')} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'lname'} label={'formLabel.lastName'}
                                        placeholder={translate('formLabel.lastName')} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput isRequired name={'phone'} label={'formLabel.phone'} numericKeys={!isUSorCA}
                                        placeholder={translate('formLabel.phone')} maxLength="255" customIcon={isUSorCA ?
                                            <InfoTooltip spanClass="form-tooltip">{translate('message.phoneFormat')}</InfoTooltip> : null} />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'email'} label={'formLabel.email'}
                                        placeholder={translate('formLabel.email')} maxLength="255" />
                                </div>
                            </div>
                        </div>
                        <div className="d-none">
                            <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.save')}</POWRButton>
                        </div>
                        <div className="d-none">
                            <POWRButton disabled={isSubmitting} ref={refForm} onClick={handleReset}></POWRButton>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
});
