import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../core/contexts';
import { useAlert } from '../../core/hooks';
import { clickOutsideReturn } from '../../core/redux/actions';
import { POWRButton, POWRIcon, POWRModal } from "../styledComponents";

export const UnsavedChangesModal = ({ show, close, save, to }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const history = useHistory();
    const { showAlert } = useAlert();

    const saveAndReturn = async () => {
        await save()
        showAlert({
            id: 'cancelAndSave',
            message: translate('message.sessionSaved'),
            page: '*',
            color:'green'
        });
        history.push(to)
        dispatch(clickOutsideReturn(''))
    }

    const toggleModal = () => {
        dispatch(clickOutsideReturn(''))
        close();
    }

    const goToHome = () => {
        dispatch(clickOutsideReturn(''))
        history.push(to)
    }

    return (
        <POWRModal
            show={show}
            title={translate('title.unsavedChanges')}
            size="small"
            onClose={toggleModal}
        >
            <div className="content">
                <div className="text-center">
                    <POWRIcon
                        className="fas fa-exclamation-triangle"
                        color="#ffb600"
                        size="3rem"
                    />
                    <p className="pt-3">{translate('message.leaveRequestProcess')}</p>
                </div>
            </div>
            <div className="toolbar">
                <POWRButton color={'secondary'} onClick={goToHome}>{translate('button.leavewithoutsaving')}</POWRButton>
                <POWRButton onClick={saveAndReturn}>{translate('button.saveandLeave')}</POWRButton>
            </div>
        </POWRModal>
    )
}

