import React, { useState, useEffect, useMemo} from 'react';
import { useTranslate } from '../core/contexts';
import { useHistory } from 'react-router-dom';
import { FORM_TYPE, SHIPMENT_STATUS_NUM, RMA_SHIPEVENTTYPE_TYPES,ROLES } from '../core/constants';
import { useCommercialInvoice, useConfigParams } from '../core/hooks';
import { DOWNLOAD, SHIPMENT_DETAIL } from '../core/constants/ROUTES';
import { InfoTooltip, Link } from './controls';
import { POWRIcon, POWRModal } from './styledComponents';
import { CommercialInvoiceForm } from './forms/commercialInvoiceForm';
import { COLOR } from '../core/constants/Colors';
import { BASE_URL_API } from "../core/constants/URLS";
import { selectUserInfo } from "../core/redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getCommercialLines } from '../services/rmaService';
import { displayCommercialInvoice } from '../services/commercialInvoiceService';
import { setDownloadParams } from '../core/redux/actions';

export default props => {
    const { translate } = useTranslate();
    const history = useHistory();
    const dispatch = useDispatch()
    const { shipment, icons } = props;
    const { idd, rmas, tns, ship_code, status, ship_event, generate_invoice, generate_sli, generate_packing_lst, theater_code, is_fact_return } = shipment;
    const {
        handleCommercialInvoice,
        handleFaultDetection,
        handleActExchange,
        handleViewReceipt,
        print,
        modalTemplate,
        setModalTemplate,
        showFaultDetection,
        showActExchange,
        showViewEmail,
        showUploadDocuments
    } = useCommercialInvoice({ shipment });
    const { downloadAllBtnEnabled, allowedTheaters } = useConfigParams();

    const [ showCommercial, setShowCommercial ] = useState(false);
    const [ printAllDisplayModal, setPrintAllDisplayModal ] = useState(false);

    const denied = ship_event === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_DENIED;
    const notSubmitted = status === SHIPMENT_STATUS_NUM.STATUS_DRAFT;
    const userInfo = useSelector(selectUserInfo);
    const userAdmin = userInfo && userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT;
    const handlePrintEmail = (id) => {
        window.open(`${BASE_URL_API}/CarrierEmailServlet?shipId=${id}`, "popup", 'width=600,height=600');
    };

    const displayPrintLabelsAndDoc = useMemo(() => {
        return downloadAllBtnEnabled && allowedTheaters.indexOf(theater_code)  > -1 && is_fact_return !== 1;
    }, [idd])

    useEffect(() => {
        displayCommercialInvoice(idd).then((res) => {
            setPrintAllDisplayModal(res.res.success)
        })
        
        getCommercialLines({ ship_id: idd }).then((response) => {
            const checkCommercial = response.res.dtos;
            if (checkCommercial.length > 0) {
                setShowCommercial(true);
            } else setShowCommercial(false);
        }).catch(() => setShowCommercial(false));
    }, [idd]);

    const handleUploadedDocuments = () => {
        dispatch(setDownloadParams({
            shipId: idd,
            shipCode: ship_code,
            rma: (rmas && rmas[0]) || '',
            trackingNumber: (tns && tns[0]) || ''
        }));
        history.push(DOWNLOAD);
    };

  

    return !notSubmitted ? (
        <div className="download-center">
            <div className="download-center-title">
                <h3>{translate('aux.downloadCenter')}</h3>
            </div>
            <div className="download-center-links">
                {!(notSubmitted || icons) &&
                    <Link className={icons ? "download-icon-link" : "download-link"} to={{ pathname: SHIPMENT_DETAIL, search: `?id=${ship_code}`, state: { id: ship_code, data: shipment } }}>
                        {translate('aux.shipmentDetail')}
                    </Link>
                }
             
                {!(notSubmitted || denied) && !displayPrintLabelsAndDoc &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => print('label', idd)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-alt"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('aux.printLabels')}</Link>
                    </div>
                }
                {((generate_invoice || (userAdmin && showCommercial)) && !(notSubmitted || denied)) && !displayPrintLabelsAndDoc &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => handleCommercialInvoice(idd, ship_code, FORM_TYPE.COMMERCIAL_INVOICE)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-invoice"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('aux.commercialInvoice')}</Link>
                    </div>
                }
                {(!!generate_packing_lst && !(notSubmitted || denied)) &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => handleCommercialInvoice(idd, ship_code, FORM_TYPE.PACKING_LIST)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-alt"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('aux.packageList')}</Link>
                    </div>
                }
                {(!!generate_sli && !(notSubmitted || denied)) &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => handleCommercialInvoice(idd, ship_code, FORM_TYPE.SLI)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-spreadsheet"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('aux.listInstructions')}</Link>
                    </div>
                }
                {(showFaultDetection && !(notSubmitted || denied)) &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => handleFaultDetection(idd, ship_code)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-alt"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('title.faultDetectionForm')}</Link>
                    </div>
                }
                {(showActExchange && !(notSubmitted || denied)) &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => handleActExchange(idd, ship_code)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-alt"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('aux.actOfExchange')}</Link>
                    </div>
                }
                {!notSubmitted &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => handleViewReceipt(idd)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-receipt"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('aux.viewReceipt')}</Link>
                    </div>
                }
                {!notSubmitted && showViewEmail && userAdmin && 
                    <div
                        className={icons ? "download-icon-link justify-content-between" : "download-link d-flex justify-content-between"}
                        onClick={() => handlePrintEmail(idd)}
                    >
                        <div className="d-flex align-items-center">
                            {icons && <div className="iconContainer">
                                <POWRIcon
                                    className="fal fa-receipt"
                                    color={COLOR.GREY}
                                    size="24px" />
                            </div>}
                            <Link>{translate('aux.viewEmail')}</Link>
                        </div>
                        <InfoTooltip>{translate('message.viewEmail')}</InfoTooltip>
                    </div>
                }
                {!notSubmitted && showUploadDocuments && userAdmin && 
                    <div
                        className={icons ? "download-icon-link justify-content-between" : "download-link d-flex justify-content-between"}
                        onClick={() => handleUploadedDocuments()}
                    >
                        <div className="d-flex align-items-center">
                            {icons && <div className="iconContainer">
                                <POWRIcon
                                    className="fal fa-file-alt"
                                    color={COLOR.GREY}
                                    size="24px" />
                            </div>}
                            <Link>{translate('aux.viewUploadedDocuments')}</Link>
                        </div>
                        <InfoTooltip>{translate('message.viewUploadedDocuments')}</InfoTooltip>
                    </div>
                }
                {(((generate_invoice || (userAdmin && showCommercial)) && !(notSubmitted || denied)) || !(notSubmitted || denied)) && displayPrintLabelsAndDoc &&
                    <div
                        className={icons ? "download-icon-link" : "download-link"}
                        onClick={() => printAllDisplayModal ? handleCommercialInvoice(idd, ship_code, FORM_TYPE.COMMERCIAL_INVOICE, true, !(notSubmitted || denied)) : print(FORM_TYPE.ZIP, idd)}
                    >
                        {icons && <div className="iconContainer">
                            <POWRIcon
                                className="fal fa-file-alt"
                                color={COLOR.GREY}
                                size="24px" />
                        </div>}
                        <Link>{translate('title.printLabelsDocumentation')}</Link>
                    </div>
                }
            </div>
            {modalTemplate != null &&
                <POWRModal
                    show={true}
                    title={translate(`title.${modalTemplate.type === FORM_TYPE.ZIP ? FORM_TYPE.COMMERCIAL_INVOICE : modalTemplate.type}Form`)}
                    size="long"
                    onClose={() => setModalTemplate(null)}
                >
                    <CommercialInvoiceForm
                        onClose={() => setModalTemplate(null)}
                        lines={modalTemplate.data}
                        print={() => print(modalTemplate.type, modalTemplate.shipId)}
                        type={modalTemplate.type}
                        shipCode={modalTemplate.shipCode}
                        fromPrintAll = {modalTemplate.fromPrintAll}
                        shipId = {modalTemplate.shipId}
                    />
                </POWRModal>
            }
        </div>
    ) : <div />
}