export const WEIGHT_UNITS = [
    { code: 0, text: 'NA' },
    { code: 1, text: 'kg' },
    { code: 2, text: 'lbs' }
];

export const LENGTH_UNITS = [
    { code: 0, text: 'NA' },
    { code: 1, text: 'm' },
    { code: 2, text: 'in' },
    { code: 3, text: 'cm' },
    { code: 4, text: 'ft' }
];