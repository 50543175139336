import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactCard } from '../index';
import { Input, Link, Checkbox, Button } from '../controls';
import { savePickup } from '../../services/pickupService';
import ActionButtons from '../controls/actionButtons';
import { useTranslate } from '../../core/contexts';
import {
    selectReturnFlowSavedInfo, selectSchedulePickup, selectZoneInstructions,
    selectSublines, selectShipmentId, selectLabelOnly, selectPackages, selectSelectionsInfo
} from '../../core/redux/selectors';
import { POWRModal, InfoSection, POWRIcon } from '../../components/styledComponents';
import { selectBaseRMAInfo, selectIsFA, selectTurkeyRevenue, selectCategoryCode } from '../../core/redux/selectors/rma.selectors';
import { COUNTRY, REGEXP, RFC_TAX_REGIME } from '../../core/constants';
import { useAlert, useGlobalMessage, useCountry, useConfigParams } from '../../core/hooks';
import { RETURN } from '../../core/constants/ROUTES';
import { COLOR } from '../../core/constants/Colors';
import { setLoader, setReturnFlowProp, updatePickupAddress } from '../../core/redux/actions';
import { gtagService, getReturnAddress } from '../../services';

const Summary = ({ step, back, next }) => {
    const { translate } = useTranslate();
    const { showAlert, removeAlert } = useAlert();
    const dispatch = useDispatch();
    const { getCountry } = useCountry();
    const { getSATServiceEnabledService, getSATServiceEnabledProduct } = useConfigParams();
    const [mails, setMails] = useState([{ email: "", error: false }]);
    const [validateInputs, setValidateInputs] = useState(true);
    const [isAgreeTerms, setIsAgreeTerms] = useState(false);
    const [isAckPayment, setIsAckPayment] = useState(false);
    const [carrierAccount, setCarrierAccount] = useState("");
    const [showModalAckPayment, setShowModalAckPayment] = useState(false);
    const [showModalAckPaymentComfirm, setShowModalAckPaymentComfirm] = useState(false);
    const [turkeyRevenue, setTurkeyRevenue] = useState(false);
    const [showTurkeyBox, setShowTurkeyBox] = useState(false);
    const [returnAddress, setReturnAddress] = useState(null);
    const [agreeTermsError, setAgreeTermsError] = useState(false);
    const [ackPaymentError, setAckPaymentError] = useState(false);
    const [carrierAccountError, setCarrierAccountError] = useState('');
    const [billingAddr, setBillingAddr] = useState(null);

    const savedShipment = useSelector(selectReturnFlowSavedInfo);
    const savedSchedule = useSelector(selectSchedulePickup);
    const packages = useSelector(selectPackages);
    const shipmentId = useSelector(selectShipmentId);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const labelOnly = useSelector(selectLabelOnly);
    const sublines = useSelector(selectSublines);
    const turkeyEnable = useSelector(selectTurkeyRevenue);
    const selectionsInfo = useSelector(selectSelectionsInfo);
    const isFA = useSelector(selectIsFA);
    const zoneInstructions = useSelector(selectZoneInstructions);
    const { showPageMessage } = useGlobalMessage(); // Global Messages
    const showPaidByUserAlertFlag = false; 
    const countryV = getCountry;
    const categoryCode = useSelector(selectCategoryCode);
    const isBaAvailable = categoryCode === 'S' ? getSATServiceEnabledService() : getSATServiceEnabledProduct();

    const showPaidByUserAlert = () => {            
        let retVal = false;
        if (!isFA && rmaInfo && rmaInfo.paymentType && rmaInfo.paymentType[0]===1) {
            retVal = true;
        }
        if (isFA && showPaidByUserAlertFlag && rmaInfo && rmaInfo.paymentType && rmaInfo.paymentType[0]===1) {
            retVal = true;
        }
        return retVal;
    }

    const isCustomerPayment = showPaidByUserAlert();


    useEffect(() => {
        if (selectionsInfo && selectionsInfo.billingAddress) {
            const { billingAddress } = selectionsInfo;
            setBillingAddr({
                title: translate('title.billingAddressTitle'),
                info: [
                    `RFC: ${billingAddress.rfc}`,
                    `${translate('formLabel.zip')}: ${billingAddress.postalCode || ''}`,
                    `${translate('formLabel.companyName')}: ${billingAddress.fullCompanyName || ''}`,
                    `${translate('formLabel.street')}: ${billingAddress.streetAddress || ''}`,
                    `${translate('formLabel.externalNumber')}:${billingAddress.externalNumber || ''}`,
                    `${translate('formLabel.internalNumber')}:${billingAddress.internalNumber || ''}`,
                    `${translate('formLabel.neighborhood')}:${billingAddress.neighborhood || ''}`,
                    `${translate('gridColumns.city')}: ${billingAddress.city || ''}`,
                    `${translate('formLabel.state')}: ${billingAddress.state || ''}`
                ]
            });
        }
    }, selectionsInfo)

    useEffect(() => {
        if (isAgreeTerms) setAgreeTermsError(false);
        if (isAckPayment) setAckPaymentError(false);
        if (carrierAccount) setCarrierAccountError('');
    }, [isAgreeTerms, isAckPayment, carrierAccount]);

    useEffect(() => {
        if (rmaInfo && rmaInfo.returnAddress && savedSchedule) {
            const { carrier } = savedSchedule;
            getReturnAddress(shipmentId, carrier.idd).then((res) =>{
                const address = res.res.dtos && res.res.dtos[0];
                const prefix = !address ? '' : address.fa_name ? 'fa_' : 'critical_';
                
                const ad = !address ? rmaInfo.returnAddress : {
                    name:     address[`${prefix}name`],
                    addr1:    address[`${prefix}addr1`],
                    addr2:    address[`${prefix}addr2`],
                    addr3:    address[`${prefix}addr3`],
                    addr4:    address[`${prefix}addr4`],
                    city:     address[`${prefix}city`],
                    province: address[`${prefix}province`],
                    postal:   address[`${prefix}postal`],
                    country:  address[`${prefix}country`]
                };

            let info = [];
            for (let i = 0; i <= 4; i++) {
                if (ad[`addr${i + 1}`]) info.push(ad[`addr${i + 1}`]);
            }
            setReturnAddress({
                title: ad ? ad.name : '',
                info: [
                    (ad ? ad.name: ''),
                    ...info,
                    (ad ? `${ad.city ? (ad.city + ', ') : ''}${ad.province ? (ad.province + ', ') : ''}${ad.postal ? (ad.postal + ' ') : ''}${ad.country || ''}` : '')
                ]
            });
        })
        }
    }, [rmaInfo, savedSchedule, translate]);

    useEffect(() => {
        if (turkeyEnable && selectionsInfo.pickup.country === COUNTRY.TURKEY) setShowTurkeyBox(true);
    }, []);

    useEffect(() => {
        if (savedSchedule) {
            if (savedSchedule.notifications.pickup_notifications) {
                savedSchedule.notifications.pickup_notifications.map((mail, i) => {
                    setMails([{ email: mail.email, error: false }])
                })
            }
        }
    }, [shipmentId, savedSchedule]);

    useEffect(() => {
        let params = {
            module: 'GCT_Step4',
            country: rmaInfo.country,
            rmaCategory: categoryCode
        }
        showPageMessage(params, '/return');
    }, []);


    const handleChangeCarrierAccount = (e) => {
        if (REGEXP.NUMBERS.test(e.target.value)) {
            setCarrierAccount(e.target.value);
        }
    }

    const handleChange = (e, i) => {
        let value = e.target.value;
        let values = [...mails];

        if (REGEXP.EMAIL_NOTI.test(e.target.value)) {
            values[i] = { email: value, error: false };

        }
        else {
            if (values.length === 1 && value === "")
                values[i] = { email: value, error: false };
            else
                values[i] = { email: value, error: true };
        }

        setMails(values);
        setValidateInputs(values.filter(a => a.error).length == 0);
    }

    const addNewMail = function () {
        let values = [...mails, { email: "", error: false }];
        setMails(values);
    }

    const handleRemove = function (i) {
        let values = [...mails];

        values.splice(i, 1);
        setMails(values);
        if (values.length === 1 && values[0].email === "")
            values[0] = { email: "", error: false };
        setValidateInputs(values.filter(a => a.error).length == 0);
    }

    const confirmSubmit = function (from) {
        dispatch(setLoader(true));
        setShowModalAckPaymentComfirm(false);
        let m = "";
        if (mails && mails.length)
            mails.forEach(element => {
                if (element.email) m += element.email + ", "
            });
        let emails = m.length ? m.slice(0, -2) : "";
        const { carrier, instruct, pickup, paperless } = savedSchedule;
        let data = {
            "ship_id": shipmentId,
            "pickup_id": savedShipment.shipmentId,
            "json": {
                "packages": packages.map(item => (
                    {
                        ...item,
                        "ship_code": `${rmaInfo && rmaInfo.country}${shipmentId || ''}`,
                        "serial": sublines && sublines.find(x => x.package_id === item.idd).serial,
                        "sn_lookup": sublines && sublines.find(x => x.package_id === item.idd).serial
                    }
                )),
                "carrier": {
                    "carrier_type": labelOnly ? "4" : "1",
                    "carrier": "",
                    "carrier_code": carrier.carrier_code,
                    "carrier_service_code": carrier.service_code,
                    "carrier_service_id": carrier.carrier_service_id,
                    "carrier_service_level_id": carrier.idd,
                    "service_level": carrier.service_level,
                    "carrier_profile_id": carrier.carrier_profile_id
                },
                "pickup": {
                    ...pickup,
                    "additional_instr": zoneInstructions,
                    "site_directions": selectionsInfo.additional,
                    "ack_returner_pays": isAckPayment ? "on" : "",
                    "returner_pays_account": isAckPayment ? carrierAccount : ""
                },
                instruct,
                "notifications": {
                    "pickup_notify": emails
                },
                "userDocumentsRequired": savedShipment && savedShipment.attachedFiles.length > 0,
                "paperless": paperless,
                "generate_sli": instruct.generate_sli, // should come from schedule later
                "generate_packing_lst": instruct.generate_packing_lst, // should come from schedule later
                "is_fact_return": isFA // should come from schedule later
            },
            "confirm": from === 'resumePickup' ? false : true // should come from schedule later
        };

        savePickup(data).then((response) => {
            if (response.res.success) {
                next();
                dispatch(setReturnFlowProp('RMASavedID', null));
                dispatch(setLoader(false));
                gtagService.event('Shipments', 'submitShipment');
            }
            else {
                if (response.res.errors) {
                    let index = response.res.errors[0].msg.indexOf(':');
                    let msg = response.res.errors[0].msg.substring(index + 1);
                    showAlert({ id: 'invalidRes', message: msg, color: 'yellow', page: RETURN, });
                }
                else showAlert({ id: 'invalidResE', message: translate('message.savePickupError'), color: 'red', page: RETURN, });

                dispatch(setLoader(false));
            }
        }).catch(err => {
            showAlert({ id: 'savePickupBroken', message: translate('message.savePickupError'), color: 'yellow', page: RETURN });
            dispatch(setLoader(false));
            console.log(err);
        });
    };

    const handleSubmit = function (from) {
        updateShipment();
        removeAlert('mandatoryFields');
        if (canSave) confirmSubmit(from);
        else {
            let fieldErrors = [];
            if (!isAgreeTerms) {
                setAgreeTermsError(true)
                fieldErrors.push(translate('message.termsMessage'));
            }
            if (isCustomerPayment && !isAckPayment) {
                setAckPaymentError(true);
                fieldErrors.push(translate('formLabel.acknowledgePayment'));
            }

            if (isCustomerPayment && isAckPayment && !carrierAccount) {
                setCarrierAccountError(translate("error.required"));
                fieldErrors.push(translate('formLabel.carrierAccount'));
            }

            showAlert({ id: 'mandatoryFields', page: RETURN, message: `${translate('message.mandatoryFieldsPrincipal')} ${fieldErrors.join(', ')}`, color: 'yellow' });
        }
    }

    const handleBack = function () {
        updateShipment();
        back();
    }

    const updateShipment = () => {
        let newPickup = {
            ...selectionsInfo.pickup,
            site_directions: selectionsInfo.additional
        }
        dispatch(updatePickupAddress(newPickup));
    }

    const canSave = useMemo(() =>
        validateInputs && isAgreeTerms && (!showTurkeyBox || turkeyRevenue) && (!isCustomerPayment || (isAckPayment && carrierAccount))
        , [validateInputs, isAgreeTerms, turkeyRevenue, isCustomerPayment, isAckPayment, carrierAccount, showTurkeyBox]);
     
    return (
        <Fragment>
            <div className="summary">
                <div className="infoContentBox">
                    <h3>{translate('gridColumns.shipmentID')}: {`${selectionsInfo ? selectionsInfo.pickup.country : ''}${shipmentId || ''}`}</h3>
                </div>
                <div className="infoContainer">
                    <div className="mb-3">
                        <div className="infoHead mb-3">
                            <h3>{translate('title.returnAddress')}</h3>
                        </div>

                        <ContactCard content={returnAddress} hideButtons={true} />
                    </div>
                </div>
                <div className="infoContainer">
                    <div className="infoHead">
                        <h3>{`${translate("title.emailNotification")} (${translate("subtitle.optional")})`}</h3>
                    </div>
                    <div className="infoBody">
                        {mails.map((item, i) => (
                            <div className="row" key={i}>
                                <div className="col-12">
                                    <Input error={mails[i].error} errorMessage={translate("error.email")} value={mails[i].email} onChange={(e) => handleChange(e, i)} label={translate("formLabel.email")} placeholder={translate("placeholder.addEmail")} />
                                    {mails.length > 1 && <div className="iconPosition" onClick={() => handleRemove(i)}>
                                        <POWRIcon className="far fa-trash-alt mt-4" size="16px" color={COLOR.CISCO_BLUE} />
                                    </div>}
                                </div>
                            </div>
                        ))}
                        <div className="d-flex justify-content-end">
                            <Link onClick={addNewMail} disabled={!(mails[mails.length - 1] && mails[mails.length - 1].email && !mails[mails.length - 1].error)} >
                                + {translate("title.additionalEmail")}
                            </Link>
                        </div>
                        {(isBaAvailable && selectionsInfo?.pickup?.country === COUNTRY.MEXICO) && <ContactCard content={billingAddr} hideButtons={true} className="mb-4 mt-4"/>}
                    </div>
                    <div className="infoBody">
                        {isCustomerPayment &&
                            <Checkbox
                                className="displayCheckBox"
                                onClick={() => setIsAckPayment(!isAckPayment)}
                                checked={isAckPayment}
                                id={'acknPayment'}
                                text={translate('formLabel.acknowledgePayment')}
                                smallText
                                error={ackPaymentError} />
                        }
                        {isAckPayment &&
                            <Input
                                value={carrierAccount}
                                onChange={(e) => handleChangeCarrierAccount(e)}
                                label={translate('formLabel.carrierAccount')}
                                placeholder={translate('placeholder.enterCarrierAccount')}
                                style={{ marginBottom: '2rem' }}
                                error={carrierAccountError}
                            />
                        }
                        {showTurkeyBox &&
                            <InfoSection type="taskBar" className="pt-16" hideBorders>
                                <div className="infoBody mt-3">
                                    <Checkbox checked={turkeyRevenue} onClick={() => setTurkeyRevenue(!turkeyRevenue)} text={translate("formLabel.turkeyRequirements")} />
                                </div>
                            </InfoSection>
                        }
                        <Checkbox
                            className="displayCheckBox"
                            onClick={() => setIsAgreeTerms(!isAgreeTerms)}
                            checked={isAgreeTerms}
                            id={'agreeTerms'}
                            text={translate('message.terms')}
                            smallText
                            error={agreeTermsError}
                        />
                    </div>
                </div>
            </div>
            <ActionButtons
                step={step}
                secondary={handleBack}
                sText={translate('button.back')}
                primary={handleSubmit}
                pText={translate('button.submit')}
                completed={true}
            />
            <POWRModal
                size="tiny"
                show={showModalAckPayment}
                title={translate('title.validation')}
                onClose={setShowModalAckPayment.bind(this, false)} >
                <div className="content">
                    <p>{!isAckPayment ? translate('message.acknowledgeConfirmChkbox') : !carrierAccount.length ? translate('message.acknowledgeConfirmInput') : ''}</p>
                </div>
                <div className="toolbar">
                    <Button onClick={setShowModalAckPayment.bind(this, false)} >
                        {translate('button.ok')}
                    </Button>
                </div>
            </POWRModal>
            <POWRModal
                size="small"
                show={showModalAckPaymentComfirm}
                title={translate('button.confirm')}
                onClose={setShowModalAckPaymentComfirm.bind(this, false)} >
                <div className="content">
                    <p>{translate('message.confirmAcknowledgeModal')}</p>
                </div>
                <div className="toolbar">
                    <Button
                        onClick={setShowModalAckPaymentComfirm.bind(this, false)}
                        type="2" >
                        {translate('button.cancel')}
                    </Button>
                    <Button onClick={() => confirmSubmit()} >
                        {translate('button.ok')}
                    </Button>
                </div>
            </POWRModal>
        </Fragment>
    );
}

export default Summary;