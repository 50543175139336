import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetReturnFlow, resetRMAInfo, setDocumentationErrorMsg } from '../core/redux/actions';
import { selectDocumentationInputRMA, selectDocumentationResults, selectUserInfo } from '../core/redux/selectors';
import { getRMA, saveRMA, getContactInfo } from '../services';
import { useTranslate } from '../core/contexts';
import { STORAGE } from '../core/constants';
import { useRMAValidation } from "../core/hooks";
import { POWRButton } from './styledComponents';
import { RETURN } from '../core/constants/ROUTES';

export default props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { translate } = useTranslate();
    const { isValidRMADashboard } = useRMAValidation();

    const inputRMA = useSelector(selectDocumentationInputRMA);
    const results = useSelector(selectDocumentationResults);
    const userInfo = useSelector(selectUserInfo);

    const clearSessionStorage = () => {
        sessionStorage.removeItem(STORAGE.SEARCH_NAME)
        sessionStorage.removeItem(STORAGE.SAVE_NAME)
        sessionStorage.removeItem(STORAGE.CONTACT_INFO)
        sessionStorage.removeItem(STORAGE.LABEL_ONLY)
        sessionStorage.removeItem(STORAGE.RESUME_PICKUP)
        sessionStorage.removeItem(STORAGE.RESUME_SHIPMENT)
    }

    // I do the asynchronous function (searchRMAFromDocument) to wait for the getRma function to execute first
    const handleReturn = async () => {
        if (userInfo.rmaLogin) history.push(RETURN);
        else {
            const paramsSave = await searchRMAFromDocument();
            if (paramsSave) {
                handleSaveFromDocumentRMA(paramsSave);
            }
        }
    }

    const searchRMAFromDocument = () => {
        const parameters = {
            param_rma_or_tradein: inputRMA,
            param_fa_case_num: "",
            isSimpleSearch: true,
            isReturnFa: false
        };
        return getRMA(parameters).then(response => {
            if (isValidRMADashboard(response).isValid) {
                let dtos = response.res.dtos;
                const rmas = inputRMA.split(',');
                if (dtos.length > 0) {
                    const paramsSave = {
                        ship_id: 0,
                        rmas: rmas.length > 1 ? rmas.sort((a, b) => a > b) : rmas[0],
                        simple: true
                    };
                    sessionStorage.setItem(STORAGE.SEARCH_NAME, JSON.stringify(dtos));
                    return paramsSave;
                } else {
                    clearSessionStorage();
                    dispatch(setDocumentationErrorMsg('RMA ' + inputRMA + translate('message.wasNotFound')));
                    return null;
                }
            }
        }).catch((e) => {
            clearSessionStorage();
        });
    }

    // I save the rma and set the information in redux to redirect to /return
    const handleSaveFromDocumentRMA = (paramsSave) => {
        saveRMA(paramsSave).then(response => {
            if (response.res.success) {
                let dtosSave = response.res.dtos;
                const paramsCInfo = {
                    ship_id: dtosSave[0].idd
                };
                sessionStorage.setItem(STORAGE.SAVE_NAME, JSON.stringify(dtosSave));
                getContactInfo(paramsCInfo).then(response => {
                    if (response.res.success) {
                        let dtosCInfo = response.res.dtos;
                        sessionStorage.setItem(STORAGE.CONTACT_INFO, JSON.stringify(dtosCInfo));
                        dispatch(resetRMAInfo());
                        dispatch(resetReturnFlow());
                        history.push(RETURN);
                    } else {
                        clearSessionStorage();
                    }
                }).catch(() => {
                    clearSessionStorage();
                });
            } else {
                clearSessionStorage();
            }
        }).catch(() => {
        });
    }

    return (
        <div className="tool-bar-documentation">
            <div className="d-flex justify-content-between">
                <h3>{translate('subtitle.returnRequest')}</h3>
            </div>
            <div className="line" />
            <p>{translate('message.alreadyDocumentation')}</p>
            <div className="request-button" >
                <POWRButton onClick={handleReturn} disabled={!results}>
                    {translate('button.requestReturn')}
                </POWRButton>
            </div>
            <div className="back-button">
                <POWRButton color="secondary" onClick={() => history.goBack()}>
                    {translate('button.back')}
                </POWRButton>
            </div>
        </div>
    );
}