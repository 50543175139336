
import ReactGA from 'react-ga4';

export const gtagService = {
    event: (category, action, label, value) => {
        // if (!window.gtag) return;
        let eventBody = {};
        if (category) eventBody['category'] = category;
        if (action) eventBody['action'] = action;
        if (label) eventBody['label'] = label;
        if (value) eventBody['value'] = value;
        ReactGA.event(eventBody);
    },
    pageView: (path) => {
        ReactGA.send({ hitType: "pageview", page: path });
        //ReactGA.pageview(path);
    },
    init: (key) => {
        ReactGA.initialize(key, { debug: false })
    }
};
