import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setShipmentsSearching, setShipmentSeleted, setShipmentsSearchResults, setShipmentsReload } from '../../core/redux/actions';
import { selectShipmentsSearchParameters, selectShipmentsSearching, selectShipmentSeleted, selectShipmentsSearchResults, selectShipmentsReload, selectUserInfo } from '../../core/redux/selectors';
import { getShipments } from '../../services';
import { useTranslate } from '../../core/contexts';
import { ShipmentSearchBar, ShipmentsInfoBar } from '../../components';
import { ShipmentsList } from '../../components/shared';
import { Link } from '../../components/controls';
import { FullPage } from '../../components/AppShell/partials/FullPage.react';
import { useGlobalMessage, useShipmentSearch } from '../../core/hooks';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showPageMessage } = useGlobalMessage(); // Global Messages
    const { searchShipments, handleExport } = useShipmentSearch();

    const searching = useSelector(selectShipmentsSearching);
    const reload = useSelector(selectShipmentsReload);
    const shipment = useSelector(selectShipmentSeleted);
    const data = useSelector(selectShipmentsSearchResults);
    const userInfo = useSelector(selectUserInfo);
    const parameters = !userInfo.rmaLogin ? useSelector(selectShipmentsSearchParameters) :
    {
        param_ship_code: "",
        param_rma_or_tradein: userInfo.rma,
        param_tn: "",
        param_date_from: moment().subtract(6, 'month').format('YYYY-MM-DD'),
        param_date_to: moment().format('YYYY-MM-DD'),
        param_ship_status: "",
        param_max_records: 501,
        param_exclude_saved: 'on',
        param_only_mine: 'on',
    };

    const [canceled, setCanceled] = useState(false);

    const setSearching = (v) => {
        dispatch(setShipmentsSearching(v))
    }

    useEffect(() => {
        dispatch(setShipmentSeleted({}));
        dispatch(setShipmentsReload(null));
        setSearching(true);
    }, []);

    useEffect(() => {
        if (reload) {
            let remove = reload.type === 'cancel';
            let dat = data;
            var index = dat.indexOf(reload.shipment);
            if (index !== -1) {
                if (remove) {
                    dat = dat.filter(x => x !== reload.shipment);
                    dispatch(setShipmentSeleted({}));
                    setCanceled(true);
                } else {
                    const params = {
                        param_ship_code: reload.shipment.ship_code, param_max_records: 1, param_exclude_saved: 'on'
                    }; 
                    getShipments(params).then(response => {
                        let ship = response.res.dtos[0];
                        dispatch(setShipmentSeleted(ship));
                    }).catch(err => {
                        console.log(err);
                    });
                    let newStatus = reload.type === 'reenable';
                    let pickup = reload.type.date ? moment(reload.type.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
                    let time = reload.type.time ? reload.type.time === 1 ? "AM" : "PM" : "ANY";
                    if (newStatus) {
                        dat[index].status = 3;
                        dispatch(setShipmentSeleted(dat[index]));
                    };
                    if (pickup) {
                        dat[index].ship_event = 4;
                        dat[index].date_pickup = pickup;
                        dat[index].pickup_schedule_time = time;
                    }
                }
                dispatch(setShipmentsSearchResults(dat));
            }
            setSearching(false);
            dispatch(setShipmentsReload(null));
        }
    }, [reload]);

    useEffect(() => {
        let params = {
            module:'GCT_SEARCH_SHIPMENTS',
            country:'',
            rmaCategory:''
        }
        showPageMessage(params, '/shipments');
    }, []);

    useEffect(() => {
        if (parameters && searching) {
            setCanceled(false);
            searchShipments(parameters);
        }
    }, [parameters, searching]);

    const handleItem = (id) => {
        let newItems = data.filter(d => d.idd === id);
        dispatch(setShipmentSeleted(newItems.length ? newItems[0] : {}));
    }
    return (
        <FullPage>
            <div className="d-flex d-xl-none" >
                <div className="col-12 pb-16 mt-3 mt-sm-0">
                    <h1 className="m-0">{translate('title.shipments')}</h1>
                </div>
            </div>
            {!userInfo.rmaLogin && 
                <div className={shipment?.idd ? 'd-none' : 'col-12 col-xl-3 p-xl-0'}>
                    <ShipmentSearchBar location={props.location} canceled={canceled} />
                </div>
            }
            <div className={userInfo.rmaLogin && !shipment?.idd ? "col-12" : "col-12 col-xl-9 p-xl-0"}>
                <div className="main-shipments">
                    <div className="d-none d-xl-flex head-lg justify-content-between align-items-center">
                        <h1>{translate('title.shipments')}</h1>
                        <Link onClick={() => handleExport(parameters)}>
                            {translate('button.exportToExcel')}
                        </Link>
                    </div>
                    <label className="pb-2">{translate('subtitle.carrierToBringLabel')}</label>
                    {searching ?
                        <p>{translate('aux.loading')}</p>
                        :
                        <ShipmentsList
                            full={true}
                            tableId="shipmentsFullTable"
                            items={data}
                            selectedItem={shipment?.idd}
                            onClick={handleItem.bind(this)}
                        />
                    }
                </div>
            </div>
            {shipment?.idd && <div className="d-none d-sm-flex col-12 col-xl-3 p-xl-0 pt-2 pt-xl-0" >
                <ShipmentsInfoBar />
            </div>}
        </FullPage>
    );
}
