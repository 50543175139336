import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../core/contexts';
import { CancelAndSaveModal, UnsavedChangesModal } from '../modals';
import { POWRLink } from '../styledComponents';
import { selectClickOutsideReturn } from '../../core/redux/selectors';

export const CancelAndSaveButtons = ({ step, save }) => {
    const { translate } = useTranslate();
    const [showCancelSaveModal, setShowCancelSaveModal] = useState(false);
    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const [type, setType] = useState('');
    const clickOutsideReturn = useSelector(selectClickOutsideReturn);

    const toggleModal = (typeModal) => {
        setType(typeModal)
        setShowCancelSaveModal(!showCancelSaveModal)
    }

    useEffect(() => {
        if (clickOutsideReturn) setUnsavedChangesModal(true)
    }, [clickOutsideReturn])

    return (
        <Fragment>
            <div className="cancel-save-container">
                <hr />
                <div className={`cancel-save-btn ${step === 0 ? 'align-self-end' : ''}`}>
                    {step !== 0 && <POWRLink onClick={() => toggleModal('cancel')}>{translate('title.cancelAndReturn')}</POWRLink>}
                    <POWRLink onClick={() => toggleModal('save')}>{translate('title.saveAndReturn')}</POWRLink>
                </div>
            </div>
            <CancelAndSaveModal
                show={showCancelSaveModal}
                type={type}
                close={() => setShowCancelSaveModal(!showCancelSaveModal)}
                save={(step === 3 || step === 4) ? () => save('resumePickup') : save}
            />
            <UnsavedChangesModal
                show={unsavedChangesModal}
                close={() => setUnsavedChangesModal(!unsavedChangesModal)}
                save={(step === 3 || step === 4) ? () => save('resumePickup') : save}
                to={clickOutsideReturn}
            />
        </Fragment>
    )
}

