import React from 'react';
import { COLOR } from '../../core/constants/Colors';
import { useTranslate } from '../../core/contexts';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';

export const ExpireSessionModal = ({ handleRefresh, handleLogOut, show }) => {
    const { translate } = useTranslate();

    return (
        <POWRModal
            show={show}
            title={translate('title.expireSession')}
            size={'medium'}
            onClose={handleRefresh}>
            <div className="content">
                <div className="x-centered mt-3 mb-4">
                    <POWRIcon
                        className="fas fa-exclamation-triangle mb-3"
                        size="3.5em"
                        color={COLOR.CISCO_BLUE} />
                </div>
                <div className="text-center">
                    <h3>{translate('message.logoutSession')}</h3>
                    <p>{translate('message.extendSession')}</p>
                </div>
            </div>
            <div className="toolbar">
                <POWRButton color={'secondary'} onClick={handleLogOut}>{translate('button.no')}</POWRButton>
                <POWRButton onClick={handleRefresh}>{translate('button.yes')}</POWRButton>
            </div>
        </POWRModal>
    )
}