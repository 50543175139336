import React, { Fragment } from 'react';
import { useInView } from "react-intersection-observer";

/**
 * A wrapper component for children of
 * VirtualScroll. Computes inline style and
 * handles whether to display props.children.
 */
const VirtualScrollChild = ({ height, children, overflowUnset, type, ...props }) => {
    const [ref, inView] = useInView();
    const style = {
        minHeight: `${height}px`,
        overflow: overflowUnset ? 'unset' : 'hidden'
    };
    return (
        <Fragment>
            {
                type !== 'table' ?
                    <div style={style} ref={ref} {...props}>
                        {inView ? children : null}
                    </div>
                    :
                    <tr style={style} ref={ref} {...props}>
                        {inView ? children : null}
                    </tr>
            }
        </Fragment>
    );
}

export default VirtualScrollChild;