import React, { useState, useEffect } from 'react';
import moment from 'moment';
import circle from '../../static/icons/blue_circle.svg';
import { useTranslate } from '../../core/contexts';
import { getShipmentDetails, getCarrierProfile } from '../../services';

export default ({id, shipment}) => {
    const { translate } = useTranslate();
    const [ data, setData ] = useState(null);
    const [ carrier, setCarrier ] = useState(null);
    const [ message, setMessage ] = useState(false);
    const [ pallets, setPallets ] = useState(false);
    const [ palletsN, setPalletsN ] = useState(0);
    const [ lift, setLift ] = useState(false);
    const [ additionalInstr, setAdditionalInstr ] = useState([]);
    const dateFormat = sessionStorage.getItem('dateFormat');
    const timeFormat = sessionStorage.getItem('timeFormat');

    useEffect(() => {
        getShipmentDetails({ id }).then(res => {
            if (res.res && res.res.success) setData(res.res.dtos);
        }).catch(console.log);
        getCarrierProfile({ ship_id: id }).then(res => {
            if (res.res && res.res.success) setCarrier(res.res.dtos[0]);
        }).catch(console.log);
    }, [shipment]);

    useEffect(() => {
        setLift(data && data[0] && data[0].equip_lift_gate > 0);
        setPallets(data && data[0] && data[0].equip_pallet_jack > 0);
        setPalletsN(data && data[0] && data[0].oversized_items > 0 ? data[0].oversized_items : 0);
        let additional_instr = data && data[0] && data[0].additional_instr ? data[0].additional_instr : '';
        setAdditionalInstr(additional_instr.trim().split("\n"));
    }, [data]);

    return data ? (
        <div className="carrierContents">
            <div className="row">
                <div className="col-12 col-xl-8">
                    <div className="carrierTable">
                        {carrier && <h3 className="m-0">
                            {carrier.carrier_service_descr} ({carrier.carrier_code} - {carrier.carrier_service_code})
                        </h3>}
                        {message && <div className="row">
                            <div className="col-6" />
                            <div className="col-6">
                                <label className="strong">
                                    {translate('message.carrierMessage')}
                                </label>
                            </div>
                        </div>}
                        {data && data.map((option, i) => {
                            let date = moment(`${option.action_time}`, 'YYYYMMDDHHmmss').format(dateFormat) || '—';
                            let time = moment(`${option.action_time}`, 'YYYYMMDDHHmmss').format(timeFormat) || '—';
                            let msg = option.carrier_error_message && option.carrier_error_message !== 'null';
                            if (msg && !message) setMessage(true);
                            let last = i >= data.length - 1;
                            return (
                                <div className="row" key={i}>
                                    <div className="col-6 d-flex">
                                        <div className={last ? "timeOption mb-0" : "timeOption py-2"}>
                                            <label>{date}</label>
                                            <label>{time}</label>
                                        </div>
                                        <div
                                            key={`historyOption-${i}`}
                                            className={last ? "historyOption" : "historyOption dot-line py-2"}
                                        >
                                            <img
                                                src={circle}
                                                alt="circle"
                                                className="icon"
                                            />
                                            <label className="strong">
                                                {option.event_type}
                                            </label>
                                        </div>
                                    </div>
                                    <div className={last ? "carrierMessage col-6" : "carrierMessage col-6 py-1"} >
                                        {msg && <p>{option.carrier_error_message}</p>}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="col-12 col-xl-4">
                    {((pallets && palletsN > 0) || lift) && <div className="carrierTable mb-3">
                        <h3>{translate('title.specialEquipment')}</h3>
                        {pallets && <p>{translate('aux.requiresPallets')}</p>}
                        {lift && <p>{translate('aux.requiresLiftTruck')}</p>}
                        {pallets && palletsN > 0 && <div>
                            <label className="strong">{translate('formLabel.pallets')}</label>
                            <p>{palletsN}</p>
                        </div>}
                    </div>}
                    {additionalInstr.length > 0 && additionalInstr[0] &&
                        <div className="additionalInstr">
                            <h4>{translate('subtitle.complianceInformation')}</h4>
                            {additionalInstr.map((inst, i) => (
                                <p className="m-0" key={`instructions-${i}`}>{inst}</p>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    ) : <div />
}   