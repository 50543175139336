import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Checkbox, Link, Spinner } from './controls';
import { useTranslate } from '../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import { loadSubLines, setReturnFlowProp } from '../core/redux/actions';
import {
    selectGroupedLines, selectSelectedLines, selectSublines,
    selectShipmentId, selectRMAsNum
} from '../core/redux/selectors';
import { SET_SUB_LINES } from '../core/redux/actionTypes';
import { VirtualScrollChild } from './shared';
import { POWRIcon } from './styledComponents';
import { useConfigParams } from '../core/hooks';
import { gtagService } from '../services';
import { REGEXP, COLOR } from '../core/constants';

const SelectParts = () => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { getRMAPackagingLink } = useConfigParams();
    const [search, setSearch] = useState("");
    const [sortProp, setSortProp] = useState({ name: 'line', dir: false });
    const [sortedList, setSortedList] = useState([]);
    const [allCollapsed, setAllCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const shipmentId = useSelector(selectShipmentId);
    const rmaNums = useSelector(selectRMAsNum);
    const rmasWithLines = useSelector(selectGroupedLines);
    const sublines = useSelector(selectSublines);
    const selectedLines = useSelector(selectSelectedLines);

    const rmaPackagingLink = getRMAPackagingLink()

    const loadAvailableSublines = useCallback(async() => {
        setIsLoading(true);
        let savedSublines = sessionStorage.getItem('savedSublines');
        savedSublines = savedSublines ? JSON.parse(savedSublines) : [];
        
        await dispatch(loadSubLines(shipmentId, rmaNums));
        setIsLoading(false);
    }, [dispatch, rmaNums, shipmentId]);

    useEffect(() => {
        if (rmaNums.length && shipmentId) {
            loadAvailableSublines();
        }
    }, [rmaNums, shipmentId, loadAvailableSublines]);

    useEffect(() => {
        setAllCollapsed(!sortedList.find(x => x.collapsed === true));
    }, [sortedList]);


    const sortRMAsLines = useCallback(() => {
        let temp = rmasWithLines;
        if (!rmasWithLines.length) return [];
        temp = temp.sort((a, b) => a.rma - b.rma); // RMA is not sortable at UI

        return temp.map((rps) => ({
            ...rps,
            lines: sortProp.dir ? rps.lines.sort((a, b) => b[sortProp.name] - a[sortProp.name]) :
                rps.lines.sort((a, b) => a[sortProp.name] - b[sortProp.name]),
            collapsed: sortedList.length ? (sortedList.find(x => x.rma === rps.rma) || { collapsed: false }).collapsed : false
        }));
    }, [sortProp, rmasWithLines]);

    useEffect(() => {
        setSortedList(sortRMAsLines());
    }, [sortRMAsLines]);

    const handleSearch = (val = null) => {
        gtagService.event('NewFeatures', 'SelectPartsSearch');
        let result = sortRMAsLines(); // get original source
        const text = String(val !== null ? val : search).toLowerCase();
        if (text !== '') {
            result = result.filter(x =>
                `${translate('title.rma')}: ${x.rma}`.toLowerCase().includes(text) ||
                x.lines.find(line =>
                    `${translate('gridColumns.part#')} ${line.part}`.toLowerCase().includes(text) ||
                    `${translate('gridColumns.line')} ${line.line}`.toLowerCase().includes(text) ||
                    (
                        Number(text) && (
                            String(line.available).includes(text) ||
                            String(line.qtyPicked).includes(text)
                        )
                    )
                )
            );
            result = result.map(rmaLines => ({
                ...rmaLines,
                lines: `${translate('title.rma')}: ${rmaLines.rma}`.toLowerCase().includes(text) ? rmaLines.lines : rmaLines.lines.filter(line =>
                    `${translate('gridColumns.part#')} ${line.part}`.toLowerCase().includes(text) ||
                    `${translate('gridColumns.line')} ${line.line}`.toLowerCase().includes(text) ||
                    (
                        Number(text) && (
                            String(line.available).includes(text) ||
                            String(line.qtyPicked).includes(text)
                        )
                    ))
            }));
        }
        setSortedList(result);
    };

    const handleCollapse = (listItem) => setSortedList(
        sortedList.map((x) => x.rma === listItem.rma ? { ...x, collapsed: !listItem.collapsed } : x)
    );

    const collapseAll = (val) => setSortedList(sortedList.map((x) => ({ ...x, collapsed: val })));

    const handleQtyParts = useCallback((value, available, line, rma) => {
        let v = value === '' ? 0 : parseInt(value)
        v = v === 0 ? 1 : v;
        if (REGEXP.NUMBERS.test(value) && v <= available) {
            let sublinesOfLine = sublines.filter(sl => sl.rma_num === rma && sl.rma_line === line);
            let otherSublines = sublines.filter(sl => sl.rma_num !== rma || sl.rma_line !== line);
            // RESET picked prop for each subline of this line
            sublinesOfLine = sublinesOfLine.map((x, i) => ({ ...x, picked: false }));

            // ASSIGN picked prop as true for each subline based on qty 
            for (let i = 0; i < v; i++) { sublinesOfLine[i].picked = true; }
            dispatch({ type: SET_SUB_LINES, payload: [...otherSublines, ...sublinesOfLine] });

            // UPTADE qtyPicked prop for selectedLine
            const newSelectedLines = [...selectedLines.map(x => (x.rma === rma && x.line === line) ? { ...x, qtyPicked: v } : x)];
            dispatch(setReturnFlowProp('selectedLines', newSelectedLines));
        }
    }, [sublines, selectedLines, dispatch]);

    const handleCheck = useCallback((line, checked) => {
        if (!checked) dispatch(setReturnFlowProp('selectedLines', [...selectedLines, line]));
        else dispatch(setReturnFlowProp('selectedLines', [...selectedLines.filter(x => x.rma !== line.rma || x.line !== line.line)]));
    }, [selectedLines, dispatch]);

    const handleCheckAll = useCallback((rmaLines, checked) => {
        const newSelectedLines = [
            ...selectedLines.filter(x =>
                !rmaLines.find(y => y.rma === x.rma && y.line === x.line)
            ), ...(!checked ? rmaLines : [])
        ];
        dispatch(setReturnFlowProp('selectedLines', newSelectedLines));
    }, [selectedLines, dispatch]);

    const changeSort = (name) => sortProp.name === name ?
        setSortProp({ name, dir: !sortProp.dir }) : setSortProp({ name, dir: false });

    const allLines = useMemo(() => {
        let all = [];
        rmasWithLines.map(x => all.push(...x.lines));
        return all;
    }, [rmasWithLines]);

    return (
        <div className="selectPartsWrapper">
            <div className="row selectPartsTitleHead">
                <div className="col-12 col-lg descripction">
                    <label className="mb-0 d-block" >{translate('message.selectPartsDesc1')}</label>
                    <label>
                        {translate('message.knowAboutFollow')}
                        <Link
                            className="ml-1"
                            target="_blank"
                            href={rmaPackagingLink} >
                            {translate('subtitle.packagingInstructions')}
                        </Link>
                    </label>
                </div>
                <div className="col-12 col-lg-auto px-2">
                    <Input
                        label={translate('button.search')}
                        clear
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onEnter={() => handleSearch()}
                        onClear={() => handleSearch('')}
                        placeholder={translate('placeholder.partOrRMA')}
                        icon={{ content: "search", height: "16px", color: "#58585b", handler: () => handleSearch() }}
                    />
                </div>
            </div>
            {
                !isLoading ?
                    <div className="selectPartsContainer">
                        <table className="d-none d-lg-table">
                            <thead>
                                <tr className="packagesTitles">
                                    <th
                                        onClick={() => collapseAll(allCollapsed)}
                                        key={`allCollapsedSquare-${allCollapsed}`} >
                                        <POWRIcon className={`${allCollapsed ? "fas fa-minus-square" : "fas fa-plus-square"}`} color={COLOR.CISCO_BLUE} size="1rem" />
                                    </th>
                                    <th>
                                        <Checkbox
                                            id="cboxA"
                                            onClick={() => handleCheckAll(allLines, selectedLines.length === allLines.length)}
                                            checked={selectedLines.length === allLines.length} />
                                    </th>
                                    <th
                                        onClick={() => changeSort('line')}
                                        key={`sortPackageArrow-rma_line-${sortProp.name === 'line' && sortProp.dir}`} >
                                        <label>{translate('gridColumns.line')}</label>
                                        <POWRIcon
                                            className={`${sortProp.name === 'line' && sortProp.dir ? "fas fa-arrow-down" : "fas fa-arrow-up"} ml-1`}
                                            color={COLOR.GREY}
                                            size="11px" />
                                    </th>
                                    <th
                                        onClick={() => changeSort('available')}
                                        key={`sortPackageArrow-available_qty-${sortProp.name === 'available' && sortProp.dir}`}
                                        className="text-right" >
                                        <label>{translate('gridColumns.rmaQty')}</label>
                                        <POWRIcon
                                            className={`${sortProp.name === 'available' && sortProp.dir ? "fas fa-arrow-down" : "fas fa-arrow-up"} ml-1`}
                                            color={COLOR.GREY}
                                            size="11px" />
                                    </th>
                                    <th
                                        onClick={() => changeSort('qtyPicked')}
                                        key={`sortPackageArrow-picked_qty-${sortProp.name === 'qtyPicked' && sortProp.dir}`}
                                        className="text-center" >
                                        <label>{translate('gridColumns.qtyPickedUp')}</label>
                                        <POWRIcon
                                            className={`${sortProp.name === 'qtyPicked' && sortProp.dir ? "fas fa-arrow-down" : "fas fa-arrow-up"} ml-1`}
                                            color={COLOR.GREY}
                                            size="11px" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="scrollable-content">
                                {
                                    sortedList.map((rmaWithLines, i) => {
                                        const { collapsed, lines } = rmaWithLines;
                                        const allChecked = lines.filter(x => selectedLines.find(y => y.rma === rmaWithLines.rma && y.line === x.line)).length === lines.length;

                                        return (
                                            <Fragment key={'rmaPackage' + i}>
                                                <VirtualScrollChild height='56' type='table' className="packageSelect">
                                                    <td
                                                        className="showLines"
                                                        key={`showLines-${!collapsed}`}
                                                        onClick={() => handleCollapse(rmaWithLines)} >
                                                        <POWRIcon className={`${!collapsed ? "fas fa-caret-down" : "fas fa-caret-right"} showLinesIcon`} color={COLOR.CISCO_BLUE} size="16px" />
                                                    </td>
                                                    <td>
                                                        <Checkbox
                                                            id={`cbox-${rmaWithLines.rma}`}
                                                            checked={allChecked}
                                                            onClick={() => handleCheckAll(rmaWithLines.lines, allChecked)}
                                                        />
                                                    </td>
                                                    <td className="headPackage" onClick={() => handleCollapse(rmaWithLines)}>
                                                        <h4>{translate('title.rma')}: {rmaWithLines.rma}</h4>
                                                    </td>
                                                    <td onClick={() => handleCollapse(rmaWithLines)} />
                                                    <td onClick={() => handleCollapse(rmaWithLines)} />
                                                </VirtualScrollChild>
                                                {!collapsed && (
                                                    rmaWithLines.lines.map((line, i) => {
                                                        const checked = selectedLines.findIndex(x => x.rma === line.rma && x.line === line.line) >= 0;
                                                        return (
                                                            <VirtualScrollChild height='60' type='table' className="packagesRow" key={'package' + i}>
                                                                <td className="noBorder" />
                                                                <td>
                                                                    <Checkbox
                                                                        id={`cbox-${i}`}
                                                                        checked={checked}
                                                                        onClick={() => handleCheck(line, checked)} />
                                                                </td>
                                                                <td className="text-left">
                                                                    <label>{translate('gridColumns.line')} {line.line}</label>
                                                                    <p className="m-0">{translate('gridColumns.part#')} {line.part}</p>
                                                                </td>
                                                                <td className="text-right">
                                                                    <p className="mb-0">{line.available}</p>
                                                                </td>
                                                                <td><div className="x-centered">
                                                                    <Input
                                                                        className="sizeInput"
                                                                        placeholder="1"
                                                                        value={line.qtyPicked}
                                                                        onChange={e => handleQtyParts(e.target.value, line.available, line.line, line.rma)}
                                                                    />
                                                                </div></td>
                                                            </VirtualScrollChild>
                                                        )
                                                    })
                                                )}
                                            </Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="d-block d-lg-none py-16 px-16">
                            <div className="row">
                                <div
                                    className="col-auto"
                                    onClick={() => collapseAll(allCollapsed)}
                                    key={`allCollapsedSquare-${allCollapsed}`} >
                                    <POWRIcon className={`${allCollapsed ? "fas fa-minus-square" : "fas fa-plus-square"}`} color={COLOR.CISCO_BLUE} size="1rem" />
                                </div>
                                <div className="col y-centered">
                                    <Checkbox
                                        id="cboxA"
                                        onClick={() => handleCheckAll(allLines, selectedLines.length === allLines.length)}
                                        checked={selectedLines.length === allLines.length} />
                                </div>
                            </div>
                            {sortedList.map((rmaWithLines, i) => {
                                const { collapsed, lines } = rmaWithLines;
                                const allChecked = lines.filter(x => selectedLines.find(y => y.rma === rmaWithLines.rma && y.line === x.line)).length === lines.length;

                                return (
                                    <Fragment key={'rmaPackage' + i}>
                                        <VirtualScrollChild height="56" className="packageSelect pb-3 mb-3 border-bottom-gray">
                                            <div className="row py-2">
                                                <div className="col-auto">
                                                    <Checkbox
                                                        id={`cbox-${rmaWithLines.rma}`}
                                                        checked={allChecked}
                                                        onClick={() => handleCheckAll(rmaWithLines.lines, allChecked)}
                                                    />
                                                </div>
                                                <div
                                                    className="col d-flex"
                                                    key={`showLines-${!collapsed}`}
                                                    onClick={() => handleCollapse(rmaWithLines)} >
                                                    <POWRIcon className={`${!collapsed ? "fas fa-caret-down" : "fas fa-caret-right"} mr-2`} color={COLOR.CISCO_BLUE} size="16px" />
                                                    <h4 className="m-0">{translate('title.rma')}: {rmaWithLines.rma}</h4>
                                                </div>
                                            </div>
                                            {!collapsed && (
                                                rmaWithLines.lines.map((line, i) => {
                                                    const checked = selectedLines.findIndex(x => x.rma === line.rma && x.line === line.line) >= 0;
                                                    return (
                                                        <VirtualScrollChild height="60" key={'package' + i} className="pb-2 mb-2 border-bottom-gray">
                                                            <div className="row pl-16 pt-2 border-bottom-gray">
                                                                <div className="col-auto y-centered">
                                                                    <Checkbox
                                                                        id={`cbox-${i}`}
                                                                        checked={checked}
                                                                        onClick={() => handleCheck(line, checked)} />
                                                                </div>
                                                                <div className="col-auto"><label>{translate('gridColumns.line')} {line.line}</label></div>
                                                                <div className="col text-right"><p className="m-0">{translate('gridColumns.part#')} {line.part}</p></div>
                                                            </div>
                                                            <div className="row pl-16 border-bottom-gray py-1">
                                                                <div className="col">
                                                                    <label>{translate('gridColumns.rmaQty')}</label>
                                                                </div>
                                                                <div className="col-auto text-right">
                                                                    <p className="m-0">{line.available}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row pl-16 py-1">
                                                                <div className="col-auto">
                                                                    <label>{translate('gridColumns.qtyPickedUp')}</label>
                                                                </div>
                                                                <div className="col">
                                                                    <Input
                                                                        className="sizeInput"
                                                                        placeholder="1"
                                                                        value={line.qtyPicked}
                                                                        onChange={e => handleQtyParts(e.target.value, line.available, line.line, line.rma)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </VirtualScrollChild>
                                                    )
                                                })
                                            )}

                                        </VirtualScrollChild>
                                    </Fragment>
                                )
                            })
                            }
                        </div>
                    </div>
                    :
                    <div className="selectPartsContainer x-centered">
                        <Spinner size="3rem" color={'#099AD6'} className="my-5" />
                    </div>
            }
        </div>
    )
};

export default SelectParts;