import React, { useState } from 'react';
import { useTranslate } from '../../core/contexts';
import { TextInput } from '../controls';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';
import { Form, Formik } from 'formik';
import { COLOR } from '../../core/constants/Colors';

export const FeedbackModal = props => {
    const { translate } = useTranslate();
    const { onClose, show } = props;
    const stars = [1, 2, 3, 4, 5];
    const [star, setStar] = useState(0);
    const [initialValues] = useState({ name: '', email: '', comments: '' });

    return (
        <POWRModal
            show={show}
            title="Give Feedback"
            size={'small'}
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
            >
                {({ values, isValid, handleBlur }) => (
                    <Form className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-6">
                                    <TextInput
                                        type="text"
                                        name="name"
                                        label="Name"
                                        maxLength="50"
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput
                                        type="email"
                                        name="email"
                                        label="Email"
                                        maxLength="50"
                                    />
                                </div>
                                <div className="col-12">
                                    <TextInput
                                        type="text"
                                        name="comments"
                                        label="Comments"
                                        maxLength="50"
                                    />
                                </div>
                                <div className="col-12 d-flex text-center justify-content-center">
                                    {stars.map((s, i) => (
                                        <div className="star" onClick={setStar.bind(this, i + 1)} key={`star-${star}-${i + 1}`}>
                                            <POWRIcon
                                                className="fas fa-star"
                                                size="24px"
                                                color={star >= s ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="toolbar">
                            <POWRButton color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                            <POWRButton onClick={onClose}>{translate('button.ok')}</POWRButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </POWRModal>
    )
}