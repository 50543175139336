import { REGEXP } from '../constants';

const Yup = require('yup');

export const FaultDetectionAndActExchangeSchema = () =>
    Yup.object().shape({
        lines: Yup.array().of(
            Yup.object().shape({
                part_num: Yup.string().when('part_num_editable', {
                    is: true,
                    then: Yup.string(),
                    otherwise: Yup.string(),
                }),
                serial: Yup.string().when('serial_editable', {
                    is: true,
                    then: Yup.string().matches(REGEXP.CI_ALPHA_NUM, 'error.invalidChar'),
                    otherwise: Yup.string(),
                }),
                country_origin: Yup.string().when('country_origin_editable', {
                    is: true,
                    then: Yup.string().matches(REGEXP.CI_COO, 'error.invalidChar'),
                    otherwise: Yup.string(),
                }),
                quantity: Yup.string().when('quantity_editable', {
                    is: true,
                    then: Yup.string().matches(REGEXP.CI_NUMERIC, 'error.invalidChar'),
                    otherwise: Yup.string(),
                }),
                failure_desc: Yup.string().when('failure_desc_editable', {
                    is: true,
                    then: Yup.string().matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
                    otherwise: Yup.string(),
                }),
            })
        )
    });