import React from 'react';

const Icon = props => {
    const iconStyles = {
        width: props.width ? props.width : 'auto',
        height: props.height ? props.height : 'auto',
        fontSize: props.height ? props.height : 'auto',
        color: props.color ? props.color : '#FFF'
    };
    const pStyles = props.style ? props.style : {}
    const styles = Object.assign(iconStyles, pStyles);

    const type = props.type === 'solid' ? 'fas' : props.type === 'regular' ? 'far' : props.type === 'duotone' ? 'fad' : 'fal';

    return (
        <i
            style={styles}
            key={props.k}
            className={`${type} fa-${props.content} ${props.className}`}
        ></i>
    )
}

export default Icon;