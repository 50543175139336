import React, { useEffect } from 'react';
import { ContactCard } from '../index';
import { useTranslate } from '../../core/contexts'
import { InfoSection, POWRIcon } from '../styledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAddresses, selectUserContacts, selectUserEvents } from '../../core/redux/selectors';
import { loadUserAddresses, loadUserContacts, loadUserEvents } from '../../core/redux/actions';
import { GRID_COLUMNS } from '../../core/constants';
import { Table } from '../controls';
import { ADDRESS_TABLE_ITEMS, CONTACT_TABLE_ITEMS, EVENT_TABLE_ITEMS } from '../../core/constants/Common';
import { COLOR } from '../../core/constants/Colors';
import { Fragment } from 'react';

const AddressBookContent = ({ currentTab, setModalTemplate }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const contacts = useSelector(selectUserContacts);
    const addresses = useSelector(selectUserAddresses);
    const events = useSelector(selectUserEvents);

    const options = [
        {
            icon: "far fa-edit",
            text: translate('button.edit'),
            handler: (data) => setModalTemplate({ type: currentTab === 'Address' ? 'PickupLocation' : currentTab, action: 'edit', data })
        },
        {
            icon: "far fa-trash-alt",
            text: translate('button.remove'),
            handler: (data) => setModalTemplate({ type: currentTab === 'Address' ? 'PickupLocation' : currentTab, action: 'delete', data })
        }
    ];

    useEffect(() => {
        dispatch(loadUserContacts());
        dispatch(loadUserAddresses());
        dispatch(loadUserEvents());
    }, [dispatch]);

    return (
        <Fragment>
            <div className="row py-16 d-none d-xl-block">
                <div className="optionContent col-12">
                    {currentTab === 'Address' &&
                        <Table columns={GRID_COLUMNS.addresses} dataSource={addresses} itemsPerPage={ADDRESS_TABLE_ITEMS} actions={options} />
                    }
                    {currentTab === 'Contact' &&
                        <Table columns={GRID_COLUMNS.contacts} dataSource={contacts} itemsPerPage={CONTACT_TABLE_ITEMS} actions={options} />
                    }
                    {currentTab === 'Subscription' &&
                        <Table columns={GRID_COLUMNS.events} dataSource={events} itemsPerPage={EVENT_TABLE_ITEMS} actions={options} />
                    }
                </div>
            </div>
            <div className="row d-flex d-xl-none">
                <div className="col-12 p-0">
                    <InfoSection
                        title={translate('subtitle.myContacts')}
                        hideBorders
                        buttonText={translate('button.addContact')}
                        titleIcon={<POWRIcon className="far fa-users mr-2" size="1rem" />}
                        buttonSymbol={<POWRIcon className="fas fa-plus select" color={COLOR.CISCO_BLUE} />}
                        onButtonClick={() => setModalTemplate({ type: 'Contact', action: 'add' })}>
                        <div className="row">
                            {contacts && contacts.map((contact, i) => (
                                <div key={`contact-${i}`} className="col-12 mb-2" >
                                    <ContactCard
                                        hideDot
                                        content={{ ...contact, header: translate(contact.header) }}
                                        onEdit={() => setModalTemplate({ type: 'Contact', action: 'edit', data: contact })}
                                        onDelete={() => setModalTemplate({ type: 'Contact', action: 'delete', data: contact })} />
                                </div>
                            ))}
                        </div>
                    </InfoSection>
                </div>
                <div className="col-12 p-0 pt-2">
                    <InfoSection
                        title={translate('subtitle.myAddresses')}
                        hideBorders
                        buttonText={translate('button.addAddress')}
                        titleIcon={<POWRIcon className="far fa-map-marker-alt mr-2" size="1rem" />}
                        buttonSymbol={<POWRIcon className="fas fa-plus select" color={COLOR.CISCO_BLUE} />}
                        onButtonClick={() => setModalTemplate({ type: 'PickupLocation', action: 'add' })}>
                        <div className="row">
                            {addresses && addresses.map((address, j) => (
                                <div key={j} className="col-12 mb-2" >
                                    <ContactCard
                                        hideDot
                                        content={address}
                                        onEdit={() => setModalTemplate({ type: 'PickupLocation', action: 'edit', data: address })}
                                        onDelete={() => setModalTemplate({ type: 'PickupLocation', action: 'delete', data: address })} />
                                </div>
                            ))}
                        </div>
                    </InfoSection>
                </div>
                <div className="col-12 p-0 pt-2">
                    <InfoSection
                        titleIcon={<POWRIcon className="far fa-map mr-2" size="1rem" />}
                        title={translate('subtitle.event')}
                        hideBorders
                        buttonText={translate('button.addSubscription')}
                        buttonSymbol={<POWRIcon className="fas fa-plus select" color={COLOR.CISCO_BLUE} />}
                        onButtonClick={() => setModalTemplate({ type: 'Subscription', action: 'add' })}>
                        <div className="row">
                            {events && events.map((ev, j) => {
                                let newEvent = { ...ev, title: translate(ev.eventTypeDesc) };
                                newEvent.info = [
                                    `${translate('gridColumns.rmaType')}:  ${ev.rma_type_descr === 'ALL' ? translate('aux.all') : ev.rma_type_descr}`,
                                    `${translate('gridColumns.frequency')}:  ${translate(ev.eventFrequencyDesc)}`,
                                    `${translate('gridColumns.country')}:  ${ev.country_descr === 'ALL' ? translate('aux.all') : ev.country_descr}`,
                                    `${translate('gridColumns.carrier')}:  ${ev.carrier_descr === 'ALL' ? translate('aux.all') : ev.carrier_descr}`,
                                    `${translate('gridColumns.restriction')}:  ${translate(ev.restrictionTypeDesc)}`
                                ];
                                return (
                                    <div key={j} className="col-12 mb-2" >
                                        <ContactCard
                                            hideDot
                                            content={newEvent}
                                            onEdit={() => setModalTemplate({ type: 'Subscription', action: 'edit', data: ev })}
                                            onDelete={() => setModalTemplate({ type: 'Subscription', action: 'delete', data: ev })} />
                                    </div>
                                )
                            })}
                        </div>
                    </InfoSection>
                </div>
            </div>
        </Fragment>
    )
}

export default AddressBookContent;