import React, { useState } from 'react';
import { REGEXP, COLOR } from '../core/constants';
import { Input, Checkbox, Info } from './controls';
import { POWRButton, POWRIcon } from './styledComponents';

export default props => {
    const [valueCard, setValueCard] = useState("");

    const handleChange = (e) => {
        if (props.validateRMA) {
            if (REGEXP.RMAS.test(e.target.value)) {
                props.setValue ? props.setValue(e.target.value) : setValueCard(e.target.value);
            }
        } else {
            props.setValue ? props.setValue(e.target.value) : setValueCard(e.target.value);
        }
    };

    return (
        <div className="actionCard">
            <div className="header">
                <POWRIcon
                    className={props.icon}
                    size={props.largeIcon ? '40px' : '24px'}
                    color={COLOR.GREY} />
                <h3 className="m-0 pl-16 d-lg-none">{props.title}</h3>
            </div>
            <div className="actionCardBody">
                <h3 className="d-none d-lg-block">{props.title}</h3>
                <label className="mb-3">{props.children}</label>
                <Input
                    label={props.label ? props.label : "RMA"}
                    name="rma"
                    placeholder={props.placeholder ? props.placeholder : "RMA"}
                    value={props.value ? props.value : valueCard}
                    onChange={handleChange}
                    onEnter={!props.disabled ? props.onClick ? props.onClick : null : null}
                    error={props.error.length ? true : false}
                    errorMessage={props.error}
                />

                {props.comments && <div className="comments" dangerouslySetInnerHTML={{ __html: props.comments }} />}
                {props.labelOnly &&
                    <div className="card-checkbox">
                        <Checkbox id="labelOnly" text={props.labelOnly} checked={props.labelOnlyValue} smallText onClick={props.handleLabelOnlyValue} />
                        <div className="infoCircle">
                            <Info>{props.tooltip}</Info>
                        </div>
                    </div>
                }
                <div className="card-button">
                    <POWRButton
                        color={props.primaryButton ? '' : "secondary"}
                        disabled={props.disabled}
                        onClick={props.onClick ? props.onClick : null} >
                        {props.button}
                    </POWRButton>
                </div>
                {props.infoLabel &&
                    <div className="warning">
                        {props.infoIcon && <POWRIcon
                            className={props.infoIcon}
                            size="16px"
                            color={COLOR.CISCO_BLUE} />}
                        {props.infoLabel}
                    </div>
                }
            </div>
        </div>
    );
}