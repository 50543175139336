import Request from "../core/api/request";
import { URL_COUNTRY, URL_GETPICKUPLOCATIONTIMEZONE, URL_DHL_ELP_COUNTRIES } from "../core/constants/URLS";

export const getCountry = (enabled = false) => {
    return Request.getURIEncoded({ url: `get/${URL_COUNTRY}?enabled=${enabled}&_dc=${Date.now()}` });
};

/*
Example : /get/country?_dc=16&code=US
Example all countries: /get/country?_dc=16&code=
_dc is any number at this moment

{"success":true, "dtos":[{"idd":221,"status":1,"type":"Country","code":"US","name":"US",
"descr":"UNITED STATES","iso_code":"USA","fact_location":true,
"descr_code":"UNITED STATES (US","post_code_required":true,
"post_code_fmt":"NNNNN (optionally NNNNN-NNNN)","post_code_fmt_required":false,
"post_code_fmt_regexp":"^\b\\d{5}\b(?:[- ]{1}\\d{4})?$","tnt_validation_required":false}]}
*/


export const getZones = (data) => {
    return Request.postEncode({ url: URL_GETPICKUPLOCATIONTIMEZONE, data });
};

export const getDHLELPCountries = (data) => {
    return Request.postEncode({ url: URL_DHL_ELP_COUNTRIES, data });
};