import { createSelector } from 'reselect';

const selectTodos = state => state.todo;

export const selectAllTodos = () => {
    return createSelector(
        selectTodos,
        (todos) => todos
    )
};
