import React, { Fragment, memo, useMemo, useEffect, useState } from 'react';
import { DropDown } from './controls';
import { useTranslate } from '../core/contexts';
import { POWRIcon, POWRLink } from './styledComponents';
import { COLOR } from '../core/constants/Colors';
import { REGEXP } from '../core/constants';

const ContactCard = memo(({ icon, content, hideButtons, onCopyRequest, onEdit, onDelete, onSave, enableCopy, hideDot, fullHeight, isCopyPickup,
    editAdditional, setAdditional, additionalLength, className, saveBillingAddressInfo, allowEditBillingAddress, isPickupCard }) => {
    const { translate } = useTranslate();

    const [additionalInfo, setAdditionalInfo] = useState('');

    const options = useMemo(() => [
        {
            icon: "fal fa-edit",
            text: isPickupCard ? translate('button.addNewPickupLocation') :  translate('button.edit'),
            handler: onEdit
        },
        {
            icon: "fal fa-trash-alt",
            text: translate('button.remove'),
            handler: onDelete
        },
        {
            icon: "fal fa-save",
            text: isPickupCard ? translate('button.changePickupLocationaddress') : translate('button.changeaddress'),
            handler: onSave
        }
    ], [onEdit, onDelete, onSave]);

    const handleAdditionalInfo = (v) => {
        if (REGEXP.ADDITIONAL_INFO.test(v)) {
            setAdditionalInfo(v);
            setAdditional(v);
        }
    }

    useEffect(() => {
        if (!onSave) options.pop();
    }, [options]);

    useEffect(() => {
        if (content) setAdditionalInfo(content.additional || '')
    }, [content]);

    return (
        <div className={`contactCardBody ${fullHeight ? 'full-height' : ''} ${className || ''}`}>
            {content ?
                <div className="contactCardBox container-fluid py-2 py-lg-3" >
                    <div className="row">
                        {content.header &&
                            < div className="col-12">
                                <label>{content.header}</label>
                            </div>
                        }
                        {(!hideDot && icon) ?
                            <Fragment>
                                {
                                    (content.errors && content.errors.length > 0) &&
                                    <div className="col-auto m-0 mt-2 mr-2">
                                        <span>
                                            <POWRIcon key={`warn${content.header}`} className={`fas fa-${icon}`} color={COLOR.RED} size="1.75rem" />
                                        </span>
                                    </div>
                                }
                                {
                                    (!content.errors || !content.errors.length) &&
                                    <div className="col-auto m-0 mt-2 mr-2">
                                        <span>
                                            <POWRIcon key={`warn${content.header}`} className={`fas fa-${icon}`} color={COLOR.CISCO_BLUE} size="1.75rem" />
                                        </span>
                                    </div>
                                }
                            </Fragment>
                            : null
                        }
                        <div className="col m-0">
                            <div className={`${hideButtons ? '' : 'infoHead'}`}>
                                <h4 className="truncateText">{content.title}</h4>
                                {!hideButtons ? <DropDown options={options} /> : null}
                            </div>
                            {content.baErrors && content.baErrors.length ?
                                <Fragment>
                                    <div className="row m-0">
                                        <div className="col-auto p-0">
                                            <POWRIcon
                                                className="fas fa-exclamation-triangle"
                                                color={COLOR.RED}
                                                size="1rem" />
                                        </div>
                                        <div className="col p-0">
                                            {content.baErrors.map((error, i) => <label key={`${content.header}-error-${i}`} className="errorMessage d-block pl-2 mb-2">{error}</label>)}
                                        </div>
                                    </div>
                                    {/* <hr /> */}
                                </Fragment>
                                : null
                            }
                            {
                                content.info &&
                                content.info.map((item, i) => (
                                    <label key={`info-row${i}`} className="d-block m-0 truncateText">{item}</label>
                                ))
                            }
                            {
                                (content.errors && content.errors.length) ?
                                    <Fragment>
                                        <hr />
                                        <div className="row">
                                            <div className="col-auto p-0">
                                                <POWRIcon
                                                    className="fas fa-exclamation-triangle"
                                                    color={COLOR.RED}
                                                    size="1rem" />
                                            </div>
                                            <div className="col p-0">
                                                {content.errors.map((error, i) => <label key={`${content.header}-error-${i}`} className="errorMessage d-block">{error}</label>)}
                                            </div>
                                        </div>
                                    </Fragment> : null
                            }
                            {(content.additional || editAdditional) &&
                                <Fragment>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12">
                                            {translate("title.specialSiteInstr")}
                                        </div>
                                        <div className="col-12">
                                            {editAdditional ? 
                                                <textarea
                                                    value={additionalInfo}
                                                    maxLength={`${additionalLength}`}
                                                    onChange={(e) => handleAdditionalInfo(e.target.value)}
                                                    placeholder={translate("title.specialSiteInstr")}
                                                />
                                            :
                                                <p className="as-label">{content.additional}</p>
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {(saveBillingAddressInfo && allowEditBillingAddress) && <div className='mt-4 text-right'><POWRLink onClick={saveBillingAddressInfo}>{translate('button.editInfo')}</POWRLink></div>}
                        </div>
                        
                    </div>
                </div>
                :
                (
                    enableCopy &&
                    <div className="contactCardBox">
                        <div className="NoInfo" onClick={onCopyRequest}>
                            <label className="selectable">{translate(`formLabel.${isCopyPickup ? 'copyPickupContact' : 'copyRequestor'}`)}</label>
                        </div>
                    </div>
                )
            }
        </div>
    )
}, (prev, next) => prev.content === next.content
    && prev.hideButtons === next.hideButtons
    && prev.enableCopy === next.enableCopy
    && prev.onCopyRequest === next.onCopyRequest
);

export default ContactCard;