import React from 'react';
import { Formik } from 'formik';
import { Checkbox, Input } from '../controls';
import { POWRButton } from '../styledComponents';
import { PackageWeightDimensionsSchema } from '../../core/schemas';
import { useTranslate } from '../../core/contexts';
import { isValidPackageProp } from '../../core/helpers/formatUnits';

export const PackageWeightDimensionsForm = ({ onSave, onCancel, weightUnit, dimUnit }) => {
    const { translate } = useTranslate();
    const initialValues = {
        weight: "0.00",
        depth: "0.00",
        width: "0.00",
        height: "0.00",
        shouldOverride: false
    };

    const handlePropChange = (setter, propName, value, isBlur) => {
        let v = String((value === '' || isNaN(value) ? '' : value)).replace(/[^0-9\.]/g, "");
        v = !isBlur ? v : v === "" ? "" : parseFloat(v).toFixed(2);

        if (!isValidPackageProp(v, propName)) return;

        setter(propName, v);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={PackageWeightDimensionsSchema}
            onSubmit={(values) => onSave(values)}>
            {
                ({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => (
                    <form onSubmit={handleSubmit} data-testid="pkwdForm" className="content p-0 overflow-hidden package-form">
                        <div className="content">
                            <div className="row">
                                <div className="col-12">
                                    <p>
                                        {translate('aux.infoFillPkform')}{translate('aux.overridePackages')}
                                    </p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="row">
                                        <div className="d-none d-md-flex col-12">
                                            <h4>{translate('aux.weight')}</h4>
                                        </div>
                                        <div className="col-12 d-flex form-group justify-content-between">
                                            <div className="d-flex d-md-none">
                                                <label>{translate('aux.weight')}</label>
                                            </div>
                                            <Input
                                                bStyle={{ padding: 0 }}
                                                style={{ height: 'auto' }}
                                                type="text"
                                                onChange={(e) => handlePropChange(setFieldValue, 'weight', e.target.value)}
                                                onBlur={(e) => handlePropChange(setFieldValue, 'weight', e.target.value, true)}
                                                value={values.weight}
                                                error={errors.weight} errorMessage={errors.weight}
                                                className="mr-2"
                                                maxLength="8" />
                                            <p>{weightUnit}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-0 col-md-1"></div>
                                <div className="col-12 col-md-8">
                                    <div className="row">
                                        <div className="d-none d-md-flex col-12">
                                            <h4>{translate('gridColumns.dimensions')}</h4>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-md d-flex form-group justify-content-between">
                                                    <div className="d-flex d-md-none">
                                                        <label>{translate('aux.length')}</label>
                                                    </div>
                                                    <Input
                                                        bStyle={{ padding: 0 }}
                                                        style={{ height: 'auto' }}
                                                        type="text"
                                                        placeholder="L"
                                                        onChange={(e) => handlePropChange(setFieldValue, 'depth', e.target.value)}
                                                        onBlur={(e) => handlePropChange(setFieldValue, 'depth', e.target.value, true)}
                                                        value={values.depth}
                                                        error={errors.length} errorMessage={errors.length}
                                                        className="mr-2"
                                                        maxLength="6" />
                                                    <label className="d-none d-md-flex">X</label>
                                                    <p className="d-flex d-md-none">{dimUnit}</p>
                                                </div>
                                                <div className="col-12 col-md pl-md-0 d-flex form-group justify-content-between">
                                                    <div className="d-flex d-md-none">
                                                        <label>{translate('aux.width')}</label>
                                                    </div>
                                                    <Input
                                                        bStyle={{ padding: 0 }}
                                                        style={{ height: 'auto' }}
                                                        type="text"
                                                        placeholder="W"
                                                        onChange={(e) => handlePropChange(setFieldValue, 'width', e.target.value)}
                                                        onBlur={(e) => handlePropChange(setFieldValue, 'width', e.target.value, true)}
                                                        value={values.width}
                                                        error={errors.width} errorMessage={errors.width}
                                                        className="mr-2"
                                                        maxLength="6" />
                                                    <label className="d-none d-md-flex">X</label>
                                                    <p className="d-flex d-md-none">{dimUnit}</p>
                                                </div>
                                                <div className="col-12 col-md pl-md-0 d-flex form-group justify-content-between">
                                                    <div className="d-flex d-md-none">
                                                        <label>{translate('aux.height')}</label>
                                                    </div>
                                                    <Input
                                                        bStyle={{ padding: 0 }}
                                                        style={{ height: 'auto' }}
                                                        type="text"
                                                        placeholder="H"
                                                        onChange={(e) => handlePropChange(setFieldValue, 'height', e.target.value)}
                                                        onBlur={(e) => handlePropChange(setFieldValue, 'height', e.target.value, true)}
                                                        value={values.height}
                                                        error={errors.height} errorMessage={errors.height}
                                                        className="mr-2"
                                                        maxLength="6" />
                                                    <p>{dimUnit}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 row">
                                    <div className="col-12">
                                        <Checkbox
                                            onClick={() => setFieldValue('shouldOverride', !values.shouldOverride)}
                                            checked={values.shouldOverride}
                                            id={'override-cbox'}
                                            text={translate('formLabel.overridePackages')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="toolbar">
                            <POWRButton type="button" color={'secondary'} onClick={onCancel}>{translate('button.cancel')}</POWRButton>
                            <POWRButton type="submit" disabled={isSubmitting}>{translate('button.ok')}</POWRButton>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
};
