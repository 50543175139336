import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWaybillSearching, setWaybillSearchResults } from '../core/redux/actions';
import { selectWaybillSearchParameters, selectWaybillSearching, selectWaybillSearchResults } from '../core/redux/selectors';
import { WayBillList, WayBillSearchBar } from '../components';
import { getWaybillShipments } from '../services';
import { useTranslate } from '../core/contexts';
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { useGlobalMessage } from "../core/hooks";
import { SEARCH_PREPRINTED_WAYBILL} from "../core/constants/ROUTES";
import { useAlert } from "../core/hooks/useAlert";


export default props => {
    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const { translate } = useTranslate();
    const parameters = useSelector(selectWaybillSearchParameters);
    const searching = useSelector(selectWaybillSearching);
    const data = useSelector(selectWaybillSearchResults);
    const { showPageMessage } = useGlobalMessage(); // Global Messages

    const setSearching = (v) => {
        dispatch(setWaybillSearching(v))
    }

    useEffect(() => {
        setSearching(true);
    }, []);

    const searchShipments = () => {
        if (parameters && searching) {
            getWaybillShipments(parameters).then(response => {
                let resStr = response.res ? typeof (response.res) === 'string' ?
                    response.res.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                    : response.res : { dtos: [] };
                let dtos = typeof (resStr) === 'string' ? JSON.parse(resStr).dtos : resStr.dtos;
                setSearching(false);
                if (dtos.length > 500) dtos = dtos.slice(0, 500);
                if (dtos.length > 0) handleAlert(dtos.length);
                dispatch(setWaybillSearchResults(dtos));
            }).catch(err => {
                console.log(err);
                setSearching(false);
            });
        }
    }

    const handleAlert = (length) => {
        showAlert({
            id: 'returnedMore500',
            message: length >= 500 ? translate('message.returnedMore500') : 
            `${translate('message.searchReturned')} ${length} ${translate('message.shipments')}`,
            page: SEARCH_PREPRINTED_WAYBILL
        });
    }

    useEffect(() => {
        searchShipments();
    }, [parameters, searching]);

    useEffect(() => {
        let params = {
            module:'GCT_Pre_Printed_Waybill',
            country:'',
            rmaCategory:''
        }
        showPageMessage(params, '/preprinted-waybill');
    }, []);

    return (
        <FullPage>
            <div className="d-flex d-xl-none">
                <div className="col-12 pb-16">
                    <h1 className="m-0">{translate('title.searchPreprintedWaybill')}</h1>
                </div>
            </div>
            <div className="col-12 col-xl-3 p-xl-0">
                <WayBillSearchBar location={props.location} />
            </div>
            <div className="col-12 col-xl-9 p-xl-0">
                <div className="main-rmas">
                    <div className="d-none d-xl-flex row-between preprinted-header">
                        <h2>{translate('title.searchPreprintedWaybill')} <small>{translate('subtitle.usCanadaOnly')}</small></h2>
                    </div>
                    <div className="pt-16 pt-xl-0">
                        {searching ?
                            <p>{translate('aux.loading')}</p>
                            :
                            <WayBillList items={data} />
                        }
                    </div>
                </div>
            </div>
        </FullPage>
    );
}
