import React, { useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { fadeAnimation } from "../../animations/fadeAnimation";
import { AppWrapperContext } from "../AppShell.react";

const SideWrapper = styled.aside`
  grid-area: SideBar;

  position: relative;
  /* Layout Index level */

  box-shadow: 0 0 0 0 #000000;

  background-color: ${({ theme: { colors } }) => colors.ciscoBlue};
  transition: left ${({ theme: { time } }) => time[0]},
    box-shadow ${({ theme: { time } }) => time[0]};

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    position: fixed;
    left: ${({ isOpen, theme: { side } }) =>
      isOpen ? "0" : "-" + side.widthOpen.rem};

    height: 100%;
    width: ${({ theme: { side } }) => side.widthOpen.rem};

    box-shadow: 0 0 ${(props) => (props.isOpen ? "48px" : "0px")} #00000080;
    z-index: 1;
  }
`;

const SideOverlay = styled.button`
  border: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000030;

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    animation: ${fadeAnimation} 0.3s ease forwards;
  }
`;

const LockBody = createGlobalStyle`
  body, html {
    @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
      /* This solution works perfect on all devices except iOS */
      overflow: hidden;
    }
  }
`;

export const SidePanelWrapper = ({ children }) => {
  const { isSidePanelOpen, toggleSidePanel } = useContext(AppWrapperContext);

  return (
    <SideWrapper isOpen={isSidePanelOpen}>
      {/* Prevent Scroll on Mobile device when side panel is open  */}
      {isSidePanelOpen && <LockBody />}

      {children}

      {/* Mobile overlay when user open menu in Mobile screen */}
      <SideOverlay
        onClick={() => toggleSidePanel(!isSidePanelOpen)}
        isOpen={isSidePanelOpen}
      />
    </SideWrapper>
  );
};
