import React, { Fragment } from 'react';
import { useTranslate } from '../core/contexts';
import { useSelector } from 'react-redux';
import { selectShipments } from '../core/redux/selectors';
import { VirtualScrollChild } from './shared';
import DownloadLabels from './downloadLabels';
import { POWRIcon } from './styledComponents';
import { COLOR } from '../core/constants/Colors';
import { Link } from '../components/controls';
import { useConfigParams } from '../core/hooks';

const DownloadList = () => {
    const { translate } = useTranslate();
    const { getFaqLink, getContactUsLink } = useConfigParams();
    const shipments = useSelector(selectShipments);
    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();

    return shipments.length > 0 ?
        (
            <Fragment>
                {shipments.map((shipment, i) => {
                    return (
                        <VirtualScrollChild height={'140'} key={i}>
                            <DownloadLabels shipment={shipment} index={i} />
                        </VirtualScrollChild>
                    );
                })
                }
            </Fragment>
        ) : (
            <div className="shipmentsNoData shipmentsNoDataSmall">
                <div className="iconContainer">
                    <POWRIcon
                        className="fal fa-search"
                        color={COLOR.GREY}
                        size="56px" />
                </div>
                <div className="textNoData">
                    <h2>{translate('aux.noResults')}</h2>
                    <p>{translate('aux.refine')} <Link target="_blank"  href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('aux.refine2')} <Link target="_blank"  href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link> {translate('message.countryRMAError2')}</p>
                </div>
            </div>
        );
};

export default DownloadList;
