import React from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRButton, POWRModal } from "../styledComponents";

export const IndiaRMAModal = props => {
    const { translate } = useTranslate();
    const { createDocument, proceedWODocument, show, close } = props;

    return (
        <POWRModal
            show={show}
            title={translate('title.reminder')}
            size="small"
            onClose={close}
            largeActions={true}
        >
            <div className="content">
                <div className="text-center pb-32 px-16">
                    <p>{translate(props.change ? 'message.changeAddrsClassify' : 'message.indiaReminder')}</p>
                </div>
            </div>
            <div className="toolbar">
                <POWRButton color={'secondary'} onClick={proceedWODocument.bind(this, false)}>{translate('button.proceedWithoutDocument')}</POWRButton>
                <POWRButton onClick={createDocument.bind(this, false)}>{translate('button.goToCreateDocument')}</POWRButton>
            </div>
        </POWRModal>
    )
}

