import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReturnFlowProp } from '../core/redux/actions';
import { selectReturnFlowCurrentStep, selectReturnFlowCurrentSteps } from '../core/redux/selectors';
import {
    RMAInfoSideBar, SelectPartsSideBar, CreatePackagesSideBar, SchedulePickupSideBar,
    SummarySideBar, SuccessSideBar
} from './index';

const ReturnTaskBar = memo(() => {
    const dispatch = useDispatch();
    const step = useSelector(selectReturnFlowCurrentStep);
    const steps = useSelector(selectReturnFlowCurrentSteps);

    const next = () => {
        const targetStep = steps.findIndex((x, i) => i > step && !x.disabled);
        if (targetStep && targetStep !== step) dispatch(setReturnFlowProp('currentStep', targetStep));
    };

    const back = () => {
        let targetStep = -1;
        steps.forEach((x, i) => (step > i && !x.disabled) ? targetStep = i : null);
        // console.log(steps.reverse(), targetStep);
        if (targetStep >= 0 && targetStep !== step) dispatch(setReturnFlowProp('currentStep', targetStep));
    };

    const home = () => dispatch(setReturnFlowProp('currentStep', 0));

    return (
        <div className="returnTaskBarContainer">
            {
                (step === 0 &&
                    <RMAInfoSideBar step={step} next={next} back={back} />)
                || (step === 1 &&
                    <SelectPartsSideBar next={next} back={back} />)
                || (step === 2 &&
                    <CreatePackagesSideBar next={next} back={back} />)
                || (step === 3 &&
                    <SchedulePickupSideBar next={next} back={back} step={step} />)
                || (step === 4 &&
                    <SummarySideBar next={next} back={back} step={step} />)
                || <SuccessSideBar home={home} />
            }
        </div>
    );
}, (prev, next) => prev.step === next.step && prev.steps === next.steps
);

export default ReturnTaskBar;
