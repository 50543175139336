import React, { useEffect, useState, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { useTranslate } from "../../core/contexts";
import { useLostFocus } from '../../core/hooks';
import { POWRIcon } from '../styledComponents';

export default props => {
    const { langCode } = useTranslate();
    const dateFormat = sessionStorage.getItem('dateFormat');
    const dateMobileFormat = "YYYY-MM-DD";
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState(null);
    const [value, setValue] = useState("");
    const [calendarP, setCalendarP] = useState(false);
    const [disabledDays, setDisabledDays] = useState(false);
    const [disabledDates, setDisabledDates] = useState([]);
    const ref = useRef();
    useLostFocus([ref], () => setShowCalendar(false));

    if (date === null) {
        if (props.value) {
            let propsDate = moment(props.value, 'DD/MM/YY').toDate();
            setDate(propsDate);
        } else {
            setDate(new Date());
        }
    }

    useEffect(() => {
        let propsDate = moment(props.value, 'DD/MM/YY').toDate();
        if (date !== propsDate && propsDate != "Invalid Date") setDate(propsDate);
    }, [props.value]);

    useEffect(() => {
        setDisabledDays(props.disabledDays);
        if (!disabledDates.length && props.disabledDates) setDisabledDates(props.disabledDates);
    }, [props.disabledDates, props.disabledDays]);

    useEffect(() => {
        if (ref.current && !props.noPostion) {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let refHeight = ref.current.children[0] ? ref.current.children[0].offsetHeight : 0;
            props.fromTable ? setCalendarP(scrollTop > refHeight) : setCalendarP(scrollTop < refHeight); // added fromTable prop to handle the visual behaivor for calendar in each edit row from a table.
        }
        
    }, [showCalendar]);

    const checkDisabled = (d, mobile) => {
        if (props.checkMinMax === false) return d;
        if (!disabledDates.length) return d;
        let format = mobile ? dateMobileFormat : dateFormat;
        let cDate = moment(d, format).toDate();
        let dateT = disabledDates.length && disabledDates.some(disabledDate => {
            if (disabledDays) return cDate.getDay() === disabledDate
            else return (
                cDate.getFullYear() === disabledDate.getFullYear() &&
                cDate.getMonth() === disabledDate.getMonth() &&
                cDate.getDate() === disabledDate.getDate()
            )
        });
        if (dateT) return mobile ? props.value : "";
        else return d;
    }

    const checkDate = (newDate) => {
        // Validates the format of the date
        if (newDate !== "" && isNaN(newDate.getTime())) {
            if (props.value) {
                let propsDate = moment(props.value, 'DD/MM/YY').toDate();
                setDate(propsDate);
            } else if (props.text) {
                let propsDate = moment(props.text, dateFormat).toDate();
                setDate(propsDate);
            } else {
                setDate(new Date());
            }
        } else {
            setDate(newDate);
        }
    }

    const checkMinMax = (d, mobile = false) => {
        let cDate = moment(d, mobile ? dateMobileFormat : dateFormat).toDate();
        let maxCheck = props.maxDate ? cDate <= props.maxDate : true;
        let minCheck = props.minDate ? cDate >= props.minDate : true;
        return props.checkMinMax === false || (maxCheck && minCheck);
    }

    const handleChange = (e, mobile = false) => {
        if (mobile) {
            if (e.target.value == "" && !props.clear) return;
            setValue(e.target.value);
            handleBlur(e, true);
        } else {
            // When change the date set the value
            if (e.target) {
                setValue(e.target.value);
            } else {
                if (e.target) {
                    setValue(e.target.value);
                } else {
                    setValue("")
                    props.onBlur && props.onBlur(e, false);
                    checkDate(e);
                    setShowCalendar(false);
                }
            }
        }
    }

    const handleEnter = (event) => {
        // When change the date text and click enter
        if (event.key === 'Enter') {
            let d = value ? value : (props.text ? props.text : props.value);
            setValue("")
            d = checkDisabled(d);
            if (checkMinMax(d)) {
                props.onBlur && props.onBlur(d);
                let newDate = new Date(d.replaceAll('—', '/'));
                checkDate(newDate);
            }
            setShowCalendar(false);
        }
    }

    const handleBlur = (v, mobile = false) => {
        let val = v.target.value;
        // Activates when left the textInput
        setValue("")
        val = checkDisabled(val, mobile);
        if (checkMinMax(val, mobile)) {
            props.onBlur && props.onBlur(val, mobile);
            let newDate = val !== "" ? new Date(val.replaceAll('—', '/')) : "";
            checkDate(newDate);
        }
        setShowCalendar(false);
    }

    const handleClear = () => {
        setValue("");
        props.onBlur && props.onBlur("");
        setShowCalendar(false);
    }

    const datesDisabled = (info) => {
        if (!disabledDates.length) return false;
        let viewT = info.view === 'month';
        let dateT = disabledDates.length && disabledDates.some(disabledDate =>
            info.date.getFullYear() === disabledDate.getFullYear() &&
            info.date.getMonth() === disabledDate.getMonth() &&
            info.date.getDate() === disabledDate.getDate()
        );

        return viewT && dateT;
    }

    const daysDisabled = (info) => {
        if (!disabledDates.length) return false;
        let viewT = info.view === 'month';
        let dateT = disabledDates.length && disabledDates.some(disabledDate =>
            info.date.getDay() === disabledDate
        );

        return viewT && dateT;
    }

    const handleFormatWeekday = (locale, dateW) => {
        moment.locale(locale)
        let weekDay = moment(dateW).format('ddd').charAt(0);
        return weekDay;
    }

    return (
        <div className="inputCalendarWrapper">
            <div
                style={props.style}
                className={`inputCalendar ${props.disabled ? ' readonly' : ''} ${showCalendar ? ' inputCalendarWriting' : ''} 
                    ${props.className || ''} ${props.error ? ' calendarControlError' : ''}`
                }
            >
                <div className="d-flex justify-content-between">
                    {props.label && <label className={`label ${props.lblClass}`}>{props.label} {props.mandatory && <label className="asterisk">*</label>}</label>}
                    {props.clear && (props.disabled ? '' : value ? value : (props.text ? props.text : props.value)) && <>
                        <label> </label>
                        <label className="clear-btn" onClick={() => handleClear()} >
                            <POWRIcon
                                className="fal fa-times"
                                color="#099AD6"
                                size="14px"
                            />
                        </label>
                    </>}
                </div>
                <div className={`d-md-none inputCalendarContainer ${props.paddingInput ? props.paddingInput : ''}`}>
                    <input
                        className="text"
                        type="date"
                        min={props.minDate ? moment(props.minDate).format(dateMobileFormat) : ''}
                        max={props.maxDate ? moment(props.maxDate).format(dateMobileFormat) : ''}
                        name={props.name}
                        value={moment(props.value, 'DD/MM/AA').format(dateMobileFormat)}
                        onChange={e => handleChange(e, true)}
                        locale={langCode}
                        disabled={props.showValueOnDisabled}
                    />
                </div>
                <div className={`d-none d-md-flex inputCalendarContainer ${props.paddingInput ? props.paddingInput : ''}`}>      
                    <input
                        className="text"
                        type="text"
                        autoComplete="off"
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.disabled && !props.showValueOnDisabled ? '' : value ? value : (props.text ? props.text : props.value)}
                        onKeyPress={handleEnter}
                        onChange={e => handleChange(e, false)}
                        onBlur={handleBlur}
                        disabled={props.showValueOnDisabled}
                    />
                    <div
                        key={`iconCalendar-${showCalendar}`}
                        onClick={() => props.disabled ? null : setShowCalendar(!showCalendar)}
                    >
                        <POWRIcon
                            className="far fa-calendar iconCalendar"
                            color={showCalendar ? '#099AD6' : '#58585b'}
                            size="16px"
                        />
                    </div>
                </div>
                {showCalendar &&
                    <div
                        ref={ref}
                        className="reactCalendarContainer"
                        style={calendarP ? { bottom: 0 } : { top: 56 }}
                    >
                        <Calendar
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            onChange={e => handleChange(e, false)}
                            tileDisabled={disabledDays ? daysDisabled : datesDisabled}
                            value={date}
                            formatShortWeekday={handleFormatWeekday}
                            locale={langCode}
                            calendarType={langCode === "en" ? "US" : "ISO 8601"}
                        />
                    </div>
                }
            </div>
            {
                props.error && !props.formInput &&
                <label className="errorMessage">{props.error}</label>
            }
        </div>
    )
}