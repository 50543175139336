import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Link, Status } from '../controls';
import { setShipmentSeleted, setShipmentsReload, setShipmentsSearching } from '../../core/redux/actions';
import { getShipmentStatus } from '../../core/constants/ShipmentsStatus';
import { selectShipmentSeleted, selectIsShipmentEdit } from '../../core/redux/selectors';
import { useTranslate } from '../../core/contexts';
import { POWRIcon, POWRModal, POWRButton } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import { SHIPMENT_DETAIL } from '../../core/constants/ROUTES';
import { useCommercialInvoice } from '../../core/hooks';

export default ({ mobile }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const history = useHistory();
    const shipment = useSelector(selectShipmentSeleted);
    const isShipmentEdit = useSelector(selectIsShipmentEdit);
    const { idd, ship_code, rmas, tns, date_created, principal,carrier_description, carrier_msg, pickup_schedule_time,date_pickup, ship_event_time} = shipment;
    const dateFormat = sessionStorage.getItem('dateFormat');
    const timeFormat = sessionStorage.getItem('timeFormat');
    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const createdDate = (date) => {
        return date && moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) : '—';
    }
    const status = getShipmentStatus(shipment.status, shipment.ship_event);
     const {
        print
    } = useCommercialInvoice({ shipment });

    const confirmChangePage = () => {
        if (!isShipmentEdit)
            history.push({ pathname: SHIPMENT_DETAIL, search: `?id=${ship_code}`, state: { id: ship_code, data: shipment } })
        else
            setUnsavedChangesModal(true);

    }

    return (
        <div className="shipment-info-bar">
            {!mobile && <div className="d-flex justify-content-end mb-2" >
                <div onClick={() => dispatch(setShipmentSeleted({}))}>
                    <POWRIcon className="fas fa-times" color={COLOR.DARK_GREY_1} size="16px" selectable />
                </div>
            </div>}
            {!mobile ? 
                <div className="main-info">
                      <div className="row-info">
                            <label>{translate('gridColumns.shipmentID')}:</label>
                             <h4 className="mb-0">{ship_code || idd}</h4>
                        </div>
                       
                    <div className='row'>
                        <div className='col-6'>
                            <div className="row-info">
                                <label>{translate('gridColumns.created')}:</label>
                                <h4>{date_created ?
                                    `${moment(`${date_created}`, 'YYYYMMDDHHmmss').format(`${dateFormat} ${timeFormat}`)}` : '—'}</h4>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="row-info">
                            <label>{translate('gridColumns.createdBy')}:</label>
                            <h4>{principal}</h4>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-info">
                        <label>{translate('gridColumns.carrier')}:</label>
                        <h4>{carrier_description}</h4>
                    </div>
                   
                    <div className='row'>
                        <div className='col-6'>
                            <div className="row-info">
                                <label>{translate('gridColumns.rmas')}:</label>
                                <h4>{rmas ? rmas.length > 1 ? rmas.join(', ') : rmas[0] : '—'}</h4>
                            </div>
                        </div>
                        <div className='col-6'>
                            <label>{translate('gridColumns.pickupRequested')}:</label>
                                <h4>{`${createdDate(date_pickup)} ${pickup_schedule_time ? pickup_schedule_time : ''}`}</h4>
                        </div>
                    </div>

                    
                    <div className="row-info">
                        <label>{translate('gridColumns.agreedPickupDate')}:</label>
                        <h4>{shipment.agreed_pickup_dt ? 
                            `${shipment.agreed_pickup_dt ? moment(`${shipment.agreed_pickup_dt}`, 'MM/DD/YYYY').format(`${dateFormat}`) : '—'} ${shipment.pickup_schedule_time || ''}` : '—'
                        }</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.tracking')}:</label>
                        <h4>{tns ? tns.length > 1 ? tns.join(', ') : tns[0] : '—'}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.shipStatus')}:</label>
                        <Status status={status} isTitle={true}/>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.date')}</label>
                        <h4>{shipment.ship_event_time ?
                            `${moment(`${ship_event_time}`, 'YYYYMMDDHHmmss').format(`${dateFormat} ${timeFormat}`)}` : '—'}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.carrier')}:</label>
                        <h4>{carrier_msg ? carrier_msg.split(', desc=')[1] ? carrier_msg.split('desc=')[1] : carrier_msg : '—'}</h4>
                    </div>
                </div>
            :
                <div className="main-info">
                    <div className="row-info">
                        <label>{translate('gridColumns.shipmentID')}:</label>
                        <h4 className="mb-0">{ship_code || idd}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.created')}:</label>
                        <p>{createdDate(shipment.date_created)}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.createdBy')}:</label>
                        <p>{shipment.principal}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.carrier')}:</label>
                        <p>{shipment.carrier_msg ? shipment.carrier_msg.split(', desc=')[1] ? shipment.carrier_msg.split('desc=')[1] : shipment.carrier_msg : '—'}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.carrier')}:</label>
                        <p>{carrier_msg.trim() !== "" && <label className="carrier-msg">{carrier_msg}</label>}  </p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.rmas')}:</label>
                        <h4>{rmas ? rmas.length > 1 ? rmas.join(', ') : rmas[0] : '—'}</h4>
                    </div>
                    <div className="row-info">
                        <label>{translate('formLabel.tracking')}:</label>
                        <p>{tns ? tns.length > 1 ? tns.join(', ') : tns[0] : '—'}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.agreedPickupDate')}:</label>
                        <p>{shipment.agreed_pickup_dt ? 
                            `${shipment.agreed_pickup_dt ? moment(`${shipment.agreed_pickup_dt}`, 'MM/DD/YYYY').format(`${dateFormat}`) : '—'} ${shipment.pickup_schedule_time || ''}` : '—'
                        }</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('message.courierDispatch')}:</label>
                        <p>{shipment.confirmation_number || '—'}</p>
                    </div>
                    <div className="row-info">
                        <label>{translate('gridColumns.status')}:</label>
                        <Status className='justify-content-start' status={status} />
                    </div>
                </div>
        
            }
            <div className="download-center">
                <div className="download-center-links">
                    <Link to={!isShipmentEdit ? { pathname: SHIPMENT_DETAIL, search: `?id=${ship_code}`, state: { id: ship_code, data: shipment } } : undefined}
                        onClick={isShipmentEdit ? setUnsavedChangesModal.bind(this, true) : undefined} className="download-link">
                        {translate('aux.shipmentDetail')}
                    </Link>
                </div>
                  <div
                        className={"download-link"}
                        onClick={() => print('label', idd)}
                    >
                        <Link>{translate('aux.printLabels')}</Link>
                    </div>
            </div>
                   <POWRModal
                    size="small"
                    show={unsavedChangesModal}
                    title={translate('title.unsavedChanges')}
                    onClose={setUnsavedChangesModal.bind(this, false)} >
             
                <div className="content">
                   <p className="pt-3">{translate('message.leaveRLSPProcess')}</p>
                </div>
                <div className="toolbar">
                    <POWRButton type="button" color='secondary' onClick={setUnsavedChangesModal.bind(this, false)}>{translate('button.cancel')}</POWRButton>
                    <POWRButton type="button" onClick={() => { history.push({ pathname: SHIPMENT_DETAIL, search: `?id=${ship_code}`, state: { id: ship_code, data: shipment } }) }}>{translate('button.ok')}</POWRButton>
                </div>
            </POWRModal>
        </div>
    );
}
