import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLostFocus } from '../../core/hooks';
import { POWRIcon } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import { useTranslate } from '../../core/contexts'
import { gtagService } from '../../services';

export default props => {
    const { translate } = useTranslate();
    const history = useHistory();
    const location = useLocation();

    const [position, setPosition] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const ref = useRef();
    useLostFocus([ref], () => setShowOptions(false));
    

    const handleGo = (path, id, item) => {
        if(location.pathname === '/home' || location.pathname === '/')
            gtagService.event('DashboardWidgets', 'RecentShipmentsList');
        history.push({
            pathname: path,
            search: `?id=${id}`,
            state: { id, data: item }
        })
    }

    useEffect(() => {
        if (!position && props.position) {
            let pos = {};
            props.position.split(' ').forEach(element => {
                let p = { [element]: 0 }
                pos = Object.assign(pos, p);
            });
            setPosition(pos);
        }
    }, [props, position])

    return (
        <div className={"dropDown" + (props.label ? ' w-auto m-0' : '')}>
            <div className="dropDownBtn" onClick={() => setShowOptions(!showOptions)}>
                {props.label || null}
                {props.icon || <POWRIcon className="far fa-ellipsis-h" size="16px" color={COLOR.GREY} />}
            </div>
            {showOptions && <div className="dropDownContent" style={position} ref={ref} >
                {props.options.map((action, i) => action.type === "popup" ?
                    <a
                        className="dropDownOption link-decoration"
                        key={`${action.text}-Action-${i + 1}`}
                        href={`${action.href}${props.labelId}`}
                        target="popup"
                        rel="noopener noreferrer"
                        onClick={() => window.open(`${action.href}${props.labelId}`, "popup", 'width=600,height=600')}
                    >
                        {action.icon && <POWRIcon className={action.icon} size="13px" color={COLOR.GREY} />}
                        <label>{translate(action.text)}</label>
                    </a>
                :
                    <div
                        className="dropDownOption" key={`${action.text}-Action-${i + 1}`}
                        onClick={() => {
                            setShowOptions(false);
                            if (action.handler) {
                                action.handler();
                            } else {
                                handleGo(action.href, props.fullId, props.item);
                            }
                        }}
                    >
                        {action.icon && <POWRIcon className={action.icon} size="13px" color={COLOR.GREY} />}
                        <label>{translate(action.text)}</label>
                    </div>
                )}
            </div>}
        </div>
    )
}