import React, { useState, useEffect} from 'react';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { useTranslate } from '../../../core/contexts';
import { DropDown, Status,Link, Select } from '../../controls';
import { SHIPMENT_EVENT_STATUS, getShipmentStatus } from '../../../core/constants/ShipmentsStatus';
import { COLOR } from '../../../core/constants/Colors.js';
import { POWRIcon, POWRModal, POWRButton } from '../../styledComponents';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShipmentSeleted, setShipmentsReload, clickOutsideReturn, setShipmentEdit } from '../../../core/redux/actions';
import { selectShipmentSeleted} from '../../../core/redux/selectors';
import { ShipmentsInfoBar } from '../../index';
import { SHIPMENT_DETAIL } from '../../../core/constants/ROUTES';
import InputCalendar from '../../controls/inputCalendar';
import { saveShipmentRLSP } from '../../../services/shipmentService';
import { useAlert } from '../../../core/hooks';
import { THEATER_TYPES } from '../../../core/constants';
import { selectClickOutsideReturn } from '../../../core/redux/selectors';
import { RLSP } from "../../../core/constants/ROUTES";




const ShipmentsTable = (props) => {
    const dispatch = useDispatch();
    const { items, allItems, setAllItems, handlePages } = props;
    const { translate } = useTranslate();
    const history = useHistory();
    const clickOutsideRLSP = useSelector(selectClickOutsideReturn);
    const [order, setOrder] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [expand, setExpand] = useState(false);
    const dateFormat = sessionStorage.getItem('dateFormat');
    const shipment = useSelector(selectShipmentSeleted);
    const { idd, ship_code, rmas, tns } = shipment;
    const[currentShip, setCurrentShip] = useState({}); 
    const [shipmentStatusSelected, setShipmentStatusSelected] = useState([]);
    const [shipmentStatusCodes, setShipmentStatusCodes] = useState([]);
    const [optionsShipmentStatus, setOptionsShipmentStatus] = useState([]);
    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const [statusTable, setStatusTable] = useState('');
    const [unsavedChanges, setunsavedChasnges] = useState({});
    const { showAlert } = useAlert();
    

    useEffect(() => {
        if (clickOutsideRLSP && clickOutsideRLSP!== RLSP) {
            if (isEditing)
                setUnsavedChangesModal(true);
         }
    }, [clickOutsideRLSP])

    useEffect(() => {
        dispatch(setShipmentEdit(isEditing));
    }, [isEditing])
    
    useEffect(() => {
        if (!optionsShipmentStatus.length) {
            let options = [];
            SHIPMENT_EVENT_STATUS.forEach(el => {
                if (el.id !== 0 && el.id !== 9 && el.id !== 12  && el.id !== 14 && el.id !== 15) {
                    options.push({
                        ...el,
                        text: translate(el.text),
                        code: translate(el.text)
                    })
                }
            })
            setOptionsShipmentStatus(options);
        }
    }, [SHIPMENT_EVENT_STATUS]);

    const handleShipmentStatus = (v) => {
        let status = SHIPMENT_EVENT_STATUS.filter(x => v.includes(translate(x.text)));
        let codes = status.map(st => (st.id));
        setShipmentStatusSelected(v);
        setShipmentStatusCodes(codes);
        setStatusTable(status && status.length > 0 && status[0].color);
        setCurrentShip({...currentShip, ship_event: status[0].id})
    }


    const handleOrder = (o, value) => {
        dispatch(setShipmentSeleted({}));
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(true);
        }
        setOrder(o);

        let r = order === o ? !reverse : true;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        let newOrder = allItems.sort(compare);
        setAllItems(newOrder);
        handlePages(newOrder);
    }
    
    const columns = [
        { id: 0, wider:false, value: 'ship_code', type: 'tex', text: translate('gridColumns.shipmentID') },
        { id: 1, wider:false, value: 'rmas', array: true, type: 'num', text: translate('gridColumns.rmas') },
        { id: 3, wider:false, value: 'ship_event_time', type: 'dat', text: translate('gridColumns.statusDate') },
        { id: 4, wider:false, value: 'agreed_pickup_dt', type: 'dat', text: translate('gridColumns.agreedPickupDate') },
        { id: 5, wider:true,  value: 'tns', array: true, text: translate('gridColumns.tracking') },
        { id: 6, wider:true,  value: 'ship_event', type: 'tex', text: translate('gridColumns.shipStatus') },
        { id: 7, wider:true,  value: 'carrier_msg',array: true, text: translate('formLabel.carrier') },
        { id: 8, noWidth:true,value: '', text:'' }
        
    ];

    const createdDate = (date, mobile) => {
        return mobile ? 
            date && moment(`${date}`, 'YYYYMMDDHHmmss').format("YYYY-MM-DD") !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDDHHmmss').format("YYYY-MM-DD") : '-'
            :
            date && moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) : '';
    }

    const agreedDate = (date, mobile) => {
       
        if (date) {
            if (!mobile) {
                if (date && moment(`${date}`, 'MM-DD-YYYY').format(dateFormat) !== 'Invalid date') 
                    return moment(`${date}`, 'MM-DD-YYYY').format(dateFormat);
               
                if (date && moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) !== 'Invalid date')
                    return moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat)
            }
            else {
                if (moment(`${date}`, 'MM-DD-YYYY').format(dateFormat) !== 'Invalid date') {
                    return moment(`${date}`, 'MM-DD-YYYY').format('YYYY-MM-DD')
                }
                if (moment(`${date}`, 'YYYYMMDDHHmmss').format("MM-DD-YYYY") !== 'Invalid date')
                    return moment(`${date}`, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')
            }
        } else return "";
     }
    
    const returnedDate = (date) => {
        return date && moment(date, dateFormat).format(dateFormat) !== 'Invalid date' ?
            moment(date,dateFormat).format(dateFormat) : '';
    }


    const handleDetails = (val, id) => {
        setShowDetails(val);
        
    }

    const hanldeSlectRow = (id, e) => {
        if (id !== idd) {
            if (isEditing && hasChanges(shipment)) {
                setunsavedChasnges({...unsavedChanges, id:id, from:'selectRow', outsidePage:true });
                setUnsavedChangesModal(true);
            }
            else {
                setIsEditing(false);
                props.onClick(id);
            }
            
        }

    }

    const confirmChangeRow = (isCancel) => {
        if (!isCancel) {
            if (clickOutsideRLSP) {
                dispatch(clickOutsideReturn(''))
                history.push(clickOutsideRLSP)
            }
            else if (unsavedChanges.from === 'selectRow') {
                setIsEditing(false);
                props.onClick(unsavedChanges.id);
            }
            else if (unsavedChanges.from === 'edit') {
                props.onClick(unsavedChanges.id, unsavedChanges.mobile)
                setIsEditing(true);
                setExpand(true);
                setShipmentStatusSelected(unsavedChanges.status ? translate(unsavedChanges.status.text) : '');
                setStatusTable(unsavedChanges.status && unsavedChanges.status.color)
            }
            else if (unsavedChanges.from === 'expand') {
                setIsEditing(unsavedChanges.isEdit);
                if(unsavedChanges.item.idd !== idd){
                    props.onClick(unsavedChanges.item.idd, true);
                    setExpand(true);
                }
                else unsavedChanges.isEdit ? setExpand(true) : setExpand(!expand);
               
            }

        }
        setUnsavedChangesModal(false)
    }
    
    const hasChanges = (item) => {
        let tnsOld = item.tns && Array.isArray(item.tns) ? item.tns.join(',') : item.tns ? item.tns : '';
        let tnsNew = currentShip.tns && Array.isArray(currentShip.tns) ? currentShip.tns.join(',') : currentShip.tns ? currentShip.tns : '';
        if (!item.carrier_msg && (currentShip.carrier_msg && currentShip.carrier_msg !== ''))
            return true
           
        return item.carrier_msg !== currentShip.carrier_msg ||
                item.ship_event !== currentShip.ship_event ||
                tnsNew !== tnsOld ||
                agreedDate(item.agreed_pickup_dt) !== agreedDate(currentShip.agreed_pickup_dt)
       
    }

    const handleSubmit = (item) => { 
        let agreedDate = moment(currentShip.agreed_pickup_dt, 'YYYYMMDDHHmmss').format("YYYYMMDDHHmmss") !== 'Invalid date' ? 
            moment(currentShip.agreed_pickup_dt, 'YYYYMMDDHHmmss').format("YYYYMMDDHHmmss") : '';
       
       
        let body = {
            json:{
                dtos:[ {
                    "idd": currentShip.idd,
                    "tns":currentShip.tns && Array.isArray(currentShip.tns) ? currentShip.tns.join(',').replace(/["'´]/g, "") : currentShip.tns ? currentShip.tns.replace(/["'´]/g, "") : '',
                    "ship_event":currentShip.ship_event,
                    "ship_event_time": hasChanges(item) ? moment(new Date(),dateFormat).format('YYYYMMDD') : currentShip?.ship_event_time ? currentShip.ship_event_time.substring(0,8) : item.ship_event_time,
                    "agreed_pickup_dt": agreedDate.substring(0,8),
                    "carrier_msg": currentShip.carrier_msg ? currentShip.carrier_msg.replace(/["'´]/g, "") : '',
                    "type": currentShip.type
                }]}     
        }
            saveShipmentRLSP(body).then(response => {
                dispatch(setShipmentsReload({type:'update',shipment}))
                
                showAlert({
                        id: 'shipSubmitted',
                        page: RLSP,
                        message: translate('message.successShipUpdate'),
                        color: 'green'
                    });
                    setIsEditing(false);
            }).catch(err => {
                showAlert({
                        id: 'shipUpdateError',
                        page: RLSP,
                        message: translate('message.savePickupError'),
                        color: 'yellow'
                    });
                console.log(err);
            });
    }
    const handleEdit = (item, status, mobile, e) => {
        if(idd === item.idd) setCurrentShip(shipment);
        
        if (!isEditing || (isEditing && !hasChanges(shipment))) {
            props.onClick(item.idd, mobile)
            setIsEditing(true);
            setExpand(true);
            setShipmentStatusSelected(status ? translate(status.text) : '');
            setStatusTable(status && status.color)
        }
        else {
            setUnsavedChangesModal(true);
            setunsavedChasnges({...unsavedChanges, id:item.idd,  mobile:mobile,status:status, from:'edit', outsidePage:true});
        }
        e.stopPropagation();
    }

    const handlerExpand = (item, isEdit) => {
        if (isEditing && hasChanges(shipment)) {
                setunsavedChasnges({...unsavedChanges, item, isEdit, from:'expand' });
                setUnsavedChangesModal(true);
        }
        else {
             setIsEditing(isEdit);
            if(item.idd !== idd){
                props.onClick(item.idd, true);
                setExpand(true);
            }
            else isEdit ? setExpand(true) : setExpand(!expand);
            }
       
    }

    const handleStatusDate = (v, mobile = false) => {
        // Check the date format and value
        let newDate = mobile ? v : moment(v, dateFormat).format('YYYY-MM-DD'); 
        if (newDate !== "Invalid date") setCurrentShip({...currentShip, ship_event_time:!mobile ? moment(v, dateFormat).format('YYYYMMDDHHmmss') : moment(v, 'YYYY-MM-DD').format('YYYYMMDDHHmmss')});
    };

    const handleAgreedDate = (v, mobile = false) => {
        // Check the date format and value
        let newDate = mobile ? v : moment(v, dateFormat).format('YYYY-MM-DD');
        if (newDate !== "Invalid date") setCurrentShip({...currentShip, agreed_pickup_dt:!mobile ? moment(v, dateFormat).format('YYYYMMDDHHmmss') : moment(v, 'YYYY-MM-DD').format('YYYYMMDDHHmmss')});
    };

    useEffect(() => {
        setCurrentShip(shipment);
    }, [shipment]);
    
    useEffect(() => {
        if(!isEditing) setCurrentShip({});
       
    }, [isEditing]);

    return (
        <Fragment>
            <table className="d-none d-lg-table">
                <thead>
                    <tr className="shipmentsTitles">
                        {columns.map((c, i) => {
                            return !(props.full && c.id === 9)&& (
                                <th
                                    className={i === columns.length - 1 && !props.full ? "shipmentsTH act-width" 
                                                                             : c.wider ? 'shipmentsTH wider-col' 
                                                                             : c.noWidth ? 'shipmentsTH wider-less-col':"shipmentsTH" }
                                    onClick={handleOrder.bind(this, i, c.value)}
                                    key={`column-${i}-${reverse}-of-${order}`} >
                                    <div className="shipmentsTitle">
                                        {c.text}
                                        {c.id !== 8 &&
                                            <POWRIcon
                                                className={`far fa-${order === i && reverse ? 'arrow-up' : 'arrow-down'} ml-2`}
                                                size="10px"
                                                color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                        }
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => {
                        let status = getShipmentStatus(item.status, item.ship_event);
                        return (
                            isEditing && idd === item.idd? 
                            <tr
                                className={
                                    props.selectedItem === item.idd ? "shipmentRow shipmentRowActive"
                                        : props.full ? "shipmentRow shipmentRowFull" : "shipmentRow"
                                }
                                key={`shipment-${i}`}
                                onClick={props.onClick ? (e)=>hanldeSlectRow(item.idd,e): undefined}>
                                <td>
                                   <div className="td boldType">{item.ship_code.replace(/["'´]/g, "") || item.idd}<span>{item.is_paperless ? ' *' : ''}</span></div>
                                </td>
                                
                                <td><div className="td boldType" title={item.rmas ? item.rmas.join(', ').replace(/["'´]/g, "") : ''}>
                                    {item.rmas ? item.rmas.join(', ').replace(/["'´]/g, ""): ''}
                                </div></td>
                                    
                                    {THEATER_TYPES[0].code === item.theater_code?
                                       
                                    <td>
                                        <div className="td" title={createdDate(item.ship_event_time)}>
                                        {createdDate(item.ship_event_time)}
                                    </div>
                                    </td> :
                                    <td>
                                        <div className="td-calendar-table" title={(item.ship_event_time)}>
                                            <InputCalendar
                                            minDate={moment().startOf('day').toDate()}
                                            text={createdDate(currentShip.ship_event_time) !== "" ? createdDate(currentShip.ship_event_time) : ""}
                                            value={createdDate(currentShip.ship_event_time) !== "" ? moment(createdDate(currentShip.ship_event_time,dateFormat)) : ""}
                                            onBlur={(v, m) => handleStatusDate(v, m)}
                                            placeholder={dateFormat}
                                            //className="mb-3 pr-1 rlsp-calendar"
                                            checkMinMax={false}
                                            //noPostion
                                            className={'mb-0 mt-0 height-auto'}
                                            paddingInput={"pl-0"}
                                            fromTable
                                            ></InputCalendar>
                                        </div>
                                    </td>
                                }
                                
                                {item.ship_event === 2 || item.ship_event === 1 ?
                                   
                                    <td key={`edit-${isEditing}-${item.agreed_pickup_dt}`}><div className="td-calendar-table" title={(item.agreed_pickup_dt)}>
                                    <InputCalendar
                                            minDate={moment().startOf('day').toDate()}
                                            text={agreedDate(currentShip.agreed_pickup_dt) !== "" ? agreedDate(currentShip.agreed_pickup_dt):""}
                                            value={agreedDate(currentShip.agreed_pickup_dt) !== "" ? moment(agreedDate(currentShip.agreed_pickup_dt), dateFormat): ""}
                                            onBlur={(v, m) => handleAgreedDate(v, m)}
                                            placeholder={dateFormat}
                                            //className="mb-3 pr-1"
                                            checkMinMax={false}
                                            noPostion
                                            className={'mb-0 mt-0 height-auto'}
                                            paddingInput={"pl-0"}
                                            fromTable
                                            ></InputCalendar>
                                        </div></td>
                                        :
                                         <td key={`read-${isEditing}-${item.agreed_pickup_dt}`}><div className="td" title={(item.agreed_pickup_dt)}>
                                        {agreedDate(item.agreed_pickup_dt)}
                                    </div></td>
                                }
                        
                                
                                <td key={`tns-${isEditing}-${item.idd}`}>
                                    <div className="td boldType" title={item.tns ? item.tns.join(',').replace(/["'´]/g, ""): ''}>
                                   
                                    <textarea
                                        style={{ height: 'auto', background :'white' }}
                                        value={currentShip.tns ? ((typeof currentShip.tns === 'string')?currentShip.tns.replace(/["'´]/g, ""):currentShip.tns) : ''}
                                        onChange={e => setCurrentShip({...currentShip, tns: e.target.value})}
                                        className="sizeInput m-0"
                                        />
                                    
                                    </div>
                                </td>
                           
                                    <td key={`status-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                        <Select
                                        value={shipmentStatusSelected}
                                        onChange={handleShipmentStatus}
                                        options={optionsShipmentStatus}
                                        singleSelection
                                        hideClear
                                        className={'mb-0 mt-0'}
                                        fromTable
                                        status={statusTable}
                                        />
                                    </td>
                             
                                
                                
                                <td><div className="td" title={item.carrier_description}>
                                <textarea
                                        style={{ height: 'auto', background :'white' }}
                                        value={currentShip.carrier_msg ? currentShip.carrier_msg.split(', desc=')[1] ? currentShip.carrier_msg.split('desc=')[1] : currentShip.carrier_msg.replace(/["'´]/g, "") : ''}
                                        onChange={e => setCurrentShip({...currentShip, carrier_msg: e.target.value})}
                                        className="sizeInput m-0"
                                        />
                                    
                                </div></td>
                                   
                                <td key={`upd-${isEditing}-${item.idd}`}>
                                    <div className="actionsUpd d-flex justify-content-end">
                                        <div  key={`cancel-${item.ship_event}`} className="d-flex justify-content-end mb-2 mr-2" >
                                            <div onClick={()=>setIsEditing(false)}>            
                                                <span><POWRIcon className="far fa-times" color={COLOR.RED} size="16px" selectable /></span>        
                                            </div>
                                        </div>
                                        <div  key={`save-${item.ship_event}`} className="d-flex justify-content-end mb-2" >
                                            <div onClick={(e)=>handleSubmit(item)}>            
                                                <span><POWRIcon className="far fa-check" color={COLOR.GREEN} size="16px" selectable /></span>        
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                

                                {!props.full && <td key={`actions-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                    <div className="actionsDropDown">
                                        {SHIPMENT_EVENT_STATUS[item.ship_event] ?
                                            <DropDown
                                                options={SHIPMENT_EVENT_STATUS[item.ship_event].actions}
                                                labelId={item.idd}
                                                fullId={item.ship_code}
                                                item={item}
                                            />
                                            : <div />
                                        }
                                    </div>
                                </td>}
                            </tr>
                            :
                            <tr
                            className={
                                props.selectedItem === item.idd ? "shipmentRow shipmentRowActive"
                                    : props.full ? "shipmentRow shipmentRowFull" : "shipmentRow"
                            }
                            key={`shipment-${i}`}
                            onClick={props.onClick ? (e)=>hanldeSlectRow(item.idd,e): undefined}>
                            <td>
                               <div className="td boldType">{item.ship_code || item.idd}<span>{item.is_paperless ? ' *' : ''}</span></div>
                            </td>
                            
                            <td><div className="td boldType" title={item.rmas ? item.rmas.join(', ') : ''}>
                                {item.rmas ? item.rmas.join(', ').replace(/["'´]/g, "") : '—'}
                            </div></td>
                            
                            <td><div className="td" title={createdDate(item.ship_event_time)}>
                                {createdDate(item.ship_event_time)}
                            </div></td>
                            <td key={`read-${isEditing}-${item.agreed_pickup_dt}`}><div className="td" title={createdDate(item.agreed_pickup_dt)}>
                                {agreedDate(item.agreed_pickup_dt)}
                            </div></td>
                            
                            <td key={`tnslbl-${isEditing}-${item.idd}`}><div className="td boldType" title={item.tns ? item.tns.join(', ') : ''}>
                                {item.tns ? item.tns.join(', ').replace(/["'´]/g, "") : ''}
                            </div></td>  
                            <td key={`status-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                <Status className="td" status={status} />
                            </td>
                            
                            <td><div className="td" title={item.carrier_description}>
                                {item.carrier_msg ? item.carrier_msg.split(', desc=')[1] ? item.carrier_msg.split('desc=')[1] : item.carrier_msg.replace(/["'´]/g, "") : ''}
                            </div></td>
                            
                            <td key={`upd-${isEditing}-${item.idd}`}> 
                                <div className="actionsUpd">
                                    <div  key={`edit-${item.ship_event}`} className="d-flex justify-content-end mb-2" >
                                        <div onClick={(e)=>handleEdit(item,status,false,e)}>            
                                            <span><POWRIcon className="far fa-pen" color={COLOR.CISCO_BLUE} size="16px" selectable /></span>        
                                        </div>
                                    </div>
                                </div>
                            </td>
                          
                            {!props.full && <td key={`actions-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                <div className="actionsDropDown">
                                    {SHIPMENT_EVENT_STATUS[item.ship_event] ?
                                        <DropDown
                                            options={SHIPMENT_EVENT_STATUS[item.ship_event].actions}
                                            labelId={item.idd}
                                            fullId={item.ship_code}
                                            item={item}
                                        />
                                        : <div />
                                    }
                                </div>
                            </td>}
                        </tr>
                        );
                    })
                    }
                </tbody>
            </table>
               
            {props.full ? 
                <div className="d-block d-lg-none">
                    {items.map((item, i) => {
                        let status = getShipmentStatus(item.status, item.ship_event);
                        return (
                            <div key={`shipment-${i}`} className="mini-shipment-list" >
                                <div className="shipment-id">
                                {expand && idd === item.idd ? 
                                    <div key={`arrow-${expand}`}  onClick={(e)=>handlerExpand(item)} >
                                        <POWRIcon className={"fas fa-caret-down" } color={COLOR.CISCO_BLUE } size="16px" selectable /> 
                                    </div>:
                                    <div key={`arrow1-${expand}`}  onClick={(e)=>handlerExpand(item)} >
                                        <POWRIcon className={"fas fa-caret-right" } color={COLOR.CISCO_BLUE } size="16px" selectable /> 
                                    </div>
                                }
                                
                                    
                                    <div className="main flex-title ml-3">
                                        <span><POWRIcon className="fas fa-circle mr-2" size="10px" color={status ? status.color : '#000'} /></span>{translate('gridColumns.shipmentID')}: {item.ship_code || item.idd}<span>{item.is_paperless ? ' *' : ''}</span>
                                    </div>
                                    {
                                        expand && idd === item.idd && isEditing?
                                            <div key='save' className='d-flex'>
                                                <div key={`save-${expand}-${i}`}  onClick={()=>handleSubmit()}>
                                                    <POWRIcon className="fal fa-check mr-2" size="14px" color={COLOR.GREEN} selectable/>
                                                </div>
                                                <div key={`cancel-${expand}-${i}`} onClick={()=>setIsEditing(false)} >
                                                    <POWRIcon className="fal fa-times mr-2" size="14px" color={COLOR.RED} selectable/>
                                                </div>
                                            </div>
                                            :
                                            <div key={`edit-${expand}-${i}`}  onClick={(e)=>handleEdit(item,status,true, e)} >
                                                <POWRIcon className="fal fa-pen mr-2" size="14px" color={COLOR.CISCO_BLUE} selectable/>
                                            </div>
                                    }
                                </div>
                            {expand && idd === item.idd?
                            <Fragment>
                                <div className="shipment-info row">
                                    <div className="col-4 info-column">
                                        <span className="light">{translate('gridColumns.created')}</span>
                                        <span className="light">{translate('gridColumns.createdBy')}</span>
                                        <span className="light">{translate('gridColumns.carrier')}</span>
                                        <span className="light">{translate('gridColumns.rmas')}</span>
                                        <span className="light">{translate('gridColumns.pickupRequested')}</span>
                                        
                                        {!isEditing &&
                                            <Fragment>
                                                <span className="light">{translate('gridColumns.agreedPickupDate')}</span>                                     
                                                <span className="light">{translate('formLabel.tracking')}</span>                                     
                                                <span className="light">{translate('formLabel.carrier')}</span>   
                                                <span className="light">{translate('gridColumns.statusDate')}</span>
                                                <span className="light">{translate('gridColumns.status')}</span>
                                                 
                                            </Fragment>                                                                   
                                        }
                                    </div>
                                    {
                                        isEditing ? 
                                        <div className="col-8 info-column">
                                        <span className="normal">{createdDate(item.date_created)}</span>
                                        <span className="normal">{item.principal}</span>
                                        <span className="normal">{item.carrier_description || '—'}</span>
                                        <span className="normal"><strong>
                                            {item.rmas ? item.rmas.join(', ') : '—'}
                                        </strong></span>
                                        <span className="normal">
                                            {`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}
                                        </span>
                                        </div>
                                        :
                                        <div className="col-8 info-column">
                                        <span className="normal">{createdDate(item.date_created)}</span>
                                        <span className="normal">{item.principal}</span>
                                        <span className="normal">{item.carrier_description || '—'}</span>
                                        <span className="normal"><strong>
                                            {item.rmas ? item.rmas.join(', ') : '—'}
                                        </strong></span>
                                        <span className="normal">
                                            {`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : '-'}`}
                                        </span>
                                        <span className="normal">
                                            {agreedDate(item.agreed_pickup_dt)}
                                        </span>

                                        <span className="normal">
                                                {item.tns ? item.tns.join(', ') : '—'}
                                        </span>
                                        <span className="normal">
                                            {item.carrier_msg ? item.carrier_msg.split(', desc=')[1] ? item.carrier_msg.split('desc=')[1] : item.carrier_msg : '-'}
                                        </span>
                                        <span className="normal"> 
                                            {createdDate(item.ship_event_time)}
                                        </span>
                                       
                                        <span className="normal">
                                            <Status className="td" status={status} />
                                        </span>
                                    </div>
                                    }
                                   
                                </div>
                                {isEditing &&
                                <Fragment>
                          
                                    <div className='row shipment-info shipment-row'>
                                        <div className='col-4 info-column center-content'>
                                                <span className="light align-bottom">{translate('gridColumns.agreedPickupDate')}</span>    
                                        </div>
                                        <div className='col-8 info-column'>
                                            <span className="normal">
                                                {item.ship_event === 2 || item.ship_event === 1 ?
                                                    <InputCalendar
                                                    minDate={moment().startOf('day').toDate()}
                                                    text={agreedDate(currentShip.agreed_pickup_dt,true) !== "" ? agreedDate(currentShip.agreed_pickup_dt, true):""}
                                                    value={agreedDate(currentShip.agreed_pickup_dt, true) !== "" ? moment(agreedDate(currentShip.agreed_pickup_dt, true)): ""}
                                                    onBlur={(v, m) => handleAgreedDate(v, m)}
                                                    placeholder={dateFormat}
                                                    //className="mb-3 pr-1"
                                                    checkMinMax={false}
                                                    noPostion
                                                    className={'mb-0 mt-0 height-auto'}
                                                    paddingInput={"pl-0"}
                                                    fromTable
                                                    clear
                                                    hideClear
                                                    ></InputCalendar>
                                                :
                                                
                                                    createdDate(item.agreed_pickup_dt,true)
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row shipment-info shipment-row p-0' >
                                        <div className='col-4 info-column center-content'>
                                            <span className="light">{translate('formLabel.tracking')}</span>        
                                        </div>
                                        <div className='col-8 info-column'>
                                            <span className="normal">
                                                <textarea
                                                style={{ height: 'auto', background :'white' }}
                                                value={currentShip.tns ? currentShip.tns : ''}
                                                onChange={e => setCurrentShip({...currentShip, tns: e.target.value})}
                                                className="sizeInput m-0"
                                                />
                                            </span>
                                        </div>

                                    </div>

                                    <div className='row shipment-info shipment-row p-0' >
                                        <div className='col-4 info-column center-content'>
                                            <span className="light">{translate('formLabel.carrier')}</span>    
                                        </div>
                                        <div className='col-8 info-column'>
                                            <span className="normal">
                                                <textarea
                                                style={{ height: 'auto', background :'white' }}
                                                value={currentShip.carrier_msg ? currentShip.carrier_msg.split(', desc=')[1] ? currentShip.carrier_msg.split('desc=')[1] : currentShip.carrier_msg : ''}
                                                onChange={e => setCurrentShip({...currentShip, carrier_msg: e.target.value})}
                                                className="sizeInput m-0"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row shipment-info shipment-row p-0' >
                                        <div className='col-4 info-column center-content'>
                                            <span className="light">{translate('gridColumns.statusDate')}</span>
                                        </div>
                                        <div className='col-8 info-column'>
                                            {THEATER_TYPES[0].code === item.theater_code?
                                                <span className="normal">
                                                    {createdDate(item.ship_event_time, true)}
                                                </span>
                                                        :
                                                <span className="normal"> 
                                                    <InputCalendar
                                                    minDate={moment().startOf('day').toDate()}
                                                    text={createdDate(currentShip.ship_event_time, true) !== "" ? createdDate(currentShip.ship_event_time, true) : ""}
                                                    value={createdDate(currentShip.ship_event_time, true) !== "" ? moment(createdDate(currentShip.ship_event_time, true)) : ""}
                                                    onBlur={(v, m) => handleStatusDate(v, m)}
                                                    placeholder={dateFormat}
                                                    checkMinMax={false}
                                                    className={'mb-0 mt-0 height-auto'}
                                                    paddingInput={"pl-0"}
                                                    fromTable
                                                    ></InputCalendar>
                                            </span>
                                            }
                                         
                                        </div>
                                    </div>

                                    <div className='row shipment-info shipment-row p-0' >
                                       <div className='col-4 info-column center-content'>
                                             <span className="light">{translate('gridColumns.status')}</span>     
                                        </div>
                                        <div className='col-8 info-column'>
                                            <span className="normal">
                                                <Select
                                                value={shipmentStatusSelected}
                                                onChange={handleShipmentStatus}
                                                options={optionsShipmentStatus}
                                                singleSelection
                                                hideClear
                                                className={'mb-0 mt-0'}
                                                fromTable
                                                status={statusTable}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                   
                           

                               </Fragment>
                            }
                                <div className="shipment-details row">
                                    <div className="download-center-links col">
                                            <Link className="download-link" to={{ pathname: SHIPMENT_DETAIL, search: `?id=${ship_code}`, state: { id: ship_code, data: shipment } }}>
                                                {translate('aux.shipmentDetail')}
                                            </Link>
                                    </div>
                                    <div className="download-center-links col text-right">
                                            <Link className="download-link" to={{ pathname: SHIPMENT_DETAIL, search: `?id=${ship_code}`, state: { id: ship_code, data: shipment } }}>
                                                {translate('title.downloadLabels')}
                                            </Link>
                                    </div>
                                </div>
                                </Fragment>
                                :<div></div>
                            }
                            </div>
                        )
                    })}
                    <POWRModal
                        show={showDetails}
                        title={translate(`title.shipmentDetails`)}
                        size="long"
                        onClose={() => handleDetails(false, -1)}
                    >
                        <ShipmentsInfoBar mobile />
                    </POWRModal>
                </div>
            :
                <div className="d-block d-lg-none">
                    {items.map((item, i) => {
                        let status = getShipmentStatus(item.status, item.ship_event);
                        return (
                            <div key={`shipment-${i}`} className="mini-shipment">
                                <div className="col-2 pl-0" key={`status-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                    <POWRIcon className="fas fa-circle" size="10px" color={status?.color} />
                                </div>
                                <div className="col-10 pl-0">
                                    <div className="row columns">
                                        <div className="col main">{item.ship_code || item.idd}<span>{item.is_paperless ? ' *' : ''}</span></div>
                                        <div className="col-auto p-0">
                                            {SHIPMENT_EVENT_STATUS[item.ship_event] && !props.full ?
                                                <DropDown
                                                    options={SHIPMENT_EVENT_STATUS[item.ship_event].actions}
                                                    labelId={item.idd}
                                                    fullId={item.ship_code}
                                                    item={item}
                                                />
                                                : <div />
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.rmas')}: `}</span>
                                            <span className="normal"> {item.rmas ? item.rmas.join(', ') : '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.createdBy')}: `}</span>
                                            <span className="normal">{item.principal}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.carrier')}: `}</span>
                                            <span className="normal">{item.carrier_description || '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.created')}: `}</span>
                                            <span className="normal">{createdDate(item.date_created)}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.pickupRequested')}: `}</span>
                                            <span className="normal">{`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.statusDate')}: `}</span>
                                            <span className="normal">  {createdDate(item.ship_event_time)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
              <POWRModal
                    size="small"
                    show={unsavedChangesModal}
                    title={translate('title.unsavedChanges')}
                    onClose={setUnsavedChangesModal.bind(this, false)} >
             
                <div className="content">
                   <p className="pt-3">{translate('message.leaveRLSPProcess')}</p>
                </div>
                <div className="toolbar">
                    <POWRButton type="button" color='secondary' onClick={() => confirmChangeRow(true)}>{translate('button.cancel')}</POWRButton>
                    <POWRButton type="button" onClick={() => confirmChangeRow(false)}>{translate('button.ok')}</POWRButton>
                </div>
            </POWRModal>
              
        </Fragment>
    );
}

export default ShipmentsTable;