import React, { Fragment, useState } from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRIcon, POWRModal } from "../styledComponents";
import { Button, Link } from "../controls";
import { FORM_TYPE } from '../../core/constants';
import { COLOR } from '../../core/constants/Colors';

export const HelpCommercialInvoiceModal = ({ type }) => {
    const { translate } = useTranslate();
    const [instruction, setInstruction] = useState(false);

    const title = type === FORM_TYPE.COMMERCIAL_INVOICE ? "Commercial Invoice Form Instructions" : type === FORM_TYPE.PACKING_LIST ? "Packing List Form Instructions" : "Shipper's Letter of Instructions Form";
    const subTitle = type === FORM_TYPE.COMMERCIAL_INVOICE ? "Commercial Invoice" : type === FORM_TYPE.PACKING_LIST ? "Packing List" : "Shipper's Letter of Instructions";

    return (
        <Fragment>
            <div onMouseOver={() => setInstruction(true)}>
                <Link className="linkComponentInstruction" >
                    <POWRIcon className="fas fa-info-circle mr-2" size="22px" color={COLOR.CISCO_BLUE} />
                    {translate('aux.instruction')}
                </Link>
            </div>
            <POWRModal
                show={instruction}
                title={title}
                onClose={() => setInstruction(false)}
                hideAlerts
            >
                <div className="content">
                    {type === FORM_TYPE.COMMERCIAL_INVOICE && <h3>Commercial Invoice (CI) is a mandatory document to return defective Cisco spares.</h3>}
                    <p>Please fill in the columns which are not filled in automatically in POWR.</p>
                    <br />
                    <h3>{`Columns of ${subTitle} which are mandatory for filling in:`}</h3>
                    <p>1. № - sequence number.</p>
                    <p>2. RМА № - RMA/order number of the returned defective spare. *It is filled in automatically from POWR.</p>
                    <p>3. Part Number – part number of the returned defective spare. *It is filled in automatically from POWR. If it is reflected incorrectly please correct it manually.</p>
                    <p>4. Serial Number – serial number of the returned defective spare. *It is filled in automatically from POWR. If it is missing or reflected incorrectly please add/correct it manually.</p>
                    <p>5. COO – country of origin of the returned defective spare. *It is filled in automatically from POWR. If it is missing or reflected incorrectly
                        <b> please input this information found on the device’s product label.</b>
                    </p>
                </div>
                <div className="toolbar">
                    <Button onClick={() => setInstruction(false)} >
                        {translate('button.close')}
                    </Button>
                </div>
            </POWRModal>
        </Fragment>
    )
}
