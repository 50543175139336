import Request from "../core/api/request";
import { URL_SAVESHIPMENT, URL_SAVESHIPMENT_RLSP, BASE_URL_API } from "../core/constants/URLS";

export const saveShipment = (data) => {
    return Request.postURIENCODEDJSONSave({ url: `${URL_SAVESHIPMENT}`, data });
};

export const getShipmentPackages = (data) => {
    return Request.postEncode({ url: `get/shippackage`, data });
};

export const saveShipmentDocuments = (data) => new Promise((resolve) => {
    let fd = new FormData();

    fd.append("fileToUpload", data.file);
    let xhr = new XMLHttpRequest();
    
    xhr.open("POST", BASE_URL_API + "/save/shipmentDocuments?jsessionid=" + data.session_id + "&cisco_user_id=" + data.cisco_user_id + "&login_email_id=" + data.login_email_id + "&ship_id=" + data.ship_id);
    xhr.send(fd);

    xhr.onload = function () {
        resolve( JSON.parse(xhr.response) );
    }
});


export const uploadRLSPDocument = (data) => new Promise((resolve) => {
    let fd = new FormData();
    fd.append("file-path", data.file);
    
    let xhr = new XMLHttpRequest();
  
    xhr.open("POST", BASE_URL_API + "/cmd/car_edit/load_file?update="+data.update);
    xhr.withCredentials = true;
    xhr.send(fd);
    xhr.onload = function () {
        resolve( JSON.parse(xhr.response) );
    }
});


export const removeShipmentDocuments = (fileIdd, ship_id) => {
    return Request.postEncode({ url: `del/shipmentDocuments`, data: {idd: fileIdd, ship_id: ship_id} });
};

export const getDocumentUploadRequired = (data) => {
    return Request.postEncode({ url: `get/DocumentUploadRequired`, data });
};

export const getSavedShipment = (id) => {
    return Request.getURIEncoded({ url: `get/savedshipment?_dc=${Date.now()}&id=${id}`});
};

export const deleteSavedShipment = (data) => {
    return Request.postEncode({ url: `del/shipment`, data});
};

export const getDocLanes = (data) => {
    return Request.postEncode({ url: `get/fact_doc_lanes`, data });
};

export const getShipRMA = (data) => { 
    return Request.postEncode({url: 'get/shiprma', data});
};

export const getShipmentById = (id) => {
    return Request.getURIEncoded({url: `get/shipment?ship_id=${id}`});
} 

export const saveShipmentRLSP = (data) => {
    return Request.postURIENCODEDJSONSave({ url: `${URL_SAVESHIPMENT_RLSP}`, data });
};