import React, { useState, useEffect, useCallback } from 'react';
import { useTranslate } from '../../core/contexts';
import { capitalize } from '../../core/helpers';
import { InfoSection } from '../../components/styledComponents';
import { ContactCard } from '../../components';
import { getPickupById, getShipmentById } from '../../services';

export default props => {
    const { translate } = useTranslate();
    const [gstin, setgstin] = useState('');
    const [pickLocation, setPickLocation] = useState(null);
    const [returnAddress, setReturnAddress] = useState(null);
    const [pickupContact, setPickupContact] = useState(null);

    useEffect(() => {
        if (props.rmaInfo && props.rmaInfo.gstin) setgstin(props.rmaInfo.gstin);
    }, [props.rmaInfo]);

    useEffect(() => {
        if (pickLocation) {
            let newInfo = pickLocation.info;
            newInfo.pop();
            newInfo.push(`${gstin ? translate('formLabel.gstin') + ': ' + gstin + '.' : ''}`);
            setPickLocation({ ...pickLocation, info: newInfo });
        }
    }, [gstin]);

    const loadPickupLocation = useCallback(() => {
        getPickupById(props.pickupId).then(res => {
            if (res.res.dtos && res.res.dtos.length) {
                const pickLoc = res.res.dtos[0];
                let info = [];
                for (let i = 0; i <= 3; i++) {
                    if (pickLoc[`pickup_addr${i + 1}`]) info.push(pickLoc[`pickup_addr${i + 1}`]);
                }

                const adds = info.join(', ');
                setPickLocation({
                    title: pickLoc.pickup_atag || pickLoc.pickup_name || '',
                    info: [
                        ...(pickLoc.pickup_atag ? [pickLoc.pickup_name] : []),
                        adds || '',
                        `${pickLoc.pickup_city ? pickLoc.pickup_city + ',' : ''} 
                        ${pickLoc.pickup_province ? pickLoc.pickup_province + ',' : ''} 
                        ${pickLoc.pickup_postal || ''} 
                        ${pickLoc.country || ''}`,
                        adds.length > 65 ? null : '',
                        `${gstin ? translate('formLabel.gstin') + ': ' + gstin + '.' : ''}`
                    ],
                    additional: pickLoc.site_directions || ''
                });
            }
        });
    }, [props.pickupId]);

    const loadPickupContactAndReturnAddress = useCallback(() => {
        getShipmentById(props.shipmentId).then(res => {
            if (res.res.dtos && res.res.dtos.length) {
                const shipData = res.res.dtos[0];
                let adds = [];
                for (let i = 0; i <= 4; i++) {
                    if (shipData[`return_addr${i + 1}`]) adds.push(shipData[`return_addr${i + 1}`]);
                }
                setReturnAddress({
                    title: shipData.return_atag || shipData.return_name || '',
                    info: [
                        shipData.return_name || '',
                        adds.join(' '),
                        `${shipData.return_city ? shipData.return_city + ', ' : ''}
                        ${shipData.return_province ? shipData.return_province + ', ' : ''}
                        ${shipData.return_postal ? (shipData.return_postal + ' ') : ''}
                        ${shipData.return_country || ''}`,
                        ...(shipData.gstin ? [`${translate('formLabel.gstin')}: ${shipData.gstin}`] : [])
                    ]
                });

                const fullName = `${capitalize(shipData.onsite_fname) + ' '}${capitalize(shipData.onsite_lname)}`;
                setPickupContact({
                    title: shipData.onsite_ctag || fullName || '',
                    info: [
                        shipData.onsite_email || '',
                        shipData.onsite_phone || ''
                    ]
                });
            }
        });
    }, [props.shipmentId]);

    useEffect(() => {
        loadPickupLocation();
        loadPickupContactAndReturnAddress();
    }, [loadPickupLocation, loadPickupContactAndReturnAddress]);

    return (
        <div className="pickupAddressCarrier">
            <div className="row mb-3">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <InfoSection
                        className="full-height"
                        title={translate('subtitle.pickupLocation')}
                        type={'cards'} >
                        <ContactCard
                            icon="map-marker-alt"
                            content={pickLocation}
                            hideButtons={true} />
                    </InfoSection>
                </div>
                <div className="col-12 col-md-6">
                    <InfoSection
                        className="full-height"
                        title={translate('subtitle.pickupContact')}
                        type={'cards'} >
                        <ContactCard
                            icon="user-circle"
                            content={pickupContact}
                            hideButtons={true} />
                    </InfoSection>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <InfoSection
                        className="full-height"
                        title={translate('subtitle.returnAddress')}
                        type={'cards'} >
                        <ContactCard
                            icon="warehouse"
                            content={returnAddress}
                            hideButtons={true} />
                    </InfoSection>
                </div>
            </div>
        </div>
    )
}