import { COLOR } from "./Colors";

let green = COLOR.GREEN;
let red = COLOR.RED;

export const RMAStatus = [
    { id: 1, color: green, text: 'shipmentsStatus.open' },
    { id: 2, color: red, text: 'shipmentsStatus.noOpenLines' }
]

export const RMAPaymentType = [
    { id: 1, text: 'shipmentsStatus.customer' },
    { id: 2, text: 'shipmentsStatus.cisco' }
]

export const RMACategoryType =[
    { id: "S", text: 'shipmentsStatus.service' },
    { id: "P", text: 'shipmentsStatus.product' }
]