export const REPORT_NAVIGATION = [
    {
        title: 'title.switzerlandCI',
        subtitle: 'message.switzerlandMsg',
        icon: 'fa-file-signature',
        route: '/commercial-invoice-ch',
        toggle: 'ch.and.fr.commercial.docs',
        enabled: false
    },
    {
        title: 'title.francePkgList',
        subtitle: 'message.francePkgListMsg',
        icon: 'fa-folders',
        route: '/packing-list-fr',
        toggle: 'ch.and.fr.commercial.docs',
        enabled: false
    },
   
]
 