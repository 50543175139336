import { createSelector } from 'reselect';

const selectWaybill = state => state.waybill;


// export const selectWaybill = createSelector(waybillState);

export const selectWayBillInfo = createSelector(
    [selectWaybill],
    (waybillInfo ) => ( waybillInfo)
);

export const selectShipmentIdWaybill = createSelector(
    [selectWaybill],
    ({ ship_id }) => ship_id
);
export const selectCarrierProfileIdWayBill = createSelector(
    [selectWaybill],
    ({ carrier_profile_id }) => carrier_profile_id
);

export const selectWaybillSearchParameters = createSelector(
    [selectWaybill],
    (waybillInfo) => waybillInfo.waybillSearchParameters
);

export const selectWaybillSearchResults = createSelector(
    [selectWaybill],
    (waybillInfo) => waybillInfo.waybillSearchResults
);

export const selectWaybillSearching = createSelector(
    [selectWaybill],
    (waybillInfo) => waybillInfo.waybillSearching
);