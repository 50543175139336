import { SET_USER_SETTINGS_PROP, RESET_USER_SETTINGS } from "../actionTypes";

const initialState = {
    security: null
};


export const userSettings = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_USER_SETTINGS_PROP:
            return {
                ...state,
                [payload.propName]: payload.value
            };
        case RESET_USER_SETTINGS: return initialState;
        default: return state;
    }
};