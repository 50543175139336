export const BASE = '/';
export const LOGIN = '/login';
export const DASHBOARD = '/home';
export const RETURN = '/return';
export const SEARCH_SHIPMENTS = '/shipments';
export const SEARCH_RMA = '/rmas';
export const RLSP = '/rlsp';
export const SEARCH_PREPRINTED_WAYBILL = '/preprinted-waybill';
export const DOCUMENTATION = '/documentation';
export const DOWNLOAD = '/download';
export const SHIPMENT_DETAIL = '/shipment';
export const SCHEDULE = '/schedule';
export const ADDRESS_BOOK = '/addressBook';
export const SETTINGS = '/settings';
export const RTFCOMMERCIALINVOICE = '/rtf-ci';
export const FR_PACKING_LIST = '/packing-list-fr';
export const CH_COMMERCIAL_INVOICE = '/commercial-invoice-ch';
export const MENU_REPORTS = '/menu-reports';
