import React, { useReducer, useContext } from "react";
import en from "../../static/i18n/en.json";
import fr from "../../static/i18n/fr.json";
import de from "../../static/i18n/de.json";
import it from "../../static/i18n/it.json";
import es from "../../static/i18n/es.json";
import esLatam from "../../static/i18n/es-latam.json";
import pt from "../../static/i18n/pt.json";
import ch from "../../static/i18n/ch.json";
import chOther from "../../static/i18n/ch-other.json";
import ko from "../../static/i18n/ko.json";
import jp from "../../static/i18n/jp.json";
import ru from "../../static/i18n/ru.json";

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();
const translations = {
    en, fr, de, it, es,
    "es-latam": esLatam,
    pt, ch,
    "ch-other": chOther,
    ko, jp, ru
};
const languages = ['en', 'fr', 'de', 'it', 'es', 'es-latam', 'pt', 'ch', 'ch-other', 'ko', 'jp', 'ru'];

const getTranslate = (langCode) => (key) => {
    let res = translations[langCode];
    for (let tag of key.split('.')) {
        res = res[tag] || '';
    }
    if (res.length && typeof (res) === 'string') return entities.decode(res);
    else {
        let resp = translations['en'];
        for (let tag of key.split('.')) {
            resp = resp[tag] || '';
        }
        if (resp.length && typeof (resp) === 'string') return entities.decode(resp);
        else return key;
    }
};

const initialState = {
    langCode: languages[0],
    translate: getTranslate(languages[0]),
    languages
};

const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {

    const reducer = (state, action) => {
        switch (action.type) {
            case "setLanguage":
                localStorage.setItem('powr3_lang_code', action.payload);
                return {
                    ...state,
                    langCode: action.payload,
                    translate: getTranslate(action.payload)
                };
            default:
                return state;
        }
    };

    const [state, dispatchI18n] = useReducer(reducer, initialState);

    return (
        <I18nContext.Provider value={{ ...state, dispatchI18n }}>
            {children}
        </I18nContext.Provider>
    );
};

export const useTranslate = () => useContext(I18nContext);