import React, { useState } from 'react';
import moment from 'moment';
import { useTranslate } from '../../../core/contexts';
import { DropDown, DropDownPickup, Status } from '../../controls';
import { SHIPMENT_EVENT_STATUS, getShipmentStatus } from '../../../core/constants/ShipmentsStatus';
import { COLOR } from '../../../core/constants/Colors.js';
import { POWRIcon, POWRModal } from '../../styledComponents';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { setShipmentSeleted } from '../../../core/redux/actions';
import { ShipmentsInfoBar } from '../../index';

const ShipmentsTable = (props) => {
    const dispatch = useDispatch();
    const { items, allItems, setAllItems, handlePages } = props;
    const { translate } = useTranslate();
    const [order, setOrder] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const dateFormat = sessionStorage.getItem('dateFormat');

    const handleOrder = (o, value) => {
        dispatch(setShipmentSeleted({}));
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(true);
        }
        setOrder(o);

        let r = order === o ? !reverse : true;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        let newOrder = allItems.sort(compare);
        setAllItems(newOrder);
        handlePages(newOrder);
    }

    const columns = [
        { id: 0, value: 'ship_code', type: 'tex', text: translate('gridColumns.shipmentID') },
        { id: 1, value: 'date_created', type: 'tex', text: translate('gridColumns.created') },
        { id: 2, value: 'principal', type: 'tex', text: translate('gridColumns.createdBy') },
        { id: 3, value: 'carrier_description', type: 'tex', text: translate('gridColumns.carrier') },
        { id: 4, value: 'rmas', array: true, type: 'num', text: translate('gridColumns.rmas') },
        { id: 5, value: 'date_pickup', type: 'dat', text: translate('gridColumns.pickupRequested') },
        // { id: 6, value: 'tns', array: true, text: translate('gridColumns.tracking') },
        { id: 6, value: 'ship_event_time', type: 'dat', text: translate('gridColumns.statusDate') },
        { id: 7, value: 'ship_event', type: 'tex', text: translate('gridColumns.status') },
        { id: 8, value: '', text: translate('gridColumns.actions') }
    ];

    const createdDate = (date) => {
        return date && moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDDHHmmss').format(dateFormat) : '—';
    }

    const returnedDate = (date) => {
        return date && moment(date).format(dateFormat) !== 'Invalid date' ?
            moment(date).format(dateFormat) : '—';
    }

    const handleDetails = (val, id) => {
        setShowDetails(val);
        props.onClick(id);
    }

    return (
        <Fragment>
            <table className="d-none d-lg-table">
                <thead>
                    <tr className="shipmentsTitles">
                        {columns.map((c, i) => {
                            return !(props.full && c.id === 8) && (
                                <th
                                    className={i === columns.length - 1 && !props.full ? "shipmentsTH act-width" : "shipmentsTH"}
                                    onClick={handleOrder.bind(this, i, c.value)}
                                    key={`column-${i}-${reverse}-of-${order}`} >
                                    <div className="shipmentsTitle">
                                        {c.text}
                                        {c.id !== 8 &&
                                            <POWRIcon
                                                className={`far fa-${order === i && reverse ? 'arrow-up' : 'arrow-down'} ml-2`}
                                                size="10px"
                                                color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                        }
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => {
                        let status = getShipmentStatus(item.status, item.ship_event);
                        return (
                            <tr
                                className={
                                    props.selectedItem === item.idd ? "shipmentRow shipmentRowActive"
                                        : props.full ? "shipmentRow shipmentRowFull" : "shipmentRow"
                                }
                                key={`shipment-${i}`}
                                onClick={props.onClick ? props.onClick.bind(this, item.idd) : undefined}>
                                <td>
                                    <div className="td boldType">{item.ship_code || item.idd}<span>{item.is_paperless ? ' *' : ''}</span></div>
                                </td>
                                <td><div className="td" title={createdDate(item.date_created)}>
                                    {createdDate(item.date_created)}
                                </div></td>
                                <td><div className="td" title={item.principal}>{item.principal}</div></td>
                                <td><div className="td" title={item.carrier_description}>
                                    {item.carrier_description || '—'}
                                </div></td>
                                <td><div className="td boldType" title={item.rmas ? item.rmas.join(', ') : '—'}>
                                    {item.rmas ? item.rmas.join(', ') : '—'}
                                </div></td>
                                <td><div className="td" title={`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}>
                                    {`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}
                                </div></td>
                                <td><div className="td" title={createdDate(item.ship_event_time)}>
                                    {createdDate(item.ship_event_time)}
                                </div></td>
                                <td key={`status-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                    <Status className="td" status={status} />
                                </td>
                                {!props.full && <td key={`actions-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                    <div className="actionsDropDown">
                                        {SHIPMENT_EVENT_STATUS[item.ship_event] ?
                                            <DropDown
                                                options={SHIPMENT_EVENT_STATUS[item.ship_event].actions}
                                                labelId={item.idd}
                                                fullId={item.ship_code}
                                                item={item}
                                            />
                                            : <div />
                                        }
                                    </div>
                                </td>}
                            </tr>
                        );
                    })
                    }
                </tbody>
            </table>
            {props.full ? 
                <div className="d-block d-lg-none">
                    {items.map((item, i) => {
                        let status = getShipmentStatus(item.status, item.ship_event);
                        return (
                            <div key={`shipment-${i}`} className="mini-shipment-list" >
                                <div className="shipment-id">
                                    <div className="main">
                                        {translate('gridColumns.shipmentID')}: {item.ship_code || item.idd}<span>{item.is_paperless ? ' *' : ''}</span>
                                    </div>
                                    <div>
                                        <DropDownPickup item={item} />
                                    </div>
                                </div>
                                <div className="shipment-info row">
                                    <div className="col-4 info-column">
                                        <span className="light">{translate('gridColumns.created')}</span>
                                        <span className="light">{translate('gridColumns.createdBy')}</span>
                                        <span className="light">{translate('gridColumns.carrier')}</span>
                                        <span className="light">{translate('gridColumns.rmas')}</span>
                                        <span className="light">{translate('gridColumns.pickupRequested')}</span>
                                        {/* <span className="light">{translate('formLabel.tracking')}</span>                                     */}
                                        <span className="light">{translate('gridColumns.status')}</span>                                    
                                    </div>
                                    <div className="col-8 info-column">
                                        <span className="normal">{createdDate(item.date_created)}</span>
                                        <span className="normal">{item.principal}</span>
                                        <span className="normal">{item.carrier_description || '—'}</span>
                                        <span className="normal"><strong>
                                            {item.rmas ? item.rmas.join(', ') : '—'}
                                        </strong></span>
                                        <span className="normal">
                                            {`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}
                                        </span>
                                        {/* <span className="normal">{tns ? tns.length > 1 ? tns.join(', ') : tns[0] : '—'}</span> */}
                                        <span className="normal"><Status className="double" status={status} /></span>
                                    </div>
                                </div>
                                <div className="shipment-details">
                                    <label className="showDetails" onClick={() => handleDetails(true, item.idd)}>
                                        {translate('button.showAllDetails')}
                                    </label>
                                </div>
                            </div>
                        )
                    })}
                    <POWRModal
                        show={showDetails}
                        title={translate(`title.shipmentDetails`)}
                        size="long"
                        onClose={() => handleDetails(false, -1)}
                    >
                        <ShipmentsInfoBar mobile />
                    </POWRModal>
                </div>
            :
                <div className="d-block d-lg-none">
                    {items.map((item, i) => {
                        let status = getShipmentStatus(item.status, item.ship_event);
                        return (
                            <div key={`shipment-${i}`} className="mini-shipment">
                                <div className="col-2 pl-0" key={`status-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                    <POWRIcon className="fas fa-circle" size="10px" color={status?.color} />
                                </div>
                                <div className="col-10 pl-0">
                                    <div className="row columns">
                                        <div className="col main">{item.ship_code || item.idd}<span>{item.is_paperless ? ' *' : ''}</span></div>
                                        <div className="col-auto p-0">
                                            {SHIPMENT_EVENT_STATUS[item.ship_event] && !props.full ?
                                                <DropDown
                                                    options={SHIPMENT_EVENT_STATUS[item.ship_event].actions}
                                                    labelId={item.idd}
                                                    fullId={item.ship_code}
                                                    item={item}
                                                />
                                                : <div />
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.rmas')}: `}</span>
                                            <span className="normal"> {item.rmas ? item.rmas.join(', ') : '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.createdBy')}: `}</span>
                                            <span className="normal">{item.principal}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.carrier')}: `}</span>
                                            <span className="normal">{item.carrier_description || '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.created')}: `}</span>
                                            <span className="normal">{createdDate(item.date_created)}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.pickupRequested')}: `}</span>
                                            <span className="normal">{`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.statusDate')}: `}</span>
                                            <span className="normal">  {createdDate(item.ship_event_time)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        </Fragment>
    );
}

export default ShipmentsTable;