import "core-js/stable";
import "regenerator-runtime/runtime";
import "intersection-observer";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./core/redux/store";
import { Provider } from "react-redux";
import { I18nContextProvider } from "./core/contexts";
import { ThemeProvider } from "styled-components";
import { theme } from "./core/styles/theme";

const store = configureStore();

render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </I18nContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
