import {
    SET_DOCUMENTATION_INPUT_RMA,
    SET_DOCUMENTATION_ERROR_MSG,
    SET_DOCUMENTATION_ERROR,
    SET_DOCUMENTATION_RESULTS
} from '../actionTypes';

export const setDocumentationInputRMA = (data) => ({
    type: SET_DOCUMENTATION_INPUT_RMA,
    payload: data
});

export const setDocumentationErrorMsg = (data) => ({
    type: SET_DOCUMENTATION_ERROR_MSG,
    payload: data
});

export const setDocumentationError = (data) => ({
    type: SET_DOCUMENTATION_ERROR,
    payload: data
});

export const setDocumentationResults = (data) => ({
    type: SET_DOCUMENTATION_RESULTS,
    payload: data
});