import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, ActionButtons, Info } from '../controls';
import { useTranslate } from '../../core/contexts';
import { PartList } from '../index'
import { useDispatch, useSelector } from 'react-redux';
import { setCreatePackagesStep, setReturnFlowProp } from '../../core/redux/actions';
import {
    selectCreatePackagesStep, selectPackages, selectSelectedSublines,
    selectSublines, selectCreatedLocalPackagesByLine, selectBaseRMAInfo, selectCategoryCode
} from '../../core/redux/selectors';
import { SET_SUB_LINES } from '../../core/redux/actionTypes';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';
import { PackageQtyForm } from '../forms';
import { useCreatePackages, useGlobalMessage } from '../../core/hooks';
import { COLOR } from '../../core/constants/Colors';

const CreatePackages = ({ back, next }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showPageMessage } = useGlobalMessage(); // Global Messages
    const { selectedParts, isLoading, error, setError, handleSubmit, rmaNums } = useCreatePackages();

    const [showModal, setShowModal] = useState(false);
    const [showQtyModal, setShowQtyModal] = useState(false);

    const step = useSelector(selectCreatePackagesStep);
    const selectedSublines = useSelector(selectSelectedSublines); // Checked sublines of createPackages table
    const sublines = useSelector(selectSublines); // All source sublines
    const packages = useSelector(selectPackages);
    const createdLocalPackages = useSelector(selectCreatedLocalPackagesByLine);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const categoryCode = useSelector(selectCategoryCode);

    const headers = [
        translate('gridColumns.part#'),
        translate('formLabel.qty'),
        `${translate('title.package')}/${translate('aux.box')}`
    ];

    useEffect(() => {
        dispatch(setReturnFlowProp('selectedSubLines', step === 0 ? selectedParts.map(x => x.idd) : []));
    }, [step, selectedParts, dispatch]);

    useEffect(() => {
        let params = {
            module: 'GCT_Step3',
            country: rmaInfo.country,
            rmaCategory: categoryCode
        }
        showPageMessage(params, '/return');

        dispatch(setCreatePackagesStep(0));
        setError(false);
    }, []);

    const handleSingleReturn = useCallback(() => {
        let newSublines = [];
        setError(false);

        // REMOVE PACKAGE_ID OF SELECTED SUBLINES
        newSublines = sublines.map(subline => {
            const isSelected = selectedSublines.find(sublineId => sublineId === subline.idd);
            return !isSelected ? subline : { ...subline, package_id: null };
        });

        // FIND UNUSED PACKAGE TO BE ASIGNED IN EACH SELECTED SUBLINE
        const unusedPackages = packages.filter(pk => !newSublines.find(sb => sb.package_id === pk.idd));

        // ASSIGN NEW PACKAGE ID IN EACH SELECTED SUBLINE
        newSublines = newSublines.map(subline => {
            const isSelected = selectedSublines.find(sublineId => sublineId === subline.idd);
            const unusedPackage = unusedPackages.find(pk => pk.rma_num === subline.rma_num);

            if (!isSelected || !unusedPackage) return subline;
            return {
                ...subline,
                package_id: unusedPackage.idd,
                package_code: unusedPackage.pack_code
            };
        });

        // UPDATE REDUX SUBLINES 
        dispatch({ type: SET_SUB_LINES, payload: newSublines });
        dispatch(setReturnFlowProp('oldSubLines', newSublines));
    }, [selectedSublines, sublines, packages, dispatch]);

    const handleMultipleReturn = useCallback(() => {
        let newSublines = [];
        setError(false);

        // REMOVE PACKAGE_ID OF SELECTED SUBLINES TO BE ABLE TO HANDLE THEM
        newSublines = sublines.map(subline => {
            const isSelected = selectedSublines.find(sublineId => sublineId === subline.idd);
            return !isSelected ? subline : { ...subline, package_id: null };
        });

        // FIND UNUSED PACKAGES TO BE ASIGNED IN EACH SELECTED SUBLINE
        const unusedPackages = packages.filter(pk => !newSublines.find(sb => sb.package_id === pk.idd));

        newSublines = newSublines.map(subline => {
            const isSelected = selectedSublines.findIndex(sublineId => sublineId === subline.idd) >= 0;
            if (!isSelected) return subline;

            // FIND UNUSED PACKAGE TO BE ASIGNED AT THIS SUBLINE
            const unusedPackageIdx = unusedPackages.findIndex(pk => pk.rma_num === subline.rma_num);
            const unusedPackage = unusedPackages[unusedPackageIdx];
            const newSubline = !unusedPackage ? subline : {
                ...subline,
                package_id: unusedPackage.idd,
                package_code: unusedPackage.pack_code
            };

            // SET PACKAGE AS USED REMOVING IT FROM UNUSED PACKAGES
            if (unusedPackage) unusedPackages.splice(unusedPackageIdx, 1);
            return newSubline;
        });

        // UPDATE REDUX SUBLINES 
        dispatch({ type: SET_SUB_LINES, payload: newSublines });
        dispatch(setReturnFlowProp('oldSubLines', newSublines));
    }, [selectedSublines, sublines, packages, dispatch]);

    const handleAssignQty = useCallback(({ itemQty, packageQty }) => {
        setError(false);
        const unusedPackages = getUnusedPackagesByRMA(
            sublines.find(sb => sb.idd === selectedSublines[0]).rma_num
        );

        let assignedSublinesCount = 0;
        const newSublines = sublines.map((subline) => {
            if (selectedSublines.find((sublineId, i) => sublineId === subline.idd && i < itemQty)) {
                const calculatedIdx = Math.floor(assignedSublinesCount * (packageQty / itemQty));
                const unusedPackage = unusedPackages[calculatedIdx];
                const newSubline = !unusedPackage ? subline : {
                    ...subline,
                    package_id: unusedPackage.idd,
                    package_code: unusedPackage.pack_code
                };
                assignedSublinesCount++
                return newSubline;
            }
            else return subline;
        });

        // UPDATE REDUX SUBLINES 
        dispatch({ type: SET_SUB_LINES, payload: newSublines });
        setShowQtyModal(false);
    }, [sublines, selectedSublines, dispatch]);

    const getUnusedPackagesByRMA = useCallback((rma) => {
        // IF "rma" ARGUMENT ITS EMPTY IT TAKES THE "rma_num" OF THE FIRST SUBLINE, 
        // IF THIS FN IS TRIGGERED WITHOUT "rma", ITS BECAUSE ALL SELECTED SUBLINES HAVE THE SAME "rma_num"
        const rmaNum = rma || sublines.find(sb => sb.idd === selectedSublines[0]).rma_num;

        // REMOVE PACKAGE_ID OF SELECTED SUBLINES TO BE ABLE TO HANDLE THEM
        const tempSublines = sublines.map(subline => {
            const isSelected = selectedSublines.find(sublineId => sublineId === subline.idd);
            return !isSelected ? subline : { ...subline, package_id: null };
        });

        // GET PACKAGES WHICH ARE NOT USED AS "package_id" IN ANY SUBLINE AND ITS "rma_num" is equal to "rmaNum" 
        let unusedPackages = packages.filter(pk => rmaNum === pk.rma_num && !tempSublines.find(sb => sb.package_id === pk.idd));
        return unusedPackages;
    }, [sublines, selectedSublines, packages]);

    const canAssignQty = useMemo(() => {
        if (!sublines.length || !selectedSublines.length) return false;
        // CHECK IF ALL SUBLINES HAS THE SAME rma_num
        const firstRMA = sublines.find(x => x.idd === selectedSublines[0]).rma_num;
        return !!selectedSublines.find(ss => sublines.find(x => x.idd === ss && x.rma_num !== firstRMA));
    }, [selectedSublines, sublines]);

    const handleOutUnitPackaging = () => {
        dispatch(setCreatePackagesStep());
        dispatch(setReturnFlowProp('subLines', sublines.map(x => ({ ...x, package_id: null }))));
        setShowModal(false);
    };

    const handleBack = () => {
        setError(false);
        back();
    };

    return (
        <Fragment>
            <div className="createPackage">
                <div className="infoContentBox">
                    <h3>{translate('title.rma')}: {rmaNums.join(', ')}</h3>
                </div>
                <div className="infoContainer">
                    <div className="infoHead">
                        <h3>{translate('subtitle.selectPackaging')}</h3>
                    </div>
                    <div className="infoBody">
                        <label>{translate('message.assignPackageFromPrevious')}</label>
                    </div>
                </div>
                <div className={`selectPackaging ${error ? 'errorButtons' : ''}`}>
                    {!step ?
                        <div className="d-flex align-items-center justify-content-between">
                            <POWRButton
                                className="selectPackagingButton"
                                color="secondary"
                                disabled={!packages.length}
                                onClick={() => handleSingleReturn()} >
                                {translate('button.packageSingleReturn')}
                            </POWRButton>
                            <div className="infoCircle">
                                <Info left className="pb-2" >{translate('message.packageSingleReturnDesc')}</Info>
                            </div>
                        </div>
                        :
                        <POWRButton
                            className="selectPackagingButton"
                            color="secondary"
                            disabled={!selectedSublines.length}
                            onClick={() => handleSingleReturn()} >
                            {translate('button.packageSingleReturn2')}
                        </POWRButton>
                    }
                    {!step ?
                        <div className="d-flex align-items-center justify-content-between">
                            <POWRButton
                                className="selectPackagingButton"
                                color="secondary"
                                disabled={!packages.length}
                                onClick={() => handleMultipleReturn()} >
                                {translate('button.packageMultiReturn')}
                            </POWRButton>
                            <div className="infoCircle">
                                <Info left className="pb-2" >{translate('message.packageMultiReturnDesc')}</Info>
                            </div>
                        </div>
                        :
                        <POWRButton
                            className="selectPackagingButton"
                            color="secondary"
                            disabled={!selectedSublines.length}
                            onClick={() => handleMultipleReturn()} >
                            {translate('button.packageMultiReturn2')}
                        </POWRButton>
                    }
                    {!step ?
                        <div className="d-flex align-items-center justify-content-between">
                            <POWRButton
                                className="selectPackagingButton"
                                color="secondary"
                                onClick={() => dispatch(setCreatePackagesStep())} >
                                {translate('button.configureUnits')}
                            </POWRButton>
                            <div className="infoCircle">
                                <Info left className="pb-2" >{translate('message.configureUnitsDesc')}</Info>
                            </div>
                        </div>
                        :
                        <POWRButton
                            className="selectPackagingButton"
                            color="secondary"
                            disabled={!selectedSublines.length || canAssignQty}
                            onClick={() => setShowQtyModal(true)}>
                            {translate('button.packageAssignQty')}
                        </POWRButton>
                    }
                </div>
                <div className="mt-5">
                    <Link className={!step ? 'd-none' : ''} onClick={() => setShowModal(true)}>
                        <POWRIcon className="fal fa-arrow-left mr-2" size="13px" color={COLOR.CISCO_BLUE} />
                        {translate('button.back')}
                    </Link>
                </div>
                <PartList
                    isGroupedList
                    headers={headers}
                    items={createdLocalPackages}
                    title={'subtitle.packagesCreated'}
                    emptyMessageTag={'placeholder.noPackagesSelected'}>
                </PartList>
                {
                    showModal &&
                    <POWRModal
                        show={true}
                        title={translate('message.title.warning')}
                        size={'medium'}
                        onClose={() => setShowModal(false)}>
                        <div className="x-centered mt-3 mb-4">
                            <POWRIcon
                                className="fas fa-trash-alt"
                                size="3.5em"
                                color={COLOR.CISCO_BLUE} />
                        </div>
                        <span className="d-block my-2 text-center">
                            {translate(`message.confirmOutUnitPackaging`)}
                        </span>
                        <span className="d-block my-2 text-center">{translate('message.wishContinue')}</span>
                        <div className="toolbar">
                            <POWRButton color={'secondary'} onClick={() => setShowModal(false)}>{translate('button.cancel')}</POWRButton>
                            <POWRButton onClick={() => handleOutUnitPackaging()}>{translate('button.ok')}</POWRButton>
                        </div>
                    </POWRModal>
                }
                {
                    showQtyModal &&
                    <POWRModal
                        show={true}
                        title={translate('message.title.assignPackageQty')}
                        size={'medium'}
                        onClose={() => setShowQtyModal(false)}>
                        <PackageQtyForm
                            onSave={(values, e) => handleAssignQty(values)}
                            onCancel={() => setShowQtyModal(false)}
                            maxItems={selectedSublines.length} />
                    </POWRModal>
                }
            </div>
            <ActionButtons
                secondary={() => handleBack()}
                sText={translate('button.back')}
                primary={() => handleSubmit(next)}
                pText={translate('button.next')}
                isLoading={isLoading}
                completed={!isLoading} />
        </Fragment>
    );
};

export default CreatePackages;