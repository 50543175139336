import React from 'react';
import { Spinner } from '../controls';

export default props => {
    return props.loading ?
        <button
            className={`
                ${props.className ? props.className : ''}
                ${props.disabled ? 'buttonControlWrapper buttonControlDisabled' : 'buttonControlWrapper'} 
                ${props.type !== '2' ? 'primaryButton' : 'secondaryButton'} 
            `}
            onClick={props.onClick}
            style={props.style}
            type={props.type === "submit" ? "submit" : undefined}
            disabled={props.disabled ? true : undefined}
            title={props.title}
        >
            <Spinner
                className="mr-2"
                size="22px"
                color={props.type !== '2' ? '#FFFFFF' : '#099AD6'} />
            {props.children}
        </button>
        :
        <button
            className={`
                ${props.className ? props.className : ''}
                ${props.disabled ? 'buttonControlWrapper buttonControlDisabled' : 'buttonControlWrapper'} 
                ${props.type !== '2' ? 'primaryButton' : 'secondaryButton'} 
            `}
            onClick={props.onClick}
            style={props.style}
            type={props.type === "submit" ? "submit" : undefined}
            disabled={props.disabled ? true : undefined}
            title={props.title}
        >
            {props.children}
        </button>
}