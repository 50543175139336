import React, { useState, useEffect } from 'react';
import { useTranslate } from '../../../core/contexts';
import { Pagination, Link } from '../../controls';
import { SHIPMENT_PAGE_ITEMS } from "../../../core/constants/Common";
import RLSPDocumentTable from './rlspDocumentTable';
import { COLOR } from '../../../core/constants/Colors';
import { POWRIcon } from '../../styledComponents';
import { useDispatch } from 'react-redux';
import { setShipmentSeleted } from '../../../core/redux/actions';
import { useConfigParams } from '../../../core/hooks';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);

    useEffect(() => {
        setAllItems(props.items);
    }, [props.items]);

    useEffect(() => {
        handlePages(allItems);
        dispatch(setShipmentSeleted({}));
    }, [allItems, page]);

    const handlePages = (aItems) => {
        let newItems = page === 1 ?
            aItems.slice(0, SHIPMENT_PAGE_ITEMS) :
            aItems.slice((page * SHIPMENT_PAGE_ITEMS) - SHIPMENT_PAGE_ITEMS, page * SHIPMENT_PAGE_ITEMS);
        setItems(newItems);
        let nPages = Math.ceil(aItems.length / SHIPMENT_PAGE_ITEMS);
        let ps = [];
        for (var i = 1; i <= nPages; i++) {
            ps.push(i);
        }
        setPages(ps);
    }

    return allItems && allItems.length > 0 ?
        (
            <div className={`shipmentsListCard no-border`}>
                <RLSPDocumentTable {...props} items={items} allItems={allItems} setAllItems={setAllItems} handlePages={handlePages} />
                    <Pagination
                        itemsPerPage={SHIPMENT_PAGE_ITEMS}
                        items={items}
                        setItems={setItems}
                        pages={pages}
                        page={page}
                        setPage={setPage}
                        allItems={allItems}
                    />
            </div>
        ) : (
            <div className={props.full ? "shipmentsNoData" : "shipmentsNoData shipmentsNoDataSmall"}>
                <div className="iconContainer" key={`icon-${items.length}`}>
                    <POWRIcon className="fal fa-search" size="56px" color={COLOR.GREY} />
                </div>
                <div className="textNoData">
                    <h2>{translate('aux.noResults')}</h2>
                    <p>{translate('aux.refineRLSP')} </p>
                </div>
            </div>
        );
}
