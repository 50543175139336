import Request from "../core/api/request";
import { URL_GET_WAYBILL, URL_CWB_SCHEDULE, URL_CARRIER_PROFILE_CWB, URL_GETPICKUPLOCATIONTIMEZONE, URL_SAVE_CWB } from "../core/constants/URLS";

export const getWaybillShipments = (data) => {
    return Request.postEncode({ url: URL_GET_WAYBILL, data });
};

export const getScheduleSWB = (shipId) => {
    return Request.getURIEncoded({ url: `${URL_CWB_SCHEDULE}?_dc=${Date.now()}&ship_id=${shipId}` });
}

export const getCarrierProfileSWB = (carrierId) => {
    return Request.getURIEncoded({ url: `${URL_CARRIER_PROFILE_CWB}?carrier_profile_id=${carrierId}` });
}

export const getZonesSWB = (data) => {
    return Request.postEncode({ url: URL_GETPICKUPLOCATIONTIMEZONE, data:data });
};

export const saveCWB = (data) => {
    return Request.postURIENCODEDJSONSave({ url: URL_SAVE_CWB, data });
};
