import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../core/contexts';
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { COLOR, REPORT_NAVIGATION } from '../core/constants';
import { POWRIcon } from '../components/styledComponents';
import { useConfigParams } from '../core/hooks';

export default props => {
    const history = useHistory();
    const { translate } = useTranslate();
    const { commercialDocsEnabled } = useConfigParams();
    const [navMenu, setNavMenu] = useState();
    
    useEffect(() => {
        let newNavMenu = REPORT_NAVIGATION.map(x => (commercialDocsEnabled(x.toggle) ? { ...x, enabled: true } : x));
        setNavMenu(newNavMenu);
     }, []);


    return (
        <FullPage>
            <div className='reportMenuPageContainer'>
                <h1>{translate('title.documentReports')}</h1>
                <div className='fluid-container'>
                    <div className='card-container row'>
                        {
                            navMenu && navMenu.length > 0 && navMenu.map(element => {
                              
                                return (
                                    element.enabled &&   
                                    <div className='col-xs-12 col-sm-6 col-md-4 pb-3 overflow-hidden' key={element.title}>
                                        <div className='report-card text-center' onClick={() => history.push(element.route)}>
                                            <POWRIcon className={`fal ${element.icon}`}
                                                color={COLOR.GREY}
                                                size="2em" />
                                            <h4 className='my-2'>{translate(element.title)}</h4>
                                            <p>{translate(element.subtitle)}</p>

                                        </div>
                                    </div>
                                );
                               

                            })
                        }
                    </div>
                </div>
                
            </div>
        </FullPage>
    );
};
