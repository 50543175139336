import axios from 'axios';
import { BASE_URL_LOGOUT_CISCO, BASE_URL_API } from '../constants/URLS';
import { withRouter } from "react-router-dom";
import { REGEXP } from '../constants';
const qs = require('querystring');

const headers = {
  'Content-Type': 'application/json',
  'withCredentials': 'true'
};

const headersPlain = {
  'Content-Type': 'text/plain',
  'withCredentials': 'true'
};


const headersEncoded = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  withCredentials: 'true'
};

axios.defaults.timeout = 5 * 60 * 1000;
const messageError401 = 'You are not authorized to this action or your session has expired';
const messageErrorTimeout = 'Error: -1, transaction aborted';

axios.interceptors.response.use((response) => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    const currentLang = localStorage.getItem('powr3_lang_code');
    localStorage.clear();
    sessionStorage.clear();
    if (currentLang) localStorage.setItem('powr3_lang_code', currentLang);
    window.location = BASE_URL_API + '/rmalogin.do';
    return error;
  }
  return error.response;
});

const encodeDecodeJSON = (json, isDecode = false) => {
  if (Array.isArray(json)) {
    let editableJson = [ ...json ];
    for (let i = 0; i < json.length; i++) {
      editableJson[i] = encodeDecodeJSON(json[i], isDecode);
    }
    return editableJson;
  } else { // ONLY COULD BE ARRAY OR OBJECT
    let editableJson = { ...json };
    for (let propName in editableJson) {
      if (typeof (editableJson[propName]) !== 'object') {
        if (propName === 'idd') editableJson[propName] = Number(editableJson[propName])
        else {
          const value = isDecode ? decodeURIComponent(editableJson[propName]) : encodeURIComponent(editableJson[propName]);
          editableJson[propName] = value;
        }
      }
      else editableJson[propName] = encodeDecodeJSON(editableJson[propName], isDecode);
    }
    return editableJson;
  }
};

const fixJsonString = (value) => {
  const formattedValue = value
    .replace(/\:\/\//g, '##')
    .replace(/from:/g, 'spifrom')
    .replace(/	/g, '')
    .replace(REGEXP.MSG, "\"$1\":\"$2\"")
    .replace(/##/g, '://')
    .replace(/spifrom/g, 'from:')
    // .replace(/'/g, '"')
    .replace(/:'/g, ':"')
    .replace(/',/g, '",')
    .replace(/""/g, '"')
    .replace(/: ",/g, ': "",')
    .replace(/:",/g, ': "",')
    .replace(/: "}/g, ': ""}')
    .replace(/:"}/g, ': ""}')

  return formattedValue;
};

const Request = {
  async get(p) {
    try {
      let params = p.parameters ? p.parameters : null;
      const req = await axios.get(`${BASE_URL_API}/${p.url}`, { params, headers });
      var response = { status: req.status, res: req.data };
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
          alert(messageError401);
        } else if (error.response.status === 500 && error.response.data.includes('html')) {
          return error.response.data
        }
      }
      else if (error.message.includes('timeout')) {
        error.message = messageErrorTimeout
        throw error;
      } else {
        console.log('error', error)
      }
    }
  },
  async getURIEncoded(params) {
    try {
      const req = await axios.get(`${BASE_URL_API}/${params.url}`, headersEncoded);
      var response = { status: req.status, res: req.data };

      if (typeof (req.data) === 'string') {
        response.res = encodeDecodeJSON(JSON.parse(fixJsonString(req.data)), true);
      }
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
        } else if (error.response.status === 500 && error.response.data.includes('html')) {
          return error.response.data
        }
      }
      else if (error.message.includes('timeout')) {
        error.message = messageErrorTimeout
        throw error;
      } else {
        console.log('error', error)
      }
    }
  },
  async post(params) {
    try {
      const req = await axios.post(`${BASE_URL_API}/${params.url}`, params.data, headersPlain);
      var response = { status: req.status, res: req.data };
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
          alert(BASE_URL_LOGOUT_CISCO);
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        }
      } else {
        throw error;
      }
    }
  },
  async postEncode(params) {
    var response = { status: 400, res: null };
    try {
      const req = await axios.post(`${BASE_URL_API}/${params.url}`, qs.stringify(params.data), headersEncoded);
      response.status = req.status;
      if (req.data) {
        if (typeof (req.data) === 'string')
          response.res = JSON.parse(decodeURIComponent(fixJsonString(req.data)));
        else response.res = req.data;
      }
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
          alert(BASE_URL_LOGOUT_CISCO);
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        } else {
          throw error;
        }
      }
    }
    return response;
  },
  async put(params) {
    try {
      const req = await axios.put(`${BASE_URL_API}/${params.url}`, params.data, headers);
      const response = { status: req.status, res: req.data };
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
          alert(messageError401);
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        }
      } else {
        throw error;
      }
    }
  },
  async delete(params) {
    try {
      const req = await axios.delete(`${BASE_URL_API}/${params.url}`, params.data, headers);
      const response = { status: req.status, res: req.data };
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
          alert(messageError401);
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        }
      } else {
        throw error;
      }
    }
  },
  async postURIENCODED(params) {
    try {
      const req = await axios({
        url: `${BASE_URL_API}/${params.url}`,
        method: 'post',
        data: 'json=' + encodeURIComponent(params.data)
        ,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
          , 'withCredentials': 'true'
        }
      })

      var response = { status: req.status, res: req.data };
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302 || error.response.status === 500) {
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        }
      } else {
        throw error;
      }
    }
  },
  async postURIENCODEDJSONSave(params) {
    try {
      let uriParams = "";
      Object.keys(params.data).forEach(function (name) {
        if (name !== 'json') {
          uriParams += '&' + name + '=' + params.data[name];
        }
      });

      if (params.data.json) params.data.json = encodeDecodeJSON(params.data.json);

      if (params && params.data && params.data.json.dtos && params.data.json.dtos[0].quantity) {
        params.data.json.dtos[0].quantity = Number(params.data.json.dtos[0].quantity);
      }
      let uriencoded = params && params.data && `json=${JSON.stringify(params.data.json)}${uriParams}`;
      
      let req = await axios.post(`${BASE_URL_API}/${params.url}?`, uriencoded, headersEncoded);

      var response = { status: req.status, res: null };
      try {
        let x = (typeof (req.data) === 'string' && params.url !== 'get/ConfigParam') ? JSON.parse(req.data) : req.data;
        response.res = req.data;
      } catch {
        let x = JSON.parse(String(req.data).replaceAll("'", '"').replaceAll('"', '').replaceAll('{', '{"').replaceAll(':', '":"').replaceAll(', N', 'ñ').replaceAll(',', '","').replaceAll('}', '"}').replaceAll('}","{', '},{').replaceAll('"[', '[').replaceAll(']"}', ']}').replaceAll('" dtos"', '"dtos"').replaceAll('dtos":"', 'dtos":'));
        response.res = x;
      }
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302 || error.response.status === 500) {
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        }
      } else {
        throw error;
      }
    }
  },
  async postURIDECODEJSONSave(params) {
    try {
      let uriParams = "";
      Object.keys(params.data).forEach(function (name) {
        if (name !== 'json') {
          uriParams += '&' + name + '=' + params.data[name];
        }
      });
      let uriencoded = params && params.data && `json=${JSON.stringify(params.data.json)}${uriParams}`;
      let req = await axios.post(`${BASE_URL_API}/${params.url}?`, uriencoded, headersEncoded);

      var response = { status: req.status, res: null };
      
      try {
        let x = (typeof (req.data) === 'string' && params.url !== 'get/ConfigParam') ? JSON.parse(req.data) : req.data;
        response.res = req.data;
        
      } catch {
        let x = JSON.parse(String(req.data).replaceAll("'", '"').replaceAll('"', '').replaceAll('{', '{"').replaceAll(':', '":"').replaceAll(', N', 'ñ').replaceAll(',', '","').replaceAll('}', '"}').replaceAll('}","{', '},{').replaceAll('"[', '[').replaceAll(']"}', ']}').replaceAll('" dtos"', '"dtos"').replaceAll('dtos":"', 'dtos":'));
        response.res = x;
      }
      return response;
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 302 || error.response.status === 500) {
          //Auth.logout();
          window.location.href = BASE_URL_LOGOUT_CISCO;
        }
      } else {
        throw error;
      }
    }
  },
}

export default withRouter(Request);
