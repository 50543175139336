export const EVENT_FREQUENCY_TYPE = {
    EVENT_FREQUENCY_UNKNOWN: 0,
    EVENT_FREQUENCY_IMMEDIATE: 1,
    EVENT_FREQUENCY_HOURLY: 2,
    EVENT_FREQUENCY_DAILY: 3
};

export const EVENT_FREQUENCY_TYPES = [
    {
        type: 0,
        key: 'unknown'
    },
    {
        type: 1,
        key: 'intermediate'
    },
    {
        type: 2,
        key: 'hourly'
    },
    {
        type: 3,
        key: 'daily'
    }
];