import request from "../core/api/request";

export const contactService = {
    getUserContacts: () => {
        return request.getURIEncoded({ url: `get/ContactUserProfile?_dc=${Date.now()}` });
    },
    saveContactToAddressBook: (data) => {
        return request.postURIENCODEDJSONSave({ url: `save/user_contact_info`, data });
    },
    deleteContact: (id) => {
        return request.postEncode({ url: `del/user_contact_info?idds=${id}`});
    }
};