import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { POWRButton, POWRIcon, POWRModal, Tabs } from '../components/styledComponents';
import { useTranslate } from '../core/contexts';
import AddressBookContent from '../components/addressBook/addressBookContent';
import { PickupLocationForm } from '../components/forms/pickupLocationForm';
import { PickupRequestorForm } from '../components/forms/pickupRequestorForm';
import { Link } from '../components/controls';
import { useDispatch } from 'react-redux';
import { removeContact, removeEvent, removePickupAddress } from '../core/redux/actions';
import { SubscriptionForm } from '../components/forms/subscriptionForm';
import { useAlert } from '../core/hooks';
import { COLOR } from '../core/constants/Colors';

const AddressBook = () => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { removeAlert } = useAlert();
    const [currentTab, setCurrentTab] = useState('Address');
    const [modalTemplate, setModalTemplate] = useState(null);

    const tabs = useMemo(() => [
        { name: translate('subtitle.myAddresses'), value: 'Address' },
        { name: translate('subtitle.myContacts'), value: 'Contact' },
        { name: translate('subtitle.event'), value: 'Subscription' }
    ], [translate]);

    useEffect(() => {
        if (modalTemplate === null) removeAlert("mandatoryFields")
    }, [modalTemplate]);

    const handleDelete = useCallback(() => {
        const { data, type } = modalTemplate;
        switch (type) {
            case 'PickupLocation':
                dispatch(removePickupAddress(data.idd)); break;
            case 'Contact':
                dispatch(removeContact(data.idd)); break;
            case 'Subscription':
                dispatch(removeEvent(data.idd)); break;
            default: break;
        }
        setModalTemplate(null);
    }, [setModalTemplate, dispatch, modalTemplate]);

    return (
        <div className="addressBookPageContainer">
            <div className="infoContainer">
                <div className="mb-3">
                    <h1 className="m-0">{`${translate('title.addressBookPageTitle')}`}</h1>
                    <label>{translate('subtitle.addressBook')}</label>
                </div>
                <div className="row d-none d-xl-flex">
                    <div className="col-12 col-lg-9">
                        <Tabs selectedTab={currentTab} options={tabs} onSelectTab={(tab) => setCurrentTab(tab)} />
                    </div>
                    <div className="col-12 col-lg-3 pt-3 pt-lg-0 text-right action">
                        <Link onClick={() => setModalTemplate({
                            type: currentTab === 'Address' ? 'PickupLocation' : currentTab,
                            action: 'add'
                        })}>
                            <span className="pr-1">
                                <POWRIcon className="fas fa-plus select" color={COLOR.CISCO_BLUE} />
                            </span>
                            {translate(`button.add${currentTab}`)}
                        </Link>
                    </div>
                </div>
                <div className="col-12">
                    <AddressBookContent currentTab={currentTab} setModalTemplate={(val) => setModalTemplate(val)} />
                </div>
            </div>
            {(modalTemplate != null && modalTemplate.action !== 'delete') &&
                <POWRModal
                    show={true}
                    title={translate(`subtitle.${modalTemplate.action}${modalTemplate.type}`)}
                    onClose={() => setModalTemplate(null)}>
                    {
                        (modalTemplate.type === 'PickupLocation' ?
                            <PickupLocationForm
                                isAdd={modalTemplate.action === 'add'}
                                saveAtAddressBook={true}
                                data={modalTemplate.data}
                                onDelete={() => setModalTemplate({ ...modalTemplate, action: 'delete' })}
                                onClose={() => setModalTemplate(null)} />
                            :
                            (modalTemplate.type === 'Contact' ?
                                <PickupRequestorForm
                                    isAdd={modalTemplate.action === 'add'}
                                    template={modalTemplate.type}
                                    saveAtAddressBook={true}
                                    data={modalTemplate.data}
                                    onDelete={() => setModalTemplate({ ...modalTemplate, action: 'delete' })}
                                    onClose={() => setModalTemplate(null)} />
                                :
                                <SubscriptionForm
                                    isAdd={modalTemplate.action === 'add'}
                                    data={modalTemplate.data}
                                    onDelete={() => setModalTemplate({ ...modalTemplate, action: 'delete' })}
                                    onClose={() => setModalTemplate(null)} />
                            )
                        )
                    }
                </POWRModal>
            }
            {(modalTemplate != null && modalTemplate.action === 'delete') &&
                <POWRModal
                    show={true}
                    title={translate('message.title.confirmDelete')}
                    size={'small'}
                    onClose={() => setModalTemplate(null)}>
                    <div className="content">
                        <div className="x-centered mt-3 mb-4">
                            <POWRIcon
                                className="fas fa-trash-alt"
                                size="3.5em"
                                color={COLOR.CISCO_BLUE} />
                        </div>
                        <span className="d-block my-2 text-center">
                            {translate(`message.confirmDelete${currentTab}`)}
                        </span>
                        <span className="d-block my-2 text-center">{translate('message.sure')}</span>
                    </div>
                    <div className="toolbar">
                        <POWRButton color={'secondary'} onClick={() => setModalTemplate(null)}>{translate('button.cancel')}</POWRButton>
                        <POWRButton onClick={handleDelete}>{translate('button.ok')}</POWRButton>
                    </div>
                </POWRModal>
            }
        </div>
    )
}

export default AddressBook;