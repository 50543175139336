import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslate } from '../core/contexts';
import { Pagination, Checkbox, Status, Link } from './controls';
import { RMAStatus, RMAPaymentType } from '../core/constants/RMAStatus';
import { SHIPMENT_PAGE_ITEMS } from "../core/constants/Common";
import { useRMAValidation, useConfigParams } from "../core/hooks";
import { getRMA } from "../services";
import { POWRIcon } from './styledComponents';
import { COLOR } from '../core/constants/Colors';
import { ROLES } from '../core/constants/ROLES';

export default ({ isFA, ...props }) => {
    const { translate } = useTranslate();
    const [checkAll, setCheckAll] = useState(false);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [items, setItems] = useState([]);
    const [order, setOrder] = useState(-1);
    const [checkDisabled, setCheckDisabled] = useState(false);
    const [reverse, setReverse] = useState(false);
    const { isValidRMAList } = useRMAValidation();
    const { getFaqLink, getContactUsLink } = useConfigParams();
    const dateFormat = sessionStorage.getItem('dateFormat');
    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();

    useEffect(() => {
        setCheckDisabled(props.userInfo && props.userInfo.role === ROLES.READ_ONLY);
    }, [props.userInfo]);

    useEffect(() => {
        setAllItems(props.items);
    }, [props.items]);

    useEffect(() => {
        handlePages(allItems);
    }, [allItems, page]);

    const handlePages = (aItems) => {
        let newItems = page === 1 ?
            aItems.slice(0, SHIPMENT_PAGE_ITEMS) :
            aItems.slice((page * SHIPMENT_PAGE_ITEMS) - SHIPMENT_PAGE_ITEMS, page * SHIPMENT_PAGE_ITEMS);
        setItems(newItems);
        let nPages = Math.ceil(aItems.length / SHIPMENT_PAGE_ITEMS);
        let ps = [];
        for (var i = 1; i <= nPages; i++) {
            ps.push(i);
        }
        setPages(ps);
    }

    const handleOrder = (o, value) => {
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(true);
        }
        setOrder(o);

        let r = order === o ? !reverse : true;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        let newOrder = allItems.sort(compare);
        setAllItems(newOrder);
        handlePages(newOrder);
    }

    const handleAddChecked = (val, check) => {
        const newValues = check ? [...props.checks, val] : props.checks.filter((x) => x !== val);
        let values = props.singleSelect ? newValues[newValues.length - 1] ? [newValues[newValues.length - 1]] : newValues : newValues;

        if (check) {
            const parameters = {
                param_rma_or_tradein: newValues.join(','),
                param_fa_case_num: "",
                isSimpleSearch: true,
                isReturnFa: isFA
            };
            getRMA(parameters).then(response => {

                if (isValidRMAList(response, val, true, isFA).isValid) {
                    setCheckAll(values.length === items.length);
                    props.setChecks(values);
                }
            }).catch((e) => {
            });
        }
        else {
            setCheckAll(values.length === items.length);
            props.setChecks(values);
        }
    }

    const getAllRMA = () => {
        let rmas = [];
        items.forEach(el => {
            if (el.rma_num[0] && (el.return_state[0] && el.return_state[0] === 1)) {
                rmas.push(el.rma_num[0])
            }
        })

        return rmas;
    }

    const handleCheckedAll = (v) => {
        const newValues = v ? getAllRMA() : [];
        let values = props.singleSelect ? newValues[0] ? [newValues[0]] : newValues : newValues;
        let current = props.checks.length ? props.checks[0] : values && values[0];
        if (values.length <= 0) {
            setCheckAll(v);
            props.setChecks(values);
        }
        else {
            const parameters = {
                param_rma_or_tradein: values.join(','),
                param_fa_case_num: "",
                isSimpleSearch: true,
                isReturnFa: isFA
            };
            getRMA(parameters).then(response => {
                let isValidRMA = isValidRMAList(response, current, false, isFA);
                if (isValidRMA.isValid) {
                    setCheckAll(v);
                    props.setChecks(values);
                }
                else {
                    setCheckAll(v);
                    props.setChecks(isValidRMA.rmas);
                }
            }).catch((e) => {
                console.log(e)
            });
        }
    }

    const columns = useMemo(() => [
        { id: 0, value: 'rma_num', type: 'num', text: translate('gridColumns.rmas') },
        { id: 1, value: !isFA ? 'trade_in_quote_num' : 'fa_case_num', type: 'tex', text: translate(`gridColumns.${!isFA ? 'tradeInQuote' : 'fa'}`) },
        { id: 1, value: 'service_request_num', type: 'tex', text: translate('gridColumns.srN') },
        { id: 2, value: 'rma_type_code', type: 'tex', text: translate('gridColumns.type') },
        { id: 3, value: 'customer_po', type: 'tex', text: translate('gridColumns.customerPO') },
        { id: 4, value: 'cust_name', type: 'tex', text: translate('gridColumns.shipToCustomer') },
        { id: 5, value: 'date_created', type: 'dat', text: translate('gridColumns.created') },
        { id: 6, value: 'rma_due_date', type: 'dat', text: translate('gridColumns.returnBy') },
        { id: 7, value: 'country', type: 'tex', text: translate('gridColumns.shipTo') },
        { id: 8, value: 'return_state', type: 'tex', text: translate('gridColumns.status') },
        { id: 9, value: 'payment_type', type: 'tex', text: translate('gridColumns.paidBy') }
    ], [translate, isFA]);

    const formatDate = (date) => {
        return date && moment(`${date}`, 'YYYYMMDD').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDD').format(dateFormat) : '—';
    }

    return items && items.length > 0 ?
        (
            <div className="shipmentsListCard shipmentsListCardFull">
                <table id={props.tableId} className="d-none d-lg-table">
                    <thead>
                        <tr className="shipmentsTitles">
                           
                            <th className="min-width">
                                {checkDisabled ?
                                    <div />
                                   :
                                <Checkbox
                                    id={`shipmentCheck-head`}
                                    value={checkAll}
                                    checked={checkAll}
                                    onClick={handleCheckedAll.bind(this, !checkAll)}
                                    
                                />
                                }
                            </th>
                            {columns.map((c, i) => (
                                !isFA || (isFA && c.value !== 'customer_po') ? <th
                                    className="shipmentsTH"
                                    onClick={handleOrder.bind(this, i, c.value)}
                                    key={`column-${i}-${reverse}-of-${order}`}
                                >
                                    <div className="shipmentsTitle">
                                        {c.text}
                                        <POWRIcon
                                            className={`far fa-${order === i && reverse ? "arrow-up" : "arrow-down"} ml-2`}
                                            size="10px"
                                            color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                    </div>
                                </th> : null
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map((item, i) => {
                                let returnedC = item.rma_due_date ? moment(`${item.rma_due_date[0]}`, 'YYYYMMDD') > moment() || false : false;
                                let checked = props.checks.filter((x) => x === item.rma_num[0]).length > 0
                                return (
                                    <tr
                                        className="shipmentRow"
                                        key={`shipment-${i}`}
                                        onClick={props.onClick ? props.onClick.bind(this, item.idd) : undefined}
                                    >
                                        <td className="p-0">
                                            {(item.return_state[0] !== 1 || checkDisabled) ?
                                                <div />
                                                :
                                                <Checkbox
                                                    id={`shipmentCheck-${i}`}
                                                    value={checked}
                                                    checked={checked}
                                                    onClick={
                                                        item.rma_num[0] ? handleAddChecked.bind(this, item.rma_num[0], !checked, item.rma_num) : undefined
                                                    }
                                                />
                                            }
                                        </td>
                                        <td><div className="td boldType">{item.rma_num[0] ? item.rma_num[0] : '—'}</div></td>
                                        <td><div className="td">
                                            {
                                                !isFA ?
                                                    (item.trade_in_quote_num[0] ? item.trade_in_quote_num[0] : '—')
                                                    :
                                                    (item.fa_case_num || '—')
                                            }
                                        </div></td>
                                        <td><div className="td" title={item.service_request_num && item.service_request_num[0] ? item.service_request_num[0] : '—'}>
                                            {item.service_request_num && item.service_request_num[0] ? item.service_request_num[0] : '—'}
                                        </div></td>
                                        <td><div className="td" title={item.rma_type_code[0] ? item.rma_type_code[0] : '—'}>
                                            {item.rma_type_code[0] ? item.rma_type_code[0] : '—'}
                                        </div></td>
                                        {  !isFA &&
                                            <td><div className="td">
                                                {item.customer_po[0] ? item.customer_po[0] : '—'}
                                            </div></td>
                                        }
                                        <td><div className="td" title={item.cust_name}>
                                            {item.cust_name}
                                        </div></td>
                                        <td><div className="td" title={formatDate(item.date_created[0])}>
                                            {formatDate(item.date_created[0])}
                                        </div></td>
                                        <td><div className={returnedC ? 'td colorRed' : 'td'} title={formatDate(item.rma_due_date)}>
                                            {formatDate(item.rma_due_date)}
                                        </div></td>
                                        <td><div className="td">{item.country[0] ? item.country[0] : '—'}</div></td>
                                        <td key={`status-${item.return_state[0] - 1}_order-${order}_reverse-${reverse}`}>
                                            <Status className="td" status={RMAStatus[item.return_state[0] - 1]} />
                                        </td>
                                        <td><div className="td">
                                            {RMAPaymentType[item.payment_type[0] - 1] ?
                                                translate(RMAPaymentType[item.payment_type[0] - 1].text)
                                                : '—'}
                                        </div></td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <div className="d-block d-lg-none">
                    {items.map((item, i) => {
                        let checked = props.checks.filter((x) => x === item.rma_num[0]).length > 0;

                        return (
                            <div className="mini-shipment" key={i}>
                                <div className="col-2 pl-0">
                                    <POWRIcon className="fas fa-circle" size="10px" color={RMAStatus[item.return_state[0] - 1].color} />
                                </div>
                                <div className="col-10 pl-0">
                                    <div className="row columns">
                                        <div className="col main">{item.rma_num[0] ? item.rma_num[0] : '—'}</div>
                                        <div className="col-auto p-0">
                                            {(item.return_state[0] !== 1 || checkDisabled) ?
                                                <div />
                                                :
                                                <Checkbox
                                                    id={`shipmentCheck-${i}`}
                                                    value={checked}
                                                    checked={checked}
                                                    onClick={
                                                        item.rma_num[0] ? handleAddChecked.bind(this, item.rma_num[0], !checked, item.rma_num) : undefined
                                                    }
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.created')}: `}</span>
                                            <span className="normal">{formatDate(item.date_created[0])}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.returnBy')}: `}</span>
                                            <span className="normal">{formatDate(item.rma_due_date)}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.shipToCustomer')}: `}</span>
                                            <span className="normal">{item.cust_name}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.shipTo')}: `}</span>
                                            <span className="normal">{item.country[0] ? item.country[0] : '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.type')}: `}</span>
                                            <span className="normal">{item.rma_type_code[0] ? item.rma_type_code[0] : '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.srN')}: `}</span>
                                            <span className="normal">{item.service_request_num && item.service_request_num[0] ? item.service_request_num[0] : '—'}</span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.paidBy')}: `}</span>
                                            <span className="normal">
                                                {RMAPaymentType[item.payment_type[0] - 1] ? translate(RMAPaymentType[item.payment_type[0] - 1].text) : '—'}
                                            </span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate(`gridColumns.${!isFA ? 'tradeInQuote' : 'fa'}`)}: `}</span>
                                            <span className="normal">
                                                {!isFA ? (item.trade_in_quote_num[0] ? item.trade_in_quote_num[0] : '—') : (item.fa_case_num || '—')}
                                            </span>
                                        </div>
                                        <div className="col-12">
                                            <span className="light">{`${translate('gridColumns.customerPO')}: `}</span>
                                            <span className="normal">{item.customer_po[0] ? item.customer_po[0] : '—'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Pagination
                    itemsPerPage={SHIPMENT_PAGE_ITEMS}
                    items={items}
                    setItems={setItems}
                    pages={pages}
                    page={page}
                    setPage={setPage}
                    allItems={allItems}
                />
            </div>
        ) : (
            <div className="shipmentsNoData shipmentsNoDataSmall">
                <div className="iconContainer" key={`icon-${items.length}`}>
                    <POWRIcon className="fal fa-search" color={COLOR.GREY} size="56px" />
                </div>
                <div className="textNoData">
                    <h2>{translate('aux.noResults')}</h2>
                    <p>{translate('aux.refine')} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('aux.refine2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link> {translate('message.countryRMAError2')}</p>
                </div>
            </div>
        );
}
