import {
    SET_RTF_DEFAULT_OPTIONS, SET_RTF_FLOW_PROP, ADD_PRODUCTS, UPDATE_PRODUCT, SET_RETURN_REQUEST_DATA, REMOVE_UNIT, REMOVE_PRODUCTS, UPDATE_UNIT, SET_RETURN_REQUEST_DATA_COMPLETE, UPDATE_PRODUCTS_QTY,
    ADD_MULTIPLE_PRODUCTS, REMOVE_MULTIPLE_PRODUCTS, UPDATE_EOR, UPDATE_IOR

} from "../actionTypes";

const initialState = {
    // STEPS
    products: [],
    oldProducts: [],
    rma: '',
    refNumber: '',
    returnByDate:'',
    country: null,
    entityType: "",
    eor: {},
    ior: {},
    data: {
        companyName: '',
        returnReason: '',
        originalSalesOrder: '',
        customerPO: '',
        requestName: '',
        terms: ''
    }
};

export const rtfFlow = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_RTF_FLOW_PROP:
            return {
                ...state,
                [payload.propName]: payload.value
            };
        case SET_RTF_DEFAULT_OPTIONS:
            return {
                ...initialState
            };
        case ADD_PRODUCTS:
            return {
                ...state,
                oldProducts: [...state.oldProducts.filter(x => x.id !== payload.id), payload],
                products: [...state.products.filter(x => x.id !== payload.id), payload],
            }
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: state.products.map((x) => (x.id === payload.id) ? { ...x, [payload.property]: payload.value } : x)
            }
        case UPDATE_PRODUCTS_QTY:
            return {
                ...state,
                products: state.products.map((x) => (x.id === payload.id) ?
                    { ...x, [payload.property]: payload.value, units: payload.units } : x)
            }
        case UPDATE_UNIT:
            return {
                ...state,
                products: state.products.map((x) => (x.id === payload.idUnit) ? { ...x, units: x?.units.map((un) => (un.id === payload.id) ? { ...un, [payload.property]: payload.value } : un) } : x)
            }
        case REMOVE_PRODUCTS:
            return {
                ...state,
                products: state.products.filter(x => x.id !== payload.id),
            }
        case REMOVE_UNIT:
            return {
                ...state,
                products: state.products.map((x) => (x.id === payload.id) && x?.units.filter(unit => unit.id !== payload.idUnit).length > 0 ? {
                    ...x, units: x.units.filter(unit => unit.id !== payload.idUnit).map((unit, index) => ({
                        ...unit, name: `Unit ${index + 1}`, partNum: index + 1,
                    })),
                    qty: x.units.filter(unit => unit.id !== payload.idUnit).length,
                } : x)
            }
        case SET_RETURN_REQUEST_DATA:
            return {
                ...state,
                data: payload,
            }
        case SET_RETURN_REQUEST_DATA_COMPLETE:
            return {
                ...state,
                ...payload,
            }
        case UPDATE_EOR:
            return {
                ...state,
                eor: payload
            }
        case UPDATE_IOR:
            return {
                ...state,
                ior: payload
            }
        default:
            return state;
    }
};
