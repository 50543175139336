import request from "../core/api/request";

export const eventService = {
    getUserEvents: () => {
        return request.getURIEncoded({ url: `get/EventsUserProfile?_dc=${Date.now()}` });
    },
    saveEventToAddressBook: (data) => {
        return request.postURIENCODEDJSONSave({ url: `save/user_event`, data });
    },
    deleteEvent: (id) => {
        return request.postEncode({ url: `del/user_event?idds=${id}` })
    }
};