import React, { Fragment, useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
// import { Button } from '.';
import { scrollTop } from '../../core/helpers';
import { selectUserInfo } from '../../core/redux/selectors';
import { CancelAndSaveButtons } from '../sideBarReturn/cancelAndSaveButtons';
import { POWRButton } from '../styledComponents';

const ActionButtons = memo(({ primary, pText, secondary, sText, completed, step, hideCancelAndSave, isLoading }) => {
    const [RMALogin, setRMALogin] = useState(false);
    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        setRMALogin(userInfo.rmaLogin && userInfo.rma)
    }, [userInfo]);

    return (
        <Fragment>
            <div className={!RMALogin ? "btnsContainer" : "btnsContainer pb-4"}>
                {secondary &&
                    <POWRButton onClick={() => { scrollTop(); secondary(); }} color="secondary" >{sText}</POWRButton>
                }
                {primary &&
                    <POWRButton
                        className={RMALogin && !secondary ? "actButton" : ''}
                        disabled={!completed} isLoading={isLoading}
                        onClick={() => { scrollTop(); primary(); }}
                    >
                        {pText}
                    </POWRButton>
                }
            </div>
            {!hideCancelAndSave && !RMALogin &&
                <CancelAndSaveButtons
                    step={step}
                    save={primary}
                />
            }
        </Fragment>
    );
}, (prev, next) => prev.completed === next.completed
    && prev.primary === next.primary
    && prev.secondary === next.secondary
    && prev.pText === next.pText
    && prev.sText === next.sText
);

export default ActionButtons;