import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { saveShipmentData, loadPackages, loadSubLines, setShipmentDetail } from '../../core/redux/actions';
import { getShipments, getRMA } from '../../services';
import { useTranslate } from '../../core/contexts';
import { ViewPackagesTable } from '../../components';
import { TabSelector, Status } from '../../components/controls';
import { PickupAddressCarrier, CarrierDetails } from './index';
import { getShipmentStatus } from '../../core/constants/ShipmentsStatus';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const [searching, setSearching] = useState(true);
    const [selected, setSelected] = useState(0);
    const [data, setData] = useState({});
    const [rma, setRMA] = useState(null);
    const [shipId, setShipId] = useState('');
    const [rmas, setRmas] = useState('');
    const [releaseLinesAllowed, setReleaseLinesAllowed] = useState(false);

    const dateFormat = sessionStorage.getItem('dateFormat');
    const timeFormat = sessionStorage.getItem('timeFormat');

    const selectOptions = [
        translate('gridColumns.carrier'),
        translate('subtitle.pickupInformation'),
        translate('subtitle.viewShipment')
    ];

    useEffect(() => {
        dispatch(setShipmentDetail({}));
    }, [dispatch]);

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.id) {
            let id = props.location.state.id;
            let dat = props.location.state.data;
            dispatch(saveShipmentData(parseInt(id.replace(/\D/g, '')), "", 2, 2));
            if (dat && dat.rmas) {
                dispatch(setShipmentDetail(dat));
                setData(dat);
                loadShipmentInfo(dat);
                setShipId(dat.idd);
                const allowedStatus = [1, 2, 3, 4, 6, 8, 11, 17];
                const isReleaseLinesAllowed = allowedStatus.findIndex(x => x === dat.ship_event);
                setReleaseLinesAllowed(isReleaseLinesAllowed >= 0);
            }
            searchShipment(id, true);
        } else if (props.location && props.location.search) {
            if (props.location.search.split('id=')[1]) {
                let id = props.location.search.split('id=')[1];
                dispatch(saveShipmentData(parseInt(id.replace(/\D/g, '')), "", 2, 2));
                searchShipment(id, true);
            }
        }
    }, [props.location, dispatch]);


    const loadShipmentInfo = (datos) => {
        dispatch(loadPackages(datos.idd, true));
        dispatch(loadSubLines(datos.idd, datos.rmas, true));
        setSearching(false);
    };

    const searchShipment = (id, search) => {
        if (searching || search) {
            const parametres = { param_ship_code: id, param_exclude_saved: "on", param_max_records: 200 };
            getShipments(parametres).then(response => {
                let resStr = response.res ? typeof (response.res) === 'string' ?
                    response.res.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                    : response.res : { dtos: [] };
                let dtos = typeof (resStr) === 'string' ? JSON.parse(resStr).dtos : resStr.dtos;
                dtos = dtos.filter(x => x.rmas)[0];
                searchRMA(dtos.rmas);
                dispatch(setShipmentDetail(dtos));
                setData(dtos);
            }).catch(err => {
                console.log(err);
                setSearching(false);
            });
        }
    }

    const searchRMA = (rma) => {
        setRmas(rma);
        const parameters = { param_rma_or_tradein: rma.toString(), isSimpleSearch: true };
        getRMA(parameters).then(response => {
            if (response.res.success) {
                setRMA(response.res.dtos[0]);
            } 
        }).catch(err => {
            console.log(err);
        });
    }

    const status = getShipmentStatus(data.status, data.ship_event);
    const pickupdate = moment(`${data.date_pickup}`, 'MM/DD/YYYY');

    return data.ship_code ? (
        <div className="shipmentDetailContainer">
            <h1>{`${translate('aux.shippingDetails')} - ${data.ship_code}`}</h1>
            <div className="shipmentDetailTable">
                <div className="shipProp">
                    <label className="strong">{translate('gridColumns.rma')}:</label>
                    <p>{data.rmas ? data.rmas.join(', ') : '—'}</p>
                </div>
                <div className="shipProp">
                    <label className="strong">{translate('gridColumns.status')}:</label>
                    <Status className="double" status={status} />
                </div>
                <div className="shipProp">
                    <label className="strong">{translate('gridColumns.createdBy')}:</label>
                    <p>{data.principal || '—'}</p>
                </div>
                <div className="shipProp">
                    <label className="strong">{translate('gridColumns.pickupRequestedDate')}:</label>
                    <p className="update">{pickupdate.format(dateFormat) || '—'}</p>
                </div>
                <div className="shipProp">
                    <label className="strong">{translate('formLabel.tracking')}:</label>
                    <p title={data.tns ? data.tns.join(', ') : '—'}>
                        {data.tns ? data.tns.join(', ') : '—'}
                    </p>
                </div>
                <div className="shipProp">
                    <label className="strong">{translate('message.courierDispatch')}:</label>
                    <p>{data.confirmation_number || '—'}</p>
                </div>
            </div>
            <TabSelector options={selectOptions} selected={selected} setSelected={setSelected} />
            <div className={selected !== 0 ? "d-none" : "d-block"}>
                <CarrierDetails id={data.idd} shipment={data} />
            </div>
            <div className={selected !== 1 ? "d-none" : "d-block"}>
                <PickupAddressCarrier pickupId={data.pickup_id} shipmentId={data.idd} rmaInfo={rma} />
            </div>
            <div className={selected !== 2 ? "d-none" : "d-block"}>
                <ViewPackagesTable isReleaseLinesEnabled={releaseLinesAllowed} shipId={shipId} rmas={rmas} />
            </div>
        </div>
    ) : <div />
}