import React, { Fragment, useState, useEffect } from 'react';
import { useTranslate } from '../core/contexts';
import { getContactInfo, getRMA, getSavedShipment, gtagService, saveRMA } from '../services';
import { LabelOnlyModal, IndiaRMAModal } from './modals';
import { Link } from './controls';
import { POWRIcon } from './styledComponents';
import { COUNTRY, STORAGE } from '../core/constants';
import { useRMAValidation } from '../core/hooks';
import { DOCUMENTATION, RETURN } from '../core/constants/ROUTES';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadPackages, loadSubLines, resetReturnFlow, resetRMAInfo, saveShipmentData, setReturnFlowProp } from '../core/redux/actions';
import moment from 'moment';
import { COLOR } from '../core/constants/Colors';
import { SET_SUB_LINES } from '../core/redux/actionTypes';

export default ({ idd, rmas, dropdown, resume, RMALogin }) => {
    const { translate } = useTranslate();
    const { isValidRMADashboard } = useRMAValidation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showLabelOnlyModal, setShowLabelOnlyModal] = useState(false);
    const [showIndiaModal, setShowIndiaModal] = useState(false);
    const [paramsSaved, setParamsSaved] = useState(null);
    const [savedPickup, setSavedPickup] = useState(null);
    const [resuming, setResuming] = useState(true);

    const clearSessionStorage = () => {
        sessionStorage.removeItem(STORAGE.SEARCH_NAME)
        sessionStorage.removeItem(STORAGE.SAVE_NAME)
        sessionStorage.removeItem(STORAGE.CONTACT_INFO)
        sessionStorage.removeItem(STORAGE.LABEL_ONLY)
        sessionStorage.removeItem(STORAGE.RESUME_PICKUP)
        sessionStorage.removeItem(STORAGE.RESUME_SHIPMENT)
    }

    useEffect(() => {
        if (resume && resuming) {
            resumeSavedPickup();
            setResuming(false);
        }
    }, [resume])

    const resumeSavedPickup = async () => {
        await getSavedShipment(idd).then(async response => {
            if (response && response.res.dtos) {
                dispatch(resetRMAInfo());
                dispatch(resetReturnFlow());
                setSavedPickup(response.res.dtos[0]);
                getRMAs(response.res.dtos[0]);
                const sublines = response.res.dtos[0].ship_sublines;
                const sublinesFullInfo = await dispatch(loadSubLines(idd, response.res.dtos[0].rmas.map(x => x.rma_num[0]), true));
                dispatch(setReturnFlowProp('zoneInstructions', response.res.dtos[0].pickup.additional_instr || ' '));
                const savedSublinesWithPackage = sublines.filter(x => x.package_id);
                if (savedSublinesWithPackage.length) {
                    const selectedLines = [];
                    for (let sb of savedSublinesWithPackage) {
                        if (!selectedLines.find(x => x.rma === sb.rma_num && x.line === sb.rma_line)) {
                            const sublinesOfThisLine = sublines.filter(ss => ss.rma_num === sb.rma_num && ss.rma_line === sb.rma_line);
                            selectedLines.push({
                                rma: sb.rma_num,
                                line: sb.rma_line,
                                part: (sublinesFullInfo.find(x => x.idd === sb.idd) || { part_num: '' }).part_num,
                                available: sublinesOfThisLine.length,
                                qtyPicked: sublinesOfThisLine.filter(sot => sot.package_id).length
                            });
                        }
                    }
                    const newSublines = savedSublinesWithPackage.map(x => ({
                        ...x,
                        picked: selectedLines.find(sl => sl.rma === x.rma_num && sl.line === x.rma_line) ? !!x.package_id : true
                    }));
                    dispatch(setReturnFlowProp('selectedLines', selectedLines));
                    dispatch({ type: SET_SUB_LINES, payload: newSublines });
                    dispatch(setReturnFlowProp('oldSubLines', newSublines));
                    // dispatch(setReturnFlowProp('oldPackages', response.res.dtos[0].ship_packages));
                    dispatch(loadPackages(response.res.dtos[0].shipment.idd, true));
                    sessionStorage.setItem('savedSublines', JSON.stringify(savedSublinesWithPackage));
                }
            }
        });
    };

    const getRMAs = (savedShipment) => {
        const parameters = {
            param_rma_or_tradein: rmas.toString(),
            param_fa_case_num: "",
            isSimpleSearch: true,
            isReturnFa: false
        };

        getRMA(parameters).then(response => {
            if (isValidRMADashboard(response).isValid) {
                let dtos = response.res.dtos;
                let country = dtos[0].country[0];

                if (dtos.length > 0) {
                    const paramsSave = {
                        ship_id: 0,
                        rmas: rmas.length > 1 ? rmas.sort((a, b) => a > b) : rmas[0],
                        simple: true
                    };

                    sessionStorage.setItem(STORAGE.SEARCH_NAME, JSON.stringify(dtos)); //dtos.rmas
                    if (country === COUNTRY.INDIA && !RMALogin) {
                        setParamsSaved(paramsSave);
                        setShowIndiaModal(!showIndiaModal);
                    } else if ((country === COUNTRY.US || country === COUNTRY.CANADA) && !RMALogin) {
                        setParamsSaved(paramsSave);
                        setShowLabelOnlyModal(!showLabelOnlyModal);
                    } else {
                        saveRMAs(paramsSave, savedShipment);
                    }
                } else {
                    clearSessionStorage();
                }
            }
        }).catch(() => {
            clearSessionStorage();
        });
    }

    const saveRMAs = (params, savedShipment) => {
        saveRMA(params).then(response => {
            if (response.res.success) {
                let rma = response.res.dtos;
                const paramsCInfo = {
                    ship_id: rma[0].idd
                };
                rma[0].idd = idd;
                sessionStorage.setItem(STORAGE.SAVE_NAME, JSON.stringify(rma));
                getContactAndReturn(paramsCInfo, savedShipment);
            } else {
                clearSessionStorage();
            }
        }).catch(() => {
            clearSessionStorage();
        });
    }

    const getContactAndReturn = (params, savedShipment) => {
        getContactInfo(params).then(contact => {
            if (contact.res.success) {
                let contacts = contact.res.dtos;

                let requestorContact = {};
                let onSiteContact = {};
                let localContact = {};
                let pickupLocation = {};
                let carrier = {};

                for (const contactInfo in savedShipment.shipment) {
                    if (contactInfo.includes('requestor_')) requestorContact = { ...requestorContact, [contactInfo.replace('requestor_', '')]: savedShipment.shipment[contactInfo] } // REQUESTOR CONTACT
                    if (contactInfo.includes('onsite_')) onSiteContact = { ...onSiteContact, [contactInfo.replace('onsite_', '')]: savedShipment.shipment[contactInfo] } // PICKUP CONTACT
                    if (contactInfo.includes('local_')) localContact = { ...localContact, [contactInfo.replace('local_', '')]: savedShipment.shipment[contactInfo] } // ALTERNATE CONTACT
                }

                for (const pickupSaved in savedShipment.pickup) {
                    if (pickupSaved.includes('pickup_')) pickupLocation = { ...pickupLocation, [pickupSaved.replace('pickup_', '')]: savedShipment.pickup[pickupSaved] } // PICKUP LOCATION CONTACT
                    if (pickupSaved.includes('carrier_')) carrier = { ...carrier, [pickupSaved]: savedShipment.pickup[pickupSaved] } // CARRIER INFO
                }

                const tmp_idd = Date.now();
                if (!contacts.requestor[0] && requestorContact.fname) contacts.requestor[0] = { idd: tmp_idd + 1 };
                if (!contacts.onsite[0] && onSiteContact.fname) contacts.onsite[0] = { idd: tmp_idd + 2 };
                if (!contacts.local[0] && localContact.fname) contacts.local[0] = { idd: tmp_idd + 3 };
                if (!contacts.pickup[0] && pickupLocation.name) contacts.pickup[0] = { idd: tmp_idd + 4 };
                
                contacts.requestor[0] = { ...contacts.requestor[0], ...requestorContact };
                contacts.onsite[0] = { ...contacts.onsite[0], ...onSiteContact };
                contacts.local[0] = { ...contacts.local[0], ...localContact };
                contacts.pickup[0] = { ...contacts.pickup[0], ...pickupLocation };

                sessionStorage.setItem(STORAGE.CONTACT_INFO, JSON.stringify(contacts));

                let pickup = savedShipment.pickup;

                let shipmentData = {
                    idd: pickup.idd,
                    directions: pickup.site_directions || '',
                    weight: savedShipment.ship_packages[0].weight_uom || 0,
                    dimension: savedShipment.ship_packages[0].dim_uom || 0
                };

                sessionStorage.setItem(STORAGE.RESUME_SHIPMENT, JSON.stringify(shipmentData));
                dispatch(saveShipmentData(shipmentData.idd, shipmentData.directions, shipmentData.weight, shipmentData.dimension, ''));

                let schedulePickup = {
                    carrier: carrier,
                    pickup: {
                        schedule_date: pickup.schedule_date ? moment(pickup.schedule_date).format("YYYY-MM-DD") : '',
                        schedule_time_type: pickup.schedule_time_type || 0,
                        oversized_items: pickup.oversized_items || 0,
                        location_id: pickup.location_id || '',
                        ack_returner_pays: pickup.ack_returner_pays || false,
                    },
                    instruct: {
                        is_fact_return: pickup.is_fact_return || false,
                        equip_pallet_jack: pickup.equip_pallet_jack || false,
                        equip_heavy_load: pickup.equip_heavy_load || false,
                        generate_sli: pickup.generate_sli || false,
                        generate_packing_lst: pickup.generate_packing_lst || false,
                        generate_invoice: pickup.generate_invoice || false,
                        equip_lift_gate: pickup.equip_lift_gate || false,
                    },
                    notifications: {
                        pickup_notifications: savedShipment.pickup_notifications || []
                    },
                    paperless: pickup.is_paperless || false,
                };
                sessionStorage.setItem(STORAGE.RESUME_PICKUP, JSON.stringify(schedulePickup));
                dispatch(setReturnFlowProp('schedulePickup', schedulePickup));

                gtagService.event('Shipments', 'ResumeSavedShipment');
                history.push(RETURN);
            } else {
                clearSessionStorage();
            }
        }).catch(() => {
            clearSessionStorage();
        });
    }

    const createDocumentationIndia = () => {
        history.push({
            pathname: DOCUMENTATION,
            state: { rma: rmas.toString() }
        })
    }

    const handleLabelOnly = (params, labelOnly) => {
        sessionStorage.setItem(STORAGE.LABEL_ONLY, JSON.stringify(labelOnly));
        saveRMAs(params, savedPickup);
    }

    return (
        <Fragment>
            <Link className={dropdown ? "dropDownOption" : "action-button"} onClick={() => resumeSavedPickup()}>
                <POWRIcon className="fal fa-play icon" size="16px" color={dropdown ? COLOR.GREY : COLOR.CISCO_BLUE} />
                {translate('button.resumeSavedPickup')}
            </Link>
            <LabelOnlyModal
                show={showLabelOnlyModal}
                onClose={() => setShowLabelOnlyModal(!showLabelOnlyModal)}
                onContinue={handleLabelOnly.bind(this, paramsSaved)}
            />
            <IndiaRMAModal
                proceedWODocument={saveRMAs.bind(this, paramsSaved, savedPickup)}
                createDocument={createDocumentationIndia.bind(this)}
                show={showIndiaModal}
                close={() => setShowIndiaModal(!showIndiaModal)}
            />
        </Fragment>
    )
}   