
import { useDispatch } from "react-redux";
import { setLinesError } from "../redux/actions";
import { getValidationSN } from "../../services/rmaService";
import { useAlert } from "./useAlert";
import { RETURN } from "../constants";
import { useTranslate } from '../../core/contexts';


export const useSerialNumberValidation = () => {
    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const { translate } = useTranslate();

    const hasInvalidLines = async (subLines, isModal) => {
        //Validate serials numbers section
        if (subLines && subLines.length > 0) {
            var sublinesErrors = [];
            //getting serialized lines
            let subliesSerialized = subLines.filter(x => x.serialized && x.serial !== 'Not Present');
            if (subliesSerialized.length > 0) {
                //serials with comma or space validation
                let sublinesInvalidSN = subliesSerialized.filter(x => x.serial?.includes(',') || x.serial?.includes(' '));
                if (sublinesInvalidSN.length > 0)
                    sublinesInvalidSN.forEach(line => {
                        sublinesErrors.push(line.idd)
                    });

                //serial empty validation
                let sublinesEmptySN = subliesSerialized.filter(x => !x.serial || x.serial === null || x.serial === '');
                if (sublinesEmptySN.length > 0)
                    sublinesEmptySN.forEach(line => {
                        sublinesErrors.push(line.idd)
                    });

                // Duplicate serials validation
                //find serials repeated
                let serialMap = subliesSerialized.reduce((acc, item, index) => {
                    if (!acc[item.serial]) {
                        acc[item.serial] = [];
                    }
                    if (item.serial) acc[item.serial].push({ line: index, item: item });
                    return acc;
                }, {});

                //getting serials repeated
                let duplicateSerials = Object.keys(serialMap).filter(serial => serialMap[serial].length > 1 && serialMap[serial] !== 'Not Present');
                //getting ids lines repeated
                if (duplicateSerials.length > 0) {
                    duplicateSerials.forEach(serial => {
                        serialMap[serial].forEach(entry => {
                            //dispatch(updateSubLineProp(entry.item.idd, 'validSN', false));
                            sublinesErrors.push(...sublinesErrors, entry.item.idd)
                        });
                    });
                    
                }
                if (sublinesEmptySN.length > 0 || duplicateSerials.length > 0 || sublinesInvalidSN.length > 0) {
                    dispatch(setLinesError(sublinesErrors));
                    return sublinesErrors;
                }
                //validate serials numbers with api
                else {
                    const grouped = subliesSerialized.reduce((acc, item) => {
                        if (!acc[item.part_num]) {
                            acc[item.part_num] = { part_num: item.part_num, serials: [] };
                        }
                        acc[item.part_num].serials.push(item.serial);
                        return acc;
                    }, {});

                    let req = Object.values(grouped);
                    let t = (req.map(x => ({ part_number: x.part_num, serial_numbers: x.serials })));
                    const body = { json: t, rma_num: subliesSerialized[0].rma_num }
             
                    try {
                        const response = await getValidationSN(body);
                        if (!response.res.success) { 
                            showAlert({ id: 'unexpected', message: translate('error.apiCiscoError'), page: RETURN, color: 'red', isModal: isModal });
                            return -1   //error ; 
                        }
                        if (response && response.res && response.res.dtos.length > 0) {
                            let data = response.res.dtos;
                            var arr = [];
                            data.forEach(element => {
                                element.forEach(line => {
                                    if (!line.snValid) arr.push(subliesSerialized.find(x => x.serial === line.serial)?.idd);
                                });
                            });
                            if (arr.length > 0) return arr;
                            else return [];
                        }
                    }
                    catch (error) {
                        console.log(error);
                        showAlert({ id: 'unexpected', message: translate('error.apiCiscoError'), page: RETURN, color: 'red', isModal: isModal });
                        return -1   //error ; 
                    }
                }
            }
            else return [];
        }

        //END validate serials numbers section

    }

    return {
        hasInvalidLines

    }
};