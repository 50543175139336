import styled from "styled-components";

export const SpacedPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 32px 24px;
  /* max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xl}; */

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.lg}) {
    padding: 8px;
  }

`;
