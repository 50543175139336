import { createSelector } from 'reselect';

const documentationState = state => {
    return state.documentation;
}

export const selectDocumentationInputRMA = createSelector(
    [documentationState],
    (documentation) => documentation.documentationInputRMA
);

export const selectDocumentationErrorMsg = createSelector(
    [documentationState],
    (documentation) => documentation.documentationErrorMsg
);

export const selectDocumentationError = createSelector(
    [documentationState],
    (documentation) => documentation.documentationError
);

export const selectDocumentationResults = createSelector(
    [documentationState],
    (documentation) => documentation.documentationResults
);