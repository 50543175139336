import { ADD_EVENT, REMOVE_EVENT, SET_EVENTS, UPDATE_EVENT } from "../actionTypes";

const initialState = {
    events: [],
    selectedEvent: null
};

export const events = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_EVENTS: return { ...state, events: payload };
        case ADD_EVENT: return { ...state, events: [payload, ...state.events] };
        case UPDATE_EVENT: return { ...state, events: state.events.map((x) => x.idd === payload.idd ? payload : x) };
        case REMOVE_EVENT: return { ...state, events: state.events.filter(x => x.idd !== payload) };
        default:
            return state;
    }
};
