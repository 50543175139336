import { BASE_URL_DOWNLOAD_LABEL } from "../../core/constants/URLS";
import { SHIPMENT_DETAIL } from "../../core/constants/ROUTES";
import { COLOR } from "./Colors";

let green = COLOR.GREEN;
let blue = COLOR.CISCO_BLUE;
let red = COLOR.RED;
let yellow = COLOR.YELLOW;

let actions = [
    { icon: 'fal fa-print', text: 'aux.printLabels', href: BASE_URL_DOWNLOAD_LABEL, type: "popup" },
    { icon: 'fal fa-boxes', text: 'title.viewShipment', href: SHIPMENT_DETAIL, type: "onClick" }
];

export const SHIPMENT_STATUS = [
    { id: 0, type: 'UNKNOWN', text: 'shipmentsStatus.UNKNOWN' },
    { id: 1, type: 'ACTIVE', text: 'shipmentsStatus.active' },
    { id: 2, type: 'DELETED', text: 'shipmentsStatus.deleted' },
    { id: 3, type: 'DRAFT', text: 'shipmentsStatus.draft' },
    { id: 4, type: 'WIPEOUT', text: 'shipmentsStatus.wipeout' },
    { id: 5, type: '', text: '' },
    { id: 6, type: 'SYSTEM', text: 'shipmentsStatus.system' },
];

export const SHIPMENT_STATUS_NUM = {
    STATUS_UNKNOWN: 0,
    STATUS_ACTIVE: 1,
    STATUS_DELETED: 2,
    STATUS_DRAFT: 3,
    STATUS_WIPEOUT: 4,
    STATUS_SYSTEM: 6
}

export const SHIPMENT_EVENT_STATUS = [
    { id: 0, actions, color: COLOR.BLACK, text: 'shipmentsStatus.unknown' },
    { id: 1, actions, color: yellow, text: 'shipmentsStatus.requested' },
    { id: 2, actions, color: blue, text: 'shipmentsStatus.scheduled' },
    { id: 3, actions, color: red, text: 'shipmentsStatus.denied' },
    { id: 4, actions, color: blue, text: 'shipmentsStatus.rescheduledByUser' },
    { id: 5, actions, color: red, text: 'shipmentsStatus.cancelledByUser' },
    { id: 6, actions, color: green, text: 'shipmentsStatus.made' },
    { id: 7, actions, color: yellow, text: 'shipmentsStatus.delayed' },
    { id: 8, actions, color: green, text: 'shipmentsStatus.delivered' },
    { id: 9, actions, color: yellow, text: 'shipmentsStatus.deliveryTimeChanged' },
    { id: 10, actions, color: yellow, text: 'shipmentsStatus.shipmentDelayed' },
    { id: 11, actions, color: red, text: 'shipmentsStatus.lost' },
    { id: 12, actions, color: green, text: 'shipmentsStatus.receiving' },
    { id: 13, actions, color: blue, text: 'shipmentsStatus.rescheduledByCarrier' },
    { id: 14, actions, color: red, text: 'shipmentsStatus.deleted' },
    { id: 15, actions, color: yellow, text: 'shipmentsStatus.alert' },
    { id: 16, actions, color: red, text: 'shipmentsStatus.futile' },
    { id: 17, actions, color: green, text: 'shipmentsStatus.labelCreated' },
];

export const RMA_SHIPEVENTTYPE_TYPES = {
    RMA_SHIPEVENTTYPE_UNKNOWN: 0,
    RMA_SHIPEVENTTYPE_PICKUP_CREATED_BY_USER: 1,
    RMA_SHIPEVENTTYPE_PICKUP_CONFIRMED: 2,
    RMA_SHIPEVENTTYPE_PICKUP_DENIED: 3,
    RMA_SHIPEVENTTYPE_PICKUP_RESCHEDULED_BY_USER: 4,
    RMA_SHIPEVENTTYPE_PICKUP_CANCELLED_BY_USER: 5,
    RMA_SHIPEVENTTYPE_PICKUP_COMPLETED: 6,
    RMA_SHIPEVENTTYPE_PICKUP_DELAYED: 7,
    RMA_SHIPEVENTTYPE_SHIPMENT_DELIVERY_COMPLETED: 8,
    RMA_SHIPEVENTTYPE_SHIPMENT_DELIVERY_TIME_CHANGED: 9,
    RMA_SHIPEVENTTYPE_SHIPMENT_DELIVERY_DELAYED: 10,
    RMA_SHIPEVENTTYPE_SHIPMENT_LOST: 11,
    RMA_SHIPEVENTTYPE_RECEIVING_PART_RECEIVED: 12,
    RMA_SHIPEVENTTYPE_PICKUP_RESCHEDULED_BY_CARRIER: 13,
    RMA_SHIPEVENTTYPE_SHIPMENT_DELETED: 14,
    RMA_SHIPEVENTTYPE_PICKUP_FUTILE: 16,
    RMA_SHIPEVENTTYPE_LABEL_CREATED: 17
}

export const getShipmentStatus = (status, event) => {
    if (status === SHIPMENT_STATUS_NUM.STATUS_DRAFT)
        return {
            color: yellow,
            text: 'shipmentsStatus.notSubmitted'
        };
    else if (status === SHIPMENT_STATUS_NUM.STATUS_DELETED && event !== RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_CANCELLED_BY_USER)
        return {
            color: red,
            text: 'shipmentsStatus.deleted'
        };
    else if (status !== SHIPMENT_STATUS_NUM.STATUS_ACTIVE && status !== SHIPMENT_STATUS_NUM.STATUS_DELETED)
        return {
            color: COLOR.BLACK,
            text: 'shipmentsStatus.na'
        };
    else return SHIPMENT_EVENT_STATUS[event];
}