import { eventService } from "../../../services";
import { REMOVE_EVENT, SET_EVENTS } from "../actionTypes";
import { loadCountries, loadEventCarriers, loadRMATypes } from "./ciscoEntities.actions";

export const loadUserEvents = () => (dispatch) => {
    eventService.getUserEvents().then(async (res) => {
        if (res.res.success) {
            await dispatch(loadCountries());
            await dispatch(loadEventCarriers());
            await dispatch(loadRMATypes());
            dispatch({ type: SET_EVENTS, payload: res.res.dtos });
        }
    });
};

export const refreshUserEvents = () => (dispatch) => {
    eventService.getUserEvents().then(async (res) => {
        if (res.res.success) dispatch({ type: SET_EVENTS, payload: res.res.dtos });
    });
}

export const saveEvent = (body, isAdd) => (dispatch) => {
    eventService.saveEventToAddressBook(body).then((res) => {
        dispatch(refreshUserEvents());
        // dispatch({
        //     type: isAdd ? ADD_EVENT : UPDATE_EVENT,
        //     payload: isAdd ? res.res.dtos[0] : body.json.dtos[0]
        // });
    });
};

export const removeEvent = (id) => (dispatch) => {
    eventService.deleteEvent(id).then((res) => {
        dispatch({ type: REMOVE_EVENT, payload: id });
    });
};
