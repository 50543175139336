import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../core/contexts';
import { useAlert } from '../../core/hooks';
import { gtagService } from '../../services';
import { POWRButton, POWRIcon, POWRModal } from "../styledComponents";

export const CancelAndSaveModal = ({ show, close, type, save }) => {
    const { translate } = useTranslate();
    const history = useHistory();
    const { showAlert } = useAlert();

    const title = type === 'cancel' ? translate('title.cancelAndReturn') : translate('title.saveAndReturn');
    const button = type === 'cancel' ? translate('button.cancelAndReturn') : translate('button.saveAndReturn');
    const message = type === 'cancel' ? translate('message.cancelWithoutSaving') : translate('message.cancelAndLeave');

    const saveAndReturn = async () => {
        close();
        await save();
        showAlert({
            id: 'cancelAndSave',
            message: translate('message.sessionSaved'),
            page: '*',
            color: 'green'
        });
        gtagService.event('ReturnFlow', 'Save&Return');
        history.push('/home');
    }
    const cancelAndReturn = () => {
        gtagService.event('ReturnFlow', 'Cancel&Return');
        history.push('/home');
    };

    return (
        <POWRModal
            show={show}
            title={title}
            size="small"
            onClose={close}
        >
            <div className="content">
                <div className="text-center">
                    <POWRIcon
                        className={`fas fa-${type === 'cancel' ? 'exclamation-triangle' : 'info-circle'}`}
                        color={`${type === 'cancel' ? '#ffb600' : '#099AD6'}`}
                        size="3rem"
                    />
                    <p className="pt-3">{message}</p>
                </div>
            </div>
            <div className="toolbar">
                <POWRButton color={'secondary'} onClick={close}>{translate('button.back')}</POWRButton>
                <POWRButton onClick={type === 'cancel' ? cancelAndReturn : saveAndReturn}>{button}</POWRButton>
            </div>
        </POWRModal>
    )
}

