import React, { Fragment, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../core/contexts';
import { setShipmentsSearchParameters, setShipmentsSearching } from '../../core/redux/actions';
import { selectShipmentsSearchResults, selectShipmentsSearching, selectUserInfo } from '../../core/redux/selectors';
import { getCarrierList } from '../../services';
import { Input, Select, InputCalendar, Checkbox, Icon, Upload, Spinner, Table, Button } from '../controls';
import { POWRButton, InfoSection, POWRModal } from '../styledComponents';
import { REGEXP, SHIPMENT_EVENT_STATUS, THEATER_TYPES } from '../../core/constants';
import { RLSPDocumentList } from '../shared';
import { useAlert } from '../../core/hooks';
import { uploadRLSPDocument } from '../../services/shipmentService';
import XLSX from 'xlsx';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const shipments = useSelector(selectShipmentsSearchResults);
    const isSearching = useSelector(selectShipmentsSearching);
    const userInfo = useSelector(selectUserInfo);
    const [rmaLogin, setRMALogin] = useState(false);
    const [error, setError] = useState(false);
    const [gettingParams, setGettingParams] = useState(true);
    const [searchingCarriers, setSearchingCarriers] = useState(true);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [shipmentID, setShipmentID] = useState("");
    const [shipmentID1, setShipmentID1] = useState(true);
    const [haveShipmentID, setHaveShipmentID] = useState(false);
    const [rma, setRma] = useState("");
    const [rma1, setRma1] = useState(true);
    const [haveRma, setHaveRma] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState("");
    const [haveTrackingNumber, setHaveTrackingNumber] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");
    const [haveSerialNumber, setHaveSerialNumber] = useState(false);
    const [shipmentStatusSelected, setShipmentStatusSelected] = useState([]);
    const [shipmentStatusCodes, setShipmentStatusCodes] = useState([]);
    const [optionsShipmentStatus, setOptionsShipmentStatus] = useState([]);
    const [theaterSelected, setTheaterSelected] = useState([]);
    const [theaterSelectedCodes, setTheaterSelectedCodes] = useState([]);
    const [theaterOptions, setTheaterOptions] = useState([]);
    const [categorySelected, setCategorySelected] = useState("");
    const [categorySelectedCode, setCategorySelectedCode] = useState("");
    const [carrier, setCarrier] = useState([]);
    const [optionsCarrier, setOptionsCarrier] = useState([]);
    const [onlyMine, setOnlyMine] = useState(false);
    const [excludeSave, setExcludeSave] = useState(true);
    const [showCalendars, setShowCalendars] = useState(true);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(true);
    const [DHLELPOnly, setDHLELPOnly] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDocumentTable, setShowDocumentTable] = useState(false);
    const [documentData, setDocumentData] = useState([]);
    const [hasErrors, setHasErrors] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [documentErrors, setDocumentErrors] = useState('');
    const [showModalValidation, setShowModalValidation] = useState(false);

    const dateFormat = sessionStorage.getItem('dateFormat');


    const rlsp = props.rlsp || false;

    useEffect(() => {
        if (attachedFiles && attachedFiles.length > 0) saveDocument();
    }, [attachedFiles]);

    useEffect(() => {
        if (attachedFiles && attachedFiles.length > 0 && !showDocumentTable) {
            setDocumentData([]);
            setDocumentErrors([]);
        }
    }, [showDocumentTable]);

    useEffect(() => {
        setError(props.canceled ? false : shipments ? shipments.length < 1 : false);
    }, [shipments, props.canceled]);

    useEffect(() => {
        if (userInfo.rmaLogin) {
            setRMALogin(true);
            setRma(userInfo.rma);
        }
    }, [userInfo]);

    useEffect(() => {
        if (rmaLogin && rma) {
            setRMALogin(false);
            handleSearch();
            dispatch(setShipmentsSearching(true));
        }
    }, [rmaLogin, rma]);

    useEffect(() => {
        if (!theaterOptions.length) setTheaterOptions(
            THEATER_TYPES.map(x => ({ ...x, text: translate(x.text) }))
        );
    }, [THEATER_TYPES]);

    useEffect(() => {
        if (!optionsShipmentStatus.length) {
            let options = [];
            SHIPMENT_EVENT_STATUS.forEach(el => {
                if (el.id !== 0 && el.id !== 9 && el.id !== 12 && el.id !== 14 && el.id !== 15) {
                    options.push({
                        ...el,
                        text: translate(el.text),
                        code: translate(el.text)
                    })
                }
            })
            setOptionsShipmentStatus(options);
        }
    }, [SHIPMENT_EVENT_STATUS]);

    const getCarrier = () => {
        if (searchingCarriers) {
            const carrierBody = {
                _dc: 16,
                code: ""
            }

            getCarrierList(carrierBody).then(res => {
                let str = res.res ? typeof (res.res) == 'string' ? res.res
                    .replace(/idd:/g, "\"idd\":")
                    .replace(/type:/g, "\"type\":")
                    .replace(/iconCls:/g, "\"iconCls\":")
                    .replace(/'/g, "\"")
                    : res.res : { dtos: [] };
                let rs = typeof (str) == 'string' ? JSON.parse(str) : str;
                setSearchingCarriers(false);
                setOptionsCarrier(rs.dtos);
            }).catch(() => {
            });
        }
    }

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.search && (rma1 || shipmentID1)) {
            if (parseInt(props.location.state.search)) {
                if (rma1) setRma(props.location.state.search);
            } else {
                if (shipmentID1) setShipmentID(props.location.state.search);
            }
            setParams(props.location.state.search);
        } else {
            setParams();
        }
        getCarrier();
        if (gettingParams) dispatch(setShipmentsSearching(true));
    }, [props.location, gettingParams]);

    const setParams = (search) => {
        if (gettingParams) {
            let evalENS = false;
            if (shipmentID || rma || trackingNumber || excludeSave) {
                evalENS = excludeSave;
            }

            let preSearch = search ? parseInt(search) ? 1 : 2 : 0;

            const parameters = rlsp ? {
                param_ship_code: preSearch === 2 && search && shipmentID1 ? search : shipmentID,
                param_rma_or_tradein: preSearch === 1 && search && rma1 ? search : rma,
                param_tn: trackingNumber,
                param_ship_status: shipmentStatusCodes ? shipmentStatusCodes.toString() : '',
                param_date_from: fromDate,
                param_date_to: toDate,
                param_max_records: 501,
                param_use_date: showAdvanceSearch && showCalendars ? 'on' : '',
                param_car_edit_mode: true
            } : {
                param_ship_code: preSearch === 2 && search && shipmentID1 ? search : shipmentID,
                param_rma_or_tradein: preSearch === 1 && search && rma1 ? search : rma,
                param_tn: trackingNumber,
                param_ship_status: shipmentStatusCodes ? shipmentStatusCodes.toString() : '',
                param_theater: theaterSelectedCodes ? theaterSelectedCodes.toString() : '',
                param_rma_cat: categorySelectedCode,
                param_carrier: carrier ? carrier.toString() : '',
                param_use_date: showAdvanceSearch && showCalendars ? 'on' : '',
                param_only_mine: onlyMine ? 'on' : '',
                param_exclude_saved: evalENS ? 'on' : '',
                'id-gct-view-search-adv-checkbox': showAdvanceSearch ? 'on' : '',
                param_date_from: fromDate,
                param_date_to: toDate,
                param_max_records: 501,
                param_is_paperless: DHLELPOnly ? 'on' : '',
                param_serial_number: serialNumber
            };
            setGettingParams(false);
            dispatch(setShipmentsSearchParameters(parameters));
        }
    }

    const handleDateBegin = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) <= new Date(toDate) || toDate === "" || v === "") setFromDate(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) <= new Date(toDate) || toDate === "")) setFromDate(newDate);
        } else setFromDate(v);
    }

    const handleDateEnd = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) >= new Date(fromDate) || fromDate === "" || v === "") setToDate(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) >= new Date(fromDate) || fromDate === "")) setToDate(newDate);
        } else setToDate(v);
    }

    const handleShipmentStatus = (v) => {
        let status = SHIPMENT_EVENT_STATUS.filter(x => v.includes(translate(x.text)));
        let codes = status.map(st => (st.id));
        setShipmentStatusSelected(v);
        setShipmentStatusCodes(codes);
    }

    const handleShipmentID = (v) => {
        setHaveShipmentID(false);
        setShipmentID1(false);
        setShipmentID(v);
    }

    const handleRma = (v) => {
        if (REGEXP.RMASRLSP.test(v)) {
            setHaveRma(false);
            setRma1(false);
            setRma(v);
        }
    }

    const handleTrackingNumber = (v) => {
        setHaveTrackingNumber(false);
        setTrackingNumber(v);
    }

    const handleSearch = () => {
        setHaveShipmentID(true);
        setHaveRma(true);
        setHaveTrackingNumber(true);
        setHaveSerialNumber(true);
        setGettingParams(true);
        setFirstLoad(false);
    }

    const handleReset = () => {
        setError(false);
        setFromDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        setToDate(moment().format('YYYY-MM-DD'));
        setShipmentID("");
        setHaveShipmentID(false);
        setRma("");
        setHaveRma(false);
        setTrackingNumber("");
        setHaveTrackingNumber(false);
        setSerialNumber("");
        setHaveSerialNumber(false);
        setShipmentStatusSelected([]);
        setShipmentStatusCodes([]);
        setTheaterSelected([]);
        setTheaterSelectedCodes([]);
        setCarrier([]);
        setOnlyMine(false);
        setExcludeSave(true);
        setShowCalendars(true);
        setShowAdvanceSearch(true);
        setDHLELPOnly(false);
    }

    const getShipmentStatusWithText = (status) => {
        let res = SHIPMENT_EVENT_STATUS.find(a => translate(a.text) === status) || '';
        if (res) return res.id
        return "";
    }

    const uploadDocument = useCallback(async (file) => {
        var fileExt = file.name.split('.').pop();
        if (fileExt === 'xls' || fileExt === 'xlsx') {
            setIsLoading(true);

            try {
                let arrayBuffer = await file.arrayBuffer();
                let options = { type: 'array', sheetstubs: true, defval: '' };
                let workbook = XLSX.read(arrayBuffer, options);
                let sheetName = workbook.SheetNames
                let sheet = workbook.Sheets[sheetName]
                let xlsjson = XLSX.utils.sheet_to_json(sheet, { defval: '',raw:false });

                const json2 = xlsjson.map((row) => {
                    return {
                        ship_code: row["Shipment ID"]?row["Shipment ID"].replace(/["']/g, ""):"",
                        carrier_code: row["Carrier Name"]?row["Carrier Name"].replace(/["']/g, ""):"",
                        tns: row["Tracking Number"]?row["Tracking Number"].replace(/["']/g, ""):"",
                        ship_event: row["Status"]?row["Status"].replace(/["']/g, ""):"",
                        carrier_msg: row["Carrier Message"]?row["Carrier Message"].replace(/["']/g, ""):"",
                        agreed_pickup_dt: row["Agreed Pickup Date"]?row["Agreed Pickup Date"].replace(/["']/g, ""):"",
                    }
                });

                setDocumentData(json2);
                setAttachedFiles([...attachedFiles, file]);
                setIsLoading(false);
            }
            catch (e) {
                console.log(e)
                setShowModalValidation(true);
                setIsLoading(false);
                setHasErrors(true);
            }
        }
        else {
            setShowModalValidation(true);
        }

    }, []);

    const saveDocument = useCallback(async (update = false) => {
        setIsLoading(true);
        let data = {
            update,
            file: attachedFiles[0]
        }
        
        await uploadRLSPDocument(data).then(response => {
            if (response.errors) {
                let msg = response.errors[0].msg;
                setDocumentErrors(msg.includes('Line') ? msg.substring(msg.indexOf('Line')).split('\n') : [msg.substring(msg.indexOf(': ') + 1)]);
                setHasErrors(true);
            }
            else {
                setHasErrors(false);
            }

        }).catch(err => console.log(err));

        setShowDocumentTable(!update);
        if (update) {
            showAlert({ id: 'mandatoryFields', page: '/rlsp', message: translate('message.successShipUpdate'), color: "green" })
            if (!firstLoad) handleSearch();

        }
        setIsLoading(false);

    }, [attachedFiles]);

    return (
        <div className={`main-search-bar ${props.className ? props.className : ''}`}>
            <div className="search-bar-header mb-3">
                <h3>{translate('subtitle.shipmentsFilter')}</h3>
                <div className="search-bar-reset" onClick={(() => handleReset())}>
                    <Icon content="redo-alt" height="16px" type="regular" className="icon" color="#099AD6" />
                    <label>{translate('button.reset')}</label>
                </div>
            </div>
            <div className="search-bar-body">
                <Input
                    value={shipmentID}
                    onChange={(e) => handleShipmentID(e.target.value)}
                    onEnter={handleSearch}
                    label={translate('gridColumns.shipmentID')}
                    placeholder={translate('gridColumns.shipmentID')}
                    error={shipmentID !== "" && haveShipmentID && !isSearching && error}
                    errorMessage={translate('error.shipmentIDError')}
                    clear
                />

                <Input
                    value={rma}
                    onChange={(e) => handleRma(e.target.value)}
                    onEnter={handleSearch}
                    label={`${translate('title.rma')}/${translate('gridColumns.tradeInQuote')}`}
                    placeholder={`${translate('title.rma')}/${translate('gridColumns.tradeInQuote')}`}
                    error={rma !== "" && haveRma && !isSearching && error}
                    errorMessage={translate('error.noScheduledPickups')}
                    clear
                />

                <Input
                    value={trackingNumber}
                    onChange={(e) => handleTrackingNumber(e.target.value)}
                    onEnter={handleSearch}
                    label={translate('gridColumns.tracking')}
                    placeholder={translate('gridColumns.tracking')}
                    error={trackingNumber !== "" && haveTrackingNumber && !isSearching && error}
                    errorMessage={translate('error.trackingError')}
                    clear
                />
                <Select
                    value={shipmentStatusSelected}
                    onChange={handleShipmentStatus}
                    options={optionsShipmentStatus}
                    label={translate('gridColumns.shipmentStatus')}
                    placeholder={translate('gridColumns.shipmentStatus')}
                    className="mt-0 mb-3"
                />
                {(showAdvanceSearch || rlsp) &&
                    <Fragment>
                        <Checkbox
                            id={'showCalendars'}
                            value={showCalendars}
                            checked={showCalendars}
                            onClick={setShowCalendars.bind(this, !showCalendars)}
                            text={translate('formLabel.dateCreated')}
                        />
                        <div className={showCalendars ? "row" : "d-none"}>
                            <div className="col-12 col-xl-6 pr-xl-0">
                                <InputCalendar
                                    text={fromDate !== "" ? moment(fromDate).format(dateFormat) : ""}
                                    value={fromDate !== "" ? moment(fromDate).format("DD/MM/YY") : ""}
                                    onBlur={(v, m) => handleDateBegin(v, m)}
                                    maxDate={moment(toDate).toDate()}
                                    label={translate('formLabel.createdFrom')}
                                    placeholder={dateFormat}
                                    className="mt-0 mb-3 pr-1"
                                    clear
                                    paddingInput={"pl-1"}
                                />
                            </div>
                            <div className="col-12 col-xl-6">
                                <InputCalendar
                                    text={toDate !== "" ? moment(toDate).format(dateFormat) : ""}
                                    value={toDate !== "" ? moment(toDate).format("DD/MM/YY") : ""}
                                    onBlur={(v, m) => handleDateEnd(v, m)}
                                    minDate={moment(fromDate).toDate()}
                                    label={translate('formLabel.to')}
                                    placeholder={dateFormat}
                                    className="mt-0 mb-3 pr-1"
                                    clear
                                    paddingInput={"pl-1"}
                                />
                            </div>
                        </div>
                        <POWRButton
                            className="w-100 mt-16 mb-5"
                            onClick={handleSearch}
                        >
                            {translate('button.search')}
                        </POWRButton>
                        {userInfo && userInfo.allow_upload_file_rlsp &&
                            <Fragment>
                                {!isLoading ?
                                    <InfoSection type="taskBar" hideBorders className="pt-16" title={translate('placeholder.uploadFiles')}>
                                        <div className="infoBody">
                                            <Upload
                                                attachedFiles={attachedFiles}
                                                onAddFile={(file) => uploadDocument(file)}
                                                isUpload
                                                rlsp
                                            />
                                        </div>
                                    </InfoSection>
                                    :
                                    <div className="content selectPartsContainer x-centered">
                                        <Spinner size="3rem" color={'#099AD6'} className="my-5" />
                                    </div>
                                }
                            </Fragment>
                        }
                    </Fragment>
                }
            </div>


            <POWRModal
                size="long"
                show={showDocumentTable}
                title={translate('placeholder.uploadFiles')}
                onClose={!isLoading ? setShowDocumentTable.bind(this, false) : undefined} >
                {hasErrors ?
                    <p className='text-danger'> {translate('message.RowErrorsRLSP')} </p> :
                    <p> {translate('message.uploadInfo')} </p>
                }
                {documentErrors && documentErrors.length > 0 ?
                    <Fragment>
                        <div className="content shipmentsListCard">
                            {documentErrors && documentErrors.map((error, i) => {
                                return (
                                    <p key={i}>{`${error}`}</p>
                                );
                            })}
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="content">
                            <RLSPDocumentList
                                full={true}
                                tableId="shipmentsFullTable"
                                items={documentData}
                                documentErrors
                            />
                        </div>

                    </Fragment>

                }
                <div className="toolbar">
                    <POWRButton disabled={isLoading} type="button" color={hasErrors ? 'primary' : 'secondary'} onClick={() => setShowDocumentTable(false)}>{translate('button.cancel')}</POWRButton>
                    <POWRButton isLoading disabled={hasErrors || isLoading} type="button" onClick={() => saveDocument(true)}>{translate('button.upload')}</POWRButton>
                    {isLoading && <Spinner size="3rem" color={'#099AD6'} />}
                </div>

            </POWRModal>

            <POWRModal
                size="tiny"
                show={showModalValidation}
                title={translate('title.validation')}
                onClose={setShowModalValidation.bind(this, false)}  >
                <div className="content">
                    <p>{translate('message.validRLSPFile')}</p>
                </div>
                <div className="toolbar">
                    <Button onClick={setShowModalValidation.bind(this, false)} >
                        {translate('button.ok')}
                    </Button>
                </div>
            </POWRModal>
        </div>
    );
}
