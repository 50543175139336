import * as Yup from 'yup';

export const SubscriptionSchema = Yup.object().shape({
    event_type:
        Yup.string()
            .required('error.required'),
    rma_type_code:
        Yup.string(),
    event_frequency:
        Yup.string()
            .required('error.required'),
    country_code:
        Yup.string(),
    carrier_code:
        Yup.string(),
    restriction_type:
        Yup.string()
            .required('error.required')
});