import { createSelector } from 'reselect';

const shipmentsState = state => {
    return state.shipments;
}

export const selectShipments = createSelector(
    [shipmentsState],
    ({ shipments }) => shipments
);

export const selectRmaSearchText = createSelector(
    [shipmentsState],
    ({ rmaSearchText }) => rmaSearchText
);

export const selectShipmentsSearchParameters = createSelector(
    [shipmentsState],
    (shipments) => shipments.shipmentsSearchParameters
);

export const selectShipmentsSearchResults = createSelector(
    [shipmentsState],
    (shipments) => shipments.shipmentsSearchResults
);

export const selectShipmentsSearching = createSelector(
    [shipmentsState],
    (shipments) => shipments.shipmentsSearching
);

export const selectShipmentsReload = createSelector(
    [shipmentsState],
    (shipments) => shipments.shipmentsReload
);

export const selectShipmentSeleted = createSelector(
    [shipmentsState],
    (shipments) => shipments.shipmentSeleted
);

export const selectShipmentDetail = createSelector(
    [shipmentsState],
    (shipments) => shipments.shipmentDetail
);