import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setWaybillSearchParameters, setWaybillSearching } from '../core/redux/actions';
import { selectWaybillSearchResults, selectWaybillSearching } from '../core/redux/selectors';
import { useTranslate } from '../core/contexts';
import { Input, Checkbox, InputCalendar, Button } from './controls';
import { REGEXP, SEARCH_PREPRINTED_WAYBILL } from '../core/constants';
import { useAlert } from '../core/hooks';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showAlert, removeAlerts } = useAlert();
    const shipments = useSelector(selectWaybillSearchResults);
    const isSearching = useSelector(selectWaybillSearching);
    const [error, setError] = useState(false);
    const [gettingParams, setGettingParams] = useState(true);
    const [_inputdpDateBegin, setInputdpDateBegin] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [_inputdpDateEnd, setInputdpDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const [_inputtxtShipmentID, setInputtxtShipmentID] = useState("");
    const [_haveShipmentID, setHaveShipmentID] = useState(false);
    const [_inputtxtRMANumber, setInputtxtRMANumber] = useState("");
    const [_haveRMANumber, setHaveRMANumber] = useState(false);
    const [_inputtxtTracking, setInputtxtTracking] = useState("");
    const [_haveTracking, setHaveTracking] = useState(false);
    const [useDateFilter, setUseDateFilter] = useState(true);
    const dateFormat = sessionStorage.getItem('dateFormat');

    useEffect(() => {
        setError(shipments ? shipments.length < 1 : false);
    }, [shipments]);

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.search) {
            setInputtxtRMANumber(props.location.state.search);
            setParams();
        } else {
            setParams();
        }
        if (gettingParams) dispatch(setWaybillSearching(true));
    }, [props.location, gettingParams]);

    const setParams = () => {
        if (gettingParams) {
            const parameters = {
                param_ship_code: _inputtxtShipmentID,
                param_rma_or_tradein: _inputtxtRMANumber.replace(' ', ','),
                param_tn: _inputtxtTracking,
                param_use_date: useDateFilter ? 'on' : '',
                param_date_from: _inputdpDateBegin,
                param_date_to: _inputdpDateEnd,
                param_exclude_saved: 'on',
                param_max_records: 200
            };
            setGettingParams(false);
            dispatch(setWaybillSearchParameters(parameters));
        }
    }

    const handleInputtxtShipmentID = (e) => {
        setHaveShipmentID(false);
        setInputtxtShipmentID(e.target.value);
    }

    const handleInputtxtRMANumber = (e) => {
        if (REGEXP.RMAS.test(e.target.value)) {
            setHaveRMANumber(false);
            setInputtxtRMANumber(e.target.value);
        }
    }

    const handleInputtxtTracking = (e) => {
        setHaveTracking(false);
        setInputtxtTracking(e.target.value);
    }

    const handleDateBegin = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) <= new Date(_inputdpDateEnd) || _inputdpDateEnd === "" || v === "") setInputdpDateBegin(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) <= new Date(_inputdpDateEnd) || _inputdpDateEnd === "")) setInputdpDateBegin(newDate);
        } else setInputdpDateBegin(v);
    }

    const handleDateEnd = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) >= new Date(_inputdpDateBegin) || _inputdpDateBegin === "" || v === "") setInputdpDateEnd(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) >= new Date(_inputdpDateBegin) || _inputdpDateBegin === "")) setInputdpDateEnd(newDate);
        } else setInputdpDateEnd(v);
    }

    const handleSearch = function () {
        removeAlerts();
        if (useDateFilter && (!_inputdpDateBegin || !_inputdpDateEnd)) {
            if (!_inputdpDateBegin)
                showAlert({ id: new Date(), page: SEARCH_PREPRINTED_WAYBILL, message: translate('message.correctBeginDate'), color:'yellow' })
            if (!_inputdpDateEnd)
                showAlert({ id: new Date(), page: SEARCH_PREPRINTED_WAYBILL, message: translate('message.correctEndDate'), color:'yellow' })
        }
        else {
            
            setHaveShipmentID(true);
            setHaveRMANumber(true);
            setHaveTracking(true);
            setGettingParams(true);
        }

    }

    return (
        <div className="rmaControlBar">
            <div className="rmaControlBarDescription">
                <h3>{translate('subtitle.shipmentsFilter')}</h3>
            </div>
            <div className="row rmaControlBarFields">
                <div className="col-12">
                    <Input
                        value={_inputtxtShipmentID}
                        onChange={handleInputtxtShipmentID}
                        onEnter={handleSearch}
                        className="inputSpace"
                        label={translate('gridColumns.shipmentID')}
                        placeholder={translate('gridColumns.shipmentID')}
                        error={_inputtxtShipmentID !== "" && _haveShipmentID && !isSearching && error}
                        errorMessage={translate('error.shipmentIDError')}
                        clear
                    />
                </div>
                <div className="col-12">
                    <Input
                        value={_inputtxtRMANumber}
                        onChange={handleInputtxtRMANumber}
                        onEnter={handleSearch}
                        className="inputSpace"
                        label={`${translate('title.rma')} / ${translate('gridColumns.tradeInQuote')} / ${translate('gridColumns.srN')}`}
                        placeholder={`${translate('title.rma')} / ${translate('gridColumns.tradeInQuote')} / ${translate('gridColumns.srN')}`}
                        error={_inputtxtRMANumber !== "" && _haveRMANumber && !isSearching && error}
                        errorMessage={translate('error.rmaError')}
                        clear
                    />
                </div>

                <div className="col-12">
                    <Input
                        value={_inputtxtTracking}
                        onChange={handleInputtxtTracking}
                        onEnter={handleSearch}
                        className="inputSpace"
                        label={translate('gridColumns.tracking')}
                        placeholder={translate('gridColumns.tracking')}
                        error={_inputtxtTracking !== "" && _haveTracking && !isSearching && error}
                        errorMessage={translate('error.trackingError')}
                        clear
                    />
                </div>
                <div className="col-12">
                    <Checkbox
                        id={'useDateFilter'}
                        value={useDateFilter}
                        checked={useDateFilter}
                        onClick={setUseDateFilter.bind(this, !useDateFilter)}
                        text={translate('formLabel.dateCreated')}
                    />
                </div>
                <div className={useDateFilter ? "optionsSearchDates w-100" : "d-none"}>
                    <div className="row">
                        <div className="col-12 pr-4" >
                            <InputCalendar
                                text={_inputdpDateBegin !== "" ? moment(_inputdpDateBegin).format(dateFormat) : ""}
                                value={_inputdpDateBegin !== "" ? moment(_inputdpDateBegin).format("DD/MM/YY") : ""}
                                onBlur={(v, m) => handleDateBegin(v, m)}
                                maxDate={moment(_inputdpDateEnd).toDate()}
                                label={translate('formLabel.createdFrom')}
                                placeholder={dateFormat}
                                className="mb-3 pr-1"
                                clear
                            /></div>
                        <div className="col-12 pr-4">
                            <InputCalendar
                                text={_inputdpDateEnd !== "" ? moment(_inputdpDateEnd).format(dateFormat) : ""}
                                value={_inputdpDateEnd !== "" ? moment(_inputdpDateEnd).format("DD/MM/YY") : ""}
                                onBlur={(v, m) => handleDateEnd(v, m)}
                                minDate={moment(_inputdpDateBegin).toDate()}
                                label={translate('formLabel.to')}
                                placeholder={dateFormat}
                                className="mb-3 pr-1"
                                clear
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div>
                {/* <div className="bottomButton"> */}
                <Button onClick={handleSearch} style={{ width: '100%', margin: 0 }}>
                    {translate('button.search')}
                </Button>
            </div>
        </div>
    );
}