import {
    RESET_RMA_INFO, SET_RMA_INFO, RESET_LABEL_ONLY,
    SET_RMA_SEARCH_PARAMETERS,
    SET_RMA_SEARCH_RESULTS,
    SET_RMA_SEARCHING,
    SET_RETURN_ADDRESS,
    SET_IS_FA,
    SET_GSTIN,
    SET_RMAS
} from '../actionTypes';

const initialState = {
    rmas: [],
    shipmentId: null,
    labelOnly: false,
    turkeyRevenue: false,
    rmaSearchParameters: null,
    rmaSearchResults: [],
    rmaSearching: false,
    isFA: false
};

export const rma = (state = initialState, action) => {
    switch (action.type) {
        case SET_RMA_INFO: return {
            ...state,
            ...action.payload
        };
        case RESET_LABEL_ONLY:
            return {
                ...state,
                labelOnly: action.payload
            };
        case RESET_RMA_INFO: return { ...initialState };
        case SET_RMA_SEARCH_PARAMETERS: return {
            ...state,
            rmaSearchParameters: action.payload
        };
        case SET_RMA_SEARCH_RESULTS: return {
            ...state,
            rmaSearchResults: action.payload
        };
        case SET_RMA_SEARCHING: return {
            ...state,
            rmaSearching: action.payload
        };
        case SET_RETURN_ADDRESS: return {
            ...state,
            rmas: state.rmas.map(x => ({ ...x, return_address: action.payload }))
        };
        case SET_GSTIN: return {
            ...state,
            rmas: state.rmas.map(x => ({ ...x, gstin: action.payload }))
        };
        case SET_IS_FA: return {
            ...state,
            isFA: action.payload
        };
        case SET_RMAS: return {
            ...state,
            rmas: action.payload
        };
        default:
            return state;
    }
};
