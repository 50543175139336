import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { getShipments } from "../../services";
import { Link } from "../../components/controls";
import { useTranslate } from "../../core/contexts";
import { ShipmentsList } from "../../components/shared";
import { SEARCH_SHIPMENTS } from "../../core/constants/ROUTES";

const ShipmentsHome = (props) => {
    const { translate } = useTranslate();
    const [shipments, setShipments] = useState([]);

    useEffect(() => {
        const parameters = {
            param_ship_code: "",
            param_rma_or_tradein: "",
            param_tn: "",
            param_date_from: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            param_date_to: moment().format('YYYY-MM-DD'),
            param_ship_status: "",
            param_max_records: 2,
            param_exclude_saved: 'on',
            param_only_mine: 'on',
        };
        getShipments(parameters).then(response => {
            let resStr = response.res ? typeof (response.res) === 'string' ?
                response.res.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                : response.res : { dtos: [] };
            let dtos = typeof (resStr) === 'string' ? JSON.parse(resStr).dtos : resStr.dtos;
            dtos = dtos.slice(0, 2);
            setShipments(dtos);
        }).catch(err => {
        });
    }, []);

    return (
        <Fragment>
            {/* (GPT-3 automatically generated documentation)
             This code creates a new div with the class name "createdShipments" and then 
             creates a new div with the class name "created-shipments" inside of it. 
             The "created-shipments" div contains a link to the "viewAll" page and a heading with the text "title.createdShipments".
               */}
            {shipments.length > 0 && (
                <Fragment>
                    <div className="created-shipments align-items-center pb-2">
                        <h2 className="m-0">{translate("title.createdShipments")}</h2>
                        <Link to={SEARCH_SHIPMENTS}>{translate("title.viewAll")}</Link>
                    </div>
                    <ShipmentsList items={shipments} full={false} home />
                </Fragment>
            )}
        </Fragment>
    );
};

export default ShipmentsHome;