import moment from 'moment';
import { REGEXP } from '../constants';

export const formatDate = (dateFormat) => {
    let newDate = '';
    dateFormat.split('').forEach(el => {
        switch (el) {
            case 'F':
                newDate += 'MMMM';
              break;
            case 'j':
                newDate += 'DD';
                break;
            case 'd':
                newDate += 'DD';
                break;
            case 'Y':
                newDate += 'YYYY';
                break;
            case 'g':
                newDate += 'h';
                break;
            case 'i':
                newDate += 'mm';
                break;
            case 'm':
                newDate += 'MM';
                break;
            case 'y':
                newDate += 'YY';
                break;
            case 'n':
                newDate += 'M';
                break;
            case 'h':
                newDate += 'HH';
                break;
            case 'G':
                newDate += 'HH';
                break;
            case 's':
                newDate += 'ss';
                break;
            case 'jS':
                newDate += 'Do';
                break;
            case 'D':
                newDate += 'ddd';
                break;
            case 'M':
                newDate += 'MMM';
                break;
            default:
                newDate += el;
          }
    });

    return newDate;
}

export const convertDateFormat = (date) => {
    var st = date.substring(0, 8);
    return st.replace(REGEXP.DATE, '$1-$2-$3')
}

export const browserDate = () => {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    var localeData = moment.localeData();
    var date = localeData.longDateFormat('L');
    var time = localeData.longDateFormat('LTS');
    
    return { date, time };
}