import React, { useEffect, useState, useRef, Fragment } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useTranslate } from "../core/contexts";
import { POWRButton, POWRModal } from "./styledComponents";
import { useAlert, useCommercialInvoice } from "../core/hooks";
import { selectRTFFlowProducts, selectRTFFlowEOR, selectRTFFlowRMA, selectRefNumberFlowRMA } from "../core/redux/selectors";
import { setRTFFlowDefaultOptions } from "../core/redux/actions";
import { Formik, Form } from "formik";
import { TextInput, InputCalendar } from "./controls";
import { ShipInfoRTFSchema } from "../core/schemas";
import { BASE_URL_API, URL_PRINT_RTF } from "../core/constants/URLS";
import { RTFCOMMERCIALINVOICE, DASHBOARD } from "../core/constants/ROUTES";
import { CommercialInvoiceForm } from './forms/commercialInvoiceForm';
import { FORM_TYPE, REGEXP } from "../core/constants";
import { isValidPackageProp } from '../core/helpers/formatUnits';

const RTFSideBar = props => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { showAlert, removeAlert } = useAlert();
    const { handleCommercialInvoiceRTF,
        print,
        modalTemplate,
        setModalTemplate,
    } = useCommercialInvoice({});
    const history = useHistory();
    const dateFormat = sessionStorage.getItem('dateFormat');
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [docGenerated, setDocGenerated] = useState(false);
    const eor = useSelector(selectRTFFlowEOR);
    const lines = useSelector(selectRTFFlowProducts);
    const rma = useSelector(selectRTFFlowRMA);
    const refNumber = useSelector(selectRefNumberFlowRMA);
    const formRef = useRef();
    const intialValues = {
        contactName: '',
        contactPhone: '',
        carrier: '',
        awb: '',
        totalPackages: '',
        totalNetWeight: '',
        totalGrossWeight: '',
        shipDate: moment().format('YYYY-MM-DD')

    }

    useEffect(() => {
        dispatch(setRTFFlowDefaultOptions());
    },[]);
    
    useEffect(() => {
        if (lines && lines.length === 0) {
            dispatch(setRTFFlowDefaultOptions());
            if (formRef.current)
                formRef.current.resetForm();
        }
    }, [lines, dispatch]);

    const handleSubmit = async () => {
        if (formRef.current) {
            formRef.current.submit();
        }

    }

    const generateDocument = (values, { setSubmitting }) => {
        const params = { lines, eor, shipInfo: { ...values, referenceNumber: refNumber, shipDate: moment(values.shipDate, 'YYYY-MM-DD').format('YYYYMMDD') } };
        handleCommercialInvoiceRTF(rma,refNumber, params, FORM_TYPE.RTF);
        setDocGenerated(true);
    }

    const handleDate = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (v === "") return v;
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date") return newDate;
        } else return v;
    }

    const handleCancel = () => {
        dispatch(setRTFFlowDefaultOptions());
        setShowCancelModal(false)
        formRef.current.resetForm();
        history.push(DASHBOARD);
    }

    const updateWeight = (value, isBlur) => {
        let v = String(value).replace(/[^0-9\.]/g, "");

        const newPropertyValue = !isBlur ? v : parseFloat(v).toFixed(2);

        if (!isValidPackageProp(newPropertyValue, 'weight')) {
            let splittedValue = String(newPropertyValue).includes('.') ? String(newPropertyValue).split('.') : [String(newPropertyValue), ''];
            let resInt = splittedValue[0].length > 5 ? splittedValue[0].slice(0, 5) : splittedValue[0]; 
            let resDec = splittedValue[1] ? splittedValue[1].length > 2 ? splittedValue[1].slice(0, 2) : splittedValue[1] : '';
            return `${resInt}${resDec ? '.' : ''}${resDec}`;
        } 

        if (!isBlur) return newPropertyValue; 
        else {
            if (value === '' || isNaN(value))return '0.00'
            else return newPropertyValue;
            
        }
    };
    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        removeAlert('mandatoryFields')
        e.preventDefault();
        let result = await validateForm(values);
        
        if (!Object.keys(result).length) {
           
            handleSubmit();
        }
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: RTFCOMMERCIALINVOICE, message: translate('message.mandatoryFields'), color: "yellow"})
        }
    };

    return (

        <div className="rmaControlBar">
            {lines && lines.length > 0 &&
                <Fragment>
                    <div className="rmaControlBarDescription border-none">
                        <h3>{translate('title.shipInfo')}</h3>
                    </div>
                    <div>

                    </div>
                    <Formik
                        initialValues={intialValues}
                        enableReinitialize
                        onSubmit={generateDocument}
                        validationSchema={ShipInfoRTFSchema}
                    >{({ handleSubmit, isSubmitting, values, setFieldValue, getFieldMeta, validateForm, setTouched, setErrors }) => (
                            <Form ref={formRef} onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} className="form-rtf-content">
                            <div>
                                    <TextInput
                                        type="text"
                                        isRequired
                                        name={'contactName'}
                                        label={'formLabel.contactName'}
                                        placeholder={translate('formLabel.contactName')}
                                        maxLength={'255'}
                                        inputClass="mt-4 pb-0"
                                    />

                                    <TextInput
                                        type="text"
                                        isRequired
                                        name="contactPhone"
                                        label={translate("formLabel.contactTelephone")}
                                        maxLength="255"
                                        inputClass="pb-0"
                                        placeholder={translate('formLabel.contactTelephone')}
                                    />

                                    <TextInput
                                        type="text"
                                        isRequired
                                        name="carrier"
                                        label="formLabel.shipCarrier"
                                        placeholder={translate("formLabel.shipCarrier")}
                                        maxLength="255"
                                        inputClass="pb-0"
                                    />

                                    <TextInput
                                        type="text"
                                        isRequired
                                        name="awb"
                                        label="formLabel.awb"
                                        placeholder={translate("formLabel.awb")}
                                        maxLength="255"
                                        inputClass="pb-0"
                                    />

                                    <TextInput
                                        type="text"
                                        isRequired
                                        name="totalPackages"
                                        label="formLabel.totalPackages"
                                        placeholder={translate("formLabel.totalPackages")}
                                        maxLength="255"
                                        inputClass="pb-0"
                                        onBlur={(e) => {
                                            setFieldValue('totalPackages', e.target.value.replace(/^0+/, ''));
                                        }}
                                        onChange={(e) => {
                                            if (REGEXP.NUMBERS.test(e.target.value))
                                                setFieldValue("totalPackages", e.target.value)
                                        }}
                                    />

                                    <TextInput
                                        type="text"
                                        isRequired
                                        name="totalNetWeight"
                                        label="formLabel.totalWeight"
                                        placeholder={translate("formLabel.totalWeight")}
                                        maxLength="255"
                                        inputClass="pb-0"
                                        // onBlur={(e) => {
                                        //     let value = e.target.value.replace(/^0+/, ''); // Remove leading zeros
                                        //     if (value.endsWith('.')) {
                                        //         value = value.slice(0, -1); // Remove trailing dot
                                        //     }
                                        //     setFieldValue('totalNetWeight', value);
                                        // }}
                                        onBlur={(e) => {
                                            let res = updateWeight(e.target.value, true);
                                            setFieldValue('totalNetWeight', res);
                                        }}
                                        onChange={(e) => {
                                            let res = updateWeight(e.target.value, false);
                                            setFieldValue('totalNetWeight', res);
                                            
                                        }}
                                    />

                                    <TextInput
                                        type="text"
                                        isRequired
                                        name="totalGrossWeight"
                                        label="formLabel.totalGrossWeight"
                                        placeholder={translate("formLabel.totalGrossWeight")}
                                        maxLength="255"
                                        inputClass="pb-0"
                                        onBlur={(e) => {
                                            let res = updateWeight(e.target.value, true);
                                            setFieldValue('totalGrossWeight', res);
                                        }}
                                        onChange={(e) => {
                                            let res = updateWeight(e.target.value, false);
                                            setFieldValue('totalGrossWeight', res);

                                        }}
                                    />
                                    <InputCalendar
                                        text={values.shipDate !== "" ? moment(values.shipDate).format(dateFormat) : ""}
                                        value={values.shipDate !== "" ? moment(values.shipDate).format("DD/MM/YY") : ""}
                                        onBlur={(v, m) => {
                                            let value = handleDate(v, m);
                                            setFieldValue('shipDate', value, true);

                                        }}
                                        label={translate('formLabel.shipDate')}
                                        placeholder={dateFormat}
                                        clear
                                        error={getFieldMeta('shipDate').touched && getFieldMeta('shipDate').error}
                                        formInput
                                        lblClass={'label-form-calendar'}
                                        mandatory
                                    />
                            </div>
                        </Form>
                    )}
                    </Formik>

                    <div className='btnContainer'>
                        <POWRButton className="widthBtn mb-2" onClick={() => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}>{translate("button.createCI")}</POWRButton>
                    {!docGenerated ?
                         <POWRButton className="widthBtn" color="secondary" onClick={() => { setShowCancelModal(true) }}>{translate("button.cancel")}</POWRButton>:
                         <POWRButton className="widthBtn" color="secondary" onClick={() => { setShowCancelModal(true) }}>{translate("button.finish")}</POWRButton>
                    }
                    </div>

                </Fragment>
            }
            <POWRModal
                show={showCancelModal}
                size="small"
                title={translate(`title.${docGenerated ? 'finishCI': 'cancelCI'}`)}
                onClose={() => setShowCancelModal(false)}
                alignActions="center"
            >
                <div className="content">
                    {translate(`message.${docGenerated ? 'finishCI': 'cancelCI'}`)}
                </div>
                <div className="toolbar" >
                    <POWRButton onClick={() => setShowCancelModal(false)} color="secondary" >
                        {translate('button.no')}
                    </POWRButton>
                    <POWRButton
                        onClick={handleCancel}
                    >
                        {translate('button.yes')}
                    </POWRButton>
                </div>
            </POWRModal>

            {modalTemplate != null &&
                <POWRModal
                    show={true}
                    title={translate(`title.${FORM_TYPE.COMMERCIAL_INVOICE}Form`)}
                    size="long"
                    onClose={() => setModalTemplate(null)}
                >
                    <CommercialInvoiceForm
                        onClose={() => setModalTemplate(null)}
                        lines={modalTemplate.data}
                        print={print}
                        type={modalTemplate.type}
                        rtfInfo={modalTemplate.rtfInfo}
                    />
                </POWRModal>
            }
        </div>
    )
}
export default RTFSideBar;
