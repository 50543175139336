import {
    DASHBOARD,
    SEARCH_SHIPMENTS,
    SEARCH_RMA,
    SEARCH_PREPRINTED_WAYBILL,
    DOCUMENTATION,
    DOWNLOAD,
    RLSP,
    RTFCOMMERCIALINVOICE,
    MENU_REPORTS
} from '../constants/ROUTES';

export const MAIN_NAVIGATION = [
    {
        id: "title.homeMenu",
        icon: "home",
        to: DASHBOARD,
        description: null,
    },
    {
        id: "title.rmaSearch",
        icon: "file-signature",
        to: SEARCH_RMA,
        description: "message.rmaSearchDesc",
    },
    {
        id: "aux.downloadCenter",
        icon: "file-download",
        to: DOWNLOAD,
        description: "message.downloadCenterDesc",
    },
    {
        id: "title.shipmentSearch",
        icon: "search",
        to: SEARCH_SHIPMENTS,
        description: "message.shipmentSearchDesc",
    },
    {
        id: "title.factReturns",
        icon: "exchange-alt",
        to: `${SEARCH_RMA}/FA`,
        description: "message.factReturnsDesc",
    },
    {
        id: "title.RTFCI",
        icon: "industry-alt",
        to: `${RTFCOMMERCIALINVOICE}`,
        description: "message.rtfDesc",
    },
    {
        id: "title.documentReports",
        icon: "file-invoice",
        to: `${MENU_REPORTS}`,
        description: "message.docReportsDesc",
    },
    {
        id: "title.documentationAccessindiaOnly",
        icon: "folders",
        to: DOCUMENTATION,
        description: "message.documentationAccessDesc",
    },
    {
        id: "title.preprintedWaybillusCanadaOnly",
        icon: "barcode",
        to: SEARCH_PREPRINTED_WAYBILL,
        description: "message.preprintedWaybillDesc",
    },
    {
        id: "title.rlspEditor",
        icon: "mail-bulk",
        location: '',
        to: RLSP,
        description: null
    },
    {
        id: "title.logout",
        icon: "sign-out",
        to: 'logout',
        description: null,
    },
];

export const FOOTER_NAVIGATION = [
    {
        id: "footer.qsguide",
        to: 'QSGuideLink', 
    },
    {
      id: "footer.faq",
      to: 'POWRFaqLink',
    },
    {
      id: "footer.customerService",
      to: 'SupportReturnsLink',
    },
    // {
    //   id: "footer.giveFeedback",
    //   to: "/",
    //   modal: "feedback"
    // },
    {
      id: "footer.privacyStatements",
      to: 'LegalLink',
    },
    {
      id: "footer.cookies",
      to: 'CookiesLink',
    },
  ]