import {
    SET_DEFAULT_OPTIONS, SET_SELECTION_ENTITY, ADD_ADDRESS_BOOK_ENTITY,
    UPDATE_ADDRESS_BOOK_ENTITY, REMOVE_SELECTION_ENTITY, SWITCH_CONTACTS, COPY_REQUESTOR,
    SAVE_SHIPMENT_DATA, RESET_RETURN_FLOW, RESET_PARTS, SET_PACKAGES, UPDATE_PACKAGE,
    SET_SUB_LINES, RESET_PACKAGES, UPDATE_SUB_LINE, SET_RETURN_FLOW_PROP, SET_CREATE_PACKAGES_STEP,
    SET_SAVED_SUB_LINES, COPY_PICKUP_CONTACT, RESET_SUB_LINES_PACKAGES, SET_BILLING_ADDRESS, SET_LINES_ERROR
} from "../actionTypes";
import returnFlowSteps from '../../../data/returnFlow.json';

const initialState = {
    // STEPS
    steps: returnFlowSteps.flow,
    currentStep: 0,
    scheduleStep: 0,
    createPackagesStep: 0,
    showSublinesPkReset: false,
    createPackagesError: false,
    // RMA ADDRESS BOOK 
    userAddressBook: {
        pickup: [],
        requestor: [],
        pickupContact: [],
        alternateContact: [],
        billingAddress:{}
    },
    pickupId: null,
    requestorId: null,
    pickupContactId: null,
    alternateContactId: null,
    // SUBLINES
    subLines: [],
    selectedLines: [],
    selectedSubLines: [],
    oldSubLines: [],
    oldSelectedSubLines: [],
    // PACKAGES
    packages: [],
    oldPackages: [],
    // SAVED INFO
    additional: '',
    shipmentId: null, // ID OF SET PICKUP SAVE IS NOT A SHIP_ID
    weight_uom: null,
    length_uom: null,
    schedulePickup: null, // SCHEDULE PICKUP LOCALLY SAVED INFO
    attachedFiles: [],
    savedSublines: [],
    // FORM HELPERS
    saveRMASForms: false,
    RMASForm1Valid: false,
    RMASForm2Valid: false,
    RMASForm3Valid: false,
    RMASForm4Valid: false,
    RMASavedID: null,
    pickupCountry: '',
    specialInstrucLimit: 150,
    updateRMA: false,
    zoneInstructions: '',
    linesError: []
};

export const returnFlow = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_RETURN_FLOW_PROP:
            return {
                ...state,
                [payload.propName]: payload.value
            };
        case SET_CREATE_PACKAGES_STEP:
            return {
                ...state,
                createPackagesStep: payload !== undefined ? payload : (!state.createPackagesStep ? 1 : 0)
            };
        case SET_DEFAULT_OPTIONS:
            return {
                ...state,
                userAddressBook: {
                    pickup: payload.pickup.map((x) => ({ ...x, save_as: x.ctag })),
                    requestor: payload.requestor.map((x) => ({ ...x, save_as: x.ctag })),
                    pickupContact: payload.onsite.map((x) => ({ ...x, save_as: x.ctag })),
                    alternateContact: payload.local.map((x) => ({ ...x, save_as: x.ctag }))
                },
                pickupId: payload.pickup.length ? payload.pickup[0].idd : null,
                requestorId: payload.requestor.length ? payload.requestor[0].idd : null,
                pickupContactId: payload.onsite.length ? payload.onsite[0].idd : null,
                alternateContactId: payload.local.length ? payload.local[0].idd : null
            };
        case SET_SELECTION_ENTITY: return { ...state, [`${payload.entity}Id`]: payload.id };
        case SWITCH_CONTACTS: return {
            ...state,
            userAddressBook: {
                ...state.userAddressBook,
                pickupContact: [
                    state.userAddressBook.alternateContact.find(x => x.idd === state.alternateContactId),
                    ...state.userAddressBook.pickupContact
                ]
            },
            pickupContactId: state.alternateContactId,
            alternateContactId: null
        };
        case COPY_REQUESTOR:
            return {
                ...state,
                userAddressBook: {
                    ...state.userAddressBook,
                    pickupContact: [
                        state.userAddressBook.requestor.find(x => x.idd === state.requestorId),
                        ...state.userAddressBook.pickupContact
                    ]
                },
                pickupContactId: state.requestorId
            };
        case COPY_PICKUP_CONTACT:
            return {
                ...state,
                userAddressBook: {
                    ...state.userAddressBook,
                    alternateContact: [
                        state.userAddressBook.pickupContact.find(x => x.idd === state.pickupContactId),
                        ...state.userAddressBook.alternateContact
                    ]
                },
                alternateContactId: state.pickupContactId
            }
        case UPDATE_ADDRESS_BOOK_ENTITY:
            return {
                ...state,
                userAddressBook: {
                    ...state.userAddressBook,
                    [payload.entity]: state.userAddressBook[payload.entity].map((x) => x.idd === payload.data.idd ?
                        { ...x, ...payload.data } : x)
                },
                [`${payload.entity}Id`]: payload.data.idd
            };
        case ADD_ADDRESS_BOOK_ENTITY:
            return {
                ...state,
                userAddressBook: {
                    ...state.userAddressBook,
                    [payload.entity]: [payload.data, ...state.userAddressBook[payload.entity]]
                },
                [`${payload.entity}Id`]: payload.data.idd
            };
        case REMOVE_SELECTION_ENTITY: return { ...state, [`${payload.entity}Id`]: null };
        case SAVE_SHIPMENT_DATA:
            return {
                ...state,
                shipmentId: payload.shipmentId,
                additional: payload.additionalInfo,
                weight_uom: payload.weight_uom,
                length_uom: payload.length_uom,
                radio: payload.radio
            };
        case RESET_RETURN_FLOW: return initialState;
        case RESET_PARTS: return { ...state, selectedLines: [], subLines: state.subLines.map(x => ({ ...x, picked: true })) };
        case SET_PACKAGES: return { ...state, packages: payload.packages, ...(payload.usedOnly ? { oldPackages: payload.packages } : {}) };
        case UPDATE_PACKAGE: return {
            ...state,
            packages: state.packages.map((x) => (x.idd === payload.id) ? { ...x, [payload.property]: payload.value } : x)
        };
        case RESET_SUB_LINES_PACKAGES: return { ...state, subLines: state.subLines.map(x => ({ ...x, package_id: null, package_code: '', pack_num: null })) };
        case SET_SUB_LINES: return {
            ...state,
            subLines: payload.map(x => {
                const currentSubline = state.subLines.find(sb => sb.idd === x.idd);
                const hasPackageAssigned = currentSubline && currentSubline.package_id;
                const isNewResponse = x.picked === undefined; // "picked === undefined" MEANS THAT THIS IS A NEW SERVICE RESPONSE

                let picked = true; // TO CALCULATE PICKED QTY
                if (state.subLines.length) { // REDUX ALREADY HAS SUBLINES STORED 
                    if (isNewResponse) {
                        const isSelected = state.selectedLines.find(ln => ln.rma === x.rma_num && ln.line === x.rma_line);
                        picked = isSelected ? (currentSubline ? currentSubline.picked : false) : true;
                    }
                    else picked = x.picked; // ITS A LOCAL CHANGE AT ONE OF THE SUBLINES
                }

                return {
                    ...x,
                    picked: picked,
                    ...(!x.serials ? { serials: x.serial ? x.serial.split(',') : [] } : {}),
                    package_id: (hasPackageAssigned && isNewResponse) ? currentSubline.package_id : (x.package_id || null),
                    pack_num: (hasPackageAssigned && isNewResponse) ? currentSubline.pack_num : (x.pack_num || null),
                    package_code: (hasPackageAssigned && isNewResponse) ? currentSubline.package_code : (x.package_code || null)
                }
            })
        };
        case SET_SAVED_SUB_LINES: return {
            ...state,
            subLines: payload.map((sl) => ({
                ...sl,
                picked: sl.picked !== undefined ? sl.picked : true
            }))
        };
        case UPDATE_SUB_LINE: return {
            ...state,
            subLines: state.subLines.map((x) => (x.idd === payload.id) ? { ...x, [payload.property]: payload.value } : x)
        };
        case RESET_PACKAGES: return { ...state, packages: state.oldPackages };
        case SET_BILLING_ADDRESS: 
            return { 
            ...state, 
            userAddressBook: {
                ...state.userAddressBook,
                billingAddress:payload
            },
            };
        case SET_LINES_ERROR: return {
            ...state,
            linesError: [
                ...payload.filter(item => !state.linesError.includes(item))
            ]
        };

        default:
            return state;
    }
};
