import styled from 'styled-components';

export const POWRLink = styled.a`
    font-family: 'CiscoSansTT-Regular';
    font-size: 0.875rem;
    cursor: pointer;
    color: #099AD6 !important;
    &:focus, &:active, &:hover {
        text-decoration: underline !important;
        color: #005073 !important;
    }
`;