import React from "react";
import styled from "styled-components";

const Svg = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

export const CiscoLogo = ({ className, width, height, color = "#099ad6" }) => (
  <Svg
    height={height}
    width={width}
    className={className}
    viewBox="0 0 60 35"
    fill={color}
  >
    <path d="m17.1 22.6h2.5v11.9h-2.5v-11.9z" />
    <path d="m41.7 26.1c-0.1-0.1-1-0.6-2.4-0.6-1.8 0-3.1 1.3-3.1 3.1 0 1.7 1.2 3.1 3.1 3.1 1.3 0 2.3-0.5 2.4-0.6v3.1c-0.4 0.1-1.3 0.4-2.6 0.4-3.2 0-6-2.3-6-6 0-3.4 2.5-6 6-6 1.3 0 2.3 0.3 2.6 0.4v3.1" />
    <path d="m13.4 26.1c-0.1-0.1-1-0.6-2.4-0.6-1.8 0-3.1 1.3-3.1 3.1 0 1.7 1.2 3.1 3.1 3.1 1.3 0 2.3-0.5 2.4-0.6v3.1c-0.4 0.1-1.3 0.4-2.6 0.4-3.2 0-6-2.3-6-6 0-3.4 2.5-6 6-6 1.3 0 2.3 0.3 2.6 0.4v3.1" />
    <path d="m49.7 25.5c-1.6 0-2.7 1.4-2.7 3 0 1.7 1.2 3 2.7 3 1.6 0 2.7-1.4 2.7-3 0.1-1.6-1.1-3-2.7-3zm5.6 3c0 3.3-2.3 6-5.5 6s-5.5-2.7-5.5-6 2.3-6 5.5-6c3.1 0.1 5.5 2.7 5.5 6z" />
    <path d="m29.9 25.4s-1.2-0.4-2.1-0.4c-1.1 0-1.7 0.4-1.7 0.9 0 0.7 0.8 0.9 1.2 1.1l0.7 0.3c1.8 0.6 2.6 1.9 2.6 3.3 0 2.9-2.4 3.9-4.4 3.9-1.4 0-2.8-0.3-2.9-0.3v-2.7c0.2 0.1 1.4 0.4 2.6 0.4 1.3 0 2-0.4 2-1.1 0-0.6-0.5-0.9-1.2-1.1-0.2-0.1-0.4-0.1-0.6-0.2-1.5-0.5-2.8-1.5-2.8-3.4 0-2.2 1.5-3.6 4-3.6 1.3 0 2.6 0.3 2.6 0.4v2.5" />
    <path d="m2.4 10.6c0-0.7-0.5-1.3-1.2-1.3-0.7 0-1.2 0.6-1.2 1.3v2.7c0 0.7 0.6 1.3 1.2 1.3 0.7 0 1.2-0.6 1.2-1.3v-2.7" />
    <path d="m9.7 6.7c0-0.7-0.6-1.4-1.2-1.4-0.7 0-1.2 0.6-1.2 1.4v6.6c0 0.8 0.6 1.4 1.2 1.4 0.7 0 1.2-0.6 1.2-1.4v-6.6" />
    <path d="m17.1 1.5c0-0.8-0.6-1.5-1.2-1.5-0.7 0-1.2 0.7-1.2 1.5v15.6c0 0.8 0.6 1.5 1.2 1.5 0.7 0 1.2-0.7 1.2-1.5v-15.6" />
    <path d="m24.5 6.7c0-0.7-0.6-1.4-1.2-1.4-0.7 0-1.2 0.6-1.2 1.4v6.6c0 0.8 0.6 1.4 1.2 1.4 0.7 0 1.2-0.6 1.2-1.4v-6.6" />
    <path d="m31.9 10.6c0-0.7-0.6-1.3-1.2-1.3-0.7 0-1.2 0.6-1.2 1.3v2.7c0 0.7 0.6 1.3 1.2 1.3 0.7 0 1.2-0.6 1.2-1.3v-2.7" />
    <path d="m39.3 6.7c0-0.7-0.6-1.4-1.2-1.4-0.7 0-1.2 0.6-1.2 1.4v6.6c0 0.8 0.5 1.4 1.2 1.4 0.7 0 1.2-0.6 1.2-1.4v-6.6" />
    <path d="m46.6 1.5c0-0.8-0.5-1.5-1.2-1.5-0.7 0-1.2 0.7-1.2 1.5v15.6c0 0.8 0.6 1.5 1.2 1.5 0.7 0 1.2-0.7 1.2-1.5v-15.6" />
    <path d="m52.8 6.7c0-0.7-0.5-1.4-1.2-1.4-0.7 0-1.2 0.6-1.2 1.4v6.6c0 0.8 0.5 1.4 1.2 1.4 0.7 0 1.2-0.6 1.2-1.4v-6.6" />
    <path d="m60.2 10.6c0-0.7-0.6-1.3-1.2-1.3-0.7 0-1.2 0.6-1.2 1.3v2.7c0 0.7 0.5 1.3 1.2 1.3 0.7 0 1.2-0.6 1.2-1.3v-2.7" />
  </Svg>
);
