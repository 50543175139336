import { createSelector } from "reselect";
import { capitalize } from "../../helpers";
import { CONTACT_TYPES } from '../../constants';

const selectContacts = (state) => state.contacts;

export const selectUserContacts = createSelector(
    selectContacts,
    (contactsState) => contactsState.contacts.map((ct) => {
        const fullName = `${capitalize(ct.fname)} ${capitalize(ct.lname)}`;
        const { key } = CONTACT_TYPES.find(x => x.type === Number(ct.type));
        return {
            ...ct,
            save_as: ct.save_as || ct.ctag,
            header: `contactType.${key}`,
            title: ct.save_as || ct.ctag || fullName,
            info: [fullName, ct.email, ct.phone],
            idd: ct.idd
        };
    })
);

export const selectSelectedContact = createSelector(
    selectContacts,
    (contactsState) => contactsState.contacts.find(ct => ct.idd === contactsState.selectedContact)
);