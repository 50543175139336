export const RFC_TAX_REGIME =
    [
        {
            code: "605",
            meaning: "Wages and Salaries and Income Assimilated to Salaries",
            description: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
            tag: "D04",
            tagDescription: "Donativos"
        },
        {
            code: "601",
            meaning: "General Legal Persons Law",
            description: "General de Ley Personas Morales",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "606",
            meaning: "Lease",
            description: "Arrendamiento",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "607",
            meaning: "Property Disposal or Acquisition Regime",
            description: "Régimen de Enajenación o Adquisición de Bienes",
            tag: "D04",
            tagDescription: "Donativos"
        },
        {
            code: "608",
            meaning: "other income",
            description: "Demás ingresos",
            tag: "D04",
            tagDescription: "Donativos"
        },
        {
            code: "610",
            meaning: "Residents Abroad without Permanent Establishment in Mexico",
            description: "Residentes en el Extranjero sin Establecimiento Permanente en México",
            tag: "S01",
            tagDescription: "Sin efectos fiscales"
        },
        {
            code: "611",
            meaning: "Dividend Income (partners and shareholders)",
            description: "Ingresos por Dividendos (socios y accionistas)",
            tag: "D04",
            tagDescription: "Donativos"
        },
        {
            code: "612",
            meaning: "Individuals with Business and Professional Activities",
            description: "Personas Físicas con Actividades Empresariales y Profesionales",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "615",
            meaning: "Income regime for obtaining prizes",
            description: "Régimen de los ingresos por obtención de premios",
            tag: "D04",
            tagDescription: "Donativos"
        },
        {
            code: "625",
            meaning: "Regime of Business Activities with income through Technological Platforms",
            description: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "616",
            meaning: "No tax obligations",
            description: "Sin obligaciones fiscales",
            tag: "S01",
            tagDescription: "Sin efectos fiscales"
        },
        {
            code: "626",
            meaning: "Simplified Trust Regime",
            description: "Régimen Simplificado de Confianza",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "620",
            meaning: "Production Cooperative Societies that choose to defer their income",
            description: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "621",
            meaning: "Tax Incorporation",
            description: "Incorporación Fiscal",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "622",
            meaning: "Agricultural, Livestock, Forestry and Fishing Activities",
            description: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "623",
            meaning: "Optional for Groups of Companies",
            description: "Opcional para Grupos de Sociedades",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "624",
            meaning: "Coordinated",
            description: "Coordinados",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "603",
            meaning: "Legal Entities with Non-Profit Purposes",
            description: "Personas Morales con Fines no Lucrativos",
            tag: "G01",
            tagDescription: "Adquisición de mercancías"
        },
        {
            code: "614",
            meaning: "Interest income",
            description: "Ingresos por intereses",
            tag: "D04",
            tagDescription: "Donativos"
        }
    ]


export const RFC_CFDI =
    [
        {
            code: "G01",
            description: "Adquisición de mercancías"
        },
        {
            code: "G02",
            description: "Devoluciones, descuentos o bonificaciones"
        },
        {
            code: "G03",
            description: "Gastos en general"
        },
        {
            code: "I01",
            description: "Construcciones"
        },
        {
            code: "I02",
            description: "Mobiliario y equipo de oficina por inversiones"
        },
        {
            code: "I03",
            description: "Equipo de transporte"
        },
        {
            code: "I04",
            description: "Equipo de computo y accesorios"
        },
        {
            code: "I05",
            description: "Dados, troqueles, moldes, matrices y herramental"
        },
        {
            code: "I06",
            description: "Comunicaciones telefónicas"
        },
        {
            code: "I07",
            description: "Comunicaciones satelitales"
        },
        {
            code: "I08",
            description: "Otra maquinaria y equipo"
        },
        {
            code: "D01",
            description: "Honorarios médicos, dentales y gastos hospitalarios"
        },
        {
            code: "D02",
            description: "Gastos médicos por incapacidad o discapacidad"
        },
        {
            code: "D03",
            description: "Gastos funerales"
        },
        {
            code: "D04",
            description: "Donativos"
        },
        {
            code: "D05",
            description: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)"
        },
        {
            code: "D06",
            description: "Aportaciones voluntarias al SAR"
        },
        {
            code: "D07",
            description: "Primas por seguros de gastos médicos"
        },
        {
            code: "D08",
            description: "Gastos de transportación escolar obligatoria"
        },
        {
            code: "D09",
            description: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones"
        },
        {
            code: "D10",
            description: "Pagos por servicios educativos (colegiaturas)"
        },
        {
            code: "S01",
            description: "Sin efectos fiscales  "
        },
        {
            code: "CP01",
            description: "Pagos"
        },
        {
            code: "CN01",
            description: "Nómina"
        }
    ]