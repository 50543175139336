import React, { useState} from 'react';
import moment from 'moment';
import { useTranslate } from '../../../core/contexts';
import { Status } from '../../controls';
import { getShipmentStatus } from '../../../core/constants/ShipmentsStatus';
import { COLOR } from '../../../core/constants/Colors.js';
import { POWRIcon,POWRModal } from '../../styledComponents';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { setShipmentSeleted } from '../../../core/redux/actions';

const ShipmentsTable = (props) => {
    const dispatch = useDispatch();
    const { items, allItems, setAllItems, handlePages, errors } = props;
    const { translate } = useTranslate();
    const [order, setOrder] = useState(0);
    const [reverse, setReverse] = useState(false);
    const dateFormat = sessionStorage.getItem('dateFormat');
    
    const handleOrder = (o, value) => {
        dispatch(setShipmentSeleted({}));
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(true);
        }
        setOrder(o);

        let r = order === o ? !reverse : true;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        let newOrder = allItems.sort(compare);
        setAllItems(newOrder);
        handlePages(newOrder);
    }
    
    const columns = [
        { id: 0, value: 'ship_code', type: 'tex', text: translate('gridColumns.shipmentID') },
        { id: 1, value: 'carrier_description', type: 'tex', text: translate('formLabel.shipCarrier') },
        { id: 2, value: 'tns', text: translate('gridColumns.tracking') },
        { id: 3, value: 'ship_event', type: 'tex', text: translate('gridColumns.shipStatus') },
        { id: 4, value: 'carrier_description', type: 'tex', text: translate('formLabel.carrier') },
        { id: 5, value: 'agreedPickupDate', type: 'tex', text: translate('gridColumns.agreedPickupDate') },
  
        
    ];

    return (
        <Fragment>
            <table className="d-none d-lg-table">
                <thead>
                    <tr className="shipmentsTitles">
                        {columns.map((c, i) => {
                            return !(props.full && c.id === 9)&& (
                                <th
                                    className="shipmentsTH"
                                    onClick={handleOrder.bind(this, i, c.value)}
                                    key={`column-${i}-${reverse}-of-${order}`} >
                                    <div className="shipmentsTitle">
                                        {c.text}
                                        {c.id !== 8 &&
                                            <POWRIcon
                                                className={`far fa-${order === i && reverse ? 'arrow-up' : 'arrow-down'} ml-2`}
                                                size="10px"
                                                color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                        }
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => {
                        return (
                            <tr
                            className={"shipmentRow shipment-document" }
                            key={`shipment-${i}`}>
                            <td>
                                <div className="td boldType">{item.ship_code}</div>
                            </td>
                            
                            <td>
                                <div className="td">{item.carrier_code}</div>
                            </td>
                            
                            <td key={`tnslbl-${item.idd}`}>
                                <div className="td" title={item.tns}>{item.tns}</div>
                            </td>
                            <td key={`status-${item.ship_event}_order-${order}_reverse-${reverse}`}>
                                     <div className="td" title={item.tns}>{item.ship_event}</div>
                            </td>
                            <td>
                                <div className="td" title={item.carrier_description}>
                                    {item.carrier_msg}
                                </div>
                            </td>
                                <td key={`read--${item.agreed_pickup_dt}`}>
                                    <div className="td" title={item.agreed_pickup_dt}>
                                        {(item.agreed_pickup_dt && item.agreed_pickup_dt instanceof Date) ? `${item.agreed_pickup_dt.getUTCMonth() + 1}/${item.agreed_pickup_dt.getUTCDate()}/${item.agreed_pickup_dt.getFullYear()}`:item.agreed_pickup_dt}
                                    </div>
                                </td>
                        </tr>
                        );
                    })
                    }
                </tbody>
            </table>
            <div className="d-block d-lg-none">
                {items.map((item, i) => {
                    return (
                        <div key={`shipment-${i}`} className="mini-shipment">
                            <div className="col-10 pl-0">
                                <div className="row columns">
                                    <div className="col main">{item.ship_code}</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="light">{`${translate('formLabel.carrier')}: `}</span>
                                        <span className="normal">{item.carrier_code}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="light">{`${translate('gridColumns.tracking')}: `}</span>
                                        <span className="normal">{item.tns}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="light">{`${translate('gridColumns.shipStatus')}: `}</span>
                                        <span className="normal">{item.ship_event}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="light">{`${translate('formLabel.carrier')}: `}</span>
                                        <span className="normal">{item.carrier_msg}</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="light">{`${translate('gridColumns.agreedPickupDate')}: `}</span>
                                        <span className="normal">  {(item.agreed_pickup_dt && item.agreed_pickup_dt instanceof Date) ? `${item.agreed_pickup_dt.getUTCMonth() + 1}/${item.agreed_pickup_dt.getUTCDate()}/${item.agreed_pickup_dt.getFullYear()}`:item.agreed_pickup_dt}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
               
                    )
                })
                }
            </div>

        </Fragment>
    );
}

export default ShipmentsTable;