import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Link, Select } from '../controls';
import { POWRButton, POWRIcon } from '../styledComponents';
import { SubscriptionSchema } from '../../core/schemas';
import { useTranslate } from '../../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountries, selectEventCarriers, selectRMATypes, selectUserEvents } from '../../core/redux/selectors';
import { loadCountries, loadEventCarriers, loadRMATypes, saveEvent } from '../../core/redux/actions';
import { RMA_SHIP_EVENT_TYPES } from '../../core/constants';
import { EVENT_FREQUENCY_TYPES } from '../../core/constants/EVENT_FREQUENCY_TYPE';
import { EVENT_RESTRICTION_TYPES } from '../../core/constants/EVENT_RESTRICTION_TYPE';
import { useAlert } from '../../core/hooks';
import { COLOR } from '../../core/constants/Colors';

export const SubscriptionForm = ({ onClose, isAdd, data, onDelete }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({
        event_type: '',
        rma_type_code: '',
        event_frequency: '',
        country_code: '',
        carrier_code: '',
        restriction_type: ''
    });
    const { showAlert } = useAlert();
    const [types, setTypes] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [restrictions, setRestrictions] = useState([]);
    const [dupError, setDupError] = useState(false);

    const countries = useSelector(selectCountries);
    const rmaTypes = useSelector(selectRMATypes);
    const carriers = useSelector(selectEventCarriers);
    const currentEvents = useSelector(selectUserEvents);

    useEffect(() => {
        if (!types.length) setTypes(RMA_SHIP_EVENT_TYPES.filter(x => x.type !== 0).map(x => ({
            text: translate(`eventType.${x.key}`) || '—', code: String(x.type)
        })));
        if (!frequencies.length) setFrequencies(EVENT_FREQUENCY_TYPES.filter(x => x.type !== 0).map(x => ({
            text: translate(`frequencyType.${x.key}`) || '—', code: String(x.type)
        })));
        if (!restrictions.length) setRestrictions(EVENT_RESTRICTION_TYPES.filter(x => x.type !== 0).map(x => ({
            text: translate(`restrictionType.${x.key}`) || '—', code: String(x.type)
        })));
    }, [translate, types, frequencies, restrictions]);

    useEffect(()=>{
        dispatch(loadCountries(true));
    },[])

    useEffect(() => {
        if (rmaTypes.length === 0) dispatch(loadRMATypes());
        if (carriers.length === 0) dispatch(loadEventCarriers());
    }, [countries, rmaTypes, carriers, dispatch]);

    useEffect(() => {
        setInitialValues({ ...initialValues, ...((!isAdd && data) ? data : {}) });
    }, [data, isAdd]);

    const validateDuplicates = useCallback((values) => {
        let duplicated = false;
        for (let ev of currentEvents) {
            let exists = true;
            for (let propName in values) {
                let val = values[propName]
                if (val === '') val = 'ALL';
                if (ev[propName] !== val) exists = false;
            }
            duplicated = exists;
            if (duplicated) break;
        }
        setDupError(duplicated);
        return duplicated;
    }, [currentEvents]);

    const save = (values, { setSubmitting }) => {
        if (!validateDuplicates(values)) {
            let newSubscription = {
                ...(isAdd ? {} : data),
                ...values,
            };
            dispatch(saveEvent({ json: { dtos: [newSubscription] } }, isAdd));
            setTimeout(() => {
                setSubmitting(false);
                onClose();
            }, 400);
        }
        else setSubmitting(false);
    };

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        e.preventDefault();
        let result = await validateForm(values);
        if (!Object.keys(result).length) handleSubmit();
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow", isModal: true })
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={SubscriptionSchema}
            onSubmit={save}>
            {
                ({ handleSubmit, isSubmitting, values, setFieldValue, getFieldMeta, validateForm, setTouched, setErrors }) => (
                    <form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.event_type}
                                        text={(values.event_type && types.length) ? types.find(x => x.code === values.event_type).text : ''}
                                        onChange={(val) => setFieldValue('event_type', val, false)}
                                        options={types}
                                        className="inputSpace"
                                        singleSelection mandatory
                                        error={getFieldMeta('event_type').touched && getFieldMeta('event_type').error}
                                        label={translate('gridColumns.eventType')} />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.rma_type_code}
                                        text={(values.rma_type_code && rmaTypes.length) ? rmaTypes.find(x => x.code === values.rma_type_code).text : ''}
                                        onChange={(val) => setFieldValue('rma_type_code', val, false)}
                                        options={rmaTypes}
                                        className="inputSpace"
                                        singleSelection
                                        error={getFieldMeta('rma_type_code').touched && getFieldMeta('rma_type_code').error}
                                        label={translate('gridColumns.rmaType')} />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.event_frequency}
                                        text={(values.event_frequency && frequencies.length) ? frequencies.find(x => x.code === values.event_frequency).text : ''}
                                        onChange={(val) => setFieldValue('event_frequency', val, false)}
                                        options={frequencies}
                                        className="inputSpace"
                                        singleSelection mandatory
                                        error={getFieldMeta('event_frequency').touched && getFieldMeta('event_frequency').error}
                                        label={translate('gridColumns.frequency')} />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.country_code}
                                        text={(values.country && countries.length) ? countries.find(x => x.code === values.country).text : ''}
                                        onChange={(val) => setFieldValue('country_code', val, false)}
                                        options={countries}
                                        className="inputSpace"
                                        singleSelection
                                        error={getFieldMeta('country_code').touched && getFieldMeta('country_code').error}
                                        label={translate('formLabel.country')} />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.carrier_code}
                                        text={(values.carrier_code && carriers.length) ? carriers.find(x => x.code === values.carrier_code).text : ''}
                                        onChange={(val) => setFieldValue('carrier_code', val, false)}
                                        options={carriers}
                                        className="inputSpace"
                                        singleSelection
                                        error={getFieldMeta('carrier_code').touched && getFieldMeta('carrier_code').error}
                                        label={translate('gridColumns.carrier')} />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.restriction_type}
                                        text={(values.restriction_type && restrictions.length) ? restrictions.find(x => x.code === values.restriction_type).text : ''}
                                        onChange={(val) => setFieldValue('restriction_type', val, false)}
                                        options={restrictions}
                                        className="inputSpace"
                                        singleSelection mandatory
                                        error={getFieldMeta('restriction_type').touched && getFieldMeta('restriction_type').error}
                                        label={translate('gridColumns.restriction')} />
                                </div>
                                {
                                    dupError &&
                                    <div className="col-12 mt-3">
                                        <label className="errorMessage">{translate('error.duplicatedEvent')}</label>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`toolbar ${!isAdd ? 'd-flex flex-column flex-xl-row justify-content-between' : ''}`}>
                            {
                                !isAdd &&
                                <Link onClick={onDelete} >
                                    <span className="m-0">
                                        <POWRIcon className="fas fa-trash-alt" size=".7rem" color={COLOR.CISCO_BLUE} />
                                    </span>
                                    {translate('button.remove')}
                                </Link>
                            }
                            <div className="d-flex pt-3 pt-xl-0">
                                <POWRButton type="button" color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                                <POWRButton type="submit" disabled={isSubmitting}>{translate('button.save')}</POWRButton>
                            </div>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
};
