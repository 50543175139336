import React, { useCallback, useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Formik, Form } from 'formik';
import { TextInput, Select,InfoTooltip } from '../controls';
import { POWRButton } from '../styledComponents';
import { PickupLocationRMASchema } from '../../core/schemas';
import { useTranslate } from '../../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBaseRMAInfo, selectCountries, selectCountriesSerbiaMontenegro,
    selectReturnFlowOptions, selectPickupCountry, selectSpecialInstrucLimit
} from '../../core/redux/selectors';
import { addPickupAddress, loadCountries, setReturnFlowProp, updatePickupAddress } from '../../core/redux/actions';
import { useAlert, usePickupLocationValidation } from '../../core/hooks';
import { COUNTRY } from '../../core/constants';
import  * as allCountryStates from '../../core/constants/CountriesStates';

export const PickupLocationRMAForm = forwardRef(({ data }, ref) => {
    const { translate } = useTranslate();
    const { handleMaxLengthPostalCode } = usePickupLocationValidation();
    const dispatch = useDispatch();
    const refSubmit = useRef();
    const { showAlert, removeAlert } = useAlert();
    const [initialValues, setInitialValues] = useState({
        name: '',
        addr1: '',
        addr2: '',
        addr3: '',
        addr4: '',
        country: '',
        province: '',
        city: '',
        postal: '',
        save_as: '',
        zipRequired: false,
        zipFormat: '',
        site_directions: '',
        gstin: '',
        isStateListEnable: false
    });
    const [localData, setLocalData] = useState(null);
    const [additional, setAdditional] = useState('');
    const [changing, setChanging] = useState(false);
    const [countryStateLst,setCountryStateLst] = useState([])

    const addressBook = useSelector(selectReturnFlowOptions);
    const countries = useSelector(data && (data.isCS) ? selectCountriesSerbiaMontenegro : selectCountries);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const pickupCountry = useSelector(selectPickupCountry);
    const instructionLimit = useSelector(selectSpecialInstrucLimit);

    useEffect(() => {
        if (countries.length === 0) dispatch(loadCountries(true));
    }, []);

    useEffect(() => {
        setLocalData(data);
    }, [data]);

    useEffect(() => {
        if (pickupCountry) {
            let specialInstrucLimit = countries && countries.find(x => x.code === pickupCountry)
            dispatch(setReturnFlowProp('specialInstrucLimit', specialInstrucLimit && specialInstrucLimit.site_instructions_max_length));
            if (additional.length > specialInstrucLimit) {
                dispatch(setReturnFlowProp('additional', additional.substr(0, specialInstrucLimit)));
                setAdditional(additional.substr(0, specialInstrucLimit));
                setChanging(true);
            }
        }
    }, [pickupCountry]);

    useEffect(() => {
        const ctr = localData ? countries.find(x => x.code === localData.country) : null;
        let newValues = {
            ...initialValues,
            ...(localData ? localData : {}),
            ...(localData && ctr ? {country:ctr.code} : {country:""}),
            ...(ctr ? { zipRequired: ctr.post_code_required, zipFormat: ctr.post_code_fmt_required ? ctr.post_code_fmt_regexp : '',isStateListEnable :ctr.states_list_enable?ctr.states_list_enable:false } : {}),
            ...((addressBook.pickup[0] && addressBook.pickup[0].country === COUNTRY.INDIA && (!localData || !localData.gstin)) ? ({ gstin: (rmaInfo && rmaInfo.gstin) || '' }) : {})
        };
        setInitialValues(newValues);
        if (localData) {
            checkInitialErrors(newValues);
            getCountryStateLst(newValues.country);
        }
    }, [localData, countries, rmaInfo]);

    const getSelectedCountryName = useCallback((countryCode) => {
        if (countryCode && countries.length) {
            const ctr = countries.find(x => x.code === countryCode)
            return ctr ? ctr.text : '';
        }
        else return '';
    }, [countries]);

    const checkInitialErrors = async (values) => {
        let isValid = await PickupLocationRMASchema.isValid(values)
        if (!isValid) refSubmit.current.click();
    }

    const save = (values, { setSubmitting }) => {
        let newAddress = {
            ...(localData ? localData : {}),
            ...values,
            ...{ atag: values.save_as }
        };
        newAddress.postal = handleMaxLengthPostalCode(values.postal)
        delete newAddress['zipRequired'];
        delete newAddress['zipFormat'];
        if (!localData) dispatch(addPickupAddress(newAddress));
        else dispatch(updatePickupAddress(newAddress));
        dispatch(setReturnFlowProp('RMASForm1Valid', true));
        dispatch(setReturnFlowProp('additional', values.site_directions));
        setTimeout(() => {
            setSubmitting(false);
        }, 400);
    };

    useImperativeHandle(ref, () => ({
        saveAll() {
            refSubmit.current.click();
        }
    }));

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        removeAlert('mandatoryFields')
        e.preventDefault();
        if (changing) {
            setInitialValues({...values, site_directions: additional})
            setChanging(false);
        } else {
            let result = await validateForm(values);
            if (!Object.keys(result).length) handleSubmit();
            else {
                dispatch(setReturnFlowProp('RMASForm1Valid', false));
                dispatch(setReturnFlowProp('saveRMASForms', false));
                setTouched(result);
                setErrors(result);
                showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow" })
            }
        }
    };

    useEffect(() => {
        if (changing) refSubmit.current.click();
    }, [changing])

    const getCountryStateLst = (countryCode) => {
        setCountryStateLst([]);
        if(allCountryStates[countryCode]!==undefined){
            setCountryStateLst(allCountryStates[countryCode])}       
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={PickupLocationRMASchema}
            enableReinitialize
            onSubmit={save}>
            {
                ({ handleSubmit, isSubmitting, values, setFieldValue, getFieldMeta, validateForm, setTouched, setErrors }) => (
                    <Form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'name'} label={'formLabel.company'}
                                        placeholder={translate('placeholder.eCompanyName')} maxLength="255" />
                                </div>
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'save_as'} label={'formLabel.site'}
                                        placeholder={translate('placeholder.eSideID')} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'addr1'} label={'formLabel.address1'}
                                        placeholder={translate('placeholder.address1')} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'addr2'} label={'formLabel.address2'}
                                        placeholder={translate('placeholder.address2')} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'addr3'} label={'formLabel.address3'} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'addr4'} label={'formLabel.address4'} maxLength="255" />
                                </div>
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.country}
                                        text={getSelectedCountryName(values.country)}
                                        onChange={(val) => {
                                            getCountryStateLst(val)
                                            const ctr = countries.find(x => x.code === val);
                                            if (ctr) {
                                                setFieldValue('zipRequired', ctr.post_code_required, true);
                                                setFieldValue('zipFormat', ctr.post_code_fmt_required ? ctr.post_code_fmt_regexp : '', true);
                                                setFieldValue('isStateListEnable', ctr.states_list_enable, true);       
                                            }
                                            setFieldValue('country', val, true);
                                            dispatch(setReturnFlowProp('pickupCountry', val));
                                        }}
                                        options={countries}
                                        className="inputSpace"
                                        singleSelection mandatory
                                        placeholder={translate('placeholder.sCountry')}
                                        error={getFieldMeta('country').touched && getFieldMeta('country').error}
                                        label={translate('formLabel.country')} />
                                </div>
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    {values.isStateListEnable && countryStateLst.length !== 0 &&
                                        <Select
                                            options={countryStateLst.map((obj) => {
                                                return {
                                                    code: obj.code,
                                                    text: obj.name + ' (' + obj.code + ')'
                                                }
                                            })}
                                            value={values.province}
                                            className="inputSpace"
                                            onChange={(val) => { setFieldValue('province', val, true); }}
                                            singleSelection mandatory
                                            label={translate('formLabel.state')} />
                                    }
                                    {(!values.isStateListEnable || (values.isStateListEnable && countryStateLst.length === 0)) &&
                                        <TextInput type="text" isRequired name={'province'} label={'formLabel.state'}
                                            placeholder={translate('placeholder.eProvince')} maxLength="255" />
                                    }
                                </div>
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'city'} label={'formLabel.city'}
                                        placeholder={translate('placeholder.eCity')} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired={values.zipRequired} name={'postal'} label={'formLabel.zip'}
                                        placeholder={translate('placeholder.ePostalCode')} maxLength="25" 
                                        customIcon={values.zipFormat ?
                                            <InfoTooltip>{translate('message.postalFormat')} {countries.find(x => x.code === values.country) ?
                                                countries.find(x => x.code === values.country).post_code_fmt : ''}</InfoTooltip> : null} />
                                </div>
                                <div className="col-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'site_directions'} key={`site_directions-${instructionLimit}`}
                                        label={'formLabel.specialInstructions'} placeholder={translate('placeholder.specialInstructions')}
                                        maxLength={`${instructionLimit}`} onChange={(e) => {
                                            setFieldValue('site_directions', e.target.value, true);
                                            setAdditional(e.target.value);
                                        }} />
                                </div>
                                {
                                    (values.country === COUNTRY.INDIA) &&
                                    <div className="col-md-6 px-3 mb-0 form-group">
                                        <TextInput type="text" name={'gstin'} label={'formLabel.gstin'} maxLength="255" />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none">
                            <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.save')}</POWRButton>
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
});