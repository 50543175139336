export const EVENT_RESTRICTION_TYPE = {
    EVENT_RESTRICTION_TYPE_UNKNOWN: 0,
    EVENT_RESTRICTION_TYPE_MY_SHIPMENTS: 1,
    EVENT_RESTRICTION_TYPE_DATA_ACCESS: 2
};

export const EVENT_RESTRICTION_TYPES = [
    {
        type: 0,
        key: 'unknown'
    },
    {
        type: 1,
        key: 'myShipments'
    },
    {
        type: 2,
        key: 'dataAccess'
    }
];
