import {
    SET_WAYBILL,
    SET_WAYBILL_SEARCH_PARAMETERS,
    SET_WAYBILL_SEARCH_RESULTS,
    SET_WAYBILL_SEARCHING
} from '../actionTypes';

export const setWaybill = (data) => {
    return {
        type: SET_WAYBILL,
        payload: data
    };
}

export const setWaybillSearchParameters = (data) => ({
    type: SET_WAYBILL_SEARCH_PARAMETERS,
    payload: data
});

export const setWaybillSearchResults = (data) => ({
    type: SET_WAYBILL_SEARCH_RESULTS,
    payload: data
});

export const setWaybillSearching = (data) => ({
    type: SET_WAYBILL_SEARCHING,
    payload: data
});