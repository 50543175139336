import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { DropDown, Pagination, Icon, Input, InfoTooltip, Status } from './index';
import { useTranslate } from '../../core/contexts';
import { POWRIcon } from '../styledComponents';
import '../../styles/components/controls/_table.scss';
import { COLOR } from '../../core/constants/Colors';

export const Table = memo(({ columns, dataSource, itemsPerPage, actions, noPaging, type, confirmationModal }) => {
    const { translate } = useTranslate();
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [items, setItems] = useState([]);
    const [showLines, setShowLines] = useState(false);
    const [sortProp, setSortProp] = useState({ name: columns[0].key, dir: false });
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        if (noPaging) {
            setItems(dataSource);
        } else {
            let newItems = page === 1 ?
                sortedData.slice(0, itemsPerPage) :
                sortedData.slice((page * itemsPerPage) - itemsPerPage, page * itemsPerPage);
            setItems(newItems);
            let nPages = Math.ceil(sortedData.length / itemsPerPage);
            let ps = [];
            for (var i = 1; i <= nPages; i++) {
                ps.push(i);
            }
            setPages(ps);
        }
    }, [sortedData, page, itemsPerPage]);

    useEffect(() => {
        let dtos = dataSource ? noPaging ? [dataSource[0], dataSource[1]] : dataSource : [];
        if (!noPaging && dtos) {
            let temp = [...dataSource];
            setSortedData(temp.sort((a, b) => {
                let temp1 = a[sortProp.name], temp2 = b[sortProp.name];
                if (typeof (a[sortProp.name]) === 'string') {
                    temp1 = temp1.toLowerCase();
                    temp2 = temp2.toLowerCase();
                }
                if (sortProp.dir ? temp2 > temp1 : temp1 > temp2) return 1;
                else return -1;
            }));
        } else {
            setSortedData(dtos);
        }
    }, [dataSource, sortProp]);

    const handleBlurInput = () => {
        if (confirmationModal) {
            confirmationModal();
        }
    }

    const getTemplate = useCallback((data, column) => {
        return !column.template ?
            <div className="td generic">{data[column.key]}</div>
            :
            (<Fragment>
                {column.template === 'bold' &&
                    <div className="td boldType text-left">{data[column.key]}</div>}
                {column.template === 'withTranslation' &&
                    <div className="td generic">{translate(data[column.key])}</div>}
                {column.template === 'withAll' &&
                    <div className="td generic">
                        {data[column.key] === 'ALL' ? translate('aux.all') : data[column.key]}
                    </div>}
                {column.template === 'actions' &&
                    <div className="td actionsDropDown">
                        <DropDown options={actions.map((act) => ({
                            ...act,
                            handler: () => act.handler(data)
                        }))} />
                    </div>}
                {column.template === 'icon' &&
                    <div className="td text-left">
                        {data[column.key] &&
                            <Fragment>
                                <Icon content={column.icon.name} width="14px" height="14px" color="#00A0DF" className="mr-3" />
                                {`${column.icon.tag ? (translate(column.icon.tag) + ':') : ''} ${data[column.key] || ''}`}
                            </Fragment>
                        }
                    </div>}
                {column.template === 'input' &&
                    <div className="td text-center inputContainer">
                        <Input
                            className="inputTable"
                            onBlur={() => handleBlurInput()}
                            onEnter={() => handleBlurInput()}
                            placeholder={translate(column.placeholder)}
                        />
                    </div>}
                {column.template === 'info' &&
                    <div className="td infoContainer">
                        {data[column.key]}
                        <div className="infoCircle">
                            <InfoTooltip>{data[column.info]}</InfoTooltip>
                        </div>
                    </div>}
                {column.template === 'unit' &&
                    <div className="td">{data[column.key] || '1'}</div>}
                {column.template === 'status' &&
                    <Status className="td" status={data[column.key]} />
                }
                {column.template === 'commaArray' &&
                    <div className="td generic py-1">
                        {
                            data[column.key] ?
                                data[column.key].split(',').map((x, i) => <Fragment key={`pkserial-${i}`}>{'- ' + x} <br /></Fragment>)
                                : ''
                        }
                    </div>
                }
            </Fragment>)
    }, []);

    const changeSort = (name) => setSortProp({ name, dir: sortProp.name === name ? !sortProp.dir : false });

    return (
        <Fragment>
            <table>
                <thead>
                    <tr className={type !== "list" ? "table-headers" : "table-headers noBorders"} >
                        {type == 'collapse' && <th></th>}
                        {columns.map((column, i) => (
                            <th className={`table-header`}
                                key={`column-header-${column.key}-${i}`}>
                                {
                                    column.tag ?
                                        <div className={`title ${column.headerClasses || 'text-left'}`}
                                            onClick={() => changeSort(column.key)}>
                                            {translate(`gridColumns.${column.tag}`)}
                                            {type !== "list" && (sortProp.name === column.key && sortProp.dir) &&
                                                <span><POWRIcon className={`far fa-arrow-up ml-2`} size=".7rem" color={'#099AD6'} /></span>}
                                            {type !== "list" && (sortProp.name === column.key && !sortProp.dir) &&
                                                <span><POWRIcon className={`far fa-arrow-down ml-2`} size=".7rem" color={'#099AD6'} /></span>}
                                            {type !== "list" && sortProp.name !== column.key &&
                                                <span><POWRIcon className={`fal fa-arrow-up ml-2`} size=".7rem" color={'#a6a5a5'} /></span>}
                                        </div>
                                        :
                                        <div></div>
                                }
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {type === "collapse" ?
                        items.map((item, i) => {
                            let itemHead = item.head;
                            return (
                                <Fragment key={`headeRow-${i}`}>
                                    <tr
                                        onClick={setShowLines.bind(this, !showLines)}
                                        key={`row-content-head-${item.id}-${i}`}
                                        className="table-row" >
                                        <td key={`showLines-${showLines}`} >
                                            <POWRIcon
                                                className={`fas fa-${showLines ? "caret-down" : "caret-right"} ml-1 showLinesIcon`}
                                                color={COLOR.CISCO_BLUE}
                                                size="0.6rem" />
                                        </td>
                                        {
                                            columns.map((column, j) => (
                                                <td key={`cell-${column.key || 'actions'}-content-${item.id}-${j}`}>
                                                    {getTemplate(itemHead, column)}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    {showLines && item && item.lines && item.lines.length ? item.lines.map((line, a) => (
                                        <tr key={`row-content-line-${item.id}-${a}`} className="table-row">
                                            <td></td>
                                            {
                                                columns.map((column, b) => (
                                                    <td key={`cell-${column.key || 'actions'}-content-line-${item.id}-${b}`}>
                                                        {getTemplate(line, column)}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    )) : <tr />}
                                </Fragment>
                            );
                        })
                        :
                        items.map((item, i) => (
                            <tr key={`row-content-${i}`} className={type !== "list" ? "table-row" : "table-row noBorders"}>
                                {
                                    columns.map((column, j) => (
                                        <td key={`cell-${column.key || 'actions'}-content-${j}`}>
                                            {getTemplate(item, column)}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {!noPaging && <Pagination
                itemsPerPage={itemsPerPage}
                items={items}
                setItems={setItems}
                pages={pages}
                page={page}
                setPage={setPage}
                allItems={dataSource}
            />}
        </Fragment>

    );
}, (prev, next) => prev.columns === next.columns
    && prev.dataSource === next.dataSource
    && prev.itemsPerPage === next.itemsPerPage)
