import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRButton, POWRModal } from "../styledComponents";
import { InfoTooltip, Select, TextInput } from "../controls";
import { FieldArray, Form, Formik } from 'formik';
import { CommercialInvoiceSchema, FaultDetectionAndActExchangeSchema } from '../../core/schemas';
import { HelpActOfExchangeModal, HelpCommercialInvoiceModal, HelpFaultDetectionModal } from '../modals';
import { saveCommercialInvoice, saveFaultDetection, saveActOfExchange } from '../../services/shipTreeService';
import { COLOR, FORM_TYPE, COUNTRY_OF_ORIGIN } from '../../core/constants';
import { useAlert } from '../../core/hooks';
import { useDispatch } from 'react-redux';
import pictureCOO from '../../static/img/pictureCOO.png';


export const CommercialInvoiceForm = ({ onClose, lines, print, type, shipCode, fromPrintAll = false, shipId, fromSummary, rtfInfo = null}) => {

    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const dispatch = useDispatch();
    const [confirmation, setConfirmation] = useState(false);
    const [country, setCountry] = useState('');
    const [initialValues, setInitialValues] = useState({});
    const [isCommercialInvoice, setIsCommercialInvoice] = useState(false);
    const [isFaultDetection, setIsFaultDetection] = useState(false);
    const [isActOfExchange, setIsActOfExchange] = useState(false);
    const [schemaForm, setSchemaForm] = useState(CommercialInvoiceSchema);
    const refSubmit = useRef();
    const countries = COUNTRY_OF_ORIGIN;

    const ALPHANUMERIC = /[^a-zA-Z0-9-= ]/g;
    const NUMERIC = /[^0-9]/g;

    useEffect(() => {
        if (fromSummary && refSubmit && initialValues){
              let {handleSubmit, validateForm, values, setTouched, setErrors} = refSubmit.current;
            customSubmit1(refSubmit.current, handleSubmit, validateForm, values, setTouched, setErrors)
            
        }
    }, [initialValues, refSubmit])

    const customSubmit1 = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
      
        let result = await validateForm(initialValues);
        if (!Object.keys(result).length) handleSubmit();
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow", isModal: true })
        }
    }


    useEffect(() => {
        setInitialValues({ lines: lines });
    }, [lines])

    useEffect(() => {
       
        switch (type) {
            case FORM_TYPE.COMMERCIAL_INVOICE:
            case FORM_TYPE.PACKING_LIST:
            case FORM_TYPE.PACKING_LIST_FR:
            case FORM_TYPE.COMMERCIAL_INVOICE_CH:
            case FORM_TYPE.SLI:
            case FORM_TYPE.ZIP:
            case FORM_TYPE.RTF:
                setIsCommercialInvoice(!isCommercialInvoice);
                break;
            case FORM_TYPE.FAULT_DETECTION:
                setIsFaultDetection(!isFaultDetection);
                setSchemaForm(FaultDetectionAndActExchangeSchema);
                break;
            case FORM_TYPE.ACT_OF_EXCHANGE:
                setIsActOfExchange(!isActOfExchange);
                setSchemaForm(FaultDetectionAndActExchangeSchema);
                break;
            default:
                break;
        }
    }, [type])

    const save = async (values) => {
        if (type === FORM_TYPE.RTF) { 
            let rtfLines = [];
            values.lines.forEach(line => {
                rtfLines.push({
                    id: line.idd,
                    partDescr: line.part_desc,
                    partNum: line.part_num,
                    returnQty: line.returnQty,
                    serialNumber: line.serial,
                    cisco_line_id: line.ciscoLineId,
                    part_weight: line.partWeight,
                    part_weight_uom: line.partWeightUom,
                    request_date: line.reqDate,
                    originCountry: countries.find(x => x.name === line.country_origin || x.code === line.country_origin)?.name || ""
                })})
            onClose();
            print(FORM_TYPE.RTF, null, { lines: rtfLines, rtfInfo });
            return;
        }
        let linesCI = [];
        values.lines.map((line) => {
            let saveLine = {
                idd: line.idd,
                ship_code: shipCode,
                rma_num: line.rma_num,
                part_num: line.part_num,
                serial: line.serial,
                part_desc: line.part_desc.replace(ALPHANUMERIC, '')
            }
            // The services need different values to save data
            if (isCommercialInvoice) {
                saveLine['country_origin'] = countries.find(x => x.name === line.country_origin || x.code === line.country_origin)?.name || "";
                saveLine['hts_code'] = line.hts_code;
                saveLine['eccn_code'] = line.eccn_code;
            } else if (isFaultDetection) {
                saveLine['country_origin'] = countries.find(x => x.name === line.country_origin || x.code === line.country_origin)?.name || "";
                saveLine['failure_desc'] = line.failure_desc//.replace(ALPHANUMERIC, '');
            } else if (isActOfExchange) {
                saveLine['quantity'] = typeof line.quantity == String ? Number(line.quantity.replace(NUMERIC, '')) : Number(line.quantity);
                saveLine['failure_desc'] = line.failure_desc//.replace(ALPHANUMERIC, '');
            }
            linesCI.push(saveLine);
        });
        let data = { json: { dtos: linesCI } };
        if (isCommercialInvoice) {
            await   saveCommercialInvoice(data).then(() => {
                onClose();
                print();
            });
        } else if (isFaultDetection) {
            saveFaultDetection(data).then(() => {
                onClose();
                print();
            });
        } else if (isActOfExchange) {
            saveActOfExchange(data).then(() => {
                onClose();
                print();
            });
        }
    }

    const prompCopyModal = (country_origin, values, i) => {
        let updateValues = { ...values };
        updateValues.lines[i].country_origin = country_origin;

        if (country_origin.length >= 2 && updateValues.lines.length > 1) {
            if (canAutoFill(updateValues.lines)) {
                setCountry(country_origin);
                setConfirmation(!confirmation);
            }
        }
    }

    const canAutoFill = (values) => {
        let hasDiff = values.find((val) => val.country_origin === '');
        if (hasDiff) return true;
        return false;
    }

    const copyToAllFields = (values, validateForm) => {
        values.lines.map((value, i) => {
            if (value.country_origin === '') values.lines[i].country_origin = country;
        })
        validateForm(values);
        setConfirmation(!confirmation);
    }

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        e.preventDefault();
        let result = await validateForm(values);
        if (!Object.keys(result).length) handleSubmit();
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow", isModal: true })
        }
    }

    return (
        <Formik
            innerRef={refSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={schemaForm}
            onSubmit={save}
            validateOnBlur={true}
            validateOnChange={true}
        >
            {({ values, handleBlur, validateForm, handleSubmit, setTouched, setErrors, getFieldMeta, setFieldValue }) => (
                <Form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} className="content p-0 overflow-hidden">
                    <div className="content">
                        <table className="d-none d-lg-table table table-form">
                            <thead>
                                <tr>
                                    <th scope="col">{translate(`gridColumns.seqnum`)}</th>
                                    <th scope="col">{translate(`gridColumns.rma`)}</th>
                                    <th scope="col">{translate(`gridColumns.partNumber`)}</th>
                                    <th scope="col">{translate(`gridColumns.serialNumber`)}</th>
                                    {/* POWR2 and services doesn't use country_origin for act of exchange */}
                                    {!isActOfExchange && <th scope="col">{translate(`gridColumns.countryOrigin`)}{isCommercialInvoice ? ' *' : ''}</th>}
                                    {isActOfExchange && <th scope="col">{translate(`gridColumns.qty`)}</th>}
                                    {isFaultDetection && <th scope="col">{translate(`gridColumns.failureDescription`)}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="lines">
                                    {() => (
                                        <Fragment>
                                            {values.lines && values.lines.length > 0 ?
                                                values.lines.map((line, index) => (
                                                    <tr key={index} scope="row">
                                                        <td>
                                                            <p>{line.seq}</p>
                                                        </td>
                                                        <td>
                                                            <p>{line.rma_num}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex">
                                                                {/* part_num_editable always comes as true, so it was decided to no allow edit */}
                                                                {/* {line.part_num_editable ?

                                                                    <TextInput
                                                                        type="text"
                                                                        name={`lines[${index}].part_num`}
                                                                        placeholder={translate(`gridColumns.partNumber`)}
                                                                        maxLength="100"
                                                                    />
                                                                    :
                                                                    <p>{line.part_num}</p>
                                                                } */}
                                                                <p>{line.part_num}</p>
                                                                {line.part_desc ?
                                                                    <InfoTooltip className="part-desc" middle>{line.part_desc}</InfoTooltip>
                                                                    : null
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {line.serial_editable ?
                                                                <TextInput
                                                                    type="text"
                                                                    name={`lines[${index}].serial`}
                                                                    placeholder={translate(`gridColumns.serialNumber`)}
                                                                    maxLength="100"
                                                                />
                                                                :
                                                                <p>{line.serial}</p>
                                                            }
                                                        </td>
                                                        {!isActOfExchange && // POWR2 and services doesn't use country_origin for act of exchange
                                                        /*
                                                        <Select
                                                            value={_inputddlCountry}
                                                            text={_inputtxtCountry}
                                                            onChange={handleInputddlCountry}
                                                            options={_inputOptionsCountry}
                                                            className="inputSpace"
                                                            label={translate('formLabel.shipToCountry')}
                                                            placeholder={translate('formLabel.shipToCountry')}
                                                            singleSelection
                                                        />*/
                                                            <td>
                                                                {line.country_origin_editable ?
                                                                    // <TextInput
                                                                    //     type="text"
                                                                    //     name={`lines[${index}].country_origin`}
                                                                    //     placeholder={translate(`gridColumns.countryOrigin`)}
                                                                    //     isRequired
                                                                    //     maxLength="50"
                                                                    //     onBlur={(e) => {
                                                                    //         handleBlur(e);
                                                                    //         prompCopyModal(e.target.value, values, index)
                                                                    //     }}
                                                                    // />
                                                                    <Select
                                                                        value={countries.find(x => x.name === line.country_origin || x.code === line.country_origin)?.code || ''}
                                                                        text={lines[index].country_origin}
                                                                        onChange={(e) => {
                                                                            setFieldValue('country', e, true);
                                                                            handleBlur(e);
                                                                            prompCopyModal(e, values, index)

                                                                        }}
                                                                        options={countries}
                                                                        className="m-0"
                                                                        selectContainerClass="h-auto"
                                                                        singleSelection
                                                                        mandatory
                                                                        isRequired
                                                                        // label={translate('formLabel.country')}
                                                                        error={getFieldMeta(`lines[${index}].country_origin`).touched && getFieldMeta(`lines[${index}].country_origin`).error}
                                                                        hideClear
                                                                    />     
                                                                    :
                                                                    <p>{line.country_origin}</p>
                                                                }
                                                            </td>
                                                        }
                                                        {isActOfExchange &&
                                                            <td>
                                                                {line.quantity_editable ?
                                                                    <TextInput
                                                                        type="text"
                                                                        name={`lines[${index}].quantity`}
                                                                        placeholder={translate(`gridColumns.qty`)}
                                                                        maxLength="50"
                                                                    />
                                                                    :
                                                                    <p>{line.quantity}</p>
                                                                }
                                                            </td>
                                                        }
                                                        {isFaultDetection &&
                                                            <td>
                                                                {line.failure_desc_editable ?
                                                                    <TextInput
                                                                        type="text"
                                                                        name={`lines[${index}].failure_desc`}
                                                                        placeholder={translate(`gridColumns.failureDescription`)}
                                                                        maxLength="50"
                                                                    />
                                                                    :
                                                                    <p>{line.failure_desc}</p>
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                ))
                                                : null
                                            }
                                        </Fragment>
                                    )}
                                </FieldArray>
                            </tbody>
                        </table>
                        <div className="d-block d-lg-none card-ci">
                            <FieldArray name="lines">
                                {() => (
                                    <Fragment>
                                        {values.lines && values.lines.length > 0 ?
                                            values.lines.map((line, index) => (
                                                <div key={index} className="row border-bottom-gray pb-2 mb-2">
                                                    <div className="col d-flex">
                                                        <div className="row">
                                                            <label className="col-auto strong">{`${translate(`gridColumns.line`)}: `}</label>
                                                            <label className="col p-0">{line.seq}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col d-flex">
                                                        <label className="col-auto strong">{`${translate(`gridColumns.rma`)}: `}</label>
                                                        <label className="col p-0">{line.rma_num}</label>
                                                    </div>
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <label className="col-5 strong">{`${translate(`gridColumns.partNumber`)}: `}</label>
                                                            <div className="col d-flex">
                                                                {line.part_num_editable ?
                                                                    <TextInput
                                                                        type="text"
                                                                        name={`lines[${index}].part_num`}
                                                                        placeholder={translate(`gridColumns.partNumber`)}
                                                                        maxLength="50"
                                                                    />
                                                                    :
                                                                    <label>{line.part_num}</label>
                                                                }
                                                                {line.part_desc ?
                                                                    <InfoTooltip className="ml-2 part-desc" middle>{line.part_desc}</InfoTooltip>
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 pt-3">
                                                        <div className="row">
                                                            <label className="col-5 strong">{`${translate(`gridColumns.serialNumber`)}: `}</label>
                                                            <div className="col d-flex">
                                                                {line.serial_editable ?
                                                                    <TextInput
                                                                        type="text"
                                                                        name={`lines[${index}].serial`}
                                                                        placeholder={translate(`gridColumns.serialNumber`)}
                                                                        maxLength="50"
                                                                    />
                                                                    :
                                                                    <label>{line.serial}</label>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!isActOfExchange && // POWR2 and services doesn't use country_origin for act of exchange
                                                        <div className="col-12 pt-3">
                                                            <div className="row">
                                                                <label className="col-5 strong">{`${translate(`gridColumns.countryOrigin`)}: `}</label>
                                                                <div className="col-4 d-flex">
                                                                    {line.country_origin_editable ?
                                                                        <Select
                                                                            value={countries.find(x => x.name === line.country_origin || x.code === line.country_origin)?.code || ''}
                                                                            text={lines[index].country_origin}
                                                                            onChange={(e) => {
                                                                                setFieldValue('country', e, true);
                                                                                handleBlur(e);
                                                                                prompCopyModal(e, values, index)

                                                                            }}
                                                                            options={countries}
                                                                            className="m-0"
                                                                            selectContainerClass="h-auto"
                                                                            singleSelection
                                                                            mandatory
                                                                            isRequired
                                                                            // label={translate('formLabel.country')}
                                                                            error={getFieldMeta(`lines[${index}].country_origin`).touched && getFieldMeta(`lines[${index}].country_origin`).error}
                                                                            hideClear
                                                                        />  
                                                                        :
                                                                        <label>{line.country_origin}</label>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {isActOfExchange &&
                                                        <div className="col-12 pt-3">
                                                            <div className="row">
                                                                <label className="col-5 strong">{`${translate(`gridColumns.qty`)}: `}</label>
                                                                <div className="col d-flex">
                                                                    {line.quantity_editable ?
                                                                        <TextInput
                                                                            type="text"
                                                                            name={`lines[${index}].quantity`}
                                                                            placeholder={translate(`gridColumns.qty`)}
                                                                            maxLength="50"
                                                                        />
                                                                        :
                                                                        <label>{line.quantity}</label>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {isFaultDetection &&
                                                        <div className="col-12 pt-3">
                                                            <div className="row">
                                                                <label className="col-5 strong">{`${translate(`gridColumns.failureDescription`)}: `}</label>
                                                                <div className="col d-flex">
                                                                    {line.failure_desc_editable ?
                                                                        <TextInput
                                                                            type="text"
                                                                            name={`lines[${index}].failure_desc`}
                                                                            placeholder={translate(`gridColumns.failureDescription`)}
                                                                            maxLength="50"
                                                                        />
                                                                        :
                                                                        <label>{line.failure_desc}</label>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                            : null
                                        }
                                    </Fragment>
                                )}
                            </FieldArray>
                        </div>
                        <POWRModal
                            show={confirmation}
                            size="tiny"
                            onClose={() => setConfirmation(!confirmation)}
                            hideAlerts
                        >
                            <p>{translate('message.applySameCountry')}</p>
                            <div className="toolbar">
                                <POWRButton type="button" color={'secondary'} onClick={() => setConfirmation(!confirmation)}>
                                    {translate('button.cancel')}
                                </POWRButton>
                                <POWRButton onClick={() => copyToAllFields(values, validateForm)} >
                                    {translate('button.continue')}
                                </POWRButton>
                            </div>
                        </POWRModal>
                        <div className='d-md-flex'>
                                <img src={pictureCOO} alt="" style={{ width: '30vw', height: 'auto', minWidth:'25rem' }} />
                            <div>
                                <p className='ml-md-4'>{translate('message.contryOriginMsg')}<br />
                                    <span style={{ color: COLOR.RED }}>{translate('message.contryOriginMsg2')}</span>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div className="toolbar d-flex flex-column flex-md-row justify-content-between">
                        {isCommercialInvoice && <HelpCommercialInvoiceModal type={type}></HelpCommercialInvoiceModal>}
                        {isFaultDetection && <HelpFaultDetectionModal></HelpFaultDetectionModal>}
                        {isActOfExchange && <HelpActOfExchangeModal></HelpActOfExchangeModal>}
                        <div className="d-flex pt-3 pt-md-0" >
                            {!fromSummary &&
                                <POWRButton type="button" color={'secondary'} onClick={() => onClose()}>
                                    {translate('button.cancel')}
                                </POWRButton>
                            }
                            <POWRButton type="submit" >
                                {translate('button.savePreview')}
                            </POWRButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
            
    )
}
