import * as Yup from 'yup';
import { REGEXP } from '../constants';

export const ShipmentSearchSchema = Yup.object().shape({
    rma:
        Yup.string()
            .matches(REGEXP.RMAS, 'error.onlyNumbersR')
            .min(3, 'error.min'),
    trackingNumber:
        Yup.string()
            .matches(REGEXP.CI_ALPHA_NUM, 'error.invalidChar')
            .min(3, 'error.min'),
    shipCode:
        Yup.string()
            .min(3, 'error.min')
});