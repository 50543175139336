export const theme = {
  colors: {
    white: "#ffffff",
    black: "#000000",

    background: "#f4f6fa",

    ciscoBlue: "#004766",
    ciscoLightBlue: "#099ad6",

    gray: "#c5d8df",
    lightGray: "#e6e6e6",
  },

  fontSize: {
    px: [10, 12, 14, 16, 18, 20, 22],
    rem: [0.625, 0.75, 0.875, 1, 1.125, 1.25, 1.375],
  },

  fontFamily: ["CiscoSansTT-Regular", "sans-serif"],

  gap: ["6px", "12px", "24px", "48px"],

  time: ["0.3s", "0.6s"],

  breakpoint: {
    size: {
      // Equivalent of 480px
      xs: "30em",

      // Equivalent of 768px
      sm: "48em",

      // Equivalent of 992px
      md: "62em",

      // Equivalent of 1200px
      lg: "75em",

      // Equivalent of 1360px
      xl: "85em",
    },
  },

  // Elements
  header: {
    height: {
      px: "64px",
      rem: "4rem",
    },
  },

  side: {
    width: {
      px: "80px",
      rem: "5rem",
    },

    widthOpen: {
      px: "352px",
      rem: "22rem",
    },
  },

  footer: {
    height: {
      px: "64px",
      rem: "4rem",
    },
  },
};
