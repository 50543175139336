import React, { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import { useTranslate } from "../../../core/contexts/i18n";
import { fadeAnimation } from "../../animations/fadeAnimation";
import { Link as DomLink, useHistory } from "react-router-dom";
import { ExpireSessionModal, LoggingOutModal } from "../../modals";
import { HeadAlert } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { selectReturnFlowCurrentStep, selectUserInfo } from "../../../core/redux/selectors";
import { ROLES } from "../../../core/constants";
import { ADDRESS_BOOK, SETTINGS } from "../../../core/constants/ROUTES";
import { clickOutsideReturn } from "../../../core/redux/actions";
import { POWRIcon } from "../../styledComponents";
import IdleTimerContainer from "../../idleTimerContainer";
import { RETURN, RLSP } from "../../../core/constants/ROUTES";

export const HeaderNavigationWrapper = styled.nav`
  > ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      transition: background-color 0.3s;
      position: relative;
      border-left: 1px #f6f6f6 solid;

      &:last-child {
        display: none;
      }

      &:hover {
        background-color: #00000010;
      }
    }
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    > ul > li {
      display: none;
      border-left: 0;

      &:last-child {
        display: block;
      }
    }
  }
`;

export const HeaderLink = styled(DomLink)`
  display: flex;
  cursor: pointer;

  justify-content: center;
  align-items: center;

  background: 0;
  border: 0;
  padding: 0;

  // @TODO Implement Theme provider
  height: 64px;
  width: 80px;

  transition: background-color 0.3s;

  svg {
    color: #5d5c5c !important;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    svg {
      color: #ffffff !important;
    }
  }
`;

export const HeaderButton = styled.button`
  display: flex;
  cursor: pointer;

  position: relative;
  z-index: 1;

  justify-content: center;
  align-items: center;

  background: 0;
  border: 0;
  padding: 0;

  // @TODO Implement Theme provider
  height: 64px;
  width: 80px;

  transition: background-color 0.3s;

  svg {
    color: #5d5c5c !important;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    svg {
      color: #ffffff !important;
    }
  }
`;

export const HeaderInformationWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 0 24px;

  > li {
    font-size: 12px;
    margin: 0 12px;

    &:first-child {
      font-family: CiscoSansTT-Bold;
    }
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    display: none;
  }
`;

const Link = styled.a`
  color: #212529;
  font-size: 12px;
  transition: color 0.3s;

  svg {
    color: #212529 !important;
  }

  &:hover {
    text-decoration: none;
    color: #003953;

    svg {
      color: #003953 !important;
    }
  }
`;
const LinkAnchor = styled.a`
  color: #212529;
  font-size: 12px;
  transition: color 0.3s;
  cursor: pointer;

  svg {
    color: #212529 !important;
  }

  &:hover {
    text-decoration: none !important;
    color: #003953 !important;

    svg {
      color: #003953 !important;
    }
  }
`;

const LinkButton = styled.div`
  color: #212529;
  font-size: 12px;
  transition: color 0.3s;
  
  svg {
    color: #212529 !important;
  }

  &:hover {
    text-decoration: none;
    color: #003953;

    svg {
      color: #003953 !important;
    }
  }
`;

const UserEmail = styled.li`
  font-size: 12px;
  font-family: CiscoSansTT-Bold;
`;

const DropdownLinkReact = ({ children, icon, id, className, to, onClick, shouldOpenWindow }) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const clickOptionMenu = () => {
    if (history.location.pathname === RETURN) dispatch(clickOutsideReturn(to))
    if (history.location.pathname === RLSP) dispatch(clickOutsideReturn(to)) //Add this to handle behavior for rlsp unsaved information and move to different page 
    else history.push(to)
  }

  return (
    <li className={className}>
      {shouldOpenWindow ?
        <LinkAnchor href={to} target="_blank">
          {icon && <POWRIcon className={icon} size="16px" />}
          {translate(id)}
        </LinkAnchor>
        :
        <Fragment>
          {to ?
            <Link onClick={clickOptionMenu}>
              {icon && <POWRIcon className={`${icon} mr-2`} size="16px" />}
              {translate(id)}
            </Link>
            :
            <LinkButton onClick={onClick}>
              {icon && <POWRIcon className={`${icon} mr-2`} size="16px" />}
              {translate(id)}
            </LinkButton>
          }
        </Fragment>
      }
      {children}
    </li>
  );
};

export const Dropdown = styled.nav`
  display: none;
  position: fixed;
  animation: ${fadeAnimation} 0.3s ease forwards;

  right: 0;
  top: 0;
  padding: 72px 8px 8px;

  > ul  {
    list-style: none;
    background-color: #fff;
    padding: 8px;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 #00000010;
    border: 1px #e6e6e6 solid;

    width: 320px;
  }

  ${UserEmail} {
    padding: 16px;
  }
`;

const DropdownLink = styled(DropdownLinkReact)`
  ${Link},
  ${LinkAnchor},
  ${LinkButton} {
    display: flex;
    padding: 16px;
    cursor: pointer;
  }
`;

const MenuLink = ({ children, icon, onClick, className, isOpen, to }) => {
  const itemRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);

      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  });

  const handleClickOutside = ($e) => {
    if (!itemRef.current.contains($e.target)) {
      onClick();
    }
  };

  return (
    <li onClick={onClick} className={className} ref={itemRef}>
      {to ? (
        <HeaderLink to={to}>
          <POWRIcon className={icon} size="20px" />
        </HeaderLink>
      ) : (
          <HeaderButton>
            <POWRIcon className={icon} size="20px" />
          </HeaderButton>
        )}

      {children}
    </li>
  );
};

const HeaderMenuLink = styled(MenuLink)`
  &:hover {
    > ${Dropdown} {
      display: block;
    }
  }

  > ${Dropdown} {
    ${UserEmail} {
      margin: -8px -8px 0;
    }

    ${DropdownLink} {
      margin: 0 -8px;

      &:hover {
        background-color: #00000010;
      }

      &:first-child  {
        margin-top: -8px;
      }

      &:last-child  {
        margin-bottom: -8px;
        border-bottom: 0;
      }

      &:last-child,
      &:nth-last-child(2) {
        border-top: 1px #e6e6e6 solid;
      }
    }
  }

  ${Dropdown} {
    display: ${(props) => props.isOpen ? "block" : "none"} !important;
  }
`;

export const HeaderNavigation = (props) => {
  const { translate } = useTranslate();
  const [isMobileMenuOpen, toggleMobileMenu] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [expire, setExpire] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const currentStep = useSelector(selectReturnFlowCurrentStep);
  const history = useHistory();
  const dispatch = useDispatch();

  const clickOptionMenu = (to) => {
    if (history.location.pathname === RETURN && currentStep !== 5) dispatch(clickOutsideReturn(to))
    if (history.location.pathname === RLSP) dispatch(clickOutsideReturn(to))
    else history.push(to)
  }

  return (
    <Fragment>
      <HeaderInformationWrapper>
        <UserEmail>{userInfo.mail}</UserEmail>
        {
          (userInfo && userInfo.role === ROLES.ADMIN) &&
          <li>
            <LinkAnchor href={`${process.env.REACT_APP_ADMIN_APIURL}/admin/adminOptions.jsp`} target="_blank">{translate('title.admin')}</LinkAnchor>
          </li>
        }
        {
          (userInfo && userInfo.role !== ROLES.RLSP && !userInfo.rmaLogin) &&
          <Fragment>
            <li>
              <LinkAnchor onClick={() => clickOptionMenu(SETTINGS)}>{translate('title.settings')}</LinkAnchor>
            </li>
            {userInfo.role !== ROLES.READ_ONLY &&
              <li>
                <LinkAnchor onClick={() => clickOptionMenu(ADDRESS_BOOK)}>{translate('title.addressBook')}</LinkAnchor>
              </li>
            }
          </Fragment>
        }
      </HeaderInformationWrapper>
      <HeaderNavigationWrapper>
        <ul>
          <HeaderMenuLink
            icon="fal fa-sign-out"
            onClick={() => setLoggingOut(true)}
          />
          <HeaderMenuLink
            icon="fas fa-user-circle"
            onClick={() => toggleMobileMenu(!isMobileMenuOpen)}
            isOpen={isMobileMenuOpen}
          >
            <Dropdown isOpen={isMobileMenuOpen}>
              <ul>
                <UserEmail>{userInfo.mail}</UserEmail>
                {(userInfo && userInfo.role === ROLES.ADMIN) &&
                  <DropdownLink to={`${process.env.REACT_APP_ADMIN_APIURL}/admin/adminOptions.jsp`} shouldOpenWindow id="title.admin" />
                }
                {
                  (userInfo && userInfo.role !== ROLES.RLSP && !userInfo.rmaLogin) &&
                  <Fragment>
                    <DropdownLink to={SETTINGS} id="title.settings" />
                    <DropdownLink to={ADDRESS_BOOK} id="title.addressBook" />
                  </Fragment>
                }
                <DropdownLink onClick={() => setLoggingOut(true)} id="title.logout" icon="fal fa-sign-out" />
              </ul>
            </Dropdown>
          </HeaderMenuLink>
        </ul>
      </HeaderNavigationWrapper>
      <HeadAlert />
      <LoggingOutModal show={loggingOut} onClose={() => setLoggingOut(false)} />
      <ExpireSessionModal show={expire} setExpire={setExpire} />
      <IdleTimerContainer></IdleTimerContainer>
    </Fragment>
  );
};
