import moment from 'moment';

// Set the pickup date, min/max, and available days based on the carrier profile record
export const usePickupDate = () => {
    const getPickupDates = (rec, pickupLocationDate,theaterCode) => {
        pickupLocationDate = moment(pickupLocationDate).format('YYYY-MM-DD[T]HH:mm:ss');

        let isAdvNoticeWindowServiceDaysOnly = rec.adv_notice_window_service_days_only;
        if (isAdvNoticeWindowServiceDaysOnly) {
            return setPickupDatePropsCR58(rec, pickupLocationDate,theaterCode);
        } else {
            let minHrsPickupRequest = rec.min_hrs_pickup_request;
            let maxDaysPickupRequestStr = rec.max_days_pickup_request;
            let advNoticeCutoffHrs = rec.adv_notice_cutoff_time_hours;
            let advNoticeCutoffMinutes = rec.adv_notice_cutoff_time_minutes;
            let isFedExExpress = rec.is_fedex_express;

            let isAllowScheduleOnWeekends = rec.allow_schedule_on_weekends;
            let currentDay = moment().day();

            let minDateTime;
            if (!isFedExExpress) {

                minDateTime = moment(pickupLocationDate).add(minHrsPickupRequest, 'hours');

                let cutoffTime = parseInt(advNoticeCutoffHrs,10) + (parseInt(advNoticeCutoffMinutes,10) / 60);
                let minDtTime = minDateTime.hours() + (minDateTime.minutes() / 60);

                if (minDtTime >= cutoffTime) {
                minDateTime = moment(minDateTime).add(1, 'days');
                } 
                
                let currentDayHours = moment().hours() + (moment().minutes() / 60);

                if (!isAllowScheduleOnWeekends){
                    while (arrayContains(rec.service_disabled_days, minDateTime.day())) {
                        minDateTime = minDateTime.add(24, 'hours');
                    }  
                }

                if (
                    !isAllowScheduleOnWeekends && 
                    ((currentDay===5 && currentDayHours>cutoffTime) || currentDay===6 || currentDay===0) &&
                    minDateTime.day() === 1
                ) {
                    minDateTime = minDateTime.add(1, 'days');    	  
                }

                let maxDaysPickupRequest = !emptyStr(maxDaysPickupRequestStr) ? parseInt(maxDaysPickupRequestStr,10) : 0;
                let maxDate = maxDaysPickupRequest >= 0 ? moment(pickupLocationDate).add(maxDaysPickupRequest, 'days') : null;
                
                return enumerateDaysBetweenDates(minDateTime, maxDate, rec.service_disabled_days);
            }
            else {
                //-- FedEx Express
                // Same day pickup currently not available in GCT
                // next business date is the first (and last) date available
                minDateTime = moment(pickupLocationDate).add(1, 'days');
                let nbdDayOfWeek = minDateTime.day();
                if (nbdDayOfWeek === 0)//Sunday
                    minDateTime = minDateTime.add(1, 'days'); //Mon
                else if (nbdDayOfWeek === 6)//Saturday
                    minDateTime = minDateTime.add(2, 'days'); //Mon
                let weekends = [0, 6];
                return enumerateDaysBetweenDates(minDateTime, null, weekends);
            }
        }
    }

    // ------------------------------ Shared functions ------------------------------
    // Set the pickup date, min/max, and available days based on the carrier profile record
    const setPickupDatePropsCR58 = (rec, pickupLocationDate,theaterCode) => {
        let removeHours = false;
        if(!theaterCode){
            pickupLocationDate = moment();
        }else{
            if(theaterCode!='GCN'){
                pickupLocationDate = moment();
            }else{
                pickupLocationDate = moment(pickupLocationDate);
                removeHours=true;
            }
        }
        
        let minHrsPickupRequest = rec.min_hrs_pickup_request;      //Advanced Notice Window (in hours)
        let maxDaysPickupRequestStr = rec.max_days_pickup_request;
        let advNoticeCutoffHrs = rec.adv_notice_cutoff_time_hours; //Advance Notice Cutoff Time(hours) - e.g. '17'
        let advNoticeCutoffMinutes = rec.adv_notice_cutoff_time_minutes; //Advance Notice Cutoff Time(minutes) - e.g. '00'
        let isFedExExpress = rec.is_fedex_express;
        let isAdvNoticeWindowServiceDaysOnly = rec.adv_notice_window_service_days_only;

        let minDateTime;

        if (!isFedExExpress) { //not implemented yet(current day pickup causes some issues)

            let cutoffTime = parseInt(advNoticeCutoffHrs,10) + (parseInt(advNoticeCutoffMinutes,10) / 60);
            let minDtTime = pickupLocationDate.hours() + (pickupLocationDate.minutes() / 60);

            if (minDtTime >= cutoffTime || arrayContains(rec.service_disabled_days, pickupLocationDate.day())) {
                minDateTime = pickupLocationDate.add(1, 'days');
                minDateTime = minDateTime.add(-pickupLocationDate.hours(), 'hours');
                minDateTime = minDateTime.add(-pickupLocationDate.minutes(), 'minutes');
                minDateTime = minDateTime.add(-pickupLocationDate.seconds(), 'seconds');
                minDateTime = minDateTime.add(1, 'minutes');
            } else {
                minDateTime = pickupLocationDate;
            }

            if (isAdvNoticeWindowServiceDaysOnly) {
                //while pickupLocationDate is a non serviceAvailable day we add 24 hours more
                while (arrayContains(rec.service_disabled_days, pickupLocationDate.day())) {
                    minDateTime = minDateTime.add(24, 'hours');
                }
            }

            while (minHrsPickupRequest>24){
                //minDateTime = minDateTime.add(Date.HOUR, minHrsPickupRequest);
                minDateTime = minDateTime.add(24, 'hours');
                minHrsPickupRequest = minHrsPickupRequest - 24;
                if (isAdvNoticeWindowServiceDaysOnly) {
                    //while pickupLocationDate is a non serviceAvailable day we add 24 hours more
                    while (arrayContains(rec.service_disabled_days, minDateTime.day())) {
                        minDateTime = minDateTime.add(24, 'hours');
                    }
                }	
            }
            
            minDateTime = minDateTime.add(minHrsPickupRequest, 'hours');
            if (isAdvNoticeWindowServiceDaysOnly) {
                //while pickupLocationDate is a non serviceAvailable day we add 24 hours more
                while (arrayContains(rec.service_disabled_days, minDateTime.day())) {
                    minDateTime = minDateTime.add(24, 'hours');
                }
            }

            //---
            //-- Set maximum pickup date
            //---
            //defect#59421 (Max Days Adv. Notice=0 means request must be same day; Max Days Adv. Notice=null, means do NOT restrict valid days)

            if (!emptyStr(maxDaysPickupRequestStr)) {
                let maxDaysPickupRequest = parseInt(maxDaysPickupRequestStr,10);

                let maxDate = minDateTime.clone();
                while (maxDaysPickupRequest > 1) {
                    if (!arrayContains(rec.service_disabled_days, maxDate.day())) {
                        maxDaysPickupRequest--;
                    }
                    maxDate = maxDate.add(24, 'hours');
                }

                if (isAdvNoticeWindowServiceDaysOnly) {
                    //while pickupLocationDate is a non serviceAvailable day we add 24 hours more
                    while (arrayContains(rec.service_disabled_days, maxDate.day())) {
                        maxDate = maxDate.add(24, 'hours');
                    }
                }
                return enumerateDaysBetweenDates(removeHours?minDateTime.startOf('day'): minDateTime, maxDate, rec.service_disabled_days);
            } else {
                return {
                    firstDay: removeHours?minDateTime.startOf('day'): minDateTime,
                    dates: [],
                    noDates: []
                }
            }
        } else {
            //-- FedEx Express
            // Same day pickup currently not available in GCT
            // next business date is the first (and last) date available
            minDateTime = pickupLocationDate;

            if (isAdvNoticeWindowServiceDaysOnly) {
                //while pickupLocationDate is a non serviceAvailable day we add 24 hours more
                while (arrayContains(rec.service_disabled_days, minDateTime.day())) {
                    minDateTime = minDateTime.add(24, 'hours');
                }
            }

            minDateTime = pickupLocationDate.add(1, 'days');
            let nbdDayOfWeek = minDateTime.day();
            if (nbdDayOfWeek === 0)//Sunday
                minDateTime = minDateTime.add(1, 'days'); //Mon
            else if (nbdDayOfWeek === 6)//Saturday
                minDateTime = minDateTime.add(2, 'days'); //Mon

            if (isAdvNoticeWindowServiceDaysOnly) {
                //while pickupLocationDate is a non serviceAvailable day we add 24 hours more
                while (arrayContains(rec.service_disabled_days, minDateTime.day())) {
                    minDateTime = minDateTime.add(24, 'hours');
                }
            }
            // let weekends = [0, 6];
            return enumerateDaysBetweenDates(removeHours?minDateTime.startOf('day'): minDateTime, minDateTime, rec.service_disabled_days);
        }
    }

    const emptyStr = (obj) => {
        return (obj === null || typeof (obj) === 'undefined' || obj === '');
    }

    const arrayContains = (arr, value) => {
        let found = false;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === value) {
                found = true;
                break;
            }
        }
        return found;
    }

    const enumerateDaysBetweenDates = (startDate, endDate, disableDays) => {
        let dates = [];
        let noDates = [];

        let currDate = moment(startDate).startOf('day');
        let lastDate = moment(endDate).startOf('day');
        let firstDay = currDate.clone();
        
        while (currDate.diff(lastDate) <= 0) {
            if (!arrayContains(disableDays, currDate.day())) {
                dates.push(currDate.clone().toDate());
            } else {
                noDates.push(currDate.clone().toDate());
            }
            currDate.add(1,'days');
        }

        if (dates.length) firstDay = dates[0];

        return {
            firstDay,
            dates,
            noDates
        };
    };

    return { getPickupDates };
}