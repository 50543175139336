import React, { useEffect, Fragment, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Input, Link } from '../components/controls';
import { useTranslate } from '../core/contexts';
import { getShipments, getValidateEntities } from '../services';
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { POWRButton, POWRIcon, POWRModal } from '../components/styledComponents';
import { useAlert, useRMAValidation, useGlobalMessage, useConfigParams, useCommercialInvoice } from "../core/hooks";
import { COLOR, DOCUMENTATION, FORM_TYPE } from '../core/constants';
import { FR_PACKING_LIST, MENU_REPORTS } from '../core/constants/ROUTES';
import { CommercialInvoiceForm } from '../components/forms/commercialInvoiceForm';


export default props => {
    const history = useHistory();
    const { translate } = useTranslate();
    const { showAlert, removeAlert } = useAlert();
    const { getFaqLink, getContactUsLink } = useConfigParams();
    const { showPageMessage } = useGlobalMessage(); // Global Messages
    const {
        handleCommercialInvoice,
        print,
        modalTemplate,
        setModalTemplate,
    } = useCommercialInvoice({  });

    const [searched, setSearched] = useState('');
    const [isFR, setIsFR] = useState(false);
    const [shipmentId, setShipmentId] = useState('');
    const [haveShipmentId, setHaveShipmentId] = useState(false);

    const [error, setError] = useState(false);
    const [results, setResults] = useState(false);

    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();

    useEffect(() => {
        setShipmentId("");
        setError(false);
        setResults(false);
        setSearched('');
        setIsFR(history.location.pathname === FR_PACKING_LIST);
    }, []);

    const handleSearch = () => {
        setHaveShipmentId(true);
        setSearched(shipmentId);
        let ship_country = shipmentId.substring(0, 2).toUpperCase();

        const parametres = {
            param_ship_code: shipmentId,
            param_exclude_saved: "on",
            param_max_records: 1
        };

        getShipments(parametres).then(res => {
            if (res.res.dtos?.length > 0) {
                let shipment = res.res.dtos[0];
                const { idd, ship_code } = shipment;
                let params = {
                    "ship_id": idd,
                    "docType": isFR ? FORM_TYPE.PACKING_LIST : FORM_TYPE.COMMERCIAL_INVOICE,
                    "countryCode": ship_country
                }
                getValidateEntities(params).then(res => {
                    if (res.res.success && res.res.warn) {
                        setError(true);
                        setResults(false);
                    }
                    else {
                        setError(false);
                        setResults(true);
                        setSearched('');
                        handleCommercialInvoice(idd,
                            ship_code,
                            isFR ? FORM_TYPE.PACKING_LIST_FR : FORM_TYPE.COMMERCIAL_INVOICE_CH,
                            false,
                            false,
                            false,
                            true)
                    }
                });

            } else {
                setError(true);
                setResults(false);
            }

        }).catch(err => {
            console.log(err);
            props.clearStorage();
        });

    }

    return (
        <FullPage>
            <div className="main-documentation">
                <h1>{translate(`title.${isFR ? 'francePkgList' : 'switzerlandCI' }`)} </h1>
                <p className="subhead-documentation">{translate(`message.${isFR ? 'francePkgListMsg' : 'switzerlandMsg'}`)}</p>
                <div className="body-documentation">
                    <div className='search-section'>
                        <div className="search-documentation">
                            <Input
                                label={translate('gridColumns.shipmentID')}
                                name="shipmentId"
                                placeholder={translate('gridColumns.shipmentID')}
                                value={shipmentId}
                                onChange={(e) => { setShipmentId(e.target.value); setError(false); }}
                                onEnter={handleSearch}
                                icon={{ content: "search", width: "23px", height: "16px", color: COLOR.DARK_GREY_1 }}
                                error={shipmentId !== "" && haveShipmentId && error}
                                errorMessage={translate('error.shipmentIDError')}
                            />
                            <POWRButton className="ml-3" onClick={() => handleSearch()} color="secondary">
                                {translate('button.search')}
                            </POWRButton>

                        </div>
                        <Link className={"download-link"} to={{ pathname: MENU_REPORTS }}>
                            {translate('back to Reports Menu')}
                        </Link> 
                     </div>
                    <div key={`documentation-result-${results}`}>
                        {
                            results ?
                                <div className="download-documentation">
                                </div>
                                :
                                (
                                    searched ?
                                        <div className="no-results">
                                            <POWRIcon
                                                className="fal fa-search icon-card"
                                                size="70px"
                                                color={COLOR.GREY}
                                            />
                                            <p className="text-center">{translate('message.shipWasNotFound').replace('{0}', searched)} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></p>
                                        </div>
                                        :
                                        <div className="no-results">
                                            <POWRIcon
                                                className="fal fa-search icon-card"
                                                size="70px"
                                                color={COLOR.GREY}
                                            />
                                            <p className="text-center">{translate('message.noShipIddetected')} <Link target="_blank" href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('message.wasNotFound2')} <Link target="_blank" href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link></p>
                                        </div>
                                )
                        }
                    </div>
                </div>
            </div>
            {modalTemplate != null &&
                <POWRModal
                    show={true}
                    title={translate(`title.${(modalTemplate.type === FORM_TYPE.ZIP || modalTemplate.type === FORM_TYPE.COMMERCIAL_INVOICE_CH) ?
                        FORM_TYPE.COMMERCIAL_INVOICE :
                       isFR ? FORM_TYPE.PACKING_LIST : modalTemplate.type}Form`)}
                    size="long"
                    onClose={() => setModalTemplate(null)}
                >
                    <CommercialInvoiceForm
                        onClose={() => setModalTemplate(null)}
                        lines={modalTemplate.data}
                        print={() => print(modalTemplate.type, modalTemplate.shipId)}
                        type={modalTemplate.type}
                        shipCode={modalTemplate.shipCode}
                        fromPrintAll={modalTemplate.fromPrintAll}
                        shipId={modalTemplate.shipId}
                    />
                </POWRModal>
            }
        </FullPage>
    )
}