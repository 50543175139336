import React, { useCallback, useEffect, useMemo, useState, useRef, Fragment } from 'react';
import { Formik } from 'formik';
import { Link, Select, InfoTooltip, TextInput } from '../controls';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';
import { ContactSchema, ContactSchemaUSCA } from '../../core/schemas';
import { useDispatch, useSelector } from 'react-redux';
import { addAlternateContact, addPickupContact, addRequestor, saveContact, updateAlternateContact, updatePickupContact, updateRequestor } from '../../core/redux/actions';
import { useTranslate } from '../../core/contexts';
import { CONTACT_TYPES, COUNTRY } from '../../core/constants';
import { selectReturnFlowOptions, selectUserContacts } from '../../core/redux/selectors';
import { useAlert } from '../../core/hooks';
import { COLOR } from '../../core/constants/Colors';

const funcs = {
    PickupRequestor: addRequestor,
    PickupRequestorUpd: updateRequestor,
    PickupContact: addPickupContact,
    PickupContactUpd: updatePickupContact,
    AlternateContact: addAlternateContact,
    AlternateContactUpd: updateAlternateContact
};
const formTypes = {
    PickupRequestor: { type: '2', template: 'requestor' },
    PickupContact: { type: '3', template: 'pickupContact' },
    AlternateContact: { type: '4', template: 'alternateContact' }
};

export const PickupRequestorForm = ({ onClose, isAdd, template, saveAtAddressBook, data, onDelete, country }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const refSubmit = useRef();
    const { showAlert } = useAlert();

    const [initialValues, setInitialValues] = useState({
        save_as: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
        type: '',
        saveAtAddressBook: false
    });
    const [typeOptions, setTypeOptions] = useState([]);
    const [hasDupError, setHasDupError] = useState(false);
    const [contact, setContact] = useState('');
    const [localData, setLocalData] = useState(null);
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [saveAsAtAddressBook, setSaveAsAtAddressBook] = useState(saveAtAddressBook ? saveAtAddressBook : false);
    const [schema, setSchema] = useState(ContactSchema);

    const addressBook = useSelector(selectReturnFlowOptions);
    const currentContacts = useSelector(selectUserContacts);

    const name = useMemo(() => saveAsAtAddressBook ? '' : formTypes[template].template, [template, saveAtAddressBook]);
    const isUSorCA = useMemo(() => (country === COUNTRY.US ||country === COUNTRY.CANADA), [country]);

    useEffect(() => {
        if (!typeOptions.length) setTypeOptions(CONTACT_TYPES.filter(x => x.type > 1).map(x => ({
            text: translate(`contactType.${x.key}`), code: String(x.type)
        })));
    }, [translate, typeOptions]);

    useEffect(() => {
        setLocalData(data);
    }, [data]);

    useEffect(() => { setSchema( isUSorCA ? ContactSchemaUSCA : ContactSchema ); }, [country]);

    useEffect(() => {
        if (typeOptions.length > 0) {
            const hasData = ((!isAdd || !saveAsAtAddressBook) && localData);
            const defaultType = (saveAtAddressBook ? { type: !isAdd ? data.type : '' }
                : { type: formTypes[template].type });
            let newValues = {
                ...initialValues,
                ...(hasData ? localData : {}),
                ...defaultType,
                saveAtAddressBook
            };
            setInitialValues(newValues);
            if (hasData) checkInitialErrors(newValues);
        }
    }, [localData, isAdd, saveAtAddressBook, template, typeOptions]);

    useEffect(() => {
        if (contact !== '' && addressBook && addressBook[name])
            setLocalData(addressBook[name].find(x => x && x.idd === Number(contact)));
    }, [contact, addressBook, name]);

    const isDuplicate = useCallback((values) => {
        for (let ct of currentContacts) {
            if (ct.type === values.type && ct.ctag.toLowerCase().trim() === values.save_as.toLowerCase().trim()) {
                if ((!localData ||
                    (localData && (ct.idd !== localData.idd || localData.ctag !== values.save_as)))) {
                    return true;
                }
            }
        }
        return false;
    }, [currentContacts, localData]);

    const checkInitialErrors = async (values) => {
        let isValid = await ContactSchema.isValid(values)
        if (!isValid) refSubmit.current.click();
    };

    const save = (values, { setSubmitting }) => {
        let add = saveAsAtAddressBook && localData ? localData.save_as !== values.save_as : isAdd;

        let newContact = {
            ...(localData ? localData : {}),
            ...values,
            ...(add && saveAsAtAddressBook ? { idd: 0 } : {})
        };

        newContact = {
            idd: newContact.idd,
            fname: newContact.fname,
            lname: newContact.lname,
            email: newContact.email,
            phone: newContact.phone,
            type: newContact.type,
            ctag: newContact.save_as,
            save_as: newContact.save_as
        };

        if (!saveAsAtAddressBook) dispatch(funcs[`${template}${!localData ? '' : 'Upd'}`](newContact));
        else {
            if (showSaveAs) dispatch(funcs[`${template}${!localData ? '' : 'Upd'}`](newContact));
            if (add) delete newContact['save_as'];
            dispatch(saveContact({ json: { dtos: [newContact] } }, false));
        }
        setSubmitting(false);
        onClose();
    };

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        e.preventDefault();
        let result = await validateForm(values);
        if (!Object.keys(result).length) {
            const hasDuplicateValues = isDuplicate(values);
            if (!saveAsAtAddressBook || !isAdd || !hasDuplicateValues) handleSubmit();
            else {
                setHasDupError(true);
                showAlert({ id: 'duplicatedContact', page: '*', message: translate('error.duplicatedContact'), color: "yellow", isModal: true });
            }
        }
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow", isModal: true })
        }
    };

    const handleSaveAs = (v) => {
        setShowSaveAs(v);
        setSaveAsAtAddressBook(v);
    };

    const handleRefSubmit = () => {
        refSubmit.current.click();
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={save}
            key={`form-${country}`} >
            {
                ({ handleSubmit, isSubmitting, getFieldMeta, setFieldValue, values, validateForm, setTouched, setErrors }) => (
                    <form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} data-testid="ctForm" className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-6 px-3 mb-0 form-group mr-md-5">
                                    {
                                        saveAtAddressBook ?
                                            <TextInput isRequired type="text" name={'save_as'} label={'formLabel.addressBookName'} maxLength="255" />
                                            :
                                            <Select
                                                error={getFieldMeta('save_as').touched && getFieldMeta('save_as').error}
                                                onAddNew={(val) => {
                                                    // setLocalData({ save_as: val, fname: '', lname: '', email: '', phone: '', type: '' });
                                                    setFieldValue('save_as', val, false);
                                                }}
                                                value={values.save_as}
                                                text={values.save_as}
                                                onChange={(val) => {
                                                    setContact(val);
                                                    setFieldValue('save_as', addressBook[name].find(x => String(x.idd) === val).ctag, false);
                                                }}
                                                options={addressBook[name].length > 0 ? 
                                                    addressBook[name].filter(x => x && x.ctag).map(x => ({ text: String(x.ctag), code: String(x.idd) })) 
                                                : []}
                                                className="inputSpace"
                                                availableAdd singleSelection
                                                label={translate('formLabel.addressBookName')} />
                                    }
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'fname'} label={'formLabel.firstName'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'lname'} label={'formLabel.lastName'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'email'} label={'formLabel.email'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput isRequired name={'phone'} label={'formLabel.phone'} maxLength="255" numericKeys={!isUSorCA}
                                        customIcon={isUSorCA ? <InfoTooltip spanClass="modal-tooltip">{translate('message.phoneFormat')}</InfoTooltip> : null} />
                                </div>
                                {saveAtAddressBook &&
                                    <div className="col-md-6 px-3 mb-0 form-group">
                                        <Select
                                            value={values.type}
                                            text={(String(values.type) && typeOptions.length) ? typeOptions.find(x => x.code === String(values.type)).text : ''}
                                            onChange={(val) => setFieldValue('type', val, false)}
                                            options={typeOptions}
                                            className="inputSpace"
                                            singleSelection mandatory
                                            error={getFieldMeta('type').touched && getFieldMeta('type').error}
                                            label={translate('formLabel.type')} />
                                    </div>
                                }
                                {
                                    hasDupError &&
                                    <div className="col-12 mt-3">
                                        <label className="errorMessage">{translate('error.duplicatedContact')}</label>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            saveAtAddressBook ?
                                <div className={`toolbar ${!isAdd ? 'd-flex flex-column flex-xl-row justify-content-between' : ''}`}>
                                    {
                                        !isAdd &&
                                        <Link onClick={onDelete} >
                                            <span className="m-0">
                                                <POWRIcon className="fas fa-trash-alt" size=".7rem" color={COLOR.CISCO_BLUE} />
                                            </span>
                                            {translate('button.remove')}
                                        </Link>
                                    }
                                    <div className="d-flex pt-3 pt-xl-0">
                                        <POWRButton type="button" color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                                        <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.save')}</POWRButton>
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    <div className="custom-toolbar">
                                        {/* <label>
                                            <span className="mr-1">
                                                <POWRIcon className={'fas fa-info-circle'} size="1rem" color={COLOR.CISCO_BLUE} />
                                            </span>
                                            {translate('message.addressBookReturn')}
                                            <Link onClick={() => { onClose(); dispatch(clickOutsideReturn('/addressBook')) }}>
                                                {translate('button.addressBook')}
                                            </Link>
                                        </label> */}
                                        <div className="toolbar-content pt-2">
                                            <POWRButton type="button" color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                                            <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.apply')}</POWRButton>
                                            <POWRButton type="button" onClick={() => handleSaveAs(true)}>{translate('button.saveAs')}</POWRButton>
                                            <POWRModal
                                                show={showSaveAs} size="wide"
                                                title={translate('button.saveAs')}
                                                onClose={() => handleSaveAs(false)}
                                            >
                                                <div className="body">
                                                    <TextInput isRequired type="text" name={'save_as'} label={'formLabel.addressBookName'} maxLength="255" />
                                                </div>
                                                <div className="custom-toolbar">
                                                    <label>
                                                        <span className="mr-1">
                                                            <POWRIcon className={'fas fa-info-circle'} size="1rem" color={COLOR.CISCO_BLUE} />
                                                        </span>
                                                        {translate('message.saveAsModal')}
                                                    </label>
                                                    <div className="toolbar-content pt-2">
                                                        <POWRButton type="button" color={'secondary'} onClick={() => handleSaveAs(false)}>{translate('button.cancel')}</POWRButton>
                                                        <POWRButton type="button" disabled={isSubmitting} onClick={() => handleRefSubmit()}>{translate('button.save')}</POWRButton>
                                                    </div>
                                                </div>
                                            </POWRModal>
                                        </div>
                                    </div>
                                </Fragment>
                        }
                    </form>
                )
            }
        </Formik>
    );
};
