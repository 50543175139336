import React, { Fragment, useState } from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRIcon, POWRModal } from "../styledComponents";
import { Button, Link } from "../controls";
import { COLOR } from '../../core/constants/Colors';

export const HelpFaultDetectionModal = props => {
    const { translate } = useTranslate();
    const [instruction, setInstruction] = useState(false);

    return (
        <Fragment>
            <div onMouseOver={() => setInstruction(true)}>
                <Link className="linkComponentInstruction" >
                    <POWRIcon className="fas fa-info-circle mr-2" size="22px" color={COLOR.CISCO_BLUE} />
                    {translate('aux.instruction')}
                </Link>
            </div>
            <POWRModal
                show={instruction}
                title="Инструкция по заполнению/оформлению Акта Дефектовки"
                onClose={() => setInstruction(false)}
            >
                <div className="content">
                    <h2>Акт Дефектовки – это документ, обязательный для возврата неисправного оборудования Cisco.</h2>
                    <p>Необходимо заполнить вручную графы, которые не были заполнены автоматически из POWR. Заполненный акт дефектовки необходимо распечатать на бланке компании в 3-х экземплярах, заверить подписью ответственного лица и печатью организации.</p>
                    <p>Примечание: 3 экземпляра Акта Дефектовки автоматически распечатываются из POWR после сохранения данных и выведения заполненной формы на печать.</p>
                    <br />
                    <h2>Обязательные к заполнению графы:</h2>
                    <p>1. № - номер по порядку.</p>
                    <p>2. RМА № - номер РМА/заказа на возврат неисправного оборудования.
                        *Заполняется автоматически из POWR.</p>
                    <p>3. Парт Номер – артикул возвращаемого неисправного оборудования.
                        *Заполняется автоматически из POWR. При обнаружении неточностей в парт номере необходимо скорректировать его вручную. (Значок информации: Описание оборудования - краткое описание возвращенного неисправного оборудования. *Заполняется автоматически из POWR.)</p>
                    <p>4. Серийный номер – серийный номер возвращаемого неисправного оборудования.
                        *Заполняется автоматически из POWR. При отсутствии серийного номера/обнаружении неточностей необходимо добавить/скорректировать его вручную.</p>
                    <p>5. Страна происхождения – страна производства возвращаемого неисправного оборудования.
                        *Заполняется автоматически из POWR. При отсутствии страны происхождения/обнаружении неточностей необходимо добавить/скорректировать ее вручную (на русском или английском  языке).</p>
                    <p>6. Краткое описание неисправности – краткое описание неисправности возвращаемого оборудования.
                        *Заполняется вручную на русском языке. Максимальное количество символов – 100.</p>
                </div>
                <div className="toolbar">
                    <Button onClick={() => setInstruction(false)} >
                        {translate('button.close')}
                    </Button>
                </div>
            </POWRModal>
        </Fragment>
    )
}
