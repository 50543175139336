import { useDispatch, useSelector } from "react-redux";
import { setReturnFlowProp } from "../redux/actions";
import { SET_SUB_LINES } from "../redux/actionTypes";
import { selectPickedSublinesOfSelectedLines, selectSublines } from "../redux/selectors";
import { selectOldSelectedLines, selectOldSublines, selectShowSublinesPkReset } from '../redux/selectors/returnFlow.selectors';

export const useSelectPartsValidations = () => {
    const dispatch = useDispatch();

    const sublines = useSelector(selectSublines);
    const oldSublines = useSelector(selectOldSublines);
    const oldSelectedLines = useSelector(selectOldSelectedLines);
    const selectedParts = useSelector(selectPickedSublinesOfSelectedLines);
    const showResetModal = useSelector(selectShowSublinesPkReset);

    const hasRemovedSublines = () => {
        let shouldResetPks = false;
        const sublinesWithPackage = sublines.filter(x => x.package_id);
        // console.log(sublinesWithPackage)
        if (sublinesWithPackage.length) {
            for (let sp of sublinesWithPackage) {
                if (!selectedParts.find(x => x.idd === sp.idd)) {
                    shouldResetPks = true;
                    break;
                }
            }
            if (!shouldResetPks) shouldResetPks = selectedParts.length !== sublinesWithPackage.length;
        }
        dispatch(setReturnFlowProp('showSublinesPkReset', shouldResetPks));
        return shouldResetPks;
    };

    const closeResetModal = (shouldResetSublines) => {
        if (shouldResetSublines) {
            dispatch({ type: SET_SUB_LINES, payload: oldSublines });
            dispatch(setReturnFlowProp('selectedLines', oldSelectedLines));
        }
        dispatch(setReturnFlowProp('showSublinesPkReset', false));
    };

    return {
        hasRemovedSublines,
        showResetModal,
        closeResetModal
    };
};