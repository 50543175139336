import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../controls';

const ButtonStructure = ({ className, children, isLoading, ref, ...props }) => {
    return (
        <button className={className} ref={ref} {...props}>
            {
                isLoading &&
                <Spinner
                    className="mr-2"
                    size="22px"
                    color={props.type !== '2' ? '#FFFFFF' : '#099AD6'} />}
            {children}
        </button>
    );
};

export const POWRButton = styled.button`
    width: auto;
    height: 40px;
    min-width: 135px;
    border-radius: 20px;
    font-family: 'CiscoSansTT-Regular', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: solid 1px;
    ${props => props.color === 'secondary' ?
        `border-color: #099AD6;
        background-color: #FFF;
        color: #099AD6;
        &:hover {
            background-color: rgba(9, 154, 214, 0.1);
            border-color: #005073;
        }`
        :
        `border-color: #099AD6;
        background-color: #099AD6;
        color: #FFF !important;
        &:hover {
            background-color: #005073;
            border-color: #005073;
        }`
    }
    ${props => props.disabled &&
        `border-color: #9194a7 !important;
        background-color: #9194a7 !important;
        color: #FFF !important;
        cursor: default !important;
        box-shadow: 0 0 0 0 transparent !important;`
    }
    &:focus, &:active {
        box-shadow: 0 0 2px 1px #099AD6 !important;
    }
    &:not(:focus), &:not(:active) {
        box-shadow: 0 0 0 0 transparent !important;
    }
`;