import React, { useEffect } from 'react';
import { COLOR } from '../../core/constants/Colors';
import { POWRIcon } from '../styledComponents';
import { useTranslate } from '../../core/contexts'

export default props => {

    const { translate } = useTranslate();

    useEffect(() => {
        let newItems = props.allItems.slice((props.page * props.itemsPerPage) - props.itemsPerPage, props.page * props.itemsPerPage);
        props.setItems(newItems);
    }, [props.page])

    const handlePageArrow = (v) => {
        let actualPage = props.page + v;
        props.setPage(actualPage);
    }

    const handlePage = (p) => {
        props.setPage(p);
    }

    useEffect(() => {
        if (props.items.length === 0 && props.allItems.length !== 0 && props.page !== 1) {
            props.setPage(props.pages.length);
        }
    }, [props.items, props.allItems, props.actualPage])

    return (
        <div className="tableFoot">
            <label>{ `${translate('aux.showing')} ${(props.page * props.itemsPerPage) - (props.itemsPerPage-1)} - ${((props.page * props.itemsPerPage) - props.itemsPerPage) + props.items.length} of ${props.allItems.length}`}</label>
            {props.allItems.length > props.itemsPerPage && <div className="pageControler">
                <div
                    className={props.page === 1 ? "pageArrow" : "pageArrow pageArrowActive hover-Btn"}
                    onClick={props.page === 1 ? undefined : handlePageArrow.bind(this, -1)}
                >
                    <POWRIcon
                        className="fal fa-chevron-left"
                        color={COLOR.GREY}
                        size="14px" />
                </div>
                {props.pages[props.page - 3] && props.page !== 3 && <>
                    <div
                        className={props.page === 1 ? "pageBtn pageBtnActive" : "pageBtn hover-Btn"}
                        onClick={handlePage.bind(this, 1)}
                        key={`pageBtn-${1}-First`}
                    >
                        {1}
                    </div>
                    <div className="pageBtn">...</div>
                </>}
                {props.pages.map((p, i) => (p === props.page - 2 || p === props.page - 1 || p === props.page || p === props.page + 1 || p === props.page + 2) && (
                    <div
                        className={props.page === p ? "pageBtn pageBtnActive" : "pageBtn hover-Btn"}
                        onClick={handlePage.bind(this, p)}
                        key={`pageBtn-${p}`}
                    >
                        {p}
                    </div>
                ))}
                {props.pages[props.page + 2] && props.page !== props.pages.length && <>
                    <div className="pageBtn">...</div>
                    <div
                        className={props.page === props.pages.length ? "pageBtn pageBtnActive" : "pageBtn hover-Btn"}
                        onClick={handlePage.bind(this, props.pages.length)}
                        key={`pageBtn-${props.pages.length}-Last`}
                    >
                        {props.pages.length}
                    </div>
                </>}
                <div
                    className={props.page >= props.pages.length ? "pageArrow" : "pageArrow pageArrowActive hover-Btn"}
                    onClick={props.page >= props.pages.length ? undefined : handlePageArrow.bind(this, 1)}
                >
                    <POWRIcon
                        className="fal fa-chevron-right"
                        color={COLOR.GREY}
                        size="14px" />
                </div>
            </div>}
        </div>
    );
}