import request from "../core/api/request";
import { URL_SAVE_BILLING_ADDRESS, URL_GET_BILLING_ADDRESS } from "../core/constants/URLS";

export const addressService = {
    getUserAddresses: () => {
        return request.getURIEncoded({ url: `get/AddressUserProfile?_dc=${Date.now()}` });
    },
    saveAddressToAddressBook: (data) => {
        return request.postURIENCODEDJSONSave({ url: `save/user_contact_address`, data });
    },
    deleteAddress: (id) => {
        return request.postEncode({ url: `del/user_contact_address?idds=${id}`})
    },
    saveBillingAddress: (data) => {
        return request.postURIENCODEDJSONSave({url:URL_SAVE_BILLING_ADDRESS, data})
    },
    getBillingAddress: (shipId) => {
        return request.getURIEncoded({url:`${URL_GET_BILLING_ADDRESS}${shipId}`})
    }

};