import React, { useEffect } from "react";
import { useTranslate } from "../../core/contexts";
import { SpacedPage } from "../../components/AppShell/partials/SpacedPage.react";
import ShipmentsHome from "./shipmentsHome";
import RequestReturn from "./requestReturn";
import Documentation from "./documentation";
import SearchShipment from "./searchShipment";
import PrintedWaybill from "./printedWaybill";
import DownloadLabels from "./downloadLabels";
import { STORAGE } from "../../core/constants";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../core/redux/selectors";

export default (props) => {
    const { translate } = useTranslate();

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        clearSessionStorage();
    }, [])

    const clearSessionStorage = () => {
        sessionStorage.removeItem(STORAGE.SEARCH_NAME)
        sessionStorage.removeItem(STORAGE.SAVE_NAME)
        sessionStorage.removeItem(STORAGE.CONTACT_INFO)
        sessionStorage.removeItem(STORAGE.LABEL_ONLY)
        sessionStorage.removeItem(STORAGE.RESUME_PICKUP)
        sessionStorage.removeItem(STORAGE.RESUME_SHIPMENT)
    }

    return (
        <SpacedPage>
            <div className="row">
                <div className={`${(userInfo && userInfo.factReturnsAllowed) ? 'col-lg-8' : 'col-12'}`}>
                    <div>
                        <h1>{`${translate("title.goodDay")}${userInfo.name}!`}</h1>
                        <label className="pb-16">{translate("subtitle.home")}</label>
                    </div>
                </div>
                {(userInfo && userInfo.factReturnsAllowed) &&
                    <div className="col-lg-4 mb-4">
                        <SearchShipment withoutCard />
                    </div>
                }
                <div className="col-md-12 col-lg-8 pb-2 pb-lg-3">
                    <RequestReturn isFA={false} clearStorage={clearSessionStorage} />
                </div>
                <div className="col-md-12 col-lg-4 pb-2 pb-lg-3">
                    {
                        (userInfo && userInfo.factReturnsAllowed) ?
                            <RequestReturn  isFA clearStorage={clearSessionStorage} />
                            : <SearchShipment />
                    }
                </div>
                {
                    (userInfo && userInfo.waybillAllowed) &&
                    <div className="col-md-12 col-lg-4 pb-2 pb-lg-0">
                        <PrintedWaybill />
                    </div>
                }
                <div className={`col-md-12 ${!(userInfo && (!userInfo.waybillAllowed || !userInfo.documentAccessAllowed)) ? 'col-lg-4' : 'col-lg-8'} pb-2 pb-lg-0`}>
                    <DownloadLabels />
                </div>
                {
                    (userInfo && userInfo.documentAccessAllowed) &&
                    <div className="col-md-12 col-lg-4 pb-lg-0">
                        <Documentation clearStorage={clearSessionStorage} />
                    </div>
                }
                <div className="col-12 pt-3">
                    <ShipmentsHome />
                </div>
            </div>
        </SpacedPage>
    );
};
