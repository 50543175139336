import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useTranslate } from '../core/contexts';
import { selectWayBillInfo, selectCarrierProfileIdWayBill, selectCountries } from '../core/redux/selectors';
import { Input, InputCalendar, CheckRadio, Select, ActionButtons, Spinner, Button, Link } from './controls';
import { getCarrierProfileSWB, getZones, saveCWB, getCarrierProfile } from '../services';
import { setWaybill, loadCountries } from '../core/redux/actions';
import { InfoSection, POWRIcon, POWRModal } from './styledComponents';
import { Fragment } from 'react';
import { useAlert, usePickupDate } from '../core/hooks';
import { REGEXP, COLOR, SEARCH_PREPRINTED_WAYBILL } from '../core/constants';
import moment from 'moment';
import { PickupLocationWaybillSchema } from '../core/schemas';

const ScheduleWaybill = (props) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { showAlert, removeAlerts } = useAlert();
    const { getPickupDates } = usePickupDate();
    const [additional, setAdditional] = useState('');
    const [mails, setMails] = useState([{ email: "", error: false }]);
    const [validateInputs, setValidateInputs] = useState(true);
    const [dueDate, setDueDate] = useState(moment().format('YYYY-MM-DD'));
    const [carrier, setCarrier] = useState('');
    const [isValidForm, setIsValidForm] = useState(false);
    const [time, setTime] = useState(10);
    const [carriers, setCarriers] = useState([]);
    const dateFormat = sessionStorage.getItem('dateFormat');
    const [showLoading, setShowLoading] = useState(false);
    const [showModalValidation, setShowModalValidation] = useState(false);
    const [finish, setFinish] = useState(false);
    const history = useHistory();
    const waybillInfo = useSelector(selectWayBillInfo);
    const carrierProfileId = useSelector(selectCarrierProfileIdWayBill);
    const [serviceDisabledDays, setServiceDisabledDays] = useState([]);
    const [disabledDates, setDisabledDates] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [specialInstrucLimit, setSpecialInstrucLimit] = useState(0);
    const refSubmit = useRef();

    const countries = useSelector(selectCountries);

    useEffect(() => {
        if (countries.length === 0) dispatch(loadCountries());
            if(waybillInfo && waybillInfo.country){
                let instrucLimit = countries && countries.find(x => x.code === waybillInfo.country)
                setSpecialInstrucLimit(instrucLimit && instrucLimit.site_instructions_max_length)
                
                if (additional.length > instrucLimit) setAdditional(additional.substr(0, specialInstrucLimit));
            }
        
    }, [countries]);

    //set all values related to carrier
    useEffect(() => {
        if (waybillInfo) {
            setShowLoading(true);
            setTime(waybillInfo.pickup_schedule_time);


            //Could be use to get carriers available for this waybill
            loadSelectsOptions().then((options) => {
                if (options.length === 1) {
                    setCarrier(options[0].code);
                }
                if (options.length === 0) handleAlert();
                setCarriers(options);
                setShowLoading(false)
            }).catch(() => setShowLoading(false));
        }
        setShowLoading(false)
    }, []);

    useEffect(() => {
        if (carriers.length === 1) {
            let currentCarrier = carriers[0];
            if (currentCarrier) getTimeZoneData().then(res => {
                let currentLocationDate = res[0] ? res[0].location_date : null;
                if (currentLocationDate && currentCarrier) {
                    let pickupDates = getPickupDates(currentCarrier, currentLocationDate);
                    if (pickupDates.dates.length) setDueDate(moment(pickupDates.dates[0]).format("YYYY-MM-DD"));
                    if (pickupDates.noDates < 1) setServiceDisabledDays(currentCarrier.service_disabled_days);
                    setDisabledDates(pickupDates.noDates);
                    setAvailableDates(pickupDates.dates);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }, [carriers])
    const getTimeZoneData = () => new Promise((resolve, reject) => {

        let params = {
            pickup_city: waybillInfo.city,
            pickup_province: waybillInfo.state,
            pickup_country: waybillInfo.country
        };


        getZones(params).then(response => {
            if (response.status === 200) {
                resolve(response.res.dtos);
            } else {
                reject(response);
            }
        }).catch(error => {
            showAlert({ id: 'unexpected', message: translate('message.unexpectedSituation'), page: '/schedule', color: 'red', time: false });
            reject(error);
        })
    });

    //update additional information input
    const handleChange = function (e) {
        let value = e.target.value;
        if (REGEXP.ADDITIONAL_INFO.test(value)) {
            setAdditional(value)
        }
    };

    //update mails
    const handleChangeMail = (e, i) => {
        let value = e.target.value;
        let values = [...mails];

        if (REGEXP.EMAIL_NOTI.test(e.target.value)) {
            values[i] = { email: value, error: false };

        }
        else {
            if (values.length === 1 && value === "")
                values[i] = { email: value, error: false };
            else
                values[i] = { email: value, error: true };
        }

        setMails(values);
        setValidateInputs(values.filter(a => a.error).length == 0);
    }

    const addNewMail = function () {
        let values = [...mails, { email: "", error: false }];
        setMails(values);
    }

    //remove mails
    const handleRemove = function (i) {
        let values = [...mails];

        values.splice(i, 1);
        setMails(values);
        if (values.length === 1 && values[0].email === "")
            values[0] = { email: "", error: false };
        setValidateInputs(values.filter(a => a.error).length == 0);
    }

    //no carriers found
    const handleAlert = () => {
        showAlert({
            id: "noCarriersFound",
            message: translate('message.noCarriers'),
            page: '/schedule',
            color: 'red',
        })
    };

    //set carriers from service
    //this method could be used to get the carriers from waybill
    const loadSelectsOptions = () => {
        return getCarrierProfileSWB(carrierProfileId).then(response => {
            if (response && response.res.success) {
                let formattedCarriers = response.res.dtos.map((item) => ({
                    ...item,
                    text: item.carrier_code,
                    code: item.carrier_code,
                }));

                return formattedCarriers;
            }
        }).
            catch(console.log);
    };

    const handleDueDate = (v, mobile = false) => {
        // Check the date format and value
        let newDate = mobile ? v : moment(v, dateFormat).format('YYYY-MM-DD');
        if (newDate !== "Invalid date" && new Date(newDate) >= new Date()) setDueDate(newDate);
    };

    useEffect(() => {
        if (props.formRef.current) {
            setIsValidForm(props.formRef.current.isValid)
        }
    }, [props])

    //validate fields in redux are not empty
    const canSave = useMemo(() =>
        time != 10

        , [time]);

    //save schedule
    const handleSubmit = async function () {
       
        if (props.formRef.current) {
            var isValid = await PickupLocationWaybillSchema.isValid(props.formRef.current.values);
            if (props.formRef.current.isValid) {
                props.formRef.current.handleSubmit()
                let m = mails.map(a => a.email);

                //map all the info from redux if has not errors
                let body = {
                    ship_id: waybillInfo.idd,
                    json: {
                        ship_code: waybillInfo.ship_code || "",
                        rma_num: waybillInfo.rma_num[0],
                        serial: "",
                        depth: waybillInfo.depth || "",
                        width: waybillInfo.width || "",
                        height: waybillInfo.height || "",
                        total_packages: waybillInfo.total_packages || "",
                        total_weight: waybillInfo.total_weight || "",
                        company: waybillInfo.company || "",
                        name: waybillInfo.name || "",
                        addr1: waybillInfo.addr1,
                        addr2: waybillInfo.addr2 || "",
                        addr3: waybillInfo.addr3 || "",
                        addr4: waybillInfo.addr4 || "",
                        city: waybillInfo.city || "",
                        state: waybillInfo.state || "",
                        postal_code: waybillInfo.postal_code || "",
                        country: waybillInfo.country || "",
                        telephone: waybillInfo.telephone || "",
                        carrier_description: waybillInfo.carrier_description || "",
                        tracking_number: waybillInfo.tracking_number.join(','),
                        date_pickup: dueDate,
                        pickup_schedule_time: time,
                        pickup_id: waybillInfo.pickup_id || "",
                        carrier_profile_id: waybillInfo.carrier_profile_id || "",
                        type: waybillInfo.type || "",
                        idd: waybillInfo.idd || "",
                        special_instructions: additional,
                        shipping_notifications: m.join(';'),
                    },

                    pickup_id: 0
                }
                if (!isValid) setShowModalValidation(true)
                else
                    saveCWB(body).then(res => {
                        const params = { _dc: Date.now(), ship_id: waybillInfo.idd };
                        getCarrierProfile(params).then(response => {
                            if (response.res.dtos) {
                                if (response.res.dtos[0].courier_dispatch_error) {
                                    sendAlert(response.res.dtos[0].courier_dispatch_error)
                                }
                                else {
                                    removeAlerts();
                                    dispatch(setWaybill({ ...waybillInfo, confirmation_number: response.res.dtos[0].confirmation_number }))
                                    setFinish(true)
                                }

                            }
                        });

                    }).catch(console.log);
            }
            else {
                setShowModalValidation(true)

            }
        }
    };
    const sendAlert = (error) => {
        let code = error.substring(error.lastIndexOf('=') + 1, error.length - 1);

        let message = "";
        if (code === '9500505') {
            message = translate('message.invalidState');
            props.formRef.current.setFieldError('province', 'aux.empty');
        }
        if (code === '9510115') {
            message = translate('message.carrierNotAvailable');
        }

        if (code === '9510116') {
            message = error;
            props.formRef.current.setFieldError('postal', 'aux.empty');
        }
        if (code === '9510130') {
            message = error;
            props.formRef.current.setFieldError('addr1', 'aux.empty');
        }

        if (code === '9500504') {
            message = error;
            props.formRef.current.setFieldError('city', 'aux.empty');
        }
        if (code === '9510150') {
            message = error;

            props.formRef.current.setFieldError('province', 'aux.empty');
            props.formRef.current.setFieldError('city', 'aux.empty');
            props.formRef.current.setFieldError('postal', 'aux.empty');
        }
        if (code === '9500537') {
            message = error;
            props.formRef.current.setFieldError('phone', 'aux.empty');
        }


        showAlert({
            id: Date.now(),
            message: message === "" ? error : message,
            page: '/schedule',
            color: "red",
        })
    }
    const goBack = () => {
        history.push(SEARCH_PREPRINTED_WAYBILL);
    }

    return (
        <div className="schedulePickUp schedule-waybill">
            <Fragment>
                {waybillInfo && !showLoading ?
                    <InfoSection type="taskBar" hideBorders title={translate('title.pickupSchedule')}>
                        <div className="pt-2">
                            <Select
                                value={waybillInfo.carrier_description}
                                onChange={(val) => setCarrier(val)}
                                options={[{ text: waybillInfo.carrier_description, code: waybillInfo.carrier_description }]}
                                className="inputSpace"
                                singleSelection
                                label={translate('formLabel.shipCarrier')}
                                placeholder={translate('formLabel.shipCarrier')}
                                disabled={true} />

                            <InputCalendar
                                minDate={availableDates[0] || moment().startOf('day').toDate()}
                                maxDate={availableDates[availableDates.length - 1]}
                                text={moment(dueDate).format(dateFormat)}
                                value={moment(dueDate).format("DD/MM/YY")}
                                label={translate('title.date')}
                                placeholder={dateFormat}
                                onBlur={(v, m) => handleDueDate(v, m)}
                                className="mb-3 pr-1"
                                disabledDays={serviceDisabledDays.length > 0}
                                disabledDates={serviceDisabledDays.length ? serviceDisabledDays : disabledDates}
                                noPostion
                                disabled={finish}
                                showValueOnDisabled={finish}
                            />

                        </div>
                        <div className="pt-2">
                            <h3>{translate('title.time')}</h3>
                            <div className="d-flex flex-column">
                                <CheckRadio
                                    id={'time1'}
                                    name="time"
                                    text={translate("scheduleTimeType.any")}
                                    checked={time === 0}
                                    onChange={() => setTime(0)}
                                    disabled={finish}
                                />

                                <CheckRadio
                                    id={'time2'}
                                    name="time"
                                    text={translate("scheduleTimeType.am")}
                                    checked={time === 1}
                                    onChange={() => setTime(1)}
                                    disabled={finish} />

                                <CheckRadio
                                    id={'time3'}
                                    name="time"
                                    text={translate("scheduleTimeType.pm")}
                                    checked={time === 2}
                                    onChange={() => setTime(2)}
                                    disabled={finish} />

                            </div>
                        </div>
                        <div className="pt-2">
                            <div className="infoHead">
                                <h3>{translate('title.additionalInfo')}</h3>
                            </div>
                            <p>{translate('message.additionalInfo').replace('{0}', '255')}</p>
                            <textarea
                                maxLength={specialInstrucLimit}
                                name="additional"
                                value={additional}
                                placeholder={translate('placeholder.siteIntructions')}
                                onChange={(e) => handleChange(e)}
                                disabled={finish} />
                        </div>
                        <div className="pt-4">
                            <div className="infoHead">
                                <h3>{translate("title.emailNotification")}</h3>
                            </div>

                            <div className="infoBody">
                                {
                                    mails.map((item, i) => {
                                        return <div className="row" key={i}>
                                            <div className="col-12">
                                                <Input disabled={finish} error={mails[i].error} errorMessage={translate("error.email")} value={mails[i].email} onChange={(e) => handleChangeMail(e, i)} label="Email" placeholder="Add email address" />
                                                {mails.length > 1 && <div className="iconPosition" onClick={() => handleRemove(i)}>
                                                    <POWRIcon className="far fa-trash-alt mt-4" color={COLOR.CISCO_BLUE} size="16px" />
                                                </div>}
                                            </div>
                                        </div>
                                    })
                                }
                                <div className="text-right">
                                    <Link
                                        className="mb-4"
                                        disabled={finish || !(mails[mails.length - 1] && mails[mails.length - 1].email && !mails[mails.length - 1].error)}
                                        onClick={finish ? '' : addNewMail}
                                    >
                                        + {translate("title.additionalEmail")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </InfoSection>
                    :
                    <Spinner
                        className="ml-40"
                        size="70px"
                        color={'#099AD6'}
                    />
                }
                <div className="pt-2">
                    {!finish ?
                        <ActionButtons
                            secondary={() => goBack()}
                            sText={translate('button.back')}
                            primary={handleSubmit}
                            pText={translate('button.confirm')}
                            hideCancelAndSave
                            completed={true} />
                        :
                        <div className="btnsContainer">
                            <Button className="widthBtn" onClick={goBack} >
                                {translate('button.finish')}
                            </Button>
                        </div>
                    }
                </div>
            </Fragment>
            <POWRModal
                size="tiny"
                show={showModalValidation}
                title={translate('title.validation')}
                onClose={setShowModalValidation.bind(this, false)} >
                <div className="content">
                    <p>{translate('message.mandatoryFieldsAll')}</p>
                </div>
                <div className="toolbar">
                    <Button onClick={setShowModalValidation.bind(this, false)} >
                        {translate('button.ok')}
                    </Button>
                </div>
            </POWRModal>

        </div>
    );
}

export default ScheduleWaybill;