import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslate } from '../core/contexts';
import { Pagination, DropDown, Button, Table, Status, Link } from './controls';
import { POWRModal, POWRButton, POWRIcon } from '../components/styledComponents';
import { WayBillStatus } from '../core/constants/WayBillStatus';
import { SHIPMENT_PAGE_ITEMS, MAX_WEIGHT_UPS } from "../core/constants/Common";
import { DASHBOARD } from '../core/constants/ROUTES';
import { GRID_COLUMNS } from '../core/constants';
import { loadPackages, loadSubLines, setWaybill } from '../core/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPackagesWithSublines } from '../core/redux/selectors';
import { useHistory } from "react-router-dom";
import { getScheduleSWB } from '../services';
import { COLOR } from '../core/constants/Colors';
import { useConfigParams } from '../core/hooks';

export default props => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    const { getFaqLink, getContactUsLink } = useConfigParams();
    const rmasPackages = useSelector(selectPackagesWithSublines);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [items, setItems] = useState([]);
    const [order, setOrder] = useState(0);
    const [reverse, setReverse] = useState(false);
    const [viewPackage, setViewPackage] = useState([]);
    const [showViewPackage, setShowViewPackage] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [waybillInfo, setWaybillInfo] = useState(null);

    const dateFormat = sessionStorage.getItem('dateFormat');
    const faqLink = getFaqLink();
    const contactUsLink = getContactUsLink();

    useEffect(() => {
        if (props.items) setAllItems(props.items);
    }, [props.items]);

    useEffect(() => {
        handlePages(allItems);
    }, [allItems, page]);

    const handlePages = (aItems) => {
        let newItems = page === 1 ?
            aItems.slice(0, SHIPMENT_PAGE_ITEMS) :
            aItems.slice((page * SHIPMENT_PAGE_ITEMS) - SHIPMENT_PAGE_ITEMS, page * SHIPMENT_PAGE_ITEMS);
        setItems(newItems);
        let nPages = Math.ceil(aItems.length / SHIPMENT_PAGE_ITEMS);
        let ps = [];
        for (var i = 1; i <= nPages; i++) {
            ps.push(i);
        }
        setPages(ps);
    }

    const handleOrder = (o, value) => {
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(false);
        }
        setOrder(o);

        let r = order === o ? !reverse : false;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        let newOrder = allItems.sort(compare);
        setAllItems(newOrder);
        handlePages(newOrder);
    }


    useEffect(() => {
        if (rmasPackages.length) {
            setViewPackage(rmasPackages);

        }
    }, [rmasPackages]);

    const handleViewPackage = (id, rma) => {
        dispatch(loadPackages(id, true)).then(() => {
            dispatch(loadSubLines(id, rma, true));
        });
        setShowViewPackage(true);
    }

    const columns = [
        { id: 0, value: 'ship_code', type: 'tex', text: translate('gridColumns.shipmentID') },
        { id: 1, value: 'date_created', type: 'dat', text: translate('gridColumns.created') },
        { id: 2, value: 'principal', type: 'tex', text: translate('gridColumns.createdBy') },
        { id: 3, value: 'carrier_description', type: 'tex', text: translate('gridColumns.carrier') },
        { id: 4, value: 'rmas', array: true, type: 'num', text: translate('gridColumns.rmas') },
        { id: 5, value: 'date_pickup', type: 'dat', text: translate('gridColumns.pickupRequest') },
        { id: 6, value: 'tns', array: true, type: 'num', text: translate('gridColumns.tracking') },
        { id: 7, value: 'ship_event', type: 'tex', text: translate('gridColumns.status') },
        { id: 8, value: '', text: '' }
    ];

    const createdDate = (date) => {
        return date && moment(`${date}`, 'YYYYMMDD').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'YYYYMMDD').format(dateFormat) : '—';
    }

    const returnedDate = (date) => {
        return date && moment(`${date}`, 'MM/DD/YYYY').format(dateFormat) !== 'Invalid date' ?
            moment(`${date}`, 'MM/DD/YYYY').format(dateFormat) : '—';
    }
    const goHome = () => {
        history.push(DASHBOARD);
    }
    const goDetails = () => {
        if (waybillInfo != {}) {
            dispatch(setWaybill(waybillInfo))
            history.push('/schedule?id=' + waybillInfo.ship_code);
        }

    }

    const handleViewDetails = (id, shipCpde) => {
        getScheduleSWB(id).then(response => {
            if (response.res) {
                let dtos = response.res.dtos;

                if (dtos[0].total_weight > MAX_WEIGHT_UPS) {
                    setWaybillInfo(dtos[0])
                    setConfirmation(true);
                }

                else {
                    dispatch(setWaybill(dtos[0]))
                    history.push('/schedule?id=' + shipCpde);
                }


            }
        }).catch(error => {

        });

    }
    return items && items.length > 0 ?
        (
            <div className="shipmentsListCard shipmentsListCardFull">
                <table className="d-none d-lg-table">
                    <thead>
                        <tr className="shipmentsTitles">
                            {columns.map((c, i) => (
                                <th
                                    className={i === columns.length - 1 ? "shipmentsTH min-width" : "shipmentsTH"}
                                    onClick={handleOrder.bind(this, i, c.value)}
                                    key={`column-${i}-${reverse}-of-${order}`}
                                >
                                    <div className="shipmentsTitle">
                                        {c.text}
                                        {c.text && <POWRIcon
                                            className={`far fa-${order === i && reverse ? "arrow-up" : "arrow-down"} ml-2`}
                                            size="10px"
                                            color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY} />}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map((item, i) => {
                                return (
                                    <tr
                                        className="shipmentRow"
                                        key={`shipment-${i}`}
                                        onClick={props.onClick ? props.onClick.bind(this, item.idd) : undefined}
                                    >
                                        <td><div className="td boldType">{item.ship_code}</div></td>
                                        <td><div className="td" title={createdDate(item.date_created)}>
                                            {createdDate(item.date_created)}
                                        </div></td>
                                        <td><div className="td" title={item.principal}>
                                            {item.principal}
                                        </div></td>
                                        <td><div className="td">
                                            {item.carrier_description || '—'}
                                        </div></td>
                                        <td><div className="td boldType">
                                            {item.rmas ? item.rmas.join(', ') : '—'}
                                        </div></td>
                                        <td><div className="td" title={`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}>
                                            {`${returnedDate(item.date_pickup)} ${item.pickup_schedule_time ? item.pickup_schedule_time : ''}`}
                                        </div></td>
                                        <td><div className="td" title={item.tns ? item.tns.join(', ') : '—'}>
                                            {item.tns ? item.tns.join(', ') : '—'}
                                        </div></td>
                                        <td key={`status-${item.ship_event - 1}_order-${order}_reverse-${reverse}`}>
                                            <Status className="td" status={WayBillStatus[item.ship_event - 1]} />
                                        </td>
                                        <td className="text-center" key={`actions-${item.ship_event - 1}_order-${order}_reverse-${reverse}`}>
                                            <div className="actionsDropDown">
                                                {WayBillStatus[item.ship_event - 1] ?
                                                    <DropDown options={[
                                                        {
                                                            icon: 'far fa-calendar-plus', text: translate('title.schedulePickup'), handler: () => handleViewDetails(item.idd, item.ship_code)
                                                        }, {
                                                            icon: 'far fa-boxes', text: translate('title.viewShipment'), handler: () => handleViewPackage(item.idd, item.rmas)
                                                        }
                                                    ]}
                                                        fullId={item.ship_code}
                                                        labelId={item.idd}
                                                        position="right" />
                                                    : <div />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <div className="d-block d-lg-none">
                    {items.map((item, i) => {
                        return (
                            <div className="mini-shipment" key={i}>
                                <div className="col-2 pl-0">
                                    {WayBillStatus[item.ship_event - 1] &&
                                        <POWRIcon className="fas fa-circle" size="10px" color={WayBillStatus[item.ship_event - 1].color} />
                                    }
                                </div>
                                <div className="col-10 pl-0">
                                    <div className="row columns">
                                        <div className="col main">{item.ship_code}</div>
                                        <div className="col-auto p-0">
                                            {WayBillStatus[item.ship_event - 1] ?
                                                <DropDown options={[
                                                    {
                                                        icon: 'far fa-calendar-plus', text: 'Schedule Pickup', handler: () => handleViewDetails(item.idd, item.ship_code)
                                                    }, {
                                                        icon: 'far fa-boxes', text: 'View Shipments', handler: () => handleViewPackage(item.idd, item.rmas)
                                                    }
                                                ]}
                                                    fullId={item.ship_code}
                                                    labelId={item.idd}
                                                    position="right" />
                                                : <div />
                                            }
                                        </div>
                                    </div>
                                    <div className="row columns">
                                        <div className="col secondary">{item.principal}</div>
                                        <div className="col secondary p-0">{item.carrier_description}</div>
                                    </div>
                                    <div className="row columns">
                                        <div className="col light">{createdDate(item.date_created)}</div>
                                        <div className="col light p-0">{returnedDate(item.date_pickup)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <Pagination
                    itemsPerPage={SHIPMENT_PAGE_ITEMS}
                    items={items}
                    setItems={setItems}
                    pages={pages}
                    page={page}
                    setPage={setPage}
                    allItems={allItems}
                />
                <POWRModal
                    size="long"
                    show={showViewPackage}
                    title={translate('title.viewShipment')}
                    onClose={setShowViewPackage.bind(this, false)} >
                    <div className="content">
                        <Table
                            columns={GRID_COLUMNS.viewPackageDetail}
                            type="collapse"
                            dataSource={viewPackage}
                            noPaging
                        />
                    </div>
                    <div className="toolbar">
                        <Button onClick={setShowViewPackage.bind(this, false)} >
                            {translate('button.ok')}
                        </Button>
                    </div>
                </POWRModal>
                <POWRModal
                    title={translate('title.UPSWeightExceeds')}
                    show={confirmation}
                    size="small"
                    onClose={() => setConfirmation(!confirmation)}
                >
                    <p>{`${translate('message.wightExceeds1')}`} <span className="textBold">{MAX_WEIGHT_UPS} lbs.</span></p>
                    <p>{translate('message.wightExceeds2')}<span className="textBold">{waybillInfo && waybillInfo.total_weight} lbs.</span></p>
                    <p>{`${translate('message.wightExceeds3')}`}</p>
                    <div className="toolbar">
                        <POWRButton type="button" color={'secondary'} onClick={() => goHome()}>
                            {translate('button.cancel')}
                        </POWRButton>
                        <POWRButton onClick={() => goDetails()} >
                            {translate('button.continue')}
                        </POWRButton>
                    </div>
                </POWRModal>
            </div>
        ) : (
            <div className="shipmentsNoData shipmentsNoDataSmall">
                <div className="iconContainer" key={`icon-${items.length}`}>
                    <POWRIcon
                        className="fal fa-search"
                        color={COLOR.GREY}
                        size="56px" />
                </div>
                <div className="textNoData">
                    <h2>{translate('aux.noResults')}</h2>
                    <p>{translate('aux.refine')} <Link target="_blank"  href={faqLink} > {translate("aux.fAQSLink")}</Link> {translate('aux.refine2')} <Link target="_blank"  href={contactUsLink} > {translate("message.assetRecoveryAgent")}</Link> {translate('message.countryRMAError2')}</p>
                </div>
            </div>
        );

}
