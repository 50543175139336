export const COLOR = {
    CISCO_BLUE: "#099AD6",
    GREY: "#9194A7",
    DARK_GREY_1: "#58585B",
    GREEN: "#40c90e",
    RED: "#ff4646",
    YELLOW: "#ffb600",
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    ORANGE: "#FFA500"
};