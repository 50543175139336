export const SHIPMENT_PAGE_ITEMS = 16;
export const ADDRESS_TABLE_ITEMS = 6;
export const CONTACT_TABLE_ITEMS = 6;
export const EVENT_TABLE_ITEMS = 6;
export const TOTAL_QTY_ORDERED = 1000;
export const MAX_WEIGHT_UPS = 100;
export const PACKAGES_PAGE_ITEMS = 8;

export const PING_TIME = 1740000;

export const PDF = ".pdf";
export const WORD = ".docx";