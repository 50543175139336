import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useTranslate } from "../../../core/contexts";
import { FeedbackModal } from "../../modals";

const Link = styled.a`
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
`;

const LinkModal = styled.span`
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
`;

const LinkText = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  flex-grow: 1;
  color: #ffffff;
  font-family: "CiscoSansTT-Regular", sans-serif;
  font-size: 14px;
  white-space: nowrap;
  transition: opacity 0.3s;

  font-weight: 300;
  font-size: 12px;
`;

const LinkWrapper = styled.li`
  position: relative;
  line-height: 16px;

  &::before {
    content: "";

    width: 1px;
    position: absolute;
    background-color: ${({ theme: { colors } }) => colors.white};
    bottom: 10%;
    top: 10%;
    left: ${({ theme: { gap } }) => "-" + gap[1]};
  }
`;

const NavLink = ({ id, to, isActive, modal }) => {
  const { translate } = useTranslate();
  const [feedback, setFeedback] = useState(false);

  return (
    <LinkWrapper>
      {modal == undefined ?
        <Link target="_blank" href={to} $isActive={isActive}>
          <LinkText>{translate(id)}</LinkText>
        </Link>
        :
        <LinkModal onClick={() => setFeedback(!feedback)}>
          <LinkText>{translate(id)}</LinkText>
        </LinkModal>
      }
      {feedback != null &&
        <FeedbackModal show={feedback} onClose={() => setFeedback(!feedback)} />
      }
    </LinkWrapper>
  );
};

export const NavigationLink = withRouter(({ location, ...props }) => {
  // IsActive Functionality
  const isActive = location.pathname === props.to;

  return <NavLink {...props} isActive={isActive} />;
});
