import styled from "styled-components";

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 0 32px;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  background-color: ${({ theme: { colors } }) => colors.ciscoBlue};
  align-items: center;
  width: 100%;
  padding-left: 112px;

  > * {
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    ${props => props.wizard ? 'padding-right: 357px' : ''};
    ${props => props.wizard ? 'margin-top: -60px' : ''};
  }
  
  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    height: 240px;
    max-height: 240px;
    margin-top: 0;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    margin-left: 0px;
    padding-right: 32px;
    padding-left: 32px;
  }
`;

