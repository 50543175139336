import Request from "../core/api/request";
import { URL_SAVEPICKUP, URL_REENABLEDENIEDPICKUP, URL_CANCELSHIPMENT } from "../core/constants/URLS";

export const savePickup = (data) => {
    return Request.postURIENCODEDJSONSave({ url: URL_SAVEPICKUP, data });
};

export const reenableDeniedPickup = (data) => {
    return Request.postEncode({ url: URL_REENABLEDENIEDPICKUP, data });
};

export const cancelPickup = (data) => {
    return Request.postEncode({ url: URL_CANCELSHIPMENT, data });
};

export const getPickupById = (id) => {
    return Request.getURIEncoded({ url: `get/pickup?pickup_id=${id}` });
};
