import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPackages, selectPackagesWithSublines } from '../core/redux/selectors';
import { Pagination } from './controls';
import { POWRIcon } from './styledComponents';
import { PACKAGES_PAGE_ITEMS } from "../core/constants/Common";
import { useTranslate } from '../core/contexts';
import { COLOR } from '../core/constants/Colors';
import { ViewPackageModal } from './modals';

const ViewPackagesTable = ({ hideTN = false, isReleaseLinesEnabled = false, shipId, rmas }) => {

    const { translate } = useTranslate();
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [items, setItems] = useState([]);
    const [reverse, setReverse] = useState(false);
    const [order, setOrder] = useState(0);

    const pks = useSelector(selectPackages);
    const rma_packages = useSelector(selectPackagesWithSublines);

    useEffect(() => {
        setAllItems(pks);
    }, [pks]);

    useEffect(() => {
        handlePages(allItems);
    }, [allItems, page]);

    const handlePages = (aItems) => {
        let newItems = page === 1 ?
            aItems.slice(0, PACKAGES_PAGE_ITEMS) :
            aItems.slice((page * PACKAGES_PAGE_ITEMS) - PACKAGES_PAGE_ITEMS, page * PACKAGES_PAGE_ITEMS);
        setItems(newItems);
        let nPages = Math.ceil(aItems.length / PACKAGES_PAGE_ITEMS);
        let ps = [];
        for (var i = 1; i <= nPages; i++) {
            ps.push(i);
        }
        setPages(ps);
    }

    const handleOrder = (o, value) => {
        if (order === o) {
            setReverse(!reverse);
        } else {
            setReverse(true);
        }
        setOrder(o);

        let r = order === o ? !reverse : true;
        const compare = (a, b) => {
            let av = a[value] ? typeof (a[value]) === 'object' ? a[value][0] : a[value] : '0';
            let bv = b[value] ? typeof (b[value]) === 'object' ? b[value][0] : b[value] : '0';
            if (av < bv) {
                return r ? -1 : 1;
            }
            if (av > bv) {
                return r ? 1 : -1;
            }
            return 0;
        }

        let newOrder = allItems.sort(compare);
        setAllItems(newOrder);
        handlePages(newOrder);
    }

    const columns = [
        { id: 0, value: 'rma', text: translate('title.rma') },
        { id: 1, value: 'package', text: translate('title.package') },
        { id: 1, value: 'tn', text: translate('gridColumns.tracking') },
        { id: 2, value: 'weight', text: translate('title.weight') },
        { id: 3, value: 'size', text: translate('gridColumns.dimensions') },
        { id: 4, value: 'productQty', text: translate('gridColumns.qty') }
    ];

    return (
        <div className="viewPackagesTable">
            <table className="d-none d-lg-table">
                <thead>
                    <tr className="packagesTitles">
                        {columns.map((c, i) => {
                            return (

                                (!hideTN || (hideTN && c.value !== 'tn')) &&
                                <th onClick={() => handleOrder(i, c.value)} key={`column-${i}-${reverse}-of-${order}`} >
                                    <div className="pr-4" >
                                        <label className="pflex">{c.text}</label>
                                        {c.id !== 1 &&
                                            <POWRIcon
                                                className={`far fa-${order === i && reverse ? 'arrow-up' : 'arrow-down'} ml-2`}
                                                size="12px"
                                                color={order === i ? COLOR.CISCO_BLUE : COLOR.GREY}
                                            />
                                        }
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {items && items.map((pk, i) => {
                        let packageRMA = rma_packages.filter(x => x.id === pk.idd);
                        let lines = packageRMA[0] ? packageRMA[0].lines || [] : [];
                        return (
                            <tr key={`option-${i}`} className="packagesRow">
                                <td className="text-left p-0" >
                                    <label className="strong">{pk.rma_num}</label>
                                </td>
                                <td className="text-left" >
                                    <POWRIcon
                                        className="fas fa-box mr-2"
                                        size="16px"
                                        color={COLOR.DARK_GREY_1}
                                    />
                                    <label className="strong">{pk.pack_name}</label>
                                </td>
                                {!hideTN &&
                                    <td className="text-left">
                                        <label>{pk.tn || ''}</label>
                                    </td>
                                }
                                <td className="text-left"><label>
                                    {`${parseFloat(pk.weight).toFixed(2)} ${pk.weight_uom === 1 ? 'kg' : pk.weight_uom === 2 ? 'lbs' : 'N/A'}`}
                                </label></td>
                                <td className="text-left"><label>
                                    {`${parseFloat(pk.depth).toFixed(2)} x ${parseFloat(pk.width).toFixed(2)} x ${parseFloat(pk.height).toFixed(2)}  ${pk.dim_uom === 1 ? 'm' : pk.dim_uom === 2 ? 'in' : pk.dim_uom === 3 ? 'cm' : pk.dim_uom === 4 ? 'ft' : ''}`}
                                </label></td>
                                <td className="text-center pr-5" >
                                    <label>{lines.length || 1}</label>
                                </td>
                                <td className="text-left" >
                                    <ViewPackageModal
                                        idd={pk.idd}
                                        shipId={shipId}
                                        rmas={rmas}
                                        isReleaseLinesEnabled={isReleaseLinesEnabled}
                                        dimensionUnit={pk.dim_uom === 1 ? 'm' : pk.dim_uom === 2 ? 'in' : pk.dim_uom === 3 ? 'cm' : pk.dim_uom === 4 ? 'ft' : ''}
                                        weightUnit={pk.weight_uom === 1 ? 'kg' : pk.weight_uom === 2 ? 'lbs' : 'N/A'} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="d-block d-lg-none py-16">
                {items && items.map((pk, i) => {
                    let packageRMA = rma_packages.filter(x => x.id === pk.idd);
                    let lines = packageRMA[0] ? packageRMA[0].lines || [] : [];
                    return (
                        <div className="border-bottom-gray pb-3 mb-3" key={`option-${i}`} >
                            <div className="row border-bottom-gray py-2">
                                <div className="col d-flex">
                                    <POWRIcon
                                        className="fal fa-box mr-2"
                                        size="14px"
                                        color={COLOR.CISCO_BLUE}
                                    />
                                    <label className="strong m-0">{`${translate('title.package')}: ${pk.pack_name}`}</label>
                                </div>
                                <div className="col-auto">
                                    <ViewPackageModal
                                        idd={pk.idd}
                                        shipId={shipId}
                                        rmas={rmas}
                                        isReleaseLinesEnabled={isReleaseLinesEnabled}
                                        dimensionUnit={pk.dim_uom === 1 ? 'm' : pk.dim_uom === 2 ? 'in' : pk.dim_uom === 3 ? 'cm' : pk.dim_uom === 4 ? 'ft' : ''}
                                        weightUnit={pk.weight_uom === 1 ? 'kg' : pk.weight_uom === 2 ? 'lbs' : 'N/A'} />
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-12 d-flex">
                                    <label className="mr-1">{`${translate('title.rma')}: `}</label>
                                    <label className="strong">{pk.rma_num}</label>
                                </div>
                                <div className="col-12 d-flex">
                                    <label className="mr-1">{`${translate('gridColumns.dimensions')}: `}</label>
                                    <label className="strong">
                                        {`${parseFloat(pk.depth).toFixed(2)} x ${parseFloat(pk.width).toFixed(2)} x ${parseFloat(pk.height).toFixed(2)} ${pk.dim_uom == 1 ? 'm' : pk.dim_uom == 2 ? 'in' : pk.dim_uom == 3 ? 'cm' : pk.dim_uom == 4 ? 'ft' : ''}`}
                                    </label>
                                </div>
                                <div className="col-12 d-flex">
                                    <label className="mr-1">{`${translate('title.weight')}: `}</label>
                                    <label className="strong">
                                        {`${parseFloat(pk.weight).toFixed(2)} ${pk.weight_uom === 1 ? 'kg' : pk.weight_uom === 2 ? 'lbs' : 'N/A'}`}
                                    </label>
                                </div>
                                <div className="col-12 d-flex">
                                    <label className="mr-1">{`${translate('gridColumns.qty')}: `}</label>
                                    <label className="strong">{lines.length || 1}</label>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Pagination
                itemsPerPage={PACKAGES_PAGE_ITEMS}
                items={items}
                setItems={setItems}
                pages={pages}
                page={page}
                setPage={setPage}
                allItems={allItems}
            />
        </div>
    )
}
export default ViewPackagesTable;