import React, { memo, useState } from 'react';
import { COLOR } from '../../core/constants/Colors';
import { POWRIcon } from '../styledComponents';

const DropDownMenu = memo(({ placeholder, options, value, onSelectValue }) => {
    const [showOptions, setShowOptions] = useState(false);

    return (
        <div className="dropDownMenu">
            <div className="dropDownMenuShow">
                {!value ?
                    <label>{placeholder || "Select"}</label>
                    :
                    <label style={{ color: COLOR.DARK_GREY_1 }}>{value}</label>
                }
                <div className="dropDownMenuBtn" onClick={() => setShowOptions(!showOptions)}>
                    <POWRIcon
                        className="fal fa-chevron-down"
                        color={COLOR.DARK_GREY_1}
                        size="14px" />
                </div>
            </div>
            {showOptions && <div className="dropDownMenuContent">
                {options.map((option, i) => (
                    <div
                        className="dropDownMenuOption"
                        key={`${option}-Action-${i + 1}`}
                        onClick={() => {
                            onSelectValue(option);
                            setShowOptions(false);
                        }}>
                        <label>{option}</label>
                    </div>
                ))}
            </div>}
        </div>
    )
}, (prev, next) => prev.options === next.options && prev.value === next.value);

export default DropDownMenu;