import React from 'react';
import { COLOR } from '../core/constants/Colors';
import { POWRIcon } from './styledComponents';

const PrintCard = props => {
    return (
        <div className="iconCardContainer" onClick={props.handleClick}>
            <div className="iconContainer mr-3">
                <POWRIcon className="far fa-print" color={COLOR.GREY} size="32px" />
            </div>
            <p>{props.text}</p>
        </div>
    );
}
export default PrintCard
