import * as Yup from 'yup';
import { REGEXP } from '../constants';

export const BillingAddressSchema = Yup.object().shape({
    rfc:
        Yup.string()
            .matches(REGEXP.RFC_MEXICO,'error.invalidRFC')
            .required('error.required'),
    postalCode:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    fullCompanyName:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    streetAddress:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    externalNumber:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
    internalNumber:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
    neighborhood:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
    city:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    state:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    taxRegimen:
        Yup.string()
            .required('error.required'),
    // cfdiText:
    //     Yup.string()
    //         .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
    //         .required('error.required')
});