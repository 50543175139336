import * as Yup from 'yup';

export const PackageQtySchema = Yup.object().shape({
    itemQty:
        Yup.number()
            .test('error.maxItems', 'error.maxItems', function (value) {
                return this.resolve(Yup.ref('maxItems')) >= value;
            })
            .required('error.required'),
    packageQty:
        Yup.number()
            .test('error.moreThanItems', 'error.moreThanItems', function (value) {
                return this.resolve(Yup.ref('maxItems')) >= value && this.resolve(Yup.ref('itemQty')) >= value;
            })
            .min(1, 'error.moreThanZero')
            .required('error.required'),
    maxItems:
        Yup.number()
});