import * as Yup from 'yup';

export const PackageWeightDimensionsSchema = Yup.object().shape({
    weight:
        Yup.string(),
    depth:
        Yup.string(),
    width:
        Yup.string(),
    height:
        Yup.string(),
    shouldOverride:
        Yup.boolean()
});