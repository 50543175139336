import React, { Fragment, useState } from "react";
import { Card } from "../../components";
import { useTranslate } from "../../core/contexts";
import { getWaybillShipments, gtagService } from '../../services';
import { useHistory } from 'react-router-dom';
import { SCHEDULE } from "../../core/constants/ROUTES";

const PrintedWaybill = (props) => {
    const history = useHistory();
    const { translate } = useTranslate();
    const [rma, setRMA] = useState("");
    const [error, setError] = useState("");


    const handlePrintedBill = () => {
        if (rma.length > 2) {
            let body = {
                param_ship_code: "",
                param_rma_or_tradein: rma,
                param_tn: "",
                param_date_from: "",
                param_date_to: "",
            }
            gtagService.event('DashboardWidgets', 'PreprintedWaybill');
            getWaybillShipments(body).then(response => {
                if (response.res) {
                    let id = response.res.dtos[0].ship_code;
                    history.push({
                        pathname: SCHEDULE,
                        search: `?id=${id}`,
                        state: { id }
                    });
                }
            }).catch(error => {
                setError(translate('error.invalidRMA'))
            });
            setError("");
        } else {
            setError(translate('error.pleaseMin3'))
        }
    }

    const preprintedWaybillTitle =
        <Fragment>{translate('title.preprintedWaybill')} <small>{translate('subtitle.usCanadaOnly')}</small></Fragment>;


    return (
        <Fragment>
            <Card
                icon="fal fa-file-spreadsheet"
                title={preprintedWaybillTitle}
                value={rma}
                setValue={setRMA}
                onClick={handlePrintedBill}
                button={translate("button.schedule")}
                validateRMA={true}
                error={error}
            >
                {translate("subtitle.preprintedInfo")}
            </Card>
        </Fragment>
    );
};

export default PrintedWaybill;