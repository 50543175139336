import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { TextInput, Select } from '../controls';
import { PickupLocationWaybillSchema } from '../../core/schemas';
import { useTranslate } from '../../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountries, selectWayBillInfo } from '../../core/redux/selectors';
import { loadCountries, setWaybill } from '../../core/redux/actions';
import { POWRIcon } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import  * as allCountryStates from '../../core/constants/CountriesStates';

export const PreprintedPickupLocationForm = ({ data, formRef }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const waybillInfo = useSelector(selectWayBillInfo);
    const [initialValues, setInitialValues] = useState({
        company: '',
        addr1: '',
        addr2: '',
        addr3: '',
        addr4: '',
        country: '',
        province: '',
        city: '',
        postal: '',
        name: '',
        zipRequired: true,
        phone: '',
        isStateListEnable: false
    });


    const countries = useSelector(selectCountries);
    const [addCount, setAddCount] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showAdd2, setShowAdd2] = useState(false);
    const [showAdd3, setShowAdd3] = useState(false);
    const [showAdd4, setShowAdd4] = useState(false);
    const [stateListEnable,setStateListEnable] = useState(false);
    const [countryStateLst,setCountryStateLst] = useState([]);


    useEffect(() => {
        setIsSubmitted(!!waybillInfo.confirmation_number);

    }, [waybillInfo])

    useEffect(() => {
        if(initialValues.country){
        getCountryStateLst(initialValues.country)
        const ctr = countries.find(x => x.code === initialValues.country);
        if (countries.length && ctr) {
            setStateListEnable(ctr.states_list_enable);
        }
    }
    }, [initialValues, countries])

    const getCountryStateLst = (countryCode) => {
        setCountryStateLst([]);
        if(allCountryStates[countryCode]!==undefined){
            setCountryStateLst(allCountryStates[countryCode])}       
    };

    //This useEffect is used to setup the states (showAdd2, showAdd3, showAdd4) related to the addresses inputs that we are using to display secondary addresses,
    //we also use a state (addCount) that is monitoring the current address available
    useEffect(() => {
        if (addCount === 0) setAddCount(1);
        else if (addCount === 5) setAddCount(4);
        else {
            switch (addCount) {
                case 1: {
                    setShowAdd2(false);
                    setShowAdd3(false);
                    setShowAdd4(false);
                    break;
                }
                case 2: {
                    setShowAdd2(true);
                    setShowAdd3(false);
                    setShowAdd4(false);
                    break;
                }
                case 3: {
                    setShowAdd2(true);
                    setShowAdd3(true);
                    setShowAdd4(false);
                    break;
                }
                default: {
                    setShowAdd2(true);
                    setShowAdd3(true);
                    setShowAdd4(true);
                    break;
                }
            }

        }
    }, [addCount])

    //We use this method to update the states related to display addresses inputs
    const updateShowAddress = (add, values) => {
        setAddCount(add ? addCount + 1 : addCount - 1);
        if (!add) {
            let nextValues = {
                ...values,
            };
            nextValues[`addr${addCount}`] = "";
            dispatch(setWaybill({ ...nextValues, confirmation_number: waybillInfo.confirmation_number || '' }))
        }
    }

    useEffect(() => {
        if (countries.length === 0) dispatch(loadCountries());
    }, [countries, dispatch]);



    useEffect(() => {
        if (countries && data) {
            const ctr = countries.find(x => x.code === data.country);
            //console.log(ctr)
            var zipFormat = ctr?.post_code_fmt_required ? ctr?.post_code_fmt_regexp : '';
            let newValues = {
                ...initialValues,
                ...data,
                province: data.state,
                postal: data.postal_code,
                phone: data.telephone,
                zipFormat: zipFormat
            };


            setInitialValues(newValues);
            setAddressConfig(data);
        }

    }, [data, countries]);

    //this method setup the configutation when the initial values has more than one address
    const setAddressConfig = (info) => {
        setShowAdd2(info.addr2.length > 0);
        setShowAdd3(info.addr3.length > 0);
        setShowAdd4(info.addr4.length > 0);
        if (info.addr2.length > 0) setAddCount(2);
        if (info.addr3.length > 0) setAddCount(3);
        if (info.addr4.length > 0) setAddCount(4);
    }


    const save = (values, props) => {
        let nextValues = {
            ...values,
            state: values.province,
            postal_code: values.postal,
            telephone: values.phone,
            addr2: showAdd2 ? values.addr2 : "",
            addr3: showAdd3 ? values.addr3 : "",
            addr4: showAdd4 ? values.addr4 : ""
        };

        dispatch(setWaybill({ ...nextValues, confirmation_number: waybillInfo.confirmation_number || '', props }))
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={PickupLocationWaybillSchema}
            onSubmit={save}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {
                ({ handleSubmit, values, setFieldValue, getFieldMeta, isSubmitting }) => (
                    <Form onSubmit={handleSubmit} onChange={handleSubmit} onBlur={handleSubmit} onBlurCapture={handleSubmit} className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" isRequired name={'company'} label={'formLabel.company'} maxLength="255" />
                                </div>
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" isRequired name={'name'} label={'formLabel.contactName'} maxLength="255" />
                                </div>
                                <div className="col-2" />
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-10 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" isRequired name={'addr1'} label={'formLabel.address1WayBill'} maxLength="255" />
                                </div>

                                {showAdd2 &&
                                    <div className="col-10 mb-0 form-group">
                                        <TextInput disabled={isSubmitted} type="text" name={'addr2'} label={'formLabel.address2WayBill'} maxLength="255" />
                                    </div>}
                                {showAdd3 && <div className="col-10  mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" name={'addr3'} label={'formLabel.address3WayBill'} maxLength="255" />
                                </div>}
                                {showAdd4 && <div className="col-10 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" name={'addr4'} label={'formLabel.address4WayBill'} maxLength="255" />
                                </div>}
                                <div className="col-2 mb-0 form-group d-flex align-items-end align-self-center justify-content-center">
                                    <div className="row">
                                        <div className="col-auto">
                                            {showAdd2 &&
                                                <div onClick={() => updateShowAddress(false, values)}>
                                                    <POWRIcon className="far fa-trash-alt" size="16px" color={COLOR.CISCO_BLUE} />
                                                </div>
                                            }
                                        </div>
                                        <div className="col d-none d-lg-flex align-items-center">
                                            {!showAdd4 &&
                                                <label className="clear-btn" onClick={() => updateShowAddress(true)}>
                                                    {translate('button.+addAddress')}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pb-2 d-flex d-lg-none">
                                    {!showAdd4 &&
                                        <label className="clear-btn" onClick={() => updateShowAddress(true)}>
                                            {translate('button.+addAddress')}
                                        </label>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    <Select
                                        value={values.country}
                                        text={(values.country && countries.length) ? countries.find(x => x.code === values.country).text : ''}
                                        onChange={(val) => {
                                            getCountryStateLst(val)
                                            const ctr = countries.find(x => x.code === val);
                                            if (countries.length && ctr) {
                                                setFieldValue('zipRequired', ctr.post_code_required, true);

                                                setFieldValue('zipFormat', ctr.post_code_fmt_required ? ctr.post_code_fmt_regexp : '', true);
                                                setFieldValue('isStateListEnable', ctr.states_list_enable, true); 
                                                setStateListEnable(ctr.states_list_enable); 
                                            }
                                            setFieldValue('country', val, false);
                                            setFieldValue('province', '', false);
                                        }}
                                        options={countries}
                                        className="inputSpace mb-0 mt-4"
                                        singleSelection mandatory
                                        error={getFieldMeta('country').touched && getFieldMeta('country').error}
                                        label={translate('formLabel.country')}
                                        disabled />
                                </div>
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    {(stateListEnable || values.isStateListEnable) && countryStateLst.length !== 0 ?
                                        <Select
                                            options={countryStateLst.map((obj) => {
                                                return {
                                                    code: obj.code,
                                                    text: obj.name + ' (' + obj.code + ')'
                                                }
                                            })}
                                            value={values.province}
                                            className="inputSpace"
                                            onChange={(val) => { setFieldValue('province', val, true); }}
                                            singleSelection 
                                            mandatory
                                            error={getFieldMeta('province').touched && getFieldMeta('province').error}
                                            label={translate('formLabel.state')}
                                            isRequired={true} 
                                            disabled={isSubmitted}/>
                                        :
                                        <TextInput type="text" name={'province'} label={'formLabel.state'} maxLength="255" />
                                    }

                                    {/* <TextInput disabled={isSubmitted} type="text" isRequired name={'province'} label={'formLabel.state'} maxLength="255" /> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" isRequired name={'city'} label={'formLabel.city'} maxLength="255" />
                                </div>
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" isRequired name={'postal'} label={'formLabel.zip'} maxLength="25" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-5 mb-0 form-group">
                                    <TextInput disabled={isSubmitted} type="text" isRequired name={'phone'} numericKeys
                                        label={'formLabel.telephone'} maxLength="255" />
                                </div>
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
};