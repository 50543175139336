import React, { useState } from 'react';
import { COLOR } from '../../core/constants/Colors';
import { useTranslate } from '../../core/contexts';
import { POWRButton, POWRModal, POWRIcon } from "../styledComponents";

export const LabelOnlyModal = props => {
    const { onContinue, show, onClose } = props;
    const { translate } = useTranslate();
    const [label, setLabel] = useState(false);

    return (
        <POWRModal
            show={show}
            title={translate('title.reminder')}
            size="small"
            onClose={onClose.bind(this, false)}
            alignActions="center"
        >
            <div className="content">
                <div className="label-only">
                    <p className="text-center mb-4">{translate('message.RMAisLabelOnly')}</p>
                    <div className="options-container">
                        <div className="modal-head">
                            <h3>{translate('subtitle.selectTypeRequest')}</h3>
                        </div>
                        <div className="select-container mt-4 mb-2">
                            <div
                                key={`requestType-0-${label}`}
                                onClick={setLabel.bind(this, false)}
                                className={`${!label ? 'request-selected' : ''} request-option`}
                            >
                                <div className="iconSelected">
                                    <POWRIcon
                                        size="14px"
                                        className={!label ? "fas fa-check-circle" : "fas fa-circle"}
                                        color={!label ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                </div>
                                <POWRIcon
                                    size="50px"
                                    className="far fa-shipping-timed"
                                    color={!label ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                <p>{translate('aux.requirePickup')}</p>
                            </div>
                            <div
                                key={`requestType-1-${label}`}
                                onClick={setLabel.bind(this, true)}
                                className={`${label ? 'request-selected' : ''} request-option`}
                            >
                                <div className="iconSelected">
                                    <POWRIcon
                                        size="14px"
                                        className={label ? "fas fa-check-circle" : "fas fa-circle"}
                                        color={label ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                </div>
                                <POWRIcon
                                    size="50px"
                                    className="fas fa-tags"
                                    color={label ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                <p>{translate('aux.labelOnly')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="toolbar">
                <POWRButton color={'secondary'} onClick={onClose.bind(this, false)}>{translate('button.cancel')}</POWRButton>
                <POWRButton onClick={onContinue.bind(this, label)}>{translate('button.continue')}</POWRButton>
            </div>
        </POWRModal>
    )
}
