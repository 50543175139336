import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../core/contexts';
import { useAlert } from '../../core/hooks';
import { POWRModal } from "../styledComponents";
import { Button } from "../controls";
import { gtagService, reenableDeniedPickup } from '../../services';
import { SHIPMENT_DETAIL } from '../../core/constants/ROUTES';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../core/redux/actions';

export default props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showAlert, removeAlert } = useAlert();
    const { show, onClose } = props;

    const handleGo = () => {
        history.push({
            pathname: SHIPMENT_DETAIL,
            search: `?id=${props.ship_code}`,
            state: { id: props.ship_code }
        })
    }

    const reenableShipment = async () => {
        removeAlert();
        onClose();
        dispatch(setLoader(true));
        await reenableDeniedPickup({ ship_id: props.idd }).then(response=> {
            if (response.status === 200) {
                if (!props.noRedirect) handleGo();
                else if (props.onReload) props.onReload();
                showAlert({
                    id:"successReenable",
                    message:translate('message.successReenable'),
                    page:"*",
                    color:"green"
                });
                gtagService.event('Shipments', 'Re-enableShipment');
            }
        }).catch(error=> {
            showAlert({
                id:"errorReenable",
                message: translate('message.unexpectedSituation'),
                page:"*",
                color:"red"
            });
            console.log('Error', error);
        });
        dispatch(setLoader(false));
    }
    
    return (
        <POWRModal
            show={show}
            size="small"
            title={translate('title.reenableShipment')}
            onClose={() => onClose()}
            alignActions="center"
        >
            <div className="content">
                {translate('message.renableShipment')}
            </div>
            <div className="toolbar" >
                <Button onClick={() => onClose()} type="2" >
                    {translate('button.no')}
                </Button>
                <Button
                    onClick={() => reenableShipment()}
                >
                    {translate('button.yes')}
                </Button>          
            </div>
        </POWRModal>
    );
}