import React from 'react'

export default ({ id, text, error, className, ...props }) => (
    <p className={`checkRadioContainer${className ? ' ' + className : ''}`}>
        <input
            className='inputCheckRadio'
            type="radio"
            id={id}
            {...props} />
        <label
            className={`${error ? 'checkRadioLabelError checkRadioLabel' : 'checkRadioLabel'}`}

            htmlFor={id}>
            {text}
        </label>
    </p>
)