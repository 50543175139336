import {
    SET_DOCUMENTATION_INPUT_RMA,
    SET_DOCUMENTATION_ERROR_MSG,
    SET_DOCUMENTATION_ERROR,
    SET_DOCUMENTATION_RESULTS
} from '../actionTypes';

const initialState = {
    documentationInputRMA: "",
    documentationErrorMsg: "",
    documentationError: false,
    documentationResults: false
};

export const documentation = (state = initialState, action) => {
    switch (action.type) {
        case SET_DOCUMENTATION_INPUT_RMA: return {
            ...state,
            documentationInputRMA: action.payload
        };
        case SET_DOCUMENTATION_ERROR_MSG: return {
            ...state,
            documentationErrorMsg: action.payload
        };
        case SET_DOCUMENTATION_ERROR: return {
            ...state,
            documentationError: action.payload
        };
        case SET_DOCUMENTATION_RESULTS: return {
            ...state,
            documentationResults: action.payload
        };
        default: return state;
    }
};
