import React, { Fragment, memo, useCallback, useEffect, useState, useMemo } from 'react';
import { DropDown, Pagination, Icon, Input, InfoTooltip, Status } from './index';
import { useTranslate } from '../../core/contexts';
import { useDispatch } from 'react-redux';
import { POWRIcon, POWRButton, POWRModal } from '../styledComponents';
import '../../styles/components/controls/_table.scss';
import { COLOR } from '../../core/constants/Colors';
import Select from './select';
import Info from './info';
import { useAlert } from '../../core/hooks';
import { REGEXP } from '../../core/constants';
import { setRequestReturnFlowProp } from '../../core/redux/actions';

export const TableRTF = memo(({ columns, dataSource, itemsPerPage, actions, noPaging, type, confirmationModal, currentStep }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [items, setItems] = useState([]);
    const [showLines, setShowLines] = useState(false);
    const [showUnits, setShowUnits] = useState([]);
    const [sortProp, setSortProp] = useState({ name: columns[0].key, dir: false });
    const [sortedData, setSortedData] = useState([]);
    const [showDelete, setShowDelete] = useState({ show: false, item: null, line: null });
    const [showDeleteItem, setShowDeleteItem] = useState({ show: false, item: null });
    const { showAlert } = useAlert();

    useEffect(() => {
        if (noPaging) {
            setItems(dataSource);
        } else {
            let newItems = page === 1 ?
            sortedData.slice(0, itemsPerPage) :
            sortedData.slice((page * itemsPerPage) - itemsPerPage, page * itemsPerPage);
            setItems(newItems);
            let nPages = Math.ceil(sortedData.length / itemsPerPage);
            let ps = [];
            for (let i = 1; i <= nPages; i++) {
                ps.push(i);
            }
            setPages(ps);
            setTimeout(() => {
                dispatch(setRequestReturnFlowProp('savingInfo', false))
            }, 1000);

        }
    }, [sortedData, page, itemsPerPage]);

    useEffect(() => {
        let dtos = dataSource ? noPaging ? [dataSource[0], dataSource[1]] : dataSource : [];
        if (!noPaging && dtos) {
            let temp = [...dataSource];
            setSortedData(temp.sort((a, b) => {
                let temp1 = a[sortProp.name], temp2 = b[sortProp.name];
                if (typeof (a[sortProp.name]) === 'string') {
                    temp1 = temp1?.toLowerCase();
                    temp2 = temp2?.toLowerCase();
                }
                if (sortProp.dir ? temp2 > temp1 : temp1 > temp2) return 1;
                else return -1;
            }));
        } else {
            setSortedData(dtos);
        }
    }, [dataSource, sortProp]);


    const handleBlurInput = () => {
        if (confirmationModal) {
            confirmationModal();
        }
    }



    function close() {
        setShowDelete({ show: false, item: null, line: null })
        setShowDeleteItem({ show: false, item: null })
    }

    const handleDelete = (item, line) => {
        setShowDelete({ show: true, item, line })
    }

    const handleDeleteItem = (item) => {
        setShowDeleteItem({ show: true, item })
    }

    const handleChangeUnits = (newUnits, productID, data = '', units) => {
        actions.updateItem(productID, newUnits, data, units);
    }

    const handleChangeSN = (id, value, idUnit) => {
        actions.onChangeSN(id, value, idUnit);
    }

    const sum = useMemo(() => dataSource?.reduce((prod, object) => {
        return prod + Number(object.qty);
    }, 0), [dataSource]);


    const getTemplate = useCallback((data, column, children = false) => {
        return !column.template ?
            <div className="td generic product">{data[column.key]}</div>
            :
            (<Fragment>
                {(column.template === 'bold' && !children) &&
                    <div className="td boldType text-left product qty" contentEditable={true} suppressContentEditableWarning={true}
                        onBlur={e => {
                            if (e.currentTarget.textContent >= 1 && e.currentTarget.textContent <= data.qty && REGEXP.CI_NUMERIC.test(e.currentTarget.textContent)) {
                                handleChangeUnits(e.currentTarget.textContent, data.id, '', data.units)
                                data.qty = e.currentTarget.textContent
                            } else if (e.currentTarget.textContent <= 0 || !REGEXP.CI_NUMERIC.test(e.currentTarget.textContent)) {
                                showAlert({
                                    id: "invalidQtyProd",
                                    message: translate('error.invalidQty'),
                                    color: "yellow",
                                    page: '*'
                                })
                                e.currentTarget.textContent = data.qty
                            } else if (e.currentTarget.textContent > data.qty) { 
                                showAlert({
                                    id: "invalidQtyProd",
                                    message: translate('error.exceedQty'),
                                    color: "yellow",
                                    page: '*'
                                })
                                e.currentTarget.textContent = data.qty
                            }
                            else {
                                e.currentTarget.textContent = data.qty
                            }
                        }
                        }>
                        {data[column.key]}
                    </div>
                }
                {(column.template === 'bold' && children) && <div className="td boldType text-left " />}
                {column.template === 'boldImage' &&
                    <div className="td boldType text-left product">
                        <img
                            className={'imageTable'}
                            src={data.image}
                        />
                        {column.key2 && data[column.key2] ? `${data[column.key2]} - ${data[column.key]}` : data[column.key]}</div>}
                {column.template === 'withTranslation' &&
                    <div className="td generic">{translate(data[column.key])}</div>}
                {column.template === 'withAll' &&
                    <div className="td generic">
                        {data[column.key] === 'ALL' ? translate('aux.all') : data[column.key]}
                    </div>}
                {column.template === 'actions' &&
                    <div className="td actionsDropDown">
                        <DropDown options={actions.map((act) => ({
                            ...act,
                            handler: () => act.handler(data)
                        }))} />
                    </div>}
                {column.template === 'icon' &&
                    <div className="td text-left">
                        {data[column.key] &&
                            <Fragment>
                                <Icon content={column.icon.name} width="14px" height="14px" color="#00A0DF" className="mr-3" />
                                {`${column.icon.tag ? (translate(column.icon.tag) + ':') : ''} ${data[column.key] || ''}`}
                            </Fragment>
                        }
                    </div>}
                {column.template === 'input' &&
                    <div className="td text-center inputContainer">
                        <Input

                            className="inputTable"
                            onBlur={() => handleBlurInput()}
                            onEnter={() => handleBlurInput()}
                            placeholder={translate(column.placeholder)}
                        />
                    </div>}
                {column.template === 'select' &&
                    <div className="td text-center inputContainer">
                        <Select
                            value={data[column.key] || ""}
                            text={data[column.key] || ""}
                            onChange={(val) => (data.type && data.type === 'unit') ? column.onChange(data.id, val, data.idProd) : column.onChange(data.id, val)}
                            options={column.options || []}
                            className={"m-0"}
                            label={''}
                            disabled={column.disabled}
                            placeholder={column.placeholder}
                            singleSelection
                            hideClear

                        />
                    </div>}
                {column.template === 'selectSN' && 
                    <div className="td text-center inputContainer product" onClick={(e) => {
                        if (!!(data.units && data.units.length > 1)) {
                            e.preventDefault()
                            setShowUnits(su => [...su.filter(x => x.id !== data.id), { show: true, id: data.id }])
                            const timer = 100
                            setTimeout(() => {
                                if (document && document.activeElement && document.activeElement.blur)
                                    document.activeElement.blur();
                            }, timer)
                        }
                    }}>
                        <Select
                            value={!!(data.units && data.units.length > 1) ? "" : (data[column.key] || "")}
                            text={!!(data.units && data.units.length > 1) ? "" : (data[column.key] || "")}
                            isSN={true}
                            onChange={(val) => {
                                !!(data.units && data.units.length > 1) ? column.onChange(data.id, '', data.idProd) :
                                    (data.type && data.type === 'unit') ? column.onChange(data.id, val, data.idProd) :
                                        column.onChange(data.id, val)
                            }}
                            options={data.serialNum?.split(",").map(x => ({ code: x, id: x, text: x })) || []}
                            className={"m-0"}
                            label={''}
                            disabled={(column.disabled || !children)}
                            placeholder={column.placeholder}
                            singleSelection
                            hideClear

                        />
                    </div>}

                {column.template === 'info' &&
                    <div className="td infoContainer">
                        {data[column.key]}
                        <div className="infoCircle">
                            <InfoTooltip>{data[column.info]}</InfoTooltip>
                        </div>
                    </div>}
                {column.template === 'unit' &&
                    <div className="td">{data[column.key] || '1'}</div>}
                {column.template === 'status' &&
                    <Status className="td" status={data[column.key]} />
                }
                {column.template === 'commaArray' &&
                    <div className="td generic py-1">
                        {
                            data[column.key] ?
                                data[column.key].split(',').map((x, i) => <Fragment key={`pkserial-${i}`}>{`- ${x}`} <br /></Fragment>)
                                : ''
                        }
                    </div>
                }
            </Fragment>)
    }, [dataSource, currentStep]);

    const changeSort = (name) => setSortProp({ name, dir: sortProp.name === name ? !sortProp.dir : false });

    return (
        <Fragment>
            <table>
                <thead>
                    <tr className={type !== "list" ? "table-headers" : "table-headers noBorders"} >
                        {(type === 'collapse' || type === 'collapseCRP') && <th></th>}
                        {columns.map((column, i) => (
                            <th className={`table-header tableHeaders`}
                                key={`column-header-${column.key}-${i}`}>
                                {
                                    column.tag ?
                                        <div className={`title ${column.headerClasses || 'text-left'}`}
                                            onClick={() => !column.hidden && changeSort(column.key)}>
                                            {translate(`gridColumns.${column.tag}`)}
                                            {type !== "list" && !column.hidden && (sortProp.name === column.key && sortProp.dir) &&
                                                <span><POWRIcon className={`far fa-arrow-up ml-2`} size=".7rem" color={'#099AD6'} /></span>}
                                            {type !== "list" && !column.hidden && (sortProp.name === column.key && !sortProp.dir) &&
                                                <span><POWRIcon className={`far fa-arrow-down ml-2`} size=".7rem" color={'#099AD6'} /></span>}
                                            {type !== "list" && !column.hidden && sortProp.name !== column.key &&
                                                <span><POWRIcon className={`fal fa-arrow-up ml-2`} size=".7rem" color={'#a6a5a5'} /></span>}
                                            {
                                                column.tooltip && <span className='ml-2'>
                                                    <Info left>{column.tooltip}</Info>
                                                </span>
                                            }
                                        </div>
                                        :
                                        <div></div>
                                }

                            </th>
                        ))}
                        {(type === 'collapseCRP') && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {type === "collapse" ?
                        items.map((item, i) => {
                            let itemHead = item.head;
                            return (
                                <Fragment key={`headeRow-${i}`}>
                                    <tr
                                        onClick={setShowLines.bind(this, !showLines)}
                                        key={`row-content-head-${item.id}-${i}`}
                                        className="table-row" >
                                        <td key={`showLines-${showLines}`} >
                                            <POWRIcon
                                                className={`fas fa-${showLines ? "caret-down" : "caret-right"} ml-1 showLinesIcon`}
                                                color={COLOR.CISCO_BLUE}
                                                size="0.6rem" />
                                        </td>
                                        {
                                            columns.map((column, j) => (
                                                <td key={`cell-${column.key || 'actions'}-content-${item.id}-${j}`}>
                                                    {getTemplate(itemHead, column)}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    {showLines && item && item.lines && item.lines.length ? item.lines.map((line, a) => (
                                        <tr key={`row-content-line-${item.id}-${a}`} className="table-row">
                                            <td></td>
                                            {
                                                columns.map((column, b) => (
                                                    <td key={`cell-${column.key || 'actions'}-content-line-${item.id}-${b}`}>
                                                        {getTemplate(line, column)}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    )) : <tr />}
                                </Fragment>
                            );
                        })
                        : type === 'collapseCRP' ?
                            items.map((item, i) => {
                                let itemHead = item;
                                let currentItemSelect = showUnits.find(x => x.id === item.id)
                                return (
                                    <Fragment key={`headeRow-${i}`} >
                                        <tr
                                            key={`row-content-head-${item.id}-${i}`}
                                            className="table-row productRow mt-1" >
                                            <td key={`showLines-${currentItemSelect?.show && currentItemSelect?.id === item.id}`} >
                                                <div
                                                    onClick={() => {
                                                        if (showUnits.find(x => x.id === item.id && x.show)) {
                                                            setShowUnits(su => [...su.filter(x => x.id !== item.id), { show: false, id: item.id }])
                                                        }
                                                        else if (showUnits.find(x => x.id === item.id && !x.show)) {
                                                            setShowUnits(su => [...su.filter(x => x.id !== item.id), { show: true, id: item.id }])
                                                        }
                                                        else {
                                                            setShowUnits(su => [...su, { show: true, id: item.id }])
                                                        }
                                                    }}
                                                    key={`icon-content-head-${item.id}-${i}`}
                                                    className='d-flex align-items-center justify-content-center p-1 rounded'
                                                    style={{ backgroundColor: COLOR.CISCO_BLUE, maxWidth: "1.2rem" }}>
                                                    <POWRIcon
                                                        className={`fas fa-${(currentItemSelect?.show && currentItemSelect?.id === item.id) ? "minus" : "plus"} showLinesIcon`}
                                                        color={COLOR.WHITE}
                                                        size="0.7rem" />
                                                </div>
                                            </td>
                                            {
                                                columns.map((column, j) => (
                                                    <td key={`cell-${column.key || 'actions'}-content-${item.id}-${j}`}>
                                                        {getTemplate(itemHead, column)}
                                                    </td>

                                                ))
                                            }
                                            <div className='mobileProduct td boldType text-left mt-3'>
                                                <div>
                                                    <p className='m-0' style={{ fontWeight: "bold" }}>
                                                        {item.pid}
                                                    </p>
                                                    <p className='m-0'>
                                                        {item.name}
                                                    </p>
                                                </div>
                                            </div>
                                            <td>
                                                <div className="td boldType text-left mobileProduct qty" contentEditable={true} suppressContentEditableWarning={true}
                                                    onBlur={e => {
                                                        if (e.currentTarget.textContent >= 1 && e.currentTarget.textContent <= item.qty && REGEXP.CI_NUMERIC.test(e.currentTarget.textContent)) {
                                                            handleChangeUnits(e.currentTarget.textContent, item.id, '', item.units)
                                                            item.qty = e.currentTarget.textContent
                                                        } else if (e.currentTarget.textContent <= 0 || !REGEXP.CI_NUMERIC.test(e.currentTarget.textContent)) {
                                                            showAlert({
                                                                id: "invalidQtyProd",
                                                                message: translate('error.invalidQtyNegative'),
                                                                color: "yellow",
                                                                page: '*'
                                                            })
                                                            e.currentTarget.textContent = item.qty
                                                        } else if (e.currentTarget.textContent > item.qty) {
                                                            showAlert({
                                                                id: "invalidQtyProd",
                                                                message: translate('error.mayor que la cantidad'),
                                                                color: "yellow",
                                                                page: '*'
                                                            })
                                                            e.currentTarget.textContent = item.qty
                                                        }
                                                        else {
                                                            e.currentTarget.textContent = item.qty
                                                        }
                                                    }
                                                    }>
                                                    {item.qty}
                                                </div>
                                            </td>
                                            <td onClick={() => handleDeleteItem(item.id)} className={currentStep == 3 ? "d-none" : ""}>
                                                <POWRIcon
                                                    className="fas fa-trash-alt" size=".8rem" color={COLOR.RED} />
                                            </td>

                                        </tr>
                                        {(currentItemSelect?.show && currentItemSelect?.id === item.id) && item && item.units ? item.units.map((line, a) => {
                                            return <tr key={`row-content-line-${item.id}-${a}`} className="table-row productRow mb-2">
                                                <td></td>
                                                {
                                                    columns.map((column, b) => (
                                                        <td key={`cell-${column.key || 'actions'}-content-line-${item.id}-${b}`}>
                                                            {
                                                                getTemplate(line, column, true)}
                                                        </td>
                                                    ))
                                                }
                                                <td className={currentStep === 3 ? "d-none" : "product"}
                                                    onClick={() => handleDelete(item, line)}
                                                ><POWRIcon
                                                        className="fas fa-trash-alt" size=".7rem" color={COLOR.RED} />
                                                </td>
                                                <tr className='mobileProduct'>
                                                    <td className='units mt-1'>
                                                        <label>{`${translate('aux.unit')} 
                                                        ${item.units[a].partNum}`}</label>
                                                    </td>
                                                    <td style={{ paddingLeft: "4.6rem" }} className={currentStep === 3 ? "d-none" : ""}
                                                        onClick={() => handleDelete(item, line)}
                                                    ><POWRIcon
                                                            className="fas fa-trash-alt" size=".7rem" color={COLOR.RED} />
                                                    </td>
                                                </tr>
                                                <tr className='mobileProduct'>
                                                    <td>
                                                        <label style={{ width: "max-content" }}>{translate('gridColumns.serialNumber')}</label>
                                                    </td>
                                                    <td>
                                                        <div className="td text-center inputContainer">
                                                            <Select
                                                                value={item.units[a].sn || ""}
                                                                text={item.units[a].sn || ""}
                                                                isSN={currentStep === 2 ? true : false}
                                                                onChange={(val) => (handleChangeSN(item.units[a].id, val, item.id))}
                                                                options={item.serialNum?.split(",").map(x => ({ code: x, id: x, text: x })) || []}
                                                                className={"m-0"}
                                                                label={''}
                                                                placeholder={translate('gridColumns.serialNumber')}
                                                                singleSelection
                                                                hideClear

                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <td className={currentStep === 3 ? "d-none" : ""}></td>
                                                <td></td>

                                            </tr>
                                        }) : <tr />}
                                        <tr className='spacer'></tr>
                                    </Fragment>
                                );
                            })
                            :
                            items.map((item, i) => (
                                <tr key={`row-content-${i}`} className={type !== "list" ? "table-row" : "table-row noBorders"}>
                                    {
                                        columns.map((column, j) => (
                                            <td key={`cell-${column.key || 'actions'}-content-${j}`}>
                                                {getTemplate(item, column)}
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                    }
                </tbody>
            </table>
            <POWRModal
                show={showDelete?.show}
                title={translate('button.deleteUnit')}
                size={'small'}
                onClose={close}
            >
                <div className="">
                    <div className="text-center ">
                        <p className="m-0 p-0">{translate('formLabel.deleteUnit')}</p>
                    </div>
                </div>
                <div className="toolbar">
                    <POWRButton color={'secondary'} onClick={close}>{translate('button.cancel')}</POWRButton>
                    <POWRButton color={'primary'} onClick={() => actions?.removeUnit(showDelete.item?.id, showDelete.line?.id) + close()}>{translate('button.remove')}</POWRButton>
                </div>
            </POWRModal>
            <POWRModal
                show={showDeleteItem?.show}
                title={translate('title.deleteItem')}
                size="small"
                onClose={close}
            >
                <div className="">
                    <div className="text-center ">
                        <p className="m-0 p-0">{translate('formLabel.deleteItem')}</p>
                    </div>
                </div>
                <div className="toolbar">
                    <POWRButton color={'secondary'} onClick={close}>{translate('button.cancel')}</POWRButton>
                    <POWRButton color={'primary'} onClick={() => actions?.removeItem(showDeleteItem.item) + close()}>{translate('button.remove')}</POWRButton>
                </div>
            </POWRModal>
            {!noPaging && <Pagination
                itemsPerPage={itemsPerPage}
                items={items}
                setItems={setItems}
                pages={pages}
                page={page}
                setPage={setPage}
                allItems={dataSource}
            />}
        </Fragment>

    );
}, (prev, next) => prev.columns === next.columns
    && prev.dataSource === next.dataSource
    && prev.itemsPerPage === next.itemsPerPage)
