import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveShipmentData, setWaybill } from '../core/redux/actions';
import { selectWayBillInfo } from '../core/redux/selectors';
import { getScheduleSWB } from '../services';
import { useTranslate } from '../core/contexts';
import { InfoSection } from '../components/styledComponents';
import { PreprintedPickupLocationForm } from '../components/forms/preprintedPickupLocationForm';
import { ScheduleWaybill } from '../components';
import { useLocation } from 'react-router-dom';
import { STORAGE } from '../core/constants';
import { FullPage } from '../components/AppShell/partials/FullPage.react';

export default props => {
    const dispatch = useDispatch();

    const { translate } = useTranslate();
    const [searching, setSearching] = useState(true);
    const [data, setData] = useState(null);
    const [confirmationNumber, setConfirmationNumber] = useState();
    const shipId = new URLSearchParams(useLocation().search).get("id");
    const modalTemplate = { action: 'add', type: 'PickupLocation' };
    const waybillInfo = useSelector(selectWayBillInfo);
    const sessionStorageSearchName = 'rmsSingleSearch';
    const sessionStorageSaveName = 'rmsSingleSearchSave';
    const sessionStorageContactInfo = 'rmsSingleSearchContactInfo';
    const formRef = useRef();


    const clearSessionStorage = () => {
        sessionStorage.removeItem(sessionStorageSearchName)
        sessionStorage.removeItem(sessionStorageSaveName)
        sessionStorage.removeItem(sessionStorageContactInfo)
        sessionStorage.removeItem(STORAGE.RESUME_PICKUP)
        sessionStorage.removeItem(STORAGE.RESUME_SHIPMENT)
    }

    useEffect(() => {

    }, [props.location]);

    useEffect(() => {
        setConfirmationNumber(waybillInfo.confirmation_number)
    }, [waybillInfo])

    useEffect(() => {

        if (shipId) {
            dispatch(saveShipmentData(parseInt(shipId.replace(/\D/g, '')), "", 2, 2));

            if (waybillInfo && waybillInfo.idd) {
                setData(waybillInfo);
                setSearching(false);
            }
            else
                getScheduleInfo(parseInt(shipId.replace(/\D/g, '')));
        }
    }, []);

    const getScheduleInfo = (id) => {
        getScheduleSWB(id).then(response => {
            if (response.res) {
                let dtos = response.res.dtos;
                setData(dtos[0]);
                // searchRMA(dtos.rmas[0]);
                dispatch(setWaybill(dtos[0]))
                setSearching(false);
            }
        }).catch(error => {
            clearSessionStorage();
        });

    }


    return !searching && data && (
        <FullPage>
            <div className="col-12 col-xl-9 p-xl-0">
                <div className="schedule-container">
                    <div>
                        <h1>{`${translate('title.schedulePickup')} - ${data.ship_code}`}</h1>
                        <h3>{`${translate('gridColumns.rmas')}: ${data ? data.rma_num.join(', ') : '—'}`}</h3>
                        <p>{`${translate('formLabel.tracking')}: ${data ? data.tracking_number.join(', ') : '—'}`}</p>
                        {confirmationNumber &&
                            <Fragment>
                                <h2 className="colorSuccess" >{`${translate('title.success')}!`}</h2>
                                <h3>{`${translate('subtitle.confirmationNumber')}: ${confirmationNumber || ""}`}</h3>
                            </Fragment>}

                    </div>
                    <div className="row">
                        <InfoSection
                            title={translate('subtitle.pickupLocation')}
                            type={'cards'}
                            isPickUpCard={true}
                        >
                            <PreprintedPickupLocationForm formRef={formRef}
                                isAdd={modalTemplate.action === 'add'}
                                data={data}
                            />
                        </InfoSection>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-3 p-xl-0 py-2 py-xl-0">
                <div className="returnTaskBarContainer toolsBarContainer schedule-taskbar">
                    <ScheduleWaybill formRef={formRef} data={data} />
                </div>
            </div>
        </FullPage>
    )
}