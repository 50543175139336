import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectShipmentDetail, selectUserInfo } from '../core/redux/selectors';
import { SHIPMENT_DETAIL } from '../core/constants/ROUTES';
import { ROLES } from '../core/constants/ROLES';
import { PickupOptionsButtons, DownloadCenterBtns } from './';

export default () => {
    const history = useHistory();
    const shipment = useSelector(selectShipmentDetail);
    const userInfo = useSelector(selectUserInfo);
    const handleGo = (id) => {
        history.push({
            pathname: SHIPMENT_DETAIL,
            search: `?id=${id}`,
            state: { id }
        })
    }

    return (
        <div className="shipment-info-bar">
            {
                (userInfo.role !== ROLES.READ_ONLY && userInfo.role !== ROLES.RLSP) &&
                <PickupOptionsButtons
                    id={shipment.idd}
                    shipment={shipment}
                    onReload={() => handleGo(shipment.ship_code)}
                />
            }
            <DownloadCenterBtns shipment={shipment} icons />


        </div>
    );
}