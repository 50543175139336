import React from 'react';

export default props => (
    <div className="tabSelector" >
        <div className="tabHeadOptions">
            {props.options.map((element, i) => (                    
                <h4
                    key={`tabOptions-${i}-${element}`}
                    onClick={props.setSelected.bind(this, i)}
                    className={
                        `tabOption ${i !== 0 ? 'ml-4': ''}
                        ${props.selected === i ? 'tabOptionSelect' : ''}`
                    }
                >
                    {element}
                </h4>
            ))}
        </div>
    </div>
);