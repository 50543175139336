import { addressService } from "../../../services";
import { REMOVE_ADDRESS, SET_ADDRESSES } from "../actionTypes";

export const loadUserAddresses = () => (dispatch) => {
    addressService.getUserAddresses().then((res) => {
        dispatch({ type: SET_ADDRESSES, payload: res.res.dtos });
    });
};

export const savePickupAddress = (body, isAdd) => (dispatch) => {
    addressService.saveAddressToAddressBook(body).then((res) => {
        dispatch(loadUserAddresses());
        // dispatch({
        //     type: isAdd ? ADD_ADDRESS : UPDATE_ADDRESS,
        //     payload: isAdd ? res.res.dtos[0] : body.json.dtos[0]
        // });
    });
};

export const removePickupAddress = (id) => (dispatch) => {
    addressService.deleteAddress(id).then((res) => {
        if (res.res.success) dispatch({ type: REMOVE_ADDRESS, payload: id });
    });
};
