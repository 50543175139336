// WEIGHT_UNITS: 1=kg, 2=lbs
// LENGTH_UNITS: 1=m, 2=in, 3=cm, 4=ft

export const changeWeight = (to, value) => {
    if (to === 0) return value;
    if (to === 1) return (value / 2.2).toFixed(2);
    if (to === 2) return (value * 2.2).toFixed(2);
};

export const changeLength = (from, to, value) => {
    if (from === 0) return value;
    if (from === 2 && to === 4) return (value / 12).toFixed(2);
    if (from === 2 && to === 3) return (value * 2.54).toFixed(2);
    if (from === 2 && to === 1) return (value / 39.37).toFixed(2);
    if (from === 4 && to === 2) return (value * 12).toFixed(2);
    if (from === 4 && to === 3) return (value * 30.48).toFixed(2);
    if (from === 4 && to === 1) return (value / 3.281).toFixed(2);
    if (from === 3 && to === 1) return (value / 100).toFixed(2);
    if (from === 3 && to === 2) return (value / 2.54).toFixed(2);
    if (from === 3 && to === 4) return (value / 30.48).toFixed(2);
    if (from === 1 && to === 3) return (value * 100).toFixed(2);
    if (from === 1 && to === 2) return (value * 39.37).toFixed(2);
    if (from === 1 && to === 4) return (value * 3.281).toFixed(2);
};

export const changeSize = (bytes) => {
    if (bytes >= 1073741824) return (bytes / 1073741824).toFixed(2) + " GB";
    else if (bytes >= 1048576) return (bytes / 1048576).toFixed(2) + " MB";
    else if (bytes >= 1024) return (bytes / 1024).toFixed(2) + " KB";
    else if (bytes > 1) return bytes + " bytes";
    else if (bytes == 1) return bytes + " byte";
    else return "0 bytes";
};

export const isValidPackageProp = (val, property = '') => {
    const splittedValue = String(val).includes('.') ? String(val).split('.') : [String(val), ''];
    return !(splittedValue[0].length > (property == 'weight' ? 5 : 3) || splittedValue[1].length > 2)
};