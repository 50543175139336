import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../core/contexts';
import { updateSubLineProp, setLoader } from '../../core/redux/actions';
import { selectSublines } from '../../core/redux/selectors';
import { Select, InfoTooltip, Spinner } from '../controls';
import { POWRButton, POWRModal } from "../styledComponents";
import { useAlert, useSerialNumberValidation } from '../../core/hooks';

export const ViewPackageSNModal = ({ weightUnit, dimensionUnit, isReleaseLinesEnabled, shipId, rmas, showViewPackage, setShowViewPackage, linesErrors }) => {
    const { removeAlerts } = useAlert();
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { hasInvalidLines } = useSerialNumberValidation();
    const [linesValue, setLinesValue] = useState([]);
    const [initialLines, setInitialLines] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const sublines = useSelector(selectSublines);

    useEffect(() => {
        setLinesValue(sublines.map(x => ({ ...x, error: linesErrors && linesErrors.includes(x.idd)  })));
        setInitialLines(sublines.map(x => ({ ...x, error: linesErrors && linesErrors.includes(x.idd) })));
        //hasInvalidLines()
    }, [sublines, linesErrors])

    const updateSerial = (id, prop, val) => dispatch(updateSubLineProp(id, prop, val));

    const updateInitialSerial = (id, val, rma_num, serialized, part_num, initline) => {
        val = val !== 'Not Present' ? val.replace(/[\s,]/g, '') : val; // Remove spaces and commas from val
        let lines = linesValue.filter(x => x.idd !== id);
        setLinesValue([...lines, { idd: id, part_num:part_num, serial: val, rma_num: rma_num, serialized:serialized }]);
    }
    const handleSave = async () => {
        setIsSubmitting(true);
        //valid serials with api
        let res = await hasInvalidLines(linesValue, true);
        if (res === -1) { 
            setIsSubmitting(false);
            dispatch(setLoader(false));
            return;
        } 
        if (!res || res.length === 0) {
            setLinesValue(linesValue.map(x => ({ ...x, error: false })));
            linesValue.forEach(line => {
                let savedLine = initialLines.find(x => x.idd === line.idd);
                if (savedLine.serial !== line.serial) {
                    updateSerial(line.idd, 'serial', line.serial)
                }
            });
            setIsSubmitting(false);
            dispatch(setLoader(false));
            setShowViewPackage(false);
        }
        else {
            setLinesValue(linesValue.map(x => ({ ...x, error: res && res.includes(x.idd) })));
            setIsSubmitting(false);
            dispatch(setLoader(false));
            
        }
    }

    return (
        <Fragment>
            {
                showViewPackage &&
                <POWRModal
                    size="long"
                    show={true}
                        title={translate('title.viewPackageSN')}
                        onClose={() => { removeAlerts();  setShowViewPackage(false) }}
                    className="modal-view-packages" >
                    <div className="content">
                        <Fragment>
                            <table className="table table-form d-none d-lg-table">
                                <thead>
                                    <tr className="descriptions-package">
                                            <th scope="col">{translate(`gridColumns.rma#`)}</th>
                                            <th scope="col">{translate(`subtitle.package`)}</th>
                                        <th scope="col">{translate(`gridColumns.line`)}</th>
                                        <th scope="col">{translate(`gridColumns.part#`)}</th>
                                        <th scope="col">{translate(`gridColumns.serial#`)}</th>
                                        {
                                            isReleaseLinesEnabled &&
                                            <th scope="col">{translate('gridColumns.released')}</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {(sublines && sublines.length) && sublines.map((line, k) => { //WEB
                                        const initLine = linesValue.find(x => x.idd == line.idd) || ''
                                        return (
                                            <tr key={k} scope="row" className="row-packages">
                                                <td>
                                                    <p>{line.rma_num}</p>
                                                </td>
                                                <td>
                                                    <p>{line.pack_num}</p>
                                                </td>
                                                <td>
                                                    <p>{line.rma_line}</p>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <p>{line.part_num}</p>
                                                        {line.part_descr && <InfoTooltip className="part-desc" middle>{line.part_descr}</InfoTooltip>}
                                                    </div>
                                                </td>
                                                <td>
                                                    <Select
                                                        onAddNew={(val) => initLine.serial === val ? undefined :  updateInitialSerial(line.idd, val, line.rma_num, line.serialized, line.part_num)}
                                                        value={initLine.serial}
                                                        customIcon={'fas fa-caret-down'}
                                                        text={line.serial}
                                                        onChange={(val) => initLine.serial === val ? undefined : updateInitialSerial(line.idd, val, line.rma_num, line.serialized, line.part_num)}
                                                        options={[
                                                            ...line.serials.map(x => ({ code: x, text: x })),
                                                            { code: 'Not Present', text: 'Not Present' }
                                                        ]}
                                                        className={'no-space inside-table'}
                                                        singleSelection
                                                        availableAdd
                                                        disabled={!line.serialized}
                                                        notFilter
                                                        error={initLine.error && translate('error.snInvalid')}
                                                        placeholder={translate('placeholder.selectSerial')} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>

                            </table>

                            <div className="d-block d-lg-none py-16">
                                {(sublines && sublines.length) && sublines.map((line, k) => { //MOBILE
                                    const initLine = linesValue.find(x => x.idd == line.idd) || ''
                                    return (
                                        <div className="border-bottom-gray py-3 mb-3 row-mini-package" key={k}>
                                            <div className="row">
                                                <div className="col-12 col-md-6 d-flex pb-2">
                                                    <p className="m-0">{`${translate(`gridColumns.rma#`)}: `}</p>
                                                    <label>{line.rma_num}</label>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex pb-2">
                                                    <p className="m-0">{`${translate(`gridColumns.package`)}: `}</p>
                                                    <label>{line.rma_num}</label>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex pb-2">
                                                    <p className="m-0">{`${translate(`gridColumns.line`)}: `}</p>
                                                    <label>{line.rma_line}</label>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex pb-2">
                                                    <p className="m-0">{`${translate(`gridColumns.part#`)}: `}</p>
                                                    <label>{line.part_num}</label>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex">
                                                    <p className="m-0">{`${translate(`gridColumns.serial#`)}: `}</p>
                                                    {//isSerialEditable ? TODO: depende del tipo de serialized
                                                        <Select
                                                            onAddNew={(val) => updateInitialSerial(line.idd, val, line.rma_num, line.serialized, line.part_num)}
                                                            value={initLine.serial}
                                                            customIcon={'fas fa-caret-down'}
                                                            text={line.serial}
                                                            onChange={(val) => updateInitialSerial(line.idd, val, line.rma_num, line.serialized, line.part_num)}
                                                            options={[
                                                                ...line.serials.map(x => ({ code: x, text: x })),
                                                                { code: 'Not Present', text: 'Not Present' }
                                                            ]}
                                                            className={'no-space inside-table'}
                                                            singleSelection
                                                            availableAdd
                                                            notFilter
                                                            disabled={!line.serialized}// depende del tipo de serialized}
                                                            placeholder={translate('placeholder.selectSerial')} />
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>
                            
                        </Fragment>
                        </div>
                        <div className="toolbar">
                            <POWRButton onClick={handleSave} disabled={isSubmitting}>
                                {translate(`button.save`)}
                            </POWRButton>
                        </div>
                        {isSubmitting &&
                            <div className="overlay-spinner">
                                <Spinner
                                    className="spinner"
                                    size="100px"
                                    color={'#099AD6'} />
                            </div>
                        }
                </POWRModal>
            }
          
        </Fragment>
    )
}

