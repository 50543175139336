import { getCountry } from "../../../services";
import { getEventCarriers } from "../../../services/carrierListService";
import { SET_CISCO_ENTITY } from "../actionTypes";
import { getRMATypes } from '../../../services/rmaService';

export const loadEventCarriers = () => (dispatch) => {
    return getEventCarriers().then((res) => {
        // debugger;
        dispatch({ type: SET_CISCO_ENTITY, payload: { entityName: 'eventCarriers', data: res.res.dtos } });
        return false;
    });
};

export const loadRMATypes = () => (dispatch) => {
    return getRMATypes().then((res) => {
        dispatch({ type: SET_CISCO_ENTITY, payload: { entityName: 'rmaTypes', data: res.res.dtos } });
        return false;
    });
};

export const loadCountries = (enabled) => (dispatch) => {
    return getCountry(enabled).then((res) => {
        dispatch({ type: SET_CISCO_ENTITY, payload: { entityName: 'countries', data: res.res.dtos } });
        return false;
    });
};