import styled from "styled-components";

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${props => props.wizard ? 'width: calc(100% - 325px)' : ''};
  background-color: ${({ theme: { colors } }) => colors.background};

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    padding-left: 0px;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    ${props => props.wizard ? 'width: 100%' : ''};
  }

`;
