export const RMA_SHIP_EVENT_TYPE = {
    UNKNOWN: 0,
    PICKUP_CREATED_BY_USER: 1,
    PICKUP_CONFIRMED: 2,
    PICKUP_DENIED: 3,
    PICKUP_RESCHEDULED_BY_USER: 4,
    PICKUP_CANCELLED_BY_USER: 5,
    PICKUP_COMPLETED: 6,
    PICKUP_DELAYED: 7,
    SHIPMENT_DELIVERY_COMPLETED: 8,
    SHIPMENT_DELIVERY_TIME_CHANGED: 9,
    SHIPMENT_DELIVERY_DELAYED: 10,
    SHIPMENT_LOST: 11,
    RECEIVING_PART_RECEIVED: 12,
    PICKUP_RESCHEDULED_BY_CARRIER: 13,
    SHIPMENT_DELETED: 14,
    PICKUP_FUTILE: 16,
    LABEL_CREATED: 17,
};

export const RMA_SHIP_EVENT_TYPES = [
    { type: 0, key: 'unknown' },
    { type: 1, key: 'pickupCreatedByUser' },
    { type: 2, key: 'pickupConfirmed' },
    { type: 3, key: 'pickupDenied' },
    { type: 4, key: 'pickupRescheduledByUser' },
    { type: 5, key: 'pickupCancelledByUser' },
    { type: 6, key: 'pickupCompleted' },
    { type: 7, key: 'pickupDelayed' },
    { type: 8, key: 'shipmentDeliveryCompleted' },
    { type: 9, key: 'shipmentDeliveryTimeChanged' },
    { type: 10, key: 'shipmentDeliveryDelayed' },
    { type: 11, key: 'shipmentLost' },
    { type: 12, key: 'receivingPartReceived' },
    { type: 13, key: 'pickupRescheduledByCarrier' },
    { type: 14, key: 'shipmentDeleted' },
    { type: 16, key: 'pickupFutile' },
    { type: 17, key: 'labelCreated' }
];
