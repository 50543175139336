import { SET_LOADER, ADD_ALERT, REMOVE_ALERT, REMOVE_ALERTS, REMOVE_ALL_ALERTS, CLICK_OUTSIDE_RETURN, SET_TYPE_ALERT, REMOVE_ALL_GLOBAL_MESSAGES, OPEN_ASIDE_FILTERS, SET_DOWNLOAD_PARAMS, SET_SHIPMENT_EDIT, SET_RMAS_URL_PARAM } from "../actionTypes";

export const addAlert = (alert) => ({
    type: ADD_ALERT,
    payload: alert
});

export const removeAlertById = (id) => ({
    type: REMOVE_ALERT,
    payload: id
});

export const removeAlertsById = (ids) => ({
    type: REMOVE_ALERTS,
    payload: ids
});

export const removeAllAlerts = () => ({
    type: REMOVE_ALL_ALERTS
});

export const removeAllGlobalMessages = () => ({
    type: REMOVE_ALL_GLOBAL_MESSAGES
});

export const setTypeAlert = (isModal) => ({
    type: SET_TYPE_ALERT,
    payload: isModal
});

export const setLoader = (show) => ({
    type: SET_LOADER,
    payload: show
});

export const clickOutsideReturn = (click) => ({
    type: CLICK_OUTSIDE_RETURN,
    payload: click
});

export const openAsideFilters = (click) => ({
    type: OPEN_ASIDE_FILTERS,
    payload: click
});

export const setDownloadParams = (params) => ({
    type: SET_DOWNLOAD_PARAMS,
    payload: params
});
export const setShipmentEdit = (isEdit) => ({
    type: SET_SHIPMENT_EDIT,
    payload: isEdit
});
export const setRMAsURLParam = (rmas) => ({
    type: SET_RMAS_URL_PARAM,
    payload: rmas
});