import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR } from '../../core/constants/Colors';
import { useTranslate } from '../../core/contexts';
import { loadPackages, loadSubLines, updateSubLineProp, saveCreatedLocalPackages } from '../../core/redux/actions';
import { selectPackagesWithSublines, selectUserInfo, selectPickedSublinesOfSelectedLines, selectRMAsNum, selectSublines } from '../../core/redux/selectors';
import { getShiptreeByIdReleaseLines, releaselines } from '../../services';
import { Select, InfoTooltip, Checkbox, Button } from '../controls';
import { POWRButton, POWRIcon, POWRModal } from "../styledComponents";

export const ViewPackageModal = ({ idd, isSerialEditable, weightUnit, dimensionUnit, isReleaseLinesEnabled, shipId, rmas }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    //await dispatch(saveCreatedLocalPackages(shipmentId, selectedParts, returnFlow.weight_uom, returnFlow.length_uom, rmaNums));
    const [viewPackage, setViewPackage] = useState([]);
    const [showViewPackage, setShowViewPackage] = useState(false);
    const [selectedLines, setSelectedLines] = useState([]);
    const [sublines, setSublines] = useState([]);
    const [linesValue, setLinesValue] = useState([]);
    const [initialLines, setInitialLines] = useState([]);
    const [showReleaseLinesModal, setShowReleaseLinesModal] = useState(false);

    const firstUpdate = useRef(true);

    const rma_packages = useSelector(selectPackagesWithSublines);
    const userInfo = useSelector(selectUserInfo);
    const selectedParts = useSelector(selectPickedSublinesOfSelectedLines);
    const sublinesPkg = useSelector(selectSublines);
    const rmaNums = useSelector(selectRMAsNum);
    useEffect(() => {
        if (showViewPackage && isReleaseLinesEnabled && shipId !== '' && !sublines.length) {
            getShiptreeByIdReleaseLines(shipId).then(response => {
                if (response && response.res && response.res.length) {
                    const sbs = [];
                    response.res.forEach(x => x.children.forEach(pk => pk.children.forEach(sb => sb ? sbs.push(sb) : null)));
                    setSublines(sbs);
                }
            });
        }
    }, [isReleaseLinesEnabled, sublines, shipId, showViewPackage]);

    useEffect(() => {
        if (rma_packages.length) {
            setViewPackage(rma_packages.filter(x => x.id === idd))
            let initialLines = [];
            rma_packages.forEach(pkg => {
                pkg.lines.forEach(line => {
                    initialLines.push(line)
                });
                
            });
            setLinesValue(initialLines);
            setInitialLines(initialLines);
           
        }
    }, [idd, rma_packages, showViewPackage])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
       if(isSerialEditable) dispatch(saveCreatedLocalPackages(shipId, sublinesPkg, weightUnit, dimensionUnit, rmaNums, true));
    }, [selectedParts])

    const updateSerial = (id, prop, val) => dispatch(updateSubLineProp(id, prop, val));
    
    const updateInitialSerial = (id, val, rma_num) => {
        let lines = linesValue.filter(x => x.idd !== id);
        setLinesValue([...lines, { idd: id, serial: val, rma_num: rma_num }]);

    }
    const handleSave = async () => {
        linesValue.forEach(  line  => {
            let savedLine = initialLines.find(x => x.idd === line.idd);
            if(savedLine.serial !== line.serial)
                updateSerial(line.idd, 'serial', line.serial)
        });
        setShowViewPackage(false);
    }

    const canReleaseLines = useMemo(() =>
        userInfo.releaseLinesAllowed && isReleaseLinesEnabled
        && selectedLines.length && !selectedLines.find(x => sublines.find(y => y.idd === x && y.rma_line_released))
        , [userInfo, isReleaseLinesEnabled, selectedLines, sublines]);

    const handleReleaseLines = () => {
        const dtos = [];
        selectedLines.forEach(x => {
            const refFromShiptree = sublines.find(sb => sb.idd === x);
            if (refFromShiptree)
                dtos.push({
                    idd: x,
                    ship_id: refFromShiptree.ship_id,
                    line: refFromShiptree.line,
                    rma: refFromShiptree.rma,
                    part: refFromShiptree.part,
                    seq: refFromShiptree.seq,
                    sub: refFromShiptree.sub,
                    qty: refFromShiptree.qty,
                    rma_line_released: refFromShiptree.rma_line_released,
                    type: 'ShipTree'
                });
        });
        releaselines({ ship_id: shipId, json: { dtos: dtos } }).then(() => {
            setSelectedLines([]);
            setSublines([]);
            dispatch(loadPackages(shipId, true));
            dispatch(loadSubLines(shipId, rmas, true));
        });
        setShowReleaseLinesModal(false);
    };

    const handleCheck = (lineId, isChecked) => {
        if (isChecked) setSelectedLines(selectedLines.filter(x => x !== lineId))
        else setSelectedLines([...selectedLines, lineId]);
    };

    return (
        <Fragment>
            <div onClick={() => setShowViewPackage(true)} className="btn p-0">
                <POWRIcon className="fas fa-search-plus mr-1" color={COLOR.CISCO_BLUE} size="20px" />
            </div>
            {
                showViewPackage &&
                <POWRModal
                    size="long"
                    show={showViewPackage}
                    title={translate('title.viewPackage')}
                    onClose={() => setShowViewPackage(false)}
                    className="modal-view-packages" >
                    <div className="content">
                        {(viewPackage && viewPackage.length) && viewPackage.map((pkg, i) => {
                            const { pack_name, qty, weight, depth, width, height } = pkg.head;
                            return (
                                <Fragment key={i}>
                                    <div className="row headers-package pb-16">
                                        <div className="col-auto pr-4">
                                            <p className="m-0">{translate('gridColumns.package')}</p>
                                            <label>
                                                <POWRIcon className="far fa-box mr-3" size={"14px"} color={COLOR.CISCO_BLUE} />
                                                {pack_name}
                                            </label>
                                        </div>
                                        <div className="col-auto pr-4">
                                            <p className="m-0">{translate('gridColumns.qty')}</p>
                                            <label>{qty}</label>
                                        </div>
                                        <div className="col-auto pr-4">
                                            <p className="m-0">{translate('gridColumns.weight')}</p>
                                            <label>{`${parseFloat(weight).toFixed(2)} ${weightUnit}`}</label>
                                        </div>
                                        <div className="col-auto col-lg pt-3 pt-md-0">
                                            <p className="m-0">{translate('gridColumns.dimensions')}</p>
                                            <label>{`${parseFloat(depth).toFixed(2)} x ${parseFloat(width).toFixed(2)} x ${parseFloat(height).toFixed(2)} ${dimensionUnit}`}</label>
                                        </div>
                                    </div>
                                    <table className="table table-form d-none d-lg-table">
                                        <thead>
                                            <tr className="descriptions-package">
                                                {
                                                    isReleaseLinesEnabled &&
                                                    <th></th>
                                                }
                                                <th scope="col">{translate(`gridColumns.rma#`)}</th>
                                                <th scope="col">{translate(`gridColumns.line`)}</th>
                                                <th scope="col">{translate(`gridColumns.part#`)}</th>
                                                <th scope="col">{translate(`gridColumns.serial#`)}</th>
                                                {
                                                    isReleaseLinesEnabled &&
                                                    <th scope="col">{translate('gridColumns.released')}</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pkg.lines.map((line, k) => {
                                                const isChecked = selectedLines.find(x => x === line.idd);
                                                const released = sublines.findIndex(x => x.idd === line.idd && x.rma_line_released) >= 0;
                                                const initLine = linesValue.find(x => x.idd == line.idd)
                                                return (
                                                    <tr key={k} scope="row" className="row-packages">
                                                        {
                                                            isReleaseLinesEnabled &&
                                                            <td>
                                                                <Checkbox
                                                                    onClick={() => handleCheck(line.idd, isChecked)}
                                                                    checked={isChecked}
                                                                    id={`cbox-${line.rma_num}${line.idd}`} />
                                                            </td>
                                                        }
                                                        <td>
                                                            <p>{line.rma_num}</p>
                                                        </td>
                                                        <td>
                                                            <p>{line.rma_line}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <p>{line.part_num}</p>
                                                                {line.part_descr && <InfoTooltip className="part-desc" middle>{line.part_descr}</InfoTooltip>}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {isSerialEditable ?
                                                                <Select
                                                                    onAddNew={(val)=>updateInitialSerial(line.idd,val, line.rma_num)}
                                                                    value={initLine.serial}
                                                                    customIcon={'fas fa-caret-down'}
                                                                    text={line.serial}
                                                                    onChange={(val)=>updateInitialSerial(line.idd,val, line.rma_num)}
                                                                    options={line.serials.map((x => ({ code: x, text: x })))}
                                                                    className={'no-space inside-table'}
                                                                    singleSelection
                                                                    availableAdd
                                                                    notFilter  
                                                                    placeholder={translate('placeholder.selectSerial')} />
                                                                :
                                                                <p>{line.serial}</p>
                                                            }
                                                        </td>
                                                        {
                                                            isReleaseLinesEnabled &&
                                                            <td>
                                                                {released ? 'Y' : 'N'}
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="d-block d-lg-none py-16">
                                        {pkg.lines.map((line, k) => {
                                            const isChecked = selectedLines.find(x => x === line.idd);
                                            const released = sublines.findIndex(x => x.idd === line.idd && x.rma_line_released) >= 0;
                                            const initLine = linesValue.find(x => x.idd == line.idd)

                                            return (
                                                <div className="border-bottom-gray py-3 mb-3 row-mini-package" key={k}>
                                                    <div className="row">
                                                        {
                                                            isReleaseLinesEnabled &&
                                                            <div className="col-12 col-md-6 d-flex pb-2">
                                                                <Checkbox
                                                                    onClick={() => handleCheck(line.idd, isChecked)}
                                                                    checked={isChecked}
                                                                    id={`cbox-${line.rma_num}${line.idd}`} />
                                                            </div>
                                                        }
                                                        <div className="col-12 col-md-6 d-flex pb-2">
                                                            <p className="m-0">{`${translate(`gridColumns.rma#`)}: `}</p>
                                                            <label>{line.rma_num}</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 d-flex pb-2">
                                                            <p className="m-0">{`${translate(`gridColumns.line`)}: `}</p>
                                                            <label>{line.rma_line}</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 d-flex pb-2">
                                                            <p className="m-0">{`${translate(`gridColumns.part#`)}: `}</p>
                                                            <label>{line.part_num}</label>
                                                        </div>
                                                        <div className="col-12 col-md-6 d-flex">
                                                            <p className="m-0">{`${translate(`gridColumns.serial#`)}: `}</p>
                                                            {isSerialEditable ?
                                                                <Select
                                                                    onAddNew={(val)=>updateInitialSerial(line.idd,val, line.rma_num)}
                                                                    value={initLine.serial}
                                                                    customIcon={'fas fa-caret-down'}
                                                                    text={line.serial}
                                                                    onChange={(val)=>updateInitialSerial(line.idd,val, line.rma_num)}
                                                                    options={line.serials.map((x => ({ code: x, text: x })))}
                                                                    className={'no-space inside-table'}
                                                                    singleSelection
                                                                    availableAdd
                                                                    notFilter
                                                                    placeholder={translate('placeholder.selectSerial')} />
                                                                :
                                                                <p>{line.serial}</p>
                                                            }
                                                        </div>
                                                        {
                                                            isReleaseLinesEnabled &&
                                                            <div className="col-12 col-md-6 d-flex">
                                                                <p className="m-0 mr-2">{`${translate('gridColumns.released')}: `}</p>
                                                                <label>{released ? 'Y' : 'N'}</label>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                    <div className="toolbar">
                        {
                            isReleaseLinesEnabled &&
                            <Button
                                className="col-auto text-left y-centered mr-2"
                                disabled={!canReleaseLines}
                                onClick={() => setShowReleaseLinesModal(true)}>
                                {translate('button.releaseLines')}
                            </Button>
                        }
                        <POWRButton onClick={() => isSerialEditable ? handleSave() : setShowViewPackage(false)}>
                            {translate(`button.${isSerialEditable ? 'save' : 'ok'}`)}
                        </POWRButton>
                    </div>
                    {
                        showReleaseLinesModal &&
                        <POWRModal
                            show={true}
                            title={translate('message.title.warning')}
                            size={'medium'}
                            onClose={() => setShowReleaseLinesModal(false)}>
                            <div className="x-centered mt-3 mb-4">
                                <POWRIcon
                                    className="fas fa-trash-alt"
                                    size="3.5em"
                                    color={COLOR.CISCO_BLUE} />
                            </div>
                            <span className="d-block my-2 text-center">
                                {translate(`button.releaseLines`)}
                            </span>
                            <span className="d-block my-2 text-center">{translate('message.confirmReleaseLines')}</span>
                            <div className="toolbar">
                                <POWRButton color={'secondary'} onClick={() => setShowReleaseLinesModal(false)}>{translate('button.cancel')}</POWRButton>
                                <POWRButton onClick={() => handleReleaseLines()}>{translate('button.ok')}</POWRButton>
                            </div>
                        </POWRModal>
                    }
                </POWRModal>
            }
        </Fragment>
    )
}

