import React, { Fragment, useState, useEffect } from "react";
import { Card } from "../../components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslate } from "../../core/contexts";
import { Input } from "../../components/controls";
import { gtagService, getShipments } from "../../services";
import { REGEXP, SEARCH_SHIPMENTS, SHIPMENT_DETAIL, SHIPMENT_STATUS_NUM } from "../../core/constants";
import moment from "moment";


const SearchShipment = ({ withoutCard }) => {
    const { translate } = useTranslate();
    const history = useHistory();
    const [rma, setRMA] = useState("");
    const [error, setError] = useState("");

    const location =useLocation();

    //getting parameters from URL
    const params = new URLSearchParams(window.location.search);
    const shipIdRMA = params.get('shipIdRMA');

      //test get rmas from local storage
      const shipIdFromLocalStorage = localStorage.getItem('scmParams');

    //handle redirect page if have required parameters in URL
    useEffect(() => {
        if (shipIdRMA || JSON.parse(shipIdFromLocalStorage)?.shipIdRMA) {
            let shipIdParam = shipIdRMA || JSON.parse(shipIdFromLocalStorage).shipIdRMA;
            handleRefreshFromURL(shipIdParam);
        }
     }, [location])


     //function to redirect page to shipments list or shipments deteils
     const handleRefreshFromURL = (shipIdRMA) => {
        setRMA(shipIdRMA);
        gtagService.event('DashboardWidgets', 'SearchShipment');

        //validate rma or shipmentId lenght
        if (shipIdRMA.length <= 2) {
            setError(translate('error.pleaseMin2'));
        } else {
            //search shipments with URL params
            let preSearch = shipIdRMA ? parseInt(shipIdRMA) ? 1 : 2 : 0; // if the param is a number, then is a RMA, if not, is a shipmentId
            const parameters = {
                param_ship_code: preSearch === 2 ?  shipIdRMA : "",
                param_rma_or_tradein:preSearch === 1 ?  shipIdRMA : "",
                param_tn: "",
                param_date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                param_date_to: moment().format('YYYY-MM-DD'),
                param_ship_status: "",
                param_max_records: 2,
                param_exclude_saved: 'on',
                param_use_date:'on',
                'id-gct-view-search-adv-checkbox':'on'
            };
            getShipments(parameters).then(response => {
                let resStr = response.res ? typeof (response.res) === 'string' ?
                    response.res.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                    : response.res : { dtos: [] };
                let dtos = typeof (resStr) === 'string' ? JSON.parse(resStr).dtos : resStr.dtos;
                
                //if the result is one and shipment state is different than NOT SUBMITTED redirect to shipment details
                if (dtos.length == 1) {
                    const { ship_code, status } = dtos[0];
                    const notSubmitted = status === SHIPMENT_STATUS_NUM.STATUS_DRAFT;
                    
                    //remove local storage param after shipments validations
                    localStorage.removeItem('scmParams');
                    
                    if (notSubmitted)
                        history.push({
                            pathname: SEARCH_SHIPMENTS,
                            state: { search: shipIdRMA },
                        });
                        //redirect to shipment list when the shipment is not submitted, because does not have datails yet
                    else
                        history.push({
                            pathname: SHIPMENT_DETAIL,
                            search: `?id=${ship_code}`,
                            state: { id: ship_code, data: dtos[0] },
                        });
                }
                //if the results are more than one or zero, redirect to shipments list, same as current behavior
                else {
                    history.push({
                        pathname: SEARCH_SHIPMENTS,
                        state: { search: shipIdRMA },
                    });
                }
            }).catch(err => {
            });
            setError("");
        }
  
    };

    const handleSearchShipment = () => {
        gtagService.event('DashboardWidgets', 'SearchShipment');
        if (rma.length <= 2) {
            setError(translate('error.pleaseMin2'));
        } else {
            setError("");
            history.push({
                pathname: SEARCH_SHIPMENTS,
                state: { search: rma },
            });
        }
    };

    const handleChange = (value) => {
        if (REGEXP.SHIPMENTS.test(value)) {
            setRMA(value);
        }
    };

    return (
        <Fragment>
            {/* (GPT-3 automatically generated documentation)
                     This code creates a new card with a search input field. 
                     The input field is bound to the searchShipment property of the Shipments component. 
                     The onClick event handler is bound to the handleSearchShipment method. 
                     The handleSearchShipment method is defined in the Shipments component.
                       */}
            {
                !withoutCard ?
                    <Card
                        icon="fal fa-search"
                        title={translate('title.shipmentSearch')}
                        value={rma}
                        setValue={setRMA}
                        onClick={handleSearchShipment}
                        button={translate("button.search")}
                        label={translate('placeholder.shipmentIdRMA')}
                        validateRMA={false}
                        placeholder={translate('title.shipmentOrRMA')}
                        error={error}
                        type="documentation">
                        {translate('message.searchForShipment')}
                        <Link to={SEARCH_SHIPMENTS} > {translate('title.shipmentSearch')}</Link>
                    </Card>
                    :
                    <div>
                        <Input
                            label={translate('placeholder.shipmentIdRMA')}
                            name="rma"
                            placeholder={translate('title.shipmentOrRMA')}
                            value={rma}
                            onChange={(e) => handleChange(e.target.value)}
                            onEnter={() => handleSearchShipment()}
                            error={!!error.length}
                            errorMessage={error}
                            icon={{ content: 'search', handler: handleSearchShipment }}
                        />
                        <div className="comments">
                            {translate('message.searchForShipment')}
                            <Link to={SEARCH_SHIPMENTS} className="blue-link" > {translate('title.shipmentSearch')}</Link>
                        </div>
                    </div>
            }
        </Fragment>
    );
};

export default SearchShipment;