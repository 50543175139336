import React from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from '../core/hooks';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeAlertsById } from '../core/redux/actions/general.actions';
import { selectTypeAlert } from '../core/redux/selectors/general.selectors';
import { useSelector } from "react-redux";
import { COLOR } from '../core/constants/Colors';
import { POWRIcon } from './styledComponents';

export default _ => {
    const { removeAlert, alerts, removeAlerts } = useAlert();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showedAlerts, setShowedAlerts] = useState([]);
    const isModal = useSelector(selectTypeAlert);
    useEffect(() => {
        if (isModal) removeAlerts();

        // IF AN ALERT HAS A PAGE ATTR EQUALS TO '*' AND DOESNT HAVE AUTOMATICALLY DISMISS BY TIME
        // YOU NEED TO MANAGE ITS DISMISS WITH "removeAlert" METHOD FROM "useAlert" HOOK INSIDE A COMPONENT ACTION
        const newAlertsToShow = [];
        const alertsIdsToRemove = [];
        alerts.forEach((alert) => {
            if (alert.page === '*' || alert.page === history.location.pathname)
                newAlertsToShow.push(alert);
            else if (alert.page !== '*' && alert.page !== history.location.pathname)
                alertsIdsToRemove.push(alert.id);
        });
        setShowedAlerts(newAlertsToShow);
        if (alertsIdsToRemove.length) dispatch(removeAlertsById(alertsIdsToRemove));
    }, [alerts, history.location, dispatch]);

    return (
        <div className="main-alert">
            {
                (showedAlerts && showedAlerts.length) ?
                    showedAlerts.map((alert, i) => {
                        return (
                            <div
                                key={i}
                                className={`row-alert ${alert.visible ? 'd-alert' : ''} 
                                ${alert.color} ${i !== 0 ? 'top' : ''} 
                                ${alert.isModal ? 'hide-alert main-alert-modal' : 'hide-modal'}
                                `}
                            >
                                <div className="d-flex">
                                    <POWRIcon
                                        className={
                                            alert.color === "green" ? "fas fa-check-circle iconAlert mr-3" :
                                                alert.color === "orange" ? "fas fa-exclamation-circle iconAlert mr-3" :    
                                                    alert.color === "red" ? "fas fa-exclamation-circle iconAlert mr-3" :
                                                        alert.color === "yellow" ? "fas fa-exclamation-triangle iconAlert mr-3" :
                                                        "fas fa-info-circle iconAlert mr-3"
                                        }
                                        color={COLOR.WHITE}
                                        size="16px" />
                                    <p>{alert.message}</p>
                                </div>
                                <div
                                    onClick={() => removeAlert(alert.id)}
                                    key={`closeBtnHeader-${alert.color}`}
                                >
                                    <POWRIcon
                                        className="fas fa-times"
                                        color={COLOR.WHITE}
                                        size="16px" />
                                </div>
                            </div>)
                    })
                    : null
            }
        </div>
    );
}