import React, { useCallback, useEffect, useState, useRef, Fragment, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextInput, Link, Select, InfoTooltip, CheckRadio } from '../controls';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';
import { PickupLocationSchema } from '../../core/schemas';
import { useTranslate } from '../../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectBaseRMAInfo, selectCountries, selectCountriesSerbiaMontenegro, selectReturnFlowOptions, selectUserAddresses, selectIsFA, selectCategoryCode } from '../../core/redux/selectors';
import { addPickupAddress, loadCountries, savePickupAddress, updatePickupAddress } from '../../core/redux/actions';
import { useAlert, usePickupLocationValidation, useConfigParams } from '../../core/hooks';
import { COLOR } from '../../core/constants/Colors';
import { SET_GSTIN } from '../../core/redux/actionTypes';
import  * as allCountryStates from '../../core/constants/CountriesStates';
import { COUNTRY, REGEXP } from '../../core/constants';

export const PickupLocationForm = ({ onClose, isAdd, saveAtAddressBook, data, onDelete, saveData, isRTF = false }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const refSubmit = useRef();
    const { handleMaxLengthPostalCode } = usePickupLocationValidation();
    const { showAlert, removeAlert } = useAlert();
    const { getSATServiceEnabledService, getSATServiceEnabledProduct, getSATValidationRequired } = useConfigParams();

    const [initialValues, setInitialValues] = useState({
        name: '',
        addr1: '',
        addr2: '',
        addr3: '',
        addr4: '',
        country: '',
        province: '',
        city: '',
        postal: '',
        save_as: '',
        zipRequired: false,
        zipFormat: '',
        gstin: '',
        saveAtAddressBook: false,
        isStateListEnable: false,
        EIN:"",
        enable_parties_to_transaction:null,
        related:false,
        noRelated:false,
        enable_uc_type:null

    });
    const [address, setAddress] = useState('');
    const [localData, setLocalData] = useState(null);
    const [hasDupError, setHasDupError] = useState(false);
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [saveAsAtAddressBook, setSaveAsAtAddressBook] = useState(saveAtAddressBook ? saveAtAddressBook : false);
    const [countryStateLst,setCountryStateLst] = useState([]);
    const [stateListEnable,setStateListEnable] = useState(false);
    const [related, setRelated] = useState(null);
    const [noRelated, setNoRelated] = useState(null);
    const [other, setOther] = useState(null);
    const [reselled, setReselled] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const addressBook = useSelector(selectReturnFlowOptions);
    const currentAddresses = useSelector(selectUserAddresses);
    const countries = useSelector(data && (data.isCS) ? selectCountriesSerbiaMontenegro : selectCountries);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const isFA = useSelector(selectIsFA);
    const categoryCode = useSelector(selectCategoryCode);
    const isBaAvailable = categoryCode === 'S' ? getSATServiceEnabledService() : getSATServiceEnabledProduct();
    const RFCValidationRequired = getSATValidationRequired();

    useEffect(() => {
        dispatch(loadCountries(true));
    }, []);

    const validateSAT = useMemo(() => (RFCValidationRequired && isBaAvailable),[isBaAvailable, RFCValidationRequired]);
    useEffect(() => {
        setLocalData(data);
    }, [data]);

    useEffect(() => {
        
        const hasData = ((!isAdd || !saveAtAddressBook) && localData);
        const ctr =  hasData ? countries.find(x => x.code === localData.country) : null;
        let newValues = {
            ...initialValues,
            ...(hasData ? localData : {}),
            ...isRTF ? {country:localData?.country} : (hasData && ctr ? {country:ctr.code} : {country:""}),
            ...(ctr ? { zipRequired: ctr.post_code_required, zipFormat: ctr.post_code_fmt_required ? ctr.post_code_fmt_regexp : '',isStateListEnable :ctr.states_list_enable?ctr.states_list_enable:false } : {}),
            ...((addressBook.pickup[0] && addressBook.pickup[0].country === COUNTRY.INDIA && (!localData || !localData.gstin)) ? ({ gstin: (rmaInfo.gstin) || '' }) : {}),
            saveAtAddressBook
        };
       
        setInitialValues(newValues);
        setStateListEnable(ctr && ctr.states_list_enable ? ctr.states_list_enable : false)
        if (hasData){
            checkInitialErrors(newValues);
            getCountryStateLst(newValues.country);
        }

        if (newValues['enable_parties_to_transaction'] !== null && newValues['enable_parties_to_transaction'] !== '') {
            setRelated(newValues['enable_parties_to_transaction'])
            setNoRelated(!newValues['enable_parties_to_transaction'])
        }
        if (newValues['enable_uc_type'] !== null && newValues['enable_uc_type'] !== '') {
            setOther(newValues['enable_uc_type'])
            setReselled(!newValues['enable_uc_type'])
        }

        
        
    }, [localData, isAdd, countries, rmaInfo, saveAtAddressBook]);

    useEffect(() => {
        if (address !== '') setLocalData(addressBook.pickup.find(x => x.idd === Number(address)));
    }, [address, addressBook.pickup]);

    const getSelectedCountryName = useCallback((countryCode) => {
        if (countryCode && countries.length) {
            const ctr = countries.find(x => x.code === countryCode)
            return ctr ? ctr.text : '';
        }
        else return '';
    }, [countries]);

    const isDuplicate = useCallback((values) => {
        for (let ct of currentAddresses) {
            if (ct.atag.toLowerCase().trim() === values.save_as.toLowerCase().trim()) {
                if ((!localData || (localData && (ct.idd !== localData.idd || localData.ctag !== values.save_as)))) {
                    return true;
                }
            }
        }
        return false;
    }, [currentAddresses, localData]);

    const checkInitialErrors = async (values) => {
        let isValid = await PickupLocationSchema.isValid(values)
        if (!isValid) refSubmit.current.click();
    };

    const save = async (values, { setSubmitting }) => {
       
        let add = saveAsAtAddressBook && localData ? localData.save_as !== values.save_as : isAdd;
        let newAddress = {
            ...(localData ? localData : {}),
            ...values,
            ...((add && saveAsAtAddressBook) ? { idd: 0 } : {})
        };

        newAddress = {
            idd: newAddress.idd,
            name: newAddress.name,
            addr1: newAddress.addr1,
            addr2: newAddress.addr2,
            addr3: newAddress.addr3,
            addr4: newAddress.addr4,
            country: newAddress.country,
            province: newAddress.province,
            city: newAddress.city,
            postal: handleMaxLengthPostalCode(newAddress.postal),
            gstin: newAddress.gstin,
            atag: newAddress.save_as,
            save_as: newAddress.save_as,
            EIN:newAddress.EIN,
            enable_parties_to_transaction:(related || noRelated) ? related : '',
            enable_uc_type: (other || reselled) ? other : ''
        };
        
        if (saveData) saveData(newAddress);

        else if (!saveAsAtAddressBook) {
            if (!localData) dispatch(addPickupAddress(newAddress));
            else dispatch(updatePickupAddress(newAddress));
            dispatch({ type: SET_GSTIN, payload: newAddress.gstin });
        } else {
            if (showSaveAs) {
                dispatch(updatePickupAddress(newAddress));
                dispatch({ type: SET_GSTIN, payload: newAddress.gstin });
            }
            if (add) delete newAddress['save_as'];
            dispatch(savePickupAddress({ json: { dtos: [newAddress] } }, false));
        }

        setTimeout(() => {
            setSubmitting(false);
            onClose();
        }, 400);
    };

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        e.preventDefault();
        let result = await validateForm(values);
        if (!Object.keys(result).length) {
            const hasDuplicateValues = isDuplicate(values);
            if (!saveAsAtAddressBook || !isAdd || !hasDuplicateValues) handleSubmit();
            else {
                setHasDupError(true);
                showAlert({ id: 'duplicatedAddress', page: '*', message: translate('error.duplicatedAddress'), color: "yellow", isModal: true });
            }
        }
        else {
           
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow", isModal: true })
        }
    };

    const handleSaveAs = (v) => {
        setShowSaveAs(v);
        setSaveAsAtAddressBook(v);
    };


    const handleRefSubmit = () => {
        refSubmit.current.click();
    };

    const getCountryStateLst = (countryCode) => {
        setCountryStateLst([]);
        if(allCountryStates[countryCode]!==undefined){
            setCountryStateLst(allCountryStates[countryCode])}       
    };

    const handleEnableParties = (value) => {
        if (value && related) setRelated(false);
        if (value && !related) {
            setRelated(true);
            setNoRelated(false);
        }
        if (!value && noRelated) setNoRelated(false);
        if (!value && !noRelated) {
            setNoRelated(true)
            setRelated(false);
        }
    }
    const handleEnableUCT = (value) => {
        if (value && other) setOther(false);
        if (value && !other) {
            setOther(true);
            setReselled(false);
        }
        if (!value && reselled) setReselled(false);
        if (!value && !reselled) {
            setReselled(true)
            setOther(false);
        }
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={PickupLocationSchema}
            enableReinitialize
            onSubmit={save}>
            {
                ({ handleSubmit, isSubmitting, values, setFieldValue, getFieldMeta, validateForm, setTouched, setErrors, setSubmitting }) => (
                    <Form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} className="content p-0 overflow-hidden"  >
                        <div className="content">
                            <div className="row">
                                { !isRTF &&
                                <div className="col-md-6 px-3 mb-0 form-group mr-md-5">
                                    {
                                        !saveAtAddressBook && isAdd ?
                                            <Select
                                                onAddNew={(val) => {
                                                    // setLocalData({
                                                    //     save_as: val, name: '', addr1: '', addr2: '', addr3: '', addr4: '', country: '', province: '', city: '', postal: '', gstin: ''
                                                    // });
                                                    setFieldValue('save_as', val, false);
                                                }}
                                                value={values.save_as}
                                                text={values.save_as}
                                                onChange={(val) => {
                                                    setAddress(val);
                                                    const result = addressBook.pickup.find(x => String(x.idd) === val);
                                                    setFieldValue('save_as', result ? result.atag : val, false);
                                                }}
                                                options={addressBook.pickup.length ? addressBook.pickup.filter(x => x && x.atag).map(x => ({ text: String(x.atag), code: String(x.idd) })) : []}
                                                className="inputSpace"
                                                availableAdd singleSelection
                                                label={isAdd ? translate('formLabel.addressNameAddressBook') : translate('formLabel.addressName')} />
                                            :
                                            <TextInput type="text" isRequired name={'save_as'} label={'formLabel.addressName'} maxLength="255" />
                                    }
                                </div>
                                }
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'name'} label={'formLabel.company'} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'addr1'} label={'formLabel.address1'} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'addr2'} label={'formLabel.address2'} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'addr3'} label={'formLabel.address3'} maxLength="255" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'addr4'} label={'formLabel.address4'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        value={values.country}
                                        text={getSelectedCountryName(values.country)}
                                        onChange={(val) => {
                                            getCountryStateLst(val)
                                            const ctr = countries.find(x => x.code === val);
                                            if (ctr) {
                                                setFieldValue('zipRequired', ctr.post_code_required, true);
                                                setFieldValue('zipFormat', ctr.post_code_fmt_required ? ctr.post_code_fmt_regexp : '', true);
                                                setFieldValue('isStateListEnable', ctr.states_list_enable, true); 
                                                setStateListEnable(ctr.states_list_enable);                                               
                                            }
                                            if(val == rmaInfo?.returnAddress.country){
                                                setFieldValue('EIN', '', false);
                                                setFieldValue('enable_parties_to_transaction', false, false);
                                                setFieldValue('enable_uc_type', false, false);
                                            }

                                            setFieldValue('country', val, true);
                                            setFieldValue('province', '', false);
                                           
                                        }}
                                        options={countries}
                                        className="inputSpace"
                                        singleSelection mandatory
                                        error={getFieldMeta('country').touched && getFieldMeta('country').error}
                                        label={translate('formLabel.country')} />                                                                             
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    {(stateListEnable || values.isStateListEnable) && countryStateLst.length !== 0 ?
                                        <Select
                                             options={countryStateLst.map((obj) => {
                                                 return {
                                                     code: obj.code,
                                                     text: obj.name + ' (' + obj.code + ')'
                                                 }
                                             })}
                                            value={values.province}
                                            className="inputSpace"
                                            onChange={(val) => { setFieldValue('province', val, true); }}
                                            singleSelection mandatory
                                            error={getFieldMeta('province').touched && getFieldMeta('province').error}
                                            label={translate('formLabel.state')}
                                            isRequired = {true} />
                                    :
                                        <TextInput type="text" name={'province'} label={'formLabel.state'} maxLength="255" />
                                    }
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired = {false}  name={'city'} label={'formLabel.city'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired={values.zipRequired} name={'postal'} label={'formLabel.zip'} maxLength='25' customIcon={values.zipFormat ?
                                        <InfoTooltip>{translate('message.postalFormat')} {countries.find(x => x.code === values.country) ?
                                            countries.find(x => x.code === values.country).post_code_fmt : ''}</InfoTooltip> : null} />
                                </div>
                                {isFA && values.country === COUNTRY.US && values.country != rmaInfo.returnAddress.country &&
                                    <Fragment>
                                        <div className="col-md-6 px-3 mb-0 form-group">
                                            <TextInput type="text" isRequired={false} name={'EIN'} label={'formLabel.EIN'} maxLength="20" />
                                        </div>
                                        <div className="col-md-6 px-3 mb-0 form-group">
                                            <div className='inputControlWrapper'>
                                                <div className='inputControl no-border'>
                                                    <label className='mb-1'>{translate('formLabel.enableParties')}</label>
                                                    <div className='inputContainer'>
                                                        <CheckRadio
                                                            id={'related'}
                                                            name="related"
                                                            text={translate("formLabel.related")}
                                                            checked={related}
                                                            onChange={(val) => { setFieldValue('enable_parties_to_transaction',!values.enable_parties_to_transaction, true); }}
                                                            onClick={()=>{handleEnableParties(true)}}
                                                        />
                                                    </div>
                                                   
                                                    <div className='inputContainer'>
                                                        <CheckRadio
                                                            id={'noRelated'}
                                                            name="noRelated"
                                                            text={translate("formLabel.noRelated")}
                                                            checked={noRelated}
                                                            onChange={(val) => { setFieldValue('enable_parties_to_transaction',  values.enable_parties_to_transaction != null && values.enable_parties_to_transaction ? false: null, false); }}
                                                            onClick={()=>{handleEnableParties(false)}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3 mb-0 form-group">
                                            <div className='inputControlWrapper'>
                                                <div className='inputControl no-border'>
                                                    <label className='mb-1'>{translate("formLabel.enableUCType")}</label>
                                                    <div className='inputContainer'>
                                                        <CheckRadio
                                                            id={'other'}
                                                            name="other"
                                                            text={translate("formLabel.otherUnknown")}
                                                            checked={other}
                                                            onChange={(val) => { setFieldValue('enable_uc_type', true, false); }}
                                                            onClick={()=>{handleEnableUCT(true)}}
                                                        />
                                                    </div>
                                                    <div className='inputContainer'>
                                                        <CheckRadio
                                                            id={'reseller'}
                                                            name="reseller"
                                                            text={translate("formLabel.reseller")}
                                                            checked={reselled}
                                                            onChange={(val) => { setFieldValue('enable_uc_type', false, false); }}
                                                            onClick={()=>{handleEnableUCT(false)}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    (values.country === COUNTRY.INDIA) &&
                                    <div className="col-md-6 px-3 mb-0 form-group">
                                        <TextInput type="text" name={'gstin'} label={'formLabel.gstin'} maxLength="255" />
                                    </div>
                                }
                                {
                                    hasDupError &&
                                    <div className="col-12 mt-3">
                                        <label className="errorMessage">{translate('error.duplicatedAddress')}</label>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            saveAtAddressBook ?
                                <div className={`toolbar ${!isAdd ? 'd-flex flex-column flex-xl-row justify-content-between' : ''}`}>
                                    {
                                        !isAdd &&
                                        <Link onClick={onDelete} >
                                            <span className="m-0">
                                                <POWRIcon className="fas fa-trash-alt" size=".7rem" color={COLOR.CISCO_BLUE} />
                                            </span>
                                            {translate('button.remove')}
                                        </Link>
                                    }
                                    <div className="d-flex pt-3 pt-xl-0">
                                        <POWRButton type="button" color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                                        <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.save')}</POWRButton>
                                    </div>
                                </div>
                                :
                                <Fragment>
                                    <div className="custom-toolbar">
                                        <div className="toolbar-content pt-2">
                                            <POWRButton type="button" color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                                            {validateSAT && (data !== undefined && (values.country === COUNTRY.MEXICO && initialValues.name != values.name)) ?
                                                <POWRButton type="button" disabled={isSubmitting} onClick={(e) => customSubmit(e, () => setShowSaveModal(true), validateForm, values, setTouched, setErrors)}>{translate('button.apply')}</POWRButton>
                                                :
                                                <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.apply')}</POWRButton>
                                            }
                                            {!isRTF && <POWRButton type="button" onClick={() => handleSaveAs(true)}>{translate('button.changePickupLocationaddress')}</POWRButton>}
                                            <POWRModal
                                                show={showSaveAs} size="wide"
                                                title={translate('button.changePickupLocationaddress')}
                                                onClose={() => handleSaveAs(false)}
                                            >
                                                <div className="body">
                                                    <TextInput isRequired type="text" name={'save_as'} label={'formLabel.addressBookName'} maxLength="255" />
                                                </div>
                                                <div className="custom-toolbar">
                                                    <label>
                                                        <span className="mr-1">
                                                            <POWRIcon className={'fas fa-info-circle'} size="1rem" color={COLOR.CISCO_BLUE} />
                                                        </span>
                                                        {translate('message.saveAsModal')}
                                                    </label>
                                                    <div className="toolbar-content pt-2">
                                                        <POWRButton type="button" color={'secondary'} onClick={() => handleSaveAs(false)}>{translate('button.cancel')}</POWRButton>
                                                        <POWRButton type="button" disabled={isSubmitting} onClick={() => handleRefSubmit()}>{translate('button.save')}</POWRButton>
                                                    </div>
                                                </div>
                                            </POWRModal>
                                        </div>
                                    </div>
                                </Fragment>
                        }
                        <POWRModal
                            show={showSaveModal} size="wide"
                            title={translate('subtitle.editPickupLocation')}
                            onClose={() => setShowSaveModal(false)}
                        >
                            <div className="content">
                                <div className="x-centered mt-3 mb-4">
                                    <POWRIcon
                                        className="fas fa-exclamation-triangle"
                                        size="3.5em"
                                        color={COLOR.YELLOW} />
                                </div>
                                <p className="d-block my-2 text-center">{translate('message.confirmSaveAddress')}</p>
                            </div>
                            <div className="custom-toolbar">
                                <div className="toolbar-content pt-2">
                                    <POWRButton type="button" color={'secondary'} onClick={() => setShowSaveModal(false)}>{translate('button.cancel')}</POWRButton>
                                    <POWRButton type="button" disabled={isSubmitting} onClick={() => save(values, { setSubmitting })}>{translate('button.ok')}</POWRButton>
                                </div>
                            </div>
                        </POWRModal>
                    </Form>
                )
            }
        </Formik>
    );
};