import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PickUpLocation, SelectParts, CreatePackages, SchedulePickup, ReturnSummary, SuccessReturn, ResumePickup } from '../components';
import { Link } from '../components/controls';
import { POWRIcon } from '../components/styledComponents';
import { IndiaRMAModal, LabelOnlyModal } from '../components/modals';
import { getRMASubLines, getValidAvailableDCRMA } from '../services/rmaService';
import { getRMA, gtagService, saveRMA } from "../services";
import {
    selectBaseRMAInfo, selectIsFA, selectLabelOnly, selectReturnFlowCurrentStep, selectReturnFlowCurrentSteps,
    selectRMAsNum, selectShipmentId, selectTotalOrderQty, selectUserInfo, selectUpdateRMA, selectRMASavedID
} from '../core/redux/selectors';
import { saveShipmentData, setReturnFlowDefaultOptions, setReturnFlowProp, setRMAInfo, setRMAs, resetReturnFlow } from '../core/redux/actions';
import { useTranslate } from '../core/contexts';
import { CONFIG_PARAMS, STORAGE, COUNTRY } from '../core/constants';
import { TOTAL_QTY_ORDERED } from '../core/constants/Common';
import { DOCUMENTATION, RETURN } from '../core/constants/ROUTES';
import { useAlert, useConfigParams, useCreatePackages, useRMAValidation, useSelectPartsValidations } from '../core/hooks';
import { BASE_URL, RMA_LOGIN } from '../core/constants/URLS';
import { COLOR } from '../core/constants/Colors';

const Return = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const { isValidRMADashboard2 } = useRMAValidation();
    const { hasRemovedSublines } = useSelectPartsValidations();
    const { handleSubmit } = useCreatePackages();
    const { getAssetRecoveryLink } = useConfigParams();
    
    // const {handleSearch} = useLoadInfoPage();
    
    const [paramsSaved, setParamsSaved] = useState({});
    const [showCustomInfo, setShowCustomInfo] = useState(false);
    const [showLabelOnlyModal, setShowLabelOnlyModal] = useState(false);
    const [showIndiaModal, setShowIndiaModal] = useState(false);
    const [maxStep, setMaxStep] = useState(0);
    const [DCGenerated, setDCGenerated] = useState(false);
    const [resumeRMA, setResumeRMA] = useState(false);
    const [savedRMA, setSavedRMA] = useState(null);

    const steps = useSelector(selectReturnFlowCurrentSteps);
    const currentStep = useSelector(selectReturnFlowCurrentStep);
    const shipmentId = useSelector(selectShipmentId);
    const rmaNums = useSelector(selectRMAsNum);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const userInfo = useSelector(selectUserInfo);
    const qty = useSelector(selectTotalOrderQty);
    const isFA = useSelector(selectIsFA);
    const labelOnly = useSelector(selectLabelOnly);
    const updateRMA = useSelector(selectUpdateRMA)
    const RMASavedID = useSelector(selectRMASavedID)

    useEffect(() => {
        validateDCGenerated();
        gtagService.event('ReturnFlow', 'InitRequestReturn', labelOnly ? 'LabelOnly' : 'RequirePickup')
    }, []);



    useEffect(() => {
        if (currentStep > maxStep) setMaxStep(currentStep);
    }, [currentStep, maxStep]);

    useEffect(() => {
        if (userInfo.rmaLogin && userInfo.rma) {
            showAlert({
                id: 'goToCiscoAccound',
                page: '*',
                message: <Fragment>
                    {translate('message.toAccessFullFuncionalities')}
                    <Link href={BASE_URL + RMA_LOGIN}>{translate('aux.clickHere')}</Link> {translate('aux.toFindIt')}
                </Fragment>
            });
            handleSearch(userInfo.rma);

       
        }
    }, [userInfo]);

    useEffect(() => {
        getRMAData();
    }, [dispatch]);

    useEffect(() => {
        if (updateRMA) {
            let rma = rmaNums.toString();
            handleSearch(rma, true);
            dispatch(setReturnFlowProp('updateRMA', false));
        }
    }, [updateRMA]);

    useEffect(() => {
        let newSteps = steps;
        newSteps[3].text = `title.${labelOnly ? 'selectCarrier' : 'schedulePickup'}`;
        dispatch(setReturnFlowProp('steps', newSteps));
    }, [labelOnly, dispatch]);

    const getRMAData = () => {
        let rss = JSON.parse(sessionStorage.getItem(STORAGE.SEARCH_NAME));
        let rssSave = JSON.parse(sessionStorage.getItem(STORAGE.SAVE_NAME))
        let rssci = JSON.parse(sessionStorage.getItem(STORAGE.CONTACT_INFO));
        let labelOnly = JSON.parse(sessionStorage.getItem(STORAGE.LABEL_ONLY));
        let ssConfigParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
        let config_params = ssConfigParams ? JSON.parse(ssConfigParams.replace(/\s+/g, "")) : null;
        let resumePickupInfo = JSON.parse(sessionStorage.getItem(STORAGE.RESUME_PICKUP));
        let resumeShipmentInfo = JSON.parse(sessionStorage.getItem(STORAGE.RESUME_SHIPMENT));
        let DHL_ELP_enabled = false;
        let turkeyRevenue = false;

        if (config_params && config_params.dtos) {
            let DHL_enabled = config_params.dtos.find(x => x.name === CONFIG_PARAMS.DHL_ELP);
            if (DHL_enabled.value === "true") {
                DHL_ELP_enabled = true;
            }

            let E_dispatch = config_params.dtos.find(x => x.name === CONFIG_PARAMS.E_DISPATCH);
            if (E_dispatch.value === "true") {
                turkeyRevenue = true;
            }
        }

        let rmaLogin = userInfo.rmaLogin;
        if (rmaLogin) {
            if (RMASavedID && RMASavedID.rma.toString() == userInfo.rma) {
                setSavedRMA(RMASavedID);
                setResumeRMA(true);
            } else dispatch(resetReturnFlow());
        }
        if (rss && rss.length && (rssci || rmaLogin) && rssSave && rssSave.length) {
            dispatch(setRMAInfo(rss, rssSave[0].idd, labelOnly, DHL_ELP_enabled, turkeyRevenue, resumePickupInfo ? resumePickupInfo.instruct.is_fact_return : rssSave[0].isFA));
            let contacts = { ...rssci, pickup: rssci.pickup.map((x => ({ ...x, isCS: x.country === 'CS' || x.country === 'RS' || x.country === 'ME' || x.country === 'XK', country: x.country === 'CS' ? '' : x.country }))) };
            dispatch(setReturnFlowDefaultOptions(contacts));
        }

        if (resumePickupInfo) dispatch(setReturnFlowProp('schedulePickup', resumePickupInfo));
        if (resumeShipmentInfo) dispatch(saveShipmentData(resumeShipmentInfo.idd, resumeShipmentInfo.directions, resumeShipmentInfo.weight, resumeShipmentInfo.dimension, ''));;
    }

    useEffect(() => { // VALIDATE RETURN STEPS AVAILABILITY
        if (rmaNums.length && shipmentId) {
            let newSteps = steps; // steps initial value from constant
            let hasMultipleSublines = false;
            const rmasFA = [];
            
            Promise.all(rmaNums.map((rma) =>
           
                getRMASubLines({
                    sort: 'sub_line',
                    dir: 'ASC',
                    ship_id: shipmentId,
                    rma: rma
                }).then((response) => {
                  
                    if (!response.res.success) return;
                    const sublines = response.res.dtos;
                    if (sublines.length > 1) hasMultipleSublines = true;
                    if (!rmasFA.find(x => x === rma) && sublines.find(x => x.cust_requested_fa === 'Y')) rmasFA.push(rma);                
                    return;
                })
            )).then(() => {
                configureSteps(newSteps, hasMultipleSublines);

                if (!isFA && rmasFA.length) {
                    if (rmasFA.length)
                        showAlert({
                            id: 'rmasFA',
                            page: RETURN,
                            message: `${translate('message.rmasWithFA')} (${rmasFA.join(', ')})`
                        });

                  
                }
            }).catch(() => {
                newSteps = newSteps.map(x => ({ ...x, disabled: true }));
                dispatch(setReturnFlowProp('steps', newSteps));
            });          
        }
    }, [rmaNums, shipmentId, rmaInfo, isFA, translate, dispatch]);

    const configureSteps = (newSteps, hasMultipleSublines) => {
        // if (!(rmaInfo && rmaInfo.returnAddress)) newSteps = newSteps.map(x => ({ ...x, disabled: true }));
        // else {
            newSteps[0].disabled = false;
            newSteps[1].disabled = !hasMultipleSublines;
            newSteps[2].disabled = !hasMultipleSublines;
            newSteps[3].disabled = false;
            newSteps[4].disabled = false;
        // }
        dispatch(setReturnFlowProp('steps', newSteps));
    };

    useEffect(() => {
        if (rmaNums.length && qty) {
            let total_ordered = 0;
            qty.forEach(item => {
                total_ordered += item
            });
            //remind if shipment has more than 1000 products to send
            if (total_ordered > TOTAL_QTY_ORDERED) {
                const message = `${translate('title.rma')} ${rmaNums.join(', ')} ${translate('message.has')} ${total_ordered} ${translate('message.parts')}. ${translate('message.bigShipmentValidation')}`;
                showAlert({ id: '1000sb', page: RETURN, message });
            }
        }
    }, [qty, rmaNums, translate]);

    const currentStepInfo = useMemo(() => {
        setShowCustomInfo(false);
        if (steps[currentStep].customDescLink === 'getAssetRecoveryLink') {
            return { ...steps[currentStep], customDescLink: getAssetRecoveryLink() }
        } else return steps[currentStep];
    }, [steps, currentStep]);

    const clearStorage = () => {
        sessionStorage.removeItem(STORAGE.SEARCH_NAME)
        sessionStorage.removeItem(STORAGE.SAVE_NAME)
        sessionStorage.removeItem(STORAGE.CONTACT_INFO)
        sessionStorage.removeItem(STORAGE.LABEL_ONLY)
        sessionStorage.removeItem(STORAGE.RESUME_PICKUP)
        sessionStorage.removeItem(STORAGE.RESUME_SHIPMENT)
        setShowLabelOnlyModal(false)
    }

    // ------------------------------------------------------
    // THE NEXT FUNCTIONS ARE ONLY USED WHIT THE EMAIL LOGIN 
    // ------------------------------------------------------

    const validateDCGenerated = () => {
        let ssConfigParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
        let config_params = ssConfigParams ? JSON.parse(ssConfigParams.replace(/\s+/g, "")) : null;
        if (config_params && config_params.dtos) {
            let DC_Generated = config_params.dtos.find(x => x.name === CONFIG_PARAMS.DC_GENERATED);
            setDCGenerated(DC_Generated.value === "true" ? true : false);
        }
    }

    const handleSearch = (rma, update) => {  
        const parameters = {
            param_rma_or_tradein: !isFA ? rma : '',
            param_fa_case_num: !isFA ? "" : rmaInfo.faCaseNum?rmaInfo.faCaseNum:"",
            isSimpleSearch: true,
            isReturnFa: isFA
        };
        searchRMA(parameters, update, rma);
    };

    const searchRMA = (parameters, update, rma) => {   
        getRMA(parameters).then(response => {
            let faRMAS = response.res.dtos.map(x => x.rma_num[0]).join(',');

            //Validate all the possibles stoppers after start the return flow with the current RMAs
            
            if (isValidRMADashboard2(response,isFA,userInfo.rmaLogin).isValid) {
                let dtos = response.res.dtos;
                let country = dtos[0].country[0];
                const rmas = update ? rma.split(',') : isFA ? faRMAS.split(',') : userInfo.rma.split(',');

                if (dtos.length > 0) {
                    const paramsSave = {
                        ship_id: 0,
                        rmas: rmas.length > 1 ? rmas.sort((a, b) => a > b) : rmas[0],
                        simple: true
                    };

                    sessionStorage.setItem(STORAGE.SEARCH_NAME, JSON.stringify(dtos));
                    if (update) {
                        dispatch(setRMAs(dtos));
                        return;
                    }
                    if (country === COUNTRY.INDIA && DCGenerated) {
                        validateDeliveryChallan(paramsSave);
                    } else if (!isFA && (country === COUNTRY.US || country === COUNTRY.CANADA)) {
                        setParamsSaved(paramsSave);
                        setShowLabelOnlyModal(!showLabelOnlyModal);
                    } else {
                        handleSaveRMA(paramsSave);
                    }
                }
                else {
                    clearStorage();
                }
            }
        }).catch((e) => {
            clearStorage();
        });
    }

    const validateDeliveryChallan = (paramsSave) => {
        getValidAvailableDCRMA({ rmas: paramsSave.rmas }).then(response => {
            let data_response = response.res.dtos;

            if (data_response.length === 0) {
                setParamsSaved(paramsSave);
                setShowIndiaModal(!showIndiaModal);
            } else {
                if (typeof paramsSave.rmas === "object") {
                    paramsSave.rmas.map((rmaSave) => {
                        if ((data_response.filter(x => x.rma_num === rmaSave && x.dc_generated))) {
                            handleSaveRMA(paramsSave);
                        } else {
                            setParamsSaved(paramsSave);
                            setShowIndiaModal(!showIndiaModal);
                        }
                    });
                } else {
                    if (data_response && data_response[0] && data_response[0].dc_generated) {
                        handleSaveRMA(paramsSave);
                    } else {
                        setParamsSaved(paramsSave);
                        setShowIndiaModal(!showIndiaModal);
                    }
                }
            }
        });
    }

    const handleSaveRMA = (params) => {
        saveRMA(params).then(response => {
            if (response.res.success) {
                let dtosSave = response.res.dtos;
                sessionStorage.setItem(STORAGE.SAVE_NAME, JSON.stringify(dtosSave.map(x => ({ ...x, isFA }))));
                getRMAData();
            } else {
                clearStorage();
            }
        }).catch(() => {
            clearStorage();
        });
    }

    const createDocumentationIndia = () => {
        history.push({
            pathname: DOCUMENTATION,
            state: { rma: paramsSaved.rmas }
        })
    }

    const noCreateDocumentationIndia = () => {
        handleSaveRMA(paramsSaved);
        setShowIndiaModal(false)
    }

    const handleLabelOnly = (labelOnly) => {
        sessionStorage.setItem(STORAGE.LABEL_ONLY, JSON.stringify(labelOnly));
        handleSaveRMA(paramsSaved);
    };

    const handleNavigation = (stepInfo, target) => {
        if (!stepInfo.disabled && target <= maxStep) {
            if (currentStep === 1 && target > currentStep && hasRemovedSublines()) return;
            else if (currentStep === 2 && target > currentStep)
                handleSubmit(() => dispatch(setReturnFlowProp('currentStep', target)));
            else dispatch(setReturnFlowProp('currentStep', target));
        }
    };

    return (
        <div className="requestReturnPageContainer">
            {currentStep < 5 &&
                <div className="flowRow p-3 p-lg-0">
                    {steps.map((option, i) => i < 5 && (
                        <Fragment key={`flowOption-${i + 1}-${currentStep}`}>
                            <div className={`flowOptionsContainer ${(option.disabled && i !== currentStep) ? 'disabled' : ''}`}
                                onClick={() => handleNavigation(option, i)}>
                                {/* <div className="y-centered m-0 px-2"  > */}
                                <span>
                                    <POWRIcon className={option.icon} size="25px" color={currentStep >= i && !option.disabled ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                </span>
                                {/* <div className="d-none d-lg-flex col-lg-9 pr-0 y-centered"> */}
                                <h4
                                    className="flowOption d-none d-xl-inline-block y-centered"
                                    style={{ color: (currentStep > i && !option.disabled) || currentStep === i ? COLOR.CISCO_BLUE : COLOR.GREY }}>
                                    {translate(option.text)}
                                </h4>
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                            {
                                steps.length - 1 > i + 1 &&
                                <span className="pl-1 pr-2">
                                    <POWRIcon
                                        className="fas fa-chevron-right ml-2 ml-lg-0"
                                        size="1rem"
                                        color={currentStep >= i && !option.disabled ? COLOR.CISCO_BLUE : COLOR.GREY} />
                                </span>
                            }
                        </Fragment>
                    ))}
                </div>
            }
            <div className="container-request-return">
                {currentStepInfo.text &&
                    <h1 className="mb-0">
                        {translate(currentStepInfo.text)}
                        {currentStepInfo.customDesc ?
                            <Fragment>
                                <span className="ml-3" onClick={() => setShowCustomInfo(!showCustomInfo)}>
                                    <POWRIcon className={'fas fa-info-circle'} size="1rem" color={COLOR.CISCO_BLUE} />
                                    <Link className={`ml-2 ${!showCustomInfo ? 'd-none' : ''}`}>
                                        <POWRIcon className={`fas fa-caret-down`} size="1rem" color={COLOR.CISCO_BLUE} />
                                    </Link>
                                    <Link className={`ml-2 ${showCustomInfo ? 'd-none' : ''}`}>
                                        <POWRIcon className={`fas fa-caret-right`} size="1rem" color={COLOR.CISCO_BLUE} />
                                    </Link>
                                </span>
                            </Fragment>
                            : null}
                    </h1>
                }
                {(!currentStepInfo.customDesc && currentStepInfo.desc) &&
                    <label className="mb-16">{translate(currentStepInfo.desc)}</label>
                }
                {
                    showCustomInfo && <label className="py-3">
                        {translate(currentStepInfo.customDesc || '')}
                        <Link
                            className="ml-1"
                            target="_blank"
                            href={currentStepInfo.customDescLink} >
                            {currentStepInfo.customDescLinkText ? translate(currentStepInfo.customDescLinkText) : ''}
                        </Link>
                    </label>
                }
                {
                    (currentStep === 0 && <PickUpLocation />)
                    || (currentStep === 1 && <SelectParts />)
                    || (currentStep === 2 && <CreatePackages />)
                    || (currentStep === 3 && <SchedulePickup />)
                    || (currentStep === 4 && <ReturnSummary />)
                    || <SuccessReturn />
                }
                {userInfo.rmaLogin && currentStep === 0 && resumeRMA && savedRMA && savedRMA.saved && savedRMA.rma &&
                    <div className="d-none">
                        <ResumePickup
                            idd={parseInt(savedRMA.saved)}
                            rmas={savedRMA.rma}
                            resume RMALogin
                        />
                    </div>
                }
            </div>
            <LabelOnlyModal
                show={showLabelOnlyModal}
                onClose={() => handleLabelOnly(false)}
                onContinue={(label) => handleLabelOnly(label)}
            />
            <IndiaRMAModal
                proceedWODocument={() => noCreateDocumentationIndia()}
                createDocument={() => createDocumentationIndia()}
                show={showIndiaModal}
                close={() => noCreateDocumentationIndia()}
            />
        </div>
    )
}

export default Return;