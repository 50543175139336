import { createSelector } from 'reselect';
import { selectRMAsNum } from './rma.selectors';

const selectReturnFlow = state => {
    return state.returnFlow;
}

export const selectReturnFlowCurrentSteps = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.steps
);

export const selectReturnFlowCurrentStep = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.currentStep
);

export const selectReturnFlowScheduleStep = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.scheduleStep
);

// saved
export const selectSchedulePickup = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.schedulePickup
);

export const selectReturnFlowSavedInfo = createSelector(
    [selectReturnFlow],
    (returnFlow) => ({
        shipmentId: returnFlow.shipmentId,
        additional: returnFlow.additional,
        pickupId: returnFlow.pickupId,
        length_uom: returnFlow.length_uom,
        weight_uom: returnFlow.weight_uom,
        attachedFiles: returnFlow.attachedFiles,
        radio: returnFlow.radio
    })
);

export const selectReturnFlowOptions = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.userAddressBook
);

export const selectReturnFlowSelections = createSelector(
    [selectReturnFlow],
    (returnFlow) => ({
        pickupId: returnFlow.pickupId,
        requestorId: returnFlow.requestorId,
        contactId: returnFlow.pickupContactId,
        alternateContactId: returnFlow.alternateContactId
    })
);

export const selectSelectionsInfo = createSelector(
    [selectReturnFlow],
    (returnFlow) => {
        let options = returnFlow.userAddressBook;
        let selections = {
            pickupId: returnFlow.pickupId,
            requestorId: returnFlow.requestorId,
            contactId: returnFlow.pickupContactId,
            alternateContactId: returnFlow.alternateContactId,
            shipId: returnFlow.shipmentId,
            additional: returnFlow.additional
        };
        let sls = {};
        if (options && selections) {
            if (options.pickup && selections.pickupId != null)
                sls['pickup'] = options.pickup.find(x => x.idd === selections.pickupId);
            if (options.requestor && selections.requestorId != null)
                sls['requestor'] = options.requestor.find(x => x.idd === selections.requestorId);
            if (options.pickupContact && selections.contactId != null)
                sls['contact'] = options.pickupContact.find(x => x.idd === selections.contactId);
            if (options.alternateContact && selections.alternateContactId != null)
                sls['alternateContact'] = options.alternateContact.find(x => x.idd === selections.alternateContactId);
            if (selections.shipId != null)
                sls['shipmentId'] = selections.shipId;
            if (selections.additional != null)
                sls['additional'] = selections.additional;
            if (options.billingAddress != null)
                sls['billingAddress'] = options.billingAddress;
        }
        return sls;
    }
);

export const selectReturnFlowAttachFiles = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.attachedFiles
);

export const selectSublines = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.subLines.sort((a, b) => a.rma_num > b.rma_num || a.rma_line > b.rma_line)
);

export const selectPartsPackages = createSelector(
    [selectRMAsNum, selectSublines],
    (rmaNums, sublines) => rmaNums.map((rma_num) => {
        let lines = [];
        let rma_lines = []
        sublines.forEach(el => {
            rma_lines.push(el.rma_line)
            const qty = sublines.filter(x => x.rma_num === rma_num && x.rma_line === el.rma_line).length;
            lines.push({
                ...el,
                picked_qty: el.picked_qty || qty,
                available_qty: qty
            })
        });

        rma_lines = Array.from(new Set(rma_lines));
        let unicLines = rma_lines.map(x => (lines.filter(y => y.rma_line === x)[0]));

        return {
            rma: rma_num,
            allPackages: lines,
            packages: unicLines
        };
    })
);

export const selectSelectedLines = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.selectedLines
);

export const selectGroupedLines = createSelector(
    [selectSublines],
    (sublines) => {
        let rmasWithLines = [];

        for (let subline of sublines) {
            const sublinesOfthisLine = sublines.filter(x => x.rma_num === subline.rma_num && x.rma_line === subline.rma_line);

            if (!rmasWithLines.find(x => x.rma === subline.rma_num)) {
                rmasWithLines.push({
                    rma: subline.rma_num,
                    lines: [{
                        rma: subline.rma_num,
                        line: subline.rma_line,
                        part: subline.part_num,
                        available: sublinesOfthisLine.length,
                        qtyPicked: sublinesOfthisLine.filter(x => x.picked).length
                    }]
                });
            }
            else {
                rmasWithLines = rmasWithLines.map((x) => x.rma === subline.rma_num ? {
                    ...x,
                    rma: subline.rma_num,
                    lines: [
                        ...x.lines,
                        ...(x.lines.find(y => y.line === subline.rma_line) ? [] : [{
                            rma: subline.rma_num,
                            line: subline.rma_line,
                            part: subline.part_num,
                            available: sublinesOfthisLine.length,
                            qtyPicked: sublinesOfthisLine.filter(x => x.picked).length
                        }])
                    ]
                } : x);
            }
        }

        return rmasWithLines;
    }
);

export const selectCreatePackagesStep = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.createPackagesStep
);

export const selectPickedSublinesOfSelectedLines = createSelector(
    [selectSelectedLines, selectSublines],
    (selectedLines, allSublines) => {
        let selectedSublines = []
        selectedSublines = allSublines.filter(x => x.picked && selectedLines.findIndex(sl => sl.rma === x.rma_num && sl.line === x.rma_line) >= 0);
        return selectedSublines;
    }
);

export const selectCreatedLocalPackagesByLine = createSelector(
    [selectSublines],
    (sublines) => {
        const packedSublines = sublines.filter(x => !!x.package_id);
        let localPackages = [];
        packedSublines.map(psub => {
            const exists = localPackages.find(lp => lp.line === psub.rma_line && lp.pack === psub.pack_num);
            if (exists) localPackages = localPackages.map((lp) =>
                lp.line === psub.rma_line && lp.pack === psub.pack_num ? { ...lp, qty: lp.qty + 1 } : lp);
            else localPackages.push({
                line: psub.rma_line,
                part_num: `${psub.part_num} ${psub.rma_line}`,
                pack: psub.pack_num,
                qty: 1,
                content: 'box'
            });
        });
        return localPackages;
    }
);

export const selectSelectedSublines = createSelector(
    [selectReturnFlow],
    ({ selectedSubLines }) => selectedSubLines
);

export const selectPackages = createSelector(
    selectReturnFlow,
    ({ packages }) => packages.sort((a, b) => a.pack_name > b.pack_name)
);

export const selectOldPackages = createSelector(
    [selectReturnFlow],
    ({ oldPackages }) => oldPackages.sort((a, b) => a.pack_name > b.pack_name)
);

export const selectGroupedParts = createSelector(
    [selectPickedSublinesOfSelectedLines, selectPackages],
    (selectedSublines, packages) => {
        let rmasWithLineParts = [];

        let packagesByRMA = {};
        for (let pk of packages) {
            const rmaPks = packagesByRMA[pk.rma_num];
            packagesByRMA[pk.rma_num] = rmaPks ? [...rmaPks, pk] : [pk];
        }

        for (let subline of selectedSublines) {
            if (subline.package_id) {
                const sublinePackage = packagesByRMA[subline.rma_num].find(pk => pk.idd === subline.package_id);
                // const packNum = sublinePackage ? parseInt(sublinePackage.pack_code.split('_')[1]) : '0-0';
                // subline['pack_num'] = `${rmaNums.findIndex(x => x === subline.rma_num) + 1}-${packNum}`
                subline['pack_num'] = sublinePackage.pack_name;
            }
            else delete subline['pack_num'];
            if (!rmasWithLineParts.find(x => x.rma === subline.rma_num)) rmasWithLineParts.push({
                rma: subline.rma_num,
                lines: [{
                    rma: subline.rma_num,
                    line: subline.rma_line,
                    part: subline.part_num,
                    collapsed: subline.collapsed,
                    parts: [subline]
                }]
            })
            else {
                rmasWithLineParts = rmasWithLineParts.map((x) => {
                    if (x.rma === subline.rma_num) {
                        if (x.lines.find(y => y.line === subline.rma_line))
                            return {
                                ...x,
                                rma: subline.rma_num,
                                lines: [...x.lines.map(z => z.line === subline.rma_line ? { ...z, parts: [...z.parts, subline] } : z)]
                            };
                        else {
                            return {
                                ...x,
                                rma: subline.rma_num,
                                lines: [
                                    ...x.lines,
                                    {
                                        rma: subline.rma_num,
                                        line: subline.rma_line,
                                        collapsed: subline.collapsed,
                                        part: subline.part_num,
                                        parts: [subline]
                                    }
                                ]
                            }
                        }
                    }
                    else return x;
                });
            }
        }
        return rmasWithLineParts;
    }
);

export const selectRMAsPackages = createSelector(
    [selectRMAsNum, selectPackages],
    (rmaNums, packages) => packages.length ? rmaNums.map((rma) => ({
        rma,
        packages: packages ? packages.filter(x => x.rma_num === rma).map(x => ({
            ...x,
            pack_num: x.pack_name
        })) : []
    })) : []
);

export const selectArePackagesMofied = createSelector(
    [selectOldPackages, selectPackages],
    (old, current) => {
        for (let i = 0; i < current.length; i++) {
            const cp = current[i], op = old[i];
            if (parseFloat(cp.weight).toFixed(2) !== parseFloat(op.weight).toFixed(2)
                || parseFloat(cp.depth).toFixed(2) !== parseFloat(op.depth).toFixed(2)
                || parseFloat(cp.width).toFixed(2) !== parseFloat(op.width).toFixed(2)
                || parseFloat(cp.height).toFixed(2) !== parseFloat(op.height).toFixed(2)) {
                return true;
            }
        };
        return false;
    }
);

export const selectPackagesWithSublines = createSelector(
    [selectSublines, selectPackages],
    (sublines, packages) => (sublines.length && packages.length) ? packages.map((pk) => {
        const packageSublines = sublines.filter((x) => x.package_id === pk.idd);
        return {
            id: pk.idd,
            rma_num: pk.rma_num,
            head: {
                ...pk,
                qty: packageSublines.length,
                rma_num: '',
                weight: `${pk.weight} ${pk.weight_uom === 1 ? 'kg' : pk.weight_uom === 2 ? 'lbs' : 'N/A'}`,
                depth: `${pk.depth} ${(pk.dim_uom === 1 && 'm') || (pk.dim_uom === 2 && 'in') || (pk.dim_uom === 3 && 'cm') || (pk.dim_uom === 4 && 'ft')}`,
                width: `${pk.width} ${(pk.dim_uom === 1 && 'm') || (pk.dim_uom === 2 && 'in') || (pk.dim_uom === 3 && 'cm') || (pk.dim_uom === 4 && 'ft')}`,
                height: `${pk.height} ${(pk.dim_uom === 1 && 'm') || (pk.dim_uom === 2 && 'in') || (pk.dim_uom === 3 && 'cm') || (pk.dim_uom === 4 && 'ft')}`
            },
            lines: packageSublines
        };
    }) : []
);

export const selectSaveRMASForms = createSelector(
    [selectReturnFlow],
    (returnFlow) => returnFlow.saveRMASForms
);

export const selectRMASFormsValid = createSelector(
    [selectReturnFlow],
    (returnFlow) => {
        return {
            pickup: returnFlow.RMASForm1Valid,
            requestor: returnFlow.RMASForm2Valid,
            pickupContact: returnFlow.RMASForm3Valid,
            alternateContact: returnFlow.RMASForm4Valid
        }
    }
);

export const selectShowSublinesPkReset = createSelector(
    [selectReturnFlow],
    ({ showSublinesPkReset }) => showSublinesPkReset
);

export const selectOldSublines = createSelector(
    [selectReturnFlow],
    ({ oldSubLines }) => oldSubLines
);

export const selectOldSelectedLines = createSelector(
    [selectReturnFlow],
    ({ oldSelectedLines }) => oldSelectedLines
);

export const selectCreatePackagesError = createSelector(
    [selectReturnFlow],
    ({ createPackagesError }) => createPackagesError
);

export const selectPickupCountry = createSelector(
    [selectReturnFlow],
    ({ pickupCountry }) => pickupCountry
);

export const selectSpecialInstrucLimit = createSelector(
    [selectReturnFlow],
    ({ specialInstrucLimit }) => specialInstrucLimit
);

export const selectUpdateRMA = createSelector(
    [selectReturnFlow],
    ({ updateRMA }) => updateRMA
);

export const selectZoneInstructions = createSelector(
    [selectReturnFlow],
    ({ zoneInstructions }) => zoneInstructions
);

export const selectRMASavedID = createSelector(
    [selectReturnFlow],
    ({ RMASavedID }) => RMASavedID
);
export const selectZone = createSelector(
    [selectReturnFlow],
    ({ zone }) => zone
);

export const selectLinesError = createSelector(
    [selectReturnFlow],
    ({ linesError }) => linesError
);