import React from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import { useAuth } from '../../core/hooks';

export const LoggingOutModal = props => {
    const { translate } = useTranslate();
    const { logout } = useAuth();

    return (
        <POWRModal
            show={props.show}
            title={translate('title.logout')}
            size={'medium'}
            onClose={() => props.onClose()}>
            <div className="content">
                <div className="x-centered mt-3 mb-4">
                    <POWRIcon
                        className="fas fa-exclamation-triangle mb-3"
                        size="3.5em"
                        color={COLOR.CISCO_BLUE} />
                </div>
                <div className="text-center">
                    <h3>{translate("subtitle.loggingOut")}</h3>
                    <p>{translate("message.sure")}</p>
                </div>
            </div>
            <div className="toolbar">
                <POWRButton color={'secondary'} onClick={() => props.onClose()}>{translate('button.no')}</POWRButton>
                <POWRButton onClick={logout}>{translate('button.yes')}</POWRButton>
            </div>
        </POWRModal>

    )
}