import Request from "../core/api/request";
import { URL_SHIPTREE } from "../core/constants/URLS";

export const getShipments = (data) => {
    return Request.postEncode({ url: `get/${URL_SHIPTREE}`, data });
};
export const getShiptree = (data) => {
    return Request.postEncode({ url: `get/shiptree`, data });
};

export const getShiptreeByIdReleaseLines = (idd) => {
    return Request.postEncode({ url: `get/shiptree?id=${idd}` });
};

export const getCommercialInvoice = (data) => {
    return Request.postEncode({ url: 'get/commercialInvoice', data });
};

export const getValidateEntities = (data) => {
    return Request.postEncode({ url: 'validateEntitiesServlet', data });
};

export const getFdf = (data) => {
    return Request.postEncode({ url: 'get/fdf', data });
};

export const getPocUkraine = (data) => {
    return Request.postEncode({ url: 'get/PocUkrainian', data });
};

export const getShipUserDocuments = (data) => {
    return Request.postEncode({ url: 'get/ship_user_documents', data });
};

export const saveCommercialInvoice = (data) => {
    return Request.postURIENCODEDJSONSave({ url: 'save/commercialInvoice', data });
};

export const saveFaultDetection = (data) => {
    return Request.postURIENCODEDJSONSave({ url: 'save/fdf', data });
};

export const saveActOfExchange = (data) => {
    return Request.postURIENCODEDJSONSave({ url: 'save/PocUkrainian', data });
};

export const releaselines = (data) => {
    return Request.postURIDECODEJSONSave({ url: 'cmd/shipment/releaselines', data });
};