import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../core/contexts';
import { useHistory } from 'react-router-dom';
import {
    selectSelectionsInfo, selectReturnFlowSavedInfo, selectReturnFlowOptions, selectCategoryCode, selectSaveRMASForms,
    selectShipmentId, selectRMAsNum, selectBaseRMAInfo, selectIsFA, selectUserInfo, selectRMASFormsValid,
    selectLabelOnly, selectSpecialInstrucLimit, selectZoneInstructions, selectCountries, selectRMAsURLParams, selectPickupCountry, selectZone
} from '../../core/redux/selectors';
import { ContactCard } from '../index';
import { saveShipment } from '../../services/shipmentService';
import { clickOutsideReturn, saveShipmentData, setRMAs,loadCountries } from '../../core/redux/actions';
import { ActionButtons, Checkbox, CheckRadio, InfoTooltip, Select } from '../controls';
import { useAlert, useContactValidation, usePickupLocationValidation, useGlobalMessage, useConfigParams, useCountry } from '../../core/hooks';
import { DASHBOARD, DOCUMENTATION, RETURN } from '../../core/constants/ROUTES';
import { Fragment } from 'react';
import { SET_RETURN_ADDRESS } from '../../core/redux/actionTypes';
import { addressService, getRMA, saveFAReturnAddress, warehouseService } from '../../services';
import { COUNTRY, STORAGE, REGEXP, RFC_TAX_REGIME } from '../../core/constants';
import { saveBillingAddress, setReturnFlowProp, updatePickupAddress } from '../../core/redux/actions/returnFlow.actions';
import { IndiaRMAModal } from '../modals';
import { BillingAddressForm } from '../forms/billingAddressForm';
import { POWRModal } from '../styledComponents';


const RMAInfo = ({ step, next }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { langCode, translate } = useTranslate();
    const { showAlert, removeAlerts, removeAlert } = useAlert();
    const { getContactErrors } = useContactValidation();
    const { getPickupErrors, getBillingAddressErrors } = usePickupLocationValidation();
    const { showPageMessage } = useGlobalMessage();
    const { getContactUsLink, getSATServiceEnabledService, getSATServiceEnabledProduct, getSATValidationRequired } = useConfigParams();
    const { getCountry } = useCountry();

    
    
    const selectionsInfo = useSelector(selectSelectionsInfo);
    const savedShipment = useSelector(selectReturnFlowSavedInfo); // **** SAVED PICKUP LOCATION ****
    const shipmentId = useSelector(selectShipmentId);
    const rmaNums = useSelector(selectRMAsNum);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const userInfo = useSelector(selectUserInfo);
    const isFA = useSelector(selectIsFA);
    const RMASForms = useSelector(selectRMASFormsValid);
    const categoryCode = useSelector(selectCategoryCode);
    const isLabelOnly = useSelector(selectLabelOnly);
    const saveRMASForms = useSelector(selectSaveRMASForms);
    const instructionLimit = useSelector(selectSpecialInstrucLimit);
    const addressBook = useSelector(selectReturnFlowOptions);
    const zoneInstructionsR = useSelector(selectZoneInstructions);
    const pickupCountry = useSelector(selectPickupCountry);
    const pickupZone = useSelector(selectZone);

    const [additional, setAdditional] = useState('');
    const [returnAddress, setReturnAddress] = useState(null);
    const [billingAddr, setBillingAddr] = useState(null);
    const [billingAddrValues, setBillingAddrValues] = useState(null);
    const [RMALogin, setRMALogin] = useState(false);
    const [radio, setRadio] = useState(6);
    const [addresses, setAddresses] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [zone, setZone] = useState(0);
    const [zoneInstructions, setZoneInstructions] = useState('');
    const [zoneInstructionsNA, setZoneInstructionsNA] = useState(false);
    const [zoneError, setZoneError] = useState(false);
    const [showIndiaModel, setShowIndiaModel] = useState(false);
    const [savedID, setSavedID] = useState(null);
    const showPaidByUserAlertFlag = false;
    const countryV = getCountry;
    const [showBillingAddressModal, setShowBillingAddressModal] = useState(false);
    const [isBillingAddrAprove, setIsBillingAddrAprove] = useState(false);
    const [ackPaymentError, setAckPaymentError] = useState(false);
    const [allowEditBillingAddress, setAllowEditBillingAddress] = useState(false);
    const [isDubaiPickup, setisDubaiPickup] = useState(false);
    const [globalMessageShown, setGlobalMessageShown] = useState(false);

    const countries = useSelector(selectCountries);
    const isBaAvailable = categoryCode === 'S' ? getSATServiceEnabledService() : getSATServiceEnabledProduct();
    const RFCValidationRequired = getSATValidationRequired();
    
    useEffect(() => {
        dispatch(loadCountries(true));
    }, [])

    useEffect(() => {
        if (selectionsInfo.pickup) {
            let specialInstrucLimit = countries && countries.find(x => (x.code === countryV))
            dispatch(setReturnFlowProp('specialInstrucLimit', specialInstrucLimit && specialInstrucLimit.site_instructions_max_length));
            if (additional.length > (specialInstrucLimit && specialInstrucLimit.site_instructions_max_length)) setAdditional(additional.substr(0, specialInstrucLimit && specialInstrucLimit.site_instructions_max_length));
        }
        setZone(pickupZone !== undefined ? pickupZone : (countryV === "CN" || (countryV === 'AE' && isDubaiPickup)) ? -1 : 0);
        
    }, [countries, countryV, dispatch, isDubaiPickup]);

    useEffect(() => {
        if (userInfo.rmaLogin) setRMALogin(true);
    }, [userInfo]);

    useEffect(() => {
        if (isBillingAddrAprove) setAckPaymentError(false);
    }, [isBillingAddrAprove]);
    const contactUsLink = getContactUsLink();
    useEffect(() => {
        if (rmaInfo && contactUsLink) {
            if (showPaidByUserAlert(rmaInfo.paymentType[0], savedShipment.shipmentId)) {
                showAlert({
                    id: 'paymentCustomer',
                    message: <Fragment>{translate('message.customerCarrierAccount1')}
                        <a href={contactUsLink} className="link" target="_blank" rel="noopener noreferrer">{translate('title.contact')}</a>
                        {translate('message.customerCarrierAccount2')
                        }</Fragment>,
                    color: 'yellow',
                    page: RETURN
                });
            }
            if (!globalMessageShown) {
                let params = {
                    module: 'GCT_Step2',
                    country: rmaInfo.country,
                    rmaCategory: categoryCode
                }
                
                showPageMessage(params, '/return');
                setGlobalMessageShown(true);
            }
          
            localStorage.removeItem('scmParams')
            
        }

        setZone(pickupZone !== undefined ? pickupZone : (countryV === "CN" || (countryV === 'AE' && isDubaiPickup)) ? -1 : 0);
    }, [rmaInfo, contactUsLink]);


    useEffect(() => {
        if (savedShipment && savedShipment.shipmentId) {
            if (savedShipment.additional) setAdditional(savedShipment.additional);
            if (savedShipment.radio) setRadio(savedShipment.radio);
        }
    }, [savedShipment]);

    useEffect(() => {
        if (rmaInfo) {
            if (Number(rmaInfo.status) !== 1)
                showAlert({
                    id: "wrongRMAStatus",
                    message: translate('message.wrongRMAStatus'),
                    page: RETURN
                });
        }
    }, [rmaInfo, dispatch, translate]);

    useEffect(() => {
        if (rmaInfo && rmaInfo.returnAddress) {
            const ad = rmaInfo.returnAddress;
            // saveFAReturnAddress({ rma_num: rmaInfo.faCaseNum, acode: ad.acode });
            let info = [];
            for (let i = 0; i <= 4; i++) {
                if (ad[`addr${i + 1}`]) info.push(ad[`addr${i + 1}`]);
            }
            setReturnAddress({
                title: translate('title.returnAddress'),
                info: [
                    (ad ? (ad.atag || ad.name) : ''),
                    ...info,
                    (ad ? `${ad.city ? ad.city + ', ' : ''}${ad.province ? ad.province + ', ' : ''}${ad.postal ? (ad.postal + ' ') : ''}${ad.country || ''}` : '')
                ]
            });
        }
        else if (rmaInfo) setReturnAddress(null);
        if (userInfo.addressClassifyEnabled && rmaInfo && rmaInfo.address_classify) {
            setRadio(getAddressClassify(rmaInfo.address_classify, true));
        }
    }, [rmaInfo, translate]);


    const setBillinginfo = (info) =>{
        setBillingAddrValues(info);
        let errors = getBillingAddressErrors(info);
        let cfdiValue = RFC_TAX_REGIME.find(a => a.code == info.taxRegimen);
        setBillingAddr({
            title: translate('title.billingAddressTitle'),
            info: [
                `RFC: ${info.rfc || ''}`,
                `${translate('formLabel.zip')}: ${info.postalCode || ''}`,
                `${translate('formLabel.companyName')}: ${info.fullCompanyName || ''}`,
                `${translate('formLabel.street')}: ${info.streetAddress || ''}`,
                `${translate('formLabel.externalNumber')}:${info.externalNumber || ''}`,
                `${translate('formLabel.internalNumber')}:${info.internalNumber || ''}`,
                `${translate('formLabel.neighborhood')}:${info.neighborhood || ''}`,
                `${translate('gridColumns.city')}: ${info.city || ''}`,
                `${translate('formLabel.state')}: ${info.state || ''}`
            ],
            baErrors: (errors && errors.length > 0 && allowEditBillingAddress && RFCValidationRequired) ? [`${translate('Please Complete SAT Information')}`] : []
        })
    }

    useEffect(() => {
        if ((!RMALogin) && (!selectionsInfo.billingAddress?.fullCompanyName || selectionsInfo?.pickup?.name !== selectionsInfo.billingAddress?.fullCompanyName)) {
            setBillinginfo({});
            dispatch(saveBillingAddress({}))
            setAllowEditBillingAddress(true)
        }
    }, [selectionsInfo?.pickup?.name]);

    useEffect(() => {
        if (selectionsInfo.billingAddress)setBillinginfo(selectionsInfo.billingAddress)
    }, [selectionsInfo.billingAddress]);
    //RFC Info
    useEffect(() => {
        if (isBaAvailable )
            if (!RMALogin && countryV == COUNTRY.MEXICO || (RMALogin && (pickupCountry == COUNTRY.MEXICO || countryV == COUNTRY.MEXICO))) {
                if (selectionsInfo && selectionsInfo.pickup && rmaInfo) {
                    addressService.getBillingAddress(shipmentId).then((res) => {
                        if (res.res.success && res?.res?.dtos.length > 0) {
                            let cfdiValue = RFC_TAX_REGIME.find(a => a.code == res?.res?.dtos[0].taxRegimen);
                            let taxRegimeText = cfdiValue ? cfdiValue.code + ' - ' + cfdiValue.description : '';
                            let cfdiText = cfdiValue ? cfdiValue.tag + ' - ' + cfdiValue.tagDescription : '';
                            let billingAddr = { ...res?.res?.dtos[0], pickupId: selectionsInfo.pickup.idd, taxRegimeText, cfdiText }
                            setBillinginfo(billingAddr);
                            dispatch(saveBillingAddress(billingAddr))
                        }
                        else {
                            let billingAddr = (selectionsInfo.billingAddress && selectionsInfo.billingAddress.pickupId == selectionsInfo.pickup.idd) ? selectionsInfo.billingAddress :
                                {
                                    rfc: selectionsInfo.pickup.gstin ? selectionsInfo.pickup.gstin : rmaInfo.gstin ? rmaInfo.gstin : "",
                                    postalCode: selectionsInfo.pickup.postal,
                                    fullCompanyName: (selectionsInfo.pickup.gstin || rmaInfo.gstin) ? selectionsInfo.pickup.name : "",
                                    streetAddress: selectionsInfo.pickup.addr1,
                                    externalNumber: "",
                                    internalNumber: "",
                                    neighborhood: "",
                                    city: selectionsInfo.pickup.city,
                                    state: selectionsInfo.pickup.province,
                                    pickupId: selectionsInfo.pickup.idd,
                                    taxRegimen: "",
                                    cfdi: ""
                                }
                            setBillinginfo(billingAddr);
                            if ((!selectionsInfo.billingAddress || selectionsInfo.billingAddress.pickupId != selectionsInfo.pickup.idd) && selectionsInfo.pickup.country === COUNTRY.MEXICO) dispatch(saveBillingAddress(billingAddr))
                        }
                    });

                }
                else if (RMALogin) setBillinginfo({ ...selectionsInfo.billingAddress, rfc: rmaInfo.gstin ? rmaInfo.gstin : "", } || {});
                setAllowEditBillingAddress((userInfo && !userInfo.rmaLogin) && (selectionsInfo?.pickup?.gstin || rmaInfo?.gstin) ? false : true);
            }
    }, [countryV, pickupCountry, isBaAvailable]);
    
    useEffect(() => {
        if (isFA) {
            warehouseService.getWarehouses().then((response) => {
                if (response.res.success) {
                    if (rmaInfo && rmaInfo.returnAddress) {
                        const existant = response.res.dtos.find(x => x.acode === rmaInfo.returnAddress.acode);
                        if (existant) dispatch({ type: SET_RETURN_ADDRESS, payload: existant });
                    }
                    setAddresses(response.res.dtos);
                }
            });
        }
    }, [isFA]);

    const handleSave = () => {
        setIsSubmitting(true);
        dispatch(setReturnFlowProp('RMASForm1Valid', false));
        dispatch(setReturnFlowProp('RMASForm2Valid', false));
        dispatch(setReturnFlowProp('RMASForm3Valid', false));
        dispatch(setReturnFlowProp('RMASForm4Valid', false));
        dispatch(setReturnFlowProp('saveRMASForms', true));
    };

    useEffect(() => {
        if (saveRMASForms && RMASForms.pickup && RMASForms.requestor && RMASForms.pickupContact  && RMASForms.alternateContact && RMALogin) {
            handleSubmit();
            dispatch(setReturnFlowProp('saveRMASForms', false));
        } else setIsSubmitting(false);
    }, [RMASForms, saveRMASForms]);

    useEffect(() => {
        if (zone !== -1) setZoneError(false);

        if (zone == 1 && (countryV === 'AE' && isDubaiPickup)) {
            setZoneError(false);
            setZoneInstructions('');
            setZoneInstructionsNA(true)
        }

    }, [zone]);

    useEffect(() => {
        if (zoneInstructionsR && zoneInstructionsR !== '' && zoneInstructionsR !== ' ' && zoneInstructionsR !== 'N/A') {
            setZone(1);
            setZoneInstructions(zoneInstructionsR);
            dispatch(setReturnFlowProp('zone', 1));
        } else if (zoneInstructionsR && zoneInstructionsR === 'N/A') {
            setZone(1);
            setZoneInstructions('');
            setZoneInstructionsNA(true);
            dispatch(setReturnFlowProp('zone', 1));
        } else if (zoneInstructionsR && zoneInstructionsR === ' ') {
            setZone(0);
            setZoneInstructions('');
            dispatch(setReturnFlowProp('zone', 0));
        }
    }, [zoneInstructionsR]);

    const reloadReturnAddress = async () => {
        const parameters = {
            param_rma_or_tradein: rmaNums.join(','),
            param_fa_case_num: '',
            isSimpleSearch: true,
            isReturnFa: isFA
        };
        const result = await getRMA(parameters);
        if (result && result.res && result.res.dtos) {
            dispatch({ type: SET_RETURN_ADDRESS, payload: result.res.dtos[0].return_address });
            dispatch(setRMAs(result.res.dtos));
        }
        return;
    };

    const getAddressClassify = (n, reverse = false) => {
        if (reverse) {
            switch (n) {
                case "STPI":  return 1;
                case "SEZ":  return 2;
                case "DTA":  return 3;
                default: return 6;
            }
        } else {
            switch (n) {
                case 1: return "STPI";
                case 2: return "SEZ";
                case 3: return "DTA";
                default: return "NA";
            }
        }
    }

    const handleAdditional = (text) => {
        let reText = text.split(". ");
        reText.shift();
        return reText.join("");
    }

    const isAddrClassifyDeprecated = (classify) =>{
        return (classify == "EHTP"  || classify == "FTWZ") && radio == 6
    }

    const billingAddressErrors = () => { 
        if (!RFCValidationRequired) return [];

        const { pickup, billingAddress } = selectionsInfo;
        var errorMessagesList = [];
        
        if (isBaAvailable && allowEditBillingAddress && (pickup.country == COUNTRY.MEXICO && getBillingAddressErrors(billingAddress).length))
            errorMessagesList.push(translate('title.billingAddressTitle'))

        if (isBaAvailable && allowEditBillingAddress && (pickup.country == COUNTRY.MEXICO && !isBillingAddrAprove)) {
            setAckPaymentError(true);
            errorMessagesList.push(translate('message.termsMessage'))
        }
        return errorMessagesList;
            
    }
  
    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        if (canSave) {
            const { pickup, requestor, contact, alternateContact } = selectionsInfo;

            if (isLabelOnly && (pickup.country !== COUNTRY.US && pickup.country !== COUNTRY.CANADA))
                showAlert({ id: 'labelOnly', page: RETURN, message: translate('message.labelOnlyMessage'), color: "yellow" });
            
            if (billingAddressErrors().length > 0)
                showAlert({ id: 'baMissing', page: RETURN, message: `${translate('message.mandatoryFieldsPrincipal')} ${billingAddressErrors().join(', ')}`, color: "yellow" });

            else {
                const tmpAdditional = RMALogin ? selectionsInfo.additional : additional;
                let additional_info = `${radio !== 6 ? (
                    !(tmpAdditional.includes(translate('message.additionalIndia'))) ?
                        `${translate('message.additionalIndia')}${getAddressClassify(radio)}. ${tmpAdditional ? tmpAdditional + '.' : ''}` :
                        `${translate('message.additionalIndia')}${getAddressClassify(radio)}. ${tmpAdditional ? handleAdditional(tmpAdditional) : ''}`
                ) : tmpAdditional}`;

                let formattedJson = {
                    // RMA
                    pickup_acode: rmaInfo && rmaInfo.returnAddress ? rmaInfo.returnAddress.acode : '',
                    idd: shipmentId,
                    // PICKUP
                    pickup_tags: pickup.atag || '',
                    pickup_name: pickup.name,
                    pickup_addr1: pickup.addr1,
                    pickup_city: pickup.city,
                    pickup_country: pickup.country,
                    pickup_province: pickup.province || '',
                    pickup_postal: pickup.postal || '',
                    pickup_gstin: pickup.gstin || '',
                    pickup_save_as: pickup.save_as || '',
                    //  REQUESTOR
                    requestor_tags: requestor.atag,
                    requestor_fname: requestor.fname,
                    requestor_lname: requestor.lname,
                    requestor_phone: requestor.phone,
                    requestor_email: requestor.email,
                    requestor_save_as: requestor.save_as || '',
                    // CONTACT
                    onsite_tags: contact.ctag,
                    onsite_fname: contact.fname,
                    onsite_lname: contact.lname,
                    onsite_phone: contact.phone,
                    onsite_email: contact.email,
                    onsite_save_as: contact.save_as || '',
                    // ALTERNATE 
                    local_tags: '',
                    local_fname: '',
                    local_lname: '',
                    local_phone: '',
                    local_email: '',
                    local_save_as: '',
                    // SHIPMENT
                    site_directions: additional_info,
                    "type": "Shipment",
                    "pickupretloc": "",
                    rb: radio,
                    "is_fact_return": isFA || false,
                    "usr_selected_lang": localStorage.getItem('powr3_lang_code')||'en'
                   
                };

                formattedJson['address_classify'] = (isDubaiPickup && zone === 1) ? "FTZ" : getAddressClassify(radio);
                formattedJson['additional_instr'] = zone === 0 ? '' : zoneInstructionsNA ? 'N/A' : zoneInstructions;
                   
                if (alternateContact) formattedJson = {
                    ...formattedJson,
                    local_tags: alternateContact.ctag || '',
                    local_fname: alternateContact.fname || '',
                    local_lname: alternateContact.lname || '',
                    local_phone: alternateContact.phone || '',
                    local_email: alternateContact.email || '',
                    local_save_as: alternateContact.save_as || ''
                };
                if (pickup.addr2) formattedJson['pickup_addr2'] = pickup.addr2;
                if (pickup.addr3) formattedJson['pickup_addr3'] = pickup.addr3;
                if (pickup.addr4) formattedJson['pickup_addr4'] = pickup.addr4;

                if (addressBook.pickup[0] && addressBook.pickup[0].country === COUNTRY.INDIA)
                    formattedJson['pickup_gstn'] = pickup.gstin || rmaInfo.gstin;
                if (pickup.country === COUNTRY.US && pickup.country != rmaInfo.returnAddress.country) {
                    if (pickup.EIN !== "") formattedJson['EIN'] = pickup.EIN;
                    if (pickup.enable_uc_type !== '') formattedJson['enable_uc_type'] = pickup.enable_uc_type;
                    if (pickup.enable_parties_to_transaction !== '') formattedJson['enable_parties_to_transaction'] = pickup.enable_parties_to_transaction;
                }

                const body = {
                    json: formattedJson,
                    pickup_id: savedShipment ? (savedShipment.shipmentId || 0) : 0,
                    rma_category: categoryCode
                };

                const response = await saveShipment(body);
                if (response && response.res && response.res.dtos) {
                    if (isFA)
                        await saveFAReturnAddress({ rma_num: rmaInfo.faCaseNum, acode: rmaInfo.returnAddress.acode });
                    else
                        await reloadReturnAddress();

                    let data = response.res.dtos[0];
                    dispatch(saveShipmentData(data.idd, additional_info, data.weight_uom, data.dim_uom, radio));
                    dispatch(setReturnFlowProp('zoneInstructions', zoneInstructionsNA ? 'N/A' : zoneInstructions));
                    
                    if (isBaAvailable )
                        if (selectionsInfo?.billingAddress && selectionsInfo?.pickup?.country === COUNTRY.MEXICO) {
                            await addressService.saveBillingAddress({
                                json: {
                                    shipId: shipmentId,
                                    ...selectionsInfo.billingAddress
                                }
                            });
                        }
                    
                    //TODO: when changing the return address the service not change the id, just the address info (check backend)
                    if (RMALogin) dispatch(updatePickupAddress({ ...pickup, site_directions: additional_info }));
                    if (((rmaInfo.address_classify === getAddressClassify(radio)) ||
                        (isAddrClassifyDeprecated(rmaInfo.address_classify))) ||
                        (countryV === COUNTRY.EMIRATES && rmaInfo.address_classify == 'FTZ')) {
                        next(); 
                    }
                    else if (userInfo.addressClassifyEnabled) {
                        if (countryV === COUNTRY.INDIA) {
                            setShowIndiaModel(true);
                        }
                        setSavedID(shipmentId);
                    }
                    else next();
                    removeAlerts();
                }
                else {
                    if (response.res.errors && response.res.errors.length) {
                        
                        showAlert({
                            id: 'shipIdAlreadySubmitted',
                            page: RETURN,
                            message: response.res.errors[0].msg.split(': ')[1]??response.res.errors[0].msg.split(': ')[0],
                            color: 'yellow'
                        });
                        setIsSubmitting(true);
                        return;
                    }
                    else alert("error");
                }
            }
        }
        else {
            let errors = [];
            if (zone === 0 || (zone === 1 && (zoneInstructions.trim() !== '' || zoneInstructionsNA))) setZoneError(false);
            else {
                setZoneError(true);
                errors.push(translate('subtitle.complianceSection'));
            }
            if (!selectionsInfo.pickup || getPickupErrors(selectionsInfo.pickup).length)
                errors.push(translate('subtitle.pickupLocation'));
            if (!selectionsInfo.requestor || getContactErrors(selectionsInfo.requestor, countryV).length)
                errors.push(translate('subtitle.requestorContactInfo'));
            if (!selectionsInfo.contact || getContactErrors(selectionsInfo.contact, countryV).length)
                errors.push(translate('subtitle.pickupContact'));
            if (!selectionsInfo.alternateContact || getContactErrors(selectionsInfo.alternateContact, countryV).length)
                errors.push(translate('subtitle.pickupContact'));
            if (isFA && !rmaInfo.returnAddress)
                errors.push(translate('title.returnAddress'));
            showAlert({ id: 'mandatoryFields', page: RETURN, message: `${translate('message.mandatoryFieldsPrincipal')} ${errors.join(', ')}`, color: 'yellow' });
        }
        setIsSubmitting(false);
    }, [selectionsInfo, additional, zone, zoneInstructions, zoneInstructionsNA, next, rmaInfo, shipmentId, dispatch, savedShipment, radio, isFA, isSubmitting, RMALogin, isBillingAddrAprove]);

    const handleChange = function (e) {
        let value = e.target.value;
          if (REGEXP.ADDITIONAL_INFO.test(value)) {
            setAdditional(value)
          }
    };

    const handleChangeTimeZone = function (e) {
        let value = e.target.value;
        setZoneError(false);
        setZoneInstructionsNA(false);
        if (REGEXP.ADDITIONAL_INFO.test(value)) {
            setZoneInstructions(e.target.value);
        }
    };

    const canSave = useMemo(() => rmaInfo && rmaInfo.country && Number(rmaInfo.status) === 1 && selectionsInfo && (zone === 0 || (zone === 1 && (zoneInstructions.trim() !== '' || zoneInstructionsNA))) && 
    selectionsInfo.pickup && selectionsInfo.requestor && selectionsInfo.contact && (RMALogin || selectionsInfo.alternateContact) &&
    !getPickupErrors(selectionsInfo.pickup).length && !getContactErrors(selectionsInfo.requestor, countryV).length &&
    !getContactErrors(selectionsInfo.contact, countryV).length && (!selectionsInfo.alternateContact || !getContactErrors(selectionsInfo.alternateContact, countryV).length) &&
    (!isFA || (isFA && rmaInfo.returnAddress)),
    [selectionsInfo, rmaInfo, zone, zoneInstructions, zoneInstructionsNA]);
    
    const setRadioBut = (data) => {
        setRadio(data);
        if (RMALogin && selectionsInfo.pickup) {
            dispatch(setReturnFlowProp('radio', data));
            dispatch(updatePickupAddress({ ...selectionsInfo.pickup, site_directions: '' }));
        }
    };
    
    const createDocumentationIndia = () => {
        dispatch(setReturnFlowProp('RMASavedID', { saved: savedID, rma: rmaNums }));
        history.push({
            pathname: DOCUMENTATION,
            state: { rma: rmaNums[0] }
        })
    }

    const showPaidByUserAlert = (rmaPAymenType, savedShipmentID) => {
        let retVal = false;
        if (!isFA && rmaPAymenType === 1 && !savedShipmentID) {
            retVal = true;
        }
        if (isFA && showPaidByUserAlertFlag && rmaPAymenType === 1 && !savedShipmentID) {
            retVal = true;
        }
        return retVal;
    }
    useEffect(() => {
        if (selectionsInfo?.pickup)
            findDubaiPickup(selectionsInfo.pickup);

    }, [selectionsInfo.pickup]);

    const findDubaiPickup = (pickup) => {
        let concatenated = '';
        for (const prop in pickup) {
            concatenated += pickup[prop] + ' ';
        }
        setisDubaiPickup(concatenated.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes('dubai'));
    }

    return (
        <Fragment>
            <div className="setPickUpLocation">
                <div className="infoContentBox">
                    <h3>{translate('title.rma')}: {rmaNums.join(', ')}</h3>
                </div>
                {isFA &&
                    <Fragment>
                        <div className="infoContainer">
                            <div className="infoBody">
                                <h3>{translate('title.returnAddress')}</h3>
                                <Select
                                    value={rmaInfo.returnAddress ? rmaInfo.returnAddress.idd : ''}
                                    text={rmaInfo.returnAddress ? (rmaInfo.returnAddress.atag || rmaInfo.returnAddress.name) : ''}
                                    onChange={(e) => e ? dispatch({ type: SET_RETURN_ADDRESS, payload: addresses.find(x => x.idd === e) }) : {}}
                                    options={addresses.map(x => ({ code: x.idd, text: x.atag || x.name }))}
                                    className="mb-0"
                                    singleSelection hideClear />
                            </div>
                        </div>
                    </Fragment>
                }
                <ContactCard content={returnAddress} hideButtons={true} className="mb-4" />
                <div className="infoContainer">
                    {
                        (!RMALogin || (countryV === COUNTRY.INDIA || countryV === COUNTRY.CHINA)) &&
                        <Fragment>
                            <div className="infoHead">
                                <h3>{countryV === COUNTRY.INDIA ?
                                    translate('title.additionalInfo')
                                : 
                                    translate('title.specialSiteInstr')
                                }</h3>
                            </div>
                            {countryV === COUNTRY.INDIA &&
                                <div className="infoBody">
                                    <label className="mb-2">{translate('message.additionalInfoIndiaConutry')}</label>
                                    <div className="timeSelector pl-1">
                                        {userInfo.addressClassifyEnabled ? 
                                            <form action="#" className="timeChecks row pl-3 mt-3">
                                                <CheckRadio
                                                    id={'radio3'}
                                                    name="radio"
                                                    text="DTA"
                                                    checked={radio === 3}
                                                    onChange={() => setRadioBut(3)} />
                                                <CheckRadio
                                                    id={'radio2'}
                                                    name="radio"
                                                    text="SEZ"
                                                    checked={radio === 2}
                                                    onChange={() => setRadioBut(2)} />
                                                <CheckRadio
                                                    id={'radio1'}
                                                    name="radio"
                                                    text="STPI"
                                                    checked={radio === 1}
                                                    onChange={() => setRadioBut(1)} />
                                                <CheckRadio
                                                    id={'radio6'}
                                                    name="radio"
                                                    text={translate("message.indiaCheckboxRadioNA")}
                                                    checked={radio === 6}
                                                    onChange={() => setRadioBut(6)} />
                                            </form>
                                        :
                                            <form action="#" className="timeChecks row pl-3 mt-3">
                                                <CheckRadio
                                                    id={'radio1'}
                                                    name="radio"
                                                    text={"STPI"}
                                                    checked={radio === 1}
                                                    onChange={() => setRadioBut(1)} />
                                                <CheckRadio
                                                    id={'radio2'}
                                                    name="radio"
                                                    text={"SEZ"}
                                                    checked={radio === 2}
                                                    onChange={() => setRadioBut(2)} />
                                                <CheckRadio
                                                    id={'radio3'}
                                                    name="radio"
                                                    text={translate("message.indiaCheckboxRadioNA")}
                                                    checked={radio === 6}
                                                    onChange={() => setRadioBut(6)} />
                                            </form>
                                        }
                                    </div>
                                </div>
                            }
                        </Fragment>
                    }
                    {!RMALogin &&
                        <div className="infoBody">
                            {countryV === COUNTRY.INDIA &&
                                <h4>{translate('title.specialSiteInstr')}</h4>
                            }  
                        {langCode === 'jp' &&
                            <Fragment>
                                <label className="mb-2">{translate('message.additionalInfoJp1')}</label>
                                <label className="mb-2">{translate('message.additionalInfoJp2')}</label>
                                <label className="mb-2">{translate('message.additionalInfoJp3')}</label>
                            </Fragment>
                        }
                        <label className="mb-2">{translate('message.additionalInfo').replace('{0}', instructionLimit)}</label>
                            <textarea
                                maxLength={instructionLimit}
                                name="additional"
                                value={additional}
                                placeholder={translate('placeholder.siteIntructions')}
                                onChange={(e) => handleChange(e)} />
                        </div>
                    }
                    {(isBaAvailable && (!RMALogin && (selectionsInfo?.pickup?.country == COUNTRY.MEXICO) || (RMALogin && (pickupCountry == COUNTRY.MEXICO || countryV == COUNTRY.MEXICO)))) &&
                        <>
                            <ContactCard content={billingAddr} allowEditBillingAddress={allowEditBillingAddress} hideButtons={true} className="mb-4 mt-4" saveBillingAddressInfo={() => { setShowBillingAddressModal(true) }} />
                            {allowEditBillingAddress && <Checkbox
                                className="displayCheckBox"
                                onClick={() => setIsBillingAddrAprove(!isBillingAddrAprove)}
                                checked={isBillingAddrAprove}
                                id={'baAprove'}
                                text={translate('formLabel.acknowledgeRFC')}
                                smallText
                                error={ackPaymentError} />}
                        </>
                    }
                    {(countryV === 'CN' || (countryV === 'AE' && isDubaiPickup)) && <div className="bondedAreaContainer">
                        <label className="my-2">{translate(`message.isBondedAreaOrFreeTrade${(countryV === 'AE' && isDubaiPickup) ? 'Dubai' : ''}`)}</label>
                        <div className="d-flex flex-column">
                            <CheckRadio
                                id={'zoneNo'}
                                name="zoneNo"
                                text={translate("button.no")}
                                checked={zone === 0}
                                onChange={() => {
                                    setZone(0);
                                    setZoneInstructions(' ');
                                    setZoneInstructionsNA(false);
                                    dispatch(setReturnFlowProp('zone', 0));
                                }}
                                error={zoneError && zone !== 1} />
                            <div className="zoneYes">
                                <CheckRadio
                                    id={'zoneYes'}
                                    name="zoneYes"
                                    text={translate("button.yes")}
                                    checked={zone === 1}
                                    onChange={() => {
                                        setZone(1);
                                        setZoneInstructions('');
                                        dispatch(setReturnFlowProp('zone', 1));
                                    }}
                                    error={zoneError} />
                                {(zone === 1 || (countryV === 'AE' && isDubaiPickup)) && <div className="infoTooltip">
                                    <InfoTooltip spanClass="infoText">{translate(`placeholder.supportZone${(countryV === 'AE' && isDubaiPickup) ? 'Dubai' : ''}`)}</InfoTooltip>
                                </div>}
                            </div>
                        </div>
                        {(zone === 1 && !(countryV === 'AE' && isDubaiPickup)) && <div className="zoneMessage">
                            <label className="mb-2">{translate('message.supportZone')}</label>
                            <div className={zoneError && zoneInstructions.trim() === '' && !zoneInstructionsNA ?
                                "zoneInstructions zoneInstructionsError" : "zoneInstructions"
                            }>
                                <textarea
                                    className="zoneText"
                                    maxLength={langCode === 'ch' || langCode === 'ch-other' ? 70 : 150}
                                    name="zoneInstructions"
                                    value={zoneInstructions}
                                    onChange={(e) => handleChangeTimeZone(e) }
                                />
                                <Checkbox
                                    id='zoneNA'
                                    className="zoneNARadio"
                                    text={translate("placeholder.notApplicable")}
                                    checked={zoneInstructionsNA}
                                    onClick={() => {
                                        setZoneError(false);
                                        setZoneInstructions('');
                                        setZoneInstructionsNA(!zoneInstructionsNA)
                                    }}
                                    error={zoneError}
                                />
                            </div>
                            {zoneError && zoneInstructions.trim() === '' && !zoneInstructionsNA &&
                                <label className="errorMessage">{translate('error.enterText')}</label>
                            }
                        </div>}
                    </div>}
                </div>                
                <IndiaRMAModal
                    change
                    show={showIndiaModel}
                    close={() => setShowIndiaModel(false)}
                    proceedWODocument={() => next()}
                    createDocument={() => createDocumentationIndia()}
                />
                <POWRModal
                    show={showBillingAddressModal}
                    title={translate(`subtitle.editBillingLocation`)}
                    onClose={() => {setShowBillingAddressModal(false); removeAlerts()}}>
                    <BillingAddressForm
                        data={billingAddrValues}
                        onClose={() => {setShowBillingAddressModal(false); removeAlerts()}} />


                </POWRModal>
            
            </div>
            <ActionButtons
                step={step}
                secondary={RMALogin ? undefined : () => dispatch(clickOutsideReturn(DASHBOARD))}
                sText={translate('button.cancel')}
                primary={RMALogin ? handleSave : handleSubmit}
                pText={translate('button.next')}
                isLoading={isSubmitting}
                completed={RMALogin ? !isSubmitting && rmaInfo : !isSubmitting} />
        </Fragment>
    );
}

export default RMAInfo;