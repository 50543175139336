import * as Yup from 'yup';
import { REGEXP } from '../constants';

export const ShipInfoRTFSchema = Yup.object().shape({
    contactName: Yup.string().matches(REGEXP.ALPHA_NUM, 'error.invalidChar').required('error.required'),
    contactPhone: Yup.string().matches(REGEXP.PHONE, 'error.phone').required('error.required'),
    carrier: Yup.string().matches(REGEXP.ALPHA_NUM, 'error.invalidChar').required('error.required'),
    awb: Yup.string().matches(REGEXP.ALPHA_NUM, 'error.invalidChar').required('error.required'),
    totalPackages: Yup.number().required('error.required').min(1, 'error.moreThanZero'),
    totalNetWeight: Yup.number().typeError('error.invalidChar').required('error.required').min(.000001, 'error.moreThanZero'),
    totalGrossWeight: Yup.number().typeError('error.invalidChar').required('error.required').min(.0000001, 'error.moreThanZero'),
    shipDate: Yup.date().required('error.required')
});