

        

export const RTFTEST = 
    [
        {
            critical_addr3: "SHUNYI DISTRICT,",
            return_postal: "101300",
            critical_addr2: "SHUN PING ROAD,",
            scheduled_ship_date: "20240831",
            critical_addr1: "NO.15, SHUN CHANG ROAD LOGISTIC PARK",
            type: "RmaLine",
            cisco_requested_fa: "N",
            return_phone: "400 810 8886, Option 4, Option 2, Option 1",
            critical_province: "BJ",
            ordered_qty: 1,
            cust_requested_fa: "N",
            idd: 4912013490751,
            return_addr1: "NO.15, SHUN CHANG ROAD LOGISTIC PARK",
            rma_line_status: 1,
            ref_line_num_repl_part: "1.1",
            replace_part_num: "C1000-48T-4X-L",
            return_atag: "",
            return_addr3: "SHUNYI DISTRICT,",
            return_addr2: "SHUN PING ROAD,",
            ref_part_num: "C1000-48T-4X-L",
            critical_postal: "101300",
            return_country: "CN",
            return_email: "https://mycase5-stage.cloudapps.cisco.com/omc?ar",
            status: 1,
            part_descr: "Catalyst 1000 48port GE, 4x10G SFP",
            ship_from_org: "000",
            return_acode: "PEK",
            rma_num: "803308617",
            critical_atag: "",
            rma_line: "2.1",
            reason_code: "RMA RETURN",
            fa_code: "OHW9",
            return_name: "PEK - BEIJING SQUARE TRADE COMPANY",
            critical_name: "PEK - BEIJING SQUARE TRADE COMPANY",
            part_weight: 15,
            part_num: "C1000-48T-4X-L",
            return_ctag: "",
            orig_ordered_qty: 5,
            critical_acode: "PEK",
            critical_country: "CN",
            return_province: "BJ",
            line_category_code: "RETURN",
            failure_class: "OF",
            cisco_line_id: "33905094",
            request_date: "20240821",
            critical_city: "BEIJING",
            return_city: "BEIJING",
            return_state: 1,
            cust_sn: "PSZ26021DEP",
            part_weight_uom: 2
        },
        {
            critical_addr3: "SHUNYI DISTRICT 2,",
            return_postal: "101300",
            critical_addr2: "SHUN PING ROAD, 2",
            scheduled_ship_date: "20240831",
            critical_addr1: "NO.15, SHUN CHANG ROAD LOGISTIC PARK 2 ",
            type: "RmaLine",
            cisco_requested_fa: "N",
            return_phone: "400 810 8886, Option 4, Option 2, Option 1",
            critical_province: "BJ",
            ordered_qty: 1,
            cust_requested_fa: "N",
            idd: 4912013490752,
            return_addr1: "NO.15, SHUN CHANG ROAD LOGISTIC PARK",
            rma_line_status: 1,
            ref_line_num_repl_part: "1.1",
            replace_part_num: "C1000-48T-4X-L",
            return_atag: "",
            return_addr3: "SHUNYI DISTRICT,",
            return_addr2: "SHUN PING ROAD,",
            ref_part_num: "C1000-48T-4X-L",
            critical_postal: "101300",
            return_country: "CN",
            return_email: "https://mycase5-stage.cloudapps.cisco.com/omc?ar",
            status: 1,
            part_descr: "Catalyst 1000 48port GE, 4x10G SFP",
            ship_from_org: "000",
            return_acode: "PEK",
            rma_num: "803308617",
            critical_atag: "",
            rma_line: "2.1",
            reason_code: "RMA RETURN",
            fa_code: "OHW9",
            return_name: "PEK - BEIJING SQUARE TRADE COMPANY",
            critical_name: "PEK - BEIJING SQUARE TRADE COMPANY",
            part_weight: 15,
            part_num: "C1000-48T-4X-L",
            return_ctag: "",
            orig_ordered_qty: 1,
            critical_acode: "PEK",
            critical_country: "CN",
            return_province: "BJ",
            line_category_code: "RETURN",
            failure_class: "OF",
            cisco_line_id: "33905094",
            request_date: "20240821",
            critical_city: "BEIJING",
            return_city: "BEIJING",
            return_state: 1,
            cust_sn: "PSZ26021DEP",
            part_weight_uom: 2
        }
    ]

    