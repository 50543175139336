import React, { useState, useEffect, useRef } from 'react';
import { useTranslate } from '../../core/contexts';
import { Icon } from './index';

const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },

        [ref, handler]
    );
}

export default props => {
    const { translate } = useTranslate();
    const [input1, setInput1] = useState("");
    const [writing, setWriting] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setWriting(false));
    const handleChange = (e) => {
        const { value } = e.target;
        setInput1(value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            props.onEnter();
        }
    }

    const handleClear = () => {
        let e = { target: { value: '' } };
        if (props.onChange) props.onChange(e);
        else handleChange(e);
        if (props.onClear) props.onClear();
    };

    return (
        <div className="inputControlWrapper" style={props.bStyle}>
            <div
                style={props.style}
                className={
                    props.error ?
                        `inputControl inputControlError ${props.className ? props.className : ''}` :
                        `inputControl ${writing ? 'inputControlWriting' : props.value ? 'inputControlText' : ''} ${props.className ? props.className : ''}
                        ${props.readOnly ? 'readonly' : ''}`
                }>
                <div className="d-flex justify-content-between">
                    {props.label && <label style={props.lStyle}>{props.label}{props?.mandatory && <label className="asterisk">*</label>}</label>}
                    {props.clear && ((props.value !== '' && props.value != null) ? props.value : input1 !== '') && <>
                        <label> </label>
                        <label className="clear-btn" onClick={() => handleClear()} >
                            {translate('button.clear')}
                        </label>
                    </>}
                </div>
                <div className="inputContainer">
                    <input
                        ref={ref}
                        className="text"
                        type="text"
                        autoComplete="off"
                        readOnly={props.readOnly}
                        maxLength={props.maxLength}
                        name={props.name ? props.name : "input1"}
                        placeholder={props.placeholder}
                        value={(props.value !== '' && props.value != null) ? props.value : input1}
                        onChange={props.onChange ? props.onChange : handleChange}
                        onKeyPress={props.onEnter ? handleKeyPress : undefined}
                        onClick={setWriting.bind(this, true)}
                        onBlur={props.onBlur}
                        disabled={props.disabled}
                    />
                    {props.icon &&
                        <span onClick={() => props.icon.handler ? props.icon.handler() : props.onEnter()}>
                            <Icon
                                content={props.icon.content}
                                width={props.icon.width || '1.1rem'}
                                height={props.icon.height || '1.1rem'}
                                color={props.icon.color || '#9194A7'}
                                className={`mt-2 clickable ${props.icon.className || ''}`}
                                type={props.icon.type || 'regular'}
                                k={props.icon.k} />
                        </span>
                    }
                </div>
            </div>
            {(props.error && props.errorMessage) && <label className="errorMessage" style={props.errorStyle}>
                {props.errorMessage}
            </label>}
        </div>
    );
}
