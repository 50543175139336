import { useDispatch } from "react-redux";
import { getShipments, getShiptree } from "../../services";
import { SEARCH_SHIPMENTS } from "../constants";
import { BASE_URL_API, URL_EXCEL_GENERATOR } from "../constants/URLS";
import { useTranslate } from "../contexts";
import { setShipmentSeleted, setShipmentsSearching, setShipmentsSearchResults } from "../redux/actions";
import { useAlert } from "./useAlert";
import { RLSP } from "../../core/constants/ROUTES";

export const useShipmentSearch = () => {
    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const { translate } = useTranslate();

    const setSearching = (v) => {
        dispatch(setShipmentsSearching(v));
    }

    const searchShipments = (parameters) => {
        getShipments(parameters).then(response  => handleData(response)).catch(err => {
            console.log(err);
            setSearching(false);
        });
    }

    const searchRLSP = (parameters, firstLoad = false) => {
        if (firstLoad) {
            dispatch(setShipmentsSearchResults([]));
            setSearching(false);
        } 
        else {
            getShiptree(parameters).then(response => handleData(response)).catch(err => {
                console.log(err);
                setSearching(false);
            });
        }
            
    }
    
    const handleData = (response) => {
        let resStr = response.res ? typeof (response.res) === 'string' ?
        response.res.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
        : response.res : { dtos: [] };
        let dtos = typeof (resStr) === 'string' ? JSON.parse(resStr).dtos : resStr.dtos;
        dtos = dtos.filter(x => x.rmas);
        setSearching(false);
        dispatch(setShipmentSeleted({}));
        if (dtos.length > 500) dtos = dtos.slice(0, 500);
        if (dtos.length > 0) handleAlert(dtos.length);
        dispatch(setShipmentsSearchResults(dtos));
    }

    const handleAlert = (length) => {
        showAlert({
            id: 'returnedMore500',
            message: length >= 500 ? translate('message.returnedMore500') : 
            `${translate('message.searchReturned')} ${length} ${translate('message.shipments')}`,
            page: SEARCH_SHIPMENTS
        });
        showAlert({
            id: 'returnedMore500RLSP',
            message: length >= 500 ? translate('message.returnedMore500') : 
            `${translate('message.searchReturned')} ${length} ${translate('message.shipments')}`,
            page: RLSP
        });
    }

    const handleExport = (parameters) => {
        dispatch(setShipmentSeleted({}));
        let URL = BASE_URL_API + URL_EXCEL_GENERATOR + '?' +
            (parameters.param_ship_code && 'param_ship_code=' + parameters.param_ship_code) +
            (parameters.param_rma_or_tradein && '&param_rma_or_tradein=' + parameters.param_rma_or_tradein) +
            (parameters.param_tn && '&param_tn=' + parameters.param_tn) +
            (parameters["id-gct-view-search-adv-checkbox"] && '&id-gct-view-search-adv-checkbox=' + parameters["id-gct-view-search-adv-checkbox"]) +
            (parameters.param_date_from && '&param_date_from=' + parameters.param_date_from) +
            (parameters.param_date_to && '&param_date_to=' + parameters.param_date_to) +
            (parameters.param_ship_status && '&param_ship_status=' + parameters.param_ship_status) +
            (parameters.param_carrier && '&param_carrier=' + parameters.param_carrier) +
            (parameters.param_theater && '&param_theater=' + parameters.param_theater) +
            (parameters.param_tn && '&param_tn=' + parameters.param_tn) +
            (parameters.param_use_date && '&param_use_date=' + parameters.param_use_date) +
            (parameters.param_only_mine && '&param_only_mine=' + parameters.param_only_mine) +
            (parameters.param_max_records && '&param_max_records=' + parameters.param_max_records) +
            (parameters.param_is_paperless && '&param_is_paperless=' + parameters.param_is_paperless) +
            (parameters.param_serial_number && '&param_serial_number=' + parameters.param_serial_number) +
            (parameters.param_rma_cat && '&param_rma_cat=' + parameters.param_rma_cat) +
            (parameters.param_exclude_saved && '&param_exclude_saved=' + parameters.param_exclude_saved);
            
        window.open(URL);
    }

    return {
        searchShipments,
        searchRLSP,
        handleExport
    };
};