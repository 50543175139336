import request from "../core/api/request";

export const commercialInvoiceService = {
    getCommercialInvoice: (data) => {
        return request.postEncode({ url: `get/commercialInvoice`, data });
    },
};

export const displayCommercialInvoice = (id) => {
    return request.postEncode({ url: `/get/display_print_ci?id=${id}` })
}
export const generateCommercialInvoice = (id) => {
    return request.postEncode({ url: `commercialInvoice?shipId=${id}` })
}
export const generateCommercialInvoiceRTF = (data) => {
    return request.postURIENCODEDJSONSave({ url: `commercialInvoiceRTF`,data})
}