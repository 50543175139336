import {
    SHIPMENT_STATUS_NUM,
    RMA_SHIPEVENTTYPE_TYPES,
    CARRIER_TYPES,
    LOGINTYPE_CISCO_IRETURNS
} from '../constants';

const loginTypeGeneral = 2;

export const renderReschedule = (ship_event, status, carrier_type) => {
    let parsedShipEvent = parseInt(ship_event,10);
    let loginType = loginTypeGeneral !== LOGINTYPE_CISCO_IRETURNS;
    let visible = (
        (status !== SHIPMENT_STATUS_NUM.STATUS_DELETED) &&
        loginType &&
        (carrier_type === CARRIER_TYPES.CARRIER_TYPE_CARRIER_DISPATCH || carrier_type === CARRIER_TYPES.CARRIER_TYPE_REG_CARRIER) &&
        (
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_CREATED_BY_USER ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_CONFIRMED ||
            // parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_DENIED ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_RESCHEDULED_BY_USER ||
            parsedShipEvent === RMA_SHIPEVENTTYPE_TYPES.RMA_SHIPEVENTTYPE_PICKUP_RESCHEDULED_BY_CARRIER
        )
    );
    return visible;
}
