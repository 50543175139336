import React, { memo, useEffect, useState } from 'react';
import { Icon } from './controls';
import { useTranslate } from '../core/contexts';
import { VirtualScrollChild } from './shared';
import { COLOR } from '../core/constants/Colors';

const PartList = memo(({ items, title, headers, isGroupedList, emptyMessageTag }) => {
    const { translate } = useTranslate();
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (isGroupedList) {
            let ct = [];
            items.forEach((x) => {
                if (!ct.find(y => y === x.pack)) ct.push(x.pack);
            })
            setCount(ct.length);
        }
    }, [items, isGroupedList]);

    return (
        <div className="infoContainer pt-4">
            <div className="infoHead pb-2">
                <h3>{translate(title)}</h3>
                <label>{items && (!isGroupedList ? items.length : count)}</label>
            </div>
            <div className={`infoBody list ${isGroupedList ? 'packages-list': 'parts-list'}`}>
                {(items && items.length === 0) && <label>{translate(emptyMessageTag || 'placeholder.noPartsSelected')}</label>}
                {(items && items.length > 0) &&
                    <table className="packCreated">
                        <thead>
                            {headers && <tr>
                                {headers && headers.map((header, i) => (
                                    <th key={i} className={`${header === "Package/Box" ? 'text-right' : ''}`}>{header}</th>
                                ))}
                            </tr>}
                        </thead>
                        <tbody>
                            {
                                items.map((item, i) => (
                                    <VirtualScrollChild height='21' type='table' key={i}>
                                        <td>{item.part_num}</td>
                                        <td className={`${item.content ? '' : 'text-right'}`}>{item.content ? `(${item.qty || 5})` : (item.qtyPicked)}</td>
                                        {item.content &&
                                            <td className="text-right">
                                                {item.pack}<Icon content={item.content} type="solid" width="14px" height="14px" color={COLOR.GREY} className="ml-1" />
                                            </td>}
                                    </VirtualScrollChild>
                                ))
                            }
                        </tbody>
                    </table>}
            </div>
        </div>
    );
}, (prev, next) => prev.items === next.next
    && prev.isGroupedList === next.isGroupedList);

export default PartList;
