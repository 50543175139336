export const COUNTRY = {
    US: "US",
    INDIA: "IN",
    CANADA: 'CA',
    BRAZIL: 'BR',
    TURKEY: 'TR',
    RUSSIA: 'RU',
    CHINA: 'CN',
    UKRAINE: 'UA',
    KAZAKHSTAN : 'KZ',
    UZBEKISTAN : 'UZ',
    MEXICO: 'MX',
    EMIRATES: 'AE',
};

export const COUNTRY_OF_ORIGIN = [
    { text: "Albania (AL)", name: "Albania", code: "AL", idd: "AL" },
    { text: "Andorra (AD)", name: "Andorra", code: "AD", idd: "AD" },
    { text: "Armenia (AM)", name: "Armenia", code: "AM", idd: "AM" },
    { text: "Australia (AU)", name: "Australia", code: "AU", idd: "AU" },
    { text: "Austria (AT)", name: "Austria", code: "AT", idd: "AT" },
    { text: "Belarus (BY)", name: "Belarus", code: "BY", idd: "BY" },
    { text: "Belgium (BE)", name: "Belgium", code: "BE", idd: "BE" },
    { text: "Bosnia and Herzegovina (BA)", name: "Bosnia and Herzegovina", code: "BA", idd: "BA" },
    { text: "Brazil (BR)", name: "Brazil", code: "BR", idd: "BR" },
    { text: "Bulgaria (BG)", name: "Bulgaria", code: "BG", idd: "BG" },
    { text: "Canada (CA)", name: "Canada", code: "CA", idd: "CA" },
    { text: "China (CN)", name: "China", code: "CN", idd: "CN" },
    { text: "Croatia (HR)", name: "Croatia", code: "HR", idd: "HR" },
    { text: "Cyprus (CY)", name: "Cyprus", code: "CY", idd: "CY" },
    { text: "Czech Republic (CZ)", name: "Czech Republic", code: "CZ", idd: "CZ" },
    { text: "Denmark (DK)", name: "Denmark", code: "DK", idd: "DK" },
    { text: "Estonia (EE)", name: "Estonia", code: "EE", idd: "EE" },
    { text: "Eurasian Customs Union (EA)", name: "Eurasian Customs Union", code: "EA", idd: "EA" },
    { text: "Faroe Islands (FO)", name: "Faroe Islands", code: "FO", idd: "FO" },
    { text: "Finland (FI)", name: "Finland", code: "FI", idd: "FI" },
    { text: "France (FR)", name: "France", code: "FR", idd: "FR" },
    { text: "Georgia (GE)", name: "Georgia", code: "GE", idd: "GE" },
    { text: "Germany (DE)", name: "Germany", code: "DE", idd: "DE" },
    { text: "Gibraltar (GI)", name: "Gibraltar", code: "GI", idd: "GI" },
    { text: "Greece (GR)", name: "Greece", code: "GR", idd: "GR" },
    { text: "Hong Kong (HK)", name: "Hong Kong", code: "HK", idd: "HK" },
    { text: "Hungary (HU)", name: "Hungary", code: "HU", idd: "HU" },
    { text: "Iceland (IS)", name: "Iceland", code: "IS", idd: "IS" },
    { text: "India (IN)", name: "India", code: "IN", idd: "IN" },
    { text: "Indonesia (ID)", name: "Indonesia", code: "ID", idd: "idd" },
    { text: "Ireland (IE)", name: "Ireland", code: "IE", idd: "IE" },
    { text: "Isle of Man (IM)", name: "Isle of Man", code: "IM", idd: "IM" },
    { text: "Italy (IT)", name: "Italy", code: "IT", idd: "IT" },
    { text: "Japan (JP)", name: "Japan", code: "JP", idd: "JP" },
    { text: "Israel (IL)", name: "Israel", code: "IL", idd: "IL" },
    { text: "Kosovo (XK)", name: "Kosovo", code: "XK", idd: "XK" },
    { text: "Latvia (LV)", name: "Latvia", code: "LV", idd: "LV" },
    { text: "Liechtenstein (LI)", name: "Liechtenstein", code: "LI", idd: "LI" },
    { text: "Lithuania (LT)", name: "Lithuania", code: "LT", idd: "LT" },
    { text: "Luxembourg (LU)", name: "Luxembourg", code: "LU", idd: "LU" },
    { text: "Macedonia (MK)", name: "Macedonia", code: "MK", idd: "MK" },
    { text: "Malaysia (MY)", name: "Malaysia", code: "MY", idd: "MY" },
    { text: "Malta (MT)", name: "Malta", code: "MT", idd: "MT" },
    { text: "Mexico (MX)", name: "Mexico", code: "MX", idd: "MX" },
    { text: "Moldova (MD)", name: "Moldova", code: "MD", idd: "MD" },
    { text: "Monaco (MC)", name: "Monaco", code: "MC", idd: "MC" },
    { text: "Montenegro (ME)", name: "Montenegro", code: "ME", idd: "ME" },
    { text: "Netherlands (NL)", name: "Netherlands", code: "NL", idd: "NL" },
    { text: "New Zealand (NZ)", name: "New Zealand", code: "NZ", idd: "NZ" },
    { text: "Norway (NO)", name: "Norway", code: "NO", idd: "NO" },
    { text: "Oman (OM)", name: "Oman", code: "OM", idd: "OM" },
    { text: "Pakistan (PK)", name: "Pakistan", code: "PK", idd: "PK" },
    { text: "Philippines (PH)", name: "Philippines", code: "PH", idd: "PH" },
    { text: "Poland (PL)", name: "Poland", code: "PL", idd: "PL" },
    { text: "Portugal (PT)", name: "Portugal", code: "PT", idd: "PT" },
    { text: "Republic of Korea (KR)", name: "Republic of Korea", code: "KR", idd: "KR" },
    { text: "Romania (RO)", name: "Romania", code: "RO", idd: "RO" },
    { text: "San Marino (SM)", name: "San Marino", code: "SM", idd: "SM" },
    { text: "Saudi Arabia (SA)", name: "Saudi Arabia", code: "SA", idd: "SA" },
    { text: "Serbia  (RS)", name: "Serbia", code: "RS", idd: "RS" }, 	
    { text: "Singapore (SG)", name: "Singapore", code: "SG", idd: "SG" },
    { text: "Slovakia (SK)", name: "Slovakia", code: "SK", idd: "SK" },
    { text: "Slovenia (SI)", name: "Slovenia", code: "SI", idd: "SI" },
    { text: "South Africa (ZA)", name: "South Africa", code: "ZA", idd: "ZA" },
    { text: "Spain (ES)", name: "Spain", code: "ES", idd: "ES" },
    { text: "Sweden (SE)", name: "Sweden", code: "SE", idd: "SE" },
    { text: "Switzerland (CH)", name: "Switzerland", code: "CH", idd: "CH" },
    { text: "Taiwan (TW)", name: "Taiwan", code: "TW", idd: "TW" },
    { text: "Thailand (TH)", name: "Thailand", code: "TH", idd: "TH" },
    { text: "Turkey (TR)", name: "Turkey", code: "TR", idd: "TR" },
    { text: "Ukraine (UA)", name: "Ukraine", code: "UA", idd: "UA" },
    { text: "United Kingdom (GB)", name: "United Kingdom", code: "GB", idd: "GB" },
    { text: "United States (US)", name: "United States", code: "US", idd: "US" },
    { text: "Vietnam (VN)", name: "Vietnam", code: "VN", idd: "VN" },
    
];