import React from 'react';
import { Link } from "react-router-dom";
import { Spinner } from '../controls';

export default props => props.href ? (
    <a
        target={props.target}
        href={props.href}
        rel="noopener noreferrer"
        className={`linkComponent ${props.className ? props.className : ''}`}
    >
        {props.children}
    </a>
) : props.loading ? (
    <Link
        to={props.disabled ? '#' : props.to ? props.to : '#'}
        target={props.target}
        onClick={!props.disabled ? props.onClick : undefined}
        className={props.disabled ? 
            `linkComponent linkComponentLoading linkComponentDisabled ${props.className ? props.className : ''}` :
            `linkComponent linkComponentLoading ${props.className ? props.className : ''}`
        }
    >
        <Spinner
            className="mr-1"
            size="20px"
            color="#099AD6"
        />
        {props.children}
    </Link>
) : (
    <Link
        to={props.disabled ? '#' : props.to ? props.to : '#'}
        target={props.target}
        onClick={!props.disabled ? props.onClick : undefined}
        className={props.disabled ? 
            `linkComponent linkComponentDisabled ${props.className ? props.className : ''}` :
            `linkComponent ${props.className ? props.className : ''}`
        }
    >
        {props.children}
    </Link>
);