import React from 'react';
import { DownloadList } from '../components';
import { useTranslate } from '../core/contexts';
import { FullPage } from '../components/AppShell/partials/FullPage.react';

const DownloadCenter = () => {
    const { translate } = useTranslate();

    const titlePage = <h1 className="m-0">{translate('aux.downloadCenter')}</h1>;

    return (
        <FullPage>
            <div className="d-flex d-lg-none w-100">
                <div className="col-12">
                    {titlePage}
                </div>
            </div>
            <div className="col-12 p-lg-0 order-2 order-lg-1" >
                <div className="main-download">
                    <div className="d-none d-lg-flex">
                        {titlePage}
                    </div>
                    <DownloadList />
                </div>
            </div>
        </FullPage>
    );
};

export default DownloadCenter;