import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react';
import { Formik, Form } from 'formik';
import { TextInput, Select } from '../controls';
import { POWRButton } from '../styledComponents';
import { useTranslate } from '../../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountries, selectCountriesSerbiaMontenegro } from '../../core/redux/selectors';
import { loadCountries } from '../../core/redux/actions';
import { useAlert } from '../../core/hooks';
import { BillingAddressSchema } from '../../core/schemas/BillingAddressSchema';
import { saveBillingAddress } from '../../core/redux/actions/returnFlow.actions';
import { RFC_TAX_REGIME, RFC_CFDI } from '../../core/constants/RFC_TAX_REGIME';
import { validateBillingAddress } from '../../services';

export const BillingAddressForm = ({ onClose, data }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const refSubmit = useRef();
    const { showAlert, removeAlert } = useAlert();
    const [initialValues, setInitialValues] = useState({ ...data, cfdiText: data.taxRegimen ? data.cfdi + ' - ' + RFC_TAX_REGIME.find(a => a.code == data.taxRegimen).tagDescription: '' } || {
        rfc: '',
        postalCode: '',
        fullCompanyName: '',
        streetAddress: '',
        externalNumber: '',
        internalNumber:'',
        neighborhood: '',
        city: '',
        state: '',
        taxRegimen: '',
        taxRegimeText:'',
        cfdi: '',
        cfdiText:''
    });

    const save = async (values, { setSubmitting }) => {
        var data = {
            rfcReceptor: values.rfc,
            nombreReceptor: values.fullCompanyName,
            domicilioFiscalReceptor: values.postalCode,
            regimenFiscalReceptor: values.taxRegimen,
            usoCFDI: values.cfdi
        }
        await validateBillingAddress(data).then((res) => {
            if (res.res.success && !res.res.SATErrors) {
                dispatch(saveBillingAddress(values))
                setTimeout(() => {
                    setSubmitting(false);
                    onClose();
                }, 400);
            }
            else {
                let err = [];
                res.res.SATErrors && res.res.SATErrors.forEach((item, index) => {
                    err.push(<li key={index}>{item}</li>)
                })
                showAlert({ id: 'err', page: '*', message: err, color: "yellow", isModal: true })
            }
        })
    };

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        removeAlert('mandatoryFields')
        e.preventDefault();
        let result = await validateForm(values);
        if (!Object.keys(result).length) {
            handleSubmit();
        }
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color: "yellow", isModal: true })
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BillingAddressSchema}
            enableReinitialize
            onSubmit={save}>
            {
                ({ handleSubmit, isSubmitting, values, setFieldValue, getFieldMeta, validateForm, setTouched, setErrors }) => (
                    <Form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} className="content p-0 overflow-hidden"  >
                        <div className="content">
                            <div className="row">
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput isRequired type="text" name={'fullCompanyName'} label={'formLabel.satCompany'} maxLength="255" />
                                </div>
                                <div className="col-12 col-md-6 px-3 mb-0 form-group">
                                    <TextInput onChange={(e)=> setFieldValue('rfc',e.target.value.toUpperCase(), false)} type="text" isRequired name={'rfc'} label={'RFC'} maxLength="13" />
                                </div>
                             
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <Select
                                        options={RFC_TAX_REGIME.map((obj) => {
                                            return {
                                                code: obj.code,
                                                text: obj.code + ' - ' + obj.description
                                            }
                                        })}
                                        value={values.taxRegimen}
                                        className="inputSpace"
                                        onChange={(val) => {
                                            let cfdiValue = RFC_TAX_REGIME.find(a => a.code == val);
                                            setFieldValue('taxRegimen', val, true);
                                            if (cfdiValue) {
                                                setFieldValue('taxRegimeText', cfdiValue.code + ' - ' + cfdiValue.description, true);
                                                setFieldValue('cfdi', cfdiValue.tag, true);
                                                setFieldValue('cfdiText', cfdiValue.tag + ' - ' + cfdiValue.tagDescription, true);
                                            }
                                            else {
                                                setFieldValue('cfdi', '', true);
                                                setFieldValue('cfdiText', '', true);
                                            }
                                        }}
                                        singleSelection mandatory
                                        error={(getFieldMeta('taxRegimen').touched && getFieldMeta('taxRegimen').error)}
                                        label={translate('formLabel.taxRegime')}
                                        isRequired={true} />
                                   
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'postalCode'} label={'formLabel.zip'} maxLength='25' />
                                </div>
                                {/* <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" disabled isRequired name={'cfdiText'} label={'formLabel.cfdi'} maxLength="255" />
                                </div> */}
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput isRequired type="text" name={'streetAddress'} label={'formLabel.street'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'neighborhood'} label={'formLabel.neighborhood'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'internalNumber'} label={'formLabel.internalNumber'} maxLength="35" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" name={'externalNumber'} label={'formLabel.externalNumber'} maxLength="35" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'city'} label={'formLabel.city'} maxLength="255" />
                                </div>
                                <div className="col-md-6 px-3 mb-0 form-group">
                                    <TextInput type="text" isRequired name={'state'} label={'formLabel.state'} maxLength="255" />
                                </div>
                      
                            </div>
                        </div>
                        {

                            <Fragment>
                                <div className="custom-toolbar">
                                    <div className="toolbar-content pt-2">
                                        <POWRButton type="button" color={'secondary'} onClick={onClose}>{translate('button.cancel')}</POWRButton>
                                        <POWRButton type="submit" disabled={isSubmitting} ref={refSubmit}>{translate('button.save')}</POWRButton>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Form>
                )
            }
        </Formik>
    );
};