import { useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RETURN } from "../constants/ROUTES";
import { useTranslate } from "../contexts";
import { saveCreatedLocalPackages, setReturnFlowProp } from "../redux/actions";
import { selectCreatePackagesError, selectPickedSublinesOfSelectedLines, selectReturnFlowSavedInfo, selectRMAsNum, selectShipmentId } from "../redux/selectors";
import { useAlert } from './useAlert';

export const useCreatePackages = () => {
    const dispatch = useDispatch();
    const { showAlert, removeAlert } = useAlert();
    const { translate } = useTranslate();

    const [isLoading, setIsLoading] = useState(false);

    const selectedParts = useSelector(selectPickedSublinesOfSelectedLines); // Checked sublines of selectParts table
    const shipmentId = useSelector(selectShipmentId);
    const returnFlow = useSelector(selectReturnFlowSavedInfo);
    const rmaNums = useSelector(selectRMAsNum);
    const error = useSelector(selectCreatePackagesError);

    const canSave = useMemo(() => selectedParts.filter(x => !x.package_id).length === 0, [selectedParts]);

    const handleSubmit = useCallback(async (next) => {
        setIsLoading(true);
        setError(!canSave);
        if (canSave) {
            await dispatch(saveCreatedLocalPackages(shipmentId, selectedParts, returnFlow.weight_uom, returnFlow.length_uom, rmaNums));
            next();
        }
        setIsLoading(false);
    }, [shipmentId, rmaNums, returnFlow, selectedParts, dispatch, canSave, translate]);

    const setError = (value) => {
        dispatch(setReturnFlowProp('createPackagesError', value));
        if (!value) removeAlert('mandatoryFields');
        else showAlert({
            id: 'mandatoryFields',
            time: false,
            page: RETURN,
            message: translate('message.notRMAsPackaged'),
            color: 'yellow'
        });
    };

    return {
        selectedParts,
        canSave,
        handleSubmit,
        isLoading,
        error,
        setError,
        rmaNums
    };
};