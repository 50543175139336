import { contactService } from "../../../services";
import { REMOVE_CONTACT, SET_CONTACTS } from "../actionTypes";

export const loadUserContacts = () => (dispatch) => {
    contactService.getUserContacts().then((res) => {
        if (res.res.success) dispatch({ type: SET_CONTACTS, payload: res.res.dtos });
    });
};

export const saveContact = (body, isAdd) => (dispatch) => {
    contactService.saveContactToAddressBook(body).then((res) => {
        dispatch(loadUserContacts());
        // dispatch({ 
        //     type: isAdd ? ADD_CONTACT : UPDATE_CONTACT, 
        //     payload: isAdd ? res.res.dtos.map((x) => ({...x, type: String(x.contact_type)}))[0] : body.json.dtos[0] 
        // });
    });
};

export const removeContact = (id) => (dispatch) => {
    contactService.deleteContact(id).then((res) => {
        if (res.res.success) dispatch({ type: REMOVE_CONTACT, payload: id });
    });
};