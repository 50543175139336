import {
    RESET_RMA_INFO, SET_RMA_INFO, RESET_LABEL_ONLY,
    SET_RMA_SEARCH_PARAMETERS,
    SET_RMA_SEARCH_RESULTS,
    SET_RMA_SEARCHING,
    SET_RMAS
} from '../actionTypes';

export const setRMAInfo = (rmas, shipmentId, labelOnly, DHL_ELP_enabled, turkeyRevenue, isFA) => ({
    type: SET_RMA_INFO,
    payload: { rmas, shipmentId, labelOnly, DHL_ELP_enabled, turkeyRevenue, isFA }
});

export const setRMAs = (rmas) => ({
    type: SET_RMAS,
    payload: rmas
});

export const resetLabelOnly = (labelOnly) => ({
    type: RESET_LABEL_ONLY,
    payload: labelOnly
});

export const resetRMAInfo = () => ({
    type: RESET_RMA_INFO,
});

export const setRMASearchParameters = (data) => ({
    type: SET_RMA_SEARCH_PARAMETERS,
    payload: data
});

export const setRMASearchResults = (data) => ({
    type: SET_RMA_SEARCH_RESULTS,
    payload: data
});

export const setRMASearching = (data) => ({
    type: SET_RMA_SEARCHING,
    payload: data
});