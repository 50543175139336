import { createSelector } from "reselect";

export const selectGeneral = state => state.general;

export const selectLoader = createSelector(
    selectGeneral,
    (generalState) => generalState.loader
);

export const selectTypeAlert = createSelector(
    selectGeneral,
    (generalState) => generalState.isModal
)

export const selectAlerts = createSelector(
    [selectGeneral],
    ({ alerts }) => alerts
);

export const selectClickOutsideReturn = createSelector(
    selectGeneral,
    (generalState) => generalState.clickOutsideReturn
);

export const selectOpenAsideFilters = createSelector(
    selectGeneral,
    (generalState) => generalState.asideFilters
);

export const selectDownloadParams = createSelector(
    selectGeneral,
    (generalState) => generalState.downloadParams
);

export const selectIsShipmentEdit = createSelector(
    selectGeneral,
    (generalState) => generalState.isShipmentEdit
);

export const selectRMAsURLParams = createSelector(
    selectGeneral,
    (generalState) => generalState.RMAsURLParam
);