import React from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRIcon } from '../styledComponents';

export default ({ status, className,isTitle }) => {
    const { translate } = useTranslate();

    return status ?
        <p
            key={`status-${status.text}`}
            title={translate(status.text)}
            className={`d-flex align-items-center ${className || ''}`}
        >
            <POWRIcon className="fas fa-circle mr-2 mb-1" size="10px" color={status.color || '#000'} />
            {!isTitle ? <span>{translate(status.text)}</span> : <strong><span>{translate(status.text)}</span></strong>  }
        </p>
        : <p>—</p>
}
