import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommercialInvoice, getCommercialInvoiceEMEA, getFdf, getPocUkraine, getShipUserDocuments } from "../../services/shipTreeService";
import { COUNTRY, FORM_TYPE } from "../constants";
import { useTranslate } from "../../core/contexts";
import { BASE_URL_API, URL_PRINT_RTF, URL_PKGS_LIST_EMEA, URL_CI_EMEA } from "../constants/URLS";
import { setLoader } from "../redux/actions";
import { useAlert } from "./useAlert";
import { generateCommercialInvoice } from "../../services/commercialInvoiceService";
import { getCommercialInvoiceRTF } from "../../services/rmaService";
import { RTFCOMMERCIALINVOICE } from "../../core/constants/ROUTES";
import { gtagService } from "../../services";

export const useCommercialInvoice = ({ shipment }) => {
    const dispatch = useDispatch();
    const { showAlert, removeAlert } = useAlert();
    const { translate } = useTranslate();
    const [modalTemplate, setModalTemplate] = useState(null);
    const [showFaultDetection, setShowFaultDetection] = useState(false);
    const [showActExchange, setShowActExchange] = useState(false);
    const [showViewEmail, setShowViewEmail] = useState(false);
    const [showUploadDocuments, setShowUploadDocuments] = useState(false);
    const [uploadDocuments, setUploadDocuments] = useState([]);

    useEffect(() => {
        if (modalTemplate === null) removeAlert("mandatoryFields")
    }, [modalTemplate]);

    useEffect(() => {
        if (shipment) {
            const country = shipment.ship_code ? shipment.ship_code.substr(0, 2) : '';
            const isService = shipment.rma_category_code === 'S';
            const isEmailCarrier = shipment.carrier_service_code === 'EMAIL';
            setShowFaultDetection((country === COUNTRY.RUSSIA || country === COUNTRY.KAZAKHSTAN || country === COUNTRY.UZBEKISTAN) && isService && isEmailCarrier);
            setShowActExchange(ukranianPoc && country === COUNTRY.UKRAINE && isService && isEmailCarrier);
            const isIndiaOrBrazil = country === COUNTRY.INDIA || country === COUNTRY.BRAZIL;
            if (shipment.idd && shipment.rmas && shipment.rmas[0] && shipment.tns && shipment.tns[0] && isIndiaOrBrazil)
                handleUserDocuments(shipment.idd, shipment.rmas[0], shipment.tns[0], isIndiaOrBrazil);
            else if (shipment.idd) {
                handleUserDocuments(shipment.idd, shipment.rmas && shipment.rmas[0], shipment.tns && shipment.tns[0], isIndiaOrBrazil);
                setShowUploadDocuments(false);
            }
            else {
                setShowViewEmail(false);
                setShowUploadDocuments(false);
            }
        }
    }, [shipment])

    const handleUserDocuments = (ship_id, rma_num, tn, isIndiaOrBrazil) => {
        getShipUserDocuments({ ship_id, rma_num, tn }).then(res => {
            if (res && res.res && res.res.success) {
                let show = res.res.dtos.findIndex(
                    item => item && item.mime_type && item.mime_type == "eml"
                );
                setShowViewEmail(show > -1);
                setShowUploadDocuments(isIndiaOrBrazil && res.res.dtos.length > 0);
                setUploadDocuments(res.res.dtos);
            } else {
                setShowViewEmail(false);
                setShowUploadDocuments(false);
            }
        }).catch(err => {
            setShowViewEmail(false);
            setShowUploadDocuments(false);
        })
    }

    const ukranianPoc = useMemo(() => {
        try {
            let configParams = sessionStorage.getItem('configParams').replace(/\s+/g, "");
            configParams = JSON.parse(configParams);
            return configParams.dtos.find(x => x.name === 'pocUkrania.enabled').value === 'true';
        }
        catch {
            return false;
        }
    }, []);


    const handleCommercialInvoice = async (shipId, shipCode, type, fromPrintAll = false, hasPrintLablels = false, fromSummary = false, pkgListFR = false) => {
        dispatch(setLoader(true));
            await getCommercialInvoice({ ship_id: shipId, ship_code: shipCode }).then((response) => {
                if (response.res.warn) {
                    showAlert({
                        id: "comercialInvoice",
                        message: response.res.warn,
                        page: '*',
                        color: 'yellow'
                    });
                    if (hasPrintLablels) print(FORM_TYPE.ZIP, shipId)
                } else if (!response.res.success) {
                    showAlert({
                        id: "comercialInvoiceError",
                        message: response.res.errors[0].msg,
                        page: '*',
                        color: 'red'
                    });
                    if (hasPrintLablels) print(FORM_TYPE.ZIP, shipId)
                } else {
                    if (response.res.dtos.length) {
                        let openForm = response.res.dtos.find(line => line.country_origin_editable === true);
                        if (openForm) {
                            setModalTemplate({ type: !fromPrintAll ? type : FORM_TYPE.ZIP, shipId, shipCode, data: response.res.dtos, fromPrintAll, fromSummary });
                        }
                        else !fromPrintAll ? print(type, shipId) : print(FORM_TYPE.ZIP, shipId);
                    }
                }
            });
        dispatch(setLoader(false));
    };



    const handleFaultDetection = async (shipId, shipCode) => {
        dispatch(setLoader(true));
        await getFdf({ ship_id: shipId, ship_code: shipCode }).then((response) => {
            if (response.res.dtos.length && !response.res.dtos[0].country_origin_editable)
                print(FORM_TYPE.FAULT_DETECTION, shipId);
            else setModalTemplate({ type: FORM_TYPE.FAULT_DETECTION, shipId, shipCode, data: response.res.dtos });
        });
        dispatch(setLoader(false));
    };

    const handleActExchange = async (shipId, shipCode) => {
        dispatch(setLoader(true));
        await getPocUkraine({ ship_id: shipId, ship_code: shipCode }).then((response) => {
            if (response.res.dtos.length)
                setModalTemplate({ type: FORM_TYPE.ACT_OF_EXCHANGE, shipId, shipCode, data: response.res.dtos });
        });
        dispatch(setLoader(false));
    };

    const handleViewReceipt = (shipId) => {
        print(FORM_TYPE.VIEW_RECEIPT, shipId);
    };

    const print = (type, id = null, data = null) => {
        switch (type) {
            case FORM_TYPE.ZIP:
                generateCommercialInvoice(id).then(async (response) => {
                    await window.open(`${BASE_URL_API}/${type}?shipId=${id}`, "popup", 'width=600,height=600');
                });
                break;
            case FORM_TYPE.RTF:
                let params = { rmaLines: data.lines, eor: data.rtfInfo.eor, shipInfo: data.rtfInfo.shipInfo };
                const encodedBody = encodeURIComponent(JSON.stringify(params));
                const url = `${BASE_URL_API}/${URL_PRINT_RTF}?rmaNum=${data.rtfInfo.rma}&json=${encodedBody}`;
                window.open(url, "popup", 'width=600,height=600');
                gtagService.event('Reports', 'RTF CI');
                showAlert({ id: 'success', page: RTFCOMMERCIALINVOICE, message: translate('message.docSuccess'), color: 'green' });
                break;
            case FORM_TYPE.PACKING_LIST_FR:
                {
                    window.open(`${BASE_URL_API}/${URL_PKGS_LIST_EMEA}?shipId=${id}`, "popup", 'width=600,height=600');
                    gtagService.event('Reports', 'France Packing List');
                    break;
                }
                
            case FORM_TYPE.COMMERCIAL_INVOICE_CH: {
                window.open(`${BASE_URL_API}/${URL_CI_EMEA}?shipId=${id}`, "popup", 'width=600,height=600');
                gtagService.event('Reports', 'Switzerland CI');
                break;
            }
            default: 
                window.open(`${BASE_URL_API}/${type}?shipId=${id}`, "popup", 'width=600,height=600');
                break;
        }

    };

    const setLines = (rtfLines, resLines = null, print = false) => {
        var rmaLines = [];
        if (rtfLines.length > 0) {
            if (print) {
                for (let i = 0; i < rtfLines.length; i++) {
                    let element = rtfLines[i];
                    element.units.forEach(unit => {
                        
                        let line = resLines.find(x => x.idd === unit.idProd);
                        rmaLines.push({
                            id: line.idd,
                            originCountry: line.country_origin,
                            partDescr: unit.partDescription,
                            partNum: unit.partNumRef,
                            serialNumber: unit.sn,
                            cisco_line_id: unit.ciscoLineId,
                            part_weight: unit.partWeight,
                            part_weight_uom: unit.partWeightUom,
                            request_date: unit.reqDate,
                            returnQty: 1,
                            
                        });
                    });
                };
                return rmaLines
            }
            else if (resLines === null) { 
                for (let i = 0; i < rtfLines.length; i++) {
                    let element = rtfLines[i];
                    element.units.forEach(unit => {
                        rmaLines.push({
                            partNum: unit.partNumRef,
                            serialNumber: unit.sn,
                            ciscoLineId: unit.ciscoLineId,
                        });
                    });
                };
                return rmaLines;
            }
            else
                for (let i = 0; i < rtfLines.length; i++) {
                    let element = rtfLines[i];
                    element.units.forEach(unit => {
                        let line = resLines.find(x => x.idd === unit.idProd);
                        rmaLines.push({
                            idd: line.idd,
                            country_origin: line.country_origin,
                            country_origin_editable: line.country_origin_editable,
                            part_desc: unit.partDescription,
                            part_num: unit.partNumRef,
                            rma_num: line.rma_num,
                            serial: unit.sn,
                            ciscoLineId: unit.ciscoLineId,
                            name: unit.name,
                            partWeight: unit.partWeight,
                            partWeightUom: unit.partWeightUom,
                            reqDate: unit.reqDate,
                            type: unit.type,
                            returnQty: 1,
                        });
                    });
                };
        }
        return rmaLines.map((x,index) => {return {...x, seq: index + 1}});
    }


    const handleCommercialInvoiceRTF = async (rma, refNumber, data, type) => {
        let modalLines = setLines(data.lines, null, false);
        const body = {
            json: modalLines,
            refNumber: refNumber,
            rma_num: rma,
        };
        dispatch(setLoader(true));
        await getCommercialInvoiceRTF(body).then((response) => {
            if (response.res.warn) {
                showAlert({
                    id: "comercialInvoice",
                    message: response.res.warn,
                    page: '*',
                    color: 'yellow'
                });
            } else if (!response.res.success) {
                showAlert({
                    id: "comercialInvoiceError",
                    message: response.res.errors[0].msg,
                    page: '*',
                    color: 'red'
                });
             
            } else {
                if (response.res.dtos.length > 0) {
                    let modalLines = setLines(data.lines, response.res.dtos);
                    let openForm = response.res.dtos.find(line => line.country_origin_editable === true);
                    if (openForm) {
                        setModalTemplate({ type, data: modalLines, rtfInfo: { rma, eor: data.eor, shipInfo: data.shipInfo } });
                    }
                        
                    else {
                        let list = setLines(data.lines, response.res.dtos, true)
                        print(type, null, { lines: list, rtfInfo: { rma, eor: data.eor, shipInfo: data.shipInfo } });
                    }
                }
            }
        });

        dispatch(setLoader(false));
    };

    return {
        handleCommercialInvoice,
        handleCommercialInvoiceRTF,
        handleFaultDetection,
        handleActExchange,
        handleViewReceipt,
        print,
        modalTemplate,
        setModalTemplate,
        showFaultDetection,
        showActExchange,
        showViewEmail,
        showUploadDocuments,
        uploadDocuments
    };
};