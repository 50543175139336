import styled from "styled-components";

export const PageWrapper = styled.main`
  display: flex;
  height: 100%;
  padding-top: 64px;
  padding-left: 80px;

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    padding-left: 0;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    height: 100%;
    width: 100%;
    ${props => props.wizard ? 'flex-direction: column' : ''};
    min-height: calc(100vh - 240px)
  }

  @media (min-width: ${({ theme: { breakpoint } }) => breakpoint.size.lg}) {
    min-height: calc(100vh - 60px);
  }
`;
