import styled from "styled-components";

export const FullPage = styled.div`
  display: flex;
  padding: 0;
  height: 100%;
  min-height: calc(100vh - 124px);

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xl}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.lg}) {
    padding: 8px 0;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    padding: 8px;
    flex-direction: column;
    min-height: 100%;
    height: auto;
  }
`;
