import React, { memo } from 'react';
import { useTranslate } from '../../core/contexts';
import { useAlert } from '../../core/hooks';
import { POWRIcon } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import { useRef } from 'react';

const Upload = memo(({ attachedFiles, onRemoveFile, onAddFile, isUpload, rlsp }) => {
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const fiveMegas = rlsp ? 512000 : 5000000;

    const fileInputRef = useRef(null);


    const handleInputFile = async (files) => {
        let file = files[files.length - 1];
        if (file.size > fiveMegas) {
            showAlert({
                id: 'file5mb',
                page: '*',
                message: rlsp ? translate('message.maxFileSizeLimitRLSP') : translate('message.maxFileSizeLimit'),
                color: 'yellow'
            });
            return;
        }
        else await onAddFile(file);
        if(fileInputRef.current)
        fileInputRef.current.value = null;
    };

    // console.log((fileInputRef && fileInputRef.current) ? fileInputRef.current.files : 'null')

    return (
        <div className="uploadContainer">
            <div className="formContainer">
                <div className="uploadZone">
                    <label className="labelForm">
                        {translate('placeholder.uploadFiles')}:
                    </label>
                    <form className="UploadForm">
                        <label htmlFor="controlFile" className="labelFile">
                            {translate('button.attachFiles')}
                        </label>
                        <input ref={fileInputRef} type="file" className="inputFile" id="controlFile" onChange={(e) => handleInputFile(e.currentTarget.files)} />
                    </form>
                </div>
                <label className="message">
                    {rlsp ? translate('message.maxFileSizeRLSP') : translate('message.maxFileSize')}
                </label>
            </div>
            {!isUpload &&
                attachedFiles.map((file, i) => (
                    <div key={`fileContainer-${i}`} className="fileContainer">
                        <POWRIcon className="fal fa-file-alt mr-3" size="24px" color={COLOR.GREY} />
                        <div className="fileData">
                            <div className="fileNameAExt">
                                <h4 className="fileName">{file.name}</h4>
                            </div>
                            <label>{file.size}</label>
                        </div>
                        <div className="removeFile" onClick={() => onRemoveFile(file.idd)}>
                            <POWRIcon className="fal fa-trash-alt ml-3" size="13px" color={COLOR.CISCO_BLUE} />
                        </div>
                    </div>
                ))
            }
        </div>
    );
}, (prev, next) => prev.attachedFiles === next.attachedFiles
    && prev.onAddFile === next.onAddFile
    && prev.onRemoveFile === next.onRemoveFile);

export default Upload;