import { createSelector } from 'reselect';

const selectRTF = state => {
    return state.rtfFlow;
}


export const selectRTFFlowProducts = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.products
);

export const selectRTFFlowOldProducts = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.oldProducts
);

export const selectRTFFlow = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow
);

export const selectRTFFlowSavedId = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.savedId
);

export const selectRTFFlowRMA= createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.rma
);
export const selectRefNumberFlowRMA = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.refNumber
);

export const selectRTFFlowReturnByDate = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.returnByDate
);
export const selectRTFFlowCountry = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.country
);

export const selectRTFFlowData = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.data
);
export const selectRTFFlowEntityType = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.entityType
);
export const selectRTFFlowTheaterCode = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.theaterCode
);
export const selectRTFFlowEOR = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.eor
);
export const selectRTFFlowIOR = createSelector(
    [selectRTF],
    (rtfFlow) => rtfFlow.ior
);
