import React from "react";
import styled from "styled-components";
import { FOOTER_NAVIGATION } from "../../../core/constants/Navigation";
import { useConfigParams } from "../../../core/hooks";
import { NavigationLink } from "./NavigationLink.react";

const NavigationContainer = styled.ul`
  position: relative;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavigationWrapper = styled.nav`
  grid-area: FooterNavigation;

  li {
    margin: 0 12px;
    &:first-child::before {
      content: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    ${NavigationContainer} {
      flex-direction: column;
      justify-content: flex-start;
      padding: 32px 0;

      &::before {
        content: "";
        width: 1px;
        position: absolute;
        background-color: ${({ theme: { colors } }) => colors.white};
        bottom: 30%;
        top: 30%;
        left: -24px;
      }
    }

    li {
      flex-grow: 0;
      margin: 12px 0;
      &::before {
        content: none !important;
      }
    }
  }
`;

export const Navigation = () => {
  const navigation = FOOTER_NAVIGATION;
  const { getPOWRFaqLink, getSupportReturnsLink, getLegalLink, getCookiesLink,getQSGuideLink } = useConfigParams();
   
  const QSGuideLink = getQSGuideLink();
  const POWRFaqLink = getPOWRFaqLink();
  const SupportReturnsLink = getSupportReturnsLink();
  const LegalLink = getLegalLink();
  const CookiesLink = getCookiesLink();

  const urls = {QSGuideLink, POWRFaqLink, SupportReturnsLink, LegalLink, CookiesLink };
  let nav = navigation.map(x => ({ id: x.id, to: urls[`${x.to}`] }));

  return (
    <NavigationWrapper>
      <NavigationContainer>
        {nav.map((props) => (
          <NavigationLink key={props.id} {...props} />
        ))}
      </NavigationContainer>
    </NavigationWrapper>
  );
};
