import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { TextInput } from '../controls';
import { POWRButton } from '../styledComponents';
import { PackageQtySchema } from '../../core/schemas';
import { useTranslate } from '../../core/contexts';
import { useAlert } from '../../core/hooks';
import { gtagService } from '../../services';

export const PackageQtyForm = ({ onSave, onCancel, maxItems }) => {
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const [initialValues, setInitialValues] = useState({
        itemQty: 0,
        packageQty: 0,
        maxItems: 0
    });

    useEffect(() => {
        gtagService.event('NewFeatures', 'AutoAssignItemsToPackages');
        setInitialValues({
            ...initialValues,
            itemQty: maxItems,
            packageQty: maxItems,
            maxItems
        });
    }, [maxItems]);

    const customSubmit = async (e, handleSubmit, validateForm, values, setTouched, setErrors) => {
        e.preventDefault();
        let result = await validateForm(values);
        if (!Object.keys(result).length) handleSubmit();
        else {
            setTouched(result);
            setErrors(result);
            showAlert({ id: 'mandatoryFields', page: '*', message: translate('message.mandatoryFields'), color:"yellow", isModal:true})
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={PackageQtySchema}
            onSubmit={onSave}>
            {
                ({ handleSubmit, isSubmitting, validateForm, values, setTouched, setErrors }) => (
                    <form onSubmit={(e) => customSubmit(e, handleSubmit, validateForm, values, setTouched, setErrors)} data-testid="ctForm" className="content p-0 overflow-hidden">
                        <div className="content">
                            <div className="row">
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="number" isRequired name={'itemQty'} label={'formLabel.items'} maxLength="5" />
                                </div>
                                <div className="col-md-12 px-3 mb-0 form-group">
                                    <TextInput type="number" isRequired name={'packageQty'} label={'subtitle.packages'} maxLength="5" />
                                </div>
                            </div>
                        </div>
                        <div className="toolbar">
                            <POWRButton type="button" color={'secondary'} onClick={onCancel}>{translate('button.cancel')}</POWRButton>
                            <POWRButton type="submit" disabled={isSubmitting}>{translate('button.ok')}</POWRButton>
                        </div>
                    </form>
                )
            }
        </Formik>
    );
};
