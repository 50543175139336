import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useTranslate } from '../../core/contexts';
import { Checkbox } from './index';
import { useLostFocus } from '../../core/hooks';
import { POWRIcon } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';
import { SHIPMENT_EVENT_STATUS } from '../../core/constants';
import { DropDown, DropDownPickup, Status,Link, Input } from './';

const Select = ({ value, label, options, placeholder, mandatory, singleSelection, onChange,
    availableAdd, onAddNew, customIcon, notFilter, hideClear, error, fromTable, status, isSN, ...props }) => {
    const { translate } = useTranslate();
    const [showOptions, setShowOptions] = useState(false);
    const [results, setResults] = useState([]);
    const [isFirtsTimeOpened, setIsFirstTimeOpened] = useState(true);
    const [searchText, setSearchText] = useState(isSN ? props.text : '');

    const controlRef = useRef();

    const phText = useMemo(() => {
        return placeholder ? placeholder.replace(/\s+/g, "-") : label ? label.replace(/\s+/g, "-") : '';
    }, [placeholder])

    const iOS = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    useEffect(() => {
        // SHOW ONLY RELEVANT OPTIONS
        if (!notFilter && !isFirtsTimeOpened && searchText) {
            let result = options ? options.filter(opt => opt.text.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) : [];
            setResults(result);
        }
        // SHOW ALL OPTIONS BY DEFAULT
        else setResults(options);
    }, [searchText, options, notFilter, isFirtsTimeOpened]);

    const displayedText = useMemo(() => {
        if (showOptions) return searchText;
        const existentOption = options.find(x => x.code === value);
         if (existentOption) return existentOption.text;
        else if (isSN) return searchText
        else if (!availableAdd) return '';
        else return value;
    }, [value, searchText, options, showOptions, isSN]);

    useEffect(() => {
        const selectedCode = results.find(x => x.code === value);
        let element = document.getElementById(`mobileSelect-${phText}`);
        if (selectedCode && element) element.value = selectedCode.code;
        else {
            const selectedValue = results.find(x => x.text === value);
            if (selectedValue && element) element.value = selectedValue.code;
            else if (element) element.value = value;
        }
    }, [value, results]);

    const handleShowOptions = () => {
        if (props.disabled || showOptions) return;
        setShowOptions(true);
        setIsFirstTimeOpened(true);
        const existentOption = options.find(x => x.code === value);
        setSearchText(singleSelection ? (existentOption ? existentOption.text : (availableAdd ? value : isSN ? searchText : '')) : isSN ? searchText : '');
    };

    const handleWrite = (value) => {
        if (isFirtsTimeOpened) setIsFirstTimeOpened(false);
        setSearchText(value);
        if (isSN)
            onChange(value)
    };

    const handleSelect = (val, alreadySelected, closeOptions) => {
        if (singleSelection) {
            if (closeOptions) setShowOptions(false);
            onChange(val);
        }
        else {
            const newValues = alreadySelected ? value.filter((x) => x !== val) : [...value, val];
            onChange(newValues);
        }
    };

    const handleRemove = (specificValue) => {
        let newValues = !singleSelection ? [] : '';
        if (specificValue)
            newValues = value.filter(x => x !== specificValue);
        onChange(newValues);

        let element = document.getElementById(`mobileSelect-${phText}`);
        element.value = "";
    };

    const handleKey = (e) => {
        if (e.keyCode == 8 || e.key === 'Backspace') {
            e.preventDefault();
            handleWrite(displayedText.slice(0, -1))    
        }
        if (e.keyCode == 13 || e.key === 'Enter') {
            e.preventDefault();
            handleLeave();
        }
    };

    useLostFocus([controlRef], () => {
        if (showOptions) handleLeave();
    });

    const handleLeave = () => {
        const existentOption = options.find(x => x.code === searchText || x.text === searchText);
        if (singleSelection) {
            if (existentOption) onChange(existentOption.code);
            else {
                if (availableAdd) onAddNew(searchText);
                else onChange('');
            }
        }
        setShowOptions(false);
    };

    return (
        <div
            className={props.disabled ? "selectControlWrapper selectControlDisabled" : "selectControlWrapper"}
            style={props.bStyle}
            ref={controlRef}>
            <div
                style={props.style}
                className={`selectControl ${props.className} ${(error) ? 'selectControlError' : ''}`}>
                <div className="header-select">
                    {label && <label className="inputLabel">{label}{mandatory && <label className="asterisk">*</label>}</label>}
                    {!singleSelection ?
                        !props.disabled && <label className="clear-btn" onClick={() => handleRemove(null)}>{translate('button.clearAll')}</label>
                        :
                        !availableAdd && !hideClear && !props.disabled &&
                        <label className="clear-btn" onClick={() => handleRemove(null)}>{translate('button.clear')}</label>
                    }
                </div>
                <div
                    onClick={() => handleShowOptions()}
                    className={`selectContainer ${showOptions ? 'selectContainerActive' : ''} ${!singleSelection ? 'multiple' : ''} ${(error) ? 'selectControlError' : ''} ${(fromTable) ? 'p-0' : ''} ${props.selectContainerClass}`}>
                    {
                        singleSelection ? <>
                            <div className="clearContainer d-md-none mobileSelect">
                                <select name="select" className="text" id={`mobileSelect-${phText}`} >
                                    <option disabled defaultValue value></option>
                                    {results && results.length && results.map((opt, j) => (
                                        <option
                                            value={opt.code}
                                            key={`option-${j}-${opt.idd}`}
                                            onClick={() => handleSelect(opt.code, false, true)}
                                        >
                                            {opt.text}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="clearContainer d-none d-md-block">  
                            {fromTable ? //added fromTable prop to add the status circle before the text in option selected this just appear on table edits
                            <Fragment>
                                <span className='dot' style={{backgroundColor:status}} ></span> 
                                <div className='overflow'>
                                    <input
                                        className={`text ${fromTable && 'text-table p-0 d-flex'}`}
                                        type="text"
                                        name="select"
                                        autoComplete="off"
                                        placeholder={placeholder}
                                        value={displayedText}
                                        onChange={(e) => handleWrite(e.target.value)}
                                        maxLength={255}
                                        readOnly={!showOptions}
                                        onKeyDown={e => !isSN && handleKey(e)}
                                    />
                                </div>
                            </Fragment>
                            :
                            <input
                            className={`text ${fromTable && 'text-table p-0 d-flex'}`}
                            type="text"
                            name="select"
                            autoComplete="off"
                            placeholder={placeholder}
                            value={displayedText}
                            onChange={(e) => handleWrite(e.target.value)}
                            onBlur={isSN ? (e) => isSN && onChange(e.target.value) : undefined}
                            maxLength={255}
                            readOnly={!showOptions}
                            onKeyDown={e => !isSN && handleKey(e)}
                        />
                            }
                         
                            </div>
                        </> :
                            <div className="text multiple">
                                {
                                    value.slice(0, 3).map((item, i) => (
                                        <span className="value-tag" key={'tag' + i + '-' + item}>
                                            {item.substr(0, 8)}
                                            <span onClick={() => handleRemove(item)}>
                                                <POWRIcon
                                                    className="far fa-times ml-1"
                                                    color={COLOR.CISCO_BLUE}
                                                    size="0.6rem" />
                                            </span>
                                        </span>
                                    ))
                                }
                                {value.length > 3 && <small className="align-bottom">...</small>}
                            </div>
                    }
                    {showOptions && <div
                        className={`iconContainer selectable${singleSelection ? ' d-none d-md-block' : ''}`}
                        onClick={() => handleLeave()}>
                        <POWRIcon className={availableAdd ? (customIcon || "fas fa-search") : "fas fa-caret-up"} size="12px" color="#58585B" selectable />
                    </div>}
                    {!showOptions && <div
                        className={`iconContainer selectable${singleSelection ? ' d-none d-md-block' : ''}`}
                        onClick={() => { if (!props.disabled) handleShowOptions() }}>
                        <POWRIcon className={availableAdd ? (customIcon || "fas fa-search") : "fas fa-caret-down"} size="12px" color="#58585B" selectable />
                    </div>}
                </div>
                {
                    (showOptions && results && results.length) ?
                        <div className={`selectOptions${availableAdd ? ' selectOptionsSmall' : ''}${singleSelection ? ' d-none d-md-block' : ''}`}>
                            {results.map((op, i) => {
                                const isSelected = singleSelection ? value === op.code : value.indexOf(op.code) >= 0;
                                return (
                                    <Fragment key={`option-${i}-${op.idd}`}>
                                        {
                                            singleSelection ?
                                                <div //added fromTable prop to add the status circle for each option before text this just appear on table edits
                                                    className={`selectOption single ${isSelected ? 'selectOptionActive' : ''} ${(fromTable && 'selectOption-table')} `}
                                                    onClick={() => handleSelect(op.code, false, true)}>
                                                     {fromTable && <i className='fal fa-circle dot' style={{color:op.color, backgroundColor:op.color}} ></i> }{op.text}
                                                </div>
                                                :
                                                <div className={'selectOption y-centered'}>
                                                    <Checkbox
                                                        id={`option-${i}-${op.idd}`}
                                                        text={op.text}
                                                        value={isSelected}
                                                        checked={isSelected}
                                                        disabled={false}
                                                        onClick={() => handleSelect(op.code, isSelected, false)} />
                                                </div>
                                        }
                                    </Fragment>
                                );
                            })}
                        </div> : null
                }
            </div>
            {
                error && error !== "error.required" &&
                <label className="errorMessage">{translate(error)}</label>
            }
        </div>
    );
};

export default Select;
