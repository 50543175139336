import React, { Fragment, useEffect, useState } from 'react';
import redo from '../../static/icons/redo.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSelectedLines, selectBaseRMAInfo, selectCategoryCode
} from '../../core/redux/selectors';
import { useTranslate } from '../../core/contexts';
import { Link, ActionButtons } from '../controls';
import { PartList } from '../index'
import { resetParts, setReturnFlowProp } from '../../core/redux/actions';
import { useAlert, useGlobalMessage, useSelectPartsValidations } from '../../core/hooks';
import { RETURN } from '../../core/constants/ROUTES';
import { POWRButton, POWRIcon, POWRModal } from '../styledComponents';
import { COLOR } from '../../core/constants/Colors';

export default ({ back, next }) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { showAlert, removeAlerts } = useAlert();
    const { showPageMessage } = useGlobalMessage();
    const { hasRemovedSublines, showResetModal, closeResetModal } = useSelectPartsValidations();

    const selectedLines = useSelector(selectSelectedLines);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const categoryCode = useSelector(selectCategoryCode);

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        let params = {
            module: 'GCT_Step3',
            country: rmaInfo.country,
            rmaCategory: categoryCode
        };
        showPageMessage(params, RETURN);
    }, []);

    const handleResetSublinePackages = () => {
        dispatch({ type: 'RESET_SUB_LINES_PACKAGES' });
        closeResetModal(false);
        next();
        removeAlerts();
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        if (selectedLines.length) {
            if (!hasRemovedSublines()) {
                dispatch(setReturnFlowProp('oldSelectedLines', selectedLines));
                next();
                removeAlerts();
            }
        }
        else showAlert({
            id: 'mandatoryFields',
            page: RETURN,
            message: translate('message.selectPart'),
            color: 'yellow'
        });
        setIsSubmitting(false);
    };


    return (
        <Fragment>
            <div className="selectedParts">
                <Link onClick={() => dispatch(resetParts())}>
                    <img
                        alt="redo"
                        src={redo}
                        style={{ marginRight: 8, height: 13 }} />
                    {translate('button.resetSelection')}
                </Link>
                <PartList items={selectedLines.map(x => ({ ...x, part_num: x.part }))} title={translate('title.selectedParts')} ></PartList>
            </div>
            {
                showResetModal &&
                <POWRModal
                    show={true}
                    title={translate('message.title.warning')}
                    size={'medium'}
                    onClose={() => closeResetModal()}>
                    <div className="x-centered mt-3 mb-4">
                        <POWRIcon
                            className="fas fa-trash-alt"
                            size="3.5em"
                            color={COLOR.CISCO_BLUE} />
                    </div>
                    <span className="d-block my-2 text-center">
                        {translate(`message.confirmResetSublinesPackages`)}
                    </span>
                    <span className="d-block my-2 text-center">{translate('message.wishContinue')}</span>
                    <div className="toolbar">
                        <POWRButton color={'secondary'} onClick={() => closeResetModal(true)}>{translate('button.cancelAndRestore')}</POWRButton>
                        <POWRButton onClick={() => handleResetSublinePackages()}>{translate('button.ok')}</POWRButton>
                    </div>
                </POWRModal>
            }
            <ActionButtons
                secondary={() => back()}
                sText={translate('button.back')}
                primary={() => handleSubmit()}
                pText={translate('button.next')}
                isLoading={isSubmitting}
                completed={!isSubmitting} />
        </Fragment>
    );
}