import Request from "../core/api/request";
import { URL_ADD_SECURITY, URL_GET_SECURITY, API_LOGOUT, URL_SAVE_SECURITY } from "../core/constants/URLS";
 
export const addSecurity = (data) => { 
    return Request.postEncode({ url: URL_ADD_SECURITY, data }); 
};

export const getSecurity = () => { 
    return Request.postEncode({ url: URL_GET_SECURITY }); 
};

export const removeSecurity = () => { 
    return Request.postEncode({ url: API_LOGOUT }); 
};

export const saveSecurity = (data) => { 
    return Request.postEncode({ url: URL_SAVE_SECURITY, data }); 
};

export const ping = () =>{
    return Request.getURIEncoded({url:'get/ping'});
}

