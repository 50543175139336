import * as Yup from 'yup';
import { REGEXP } from '../constants';

export const ContactSchema = Yup.object().shape({
    save_as:
        Yup.string()
            .when('saveAtAddressBook', {
                is: (saveAtAddressBook) => !!saveAtAddressBook,
                then: Yup.string().required(),
                otherwise: Yup.string()
            }),
    fname:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    lname:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    phone:
        Yup.string()
            .matches(REGEXP.PHONE, 'error.phone')
            .required('error.required'),
    email:
        Yup.string()
            .lowercase()
            .matches(REGEXP.EMAIL, 'error.email')
            .required('error.required'),
    type:
        Yup.string()
            .required('error.required'),
    saveAtAddressBook:
        Yup.boolean()
});

export const ContactSchemaUSCA = Yup.object().shape({
    save_as:
        Yup.string()
            .when('saveAtAddressBook', {
                is: (saveAtAddressBook) => !!saveAtAddressBook,
                then: Yup.string().required(),
                otherwise: Yup.string()
            }),
    fname:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    lname:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    phone:
        Yup.string()
            .matches(REGEXP.PHONE_USCA, 'error.phone')
            .required('error.required'),
    email:
        Yup.string()
            .lowercase()
            .matches(REGEXP.EMAIL, 'error.email')
            .required('error.required'),
    type:
        Yup.string()
            .required('error.required'),
    saveAtAddressBook:
        Yup.boolean()
});