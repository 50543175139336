import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { ExpireSessionModal } from './modals';
import { useAuth } from "../core/hooks";
import { Fragment } from 'react';
import { addSecurity } from '../services';

//Added this component to handle the time out session, IdleTimer library help us to add a expire time for each session. 
const IdleTimerContainer = () => {
    const { authUser, logout } = useAuth();
    const idleTimerRef = useRef(null);
    const sessionTimeOutRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const onIdle = () => {
        setModalIsOpen(true);
        sessionTimeOutRef.current = setTimeout(logout, parseInt(process.env.REACT_APP_EXPIRATIONTIMELOGOUT));
    }

    const handleRefresh = async () => {
        setModalIsOpen(false);
        clearTimeout(sessionTimeOutRef.current);
        if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === '45') {
            const body = { type: 'cisco', login: 'asptest' };
            await addSecurity(body)
        }
        await authUser();
    }



    return (
        <Fragment>
            <IdleTimer ref={idleTimerRef} timeout={parseInt(process.env.REACT_APP_EXPIRATIONTIMEWARNING)} onIdle={onIdle}></IdleTimer>
            <ExpireSessionModal show={modalIsOpen} handleLogOut={logout} handleRefresh={handleRefresh}></ExpireSessionModal>
        </Fragment>
    )
};

export default IdleTimerContainer