import { createSelector } from 'reselect';

const selectRMA = state => {
    return state.rma;
}

export const selectRMAs = createSelector(
    [selectRMA],
    ({ rmas }) => rmas
);

export const selectRMAsNum = createSelector(
    [selectRMAs],
    (rmas) => rmas.map(x => x.rma_num[0]).sort((a, b) => a > b)
)
export const selectTotalOrderQty = createSelector(
    [selectRMAs],
    (rmas) => rmas.map(x => x.total_ordered_qty)
)
export const selectShipmentId = createSelector(
    [selectRMA],
    ({ shipmentId }) => shipmentId
);

export const selectLabelOnly = createSelector(
    [selectRMA],
    ({ labelOnly }) => labelOnly
);

export const selectTurkeyRevenue = createSelector(
    [selectRMA],
    ({ turkeyRevenue }) => turkeyRevenue
);

export const selectBaseRMAInfo = createSelector(
    [selectRMAs],
    (rmas) => rmas.length ? ({
        status: rmas[0].return_state[0],
        paymentType: rmas[0].payment_type,
        returnAddress: (rmas.find(x => x.return_address) || { return_address: null }).return_address,
        country: (rmas.find(x => x.country && x.country.length) || { country: [''] }).country[0],
        gstin: (rmas.find(x => x.gstin) || { gstin: '' }).gstin,
        faCaseNum: (rmas.find(x => x.fa_case_num) || { fa_case_num: null }).fa_case_num,
        address_classify: rmas[0].address_classify ? rmas[0].address_classify : '',
        theater_code: (rmas.find(x => x.theater_code) || { theater_code: null }).theater_code,
    }) : null
);

export const selectCategoryCode = createSelector(
    [selectRMAs],
    (rmas) => rmas.length ? rmas[0].rma_category_code[0] : null
);

export const selectRMASearchParameters = createSelector(
    [selectRMA],
    (rma) => rma.rmaSearchParameters
);

export const selectRMASearchResults = createSelector(
    [selectRMA],
    (rma) => rma.rmaSearchResults
);

export const selectRMASearching = createSelector(
    [selectRMA],
    (rma) => rma.rmaSearching
);

export const selectIsFA = createSelector(
    [selectRMA],
    ({ isFA }) => isFA || false
);