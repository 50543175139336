import React from 'react';
import styled from 'styled-components';
import { Link } from '../controls';

const DivStructure = ({ className, title, buttonText, buttonSymbol, onButtonClick, isRequired, children, titleIcon, customTitle }) => (
    <div className={className}>
        {(title || titleIcon || isRequired || customTitle || buttonText || buttonSymbol || onButtonClick) &&
            <div className="row head">
                {!customTitle ?
                    <h3 className="col pr-0 ellipsis">
                        {titleIcon} {title} {isRequired && <label className="asterisk">*</label>}
                    </h3>
                    :
                    customTitle
                }
                {
                    buttonText &&
                    <Link className="col-auto pl-0" onClick={onButtonClick} >
                        <span className="pr-1">{buttonSymbol}</span>
                        {buttonText}
                    </Link>
                }
            </div>
        }
        <div className="body">
            {children}
        </div>
    </div>
);

export const InfoSection = styled(DivStructure)`
    width: 100%;
    box-sizing: border-box;
    border: ${props => props.hideBorders ? 'none' : '1px solid rgba(205,208,227,0.3)'};
    border-radius: 8px;
    background-color: #FFFFFF;
    min-height: ${props => props.isPickUpCard ? 'auto' : props.isEmpty ? '100%' : 'auto'}; 
    padding: ${props => props.type === 'taskBar' || props.type === 'table' ? '0' :
        props.type === 'addressBook' ? '0' :
            props.type === 'cards' ? '22px 1.5rem 1.5rem' :
                props.hideBorders ? '.7rem 1.5rem' : '1.5rem'
    };
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${props => props.type === 'addressBook' ? "1rem" :
        props.type === 'taskBar' ? '0' : ""
    };
        padding-bottom: ${props => props.type === 'cards' ? '0.75rem' : props.type === 'table' ? '0' : '0.5rem'};
        border-bottom: ${props => props.type === 'addressBook' || props.type === 'table' ? '0' : '1px solid #E9E9E9'};

        text-align: left;
        h3 {
            margin-bottom: 0px !important;
            min-height: auto !important;
        }
        span * {
            &:nth-child(1){
                margin-right: .3rem;
            }
        }
    }
    .body {
        padding: ${props => props.type === 'cards' ? '1rem 0 0' :
            props.type === 'taskBar' ? '0 8px' : props.type === 'table' ? '0' : "0.6rem"
        };
        height: calc(100% - 32px);
        .form-tooltip {
            width: 300px;
        }
    }
    @media only screen and (max-width: 420px) {
        height: auto !important;
    }
    @media only screen and (max-width: 1199px) {
        padding: ${props => props.type === 'taskBar' || props.type === 'table' ? '0' :
            props.type === 'addressBook' ? '0' : '1rem'};
        .body {
            padding: ${props => props.type === 'cards' ? '1rem 0 0' :
                props.type === 'taskBar' ? '0 8px' : '0.6rem 0 0'
            };
        }
    }
`;