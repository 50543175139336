import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REGEXP } from "../constants";
import { useTranslate } from "../contexts";
import { loadCountries } from "../redux/actions";
import { selectCountries } from "../redux/selectors";

export const usePickupLocationValidation = () => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();

    const countries = useSelector(selectCountries);

    useEffect(() => {
        if (countries.length === 0) dispatch(loadCountries(true));
    }, [dispatch, countries]);

    const getPickupErrors = useCallback((pickup) => {
        const errors = [];
        // if (!pickup.name) errors.push(translate('error.nameMissing'));
        if (!pickup.addr1) errors.push(translate('error.addr1Missing'));
        if (!pickup.city) errors.push(translate('error.cityMissing'));
        if (!pickup.country) errors.push(translate('error.countryMissing'));
        else {
            const ctr = countries.find(x => x.code === pickup.country);
            if (ctr) {
                if (ctr.post_code_required && !pickup.postal)
                    errors.push(translate('error.zipMissing'));
                else if (ctr.post_code_fmt_required && !RegExp(ctr.post_code_fmt_regexp).test(pickup.postal))
                    errors.push(translate('error.zipWrong'));
            }
        }

        // NAME
        if (
            (pickup.name && !REGEXP.ALPHA_NUM_NAME.test(pickup.name)) ||
            (pickup.save_as && !REGEXP.ALPHA_NUM_NAME.test(pickup.save_as)) 
        ) errors.push(translate('error.invalidChar'));

        // ADDRESSES
        if (
            (pickup.addr1 && !REGEXP.ALPHA_NUM.test(pickup.addr1)) ||
            (pickup.addr2 && !REGEXP.ALPHA_NUM.test(pickup.addr2)) ||
            (pickup.addr3 && !REGEXP.ALPHA_NUM.test(pickup.addr3)) ||
            (pickup.addr4 && !REGEXP.ALPHA_NUM.test(pickup.addr4))
        ) errors.push(translate('error.addressWrong'));

        return errors;
    }, [countries, translate]);

    const handleMaxLengthPostalCode = (val) => {
        //valid total bytes more than 25
        if ((new TextEncoder().encode(val)).length <= 25) return val
        else {
            //truncate word, take fist 25 bytes
            let encodeText = (new TextEncoder().encode(val)).slice(0, 25);
            //decode word again, convert to UTF-8
            let decodeText = new TextDecoder().decode(encodeText)
            return decodeText.substring(0, decodeText.length - 1).trim();
        }
    }

    const getBillingAddressErrors = useCallback((billingAddress) => {
        const errors = [];
        if (!billingAddress.rfc
            || (!billingAddress.city)
            || (!billingAddress.fullCompanyName)
            || (!billingAddress.postalCode.toString())
            || (!billingAddress.state)
            || (!billingAddress.streetAddress)
            || (!billingAddress.taxRegimen)) errors.push(translate('title.billingAddressTitle'));
        return errors;
    }, [countries, translate]);

    return {
        getPickupErrors,
        handleMaxLengthPostalCode,
        getBillingAddressErrors
    };
};