import { getRMASubLines, saveRMASubLines } from "../../../services/rmaService";
import { getShipmentPackages } from "../../../services/shipmentService";
import {
    SET_DEFAULT_OPTIONS, SET_SELECTION_ENTITY, ADD_ADDRESS_BOOK_ENTITY,
    UPDATE_ADDRESS_BOOK_ENTITY, REMOVE_SELECTION_ENTITY, SWITCH_CONTACTS, COPY_REQUESTOR,
    SAVE_SHIPMENT_DATA, RESET_RETURN_FLOW, RESET_PARTS, UPDATE_PACKAGE, SET_PACKAGES,
    SET_SUB_LINES, UPDATE_SUB_LINE, SET_RETURN_FLOW_PROP,
    SET_CREATE_PACKAGES_STEP, COPY_PICKUP_CONTACT, SET_BILLING_ADDRESS,
    SET_LINES_ERROR
} from "../actionTypes";


export const setReturnFlowProp = (propName, value) => ({ type: SET_RETURN_FLOW_PROP, payload: { propName, value } });

export const setCreatePackagesStep = (value) => ({ type: SET_CREATE_PACKAGES_STEP, payload: value });

export const setReturnFlowDefaultOptions = (options) => ({
    type: SET_DEFAULT_OPTIONS,
    payload: options
});

export const setReturnFlowPickupAddressId = (id) => ({
    type: SET_SELECTION_ENTITY,
    payload: { id, entity: 'pickup' }
});

export const setReturnFlowRequestorId = (id) => ({
    type: SET_SELECTION_ENTITY,
    payload: { id, entity: 'requestor' }
});

export const setReturnFlowPickupContactId = (id) => ({
    type: SET_SELECTION_ENTITY,
    payload: { id, entity: 'pickupContact' }
});

export const setReturnFlowAlternateContactId = (id) => ({
    type: SET_SELECTION_ENTITY,
    payload: { id, entity: 'alternateContact' }
});

export const setAlternateAsPickupContact = () => ({
    type: SWITCH_CONTACTS
});

export const setRequestorAsPickupContact = () => ({
    type: COPY_REQUESTOR
});

export const setPickupContactAsAlternateContact = () => ({
    type: COPY_PICKUP_CONTACT
});

export const resetReturnFlow = () => {
    sessionStorage.removeItem('savedSublines');
    return {
        type: RESET_RETURN_FLOW
    };
};

export const saveShipmentData = (shipmentId, additionalInfo, weight_uom, length_uom, radio) => ({
    type: SAVE_SHIPMENT_DATA,
    payload: { shipmentId, additionalInfo, weight_uom, length_uom, radio }
});

export const updatePickupAddress = (data) => ({
    type: UPDATE_ADDRESS_BOOK_ENTITY,
    payload: { data, entity: 'pickup' }
});

export const updateRequestor = (data) => ({
    type: UPDATE_ADDRESS_BOOK_ENTITY,
    payload: { data, entity: 'requestor' }
});

export const updatePickupContact = (data) => ({
    type: UPDATE_ADDRESS_BOOK_ENTITY,
    payload: { data, entity: 'pickupContact' }
});

export const updateAlternateContact = (data) => ({
    type: UPDATE_ADDRESS_BOOK_ENTITY,
    payload: { data, entity: 'alternateContact' }
});

export const addPickupAddress = (data) => {
    const tmp_idd = Date.now();
    return ({
        type: ADD_ADDRESS_BOOK_ENTITY,
        payload: { data: { ...data, idd: tmp_idd }, entity: 'pickup' }
    });
};

export const addRequestor = (data) => {
    const tmp_idd = Date.now();
    return ({
        type: ADD_ADDRESS_BOOK_ENTITY,
        payload: { data: { ...data, idd: tmp_idd }, entity: 'requestor' }
    });
};

export const addPickupContact = (data) => {
    const tmp_idd = Date.now();
    return ({
        type: ADD_ADDRESS_BOOK_ENTITY,
        payload: { data: { ...data, idd: tmp_idd }, entity: 'pickupContact' }
    });
};

export const addAlternateContact = (data) => {
    const tmp_idd = Date.now();
    return ({
        type: ADD_ADDRESS_BOOK_ENTITY,
        payload: { data: { ...data, idd: tmp_idd }, entity: 'alternateContact' }
    });
};

export const deletePickupAddress = () => ({ type: REMOVE_SELECTION_ENTITY, payload: { entity: 'pickup' } });
export const deleteRequestor = () => ({ type: REMOVE_SELECTION_ENTITY, payload: { entity: 'requestor' } });
export const deletePickupContact = () => ({ type: REMOVE_SELECTION_ENTITY, payload: { entity: 'pickupContact' } });
export const deleteAlternateContact = () => ({ type: REMOVE_SELECTION_ENTITY, payload: { entity: 'alternateContact' } });

export const resetParts = () => ({
    type: RESET_PARTS
});

export const updatePackage = (data) => ({
    type: UPDATE_PACKAGE,
    payload: data
});

export const setLinesError = (ids) => ({
    type: SET_LINES_ERROR,
    payload: ids
});

export const loadSubLines = (ship_id, rmas, used_only) => (dispatch) => {
    let sls = [];
    return Promise.all(
        rmas.map((rma) => getRMASubLines({
            sort: 'sub_line',
            dir: 'ASC',
            ship_id,
            rma
        }).then((response) => {
            if (response.res.success) {
                const sublines = used_only ? response.res.dtos.filter(x => x.package_id !== 0) : response.res.dtos.map(x => ({
                    idd: x.idd,
                    return_qty: x.return_qty,
                    serial: x.serial,
                    package_code: x.package_code,
                    package_id: null,
                    part_num: x.part_num,
                    rma_num: x.rma_num,
                    rma_line: x.rma_line
                }));
                sls.push(...sublines);
            }
            return;
        }))
    ).then(() => {
        dispatch({ type: SET_SUB_LINES, payload: sls });
        return sls;
    });
};

export const loadPackages = (ship_id, used_only) => (dispatch) => {
    return getShipmentPackages(used_only ? { ship_id, used_only } : { ship_id }).then((res) => {
        if (!res.res.success) return;
        const pks = used_only ? res.res.dtos : res.res.dtos.map(x => ({
            idd: x.idd,
            rma_num: x.rma_num,
            pack_code: x.pack_code,
            pack_name: x.pack_name
        }));
        dispatch({ type: SET_PACKAGES, payload: { packages: pks, usedOnly: used_only } });
        return;
    });
};

export const saveCreatedLocalPackages = (ship_id, sublines, weight_uom, length_uom, rmas, noReturn = false) => async (dispatch) => {
    let savedSublines = sessionStorage.getItem('savedSublines');
    savedSublines = savedSublines ? JSON.parse(savedSublines) : [];
    let sublinesToRemove = savedSublines.filter(x => !sublines.find(s => s.idd === x.idd));
    sublinesToRemove.forEach((x, i) => {
        x.return_qty = 0;
        x.package_code = '';
        x.package_id = 0;
        x.pkg_modified = true;
        x.seq = i + 1;
    });

    let sublinesToSave = sublines.map(x => ({
        idd: x.idd,
        return_qty: x.return_qty || 1,
        serial: x.serial, // !x.serial ? x.serials.join(',') : x.serial,
        package_code: x.package_code,
        package_id: x.package_id,
        type: 'ShipSubLine',
        seq: parseInt(x.package_code.split('_')[1]),
        pkg_modified: false
    }));
    const res = await saveRMASubLines({
        ship_id,
        json: JSON.stringify({ dtos: [...sublinesToSave, ...sublinesToRemove] }),
        weight_uom,
        length_uom
    });
    if (!res || !res.res.success || noReturn) return;
    sessionStorage.setItem('savedSublines', JSON.stringify(sublinesToSave));
    return dispatch(loadSubLines(ship_id, rmas, true)).then(() => dispatch(loadPackages(ship_id, true)));
};

export const updateShipmentPackages = (ship_id) => (dispatch) => {
    getShipmentPackages({ ship_id, used_only: true }).then((res) => {
        if (!res.res.success) return;
        dispatch({ type: SET_PACKAGES, payload: { packages: res.res.dtos, usedOnly: true } });
    });
};

export const updateSubLineProp = (id, property, value) => ({
    type: UPDATE_SUB_LINE,
    payload: { id, property, value }
});

export const saveBillingAddress = (data) => ({
    type: SET_BILLING_ADDRESS,
    payload: data
});