import Request from "../core/api/request";
import { URL_RMA, URL_GET_CFDI, URL_IS_RTF, URL_RTF_LINES, URL_GET_EOR, URL_GET_RTF_COMMERCIAL_INVOICE, URL_GET_VALIDATION_SN } from "../core/constants/URLS";
import { URL_RMA_VALIDATE_DC } from "../core/constants/URLS";
import { URL_RMA_DELIVERY_CHALLAN_SEARCH } from "../core/constants/URLS";
import { URL_RMA_DELIVERY_CHALLAN_DOWNLOAD } from "../core/constants/URLS";

export const getRMA = (data) => {
    return Request.postEncode({ url: `get/${URL_RMA}`, data });
};

export const getRMASubLines = (body) => {
    return Request.postEncode({ url: 'get/rma_sub_lines', data: body });
};

export const saveRMASubLines = (body) => {
    return Request.postEncode({ url: 'save/rma_sub_lines', data: body });
};

export const getCommercialLines = (body) => {
    return Request.postEncode({ url: 'get/commercial_inv_lanes', data: body })
}

export const getRMATypes = () => {
    return Request.getURIEncoded({ url: `get/RmaTypes?_dc=${Date.now()}` });
};

export const getValidAvailableDCRMA = (data) => {
    return Request.postEncode({ url: `get/${URL_RMA_VALIDATE_DC}`, data });
};

export const getRMADeliveryChallan = (data) => {
    return Request.postEncode({ url: `get/${URL_RMA_DELIVERY_CHALLAN_SEARCH}`, data });
};

export const getDownloadDC = (data) => {
    return Request.postEncode({ url: `get/${URL_RMA_DELIVERY_CHALLAN_DOWNLOAD}`, data });
};

export const saveFAReturnAddress = (data) => {
    return Request.postEncode({ url: `save/fa_ret_addr`, data});
};

export const getReturnAddress = (id, carrierServiceId) => {
    return Request.postEncode({ url: `get/return_address?id=${id}&serviceLevelId=${carrierServiceId}` })
}

export const validateBillingAddress = (data) => {
    return Request.postEncode({ url: URL_GET_CFDI, data})
}

export const isRTF = (rma) => { 
    return Request.postEncode({ url: `${URL_IS_RTF}?rma_num=${rma}` })
}

export const getRTFLines = (rma) => {
    return Request.postEncode({ url: `${URL_RTF_LINES}?rma_num=${rma}` })
}

export const getExportOfRecordsRTF = (rma) => {
    return Request.postEncode({ url: `${URL_GET_EOR}?rma_num=${rma}` })
}

export const getCommercialInvoiceRTF = (data) => {
    return Request.postURIENCODEDJSONSave({ url: `${URL_GET_RTF_COMMERCIAL_INVOICE}`,data })
}

export const getValidationSN = (data) => {
    return Request.postURIDECODEJSONSave({ url: URL_GET_VALIDATION_SN, data })
}



const jsonToSave = {
    ship_id: 10847981,
    pickup_id: 10762483,
    json: {
        "packages": [
            {
                "idd": 32034180,
                "ship_id": 10847981,
                "pack_code": "PACK01_001",
                "pack_name": "01_001",
                "tn": "",
                "weight": 1000,
                "weight_uom": 2,
                "width": 1.33,
                "height": 0.5,
                "depth": 1.5,
                "dim_uom": 4,
                "rma_num": "800611142",
                "used": true,
                "type": "ShipPackage",
                "ship_code": "US10847981",
                "serial": "FTX1910AHA9",
                "sn_lookup": "FTX1910AHA9"
            }
        ],
        "carrier": {
            "carrier_type": "1",
            "carrier": "",
            "carrier_code": "UPS",
            "carrier_service_code": "03",
            "carrier_service_id": 1,
            "carrier_service_level_id": 9911885,
            "service_level": 0,
            "carrier_profile_id": 8048660
        },
        "pickup": {
            "schedule_date": "2020-10-05",
            "schedule_time_type": "2", // 0,1,2
            "oversized_items": "0", // PALLETS #
            "location_id": "US-ME",
            "site_directions": ""
        },
        "instruct": { //CHECKBOXES
            "equip_pallet_jack": "",
            "equip_heavy_load": "",
            "generate_invoice": 'on',
            "is_fact_return": false //SIEMPRE FALSE **** PENDIENTE NOMBRE DE PROPIEDADES
        },
        "notifications": {
            "pickup_notify": ""
        },
        "userDocumentsRequired": false, //ONLY INDIA
        "paperless": false,
        "generate_sli": false,
        "generate_packing_lst": false,
        "is_fact_return": false
    },
    confirm: true
}

// PARA HABILITAR INPUT DE PALLETS SE NECESITA UNO DE LOS " CHECKBOX

// PICKUP SCHEDULE DEPENDE DEL CARRIER SELECCIONADO

// **** PENDIENTE COMERCIAL INVOICE property name

// GET CARRIERLIST regresa [] si recibe como ship_id el idd de rmaSingleSearchSave
// si recibe como ship_id el idd del resultado de saveShipment siempre regresa 5 carriers

