import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBaseRMAInfo, selectPickupCountry, selectReturnFlowOptions, selectSelectionsInfo } from "../redux/selectors";


export const useCountry = () => {
    const addressBook = useSelector(selectReturnFlowOptions);
    const selectionsInfo = useSelector(selectSelectionsInfo);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const pickupCountry = useSelector(selectPickupCountry);

    const getCountry = useMemo(() => ((addressBook && addressBook.pickup[0] && addressBook.pickup[0].country) ? 
    (addressBook && addressBook.pickup[0] && addressBook.pickup[0].country) : pickupCountry ?
    pickupCountry : (selectionsInfo && selectionsInfo.pickup && selectionsInfo.pickup.country) ?
    (selectionsInfo && selectionsInfo.pickup && selectionsInfo.pickup.country) : (rmaInfo && rmaInfo.country)),
    [addressBook, selectionsInfo, rmaInfo, pickupCountry]);

    return {
        getCountry
    };
};