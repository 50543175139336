import React, { Fragment, useState } from 'react';
import { useTranslate } from '../../core/contexts';
import { POWRIcon, POWRModal } from "../styledComponents";
import { Button, Link } from "../controls";
import { COLOR } from '../../core/constants/Colors';

export const HelpActOfExchangeModal = props => {
    const { translate } = useTranslate();
    const [instruction, setInstruction] = useState(false);

    return (
        <Fragment>
            <div onMouseOver={() => setInstruction(true)}>
                <Link className="linkComponentInstruction" >
                    <POWRIcon className="fas fa-info-circle mr-2" size="22px" color={COLOR.CISCO_BLUE} />
                    {translate('aux.instruction')}
                </Link>
            </div>
            <POWRModal
                show={instruction}
                title="Инструкция по заполнению/оформлению Акт Обмена/Возврата"
                onClose={() => setInstruction(false)}
            >
                <div className="content">
                    <h3>Акт Обмена/Возврата – это документ, обязательный для возврата неисправного оборудования Cisco.</h3>
                    <p>Необходимо заполнить вручную графы, которые не были заполнены автоматически из POWR. Заполненный Акт Обмена/Возврата необходимо распечатать на бланке компании в 2-х экземплярах, заверить подписью ответственного лица и печатью организации.</p>
                    <p>Примечание: 2 экземпляра Акт Обмена/Возврата автоматически распечатываются из POWR после сохранения данных и выведения заполненной формы на печать.</p>
                    <br />
                    <h3>Обязательные к заполнению графы:</h3>
                    <p>1. № - номер по порядку.</p>
                    <p>2. RМА № - номер РМА/заказа на возврат неисправного оборудования. *Заполняется автоматически из POWR.</p>
                    <p>3. Парт Номер – артикул возвращаемого неисправного оборудования. *Заполняется автоматически из POWR. При обнаружении неточностей в парт номере необходимо скорректировать его вручную. </p>
                    <p>4. Серийный номер – серийный номер возвращаемого неисправного оборудования.
                        *Заполняется автоматически из POWR. При отсутствии серийного номера/обнаружении неточностей необходимо добавить/скорректировать его вручную.</p>
                    <p>5. Кол-во </p>
                    <p>6. Описание оборудования – краткое описание возвращаемого неисправного оборудования. *Заполняется автоматически из POWR.</p>
                </div>
                <div className="toolbar">
                    <Button onClick={() => setInstruction(false)} >
                        {translate('button.close')}
                    </Button>
                </div>
            </POWRModal>
        </Fragment>
    )
}
