import React, { useState } from 'react';
import { useTranslate } from '../../core/contexts';
import { useAlert } from '../../core/hooks';
import { POWRIcon, POWRModal } from "../styledComponents";
import { Button, Link } from "../controls";
import { deleteSavedShipment, gtagService } from '../../services';
import { useDispatch } from 'react-redux';
import { deleteShipmentSearchResults, setShipmentSeleted } from '../../core/redux/actions';
import { Fragment } from 'react';
import { COLOR } from '../../core/constants';

export const DeleteSavedPickupModal = ({ idd, dropdown  }) => {
    const { translate } = useTranslate();
    const { showAlert } = useAlert();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const deleteSavedPickup = () => {
        deleteSavedShipment({ ship_id: idd }).then(response => {
            if (response.res.success) {
                showAlert({
                    id: 'deleteSavedShipment',
                    message: translate('message.savedShipmentDeleted'),
                    page: '*',
                    color:"green"
                });
                gtagService.event('Shipments', 'DeleteSavedShipment');
                dispatch(deleteShipmentSearchResults(idd));
                dispatch(setShipmentSeleted({}))
            } else {
                showAlert({
                    id: "errorCancelShipment",
                    message: 'There was an unexpected situation loading information, try again later',
                    page: "*",
                    color:"red"
                });
            }
        }).catch(error => {
            showAlert({
                id: "errorCancelShipment",
                message: 'There was an unexpected situation loading information, try again later',
                page: "*",
                color:"red"
            });
        });
    }

    return (
        <Fragment>
            <Link className={dropdown ? "dropDownOption" : "action-button"} onClick={() => setShowModal(!showModal)}>
                <POWRIcon className="fal fa-times icon" size="16px" color={dropdown ? COLOR.GREY : COLOR.CISCO_BLUE} />
                {translate('button.deleteSavedPickup')}
            </Link>
            <POWRModal
                show={showModal}
                size="small"
                title={translate('title.deleteSavedShipment')}
                onClose={() => setShowModal(!showModal)}
                alignActions="center"
            >
                <div className="content">
                    {translate('message.deleteSavedShipment')}
                </div>
                <div className="toolbar" >
                    <Button onClick={() => setShowModal(!showModal)} type="2" >
                        {translate('button.no')}
                    </Button>
                    <Button
                        onClick={() => deleteSavedPickup()}
                    >
                        {translate('button.yes')}
                    </Button>
                </div>
            </POWRModal>
        </Fragment>
    );
}