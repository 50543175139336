import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setRMASearchParameters, setRMASearching } from '../core/redux/actions';
import { selectRMASearchResults, selectRMASearching } from '../core/redux/selectors';
import { useTranslate } from '../core/contexts';
import { getCountry, gtagService } from '../services';
import { Input, Select, InputCalendar, Info } from './controls';
import { POWRButton } from './styledComponents';
import { useParams, useLocation } from 'react-router-dom';
import { REGEXP } from '../core/constants';

export default props => {
    const dispatch = useDispatch();
    const params = useParams();
    const { translate } = useTranslate();
    const dateFormat = sessionStorage.getItem('dateFormat');
    
    const [error, setError] = useState(false);
    const [gettingParams, setGettingParams] = useState(true);
    const [searching, setSearching] = useState(true);
    const [_inputdpDateBegin, setInputdpDateBegin] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [_inputdpDateEnd, setInputdpDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const [_inputtxtRMANumber, setInputtxtRMANumber] = useState("");
    const [_haveRMANumber, setHaveRMANumber] = useState(false);
    const [_inputddlRMACategory, setInputddlRMACategory] = useState("");
    const [_inputtxtRMACategory, setInputtxtRMACategory] = useState("");
    const [_inputtxtCustomerPO, setInputtxtCustomerPO] = useState("");
    const [_haveCustomerPO, setHaveCustomerPO] = useState(false);
    const [_inputtxtToCustomer, setInputtxtToCustomer] = useState("");
    const [_haveToCustomer, setHaveToCustomer] = useState(false);
    const [_inputddlCountry, setInputddlCountry] = useState("");
    const [_inputtxtCountry, setInputtxtCountry] = useState("");
    const [_inputOptionsCountry, setInputOptionsCountry] = useState([]);
    const [_inputFA, setInputFA] = useState('');

    const rmas = useSelector(selectRMASearchResults);
    const isSearching = useSelector(selectRMASearching);

    //(GPT-3 automatically generated documentation) It creates a dropdown list of options for the user to select from.
    const _inputOptionsRMACategory = [
        { code: 'A', text: translate('aux.all') },
        { code: 'P', text: translate('shipmentsStatus.product') },
        { code: 'S', text: translate('shipmentsStatus.service') }
    ];

    useEffect(() => {
        setGettingParams(true);
        setParams();
    }, [params]);

    useEffect(() => {
        setError(rmas ? rmas.length < 1 : false);
    }, [rmas]);

    const getCountryOps = () => {
        if (searching) {
            const carrierBody = {
                _dc: 16,
                code: ""
            }
            /*(GPT-3 automatically generated documentation)
            It gets the country from the carrier body, and then it creates a new object with the country name and the description of the country. 
            Then it pushes the new object into an array.
            */
            getCountry().then(res => {
                let dtos = [];
                res.res.dtos.forEach(element => {
                    let textOb = { text: `${element.descr} (${element.code})` };
                    let newObj = Object.assign(element, textOb);
                    dtos.push(newObj);
                });
                setSearching(false);
                setInputOptionsCountry(dtos);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.search) {
            setInputtxtRMANumber(props.location.state.search);
            setParams();
        } else {
            setParams();
        }
        getCountryOps();
        if (gettingParams) dispatch(setRMASearching(true));
    }, [props.location, gettingParams]);
   
    useEffect(() => {
        setInputtxtRMANumber("");
        setInputtxtRMACategory("");
        setInputddlRMACategory("");
        setInputtxtToCustomer("");
        setInputtxtCustomerPO("");
        setInputddlCountry("");
        setInputtxtCountry("");
        setInputFA("");
        setInputdpDateBegin(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        setInputdpDateEnd(moment().format('YYYY-MM-DD'));
    }, [params.type])
    
    const location = useLocation();

    //getting parameters from URL
    const URLparams = new URLSearchParams(window.location.search);
    const rmaSearch = URLparams.get('rma');

    //test get rmas from local storage
    const rmaSeachFromLocalStorage = localStorage.getItem('scmParams');

    //handle redirect page if have required parameters in URL
    useEffect(() => {
        if (rmaSearch || JSON.parse(rmaSeachFromLocalStorage)?.rma) {
            let searchRMAParam = rmaSearch || JSON.parse(rmaSeachFromLocalStorage).rma;
            handleRefreshFromURL(searchRMAParam);
        }
    }, [location])


    //function to redirect page to rmas list filter by rma number getting from url params
    const handleRefreshFromURL = (rmaSearch) => {
        gtagService.event('DashboardWidgets', 'SearchRMAFromURL');

        //set input with rma from URL
        setInputtxtRMANumber(rmaSearch);
        setHaveRMANumber(true);

        setError(rmaSearch.length <= 2); //validate rma lenght
        if (gettingParams) {
            //(GPT-3 automatically generated documentation) It creates a JSON object with the parameters that will be sent to the server.
            const parameters = {
                param_rma_or_tradein: rmaSearch.replace(' ', ','),
                param_country: _inputddlCountry,
                param_po: _inputtxtCustomerPO,
                param_customer_name: _inputtxtToCustomer,
                param_fa_case_num: _inputFA,
                param_rma_cat: _inputddlRMACategory,
                param_date_from: _inputdpDateBegin,
                param_date_to: _inputdpDateEnd,
                isReturnFa: params.type === 'FA',
                sort: "rma_type_code",
                dir: 'ASC'
            };
            //remove local storage param when process is finished
            localStorage.removeItem('scmParams');
            setGettingParams(false);
            dispatch(setRMASearchParameters(parameters));
        }
    };

    const setParams = () => {
        if (gettingParams) {
            //(GPT-3 automatically generated documentation) It creates a JSON object with the parameters that will be sent to the server.
            const parameters = {
                param_rma_or_tradein: _inputtxtRMANumber.replace(' ',','),
                param_country: _inputddlCountry,
                param_po: _inputtxtCustomerPO,
                param_customer_name: _inputtxtToCustomer,
                param_fa_case_num: _inputFA,
                param_rma_cat: _inputddlRMACategory,
                param_date_from: _inputdpDateBegin,
                param_date_to: _inputdpDateEnd,
                isReturnFa: params.type === 'FA',
                sort: "rma_type_code",
                dir: 'ASC'
            };
            setGettingParams(false);
            dispatch(setRMASearchParameters(parameters));
        }
    }

    const handleInputtxtRMANumber = (e) => {
        if (REGEXP.RMAS.test(String(e))) {
            setHaveRMANumber(false);
            setInputtxtRMANumber(e);
        }
    }

    const handleInputddlRMACategory = (e) => {
        let select = _inputOptionsRMACategory.filter(x => x.code === e);
        let text = select[0] && select[0].text ? select[0].text : '';
        setInputddlRMACategory(e);
        setInputtxtRMACategory(text);
    }

    const handleInputtxtCustomerPO = (e) => {
        setHaveCustomerPO(false);
        setInputtxtCustomerPO(e);
    }

    const handleInputtxtToCustomer = (e) => {
        setHaveToCustomer(false);
        setInputtxtToCustomer(e);
    }

    const handleInputddlCountry = (e) => {
        let select = _inputOptionsCountry.filter(x => x.code === e);
        let text = select[0] && select[0].text ? select[0].text : '';
        setInputddlCountry(e);
        setInputtxtCountry(text);
    }

    const handleDateBegin = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) <= new Date(_inputdpDateEnd) || _inputdpDateEnd === "" || v === "") setInputdpDateBegin(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) <= new Date(_inputdpDateEnd) || _inputdpDateEnd === "")) setInputdpDateBegin(newDate);
        } else setInputdpDateBegin(v);
    }

    const handleDateEnd = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) >= new Date(_inputdpDateBegin) || _inputdpDateBegin === "" || v === "") setInputdpDateEnd(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) >= new Date(_inputdpDateBegin) || _inputdpDateBegin === "")) setInputdpDateEnd(newDate);
        } else setInputdpDateEnd(v);
    }

    const handleSearch = function () {
        //we could use this object to display on google analytics
        const parameters = {
            param_rma_or_tradein: _inputtxtRMANumber.replace(' ',','),
            param_country: _inputddlCountry,
            param_po: _inputtxtCustomerPO,
            param_customer_name: _inputtxtToCustomer,
            param_fa_case_num: _inputFA,
            param_rma_cat: _inputddlRMACategory,
            param_date_from: _inputdpDateBegin,
            param_date_to: _inputdpDateEnd,
            isReturnFa: params.type === 'FA',
            sort: "rma_type_code",
            dir: 'ASC'
        };
        let gaParams = "";
        if (_inputtxtRMANumber.replace(' ', ',')) {
            gaParams = `rmaTradeIn:${_inputtxtRMANumber.replace(' ', ',')}, `;
        }
        if (_inputddlCountry) {
            gaParams = gaParams + `country:${_inputtxtCountry}, `;
        }
        if (_inputtxtCustomerPO) {
            gaParams = gaParams + `customerPO:${_inputtxtCustomerPO}, `;
        }
        if (_inputtxtToCustomer) {
            gaParams = gaParams + `shipToCustomer:${_inputtxtToCustomer}, `;
        }
        if (_inputddlRMACategory) {
            gaParams = gaParams + `rmaCategory:${_inputtxtRMACategory}, `;
        }
        
        
        gaParams = gaParams + `from:${_inputdpDateBegin} to:${_inputdpDateEnd}`;
        

        //option 1 send properties as string
        gtagService.event('RMAs Search', 'Click Search Button', gaParams);

        //add evento every click search button
        //gtagService.event('RMAs Search', 'Click Search Button');
        
        //add json as string
        //gtagService.event('RMAs Search', 'Click Search Button', JSON.stringify(parameters));

        setHaveRMANumber(true);
        setHaveCustomerPO(true);
        setHaveToCustomer(true);
        setGettingParams(true);
    }

    return (
        <div className="rmaControlBar">
            <div className="rmaControlBarDescription">
                <h3>{translate(`subtitle.${params.type === 'FA' ? 'fa' : 'rmas'}Filter`)}</h3>
                <p>{translate('message.forMultipleRMA')}</p>
            </div>
            <div className="row rmaControlBarFields">
                <div className="col-12">
                    <div className="inputWithInfo">
                        <div className="infoCircle">
                            <Info>{translate('message.forMultipleRMATNSR')}</Info>
                        </div>
                        <Input
                            /*(GPT-3 automatically generated documentation)
                            It creates a form with a text input field and a button. 
                            When the button is clicked, the value of the input field is sent to the server.
                            */
                            value={_inputtxtRMANumber}
                            onChange={e => handleInputtxtRMANumber(e.target.value)}
                            onEnter={handleSearch}
                            className="inputSpace"
                            label={`${translate('title.rma')} / ${translate('gridColumns.tradeInQuote')} / ${translate('gridColumns.srN')}`}
                            placeholder={`${translate('title.rma')} / ${translate('gridColumns.tradeInQuote')} / ${translate('gridColumns.srN')}`}
                            error={_inputtxtRMANumber !== "" && _haveRMANumber && !isSearching && error}
                            errorMessage={translate('error.rmaError')}
                            clear
                        />
                    </div>
                </div>
                {
                    params.type === 'FA' &&
                    <div className="col-12">
                        <Input
                            value={_inputFA}
                            onChange={e => setInputFA(e.target.value)}
                            onEnter={handleSearch}
                            className="inputSpace"
                            label={translate('formLabel.fa')}
                            placeholder={translate('placeholder.fa')} />
                    </div>
                }
                <div className="col-12">
                    <Select
                        value={_inputddlRMACategory}
                        text={_inputtxtRMACategory}
                        //(GPT-3 automatically generated documentation) It creates a select element with the name "rmaCat" and the value of the selected category.
                        onChange={handleInputddlRMACategory}
                        options={_inputOptionsRMACategory}
                        className="inputSpace"
                        label={translate('formLabel.rmaCat')}
                        placeholder={translate('formLabel.rmaCat')}
                        singleSelection
                    />
                </div>
                <div className="col-12">
                    <Input
                        //(GPT-3 automatically generated documentation) It creates a text input field for the customer PO number.
                        value={_inputtxtCustomerPO}
                        onChange={e => handleInputtxtCustomerPO(e.target.value)}
                        onEnter={handleSearch}
                        className="inputSpace"
                        label={translate('gridColumns.customerPO')}
                        placeholder={translate('gridColumns.customerPO')}
                        error={_inputtxtCustomerPO !== "" && _haveCustomerPO && !isSearching && error}
                        errorMessage={translate('error.customerPOError')}
                        clear
                    />
                </div>
                {/* (GPT-3 automatically generated documentation) 
                 It creates a new div with the class "inputWithInfo" and adds a div with the class "infoCircle" inside it. 
                 The "infoCircle" div contains the text "message.singleRMA" and the input field. 
                 The input field is bound to the value of the input field in the form. 
                 The input field is also bound to the handleInputtxtToCustomer function. 
                 The input field has the class "inputSpace" and the label "shipToCustomer". 
                 The input field has the class "inputSpace" and the placeholder "shipToCustomer". 
                 The input field has the class "inputSpace" and the error message "shipToCustomerError". */}
                <div className="col-12">
                    <div className="inputWithInfo">
                        <div className="infoCircle">
                            <Info>{translate('message.singleRMA')}</Info>
                        </div>
                        <Input
                            value={_inputtxtToCustomer}
                            onChange={e => handleInputtxtToCustomer(e.target.value)}
                            onEnter={handleSearch}
                            className="inputSpace"
                            label={translate('gridColumns.shipToCustomer')}
                            placeholder={translate('gridColumns.shipToCustomer')}
                            error={_inputtxtToCustomer !== "" && _haveToCustomer && !isSearching && error}
                            errorMessage={translate('error.shipToCustomerError')}
                            clear
                        />
                    </div>
                </div>
                {/* (GPT-3 automatically generated documentation)
                  It creates a select element with the name "country" and the value of the input element "country" from the form. */}
                <div className="col-12">
                    <Select
                        value={_inputddlCountry}
                        text={_inputtxtCountry}
                        onChange={handleInputddlCountry}
                        options={_inputOptionsCountry}
                        className="inputSpace"
                        label={translate('formLabel.shipToCountry')}
                        placeholder={translate('formLabel.shipToCountry')}
                        singleSelection
                    />
                </div>
                {/* (GPT-3 automatically generated documentation)
                It creates a calendar that allows the user to select a date range. */}
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <InputCalendar
                                text={_inputdpDateBegin !== "" ? moment(_inputdpDateBegin).format(dateFormat) : ""}
                                value={_inputdpDateBegin !== "" ? moment(_inputdpDateBegin).format("DD/MM/YY") : ""}
                                onBlur={(v, m) => handleDateBegin(v, m)}
                                maxDate={moment(_inputdpDateEnd).toDate()}
                                label={translate('formLabel.createdFrom')}
                                placeholder={dateFormat}
                                className="mb-3 pr-1"
                                clear
                                paddingInput={"pl-1"}
                            />
                        </div>
                        <div className="col-12 col-lg-6">
                            <InputCalendar
                                text={_inputdpDateEnd !== "" ? moment(_inputdpDateEnd).format(dateFormat) : ""}
                                value={_inputdpDateEnd !== "" ? moment(_inputdpDateEnd).format("DD/MM/YY") : ""}
                                onBlur={(v, m) => handleDateEnd(v, m)}
                                minDate={moment(_inputdpDateBegin).toDate()}
                                label={translate('formLabel.to')}
                                placeholder={dateFormat}
                                className="mb-3 pr-1"
                                clear
                                paddingInput={"pl-1"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {/* <div className="bottomButton"> */}
                <POWRButton
                    disabled={isSearching}
                    onClick={handleSearch}
                    className="w-100"
                >
                    {translate('button.search')}
                </POWRButton>
            </div>
        </div>
    );
}