import { ADD_CONTACT, REMOVE_CONTACT, SET_CONTACTS, UPDATE_CONTACT } from "../actionTypes";

const initialState = {
    contacts: [],
    selectedContact: null
};

export const contacts = (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_CONTACTS: return { ...state, contacts: payload };
        case ADD_CONTACT: return { ...state, contacts: [payload, ...state.contacts] };
        case UPDATE_CONTACT: return { ...state, contacts: state.contacts.map((x) => x.idd === payload.idd ? payload : x) };
        case REMOVE_CONTACT: return { ...state, contacts: state.contacts.filter(x => x.idd !== payload) };
        default:
            return state;
    }
};
