import React, { useContext } from "react";
import styled from "styled-components";
import { AppWrapperContext } from "../../AppShell/AppShell.react";
import { Navigation } from "./Navigation.react";
import Icon from "../../controls/icon";

const Version = styled.div`
  background-color: #004766;
  transition: width ${({ theme: { time } }) => time[0]},
    height ${({ theme: { time } }) => time[0]};
  padding: 0 ${({ theme: { gap } }) => gap[2]};

  display: flex;
  align-items: center;

  span {
    transition: opacity ${({ theme: { time } }) => time[0]};
    color: ${({ theme: { colors } }) => colors.white};
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    font-style: oblique;
    white-space: nowrap;
  }
`;

const SideNavigationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  z-index: 1000;

  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s;

  box-shadow: 0 0 4px 0 #00000040;

  overflow: hidden;

  .bars-height {
    background-color: #004766;
    height: 64px;
  }

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.xs}) {
    height: ${({ isOpen }) => (isOpen ? "auto" : "64px")};
    box-shadow: 0 0 0 0 #00000040;
  }

  ${Version} {
    width: ${({ isOpen, theme: { side } }) =>
    isOpen ? side.widthOpen.rem : side.width.rem};

    height: ${({ isOpen, theme: { side } }) => (isOpen ? "48px" : 0)};
    span {
      opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    }
  }
`;

const ToggleButton = styled.button`
  position: relative;
  background-color: #004766;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;
  flex-shrink: 0;

  // @TODO Implement Theme provider
  height: 64px;
  width: 80px;

  z-index: 1001;

  transition: background-color 0.3s;
`;

export const SideNavigation = props => {
  const { isSidePanelOpen, toggleSidePanel } = useContext(AppWrapperContext);

  return (
    <SideNavigationWrapper isOpen={isSidePanelOpen}>
      <ToggleButton onClick={() => toggleSidePanel(!isSidePanelOpen)}>
        <Icon content="bars" height="20px" width="20px" />
      </ToggleButton>
      <Navigation />
      <Version>
        {/* <span>version 1.0.0.0</span> PENDING */}
      </Version>
    </SideNavigationWrapper>
  );
};
