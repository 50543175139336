// Internal routes
export const URL_LOGIN = 'login/';

//BASE URLS
export const BASE_URL_SUBDOMAIN = process.env.PUBLIC_URL;

//APIs URLs
export const URL_ADD_SECURITY = 'add/security';
export const URL_GET_SECURITY = 'get/security';
export const URL_SAVE_SECURITY = 'save/security';
export const URL_SHIPTREE = 'powr3shiptree';
export const URL_COUNTRY = 'country';
export const URL_CARRIER_LIST = 'carrier_list';
export const URL_EXCEL_GENERATOR = '/excelGenerator';
export const API_LOGOUT = 'cmd/security/logout';
export const URL_ADMINISTRATION = '/admin/adminOptions.jsp';
export const URL_SHIPMENT_DETAILS = 'get/shipdetails';
export const URL_CHECKRECEIPT = 'shipmentReceipt?shipId=';
export const URL_PRINTLABLES = 'label?shipId=';
export const URL_COMERCIALINVOICE = 'commercialInvoice?shipId=';
export const URL_GETCARRIERINTERFACE = 'get/interface?id=';
export const URL_FAULTDETECTIONFORM = 'fdf';
export const URL_FAULTDETECTIONFORMSAVE = 'save/fdf';
export const URL_ACTOFEXCHANGEUKRANIAN = 'save/PocUkrainian';
export const URL_HELP = 'help';
export const FILE_NAME_HELPFDR = 'instr-fdf.html';
export const FILE_NAME_HELPAOEUK = 'instr-poc.html';
export const URL_CANCELSHIPMENT = 'cmd/shipment/cancel';
export const URL_REENABLEDENIEDPICKUP = 'cmd/shipment/downgrade2draft';
export const URL_DELETESHIPMENT = 'del/shipment?ship_id=';
export const URL_GETCARRIERPROFILE = 'get/CarrierProfile';
export const URL_GETRESCHEDULE = 'get/reschedule';
export const URL_POSTRESCHEDULE = 'cmd/shipment/reschedule';
export const URL_GETPICKUPLOCATIONTIMEZONE = 'get/PickupLocationTimezone';
export const URL_GETSHIPCARRIERSERVICES = 'get/ship_carrier_services';
export const URL_SAVE_CWB = 'cmd/pickup/save_cwb';
export const URL_RELEASELINES = 'cmd/shipment/releaselines';
export const URL_RMA = 'rma';
export const URL_RMA_SEARCH = 'rma_search';
export const URL_RMA_VALIDATE_DC = 'validation_dc_generated';
export const URL_RMA_DELIVERY_CHALLAN_SEARCH = 'rma_delivery_challan_search';
export const URL_RMA_DELIVERY_CHALLAN_DOWNLOAD = 'indiaDocuments';
export const URL_CONTACT_INFO = 'contact_info';
export const URL_CONFIG_PARAM = 'ConfigParam';
export const URL_SAVESHIPMENT = 'save/shipment';
export const URL_SAVESHIPMENT_DOCUMENTS = 'save/shipmentDocuments';
export const URL_SAVESHIPMENT_RLSP = 'save/car_edit';
export const URL_SAVEPICKUP = 'save/pickup';
export const URL_STD_NOTIFY = 'get/std_notify'
export const URL_LABEL = 'label';
export const URL_INDIA_DOC = 'indiaDocuments?rmaNum=';
export const URL_DHL_ELP_COUNTRIES = 'get/dhl_elp_countries';
export const URL_GET_WAYBILL = "get/cwbsearch";
export const URL_CWB_SCHEDULE = "get/cwb_schedule";
export const URL_CARRIER_PROFILE_CWB = "get/CarrierProfileCWB";
export const URL_GET_GLOBAL_MESSAGE = "get/messages";
export const URL_SAVE_BILLING_ADDRESS = "save/sat_address";
export const URL_GET_BILLING_ADDRESS = "get/sat_address?ship_id=";
export const URL_GET_CFDI = "get/cfdi";
export const URL_IS_RTF = "get/rtf";
export const URL_RTF_LINES = "get/rma_lines";
export const URL_GET_EOR = "get/eor";
export const URL_PRINT_RTF = "commercialInvoiceRTF";
export const URL_GET_RTF_COMMERCIAL_INVOICE = "get/commercialInvoiceRTF"
export const URL_GET_VALIDATION_SN = "get/validation_sn";
export const URL_PKGS_LIST_EMEA = "PackingListEmeaServlet";
export const URL_CI_EMEA = "commercialInvoiceEmea"
//External URLs
export const BASE_URL_LOGOUT_CISCO = 'https://www.cisco.com/autho/logout.html';
export const BASE_URL_DOWNLOAD = 'https://www.cisco-returns-test.com/';
export const BASE_URL_DOWNLOAD_LABEL = process.env.REACT_APP_APIURL + '/label?shipId=';

export const GA_CUSTOMURL_ANALTICS = 'TODO';
export const RMA_LOGIN = '/rma_login.jsp';
//export const GA_


export const BASE_URL = process.env.REACT_APP_APPURL;
export const BASE_URL_API = process.env.REACT_APP_APIURL;