import Request from "../core/api/request";
import { URL_CARRIER_LIST, URL_GETSHIPCARRIERSERVICES, URL_GETCARRIERPROFILE } from "../core/constants/URLS";

export const getCarrierList = (data) => {
    return Request.postEncode({ url: `get/${URL_CARRIER_LIST}`, data });
};

export const getCarrierServices = (data) => {
    return Request.postEncode({ url: URL_GETSHIPCARRIERSERVICES, data });
}

export const getShipCarriers = (data) => {
    return Request.postEncode({ url: `get/ship_carrier_services`, data });
};

export const getEventCarriers = () => {
    return Request.getURIEncoded({ url: `get/eventCarrier?_dc=${Date.now()}` });
};

export const getCarrierProfile = (data) => {
    return Request.postEncode({ url: `${URL_GETCARRIERPROFILE}`,data});
};

/*
Example : /get/carrier_list?_dc=16&code=APAC-DOA-PICKUP
Example all countries: /get/carrier_list?_dc=16&code=
_dc is any number at this moment
"dtos": [{idd:4076109,type:'Carrier',iconCls:'icoCarrier',"code":"APAC-DOA-PICKUP","text":"APAC - DOA pickup"},
*/



