import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../core/contexts';
import { FullPage } from '../components/AppShell/partials/FullPage.react';
import { InfoSection, POWRButton, POWRIcon } from '../components/styledComponents';
import { getSecurity, saveSecurity } from '../services';
import { capitalize } from '../core/helpers';
import { Select } from '../components/controls';
import { useAlert } from "../core/hooks";
import { formatDate } from '../core/helpers';
import { COLOR } from '../core/constants/Colors';

const Settings = () => {

    const { translate } = useTranslate();
    const history = useHistory();
    const { showAlert } = useAlert();
    const { langCode, languages, dispatchI18n } = useTranslate();

    const [userContent, setUserContent] = useState(null);
    const [additionalContent, setAdditionalContent] = useState(null);
    const [userWeightUom, setWeightUom] = useState('');
    const [userLengthUom, setLengthUom] = useState('');
    const [userDateFormat, setDateFormat] = useState('');
    const [userTimeFormat, setTimeFormat] = useState('');
    const [saveTimeFormat, setSaveTimeFormat] = useState('');
    const [saveDateFormat, setSaveDateFormat] = useState('');
    const [saveLengthFormat, setSaveLengthFormat] = useState('');
    const [saveWeightFormat, setSaveWeightFormat] = useState('');
    const [lang, setLang] = useState('en');

    let timeFormat = [
        { code: 'H:i:s', text: "hh:mm:ss (24-hour)" },
        { code: 'h:i:s A', text: 'hh:mm:ss AM' }
    ]

    let dateFormat = [
        { code: 'd/m/Y', text: "dd/mm/yyyy" },
        { code: 'm/d/Y', text: 'mm/dd/yyyy' },
        { code: 'Y/m/d', text: "yyyy/mm/dd" },
        { code: 'd-M-Y', text: 'dd-MMM-yyyy' }
    ]

    let lengthUom = [
        { code: 1, text: "m" },
        { code: 2, text: 'in' },
        { code: 3, text: "cm" },
        { code: 4, text: 'ft' }
    ]

    const weightUom = [
        { code: 1, text: "Kg" },
        { code: 2, text: 'Lbs' }
    ]


    useEffect(() => {
        preLoadUserInfo();
    }, []);

    useEffect(() => {
        if (lang !== langCode) setLang(langCode)
    }, [langCode]);

    const preLoadUserInfo = () => {
        getSecurity().then(resp => {
            if (resp.status === 200) {
                let data = resp.res.dtos[0];
                if (data) {
                    const fullName = `${capitalize(data.fname)} ${capitalize(data.lname)}`;
                    let userContent = {
                        title: fullName,
                        info: [fullName,
                            data.street + ' ' + data.province + ' ' + data.postal,
                            data.city,
                            data.cisco_email,
                            data.phone],
                        errors: [],
                        idd: data.idd
                    }
                    setUserContent(userContent);
                    let additionalUserContact = [
                        { id: 1, title: 'formLabel.settingsBill', content: data.bill_to_id ? data.bill_to_id : '0' },
                        { id: 2, title: 'formLabel.settingsContNum', content: data.contract_num ? data.contract_num : '0' },
                        { id: 3, title: 'formLabel.settingsOldCon', content: data.old_contract ? data.old_contract : '0' },
                        { id: 4, title: 'formLabel.settingsAccLvl', content: data.access_level ? data.access_level : '0' },
                        { id: 5, title: 'formLabel.settingsContAdd', content: data.contract_additional ? data.contract_additional : '0' },
                        { id: 6, title: 'formLabel.settingsCancelCont', content: data.cancelled_contracts ? data.cancelled_contracts : '0' }
                    ];
                    setAdditionalContent(additionalUserContact);
                    handleChangeTime(data.time_format);
                    handleChangeDate(data.date_format);
                    handleChangeLenght(data.length_uom);
                    handleChangeWeight(data.weight_uom);
                } else {
                    setUserContent(null);
                    setAdditionalContent(null);
                }
            } else { console.log('Error Response'); }
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangeTime = (v) => {      
        const time = timeFormat.find(l => l.code === v);
        if(time){
            setTimeFormat(time ? time.text : '');
            setSaveTimeFormat(time.code? time.code:'');
        }
        
    }
    const handleChangeDate = (v) => {
        const date = dateFormat.find(l => l.code === v);
        if(date){
            setDateFormat(date ? date.text : '');
            setSaveDateFormat(date.code);
        }
    }
    const handleChangeLenght = (v) => {
        const length = lengthUom.find(l => l.code === v);
        if (length) {
            setLengthUom(length ? length.text : '');
            setSaveLengthFormat(length.code);
        }
    }
    const handleChangeWeight = (v) => {
        const weitght = weightUom.find(l => l.code === v);
        if (weitght) {
            setWeightUom(weitght ? weitght.text : '');
            setSaveWeightFormat(weitght.code);
        }
    }

    const searchRMAFromDocument = () => {
        return null;
    }

    const saveUserSettings = () => {
        let data = {
            fName: userContent.fName,
            street: userContent.street,
            province: userContent.province,
            mail: userContent.mail,
            bill_toid: userContent.bill_toid,
            contract_Number: userContent.contract_Number,
            old_Contract: userContent.old_Contract,
            lName: userContent.lName,
            city: userContent.city,
            postal: userContent.postal,
            phone: userContent.phone,
            access_Level: userContent.access_Level,
            contract_Additional: userContent.contract_Additional,
            cancelled_Contracts: userContent.cancelled_Contracts,
            cisco_user_id: userContent.cisco_user_id,

            locale: lang,
            weight_uom: saveWeightFormat,
            length_uom: saveLengthFormat,
            date_format: saveDateFormat,
            time_format: saveTimeFormat
        }
        saveSecurity(data).then(resp => {
            if (resp.status === 200) {
                let data = resp.res.dtos[0];
                if (data.status === 1) {
                    dispatchI18n({ type: 'setLanguage', payload: lang });
                    sessionStorage.setItem('dateFormat', formatDate(saveDateFormat));
                    sessionStorage.setItem('timeFormat', formatDate(saveTimeFormat));
                    showAlert({
                        id: "settingsOk",
                        message: `${translate('message.successSettingsSaved')}`,
                        page: '*',
                        color: 'green'
                    });
                } else {
                    showAlert({
                        id: "settingsFail",
                        message: `${translate('error.settingSavedFail')}`,
                        page: '*'
                    });
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <FullPage className="main-settings">
            <div className="col-12 col-xl-9 p-xl-0">
                <div className="main-container">
                    <h1>
                        {translate('title.settings')}
                    </h1>
                    <p className="setting-header">{translate('message.settingsDescription')}</p>
                    <div>
                        <div className="pt-0 pt-xl-3">
                            <InfoSection
                                title={translate('title.userProfile')}
                                buttonSymbol={<POWRIcon className="fas fa-plus select" color={COLOR.CISCO_BLUE} />}
                                onButtonClick={searchRMAFromDocument()}>
                                <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="settings-card">
                                            <div className="row py-3 px-2 m-0">
                                                <div className="col-12 col-xl-4 m-0 align-self-center">
                                                    <h4 className="d-block">{userContent && userContent.title}</h4>
                                                    {userContent && userContent.info.map((item, i) => (
                                                        <label key={i} className="d-block m-0">{item}</label>
                                                    ))
                                                    }
                                                </div>
                                                <div className="col-12 col-xl-4 m-0">
                                                    <Select
                                                        value={saveWeightFormat}
                                                        text={userWeightUom}
                                                        onChange={handleChangeWeight}
                                                        options={weightUom}
                                                        singleSelection={true}
                                                        label={translate('formLabel.weightUOM')}
                                                        placeholder={translate('placeholder.weightUOM')}
                                                        hideClear
                                                    />
                                                    <Select
                                                        value={saveLengthFormat}
                                                        text={userLengthUom}
                                                        onChange={handleChangeLenght}
                                                        options={lengthUom}
                                                        singleSelection={true}
                                                        label={translate('formLabel.lengthUOM')}
                                                        placeholder={translate('placeholder.lengthUOM')}
                                                        hideClear
                                                    />
                                                </div>
                                                <div className="col-12 col-xl-4 m-0">
                                                    <Select
                                                        value={saveDateFormat}
                                                        text={userDateFormat}
                                                        onChange={handleChangeDate}
                                                        options={dateFormat}
                                                        singleSelection={true}
                                                        label={translate('formLabel.dateFormat')}
                                                        placeholder={translate('placeholder.dateFormat')}
                                                        hideClear
                                                    />
                                                    <Select
                                                        value={saveTimeFormat}
                                                        text={userTimeFormat}
                                                        onChange={handleChangeTime}
                                                        options={timeFormat}
                                                        singleSelection={true}
                                                        label={translate('formLabel.timeFormat')}
                                                        placeholder={translate('placeholder.timeFormat')}
                                                        hideClear
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InfoSection>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-3 p-xl-0 py-2 py-xl-0">
                <div className="toolbar-settings">
                    <div className="toolbar-container">
                        <Select
                            value={lang}
                            onChange={(val) => setLang(val)}
                            options={languages.map(x => ({ text: translate(`lang.${x}`), code: x }))}
                            singleSelection={true}
                            label={translate('formLabel.Language')}
                            placeholder={translate('placeholder.Language')}
                            hideClear />

                        <h3>{translate('title.additionalInfo')}</h3>

                        <div className="additional-settings-card">
                            {additionalContent &&
                                additionalContent.map((item, i) => (
                                    <label key={`info-row${i}`} className="d-block m-0 truncateText">  {`${translate(item.title)}:  ${item.content}`}</label>
                                ))
                            }
                        </div>
                    </div>
                    <div className="toolbar-buttons">
                        <POWRButton className="cancel" color="secondary" onClick={() => history.goBack()}> {translate('button.back')} </POWRButton>
                        <POWRButton className="save" type="submit" onClick={() => saveUserSettings()}> {translate('button.save')}</POWRButton>
                    </div>
                </div>
            </div>
        </FullPage>
    )
}
export default Settings;