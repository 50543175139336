import styled from "styled-components";

export const WizardPanelWrapper = styled.aside`
  width: 325px;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: ${({ theme: { colors } }) => colors.white};
  position: relative;
  height: calc(100vh - 64px);
  z-index: 1;

  @media (max-width: ${({ theme: { breakpoint } }) => breakpoint.size.md}) {
    width: 100%;
    height: auto;
    box-shadow: none;
    background-color: transparent;
    padding: 0 8px 8px;
  }
`;
