import React, { memo } from 'react';
import { useField } from 'formik';
import { POWRIcon } from '../styledComponents';
import { useTranslate } from '../../core/contexts';

export const TextInput = memo(({ id, control: Editor, label, icon, isRequired, customIcon, errorInfo, disabled, numericKeys, ...props }) => {
    const [field, meta] = useField(props);
    const { translate } = useTranslate();
    return (
        <div className={`inputControlWrapper ${props.inputClass}`}>
            <div className={`inputControl ${(meta.touched && meta.error) ? 'inputControlError' : ''}`}>
                {
                    label &&
                    <label htmlFor={id || props.name}>
                        {translate(label)}
                        {isRequired && <span className="text-danger ml-1">*</span>}
                    </label>
                }
                <div className="inputContainer">
                    {
                        Editor ?
                            <Editor {...field} {...props} />
                            :
                            (disabled ?
                                <input className="text" autoComplete="off" disabled {...field} {...props} /> : numericKeys ? <>                             
                                    <input className="text d-md-none" type="number" autoComplete="off" pattern="[0-9]*" {...field} {...props} /> 
                                    <input className="text d-none d-md-block" autoComplete="off" disabled={disabled} {...field} {...props} />
                                </> :
                                <input className="text" autoComplete="off" disabled={disabled} {...field} {...props} />
                            )

                    }
                    {
                        icon &&
                        <POWRIcon {...icon} />
                    }
                    {customIcon || null}
                </div>
            </div>
            {
                (field.value !== "" && meta.error) &&
                <label className="errorMessage">{translate(meta.error)}{errorInfo || ''}</label>
            }
        </div>
    );
}, (prev, next) => prev.control === next.control &&
    prev.name === next.name &&
    prev.isRequired === next.isRequired &&
    prev.customIcon === next.customIcon &&
    prev.disabled === next.disabled);
