import React, { Fragment, useState } from "react";
import { Card } from "../../components";
import { useTranslate } from "../../core/contexts";
import { BASE_URL_DOWNLOAD_LABEL } from "../../core/constants/URLS";
import { useHistory } from "react-router-dom";
import { loadShipments, setShipmentsProp } from "../../core/redux/actions";
import { useDispatch } from "react-redux";
import { DOWNLOAD } from "../../core/constants/ROUTES";
import { gtagService } from "../../services";

const DownloadLabels = () => {
    const { translate } = useTranslate();
    const history = useHistory();
    const dispatch = useDispatch();
    const [rma, setRMA] = useState("");
    const [error, setError] = useState("");

    const handleDownload = () => {
        if (rma.length < 3) setError('error.min');
        else {
            gtagService.event('DashboardWidgets', 'DownloadLabels');
            dispatch(loadShipments(rma)).then((shipments) => {
                if (!shipments.length) setError('error.noShipmentsFound');
                else {
                    setError('');
                    if (shipments.length === 1)
                        window.open(`${BASE_URL_DOWNLOAD_LABEL}${shipments[0].idd}`, "popup", 'width=600,height=600');
                    else {
                        dispatch(setShipmentsProp('rmaSearchText', rma));
                        history.push(DOWNLOAD);
                    }
                }
            });
        } 
    };

    return (
        <Fragment>
            <Card
                icon="fal fa-file-download"
                title={translate("title.downloadLabels")}
                value={rma}
                setValue={setRMA}
                onClick={handleDownload}
                button={translate("button.download")}
                validateRMA={true}
                error={translate(error)} >
                {translate("subtitle.downloadLabelsInfo")}
            </Card>
        </Fragment>
    );
};

export default DownloadLabels;