import {
    SET_SHIPMENTS,
    SET_SHIPMENTS_SEARCH_PARAMETERS,
    SET_SHIPMENTS_SEARCH_RESULTS,
    SET_SHIPMENTS_SEARCHING,
    SET_SHIPMENTS_RELOAD,
    SET_SHIPMENTS_SELECTED,
    SET_SHIPMENTS_DETAIL,
    DELETE_SHIPMENT_SEARCH_RESULTS,
    SET_SHIPMENTS_PROP
} from "../actionTypes";

const initialState = {
    rmaSearchText: '',
    shipments: [],
    shipmentsSearchParameters: null,
    shipmentsSearchResults: [],
    shipmentsSearching: false,
    shipmentsReload: null,
    shipmentSeleted: {},
    shipmentDetail: {}
};

export const shipments = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_SHIPMENTS: return {
            ...state,
            shipments: payload
        };
        case SET_SHIPMENTS_PROP: return {
            ...state,
            [payload.propName]: payload.value
        }
        case SET_SHIPMENTS_SEARCH_PARAMETERS: return {
            ...state,
            shipmentsSearchParameters: payload
        };
        case SET_SHIPMENTS_SEARCH_RESULTS: return {
            ...state,
            shipmentsSearchResults: payload
        };
        case SET_SHIPMENTS_SEARCHING: return {
            ...state,
            shipmentsSearching: payload
        };
        case SET_SHIPMENTS_RELOAD: return {
            ...state,
            shipmentsReload: payload
        };
        case SET_SHIPMENTS_SELECTED: return {
            ...state,
            shipmentSeleted: payload
        };
        case SET_SHIPMENTS_DETAIL: return {
            ...state,
            shipmentDetail: payload
        };
        case DELETE_SHIPMENT_SEARCH_RESULTS:
            return {
                ...state,
                shipmentsSearchResults: state.shipmentsSearchResults.filter(x => x.idd !== payload)
            }
        default: return state;
    }
};
