import moment from 'moment';
import { useDispatch } from "react-redux";
import { useTranslate } from '../contexts';
import { getConfigParams, getSecurity, gtagService, removeSecurity } from "../../services";
import { formatDate, browserDate } from "../helpers";
import { SET_USER_SETTINGS_PROP } from "../redux/actionTypes";
import { useConfigParams } from './useConfigParams';

export const useAuth = () => {
    const dispatch = useDispatch();
    const { langCode } = useTranslate();
    const { getLogoutLink } = useConfigParams();
    const sessionStorageConfigParamsName = 'configParams';

    const authUser = async () => {
        const resp = await getSecurity();
        let security = null;
        if (resp && resp.res && resp.res.success) {
            let data = resp.res.dtos[0];

            const response = await getConfigParams();
            if (response.status === 200 && response.res) {
                sessionStorage.setItem(sessionStorageConfigParamsName, JSON.stringify(response.res));
                
                //for TEST add session storage item to get parameters from URL when user is not logged in
                //localStorage.setItem('scmParams', JSON.stringify({"rmas":'801782922'}));
                //sessionStorage.setItem("scmParams", ["801782614","23311"]);
                //localStorage.setItem('scmParams', JSON.stringify({ "rma":'801783024'}));
            }
            security = handleSecurityData(data);

        }
        return security;
    };

    const handleSecurityData = (data) => {
        let getAt = moment().toDate();
        let expiresAt = moment().add(process.env.REACT_APP_EXPIRATIONTIMEWARNING, 'milliseconds').toDate();
        const configP = JSON.parse((sessionStorage.getItem('configParams') || '{}').replace(/\s+/g, ""));

        let confDtos = (configP && configP.dtos) ? configP.dtos : [];
        const documentAccess = confDtos.find(x => x.name == 'indiaDocumentAccess.enabled');
        const documentAccessAllowed = (documentAccess && documentAccess.value === 'true') || false;
        const waybill = confDtos.find(x => x.name == 'cwbSchedule.enabled');
        const waybillAllowed = (waybill && waybill.value === 'true') || false;
        const addressClassify = confDtos.find(x => x.name == 'india.address_classify.enabled');
        const addressClassifyEnabled = (addressClassify && addressClassify.value === 'true') || false;

        let security = {
            ...data,
            documentAccessAllowed,
            waybillAllowed,
            addressClassifyEnabled
        };

        let GAKey = process.env.REACT_APP_GAKEY //OG22701419-394 Initialize Google Analytics key from ENV variable
        
        gtagService.init(GAKey);

        dispatch({
            type: SET_USER_SETTINGS_PROP,
            payload: {
                propName: 'security',
                value: security
            }
        });

        sessionStorage.setItem('securityData', JSON.stringify({ ...data, expiresAt, getAt }));
        sessionStorage.setItem('dateFormat', data.date_format ? formatDate(data.date_format) : browserDate().date);
        sessionStorage.setItem('timeFormat', data.time_format ? formatDate(data.time_format) : browserDate().time);

        moment.locale(langCode);

        return security;
    }

    const logout = async () => {
        const currentLang = localStorage.getItem('powr3_lang_code');
        localStorage.clear();
        sessionStorage.clear();
        await removeSecurity();
        if (currentLang) localStorage.setItem('powr3_lang_code', currentLang);
        window.location = getLogoutLink();
    };

    return {
        authUser,
        logout,
        handleSecurityData
    };
};