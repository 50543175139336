import { useAlert } from './useAlert';
import { getGloblalMessage } from '../../services/index';
import { useTranslate } from '../../core/contexts';

export const useGlobalMessage = () => {
    const { showAlert } = useAlert();
    const { langCode } = useTranslate();

    const showPageMessage = (params, page) => {
        getGloblalMessage({ ...params, language: langCode }).then(response => {
            if (response.res && response.res.dtos) {
                response.res.dtos.forEach(message => {
                    showAlert({
                        id: new Date(),
                        message: message.message,
                        page: page,
                        color: 'yellow',
                        isGlobalMessage: true
                    });
                });
            }

        }).catch(err => {
            console.log(err);

        });
    }

    return {
        showPageMessage
    };
};