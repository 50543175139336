import {
    SET_WAYBILL,
    SET_WAYBILL_SEARCH_PARAMETERS,
    SET_WAYBILL_SEARCH_RESULTS,
    SET_WAYBILL_SEARCHING
 } from '../actionTypes';

const initialState = {
    waybillInfo: {},
    waybillSearchParameters: null,
    waybillSearchResults: [],
    waybillSearching: false
};

export const waybill = (state = initialState, action) => {
    switch (action.type) {
        case SET_WAYBILL:
            return {
                ...action.payload
            };
        case SET_WAYBILL_SEARCH_PARAMETERS: return {
            ...state,
            waybillSearchParameters: action.payload
        };
        case SET_WAYBILL_SEARCH_RESULTS: return {
            ...state,
            waybillSearchResults: action.payload
        };
        case SET_WAYBILL_SEARCHING: return {
            ...state,
            waybillSearching: action.payload
        };
        default:
            return state;
    }
};
