import * as Yup from 'yup';
import { REGEXP } from '../constants';

export const PickupLocationSchema = Yup.object().shape({
    save_as:
        Yup.string()
            .when('saveAtAddressBook', {
                is: (saveAtAddressBook) => !!saveAtAddressBook,
                then: Yup.string().required(),
                otherwise: Yup.string()
            }),
    name:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM_NAME, 'error.invalidChar')
            .required('error.required'),
    addr1:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    addr2:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
    addr3:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
    addr4:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar'),
    country:
        Yup.string()
            .required('error.required'),
    province:
        Yup.string().when('isStateListEnable',{
            is: true,            
            then: Yup.string().matches(REGEXP.ALPHA_NUM, 'error.invalidChar').required('error.required'),
            otherwise: Yup.string().matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
        })
       
        ,
    city:
        Yup.string()
            .matches(REGEXP.ALPHA_NUM, 'error.invalidChar')
            .required('error.required'),
    zipRequired:
        Yup.boolean(),
    zipFormat:
        Yup.string(),
    postal:
        Yup.string()
        .matches(REGEXP.POSTAL_CODE, 'error.invalidChar')
            .when('zipFormat', {
                is: (zipFormat) => !zipFormat,
                then: Yup.string()
                    .when('zipRequired', {
                        is: true,
                        then: Yup.string().required('error.required'),
                        otherwise: Yup.string()
                    }),
                    
                otherwise: Yup.string()
                    .required('error.required')
                    .test('error.invalidPostal', 'error.invalidPostal', function (value) {
                        return RegExp(this.resolve(Yup.ref('zipFormat'))).test(value);
                    }),
            }),
    gstin:
        Yup.string(),
    saveAtAddressBook:
        Yup.boolean(),
    isStateListEnable:
        Yup.boolean(),
        EIN:Yup.string().matches(REGEXP.NUMBERS, 'error.invalidChar')
});