import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Link } from '../controls';
import { selectCategoryCode, selectSchedulePickup, selectSelectionsInfo, selectShipmentId, selectUserInfo, selectBaseRMAInfo, selectIsFA } from '../../core/redux/selectors';
import { useTranslate } from '../../core/contexts';
import { sendMails } from '../../services/labelService';
import { useHistory } from 'react-router-dom';
import { POWRIcon, POWRModal } from '../styledComponents';
import { CommercialInvoiceForm } from '../forms/commercialInvoiceForm';
import { FORM_TYPE, REGEXP } from '../../core/constants';
import { useCommercialInvoice, useGlobalMessage, useConfigParams } from '../../core/hooks';
import { DASHBOARD } from '../../core/constants/ROUTES';
import PrintCard from '../printCard';
import { COLOR } from '../../core/constants/Colors';
import { setLoader } from '../../core/redux/actions';

const Success = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { allowedTheaters } = useConfigParams();
    const [mails, setMails] = useState(['']);
    const [validateInputs, setValidateInputs] = useState(true);

    const shipmentId = useSelector(selectShipmentId);
    const savedSchedule = useSelector(selectSchedulePickup);
    const selectionsInfo = useSelector(selectSelectionsInfo);
    const categoryCode = useSelector(selectCategoryCode);
    const userInfo = useSelector(selectUserInfo);
    const rmaInfo = useSelector(selectBaseRMAInfo);
    const isFA = useSelector(selectIsFA);
    const { showPageMessage } = useGlobalMessage(); // Global Messages

    const [shipCode, setShipCode] = useState('');
    const [serviceCode, setServiceCode] = useState('');
    const [rmaFinished, setRMAFinished] = useState(false);

    useEffect(() => {
        if(allowedTheaters.indexOf(rmaInfo.theater_code) > -1 && !isFA)dispatch(setLoader(true))
        if (selectionsInfo && shipmentId) setShipCode(`${selectionsInfo.pickup.country}${shipmentId}`)
        if(savedSchedule && !savedSchedule.instruct.generate_invoice)
            dispatch(setLoader(false))
    }, [selectionsInfo, shipmentId])

    useEffect(() => {
        if (savedSchedule) setServiceCode(savedSchedule.carrier.service_code)
    }, [savedSchedule])

    const shipment = {
        ship_code: shipCode,
        rma_category_code: categoryCode,
        carrier_service_code: serviceCode
    };

    useEffect(() => {
        let params = {
            module: 'GCT_Step5',
            country: rmaInfo.country,
            rmaCategory: categoryCode
        }
        showPageMessage(params, '/return');
        if(shipCode && savedSchedule.instruct.generate_invoice && allowedTheaters.indexOf(rmaInfo.theater_code) > -1 && !isFA){
            handleCommercialInvoice(shipmentId, shipCode, FORM_TYPE.COMMERCIAL_INVOICE, false, false, true)
        } 
        else
            dispatch(setLoader(false))
            
     }, [shipCode]);

    const {
        handleCommercialInvoice,
        handleFaultDetection,
        handleActExchange,
        print,
        modalTemplate,
        setModalTemplate,
        showFaultDetection,
        showActExchange
    } = useCommercialInvoice({ shipment });

    const handleChange = (e, i) => {
        let value = e.target.value;
        let values = [...mails];

        if (REGEXP.EMAIL_NOTI.test(e.target.value)) {
            values[i] = { email: value, error: false };

        }
        else {
            if (values.length === 1 && value === "")
                values[i] = { email: value, error: false };
            else
                values[i] = { email: value, error: true };
        }
        setMails(values);
        setValidateInputs(values.filter(a => a.error).length == 0);
    }

    const addNewMail = function () {
        let values = [...mails, { email: "", error: false }];
        setMails(values);
    }

    const handleRemove = function (i) {
        let values = [...mails];

        values.splice(i, 1);
        setMails(values);
        if (values.length === 1 && values[0].email === "")
            values[0] = { email: "", error: false };

        setValidateInputs(values.filter(a => a.error).length == 0);
    }

    const handleSubmit = function () {
        let m = "";
        if (mails && mails.length)
            mails.forEach(element => {
                m += element.email + ","
            });
        let body = {
            shipId: shipmentId,
            mails: m,
            sendMail: true,
            isFinish: true,
            emailType: 'labels'
        }
        if (mails.length > 0)
            sendMails(body).then((response) => {
                if (userInfo.rmaLogin) setRMAFinished(true);
                else history.push({
                    pathname: DASHBOARD
                })
            });

    }

    return (
        <Fragment>
            <div className="success pt-3">
                <PrintCard text={translate("aux.printLabels")} handleClick={() => print('label', shipmentId)} icon="print" />
                {(savedSchedule && savedSchedule.instruct) ?
                    <Fragment>
                        {savedSchedule.instruct.generate_invoice &&
                            <PrintCard
                                icon="file-alt"
                                text={translate('aux.commercialInvoice')}
                                handleClick={() => handleCommercialInvoice(shipmentId, shipCode, FORM_TYPE.COMMERCIAL_INVOICE)}
                            />
                        }
                        {/* TODO: available until FACTs return is ready */}
                        {savedSchedule.instruct.generate_packing_lst &&
                            <PrintCard
                                icon="file-alt"
                                text={translate('aux.packageList')}
                                handleClick={() => handleCommercialInvoice(shipmentId, shipCode, FORM_TYPE.PACKING_LIST)}
                            />
                        }
                        {savedSchedule.instruct.generate_sli &&
                            <PrintCard
                                icon="file-alt"
                                text={translate('aux.printSli')}
                                handleClick={() => handleCommercialInvoice(shipmentId, shipCode, FORM_TYPE.SLI)}
                            />
                        }
                    </Fragment>
                    : null
                }
                {showFaultDetection &&
                    <PrintCard
                        icon="file-alt"
                        text={translate('aux.printFaultDetection')}
                        handleClick={() => handleFaultDetection(shipmentId, shipCode)}
                    />
                }
                {showActExchange &&
                    <PrintCard
                        icon="file-alt"
                        text={translate('aux.printActExchange')}
                        handleClick={() => handleActExchange(shipmentId, shipCode)}
                    />
                }
                <div className="infoContainer">
                    <div className="infoHead mb-3">
                        <h3>{translate('title.emailNotification')}</h3>
                    </div>
                    <div className="infoBody mt-0 mb-4">
                        <h4>{translate('subtitle.labels')}</h4>
                        {
                            mails.map((item, i) => {
                                return <div className="row" key={i}>
                                    <div className="col-12">
                                        <Input
                                            error={mails[i].error}
                                            errorMessage={translate("error.email")}
                                            value={mails[i].email}
                                            onChange={(e) => handleChange(e, i)}
                                            label={translate("formLabel.email")}
                                            className="mb-0"
                                            placeholder={translate("placeholder.addEmail")}
                                        />
                                        {mails.length > 1 && <div className="iconPosition" onClick={() => handleRemove(i)}>
                                            <POWRIcon className="far fa-trash-alt mt-4" size="16px" color={COLOR.CISCO_BLUE} />
                                        </div>}
                                    </div>
                                </div>
                            })
                        }
                        <div className="text-right">
                            <Link onClick={addNewMail} disabled={!(mails[mails.length-1] && mails[mails.length-1].email && !mails[mails.length-1].error)} >
                                + {translate("title.additionalEmail")}
                            </Link>
                        </div>
                    </div>
                    {/* <div className="infoBody mt-0 mb-4">
                        <h4>{translate('aux.actOfExchange')}</h4>
                        {
                            mails.map((item, i) => {
                                return <div className="row" key={i}>
                                    <div className="col-12">
                                        <Input
                                            error={mails[i].error}
                                            errorMessage={translate("error.email")}
                                            value={mails[i].email}
                                            onChange={(e) => handleChange(e, i)}
                                            label="Email"
                                            className="mb-0"
                                            placeholder={translate("placeholder.addEmail")}
                                        />
                                        <div className="iconPosition" onClick={() => handleRemove(i)}>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        <div className="text-right">
                            <Link onClick={addNewMail} >+ {translate("title.additionalEmail")}</Link>
                        </div>
                    </div>
                    <div className="infoBody mt-0 mb-4">
                        <h4>{translate('title.faultDetectionForm')}</h4>
                        {
                            mails.map((item, i) => {
                                return <div className="row" key={i}>
                                    <div className="col-12">
                                        <Input
                                            error={mails[i].error}
                                            errorMessage={translate("error.email")}
                                            value={mails[i].email}
                                            onChange={(e) => handleChange(e, i)}
                                            label="Email"
                                            className="mb-0"
                                            placeholder={translate("placeholder.addEmail")}
                                        />
                                        <div className="iconPosition" onClick={() => handleRemove(i)}>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        <div className="text-right">
                            <Link onClick={addNewMail} >+ {translate("title.additionalEmail")}</Link>
                        </div>
                    </div> */}
                </div>
                {modalTemplate != null &&
                    <POWRModal
                        show={true}
                        title={translate(`title.${modalTemplate.type}Form`)}
                        size="long"
                        onClose={() => setModalTemplate(null)}
                        hideClose={modalTemplate.fromSummary}
                    >
                        <CommercialInvoiceForm
                            onClose={() => setModalTemplate(null)}
                            lines={modalTemplate.data}
                            print={() => print(modalTemplate.type, modalTemplate.shipId)}
                            type={modalTemplate.type}
                            shipCode={modalTemplate.shipCode}
                            fromSummary={modalTemplate.fromSummary}
                        />
                    </POWRModal>
                }
            </div>
            <div className='btnContainer'>
                {!rmaFinished ? 
                <Button disabled={!validateInputs} className="widthBtn" onClick={handleSubmit} >
                    {translate('button.finish')}
                </Button>
                :
                <div className="finishedMessage">
                    <POWRIcon className="fas fa-check-circle mb-1" color={COLOR.GREEN} size="16px" />
                    <p className="ml-3">{translate('message.processFinished')}</p>
                </div>
                }
            </div>
        </Fragment>
    );
}

export default Success;