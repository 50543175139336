import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../core/contexts';
import { useAlert, useConfigParams } from '../../core/hooks';
import { POWRModal } from "../styledComponents";
import { Button } from "../controls";
import { cancelPickup, gtagService } from '../../services';
import { SEARCH_SHIPMENTS } from '../../core/constants/ROUTES';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../core/redux/actions';

export default props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const { getContactUsLink } = useConfigParams();
    const { showAlert, removeAlerts } = useAlert();
    const { show, onClose,carrierProfile } = props;
console.log(carrierProfile.carrier_code)
    const contactUsLink = getContactUsLink();

    const handleGo = () => {
        history.push(SEARCH_SHIPMENTS);
    }

    const cancelShipment = async () => {
        removeAlerts();
        onClose();
        dispatch(setLoader(true));
        await cancelPickup({ ship_id: props.idd }).then(response => {
            if (response.status === 200) {
                if (!props.noRedirect) handleGo();
                else if (props.onReload) props.onReload();
                if (response.res.dtos[0].message) {
                    if (carrierProfile.carrier_code === "DHL-APAC-EXPRESS") {
                        showAlert({
                            id: 'postReschedule',
                            message: (response.res.dtos && response.res.dtos[0].message) ? response.res.dtos[0].message : translate('message.successCancel'),
                            page: '/shipments',
                            color: 'orange'
                        });
                    } else {
                        showAlert({
                            id: "successCancel",
                            message: <>{translate('message.errorCancelShipment')} ({response.res.dtos[0].message}) {translate('message.contactCarrier')} <a className="link" target={"_blank"} href={contactUsLink} rel="noopener noreferrer">{translate("title.assetRecoveryTeam")}</a> {translate('message.toArrange')}</>,
                            page: "/shipments",
                            color: "yellow"
                        });
                    }

                } else {
                    if (carrierProfile.carrier_code === "DHL-APAC-EXPRESS") {
                        showAlert({
                            id: 'postReschedule',
                            message: (response.res.dtos && response.res.dtos[0].message) ? response.res.dtos[0].message : translate('message.successCancel'),
                            page: '/shipments',
                            color: 'orange'
                        });
                    } else {
                        showAlert({
                            id: "successCancel",
                            message: translate('message.successCancel'),
                            page: "/shipments",
                            color: "green"
                        });
                    }
                }
                gtagService.event('Shipments', 'CancelShipment');
            }
        }).catch(error => {
            showAlert({
                id: "errorCancelShipment",
                message: translate('message.unexpectedSituation'),
                page: "*",
                color: "red"
            });
            console.log('Error', error);
        });
        dispatch(setLoader(false));
    }

    return (
        <POWRModal
            show={show}
            size="small"
            title={translate('title.cancelShipment')}
            onClose={() => onClose()}
            alignActions="center"
        >
            <div className="content">
                {translate('message.cancelShipment')}
            </div>
            <div className="toolbar" >
                <Button onClick={() => onClose()} type="2" >
                    {translate('button.no')}
                </Button>
                <Button
                    onClick={() => cancelShipment()}
                >
                    {translate('button.yes')}
                </Button>
            </div>
        </POWRModal>
    );
}