import { createSelector } from "reselect";
import { LOGINTYPE_RMA_EMAIL } from '../../../core/constants';

const selectUserSettings = (state) => state.userSettings;

export const selectUserInfo = createSelector(
    [selectUserSettings],
    (settings) => settings.security ? ({
        login: settings.security.login_type,
        rmaLogin: settings.security.login_type === LOGINTYPE_RMA_EMAIL,
        rma: settings.security.login_rma_num,
        name: settings.security.fname,
        cisco_user_id: settings.security.cisco_user_id,
        mail: settings.security.cisco_email ? settings.security.cisco_email : settings.security.login_email_id,
        role: settings.security.role_name,
        factReturnsAllowed: settings.security.allow_fa_returns,
        documentAccessAllowed: settings.security.documentAccessAllowed,
        waybillAllowed: settings.security.waybillAllowed,
        releaseLinesAllowed: settings.security.allow_rma_line_release,
        addressClassifyEnabled: settings.security.addressClassifyEnabled,
        allow_upload_file_rlsp:settings.security.allow_upload_file_rlsp
    }) : null
);

export const selectSecurity = createSelector(
    [selectUserSettings],
    (settings) => settings.security
);