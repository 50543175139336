import React, { Fragment, useEffect, useState } from "react";
import { Card } from "../../components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslate } from "../../core/contexts";
import { useDispatch } from "react-redux";
import { useRMAValidation, useAlert, useConfigParams } from "../../core/hooks";
import { getContactInfo, getRMA, getValidAvailableDCRMA, gtagService, saveRMA } from "../../services";
import { CONFIG_PARAMS, COUNTRY, STORAGE } from "../../core/constants";
import { resetReturnFlow, resetRMAInfo, setRMAsURLParam } from "../../core/redux/actions";
import { IndiaRMAModal, LabelOnlyModal } from "../../components/modals";
import { Link } from '../../components/controls';
import { RETURN, SEARCH_RMA, DOCUMENTATION } from "../../core/constants/ROUTES";

const RequestReturn = ({ clearStorage, isFA, test }) => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { isValidRMADashboard } = useRMAValidation();
    const history = useHistory();
    const { showAlert, removeAlert, removeAlerts } = useAlert();
    const { getFaqLink } = useConfigParams();

    const [paramsSaved, setParamsSaved] = useState(null);
    const [error, setError] = useState("");
    const [rma, setRMA] = useState("");
    const [faNum, setFaNum] = useState("");
    const [showLabelOnlyModal, setShowLabelOnlyModal] = useState(false);
    const [showIndiaModal, setShowIndiaModal] = useState(false);
    const [labelOnlyValue, setLabelOnlyValue] = useState(false);
    const [DCGenerated, setDCGenerated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [is, setIs] = useState(false);


    const faqLink = getFaqLink();
    const location = useLocation();

     //getting parameters from URL
    const params = new URLSearchParams(window.location.search);
    const rmas = params.get('rmas');
    const faRMAS = params.get('faRMAS');

    //test get rmas from local storage
    const rmasFromLocalStorage = localStorage.getItem('scmParams');
    
    //handle redirect page if have required parameters in URL, also validate if is FA 
    useEffect(() => {
        if (!isFA && (rmas || JSON.parse(rmasFromLocalStorage)?.rmas) ){
            let rmasParam = rmas || JSON.parse(rmasFromLocalStorage).rmas;
            setRMA(rmasParam)
            handleRefreshFromURL(rmasParam);
            dispatch(setRMAsURLParam(rmasParam.split(',')));
        }
      
     }, [location])

    //function to redirect page to return flow 
    const handleRefreshFromURL = (rmas = null, faRMAS = null) => {
        if (isLoading) return;
        gtagService.event('DashboardWidgets', 'RequestReturn', faRMAS !== null ? 'FACase' : 'RMA');
        setIsLoading(true);
        if ((!faRMAS && rmas.length >= 3) || (faRMAS && faRMAS.length >= 3)) {
            setError("");
            const parameters = {
                param_rma_or_tradein: faRMAS === null ? rmas.replace(' ', ',') : '',
                param_fa_case_num: faRMAS === null ? "" : faRMAS,
                isSimpleSearch: true,
                isReturnFa: faRMAS !== null
            };
            searchRMAFromURL(parameters, rmas, faRMAS);
        }
        else {
            clearStorage();
            setError(translate('error.pleaseMin3'));
            setIsLoading(false);
        }
    }


const searchRMAFromURL = (parameters, rmasParam, faRMASParam) => {
    const isFAURL = faRMASParam !== null;

    getRMA(parameters).then(response => {   
        let faRMAS = response.res.dtos.map(x => x.rma_num[0]).join(',');
        if (isFAURL) {
            setRMA(faRMAS);
        }

        //Validate all the possibles stoppers after start the return flow with the current RMAs 
        if (isValidRMADashboard(response, isFAURL).isValid) {
            let dtos = response.res.dtos;
           
            let country = dtos[0].country[0];
            const rmas = isFAURL ? faRMASParam.split(',') : rmasParam.replace(' ', ',').split(',');
            if (dtos.length > 0) {
                if(dtos.length !== rmas.length){
                    let rmasNum = dtos.map(x => x.rma_num[0])
                    let invalidRMAs = rmas.filter(x => !rmasNum.includes(x));
                    showAlert({
                        id: 'invalidRmas',
                        message: <Fragment>{'invalid rmas: ' + invalidRMAs.filter(x => x !== "")}</Fragment>,
                        color: 'yellow',
                        page:'/'
                    });
                    if (isFA) setFaNum(rmasNum.join(',')) 
                    else setRMA(rmasNum.join(','));
                    
                    setIsLoading(false);
                    return;
                }
                

                const paramsSave = {
                    ship_id: 0,
                    rmas: rmas.length > 1 ? rmas.sort((a, b) => a > b) : [rmas[0]],
                    simple: !isFAURL,
                    isReturnFa: isFAURL
                };

                sessionStorage.setItem(STORAGE.SEARCH_NAME, JSON.stringify(dtos));
                if (country === COUNTRY.INDIA && DCGenerated) {
                    validateDeliveryChallan(paramsSave);
                } else if (!isFAURL && labelOnlyValue && (country === COUNTRY.US || country === COUNTRY.CANADA)) {
                    setParamsSaved(paramsSave);
                    handleLabelOnly(paramsSave, true);
                }
                else if (!isFAURL && labelOnlyValue && (country !== COUNTRY.US && country !== COUNTRY.CANADA)) {
                    removeAlert("labelOnly");
                    showAlert({ id: 'labelOnly', page: '/home', message: translate('message.labelOnlyMessage'), color: "yellow" });
                    setIsLoading(false);
                }
                else {
                    handleSaveRMA(paramsSave);
                }
            }
            else {
                clearStorage();
                setError('RMA ' + rma + translate('message.wasNotFound'));
                setIsLoading(false);
            }
        }
        else setIsLoading(false);
    }).catch((e) => {
        clearStorage();
        setError('RMA ' + rma + translate('message.wasNotFound'));
        setIsLoading(false);
    });
}

    useEffect(() => {
        sessionStorage.setItem(STORAGE.LABEL_ONLY, JSON.stringify(false));
        validateDCGenerated();
    }, []);

    const validateDCGenerated = () => {
        let ssConfigParams = sessionStorage.getItem(STORAGE.CONFIG_PARAMS);
        let config_params = ssConfigParams ? JSON.parse(ssConfigParams.replace(/\s+/g, "")) : null;
        if (config_params && config_params.dtos) {
            let DC_Generated = config_params.dtos.find(x => x.name === CONFIG_PARAMS.DC_GENERATED);
            setDCGenerated(DC_Generated.value === "true" ? true : false);
        }
    }

    const handleSearch = () => {
        if (isLoading) return;
        gtagService.event('DashboardWidgets', 'RequestReturn', isFA ? 'FACase' : 'RMA');
        setIsLoading(true);
        if ((!isFA && rma.length >= 3) || (isFA && faNum.length >= 3)) {
            setError("");
            const parameters = {
                param_rma_or_tradein: !isFA ? rma.replace(' ', ',') : '',
                param_fa_case_num: !isFA ? "" : faNum,
                isSimpleSearch: true,
                isReturnFa: isFA
            };
            searchRMA(parameters);
        }
        else {
            clearStorage();
            setError(translate('error.pleaseMin3'));
            setIsLoading(false);
        }
    };

    const searchRMA = (parameters) => {
        getRMA(parameters).then(response => {
            let faRMAS = response.res.dtos.map(x => x.rma_num[0]).join(',');
            if (isFA) {
                setRMA(faRMAS);
            }

            //Validate all the possibles stoppers after start the return flow with the current RMAs 
            if (isValidRMADashboard(response, isFA).isValid) {
                let dtos = response.res.dtos;
                let country = dtos[0].country[0];
                const rmas = isFA ? faRMAS.split(',') : rma.replace(' ', ',').split(',');
                if (dtos.length > 0) {
                    const paramsSave = {
                        ship_id: 0,
                        rmas: rmas.length > 1 ? rmas.sort((a, b) => a > b) : [rmas[0]],
                        simple: !isFA,
                        isReturnFa: isFA
                    };

                    sessionStorage.setItem(STORAGE.SEARCH_NAME, JSON.stringify(dtos));
                    if (!labelOnlyValue && country === COUNTRY.INDIA && DCGenerated) {
                        validateDeliveryChallan(paramsSave);
                    } else if (!isFA && labelOnlyValue && (country === COUNTRY.US || country === COUNTRY.CANADA)) {
                        setParamsSaved(paramsSave);
                        handleLabelOnly(paramsSave, true);
                    }
                    else if (!isFA && labelOnlyValue && (country !== COUNTRY.US && country !== COUNTRY.CANADA)) {
                        removeAlert("labelOnly");
                        showAlert({ id: 'labelOnly', page: '/home', message: translate('message.labelOnlyMessage'), color: "yellow" });
                        setIsLoading(false);
                    }
                    else handleSaveRMA(paramsSave);
                }
                else {
                    clearStorage();
                    setError('RMA ' + rma + translate('message.wasNotFound'));
                    setIsLoading(false);
                }
            }
            else setIsLoading(false);
        }).catch((e) => {
            clearStorage();
            setError('RMA ' + rma + translate('message.wasNotFound'));
            setIsLoading(false);
        });
    }

    const validateDeliveryChallan = (paramsSave) => {
        getValidAvailableDCRMA({ rmas: paramsSave.rmas }).then(response => {
            let data_response = response.res.dtos;
            if (data_response.length === 0) {
                setParamsSaved(paramsSave);
                setShowIndiaModal(!showIndiaModal);
                setIsLoading(false);
            }
            else {
                if (typeof paramsSave.rmas === "object") {
                    paramsSave.rmas.map((rmaSave) => {
                        if ((data_response.filter(x => x.rma_num === rmaSave && x.dc_generated))) {
                            handleSaveRMA(paramsSave);
                        }
                        else {
                            setParamsSaved(paramsSave);
                            setShowIndiaModal(!showIndiaModal);
                            setIsLoading(false);
                        }
                    });
                }
                else {
                    if (data_response && data_response[0] && data_response[0].dc_generated) {
                        handleSaveRMA(paramsSave);
                    }
                    else {
                        setParamsSaved(paramsSave);
                        setShowIndiaModal(!showIndiaModal);
                        setIsLoading(false);
                    }
                }
            }
        }).catch(() => setIsLoading(false));
    }

    const handleSaveRMA = (params) => {
        saveRMA(params).then(response => {
            if (response.res.success) {
                removeAlerts();
                let dtosSave = response.res.dtos;
                const paramsCInfo = {
                    //_dc: 1601322508190,//TODO we dont know where this value came from yet
                    ship_id: dtosSave[0].idd
                };
                sessionStorage.setItem(STORAGE.SAVE_NAME, JSON.stringify(dtosSave.map(x => ({ ...x, isFA }))));
                getContactInfo(paramsCInfo).then(contact => {
                    if (contact.res.success) {
                        let dtosCInfo = contact.res.dtos;
                        sessionStorage.setItem(STORAGE.CONTACT_INFO, JSON.stringify(dtosCInfo));
                        dispatch(resetRMAInfo());
                        dispatch(resetReturnFlow());
                        setIs(true)
                        history.push(RETURN);
                    } 
                    else {
                        clearStorage();
                        setIsLoading(false);
                    }
                }).catch(() => {
                    clearStorage();
                    setIsLoading(false);
                });
            } 
            else {
                clearStorage();
                setIsLoading(false);
            }
        }).catch(() => {
            clearStorage();
            setIsLoading(false);
        });
    }

    const createDocumentationIndia = () => {
        history.push({
            pathname: DOCUMENTATION,
            state: { rma: rma }
        });
    };

    const handleLabelOnly = (params, labelOnly) => {
        sessionStorage.setItem(STORAGE.LABEL_ONLY, JSON.stringify(labelOnly));
        handleSaveRMA(params);
    };

    const handleLabelOnlyValue = () => {
        setLabelOnlyValue(!labelOnlyValue);
    };

    const requestReturnInfo = (
        <label>
            {translate("subtitle.requestlinkStart") + ' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                href={faqLink} >
                {translate("title.clickHere") + ' '}
            </a>
            {translate("subtitle.orVisit") + ' '}
            <Link
                className="link"
                to={SEARCH_RMA} >
                {translate("title.rmaSearch") + ' '}
            </Link>
            {translate("subtitle.requestlinkEnd")}
        </label>
    );

    return (
        <Fragment>
            {/* (GPT-3 automatically generated documentation)
                     It creates a new card with the title "RMA Info Search" and the subtitle "RMA Info Search" and adds a button with the text "Request Return".
                       */}
            {
                !isFA ?
                    <Card
                        icon="fal fa-box"
                        largeIcon={true}
                        title={translate("title.searchCardTitle")}
                        comments={translate("subtitle.forMultipleRMAs")}
                        value={rma}
                        setValue={setRMA}
                        onClick={handleSearch}
                        placeholder={translate("placeholder.enterRMA")}
                        button={translate("button.requestReturn")}
                        primaryButton={true}
                        infoLabel={requestReturnInfo}
                        infoIcon="fas fa-info-circle"
                        validateRMA={true}
                        error={error}
                        labelOnly={translate('subtitle.labelOnlyCheckBox')}
                        labelOnlyValue={labelOnlyValue}
                        handleLabelOnlyValue={() => handleLabelOnlyValue()}
                        tooltip={translate('message.labelOnlyToolTip')}>
                        {translate("subtitle.RMAInfoSearch")}

                    </Card>
                    :
                    <Card
                        icon="fal fa-exchange-alt"
                        title={translate('title.factReturns')}
                        value={faNum}
                        setValue={setFaNum}
                        onClick={handleSearch}
                        button={translate("button.search")}
                        label={translate('formLabel.fa')}
                        placeholder={translate('placeholder.fa')}
                        validateRMA={false}
                        error={error}
                        type="factReturn"
                    >
                        <br />
                    </Card>
            }
            <LabelOnlyModal
                show={showLabelOnlyModal}
                onClose={() => setShowLabelOnlyModal(!showLabelOnlyModal)}
                onContinue={handleLabelOnly.bind(this, paramsSaved)} />
            <IndiaRMAModal
                proceedWODocument={handleSaveRMA.bind(this, paramsSaved)}
                createDocument={createDocumentationIndia.bind(this)}
                show={showIndiaModal}
                close={() => setShowIndiaModal(!showIndiaModal)} />
        </Fragment>
    );
};

export default RequestReturn;