import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../core/contexts';
import { useAlert } from '../core/hooks';
import { loadShipments, setDownloadParams } from '../core/redux/actions';
import { selectDownloadParams, selectRmaSearchText } from '../core/redux/selectors';
import { ShipmentSearchSchema } from '../core/schemas';
import { Button, TextInput } from './controls';

const DownloadSearchBar = props => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { showAlert } = useAlert();

    const [initialValues, setInitialValues] = useState({
        rma: '',
        shipCode: '',
        trackingNumber: ''
    });

    const rmaSearchText = useSelector(selectRmaSearchText);
    const downloadParams = useSelector(selectDownloadParams);
    

    useEffect(() => {
        if (downloadParams) {
            setInitialValues(downloadParams);
            handleLoadShipments(downloadParams);
        };
    }, [downloadParams]);

    useEffect(() => {
        if (rmaSearchText) setInitialValues({ ...initialValues, rma: rmaSearchText });
    }, [rmaSearchText]);

    const getRMAsWithoutShipments = (rmaQuery, shipments) => {
        let rmasToValidateShipments = [];
        if (rmaQuery) {
            rmasToValidateShipments = rmaQuery.split(',');
            for (let shipment of shipments) {
                if (!rmasToValidateShipments.length) break;
                for (let rmaText of rmasToValidateShipments) {
                    if ((shipment.rmas || []).find(x => x === rmaText))
                        rmasToValidateShipments = rmasToValidateShipments.filter(x => x !== rmaText)
                }
            }
        }
        return rmasToValidateShipments;
    };

    const handleSearch = async (values, { setSubmitting }) => {
        handleLoadShipments(values);
        setSubmitting(false);
    };

    const handleLoadShipments = async (values) => {
        dispatch(setDownloadParams(values));
        const shipments = await dispatch(loadShipments(values.rma, values.shipCode, values.trackingNumber));
        const rmas = getRMAsWithoutShipments(values.rma, shipments);
        if (rmas.length) {
            showAlert({
                id: 'noShipmentFound',
                message: `${translate('error.noShipmentsFound')} - ${translate('gridColumns.rmas')}: ${rmas.join(', ')}`,
                page: '/download'
            });
        }

    };

    return (
        <div className="rmaControlBar">
            <div className="rmaControlBarDescription">
                <h3>{translate('subtitle.searchFilters')}</h3>
            </div>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={ShipmentSearchSchema}
                onSubmit={handleSearch}>
                {
                    ({ handleSubmit, isSubmitting, values }) => (
                        <form
                            onSubmit={handleSubmit}
                            data-testid="ssForm"
                            className="rmaControlBarFields">
                            <div className="row mb-2 pt-3">
                                <div className="col-12 py-1 form-group">
                                    <TextInput
                                        type="text"
                                        name={'shipCode'}
                                        label={'gridColumns.shipmentID'}
                                        placeholder={translate('gridColumns.shipmentID')}
                                        maxLength="255" />
                                </div>
                                <div className="col-12 py-1 form-group">
                                    <TextInput
                                        type="text"
                                        name={'rma'}
                                        label={'title.rma'}
                                        placeholder={translate('title.rma')}
                                        maxLength="255" />
                                </div>
                                <div className="col-12 py-1 form-group">
                                    <TextInput
                                        type="text"
                                        name={'trackingNumber'}
                                        label={'gridColumns.tracking'}
                                        placeholder={translate('gridColumns.tracking')}
                                        maxLength="255" />
                                </div>
                            </div>
                            <div className="bottomButton">
                                <Button
                                    loading={isSubmitting}
                                    disabled={isSubmitting || (!values.rma && !values.shipCode && !values.trackingNumber)}
                                    type="submit"
                                    style={{ width: '100%', margin: 0 }}>
                                    {translate('button.search')}
                                </Button>
                            </div>
                        </form>
                    )
                }
            </Formik>
        </div>
    );
};

export default DownloadSearchBar;