import { createSelector } from "reselect";

const selectCiscoEntities = (state) => state.ciscoEntities;

export const selectCountries = createSelector(
    selectCiscoEntities,
    (cEState) => cEState.countries.map((ct) => ({
        ...ct,
        text: ct.descr_code,
        code: ct.code
    }))
);

export const selectCountriesSerbiaMontenegro = createSelector(
    selectCiscoEntities,
    (cEState) => cEState.countries.filter(x => x.code === "RS" || x.code === "ME" || x.code === "XK").map((ct) => ({
        ...ct,
        text: ct.descr_code,
        code: ct.code
    }))
);

export const selectEventCarriers = createSelector(
    selectCiscoEntities,
    (cEState) => cEState.eventCarriers
);

export const selectRMATypes = createSelector(
    selectCiscoEntities,
    (cEState) => cEState.rmaTypes.map((ct) => ({
        ...ct,
        text: ct.name || 'ALL',
        code: ct.code
    }))
);