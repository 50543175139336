import React from 'react';
import { COLOR } from '../../core/constants/Colors';
import { POWRIcon } from '../styledComponents';

export default props => {
    return (
        <div className={props.className ? `infoContainer ${props.className}` : "infoContainer"}>
            <div className="infoBtn">
                <POWRIcon
                    className="fal fa-info-circle"
                    color={COLOR.DARK_GREY_1}
                    size="16px" />
            </div>
            <div className={props.left ? "infoContent infoContentLeft" : "infoContent"}>
                <POWRIcon
                    className="far fa-info-circle"
                    color={COLOR.DARK_GREY_1}
                    size="16px" />
                <label>{props.children}</label>
            </div>
        </div>
    )
}