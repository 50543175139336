import React from 'react'

export default props => (
    <label
        className={`checkboxContainer ${props.className ? props.className : ''}`}
        style={props.style}
    >
        <input
            className="inputCheckbox"
            type="checkbox"
            id={props.id}
            value={props.value}
            // onClick={props.onClick}
            onChange={props.onClick}
            checked={props.value ? "checked" : props.checked ? "checked" : ''}
            disabled={props.disabled}
        />
        <span className={props.error ? "checkmark controlError" : "checkmark"}></span>
        {props.text && <label className={!props.smallText ? "checkboxLabel bigLabel" : "checkboxLabel"}>{props.text}</label>}
    </label>
);