import { COLOR } from "./Colors";
import { SCHEDULE, SHIPMENT_DETAIL } from "./ROUTES";

let green = COLOR.GREEN;
let blue = COLOR.CISCO_BLUE;
let red = COLOR.RED;
let yellow = COLOR.YELLOW;

let actions = [
    { icon: 'fal fa-calendar-plus', text: 'Schedule Pickup', href: SCHEDULE, type: "onClick" },
    { icon: 'fal fa-boxes', text: 'View Shipments', href: SHIPMENT_DETAIL, type: "onClick" }
]

export const WayBillStatus = [
    { id: 1, actions, color: yellow, text: 'shipmentsStatus.requested' },
    { id: 2, actions, color: blue, text: 'shipmentsStatus.scheduled' },
    { id: 3, actions, color: red, text: 'shipmentsStatus.denied' },
    { id: 4, actions, color: blue, text: 'shipmentsStatus.rescheduledByUser' },
    { id: 5, actions, color: red, text: 'shipmentsStatus.cancelledByUser' },
    { id: 6, actions, color: green, text: 'shipmentsStatus.made' },
    { id: 7, actions, color: yellow, text: 'shipmentsStatus.delayed' },
    { id: 8, actions, color: green, text: 'shipmentsStatus.delivered' },
    { id: 9, actions, color: yellow, text: 'shipmentsStatus.deliveryTimeChanged' },
    { id: 10, actions, color: yellow, text: 'shipmentsStatus.shipmentDelayed' },
    { id: 11, actions, color: red, text: 'shipmentsStatus.lost' },
    { id: 12, actions, color: green, text: 'shipmentsStatus.receiving' },
    { id: 13, actions, color: blue, text: 'shipmentsStatus.rescheduledByCarrier' },
    { id: 14, actions: [], color: '', text: 'shipmentsStatus.' },
    { id: 15, actions, color: yellow, text: 'shipmentsStatus.alert' },
    { id: 16, actions, color: red, text: 'shipmentsStatus.futile' },
    { id: 17, actions, color: green, text: 'shipmentsStatus.labelCreated' },
]