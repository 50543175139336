import {
    SET_RTF_DEFAULT_OPTIONS, SET_RTF_FLOW_PROP, ADD_PRODUCTS, UPDATE_PRODUCT, SET_RETURN_REQUEST_DATA, REMOVE_UNIT, REMOVE_PRODUCTS, UPDATE_UNIT, SAVE_RTF_DATA, SET_RETURN_REQUEST_DATA_COMPLETE, UPDATE_PRODUCTS_QTY,
    REMOVE_MULTIPLE_PRODUCTS, ADD_MULTIPLE_PRODUCTS, UPDATE_EOR, UPDATE_IOR
} from "../actionTypes";


export const setRequestReturnFlowProp = (propName, value) => ({ type: SET_RTF_FLOW_PROP, payload: { propName, value } });

export const setRTFFlowDefaultOptions = (options) => ({
    type: SET_RTF_DEFAULT_OPTIONS,
    payload: options
});

export const setRTFFlowProducts = (options) => ({
    type: ADD_PRODUCTS,
    payload: options
});

export const updateRTFProduct = (data) => ({
    type: UPDATE_PRODUCT,
    payload: data
});

export const updateRTFProductsQty = (data) => ({
    type: UPDATE_PRODUCTS_QTY,
    payload: data
});

export const updateRTFUnit = (data) => ({
    type: UPDATE_UNIT,
    payload: data
});
export const setRTFData = (data) => ({
    type: SET_RETURN_REQUEST_DATA,
    payload: data
});

export const setRequestReturnDataComplete = (data) => ({
    type: SET_RETURN_REQUEST_DATA_COMPLETE,
    payload: data
});

export const removeRequestReturnProduct = (data) =>({
    type: REMOVE_PRODUCTS,
    payload: data
})

export const removeRTFUnit = (data) =>({
    type: REMOVE_UNIT,
    payload: data
})

export const saveRTF = (data) =>({
    type: SAVE_RTF_DATA,
    payload: data
})
export const addMultipleRTFProducts = (data) =>({
    type: ADD_MULTIPLE_PRODUCTS,
    payload: data
})
export const removeMultipleRTFProducts = (data) =>({
    type: REMOVE_MULTIPLE_PRODUCTS,
    payload: data
})
export const updateEOR = (data) => ({
    type: UPDATE_EOR,
    payload: data
});
export const updateIOR = (data) => ({
    type: UPDATE_IOR,
    payload: data
});