
import React, { useEffect, useState } from 'react';
import { useTranslate } from '../core/contexts';
import { InfoSection, POWRIcon, POWRModal } from './styledComponents';
import { COUNTRY, FORM_TYPE, ROLES, SHIPMENT_TYPE } from '../core/constants';
import { CommercialInvoiceForm } from './forms/commercialInvoiceForm';
import { useCommercialInvoice } from '../core/hooks';
import { COLOR } from '../core/constants/Colors';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../core/redux/selectors';
import { BASE_URL_API } from '../core/constants/URLS';
import { getRMA } from '../services';

const DownloadLabels = ({ shipment, index }) => {
    const { ship_code, idd, rmas, status, generate_invoice, generate_packing_lst, generate_sli } = shipment;
    const { translate } = useTranslate();
    const {
        handleCommercialInvoice,
        handleFaultDetection,
        handleActExchange,
        print,
        modalTemplate,
        setModalTemplate,
        showFaultDetection,
        showActExchange,
        uploadDocuments
    } = useCommercialInvoice({ shipment });

    const [isIndiaOrBrazil, setIsIndiaOrBrazil] = useState(false);

    const userInfo = useSelector(selectUserInfo);
    const userAdmin = userInfo && userInfo.role === ROLES.ADMIN || userInfo.role === ROLES.AR_AGENT;
    
    useEffect(() => {
        const country = ship_code ? ship_code.substr(0, 2) : '';
        setIsIndiaOrBrazil(country === COUNTRY.INDIA || country === COUNTRY.BRAZIL);
    }, [ship_code]);

    const handleDownloadFile = id => {
        window.open(`${BASE_URL_API}/CarrierEmailServlet?docId=${id}`, "popup", 'width=600,height=600');
    };

    return (
        <InfoSection
            key={`shipment-download-${idd}${index}`}
            className="card-download my-2"
            customTitle={
                <h3 className="m-0">
                    {`${ship_code} ${rmas ? `- ${translate('gridColumns.rmas')}: ${rmas.join(', ')}` : ''}`}
                </h3>
            }>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 download-button">
                    <div className="button-content" onClick={() => print('label', idd)}>
                        <POWRIcon className="far fa-file-alt mr-4" color={COLOR.GREY} size={'2rem'} />
                        <span>{translate('button.carrierLabel')}</span>
                    </div>
                </div>
                {generate_invoice && <div className="col-12 col-md-6 col-lg-4 download-button">
                    <div className="button-content" onClick={() => handleCommercialInvoice(idd, ship_code, FORM_TYPE.COMMERCIAL_INVOICE)}>
                        <POWRIcon className="far fa-file-invoice mr-4" color={COLOR.GREY} size={'2rem'} />
                        <span>{translate('title.commercialInvoice')}</span>
                    </div>
                </div>
                }
                {(!!generate_packing_lst && status !== SHIPMENT_TYPE.STATUS_DRAFT) &&
                    <div className="col-12 col-md-6 col-lg-4 download-button">
                        <div className="button-content" onClick={() => handleCommercialInvoice(idd, ship_code, FORM_TYPE.PACKING_LIST)}>
                            <POWRIcon className="far fa-receipt mr-4" color={COLOR.GREY} size={'2rem'} />
                            <span>{translate('button.packingList')}</span>
                        </div>
                    </div>
                }
                {(!!generate_sli && status !== SHIPMENT_TYPE.STATUS_DRAFT) &&
                    <div className="col-12 col-md-6 col-lg-4 download-button">
                        <div className="button-content" onClick={() => handleCommercialInvoice(idd, ship_code, FORM_TYPE.SLI)}>
                            <POWRIcon className="far fa-file-spreadsheet mr-4" color={COLOR.GREY} size={'2rem'} />
                            <span>{translate('button.shipperLetterOfInstructions')}</span>
                        </div>
                    </div>
                }
                {showFaultDetection && <div className="col-12 col-md-6 col-lg-4 download-button">
                    <div className="button-content" onClick={() => handleFaultDetection(idd, ship_code)}>
                        <POWRIcon className="far fa-file mr-4" color={COLOR.GREY} size={'2rem'} />
                        <span>{translate('button.faultDetection')}</span>
                    </div>
                </div>
                }
                {showActExchange && <div className="col-12 col-md-6 col-lg-4 download-button">
                    <div className="button-content" onClick={() => handleActExchange(idd, ship_code)}>
                        <POWRIcon className="far fa-file mr-4" color={COLOR.GREY} size={'2rem'} />
                        <span>{translate('button.actExchange')}</span>
                    </div>
                </div>
                }
                {modalTemplate != null &&
                    <POWRModal
                        show={true}
                        title={translate(`title.${modalTemplate.type}Form`)}
                        size="long"
                        onClose={() => setModalTemplate(null)}
                    >
                        <CommercialInvoiceForm
                            onClose={() => setModalTemplate(null)}
                            lines={modalTemplate.data}
                            print={() => print(modalTemplate.type, modalTemplate.shipId)}
                            type={modalTemplate.type}
                            shipCode={modalTemplate.shipCode}
                        />
                    </POWRModal>
                }
            </div>
            <div className="row uploaded-documents-container">
                <h4 className="pl-3">{translate('subtitle.userUploadedDocuments')}</h4>
                <div className="uploaded-documents">
                    {userAdmin && isIndiaOrBrazil && uploadDocuments && uploadDocuments.length > 0 && 
                        uploadDocuments.map((doc, i) => doc.mime_type !== "eml" && (
                            <div className="col-12 col-md-6 col-lg-4 download-button" key={`document-${i}`}>
                                <div className="button-content" onClick={() => handleDownloadFile(doc.idd)}>
                                    <POWRIcon className="far fa-file mr-4" color={COLOR.GREY} size={'2rem'} />
                                    <span>{doc.file_name}</span>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </InfoSection>
    );

};

export default DownloadLabels;
