export const GRID_COLUMNS = {
    addresses: [
        {
            key: 'title',
            tag: 'addressBookName',
            template: 'bold',
            headerClasses: null
        },
        {
            key: 'name',
            tag: 'company'
        },
        {
            key: 'country',
            tag: 'country'
        },
        {
            key: 'province',
            tag: 'province'
        },
        {
            key: 'city',
            tag: 'city'
        },
        {
            key: '',
            tag: '',
            template: 'actions'
        }
    ],
    contacts: [
        {
            key: 'title',
            tag: 'addressBookName',
            template: 'bold',
            headerClasses: null
        },
        {
            key: 'fname',
            tag: 'firstName'
        },
        {
            key: 'lname',
            tag: 'lastName'
        },
        {
            key: 'phone',
            tag: 'phone'
        },
        {
            key: 'email',
            tag: 'email'
        },
        {
            key: 'header',
            tag: 'type',
            template: 'withTranslation'
        },
        {
            key: '',
            tag: '',
            template: 'actions'
        }
    ],
    events: [
        {
            key: 'eventTypeDesc',
            tag: 'eventType',
            template: 'withTranslation'
        },
        {
            key: 'rma_type_descr',
            tag: 'rmaType',
            template: 'withAll'
        },
        {
            key: 'eventFrequencyDesc',
            tag: 'frequency',
            template: 'withTranslation'
        },
        {
            key: 'country_descr',
            tag: 'country',
            template: 'withAll'
        },
        {
            key: 'carrier_descr',
            tag: 'carrier',
            template: 'withAll'
        },
        {
            key: 'restrictionTypeDesc',
            tag: 'restriction',
            template: 'withTranslation'
        },
        {
            key: '',
            tag: '',
            template: 'actions'
        }
    ],
    viewPackage: [
        {
            key: 'rma_num',
            tag: 'rma#'
        },
        {
            key: 'rma_line',
            tag: 'line'
        },
        {
            key: 'part_num',
            tag: 'part#'
        },
        {
            key: 'serial',
            tag: 'serial#',
            template: 'commaArray'
        }
    ],
    viewPackageDetail: [
        {
            key: 'pack_name',
            tag: 'package'
        },
        {
            key: 'qty',
            tag: 'qty',
            template: 'unit'
        },
        {
            key: 'rma_num',
            tag: 'rma#'
        },
        {
            key: 'tn',
            tag: 'tracking'
        },
        {
            key: 'weight',
            tag: 'weight'
        },
        {
            key: 'depth',
            tag: 'length'
        },
        {
            key: 'width',
            tag: 'width'
        },
        {
            key: 'height',
            tag: 'height'
        },
    ],
    commercialInvoice: [
        {
            key: 'line',
            tag: 'line'
        },
        {
            key: 'rma_num',
            tag: 'rma'
        },
        {
            key: 'part_mumber',
            tag: 'partNumber',
            template: 'info',
            info: 'part_info'
        },
        {
            key: 'serial',
            tag: 'serialNumber',
            template: 'input',
            placeholder: 'placeholder.serialNumber'
        },
        {
            key: 'country',
            tag: 'countryOrigin',
            template: 'input',
            placeholder: 'placeholder.COO'
        }
    ],
    actOfExchange: [
        {
            key: 'line',
            tag: 'line'
        },
        {
            key: 'rma_num',
            tag: 'rma'
        },
        {
            key: 'part_mumber',
            tag: 'partNumber',
            template: 'info',
            info: 'part_info'
        },
        {
            key: 'serial',
            tag: 'serialNumber',
            template: 'input',
            placeholder: 'placeholder.serialNumber'
        },
        {
            key: 'qty',
            tag: 'qty',
        }
    ],
    faultDetection: [
        {
            key: 'line',
            tag: 'line'
        },
        {
            key: 'rma_num',
            tag: 'rma'
        },
        {
            key: 'part_mumber',
            tag: 'partNumber',
            template: 'info',
            info: 'part_info'
        },
        {
            key: 'serial',
            tag: 'serialNumber',
            template: 'input',
            placeholder: 'placeholder.serialNumber'
        },
        {
            key: 'country',
            tag: 'countryOrigin',
            template: 'input',
            placeholder: 'placeholder.COO'
        },
        {
            key: 'failure',
            tag: 'failureDescription',
            template: 'input',
            placeholder: 'gridColumns.failureDescription'
        }
    ],
    rmaDashboard: [
        {
            key: 'rmaType',
            tag: 'rmaType'
        },
        {
            key: 'customerPO',
            tag: 'customerPO'
        },
        {
            key: 'created',
            tag: 'created'
        },
        {
            key: 'returnedBy',
            tag: 'returnedBy'
        },
        {
            key: 'shipToCountry',
            tag: 'shipToCountry'
        },
        {
            key: 'shipToCustomer',
            tag: 'shipToCustomer'
        },
        {
            key: 'status',
            tag: 'status',
            template: 'status'
        },
        {
            key: 'paidBy',
            tag: 'paidBy'
        },
    ],
    shippingDashboard: [
        {
            key: 'carrier',
            tag: 'carrier'
        },
        {
            key: 'pickupRequested',
            tag: 'pickupRequested'
        },
        {
            key: 'agreedPickupDate',
            tag: 'agreedPickupDate'
        },
        {
            key: 'shipStatus',
            tag: 'shipStatus',
            template: 'status'
        },
        {
            key: 'statusDate',
            tag: 'statusDate'
        },
    ]
};