import { createSelector } from "reselect";

const selectAddresses = (state) => state.addresses;

export const selectUserAddresses = createSelector(
    selectAddresses,
    (addressesState) => addressesState.addresses.map((ad) => {
        let info = [];
        for (let i = 0; i <= 4; i++) {
            if (ad[`addr${i + 1}`]) info.push(ad[`addr${i + 1}`]);
        }

        return {
            ...ad,
            save_as: ad.save_as || ad.atag,
            title: ad.save_as || ad.atag || ad.name,
            info: [
                ...info,
                `${ad.city ? ad.city + ', ' : ''}${ad.province ? ad.province + ', ' : ''}${ad.postal + ' ' || ''}${ad.country || ''}`
            ]
        };
    })
);

export const selectSelectedAddress = createSelector(
    selectAddresses,
    (addressesState) => addressesState.addresses.find(ct => ct.idd === addressesState.selectedAddress)
);