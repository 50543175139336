import { useDispatch, useSelector } from 'react-redux';
import { RETURN } from '../constants/ROUTES';
import { removeAlertById, addAlert, removeAllAlerts, removeAllGlobalMessages } from '../redux/actions';
import { selectAlerts, selectUserInfo } from '../redux/selectors';

export const useAlert = () => {
    const dispatch = useDispatch();
    const alerts = useSelector(selectAlerts);
    const userInfo = useSelector(selectUserInfo);

    const setAlert = (...props) => console.log({ ...props });
    const showAlert = ({ id, time = 10000, page, message, color, isModal = false, isGlobalMessage = false }) => {
        if (alerts.filter(a => a.id === id))
            removeAlert(id);
        dispatch(addAlert({
            id,
            page: page === RETURN && userInfo.rmaLogin ? '*' : page,
            message,
            color,
            visible: true,
            isModal,
            isGlobalMessage
        }));
        if (color !== "yellow" && color !== "red")
            setTimeout(() => removeAlert(id), time );
    };

    const removeAlert = (id) => dispatch(removeAlertById(id));

    const removeAlerts = () => {
        dispatch(removeAllAlerts());
    }

    const removeGlobalMessages = () => {
        dispatch(removeAllGlobalMessages());
    }
    return {
        showAlert,
        removeAlert,
        alerts,
        setAlert,
        removeAlerts,
        removeGlobalMessages
    };
};