import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Checkbox, Link, Select } from './controls';
import { useTranslate } from '../core/contexts';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCreatePackagesStep, selectGroupedParts, selectSelectedSublines,
    selectShipmentId, selectSublines
} from '../core/redux/selectors';
import { loadPackages, setReturnFlowProp, updateSubLineProp } from '../core/redux/actions';
import { VirtualScrollChild } from './shared';
import { COLOR } from '../core/constants/Colors';
import { POWRIcon } from './styledComponents';
import { useCreatePackages } from '../core/hooks';

const CreatePackages = () => {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const [collapsedLines, setCollapsedLines] = useState([]);
    const { error } = useCreatePackages();

    const step = useSelector(selectCreatePackagesStep);
    const selectedSublines = useSelector(selectSelectedSublines);
    const rmasWithLineParts = useSelector(selectGroupedParts);
    const allSublines = useSelector(selectSublines);
    // const selectedLines = useSelector(selectSelectedLines); // AUTO FILL SUBLINE SERIALS
    const shipmentId = useSelector(selectShipmentId);

    useEffect(() => {
        dispatch(loadPackages(shipmentId));
    }, [dispatch, shipmentId]);

    // useEffect(() => { // AUTO FILL SUBLINE SERIALS
    //     let updatedSublines = [];
    //     let otherSublines = allSublines;
    //     for (let line of selectedLines) {
    //         let sublinesOfLine = otherSublines.filter(sl => sl.rma_num === line.rma && sl.rma_line === line.line);
    //         otherSublines = otherSublines.filter(sl => sl.rma_num !== line.rma || sl.rma_line !== line.line);

    //         if (line.available === line.qtyPicked) {
    //             const serials = sublinesOfLine.length ? sublinesOfLine[0].serials : [];
    //             sublinesOfLine.forEach((subline, i) => {
    //                 subline.serial = serials[i] || '';
    //                 updatedSublines.push(subline);
    //             });
    //         }
    //         else updatedSublines.push(...sublinesOfLine.map((x) => ({ ...x, serial: '' })))
    //     }
    //     dispatch({
    //         type: SET_SUB_LINES,
    //         payload: [...updatedSublines, ...otherSublines]
    //     });
    // }, [selectedLines, dispatch]);

    const updSublineProp = (id, prop, val) => dispatch(updateSubLineProp(id, prop, val));

    const handleCheck = useCallback((partId, checked) => {
        if (!checked) dispatch(setReturnFlowProp('selectedSubLines', [...selectedSublines, partId]));
        else dispatch(setReturnFlowProp('selectedSubLines', [...selectedSublines.filter(x => x !== partId)]));
    }, [selectedSublines, dispatch]);

    const handleCheckAll = useCallback((parts, checked) => {
        dispatch(setReturnFlowProp('selectedSubLines', [...selectedSublines.filter(x => !parts.find(y => y === x)), ...(!checked ? parts : [])]));
    }, [selectedSublines, dispatch]);

    const handleCollapse = useCallback((line, collapsed) => {
        if (!collapsed) setCollapsedLines([...collapsedLines, { rma: line.rma, line: line.line }]);
        else setCollapsedLines([...collapsedLines.filter(x => x.rma !== line.rma || x.line !== line.line)]);
    }, [collapsedLines]);

    const handleResetPackages = () => {
        dispatch(setReturnFlowProp('subLines', allSublines.map(x => ({ ...x, package_id: null }))));
    };

    useEffect(() => {
        let sublinesWithSerials = [];
        rmasWithLineParts.map((rmaLinePart, i) => {
            return rmaLinePart.lines.map((line, j) => {
                return line.parts.map((part, k) => {
                    sublinesWithSerials.push({ ...part, serial:part.serial ? part.serial.includes(',') ? part.serials[k] : part.serial :"" })
                })

            })

        })
        dispatch(setReturnFlowProp('subLines', sublinesWithSerials));
    }, [])

    return (
        <div className="createPackagesWrapper">
            <div className="createPackagesTitleHead">
                <div className="row">
                    <div className="col-12 pt-3 pt-lg-0 head-actions">
                        <Link className="mr-2" onClick={() => handleResetPackages()}>
                            <POWRIcon className="fal fa-redo mr-1" color={COLOR.CISCO_BLUE} size="13px" />
                            {translate('button.resetAllPackages')}
                        </Link>
                        <Link className={!step ? 'd-none' : ''} onClick={() => dispatch(setReturnFlowProp('selectedSubLines', []))}>
                            <POWRIcon className="fal fa-minus-circle mr-2" color={COLOR.CISCO_BLUE} size="13px" />
                            {translate('button.resetSelection')}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="createPackagesContainer">
                <table className="d-none d-lg-table">
                    <thead>
                        <tr className="packagesTitles">
                            <th>
                                {translate('title.rma')}
                            </th>
                            <th>
                                {translate('formLabel.qty')}
                            </th>
                            <th>
                                {translate('gridColumns.part#')}
                            </th>
                            <th style={{ minWidth: 144 }}>
                                {translate('gridColumns.serial#')}
                            </th>
                            <th style={{ minWidth: 144 }} className="text-center pr-5">
                                {translate('title.package')}/{translate('aux.box')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rmasWithLineParts.map((rmaLinePart, i) => {
                                const RMASublinesId = [];
                                rmaLinePart.lines.forEach(ln => ln.parts.forEach(pr => RMASublinesId.push(pr.idd)));
                                const allRMAChecked = RMASublinesId.filter(x => !selectedSublines.find(y => y === x)).length === 0;

                                return (
                                    <Fragment key={`rmaPart-${i}`}>
                                        {
                                            rmaLinePart.lines.map((line, j) => {
                                                const collapsed = !!collapsedLines.find(x => x.rma === line.rma && x.line === line.line);
                                                const allChecked = line.parts.filter(x => (selectedSublines || []).find(y => y === x.idd)).length === line.parts.length;
                                                return (
                                                    <Fragment key={`rmaPartLine-${j}`}>
                                                        <VirtualScrollChild height='55' type='table' className="packageSelect">
                                                            <td className="headPackage noBorder" >
                                                                <div className="row m-0">
                                                                    <div>
                                                                        {
                                                                            (j === 0 && !!step) &&
                                                                            <Checkbox
                                                                                onClick={() => handleCheckAll(RMASublinesId, allRMAChecked)}
                                                                                checked={allRMAChecked}
                                                                                id={`cbox-RMA-${rmaLinePart.rma}`} />
                                                                        }
                                                                    </div>
                                                                    <div className="col">
                                                                        {
                                                                            j === 0 &&
                                                                            <h4>{translate('title.rma')}: {rmaLinePart.rma}</h4>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="showLines" key={`showLines-${!collapsed}`}>
                                                                {!!step && <Checkbox
                                                                    onClick={() => handleCheckAll(line.parts.map(x => x.idd), allChecked)}
                                                                    checked={allChecked}
                                                                    id={`cbox-${rmaLinePart.rma}`} />}
                                                                <div onClick={() => handleCollapse(line, collapsed)}>
                                                                    <POWRIcon
                                                                        className={`${!collapsed ? "fas fa-caret-down" : "fas fa-caret-right"} showLinesIcon`}
                                                                        color={COLOR.CISCO_BLUE}
                                                                        size="16px" />
                                                                </div>
                                                                <h4>{line.parts.length}</h4>
                                                            </td>
                                                            <td onClick={() => handleCollapse(line, collapsed)}>
                                                                <p>{line.part}</p>
                                                            </td>
                                                            <td onClick={() => handleCollapse(line, collapsed)} />
                                                            <td onClick={() => handleCollapse(line, collapsed)} />
                                                        </VirtualScrollChild>
                                                        {
                                                            !collapsed &&
                                                            line.parts.map((part, k) => {
                                                                const checked = !!(selectedSublines || []).find(x => x === part.idd);
                                                                return (
                                                                    <VirtualScrollChild height='55' type='table' key={`part-${k}`} className={`packagesRow ${(error && !part.pack_num) ? 'error' : ''}`}>
                                                                        <td className="rma-empty noBorder" />
                                                                        <td className="noBorder" />
                                                                        <td>
                                                                            <div className="d-flex align-items-center pl-3">
                                                                                {!!step && <Checkbox
                                                                                    id={`cbox-${k}`}
                                                                                    value={checked}
                                                                                    checked={checked}
                                                                                    className={error && !part.pack_num ? "checkError mr-1" : "mr-1"}
                                                                                    onClick={() => handleCheck(part.idd, checked)} />}
                                                                                <p className="m-0">{`${translate('aux.unit')} ${k + 1}`}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-left">
                                                                            <Select
                                                                                onAddNew={(val) => updSublineProp(part.idd, 'serial', val)}
                                                                                value={part.serial}
                                                                                text={part.serial}
                                                                                customIcon={'fas fa-caret-down'}
                                                                                onChange={(val) => updSublineProp(part.idd, 'serial', val)}
                                                                                options={part.serials.map((x => ({ code: x, text: x })))}
                                                                                className={'no-space inside-table'}
                                                                                singleSelection mandatory availableAdd notFilter
                                                                                placeholder={translate('placeholder.selectSerial')} />
                                                                        </td>
                                                                        <td>
                                                                            {part.pack_num && <div className="d-flex align-items-center justify-content-center pr-5">
                                                                                <POWRIcon
                                                                                    className="fas fa-box mr-2"
                                                                                    color={COLOR.DARK_GREY_1}
                                                                                    size="14px" />
                                                                                <p className="m-0"><strong>{part.pack_num}</strong></p>
                                                                            </div>}
                                                                        </td>
                                                                        <td />
                                                                    </VirtualScrollChild>
                                                                )
                                                            })
                                                        }
                                                    </Fragment>
                                                );
                                            })
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="d-block d-lg-none py-16 px-16">
                    {rmasWithLineParts.map((rmaLinePart, i) => {
                        const RMASublinesId = [];
                        rmaLinePart.lines.forEach(ln => ln.parts.forEach(pr => RMASublinesId.push(pr.idd)));
                        const allRMAChecked = RMASublinesId.filter(x => !selectedSublines.find(y => y === x)).length === 0;

                        return (
                            <div key={`rmaPart-${i}`} className="border-bottom-gray pb-3 mb-3">
                                {rmaLinePart.lines.map((line, j) => {
                                    const collapsed = !!collapsedLines.find(x => x.rma === line.rma && x.line === line.line);
                                    const allChecked = line.parts.filter(x => (selectedSublines || []).find(y => y === x.idd)).length === line.parts.length;
                                    return (
                                        <Fragment key={`rmaPartLine-${j}`}>
                                            <VirtualScrollChild overflowUnset height="55" className="packageSelect">
                                                <div className="row border-bottom-gray py-2">
                                                    <div className="col d-flex">
                                                        {(j === 0 && !!step) &&
                                                            <Checkbox
                                                                className="mr-2"
                                                                onClick={() => handleCheckAll(RMASublinesId, allRMAChecked)}
                                                                checked={allRMAChecked}
                                                                id={`checkbox-RMA-${rmaLinePart.rma}`} />
                                                        }
                                                        {j === 0 && <h4 className="m-0">{translate('title.rma')}: {rmaLinePart.rma}</h4>}
                                                    </div>
                                                </div>
                                                <div className="row border-bottom-gray py-2">
                                                    <div className="col-auto">
                                                        {!!step &&
                                                            <Checkbox
                                                                onClick={() => handleCheckAll(line.parts.map(x => x.idd), allChecked)}
                                                                checked={allChecked}
                                                                id={`checkbox-${rmaLinePart.rma}`} />
                                                        }
                                                    </div>
                                                    <div className="col d-flex" onClick={() => handleCollapse(line, collapsed)} key={`showLines-${!collapsed}`}>
                                                        <POWRIcon
                                                            className={`${!collapsed ? "fas fa-caret-down" : "fas fa-caret-right"} showLinesIcon mr-2`}
                                                            color={COLOR.CISCO_BLUE}
                                                            size="16px" />
                                                        <h4 className="m-0">{line.parts.length}</h4>
                                                    </div>
                                                    <div className="col text-right" onClick={() => handleCollapse(line, collapsed)}>
                                                        <p className="m-0">{line.part}</p>
                                                    </div>
                                                </div>

                                                {!collapsed && line.parts.map((part, k) => {
                                                    const checked = !!(selectedSublines || []).find(x => x === part.idd);
                                                    return (
                                                        <VirtualScrollChild overflowUnset height="55" key={`part-${k}`} className={`packagesRow border-bottom-gray ml-4 ${(error && !part.pack_num) ? 'error' : ''}`}>
                                                            <div className="row py-2">
                                                                <div className="col d-flex">
                                                                    {!!step &&
                                                                        <Checkbox
                                                                            id={`checkbox-${k}`}
                                                                            value={checked}
                                                                            checked={checked}
                                                                            className={error && !part.pack_num ? "checkError mr-2" : "mr-2"}
                                                                            onClick={() => handleCheck(part.idd, checked)} />
                                                                    }
                                                                    <p className="m-0">{`${translate('aux.unit')} ${k + 1}`}</p>
                                                                </div>
                                                                <div className="col d-flex text-left">
                                                                    {part.pack_num &&
                                                                        <div className="d-flex align-items-center justify-content-center pr-5">
                                                                            <POWRIcon
                                                                                className="fas fa-box mr-2"
                                                                                color={COLOR.DARK_GREY_1}
                                                                                size="14px" />
                                                                            <p className="m-0"><strong>{part.pack_num}</strong></p>
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                            <div className="row py-2">
                                                                <div className="col-auto">
                                                                    <label className="m-0">{translate('gridColumns.serial#')}</label>
                                                                </div>
                                                                <div className="col">
                                                                    <Select
                                                                        onAddNew={(val) => updSublineProp(part.idd, 'serial', val)}
                                                                        value={part.serial}
                                                                        customIcon={'fas fa-caret-down'}
                                                                        text={part.serial}
                                                                        onChange={(val) => updSublineProp(part.idd, 'serial', val)}
                                                                        options={part.serials.map((x => ({ code: x, text: x })))}
                                                                        className="no-space"
                                                                        singleSelection mandatory availableAdd notFilter
                                                                        placeholder={translate('placeholder.selectSerial')} />
                                                                </div>
                                                            </div>
                                                        </VirtualScrollChild>
                                                    )
                                                })}
                                            </VirtualScrollChild>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default CreatePackages;