import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '../core/contexts';
import { renderReschedule, renderReEnable, renderCancel } from '../core/helpers';
import { getCarrierProfile } from '../services';
import { ReEnablePickUpModal, ReschedulePickUpModal, CancelPickupModal, DeleteSavedPickupModal } from './modals';
import { Link } from './controls';
import { POWRIcon } from './styledComponents';
import { SHIPMENT_STATUS_NUM } from '../core/constants';
import { ResumePickup } from '.';
import { COLOR } from '../core/constants/Colors';
import { selectUserInfo } from '../core/redux/selectors';


export default ({ id, shipment, onReload, dropdown }) => {
    const { idd, ship_event, status, carrier_type, ship_code, rmas } = shipment;
    const { translate } = useTranslate();
    const [carrierProfile, setCarrierProfile] = useState([]);
    const [reSchedulePickup, setReSchedulePickup] = useState(false);
    const [showReSchedulePickup, setShowReSchedulePickup] = useState(false);
    const [reEnablePickup, setReEnablePickup] = useState(false);
    const [showReEnablePickup, setShowReEnablePickup] = useState(false);
    const [cancelPickup, setCancelPickup] = useState(false);
    const [showCancelPickup, setShowCancelPickup] = useState(false);

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        if (renderReschedule(ship_event, status, carrier_type)) {
            const params = { _dc: Date.now(), ship_id: idd };
            getCarrierProfile(params).then(response => {
                let carrier = response.res.dtos ? response.res.dtos[0] : [];
                setCarrierProfile(carrier);
                if (carrier) setReSchedulePickup(carrier.allow_reschedule)
            });
        } else {
            setReSchedulePickup(false);
        }
    }, [id]);

    useEffect(() => {
        setReEnablePickup(renderReEnable(ship_event, status));
        setCancelPickup(renderCancel(ship_event, status));
    }, [shipment]);

    const show = reSchedulePickup || cancelPickup || reEnablePickup || (status === SHIPMENT_STATUS_NUM.STATUS_DRAFT && !userInfo.rmaLogin)

    return show ? (
        <Fragment>
            <div className={dropdown ? '' : "actions-container"}>
                {!dropdown && 
                    <h3 className="actions-title">{translate('subtitle.pickupOptions')}</h3>
                }
                {reSchedulePickup &&
                    <Link className={dropdown ? "dropDownOption" : "action-button"} onClick={() => setShowReSchedulePickup(reSchedulePickup)}>
                        <POWRIcon className="fal fa-calendar-day icon" size="16px" color={dropdown ? COLOR.GREY : COLOR.CISCO_BLUE} />
                        {translate('button.reschedulePickup')}
                    </Link>
                }
                {cancelPickup &&
                    <Link className={dropdown ? "dropDownOption" : "action-button"} onClick={() => setShowCancelPickup(cancelPickup)}>
                        <POWRIcon className="fal fa-times-circle icon" size="16px" color={dropdown ? COLOR.GREY : COLOR.CISCO_BLUE} />
                        {translate('button.cancelPickup')}
                    </Link>
                }
                {reEnablePickup &&
                    <Link className={dropdown ? "dropDownOption" : "action-button"} onClick={() => setShowReEnablePickup(reEnablePickup)}>
                        <POWRIcon className="fal fa-redo icon" size="16px" color={dropdown ? COLOR.GREY : COLOR.CISCO_BLUE} />
                        {translate('button.deniedPickup')}
                    </Link>
                }
                {status === SHIPMENT_STATUS_NUM.STATUS_DRAFT && !userInfo.rmaLogin &&
                    <Fragment>
                        <ResumePickup idd={idd} rmas={rmas} dropdown={dropdown} />
                        <DeleteSavedPickupModal idd={idd} dropdown={dropdown} />
                    </Fragment>
                }
            </div>
            {showReSchedulePickup &&
                <ReschedulePickUpModal
                    data={shipment}
                    idd={idd}
                    show={showReSchedulePickup}
                    carrierProfile={carrierProfile}
                    onClose={() => setShowReSchedulePickup(false)}
                    noChangeCarrier
                    onReload={(date) => onReload(date)}
                />
            }
            {showCancelPickup &&
                <CancelPickupModal
                    idd={idd}
                    show={showCancelPickup}
                    onClose={() => setShowCancelPickup(false)}
                    noRedirect
                    onReload={() => onReload('cancel')}
                    carrierProfile = {carrierProfile}
                />
            }
            {showReEnablePickup &&
                <ReEnablePickUpModal
                    idd={idd}
                    ship_code={ship_code}
                    show={showReEnablePickup}
                    onClose={() => setShowReEnablePickup(false)}
                    noRedirect
                    onReload={() => onReload('reenable')}
                    
                />
            }
        </Fragment>
    ) : <div />
}   