import React, { Fragment } from 'react';

export default props => {
    const { content, headers, message, labelOnly } = props;
    return (
        <div className={`contactCardBody`}>
            <div className={`contactCardBox container-fluid p-0'`}>
                <div className={"row py-3 m-0"}>
                    {
                        headers.map((item, i) => (
                            <div className="col-12 mb-3" key={i}>
                                {item.title && <label className={"titleInfo"}>{item.title}</label>}
                                <label className="d-block m-0 lblInfo">{item.value}</label>
                            </div>))
                    }
                    {
                        content &&
                        content.map((item, i) => (
                            labelOnly && (item.title === "Time" || item.title === "Date") ? <></>:
                            <div key={`info-row${i}`} className="col-6">
                                
                                {item.title &&
                                <label className={"titleInfo"}>{item.title}</label>}
                                <label className="d-block m-0 lblInfo">{item.value}</label>
                            </div>

                        ))
                    }
                </div>

                {message &&
                    <Fragment>
                        <hr className="borderBottom"></hr>
                        <div className="row py-3 m-0">
                            <div className="col-12 mb-3">
                                <label className="d-block m-0 lblInfo">{message}</label>
                            </div>

                        </div>
                    </Fragment>
                }

            </div>
        </div>
    );
}