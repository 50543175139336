import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../core/contexts';
import { setShipmentsSearchParameters, setShipmentsSearching } from '../../core/redux/actions';
import { selectShipmentsSearchResults, selectShipmentsSearching, selectUserInfo } from '../../core/redux/selectors';
import { getCarrierList } from '../../services';
import { Input, Select, InputCalendar, Checkbox, Icon } from '../controls';
import { POWRButton } from '../styledComponents';
import { REGEXP, SHIPMENT_EVENT_STATUS, THEATER_TYPES } from '../../core/constants';

export default props => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();
    const shipments = useSelector(selectShipmentsSearchResults);
    const isSearching = useSelector(selectShipmentsSearching);
    const userInfo = useSelector(selectUserInfo);
    const [rmaLogin, setRMALogin] = useState(false);
    const [error, setError] = useState(false);
    const [gettingParams, setGettingParams] = useState(true);
    const [searchingCarriers, setSearchingCarriers] = useState(true);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [shipmentID, setShipmentID] = useState("");
    const [shipmentID1, setShipmentID1] = useState(true);
    const [haveShipmentID, setHaveShipmentID] = useState(false);
    const [rma, setRma] = useState("");
    const [rma1, setRma1] = useState(true);
    const [haveRma, setHaveRma] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState("");
    const [haveTrackingNumber, setHaveTrackingNumber] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");
    const [haveSerialNumber, setHaveSerialNumber] = useState(false);
    const [shipmentStatusSelected, setShipmentStatusSelected] = useState([]);
    const [shipmentStatusCodes, setShipmentStatusCodes] = useState([]);
    const [optionsShipmentStatus, setOptionsShipmentStatus] = useState([]);
    const [theaterSelected, setTheaterSelected] = useState([]);
    const [theaterSelectedCodes, setTheaterSelectedCodes] = useState([]);
    const [theaterOptions, setTheaterOptions] = useState([]);
    const [categorySelected, setCategorySelected] = useState("");
    const [categorySelectedCode, setCategorySelectedCode] = useState("");
    const [carrier, setCarrier] = useState([]);
    const [optionsCarrier, setOptionsCarrier] = useState([]);
    const [onlyMine, setOnlyMine] = useState(false);
    const [excludeSave, setExcludeSave] = useState(true);
    const [showCalendars, setShowCalendars] = useState(true);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(true);
    const [DHLELPOnly, setDHLELPOnly] = useState(false);

    const dateFormat = sessionStorage.getItem('dateFormat');

    const rlsp = props.rlsp || false;
    const categoryOptions = [
        { code: 'A', text: translate('aux.all') },
        { code: 'P', text: translate('shipmentsStatus.product') },
        { code: 'S', text: translate('shipmentsStatus.service') }
    ];

    useEffect(() => {
        setError(props.canceled ? false : shipments ? shipments.length < 1 : false);
    }, [shipments, props.canceled]);

    useEffect(() => {
        if (userInfo.rmaLogin) {
            setRMALogin(true);
            setRma(userInfo.rma);
        }
    }, [userInfo]);

    useEffect(() => {
        if (rmaLogin && rma) {
            setRMALogin(false);
            handleSearch();
            dispatch(setShipmentsSearching(true));
        }
    }, [rmaLogin, rma]);

    useEffect(() => {
        if (!theaterOptions.length) setTheaterOptions(
            THEATER_TYPES.map(x => ({...x, text: translate(x.text)}))
        );
    }, [THEATER_TYPES]);

    useEffect(() => {
        if (!optionsShipmentStatus.length) {
            let options = [];
            SHIPMENT_EVENT_STATUS.forEach(el => {
                if (el.id !== 0 && el.id !== 9 && el.id !== 12  && el.id !== 14 && el.id !== 15) {
                    options.push({
                        ...el,
                        text: translate(el.text),
                        code: translate(el.text)
                    })
                }
            })
            setOptionsShipmentStatus(options);
        }
    }, [SHIPMENT_EVENT_STATUS]);

    const getCarrier = () => {
        if (searchingCarriers) {
            const carrierBody = {
                _dc: 16,
                code: ""
            }

            getCarrierList(carrierBody).then(res => {
                let str = res.res ? typeof (res.res) == 'string' ? res.res
                    .replace(/idd:/g, "\"idd\":")
                    .replace(/type:/g, "\"type\":")
                    .replace(/iconCls:/g, "\"iconCls\":")
                    .replace(/'/g, "\"")
                    : res.res : { dtos: [] };
                let rs = typeof (str) == 'string' ? JSON.parse(str) : str;
                setSearchingCarriers(false);
                setOptionsCarrier(rs.dtos);
            }).catch(() => {
            });
        }
    }

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.search && (rma1 || shipmentID1)) {
            if (parseInt(props.location.state.search)) {
                if (rma1) setRma(props.location.state.search);
            } else {
                if (shipmentID1) setShipmentID(props.location.state.search);
            }
            setParams(props.location.state.search);
        } else {
            setParams();
        }
        getCarrier();
        if (gettingParams) dispatch(setShipmentsSearching(true));
    }, [props.location, gettingParams]);

    const setParams = (search) => {
        if (gettingParams) {
            let evalENS = false;
            if (shipmentID || rma || trackingNumber || excludeSave) {
                evalENS = excludeSave;
            }

            let preSearch = search ? parseInt(search) ? 1 : 2 : 0;

            const parameters = rlsp ? {
                param_ship_code: preSearch === 2 && search && shipmentID1 ? search : shipmentID,
                param_rma_or_tradein: preSearch === 1 && search && rma1 ? search : rma,
                param_tn: trackingNumber,
                param_ship_status: shipmentStatusCodes ? shipmentStatusCodes.toString() : '',
                param_date_from: fromDate,
                param_date_to: toDate,
                param_max_records: 501,
                param_use_date: showAdvanceSearch && showCalendars ? 'on' : '',
                param_car_edit_mode: true
            } : {
                param_ship_code: preSearch === 2 && search && shipmentID1 ? search : shipmentID,
                param_rma_or_tradein: preSearch === 1 && search && rma1 ? search : rma,
                param_tn: trackingNumber,
                param_ship_status: shipmentStatusCodes ? shipmentStatusCodes.toString() : '',
                param_theater: theaterSelectedCodes ? theaterSelectedCodes.toString() : '',
                param_rma_cat: categorySelectedCode,
                param_carrier: carrier ? carrier.toString() : '',
                param_use_date: showAdvanceSearch && showCalendars ? 'on' : '',
                param_only_mine: onlyMine ? 'on' : '',
                param_exclude_saved: evalENS ? 'on' : '',
                'id-gct-view-search-adv-checkbox': showAdvanceSearch ? 'on' : '',
                param_date_from: fromDate,
                param_date_to: toDate,
                param_max_records: 501,
                param_is_paperless: DHLELPOnly ? 'on' : '',
                param_serial_number: serialNumber
            };
            setGettingParams(false);
            dispatch(setShipmentsSearchParameters(parameters));
        }
    }

    const handleDateBegin = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) <= new Date(toDate) || toDate === "" || v === "") setFromDate(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) <= new Date(toDate) || toDate === "")) setFromDate(newDate);
        } else setFromDate(v);
    }

    const handleDateEnd = (v, mobile = false) => {
        // Check the date format and value
        if (mobile) {
            if (new Date(v) >= new Date(fromDate) || fromDate === "" || v === "") setToDate(v);
        } else if (v !== "") {
            let newDate = moment(v, dateFormat).format('YYYY-MM-DD')
            if (newDate !== "Invalid date" && (new Date(newDate) >= new Date(fromDate) || fromDate === "")) setToDate(newDate);
        } else setToDate(v);
    }

    const handleShipmentStatus = (v) => {
        let status = SHIPMENT_EVENT_STATUS.filter(x => v.includes(translate(x.text)));
        let codes = status.map(st => (st.id));
        setShipmentStatusSelected(v);
        setShipmentStatusCodes(codes);
    }

    const handleTheater = (v) => {
        let theater = theaterOptions.filter(x => v.includes(x.code));
        let tCode = theater.map(th => (th.code));
        setTheaterSelected(v);
        setTheaterSelectedCodes(tCode);
    }

    
    const handleCategory = (e) => {
        let select = categoryOptions.filter(x => x.code === e);
        let text = select[0] && select[0].text ? select[0].text : '';
        setCategorySelectedCode(e);
        setCategorySelected(text);
    }

    const handleShipmentID = (v) => {
        setHaveShipmentID(false);
        setShipmentID1(false);
        setShipmentID(v);
    }

    const handleRma = (v) => {
        if (REGEXP.RMAS.test(v)) {
            setHaveRma(false);
            setRma1(false);
            setRma(v);
        }
    }

    const handleTrackingNumber = (v) => {
        setHaveTrackingNumber(false);
        setTrackingNumber(v);
    }

    const handleSerialNumber = (v) => {
        setHaveSerialNumber(false);
        setSerialNumber(v);
    }

    const handleSearch = () => {
        setHaveShipmentID(true);
        setHaveRma(true);
        setHaveTrackingNumber(true);
        setHaveSerialNumber(true);
        setGettingParams(true);
    }

    const handleReset = () => {
        setError(false);
        setFromDate(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        setToDate(moment().format('YYYY-MM-DD'));
        setShipmentID("");
        setHaveShipmentID(false);
        setRma("");
        setHaveRma(false);
        setTrackingNumber("");
        setHaveTrackingNumber(false);
        setSerialNumber("");
        setHaveSerialNumber(false);
        setShipmentStatusSelected([]);
        setShipmentStatusCodes([]);
        setTheaterSelected([]);
        setTheaterSelectedCodes([]);
        setCarrier([]);
        setOnlyMine(false);
        setExcludeSave(true);
        setShowCalendars(true);
        setShowAdvanceSearch(true);
        setDHLELPOnly(false);
    }

    return (
        <div className={`main-search-bar ${props.className ? props.className : ''}`}>
            <div className="search-bar-header mb-3">
                <h3>{translate('subtitle.shipmentsFilter')}</h3>
                <div className="search-bar-reset" onClick={(() => handleReset())}>
                    <Icon content="redo-alt" height="16px" type="regular" className="icon" color="#099AD6" />
                    <label>{translate('button.reset')}</label>
                </div>
            </div>
            <div className="search-bar-body">
                <Input
                    value={shipmentID}
                    onChange={(e) => handleShipmentID(e.target.value)}
                    onEnter={handleSearch}
                    label={translate('gridColumns.shipmentID')}
                    placeholder={translate('gridColumns.shipmentID')}
                    error={shipmentID !== "" && haveShipmentID && !isSearching && error}
                    errorMessage={translate('error.shipmentIDError')}
                    clear
                />
                <div className="rma-search-bar">
                    <Input
                        value={rma}
                        onChange={(e) => handleRma(e.target.value)}
                        onEnter={handleSearch}
                        label={translate('title.rma')}
                        placeholder={translate('title.rma')}
                        error={rma !== "" && haveRma && !isSearching && error}
                        errorMessage={translate('error.noScheduledPickups')}
                        clear
                    />
                    <label className="pb-16">{translate("subtitle.forMultipleRMAs")}</label>
                </div>
                <Input
                    value={trackingNumber}
                    onChange={(e) => handleTrackingNumber(e.target.value)}
                    onEnter={handleSearch}
                    label={translate('gridColumns.tracking')}
                    placeholder={translate('gridColumns.tracking')}
                    error={trackingNumber !== "" && haveTrackingNumber && !isSearching && error}
                    errorMessage={translate('error.trackingError')}
                    clear
                />
                {!rlsp && <>
                    <Input
                        value={serialNumber}
                        onChange={(e) => handleSerialNumber(e.target.value)}
                        onEnter={handleSearch}
                        label={translate('formLabel.serial')}
                        placeholder={translate('formLabel.serial')}
                        error={serialNumber !== "" && haveSerialNumber && !isSearching && error}
                        errorMessage={translate('error.serialError')}
                        clear
                    />
                    <Checkbox
                        id={'showAdvanceSearch'}
                        value={showAdvanceSearch}
                        checked={showAdvanceSearch}
                        onClick={setShowAdvanceSearch.bind(this, !showAdvanceSearch)}
                        text={translate('subtitle.advancedSearchOptions')}
                    />
                </>}
                {(showAdvanceSearch || rlsp) &&
                    <Fragment>
                        <Checkbox
                            id={'showCalendars'}
                            value={showCalendars}
                            checked={showCalendars}
                            onClick={setShowCalendars.bind(this, !showCalendars)}
                            text={translate('formLabel.dateCreated')}
                        />
                        <div className={showCalendars ? "row" : "d-none"}>
                            <div className="col-12 col-xl-6 pr-xl-0">
                                <InputCalendar
                                    text={fromDate !== "" ? moment(fromDate).format(dateFormat) : ""}
                                    value={fromDate !== "" ? moment(fromDate).format("DD/MM/YY") : ""}
                                    onBlur={(v, m) => handleDateBegin(v, m)}
                                    maxDate={moment(toDate).toDate()}
                                    label={translate('formLabel.createdFrom')}
                                    placeholder={dateFormat}
                                    className="mt-0 mb-3 pr-1"
                                    clear
                                    paddingInput={"pl-1"}
                                />
                            </div>
                            <div className="col-12 col-xl-6">
                                <InputCalendar
                                    text={toDate !== "" ? moment(toDate).format(dateFormat) : ""}
                                    value={toDate !== "" ? moment(toDate).format("DD/MM/YY") : ""}
                                    onBlur={(v, m) => handleDateEnd(v, m)}
                                    minDate={moment(fromDate).toDate()}
                                    label={translate('formLabel.to')}
                                    placeholder={dateFormat}
                                    className="mt-0 mb-3 pr-1"
                                    clear
                                    paddingInput={"pl-1"}
                                />
                            </div>
                        </div>
                        {!rlsp && <>
                            <Select
                                value={shipmentStatusSelected}
                                onChange={handleShipmentStatus}
                                options={optionsShipmentStatus}
                                label={translate('gridColumns.shipmentStatus')}
                                placeholder={translate('gridColumns.shipmentStatus')}
                                className="mt-0 mb-3"
                            />
                            <Select
                                value={carrier}
                                onChange={setCarrier}
                                options={optionsCarrier}
                                label={translate('gridColumns.carrier')}
                                placeholder={translate('gridColumns.carrier')}
                                className="mt-0 mb-3"
                            />
                            <Select
                                value={theaterSelected}
                                onChange={handleTheater}
                                options={theaterOptions}
                                label={translate('gridColumns.theater')}
                                placeholder={translate('gridColumns.theater')}
                                className="mt-0 mb-3"
                            />
                            <Select
                                value={categorySelectedCode}
                                text={categorySelected}
                                onChange={handleCategory}
                                options={categoryOptions}
                                label={translate('formLabel.rmaCat')}
                                placeholder={translate('formLabel.rmaCat')}
                                className="mt-0 mb-3"
                                singleSelection
                            />
                            <Checkbox
                                id={'excludeSave'}
                                value={excludeSave}
                                checked={excludeSave}
                                onClick={setExcludeSave.bind(this, !excludeSave)}
                                text={translate('formLabel.excludeNotSubmitted')}
                            />
                            <Checkbox
                                id={'onlyMine'}
                                value={onlyMine}
                                checked={onlyMine}
                                onClick={setOnlyMine.bind(this, !onlyMine)}
                                text={translate('formLabel.shipmentsOnly')}
                            />
                            <Checkbox
                                id={'DHLELPOnly'}
                                value={DHLELPOnly}
                                checked={DHLELPOnly}
                                onClick={setDHLELPOnly.bind(this, !DHLELPOnly)}
                                text={translate('formLabel.dhlELPOnly')}
                                className="pb-16"
                            />
                        </>}
                    </Fragment>
                }
            </div>
            <POWRButton
                className="w-100 mt-16"
                onClick={handleSearch}
            >
                {translate('button.search')}
            </POWRButton>
        </div>
    );
}